import React from 'react';

import { Route, Routes } from 'react-router-dom';

import DashboardView from './views/DasboardView';
import { appRoutes } from '@/configs/appRoutes';

const Dashboard = () => (
  <Routes>
    <Route path={appRoutes.ROOT} element={<DashboardView />} />
  </Routes>
);
export default Dashboard;
