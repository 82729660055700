import React, { useCallback, useState } from 'react';

import { ColumnProps } from 'antd/lib/table';

import { PaginatedResponse } from '../hooks/useGetBoxConfigHistory';
import { BoxConfigHistoryItem } from '../models/boxConfig';
import CopyToClipboard from '@/components/CopyToClipboard';
import CustomModal from '@/components/CustomModal';
import StyledTable from '@/components/tables/StyledTable';
import { parseAntdSorter } from '@/helpers/antdTable';
import useAntdColumns from '@/hooks/useAntdColumns';
import { FetchResourceState } from '@/hooks/useFetchResource';
import { PaginationParams } from '@/hooks/usePagination';

interface BoxConfigHistoryTableProps {
  setPaginationParams: (params: Partial<PaginationParams>) => void;
  boxConfigHistory: FetchResourceState<
    PaginatedResponse<BoxConfigHistoryItem>,
    Error
  >;
  paginationParams: PaginationParams;
}

const BoxConfigHistoryTable = ({
  paginationParams,
  boxConfigHistory,
  setPaginationParams,
}: BoxConfigHistoryTableProps) => {
  const [config, setConfig] = useState<Pick<
    BoxConfigHistoryItem,
    'boxConfiguration'
  > | null>(null);

  const pagination = {
    current: paginationParams.page,
    pageSize: paginationParams.pageSize,
    orderDirection: paginationParams.orderDirection,
    total:
      boxConfigHistory.state === 'success'
        ? boxConfigHistory.resource.total
        : 0,
    showSizeChanger: false,
  };

  const handleTableChange = useCallback(
    ({ current: page, pageSize, orderDirection }, _filters, sorter) => {
      const [sortField] = parseAntdSorter(sorter);
      setPaginationParams({
        page,
        orderColumn: sortField,
        orderDirection: orderDirection,
        pageSize,
      });
    },
    [setPaginationParams]
  );

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: ['createdByType', 'parsedCreatedAt', 'type'],
    defaultSort: {
      sortBy: paginationParams.orderColumn,
      order: paginationParams.orderDirection,
    },
    eyeLinkProps: {
      onClick: (record) => {
        setConfig(record.boxConfiguration);
      },
    },
  });

  return (
    <>
      <StyledTable
        rowKey="id"
        dataSource={
          boxConfigHistory.state === 'success' &&
          boxConfigHistory.resource.items
        }
        columns={columns}
        size="small"
        pagination={pagination}
        loading={boxConfigHistory.state !== 'success'}
        onChange={handleTableChange}
        scroll={{ x: '100%' }}
      />
      {config && (
        <CustomModal
          title={
            <>
              Box Configuration
              <CopyToClipboard data={JSON.stringify(config, null, 2)} />
            </>
          }
          open={!!config}
          destroyOnClose={true}
          onOk={() => setConfig(null)}
          onCancel={() => setConfig(null)}
        >
          <pre>{JSON.stringify(config, null, 2)}</pre>
        </CustomModal>
      )}
    </>
  );
};

export default BoxConfigHistoryTable;
