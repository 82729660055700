import { useCallback } from 'react';

import useLPShopsStore from './useLPShopsStore';
import lpShopsPostProcessor from '../helpers/lpShopsPostProcessor';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useRefreshShops = () => {
  const dispatch = useLPShopsStore((state) => state.dispatch);
  const order = useLPShopsStore((state) => state.order);
  const sortBy = useLPShopsStore((state) => state.sortBy);
  const pageSize = useLPShopsStore((state) => state.pageSize);
  const offset = useLPShopsStore((state) => state.offset);
  const addError = useError();
  const [, makeRequest] = useImperativeRequestWrapper('lpVillage');

  const getShops = useCallback(async () => {
    const { data, error } = await getPaginatedTableData({
      params: {
        path: '/admin/shops',
        method: 'get',
      },
      reducer: lpShopsPostProcessor,
      dispatch,
      addError,
      makeRequest,
      queryParams: {
        pageSize,
        offset,
        sortBy,
        order,
      },
      queryParamsOptions: { skipEmptyString: true, skipNull: true },
    });

    return { data, error };
  }, [addError, dispatch, makeRequest, order, sortBy, pageSize, offset]);

  return getShops;
};

export default useRefreshShops;
