import React, { useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Button, Form } from 'antd';
import styled from 'styled-components';

import { getRows } from './commonFunctions/getRows';
import useAddSocialMediaData from './hooks/useAddSocialMediaData';
import { socialMediaArticle } from '../../features/dashboard/models/socialMediaArticle';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const SocialMediaTable = () => {
  const article: socialMediaArticle = {
    title: '',
    description: '',
    publicated_at: '',
    url: '',
    title_fr: '',
    description_fr: '',
    url_fr: '',
    title_de: '',
    description_de: '',
    url_de: '',
    url_icon: '',
  };
  const initialArticles: socialMediaArticle[] = Array(5).fill(article);
  const [{ loading }, addSocialMediaData] = useAddSocialMediaData();
  const [step, setStep] = useState(1);
  const [formData, setFormData] =
    useState<socialMediaArticle[]>(initialArticles);
  const columns = [
    { key: 'description', text: 'Description', language: 'english' },
    { key: 'publicated_at', text: 'Date of event', language: 'english' },
    { key: 'title', text: 'Title Text', language: 'english' },
    { key: 'url', text: 'Title Link', language: 'english' },
    { key: 'url_icon', text: 'Icon Image URL', language: 'english' },
    { key: 'description_fr', text: 'Description', language: 'french' },
    { key: 'title_fr', text: 'Titre du text', language: 'french' },
    { key: 'url_fr', text: 'Title Link', language: 'french' },
    { key: 'description_de', text: 'Bezeichnung', language: 'german' },
    { key: 'title_de', text: 'Texttitel', language: 'german' },
    { key: 'url_de', text: 'Title Link', language: 'german' },
  ];

  const prevStep = () => {
    setStep(step - 1);
  };
  const onFinish = async (values) => {
    if (step !== 3) {
      setStep(step + 1);
    }
    const res = Object.entries(values).reduce((o, [k, v]) => {
      const [name, number]: any = k.match(/\D+|\d+$/g);
      o[number] = { ...(o[number] || {}), [name]: v };

      return o;
    }, {});
    const data: socialMediaArticle[] = Object.values(res);
    setFormData((prevData) =>
      prevData.map((obj, index) => ({ ...obj, ...data[index] }))
    );
    const newData = formData.map((obj, index) => ({ ...obj, ...data[index] }));
    if (!newData.some((item) => Object.values(item).includes(''))) {
      addSocialMediaData(newData);
    }
  };

  return (
    <Wrapper>
      <h1
        style={{
          fontSize: '2rem',
          fontWeight: 'bold',
          marginBottom: '1rem',
        }}
      >
        Social media table
      </h1>
      <Form layout="inline" name="control-hooks" onFinish={onFinish}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((col, index) => {
                  if (step === 1 && col.language === 'english') {
                    return <TableCell key={index}>{col.text}</TableCell>;
                  }
                  if (step === 2 && col.language === 'french') {
                    return <TableCell key={index}>{col.text}</TableCell>;
                  }
                  if (step === 3 && col.language === 'german') {
                    return <TableCell key={index}>{col.text}</TableCell>;
                  }

                  return null;
                })}
              </TableRow>
            </TableHead>
            <TableBody>{getRows(step, columns)}</TableBody>
          </Table>
        </TableContainer>
        {step === 1 && (
          <Form.Item style={{ margin: '1rem 1rem' }}>
            <Button loading={loading} type="primary" htmlType="submit">
              Next
            </Button>
          </Form.Item>
        )}
        {step === 2 && (
          <>
            <Form.Item style={{ margin: '1rem 1rem' }}>
              <Button
                onClick={prevStep}
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                Prev
              </Button>
            </Form.Item>
            <Form.Item style={{ margin: '1rem 1rem' }}>
              <Button loading={loading} type="primary" htmlType="submit">
                Next
              </Button>
            </Form.Item>
          </>
        )}
        {step === 3 && (
          <>
            <Form.Item style={{ margin: '1rem 1rem' }}>
              <Button
                onClick={prevStep}
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                Prev
              </Button>
            </Form.Item>
            <Form.Item style={{ margin: '1rem 1rem' }}>
              <Button loading={loading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </>
        )}
      </Form>
    </Wrapper>
  );
};

export default SocialMediaTable;
