import React, { useCallback } from 'react';

import { FileExcelOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';

import { useQualityCheck } from '../../quality-checks';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';
import useError from '@/hooks/useError';

const DownloadQualityChecks = ({ boxId }: { boxId: string }) => {
  const { downloadChecklists, loadingDownload } = useQualityCheck(boxId);
  const addError = useError();

  const handleDownload = useCallback(async () => {
    const { success, data } = await downloadChecklists();

    if (success) {
      try {
        const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        saveAs(csvData, `checklists-box-${boxId}.csv`);
      } catch (error) {
        addError(error);

        return;
      }
    }
  }, [addError, boxId, downloadChecklists]);

  return (
    <ScopedCta
      component={PillButton}
      onClick={handleDownload}
      icon={<FileExcelOutlined />}
      requiredScopes={[scopes.EDIT_BOX_QUALITY_CHECK]}
      disabled={loadingDownload}
    >
      Download Checklists
    </ScopedCta>
  );
};

export default DownloadQualityChecks;
