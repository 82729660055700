import has from 'lodash/has';

import { offerPostProcessor } from './offersPostProcessor';
import { addressPostProcessor } from '@/helpers/addressesPostProcessor';
import dates from '@/helpers/dates';

export const contributionPostProcessor = (record) => ({
  ...record,
  parsedTotalAmount:
    has(record, 'totalAmountInCents') && has(record, 'currency')
      ? `${(record.totalAmountInCents / 100).toFixed(2)} ${record.currency}`
      : '',
  parsedCreatedAt: !!record.createdAt ? dates.parseDate(record.createdAt) : '',
  parsedUpdatedAt: !!record.updatedAt ? dates.parseDate(record.updatedAt) : '',
  parsedPaymentDate: !!record.paymentDate
    ? dates.parseDate(record.paymentDate)
    : '',
  parsedStartDate: !!record.startDate ? dates.parseDate(record.startDate) : '',
  parsedOffer: has(record, 'contributionOffer')
    ? offerPostProcessor(record.contributionOffer)
    : {},
  address: has(record, 'address') ? addressPostProcessor(record.address) : {},
});

const contributionsPostProcessor = (data) => {
  const parsedItems = data.items.map((item) => contributionPostProcessor(item));

  return { ...data, items: parsedItems };
};

export const nonPaginatedContributionsProcessor = (data) =>
  data.map((item) => contributionPostProcessor(item));

export default contributionsPostProcessor;
