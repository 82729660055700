import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useAddNewsletterData: any = () => {
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpAccountV3');
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);

  const addNewsletterData = async (newsletterData) => {
    const { error } = await makeRequest({
      path: '/newsletters',
      method: 'post',
      body: { newsletters: newsletterData },
    });
    if (error) {
      addError(error);

      return { success: false };
    }
    addSuccessMsg(
      { msg: 'Newsletter was successfully added!', type: MessageType.Success },
      'root',
      {}
    );

    return { success: true };
  };

  return [{ loading }, addNewsletterData];
};

export default useAddNewsletterData;
