import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Steps } from 'antd';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import {
  ChecklistItem,
  useQualityCheck,
  AddShipmentId,
  ICreateQualityCheckItem,
  ICreateQualityChecklist,
  INITIAL_CreateQualityChecklist,
  INITIAL_QualityCheck,
  IQualityCheckType,
  ZonesNames,
} from '../../quality-checks';
import CancelSubmitFooterModal from '@/components/CancelSubmitFooterModal';
import CustomModal from '@/components/CustomModal';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const { Step } = Steps;

const steps = [
  { title: 'Shipment-ID' },
  ...Object.keys(ZonesNames).map((z) => ({ title: ZonesNames[z] })),
];

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const modalContentStyle = {
  height: '75vh',
  overflow: 'auto',
};

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  width: 10rem;
`;

const CreateBoxChecklist = ({ boxId }: { boxId: string }) => {
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('device');
  const addError = useError();
  const { createChecklist, getQualityChecklists } = useQualityCheck(boxId);
  const [visible, setVisible] = useState<boolean>(false);
  const [checklist, setChecklist] = useState<ICreateQualityChecklist>(
    INITIAL_CreateQualityChecklist
  );
  const [step, setStep] = useState<number>(0);
  const openModal = useCallback(() => setVisible(true), []);
  const closeModal = useCallback(() => {
    setVisible(false);
    setChecklist(INITIAL_CreateQualityChecklist);
    setStep(0);
  }, []);
  const [qualityCheckTypes, setQualityCheckTypes] = useState<
    IQualityCheckType[]
  >([]);

  const getQualityCheckTypes = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: '/quality-checks/types?offset=0&order=DESC&pageSize=100&sortBy=createdAt',
      method: 'get',
    });

    if (error) {
      addError(error);

      return;
    }
    if (data?.items) {
      setQualityCheckTypes(data.items);
      setChecklist((prev) => ({
        ...prev,
        items: data.items.map((qct) => ({
          ...INITIAL_QualityCheck,
          qualityCheckTypeId: qct.id,
        })),
      }));
    }
  }, [addError, makeRequest]);

  useEffect(() => {
    if (visible) {
      getQualityCheckTypes();
    }
  }, [visible, getQualityCheckTypes]);

  const isInvalid = useMemo(
    () => !checklist.items.every((item) => item.status !== 'UNKNOWN'),
    [checklist]
  );

  const isInvalidStep = useMemo(() => {
    if (step === 0) {
      return false;
    }
    const items = qualityCheckTypes
      .filter((q) => q.zone === Object.keys(ZonesNames)[step - 1])
      .map((qct) =>
        checklist.items.find((item) => item.qualityCheckTypeId === qct.id)
      );

    if (isEmpty(items)) {
      return false;
    }

    return !items.every((item) => item?.status !== 'UNKNOWN');
  }, [checklist, qualityCheckTypes, step]);

  const handleSubmit = useCallback(async () => {
    if (checklist) {
      const { success } = await createChecklist({
        ...checklist,
        boxId: boxId,
      });

      if (success) {
        await getQualityChecklists();
        closeModal();
      }
    }
  }, [getQualityChecklists, createChecklist, checklist, closeModal, boxId]);

  const setChecklistItem = useCallback(
    (item: ICreateQualityCheckItem) => {
      const index = checklist?.items.findIndex(
        (checkItem) => checkItem.qualityCheckTypeId === item.qualityCheckTypeId
      );

      if (index > -1) {
        setChecklist((prev) => {
          if (!prev) {
            return prev;
          }
          const copy = prev.items.slice();
          copy.splice(index, 1, item);

          return { ...prev, items: copy };
        });
      }
    },
    [checklist]
  );

  const modalFooterButtons = CancelSubmitFooterModal(
    isInvalid,
    handleSubmit,
    closeModal,
    loading
  );

  return (
    <Wrapper>
      <ScopedCta
        component={PillButton}
        icon={<PlusOutlined />}
        onClick={openModal}
        requiredScopes={[scopes.EDIT_BOX_QUALITY_CHECK]}
      >
        Create Checklist
      </ScopedCta>

      <CustomModal
        title="Create Checklist"
        open={visible}
        destroyOnClose={true}
        footer={modalFooterButtons}
        onCancel={closeModal}
        width="95%"
        bodyStyle={modalContentStyle}
      >
        <>
          <Steps size="small" current={step}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          {step === 0 && (
            <AddShipmentId setChecklist={setChecklist} checklist={checklist} />
          )}
          {qualityCheckTypes
            .filter((q) => q.zone === Object.keys(ZonesNames)[step - 1])
            .map((qct) => (
              <ChecklistItem
                key={qct.id}
                qualityCheckType={qct}
                setChecklistItem={setChecklistItem}
                item={
                  checklist.items.find(
                    (item) => item.qualityCheckTypeId === qct.id
                  ) || INITIAL_QualityCheck
                }
              />
            ))}
          <ButtonsWrapper>
            <PillButton
              type="default"
              disabled={step === 0}
              onClick={() => setStep((prev) => prev - 1)}
            >
              Previous
            </PillButton>
            <PillButton
              disabled={
                isInvalidStep || step === Object.keys(ZonesNames).length
              }
              onClick={() => setStep((prev) => prev + 1)}
            >
              Next
            </PillButton>
          </ButtonsWrapper>
        </>
      </CustomModal>
    </Wrapper>
  );
};

export default CreateBoxChecklist;
