import React, { FormEvent } from 'react';

import { Input } from 'antd';

interface ISearchField {
  value: string;
  name: string;
  label?: string;
  placeholder?: string;
  width?: string;
  handleChange: (event: FormEvent<Element>) => void;
}

const SearchField = ({
  value,
  name,
  placeholder,
  width,
  handleChange,
}: ISearchField) => (
  <Input
    value={value}
    name={name}
    type="text"
    onChange={handleChange}
    placeholder={placeholder}
    autoComplete="false"
    width={width || '30rem'}
  />
);

export default SearchField;
