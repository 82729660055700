import React from 'react';

import { Radio, RadioChangeEvent } from 'antd';
import styled from 'styled-components';

import Label from '@/components/forms/Label';

const LabelWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

const TYPES = [
  { label: 'INTERNAL', value: true },
  { label: 'EXTERNAL', value: false },
];

const PartnerInternalSelector = ({
  onChange,
  value,
  style,
  name,
}: {
  onChange: any;
  value?: { is_internal: boolean };
  style?: any;
  name?: string;
}) => (
  <div style={style}>
    <LabelWrapper>
      <Label>Partner profil *</Label>
    </LabelWrapper>

    <Radio.Group
      name={name || 'partner-type'}
      options={TYPES}
      onChange={({ target: { value } }: RadioChangeEvent) => onChange(value)}
      optionType="button"
      buttonStyle="solid"
      value={value?.is_internal}
    />
  </div>
);

export default PartnerInternalSelector;
