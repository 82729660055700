import { useCallback } from 'react';

import { PaginatedResponse } from './useGetBoxConfigHistory';
import useGetBoxFirmwareHistory, {
  GetBoxFirmwareHistoryParams,
} from '../hooks/useGetBoxFirmwareHistory';
import { BoxFirmwareHistoryItem } from '../models/boxFirmware';
import { UseFetchResource, useFetchResource } from '@/hooks/useFetchResource';

const useBoxFirmwareHistory = (
  boxId: string,
  params?: GetBoxFirmwareHistoryParams
): UseFetchResource<PaginatedResponse<BoxFirmwareHistoryItem>> => {
  const getBoxFirmwareHistory = useGetBoxFirmwareHistory();

  const fetchFunction = useCallback(
    () => getBoxFirmwareHistory(boxId, params),
    [getBoxFirmwareHistory, boxId, params]
  );

  return useFetchResource(fetchFunction, {
    enabled: boxId !== '',
  });
};

export default useBoxFirmwareHistory;
