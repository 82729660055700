import { create } from 'zustand';

import { boxLifecycle } from '../models/boxLifecycle';
import {
  getInitialState,
  IPaginatedStore,
  IReducer,
  reducer,
} from '@/helpers/paginatedStoreHelpers';

const INITIAL_STATE = getInitialState<boxLifecycle, {}>({
  sortBy: 'createdAt',
  order: 'DESC',
});

const useBoxesLifecycleStore = create<IPaginatedStore<boxLifecycle, {}>>(
  (set) => ({
    ...INITIAL_STATE,
    dispatch: (input: IReducer<boxLifecycle, {}>) =>
      set((state) => reducer(state, input, INITIAL_STATE)),
  })
);

export default useBoxesLifecycleStore;
