import React, { useCallback, useMemo, useState, useEffect } from 'react';

import { PlusOutlined } from '@ant-design/icons';

import CreateBoxContractForm from './CreateBoxContractForm';
import useCreateBoxContract from '../hooks/useCreateBoxContract';
import { INITIAL_CreateBoxContract } from '../models/boxContract';
import boxContractSchema from '../schemas/boxContractSchema';
import CancelSubmitFooterModal from '@/components/CancelSubmitFooterModal';
import CustomModal from '@/components/CustomModal';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';

const CreateBoxContract = () => {
  const [visible, setVisible] = useState(false);
  const openModal = useCallback(() => setVisible(true), []);
  const closeModal = useCallback(() => setVisible(false), []);
  const [boxContract, setBoxContract] = useState(INITIAL_CreateBoxContract);
  const { createBoxContract, loading } = useCreateBoxContract();
  const [isInvalid, setIsInvalid] = useState(true);

  const handleClose = useCallback(() => {
    closeModal();
    setBoxContract(INITIAL_CreateBoxContract);
  }, [closeModal]);

  const handleSubmit = useCallback(async () => {
    const { success } = await createBoxContract(boxContract);
    if (success) {
      handleClose();
    }
  }, [createBoxContract, boxContract, handleClose]);

  useEffect(() => {
    const setValidateStatus = async () =>
      await boxContractSchema
        .validate(boxContract)
        .then((_) => setIsInvalid(false))
        .catch((_) => setIsInvalid(true));

    setValidateStatus();
  }, [boxContract]);

  const modalFooterButtons = useMemo(
    () => CancelSubmitFooterModal(isInvalid, handleSubmit, closeModal, loading),
    [closeModal, handleSubmit, isInvalid, loading]
  );

  return (
    <div style={{ marginTop: '0.75rem' }}>
      <ScopedCta
        component={PillButton}
        icon={<PlusOutlined />}
        onClick={openModal}
        requiredScopes={[scopes.EDIT_BUY_N_RENT_CONTRACT]}
      >
        Add box contract
      </ScopedCta>

      <CustomModal
        title="Add a new box contract"
        open={visible}
        destroyOnClose={true}
        footer={modalFooterButtons}
        onCancel={handleClose}
        width="80%"
      >
        <CreateBoxContractForm
          boxContract={boxContract}
          setBoxContract={setBoxContract}
        />
      </CustomModal>
    </div>
  );
};

export default CreateBoxContract;
