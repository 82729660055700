import React, { useEffect, useState } from 'react';

import { Button, Col, Row, Tooltip } from 'antd';
import get from 'lodash/get';
import pick from 'lodash/pick';
import styled from 'styled-components';

import useLPAddressStore from '../hooks/useLPAddressStore';
import AddressForm from '@/components/AddressForm';
import CustomModal from '@/components/CustomModal';
import addressesPostProcessor, {
  addressPostProcessor,
} from '@/helpers/addressesPostProcessor';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import {
  IModifiedLPAccountAddress,
  INITIAL_ModifiedLPAccountAddress,
} from '@/models/address';
import FormTextInput from '@/shared/components/FormTextInput/FormTextInput';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const ButtonWrapper = styled.span`
  margin-left: 8px;
`;

const Span = styled.span`
  margin-right: 8px;
`;

const getFooter = ({
  footer,
  isInvalid,
  loading,
  handleSubmit,
  handleDelete,
  setFooter,
  closeModal,
}) => {
  switch (footer) {
    case 'edit':
      if (isInvalid) {
        return [
          <Button key="Cancel" onClick={closeModal}>
            Cancel
          </Button>,
          <ButtonWrapper key="Wrapper">
            <Tooltip
              placement="topRight"
              title="Please fill all required fields"
            >
              <Button key="submit" type="primary" disabled={true}>
                Save
              </Button>
            </Tooltip>
          </ButtonWrapper>,
        ];
      }

      return [
        <Button key="Cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          key="Save"
          type="primary"
          loading={loading}
          onClick={handleSubmit}
        >
          Save
        </Button>,
      ];
    case 'delete':
      return [
        <Span key="Are you">
          Are you sure you want to delete this address?
        </Span>,
        <Button key="No" onClick={() => setFooter('view')}>
          No
        </Button>,
        <Button key="Yes" danger onClick={handleDelete} loading={loading}>
          Yes
        </Button>,
      ];
    case 'view':
      return [
        <Button key="Cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          key="Delete"
          danger
          onClick={() => setFooter('delete')}
          loading={loading}
        >
          Delete
        </Button>,
        <Button key="Edit" type="primary" onClick={() => setFooter('edit')}>
          Edit
        </Button>,
      ];
  }
};

const ViewAddressModal = () => {
  const viewModalAddress = useLPAddressStore((state) => state.viewModalAddress);
  const dispatch = useLPAddressStore((state) => state.dispatch);
  const pageSize = useLPAddressStore((state) => state.pageSize);
  const offset = useLPAddressStore((state) => state.offset);
  const sortBy = useLPAddressStore((state) => state.sortBy);
  const order = useLPAddressStore((state) => state.order);
  const [footer, setFooter] = useState<'view' | 'delete' | 'edit'>('view');
  const [address, setAddress] = useState<IModifiedLPAccountAddress>(
    INITIAL_ModifiedLPAccountAddress
  );
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);
  const addWarningMsg = useToasts((state) => state.addItem);

  const [{ loading }, makeRequest] =
    useImperativeRequestWrapper('serviceLpAccount');

  useEffect(() => {
    if (!viewModalAddress) {
      setAddress(INITIAL_ModifiedLPAccountAddress);

      return;
    }
    setAddress(viewModalAddress);
  }, [viewModalAddress]);

  const closeModal = () => {
    dispatch({
      type: 'updateViewModal',
      args: { viewModalAddress: null },
    });
  };

  const handleSubmit = () => {
    setFooter('view');
    makeRequest({
      path: `/admin/addresses/${address.id}`,
      method: 'put',
      body: pick(address, [
        'versionHash',
        'displayName',
        'company',
        'firstName',
        'lastName',
        'houseNumber',
        'street',
        'street2',
        'postalCode',
        'city',
        'state',
        'countryCode',
        'isPrimary',
        'types',
      ]),
    }).then(({ data, error }) => {
      if (error) {
        addError(error);
        setFooter('edit');

        return;
      }
      addSuccessMsg(
        { msg: 'Address was successfully updated!', type: MessageType.Success },
        'root',
        {}
      );
      dispatch({
        type: 'updateViewModal',
        args: { viewModalAddress: addressPostProcessor(data) },
      });
      getPaginatedTableData({
        params: {
          path: `/admin/addresses/byaccount/${address.accountId}`,
          method: 'get',
        },
        dispatch,
        queryParams: {
          pageSize,
          offset,
          sortBy,
          order,
        },

        reducer: addressesPostProcessor,
        addError,
        makeRequest,
      });
    });
  };

  const handleDelete = () => {
    makeRequest({
      path: `/admin/addresses/${address.id}/remove`,
      method: 'put',
    }).then(({ _, error }) => {
      if (error) {
        addError(error, { trackError: false });

        return;
      }
      closeModal();
      addWarningMsg(
        { msg: 'Address was successfully deleted!', type: MessageType.Warning },
        'root',
        {}
      );
      getPaginatedTableData({
        params: {
          path: `/admin/addresses/byaccount/${address.accountId}`,
          method: 'get',
        },
        dispatch,
        queryParams: {
          pageSize,
          offset,
          sortBy,
          order,
        },
        reducer: addressesPostProcessor,
        addError,
        makeRequest,
      });
    });
  };

  const isInvalid =
    !address.firstName ||
    !address.lastName ||
    !address.street ||
    !address.postalCode ||
    !address.city ||
    !address.countryCode;

  return (
    <CustomModal
      destroyOnClose={true}
      title="View Address"
      open={!!viewModalAddress}
      width="80%"
      onCancel={closeModal}
      afterClose={() => setFooter('view')}
      footer={getFooter({
        footer,
        isInvalid,
        loading,
        handleSubmit,
        handleDelete,
        setFooter,
        closeModal,
      })}
    >
      <Row gutter={24}>
        <Col span={12}>
          <FormTextInput
            label="Address-ID"
            name="id"
            mb="1rem"
            value={address.id}
            disabled={true}
          />
        </Col>
        <Col span={12}>
          <FormTextInput
            label="Creator-ID"
            name="id"
            mb="1rem"
            value={address.creatorId}
            disabled={true}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={8}>
          <FormTextInput
            label="Created at"
            name="id"
            mb="1rem"
            value={address.parsedCreatedAt}
            disabled={true}
          />
        </Col>
        <Col span={8}>
          <FormTextInput
            label="Updated at"
            name="id"
            mb="1rem"
            value={address.parsedUpdatedAt}
            disabled={true}
          />
        </Col>
        <Col span={4}>
          <FormTextInput
            label="Latitude"
            name="id"
            mb="1rem"
            value={get(address.position, 'latitude', '')}
            disabled={true}
          />
        </Col>
        <Col span={4}>
          <FormTextInput
            label="Longitude"
            name="id"
            mb="1rem"
            value={get(address.position, 'longitude', '')}
            disabled={true}
          />
        </Col>
      </Row>
      <AddressForm
        address={address}
        setAddress={setAddress}
        disabled={footer !== 'edit'}
      />
    </CustomModal>
  );
};

export default ViewAddressModal;
