import { create } from 'zustand';

import { IQualityChecklistLight } from '../models/qualityCheck';
import {
  getInitialState,
  IPaginatedStore,
  IReducer,
  reducer,
} from '@/helpers/paginatedStoreHelpers';

const INITIAL_STATE = getInitialState<IQualityChecklistLight, {}>({
  sortBy: 'createdAt',
  order: 'DESC',
});

const useQualityCheckStore = create<
  IPaginatedStore<IQualityChecklistLight, {}>
>((set) => ({
  ...INITIAL_STATE,
  dispatch: (input: IReducer<IQualityChecklistLight, {}>) =>
    set((state) => reducer(state, input, INITIAL_STATE)),
}));

export default useQualityCheckStore;
