import { useCallback } from 'react';

import useContributionStore from './useContributionStore';
import { contributionPostProcessor } from '../../helpers/contributionsPostProcessor';
import completedPaymentKlaviyo from '@/helpers/completedPaymentKlaviyo';
import { GAEcommEvent, GAEvent } from '@/helpers/tracking';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useModifyContribution: any = () => {
  const [{ loading }, makeRequest] =
    useImperativeRequestWrapper('serviceLpAccount');
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);

  const contribution = useContributionStore((state) => state.contribution);
  const setContribution = useContributionStore(
    (state) => state.setContribution
  );
  const setError = useContributionStore((state) => state.setError);

  const {
    id,
    versionHash,
    paymentMethod,
    paymentUniqueId,
    totalAmountInCents,
    accountId,
    parsedOffer: { multiplierInCents, durationInMonth },
  } = contribution;

  const modifyContribution = useCallback(
    async (newPaymentStatus: string) => {
      const result = await makeRequest({
        path: `/admin/contributions/payment/status`,
        method: 'POST',
        body: {
          id,
          paymentUniqueId: paymentUniqueId,
          paymentStatus: newPaymentStatus,
          versionHash,
        },
      });
      if (!!result.error) {
        addError(result.error);
        setError(result.error);

        return { success: false };
      }
      try {
        const processedContribution = contributionPostProcessor(result.data);
        setContribution(processedContribution);
      } catch (err) {
        addError(err as Error);
        setError(err as Error);

        return { success: false };
      }
      addSuccessMsg(
        {
          msg: `Payment status was successfully marked as "${newPaymentStatus}"`,
          type: MessageType.Success,
        },
        'root',
        {}
      );

      if (newPaymentStatus === 'completed') {
        const parsedAmount = (totalAmountInCents / 100).toFixed(2);
        GAEvent({
          amount: parsedAmount,
          paymentMethod: paymentMethod as string,
        });
        GAEcommEvent({
          contributionId: id,
          parsedMultiplier: (multiplierInCents as number) / 100,
          amount: parsedAmount,
          durationInMonth: durationInMonth as number,
          paymentMethod: paymentMethod as string,
        });
        completedPaymentKlaviyo({
          accountId,
          makeRequest,
          amount: parsedAmount,
          paymentMethod: paymentMethod as string,
        });
      }

      return { success: true };
    },
    [
      makeRequest,
      id,
      versionHash,
      addError,
      addSuccessMsg,
      paymentUniqueId,
      totalAmountInCents,
      durationInMonth,
      multiplierInCents,
      paymentMethod,
      accountId,
      setContribution,
      setError,
    ]
  );

  return [{ loading }, modifyContribution];
};

export default useModifyContribution;
