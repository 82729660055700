import React, { useCallback } from 'react';

import { Grid, NewTextField as TextField } from '@lp/ds-next';
import { Checkbox } from 'antd';

const OfferConfigurationForm = ({ initialOffer, setServiceLevel }) => {
  const handleChange = useCallback(
    (event) => {
      setServiceLevel((prev) => ({
        ...prev,
        offerConfiguration: {
          ...prev.offerConfiguration,
          [event.target.name]: event.target.value,
        },
      }));
    },
    [setServiceLevel]
  );

  const handleChangeSensor = useCallback(
    (event) => {
      setServiceLevel((prev) => ({
        ...prev,
        offerConfiguration: {
          ...prev.offerConfiguration,
          [event.target.name]: {
            ...prev.offerConfiguration[event.target.name],
            values: event.target.value,
          },
        },
      }));
    },
    [setServiceLevel]
  );

  const handleCheckBoxChanges = useCallback(
    (event) => {
      const sensorName = event.target.name.split('-')[1];
      setServiceLevel((prev) => ({
        ...prev,
        offerConfiguration: {
          ...prev.offerConfiguration,
          [sensorName]: {
            ...prev.offerConfiguration[sensorName],
            enabled: event.target.checked,
          },
        },
      }));
    },
    [setServiceLevel]
  );

  return (
    <>
      <Grid item mobile={12} tablet={6} laptop={4}>
        <TextField
          multiline
          fullWidth
          value={initialOffer.otherValues}
          onChange={handleChange}
          name="otherValues"
          label="Other Values"
          {...{ sx: { height: '100%' } }}
        />
      </Grid>
      <Grid item mobile={12} tablet={12} laptop={4}>
        <Grid
          container
          direction={{ mobile: 'column', tablet: 'row', laptop: 'column' }}
          spacing={1}
        >
          <Grid container item mobile={12} tablet={4} laptop={12}>
            <Grid item mobile={12}>
              <Checkbox
                checked={initialOffer.pressureSensor.enabled}
                onChange={handleCheckBoxChanges}
                name="checkBox-pressureSensor"
              >
                Pressure Sensor
              </Checkbox>
            </Grid>
            <Grid item mobile={12}>
              <Checkbox
                checked={initialOffer.humiditySensor.enabled}
                onChange={handleCheckBoxChanges}
                name="checkBox-humiditySensor"
              >
                Humidity Sensor
              </Checkbox>
            </Grid>
            <Grid item mobile={12}>
              <TextField
                multiline
                fullWidth
                value={initialOffer.humiditySensor.values}
                onChange={handleChangeSensor}
                name="humiditySensor"
                label="Humidity Sensor"
                maxRows={5}
              />
            </Grid>
          </Grid>
          <Grid container item mobile={12} tablet={4} laptop={12}>
            <Grid item mobile={12}>
              <Grid container direction="column">
                <Grid item mobile={12}>
                  <Checkbox
                    checked={initialOffer.temperatureSensor.enabled}
                    onChange={handleCheckBoxChanges}
                    name="checkBox-temperatureSensor"
                  >
                    Temperature Sensor
                  </Checkbox>
                </Grid>
                <Grid item mobile={12}>
                  <TextField
                    multiline
                    fullWidth
                    value={initialOffer.temperatureSensor.values}
                    onChange={handleChangeSensor}
                    label="Temperature Sensor"
                    name="temperatureSensor"
                    maxRows={5}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item mobile={12} tablet={4} laptop={12}>
            <Grid item mobile={12}>
              <Checkbox
                checked={initialOffer.shockSensor.enabled}
                onChange={handleCheckBoxChanges}
                name="checkBox-shockSensor"
              >
                Shock Sensor
              </Checkbox>
            </Grid>
            <Grid item mobile={12}>
              <TextField
                multiline
                fullWidth
                value={initialOffer.shockSensor.values}
                onChange={handleChangeSensor}
                label="Shock Sensor"
                name="shockSensor"
                maxRows={5}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default OfferConfigurationForm;
