import { find } from 'lodash';
import has from 'lodash/has';

import dates, { formatISODate } from '@/helpers/dates';

const productsPostProcessor = (data, articles) => {
  if (!data) {
    return {};
  }
  const { total } = data;
  const items = data.extended_product_states || data.items || [];

  const parsedItems = items
    .map((item) => {
      const { extra_information, product_state } = item;

      return { ...extra_information, ...product_state };
    })
    .map((item) => {
      const articleFindKey = item.articleCode || item.article_code;

      return {
        ...item,
        parsedCreatedAt: has(item, 'createdAt')
          ? dates.parseDate(item.createdAt)
          : '',
        parsedUpdatedAt: has(item, 'updatedAt')
          ? dates.parseDate(item.updatedAt)
          : '',
        parsedManufacturingDate: has(item, 'manufacturingDate')
          ? dates.parseDate(item.manufacturingDate)
          : '',
        contract_state_updated_at: item.contract_state_updated_at
          ? formatISODate(item.contract_state_updated_at)
          : '',
        partner_since: item.partner_since
          ? formatISODate(item.partner_since)
          : '',
        sim_status_updated_at: item.sim_status_updated_at
          ? formatISODate(item.sim_status_updated_at)
          : '',
        productDescription:
          find(articles, ['code', articleFindKey])?.description || 'Unknown',
      };
    });

  return { ...{ total }, items: parsedItems };
};

export default productsPostProcessor;
