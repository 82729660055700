import { useCallback, useEffect, useState } from 'react';

export interface FetchResourceStateIdle {
  state: 'idle';
}

export interface FetchResourceStateLoading {
  state: 'loading';
}

export interface FetchResourceStateLoadSuccess<Resource> {
  state: 'success';
  resource: Resource;
}

export interface FetchResourceStateLoadError<Error> {
  state: 'error';
  error: Error;
}

type StandardError = Error;

export type FetchResourceState<Resource, Error = StandardError> =
  | FetchResourceStateIdle
  | FetchResourceStateLoading
  | FetchResourceStateLoadSuccess<Resource>
  | FetchResourceStateLoadError<Error>;

export interface UseFetchResourceOptions {
  enabled?: boolean;
}

const DEFAULT_OPTIONS = { enabled: true };

export interface UseFetchResource<Resource, Error = globalThis.Error> {
  result: FetchResourceState<Resource, Error>;
  invalidate: () => void;
}

export function useFetchResource<Resource, Error = globalThis.Error>(
  fetchFunction: () => Promise<Resource>,
  options?: UseFetchResourceOptions
): UseFetchResource<Resource, Error> {
  const [state, setState] = useState<FetchResourceState<Resource, Error>>({
    state: 'idle',
  });

  const effectiveOptions =
    options === undefined
      ? DEFAULT_OPTIONS
      : { ...DEFAULT_OPTIONS, ...options };

  const startFetch = useCallback(() => {
    setState({ state: 'loading' });
    fetchFunction()
      .then((resource) => setState({ state: 'success', resource }))
      .catch((error) => setState({ state: 'error', error }));
  }, [fetchFunction]);

  const invalidate = startFetch;

  useEffect(() => {
    if (effectiveOptions.enabled) startFetch();
  }, [startFetch, effectiveOptions.enabled]);

  return { result: state, invalidate };
}
