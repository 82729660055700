import React from 'react';

import styled from 'styled-components';

import Error from './Error';
import useAppState from '../hooks/useAppState';

const ErrorWrapper = styled.div`
  margin-top: 0.25rem;
  margin-left: 0.125rem;
`;

interface IScopedCta {
  requiredScopes: string[];
  component: any;
  children?: any;
}

const ScopedCta = ({
  requiredScopes,
  component: Component,
  children,
  ...rest
}: IScopedCta & any) => {
  const userScopes = useAppState((state) => state.scopes);

  if (
    !requiredScopes ||
    requiredScopes.length === 0 ||
    requiredScopes.some((val) => userScopes.includes(val))
  ) {
    return <Component {...rest}>{children}</Component>;
  }

  return (
    <div>
      <Component {...rest} disabled={true}>
        {children}
      </Component>
      <ErrorWrapper>
        <Error message="You lack permissions!" />
      </ErrorWrapper>
    </div>
  );
};

export default ScopedCta;
