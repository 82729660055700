import React from 'react';

import { Grid } from '@material-ui/core';

import LPShopDetailsContent from '../components/LPShopDetailsContent';
import PageTitle from '@/components/layout/PageTitle';

const LPShopDetails = () => (
  <Grid container justifyContent="center" spacing={3}>
    <Grid item xs={10}>
      <PageTitle title="LP SHOP DETAILS" />
    </Grid>
    <Grid item xs={10}>
      <LPShopDetailsContent />
    </Grid>
  </Grid>
);

export default LPShopDetails;
