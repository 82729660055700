import React, { useCallback, useMemo, useState } from 'react';

import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

import useContributionStore from '../../../hooks/saContributions/useContributionStore';
import useModifyContribution from '../../../hooks/saContributions/useModifyContribution';
import CustomModal from '@/components/CustomModal';
import InputIdForm from '@/components/InputIdForm';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';

const StyledButton = styled(PillButton)`
  margin-left: 1rem;
`;

const statusData: (string) =>
  | {
      buttonText: string;
      newPaymentStatus: string;
      buttonIcon: JSX.Element;
      buttonType: 'primary' | 'ghost';
      testid?: string;
    }[]
  | null = (paymentStatus) => {
  switch (paymentStatus) {
    case 'pending':
      return [
        {
          buttonText: 'Mark payment as completed',
          newPaymentStatus: 'completed',
          buttonIcon: <CheckOutlined />,
          buttonType: 'primary',
        },
      ];
    case 'completed':
      return [
        {
          buttonText: 'Initiate a refund',
          newPaymentStatus: 'refund_initiated',
          buttonIcon: <RedoOutlined />,
          buttonType: 'ghost',
        },
      ];
    case 'refund_requested':
      return [
        {
          buttonText: 'Initiate a refund',
          newPaymentStatus: 'refund_initiated',
          buttonIcon: <RedoOutlined />,
          buttonType: 'ghost',
        },
        {
          buttonText: 'Cancel refund request',
          newPaymentStatus: 'completed',
          buttonIcon: <CloseOutlined />,
          buttonType: 'ghost',
        },
      ];
    case 'refund_initiated':
      return [
        {
          buttonText: 'Cancel refund',
          newPaymentStatus: 'completed',
          buttonIcon: <CloseOutlined />,
          buttonType: 'ghost',
          testid: 'modify-button-cancel-refund',
        },
        {
          buttonText: 'Mark refund as completed',
          newPaymentStatus: 'refunded',
          buttonIcon: <CheckCircleOutlined />,
          buttonType: 'primary',
        },
      ];
    default:
      return null;
  }
};

const ModifyPayment = () => {
  const contributionLoading = useContributionStore((state) => state.loading);
  const contribution = useContributionStore((state) => state.contribution);

  const [showModal, setShowModal] = useState(false);
  const [inputtedId, setInputtedId] = useState({
    data: '',
    touched: false,
  });

  const { paymentStatus, id } = contribution;

  const hasError = inputtedId.data.trim() !== id;

  const [newPaymentStatus, setNewPaymentStatus] = useState(null);

  const [{ loading: requestLoading }, modifyContribution] =
    useModifyContribution();

  const handleSubmit = async () => {
    if (!newPaymentStatus) {
      return;
    }
    const { success } = await modifyContribution(newPaymentStatus);
    if (success) {
      setShowModal(false);
    }
  };

  const handleClick = useCallback(
    (newPaymentStatus) => {
      setNewPaymentStatus(newPaymentStatus);
      setShowModal(true);
    },
    [setShowModal]
  );

  const handleCancel = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const afterClose = useCallback(() => {
    setInputtedId({ data: '', touched: false });
  }, [setInputtedId]);

  const statusButtons = useMemo(
    () =>
      statusData(paymentStatus)?.map((s) => {
        const { buttonIcon, buttonType, buttonText, newPaymentStatus, testid } =
          s;

        return (
          <ScopedCta
            component={StyledButton}
            data-testvalue={newPaymentStatus}
            key={buttonText}
            type={buttonType}
            icon={buttonIcon}
            shape="round"
            loading={requestLoading}
            onClick={() => handleClick(newPaymentStatus)}
            data-testid={testid ? testid : 'modify-button'}
            disabled={contributionLoading}
            requiredScopes={[scopes.EDIT_SHARING_ANGELS]}
          >
            {buttonText}
          </ScopedCta>
        );
      }),
    [contributionLoading, handleClick, paymentStatus, requestLoading]
  );

  if (!statusData(paymentStatus)) {
    return <div data-testid="invalid-status" />;
  }

  return (
    <>
      {statusButtons}
      <CustomModal
        open={showModal}
        onCancel={handleCancel}
        title={`You are about to change payment status to "${newPaymentStatus}"`}
        okText="Modify Payment Status"
        cancelText="Close"
        okButtonProps={{
          danger: true,
          disabled: hasError,
          loading: requestLoading,
        }}
        width="30%"
        onOk={handleSubmit}
        afterClose={afterClose}
        destroyOnClose
      >
        <InputIdForm
          placeholder="Contribution-ID"
          text={
            "To modify the payment status of the contribution, please enter the contribution's ID:"
          }
          hasError={hasError}
          inputtedId={inputtedId}
          setId={setInputtedId}
          inputName="modify-modal"
        />
      </CustomModal>
    </>
  );
};

export default ModifyPayment;
