import styled from 'styled-components';

interface SVGProps {
  cursor?: string;
}

const SVG = styled.svg<SVGProps>`
  cursor: ${(props) => (props.cursor ? props.cursor : 'default')};
  display: inline-block;
  transform-origin: center center;
`;
export default SVG;
