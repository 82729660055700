import has from 'lodash/has';

import datatransforms from '@/helpers/datatransforms';
import dates from '@/helpers/dates';

const shipmentsPostProcessor = (data) => {
  if (!data) {
    return {};
  }
  const parsedItems = data.items?.map((item) => ({
    ...item,
    parsedCreatedAt: has(item, 'createdAt')
      ? dates.parseDate(item.createdAt)
      : '',
    parsedUpdatedAt: has(item, 'updatedAt')
      ? dates.parseDate(item.updatedAt)
      : '',
    boxId: item.box.id,
    recipientSender: [
      datatransforms.concatNames(item.recipient.address),
      datatransforms.concatNames(item.sender.address),
    ],
    carrierCode: item.carrier.carrierCode,
  }));

  return { ...data, items: parsedItems };
};

export default shipmentsPostProcessor;
