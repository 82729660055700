import React, { useCallback, useEffect } from 'react';

import { Row, Col } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import get from 'lodash/get';
import styled from 'styled-components';

import CreateApiKey from './CreateApiKey';
import useApiKeyStore, {
  TLPApiKeySortBy,
  rowDataSelector,
  loadingSelector,
  pageSizeSelector,
  offsetSelector,
  totalSelector,
  defaultSortSelector,
  dispatchSelector,
} from '../hooks/useApiKeyStore';
import useDeleteApiKey from '../hooks/useDeleteApiKey';
import useGetApiKeys from '../hooks/useGetApiKeys';
import { IApiKey } from '../models/apiKey';
import StyledTable from '@/components/tables/StyledTable';
import {
  getCurrentPage,
  getOffset,
  parseAntdSorter,
} from '@/helpers/antdTable';
import useAntdColumns from '@/hooks/useAntdColumns';
import useWindowSize from '@/hooks/useWindowSize';

const MbRow = styled(Row)`
  margin-bottom: 1rem;
`;

const PartnerApiKeyTable = ({ partnerID }) => {
  const getApiKeys = useGetApiKeys();
  const windowSize = useWindowSize();
  const rowData = useApiKeyStore(rowDataSelector);
  const loading = useApiKeyStore(loadingSelector);
  const pageSize = useApiKeyStore(pageSizeSelector);
  const offset = useApiKeyStore(offsetSelector);
  const total = useApiKeyStore(totalSelector);
  const defaultSort = useApiKeyStore(defaultSortSelector);
  const dispatch = useApiKeyStore(dispatchSelector);
  const [, deleteApiKey] = useDeleteApiKey(partnerID);

  useEffect(() => {
    const fetchApiKeys = async () => await getApiKeys(partnerID);
    fetchApiKeys();
  }, [getApiKeys, partnerID]);

  const handleEntryDelete = useCallback(
    async (e: any, entry: IApiKey) => {
      await deleteApiKey(entry.id);
      await getApiKeys(partnerID);
    },
    [deleteApiKey, getApiKeys, partnerID]
  );

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: ['apiKeyId', 'description'],
    defaultSort: defaultSort,
    addDefaultColumns: true,
    deleteIconProps: { onClick: handleEntryDelete, isPopover: true },
  });

  const pagination = {
    current: getCurrentPage(pageSize, offset),
    pageSize,
    total,
    showSizeChanger: true,
  };

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const [sortField, sortOrder] = parseAntdSorter(sorter);
      dispatch({
        type: 'updateSorting',
        args: { sortBy: sortField as TLPApiKeySortBy, order: sortOrder },
      });
      dispatch({
        type: 'updatePagination',
        args: {
          offset: getOffset(pagination.pageSize, pagination.current),
          pageSize: pagination.pageSize,
        },
      });
    },
    [dispatch]
  );

  return (
    <>
      <MbRow justify="end">
        <Col>
          <CreateApiKey partnerId={partnerID} />
        </Col>
      </MbRow>
      <Row>
        <Col>
          <StyledTable
            rowKey="id"
            dataSource={rowData}
            columns={columns}
            size="small"
            pagination={pagination}
            loading={loading}
            scroll={{ y: get(windowSize, 'height', 0) * 0.65 }}
            onChange={handleTableChange}
          />
        </Col>
      </Row>
    </>
  );
};

export default PartnerApiKeyTable;
