import React from 'react';

import styled from 'styled-components';

import H2 from './text/H2';

const Wrapper = styled.div`
  width: 100%;
`;

const Border = styled.hr`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.black};
  box-shadow: none;
  margin-top: 0.15rem;
  margin-bottom: 1rem;
`;

const SectionHeader = (props: { title: string }) => (
  <Wrapper>
    <H2>{props.title}</H2>
    <Border />
  </Wrapper>
);

export default SectionHeader;
