import React from 'react';

import { HStack } from '@chakra-ui/react';

import BoxConfigHistoryTable from './BoxConfigHistoryTable';
import BoxConfigEdit from '../components/BoxConfigEdit';
import useBoxConfigHistory from '../hooks/useBoxConfigHistory';
import { useBoxConfiguration } from '../hooks/useBoxConfiguration';
import { useUpdateBoxConfiguration } from '../hooks/useUpdateBoxConfiguration';
import Loader from '@/components/Loader';
import useError from '@/hooks/useError';
import { usePagination } from '@/hooks/usePagination';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const BoxConfig = ({ boxId }: { boxId: string }) => {
  const { result, invalidate: fetchConfiguration } = useBoxConfiguration(
    boxId!
  );
  const updateBoxConfiguration = useUpdateBoxConfiguration();

  const addError = useError();
  const successMsg = useToasts((state) => state.addItem);

  const { params: paginationParams, setParams: setPaginationParams } =
    usePagination({
      page: 1,
      pageSize: 10,
      orderDirection: 'DESC',
      orderColumn: 'createdAt',
    });

  const { result: boxConfigHistory, invalidate: fetchConfigHistory } =
    useBoxConfigHistory(boxId!, paginationParams);

  if (result.state === 'idle' || result.state === 'loading') {
    return <Loader size={32} />;
  }

  if (result.state === 'error') return <>Error fetching configuration.</>;

  const { resource: boxConfiguration } = result;

  return (
    <HStack spacing={8} align="start">
      <BoxConfigEdit
        initialValue={JSON.stringify(boxConfiguration, null, 2)}
        onSave={async (newBoxConfig) => {
          try {
            await updateBoxConfiguration(boxId!, JSON.parse(newBoxConfig));
          } catch (e) {
            addError('Could not update box config', { trackError: false });

            return;
          }
          fetchConfigHistory();
          fetchConfiguration();
          successMsg(
            { msg: 'Successfully updated config', type: MessageType.Success },
            'root',
            {}
          );
        }}
      />
      <BoxConfigHistoryTable
        boxConfigHistory={boxConfigHistory}
        paginationParams={paginationParams}
        setPaginationParams={setPaginationParams}
      />
    </HStack>
  );
};

export default BoxConfig;
