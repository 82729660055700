import { ILPAccountUser } from '@/features/lp-account-users';
import { IPartner, IShop } from '@/features/lp-village';

export interface IBox {
  assemblyDone: boolean;
  batterySerialNumber: string;
  bleStackVersion: string;
  bluetoothMacAddress: string;
  certificateID: string;
  createdAt: number;
  ePaperDisplay: string;
  electronicCircuitBoard: string;
  id: string;
  imei: string;
  isVirtual: boolean;
  manufacturingDate: number;
  productionLineNumber: string;
  productionOrder: string;
  status: string;
  tabletSerialNumber: string;
  thingName: string;
  updatedAt: number;
  wifiMacAddress: string;
  lpUI: string;
  firmwareVersion: string;
  cortexFirmwareVersion: string;
  productDescription: string;
  cortexBootVersion: string;
  minusFirmwareVersion: string;
  minusBootVersion: string;
  modemFirmwareVersion: string;
  bleFusVersion: string;
  wifiFirmwareVersion: string;
  ArticleEmbeddedCode: Number | null;
}

export interface IModifiedBox extends IBox {
  parsedCreatedAt: string;
  parsedManufacturingDate: string;
  parsedUpdatedAt: string;
}

export type TRelationTypes = (typeof RELATION_TYPES)[number];
export type TObjectTypes = (typeof OBJECT_TYPES)[number];

export const RELATION_TYPES = ['HOLDER', 'PIC', 'LOCATION', 'OWNER'] as const;
export const OBJECT_TYPES = ['LP_ACCOUNT', 'LP_PARTNER', 'LP_SHOP'] as const;

export interface IRelation {
  id: string;
  objectType: TObjectTypes;
  object: IPartner | IShop | ILPAccountUser;
  relationType: TRelationTypes;
  activeSince: number;
  activeUntil: number;
  createdAt: number;
}

export const INITIAL_Box: IBox = {
  assemblyDone: false,
  batterySerialNumber: '',
  bleStackVersion: '',
  bluetoothMacAddress: '',
  certificateID: '',
  createdAt: 0,
  ePaperDisplay: '',
  electronicCircuitBoard: '',
  id: '',
  imei: '',
  isVirtual: false,
  manufacturingDate: 0,
  productionLineNumber: '',
  productionOrder: '',
  status: '',
  tabletSerialNumber: '',
  thingName: '',
  updatedAt: 0,
  wifiMacAddress: '',
  lpUI: '',
  firmwareVersion: '',
  cortexFirmwareVersion: '',
  productDescription: '',
  cortexBootVersion: '',
  minusFirmwareVersion: '',
  minusBootVersion: '',
  modemFirmwareVersion: '',
  bleFusVersion: '',
  wifiFirmwareVersion: '',
  ArticleEmbeddedCode: null,
};

export const INITIAL_ModifiedBox: IModifiedBox = {
  ...INITIAL_Box,
  parsedManufacturingDate: '',
  parsedCreatedAt: '',
  parsedUpdatedAt: '',
};

export interface IArticle {
  code: string;
  embeddedCode: number;
  description: string;
}
