import { useCallback } from 'react';

import useApiKeyStore from './useApiKeyStore';
import lpApiKeysPostProcessor from '../helpers/lpApiKeysPostProcessor';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useCreateApiKey: any = (partnerId) => {
  const addError = useError();
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpVillage');
  const successMsg = useToasts((state) => state.addItem);

  const dispatch = useApiKeyStore((state) => state.dispatch);
  const order = useApiKeyStore((state) => state.order);
  const sortBy = useApiKeyStore((state) => state.sortBy);
  const offset = useApiKeyStore((state) => state.offset);
  const pageSize = useApiKeyStore((state) => state.pageSize);

  const getApiKeys = useCallback(async () => {
    const { data, error } = await getPaginatedTableData({
      params: {
        path: `/admin/partners/${partnerId}/apikeys`,
        method: 'get',
      },
      reducer: lpApiKeysPostProcessor,
      dispatch,
      addError,
      makeRequest,
      queryParams: Object.assign({
        pageSize: pageSize,
        offset: offset,
        sortBy: sortBy,
        order: order,
      }),
      queryParamsOptions: { skipEmptyString: true, skipNull: true },
    });

    return { data, error };
  }, [
    addError,
    dispatch,
    makeRequest,
    order,
    sortBy,
    offset,
    pageSize,
    partnerId,
  ]);

  const createApiKey = async (apiKey) => {
    const { data, error } = await makeRequest({
      path: `/admin/partners/${partnerId}/apikeys`,
      method: 'post',
      body: apiKey,
    });
    if (error) {
      addError(error);

      return { success: false };
    }
    await getApiKeys();
    successMsg(
      { msg: 'API key was successfully created', type: MessageType.Success },
      'root',
      {}
    );

    return { success: true, ...data };
  };

  return [{ loading }, createApiKey];
};

export default useCreateApiKey;
