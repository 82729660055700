import { object, string } from 'yup';

export const PartnerUpdateSchema = object().shape({
  phone_number: string(),
  partner_id_card: object().shape({
    client_name: string().required('Client name is required'),
    email: string().email('The email is not valid'),
    country_code: string()
      .matches(
        /^[A-Z]{2}$/,
        'Country code must be 2 alphabetic uppercase characters'
      )
      .required('Country code is required'),
  }),
});

export const CreateAddressSchema = object().shape({
  company: string().required('Identification name is required'),
  street: string().required('Address is required'),
  postalCode: string().required('Zipcode is required'),
  city: string().required('City / Town is required'),
  countryCode: string().required('Country is required'),
  email: string().email(),
});
