import React from 'react';

import SVG from './SVG';

const ToIcon = (props) => (
  <SVG width="1em" height="1em" viewBox="0 0 17 16" {...props}>
    <path
      d="M16.5 5.75v10H.25v-10H1.5v8.75h13.75V5.75h1.25zm-8.75 2.5V.75H9v7.5h3.75l-4.375 4.375L4 8.25h3.75z"
      fill="#000"
      fillRule="evenodd"
    />
  </SVG>
);

export default ToIcon;
