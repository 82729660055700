import { useCallback } from 'react';

import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

export enum RemoteCommand {
  COMMAND_TYPE_UNSPECIFIED = 'COMMAND_TYPE_UNSPECIFIED', // unspecified
  COMMAND_TYPE_LOCK_UNLOCK = 'COMMAND_TYPE_LOCK_UNLOCK', //lock - unlock
  COMMAND_TYPE_RESET = 'COMMAND_TYPE_RESET', //reset
  COMMAND_TYPE_RECEIVED = 'COMMAND_TYPE_RECEIVED', //received
  COMMAND_TYPE_START_INITIAL_SHIPMENT = 'COMMAND_TYPE_START_INITIAL_SHIPMENT', //start initial shipment
  COMMAND_TYPE_START_RETURN_SHIPMENT = 'COMMAND_TYPE_START_RETURN_SHIPMENT', //start return shipment
  COMMAND_TYPE_CONFIRM_SHIPMENT = 'COMMAND_TYPE_CONFIRM_SHIPMENT', //confirm shipment
  COMMAND_TYPE_CANCEL_SHIPMENT = 'COMMAND_TYPE_CANCEL_SHIPMENT', //cancel shipment
  COMMAND_TYPE_CLEANUP = 'COMMAND_TYPE_CLEANUP', //cleanup
}
export enum REMOTE_COMMAND_ERRORS {
  DIRECT_MODE_DISABLED = 'deviceProductShouldBeInDirectMode',
  COMMAND_ALREADY_RUN = 'deviceProductAlreadyHaveProgressingJob',
}

const useRemoteControl = (lpui: string | undefined) => {
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('productV3');

  const getConnectivity = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: `/${lpui}/connectivity/lifecycle`,
      method: 'GET',
      body: {},
    });
    if (error) {
      console.error('ERROR', error);
    }
    if (data) {
      if (data.is_connected && data.is_direct_mode) {
        return data;
      }
    }
  }, [makeRequest, lpui]);

  const sendRemoteAction = useCallback(
    async (lpui, shipmentId, action) => {
      const { error } = await makeRequest({
        path: `/${lpui}/remote-commands`,
        method: 'post',
        body: {
          shipment_id: shipmentId,
          type: action,
        },
      });

      if (error) {
        return error.response.data;
      }
    },
    [makeRequest]
  );

  const getRemoteActionList = useCallback(
    async (lpui) => {
      const { error, data } = await makeRequest({
        path: `/${lpui}/remote-commands`,
        method: 'get',
        body: {},
      });

      if (error) {
        console.error(error);
      }

      if (data) {
        return data.remote_commands;
      }
    },
    [makeRequest]
  );

  const getProductState = useCallback(
    async (lpui) => {
      const { error, data } = await makeRequest({
        path: `/${lpui}/state`,
        method: 'get',
        body: {},
      });

      if (error) {
        console.error(error);
      }

      if (data) {
        return data;
      }
    },
    [makeRequest]
  );

  return {
    getConnectivity,
    loading,
    sendRemoteAction,
    getRemoteActionList,
    getProductState,
  };
};

export default useRemoteControl;
