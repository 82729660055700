import { useCallback } from 'react';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useBuyShipmentQuote = () => {
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('shipment');
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);

  const buyQuote = useCallback(
    async ({
      quoteId,
      serviceLevelId,
      shipmentId,
      versionHash,
    }: {
      quoteId: string;
      serviceLevelId: string;
      shipmentId: string;
      versionHash: string;
    }) => {
      const body = !serviceLevelId
        ? {
            versionHash,
            quoteId,
          }
        : {
            versionHash,
            quoteId,
            serviceLevelId,
          };
      const { error } = await makeRequest({
        path: `/shipments/${shipmentId}/buy`,
        method: 'post',
        body: body,
      });
      if (error) {
        addError(error);

        return { success: false };
      }
      addSuccessMsg(
        { msg: 'Quote was successfully bought!', type: MessageType.Success },
        'root',
        {}
      );

      return { success: true };
    },
    [addSuccessMsg, addError, makeRequest]
  );

  return { loading, buyQuote };
};

export default useBuyShipmentQuote;
