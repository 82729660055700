import React, { useCallback, useState } from 'react';

import { HStack } from '@chakra-ui/react';
import { Grid } from '@material-ui/core';
import { ColumnProps } from 'antd/lib/table';
import get from 'lodash/get';
import styled from 'styled-components';

import { IRole } from '../../roles/models/role';
import AddScopeToRole from '../../scopes/components/AddScopeToRole';
import CreateScope from '../../scopes/components/CreateScope';
import CreateRole from '../components/CreateRole';
import DeleteRole from '../components/DeleteRole';
import EditRole from '../components/EditRole';
import useModifyScopes from '../components/useModifyScopes';
import useGetRoles from '../hooks/useGetRoles';
import useRolesStore, { TLPRolesSortBy } from '../hooks/useRolesStore';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import StyledTable from '@/components/tables/StyledTable';
import { scopes } from '@/config';
import {
  getCurrentPage,
  getOffset,
  parseAntdSorter,
} from '@/helpers/antdTable';
import FormatListTags from '@/helpers/FormatListTags';
import useAntdColumns from '@/hooks/useAntdColumns';
import useWindowSize from '@/hooks/useWindowSize';

const ButtonWrapper = styled(PillButton)`
  margin-left: 8px;
`;

const LPRolesTable = () => {
  useGetRoles();
  const rowData = useRolesStore((state) => state.rowData);
  const loading = useRolesStore((state) => state.loading);
  const pageSize = useRolesStore((state) => state.pageSize);
  const offset = useRolesStore((state) => state.offset);
  const total = useRolesStore((state) => state.total);
  const defaultSort = useRolesStore((state) => state.defaultSort);
  const dispatch = useRolesStore((state) => state.dispatch);
  const [role, setRole] = useState<
    Pick<IRole, 'id' | 'name' | 'description' | 'scopes'>
  >({
    id: '',
    name: '',
    description: '',
    scopes: [],
  });
  const [visibleAddScope, setVisibleAddScope] = useState(false);
  const openModalAddScope = useCallback(() => setVisibleAddScope(true), []);
  const closeModalAddScope = useCallback(() => setVisibleAddScope(false), []);
  const [visibleEditRole, setVisibleEditRole] = useState(false);
  const openModalEditRole = useCallback(() => setVisibleEditRole(true), []);
  const closeModalEditRole = useCallback(() => setVisibleEditRole(false), []);
  const windowSize = useWindowSize();

  const [
    modifiedRowData,
    editScopes,
    handleEditScopes,
    handleCloseTag,
    handleResetScopes,
    handleSaveDeletedScopes,
  ] = useModifyScopes(rowData);

  const handleAddScope = useCallback(
    (role) => {
      setRole(role);
      openModalAddScope();
    },
    [openModalAddScope]
  );

  const handleEditRole = useCallback(
    (role) => {
      setRole(role);
      openModalEditRole();
    },
    [openModalEditRole]
  );

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: ['roleId', 'name', 'type', 'description', 'scopes'],
    addDefaultColumns: true,
    defaultSort: defaultSort,
    columnsSpecialProps: {
      scopes: {
        sorter: true,
        render: (text, record) =>
          record.scopes && (
            <FormatListTags
              data={record.scopes}
              closable={editScopes}
              onClose={handleCloseTag}
              role={record}
            />
          ),
      },
    },
    addIconProps: {
      title: 'Add scopes',
      requiredScopes: [scopes.EDIT_LPVILLAGE_ROLES],
      onClick: (role) => handleAddScope(role),
    },
    editIconProps: {
      title: 'Edit role',
      requiredScopes: [scopes.EDIT_LPVILLAGE_ROLES],
      onClick: (role) => handleEditRole(role),
    },
  });

  const pagination = {
    current: getCurrentPage(pageSize, offset),
    pageSize,
    total,
    showSizeChanger: true,
  };

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const [sortField, sortOrder] = parseAntdSorter(sorter);
      dispatch({
        type: 'updateSorting',
        args: { sortBy: sortField as TLPRolesSortBy, order: sortOrder },
      });
      dispatch({
        type: 'updatePagination',
        args: {
          offset: getOffset(pagination.pageSize, pagination.current),
          pageSize: pagination.pageSize,
        },
      });
    },
    [dispatch]
  );

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid
        container
        item
        xs={11}
        style={{ justifyContent: 'flex-end' }}
        spacing={2}
      >
        <Grid item>
          {editScopes && (
            <HStack>
              <ButtonWrapper type="default" onClick={handleResetScopes}>
                Cancel
              </ButtonWrapper>
              <ScopedCta
                component={ButtonWrapper}
                onClick={handleSaveDeletedScopes}
                requiredScopes={[scopes.EDIT_LPVILLAGE_SCOPES]}
              >
                Save
              </ScopedCta>
            </HStack>
          )}
          {!editScopes && (
            <ScopedCta
              component={ButtonWrapper}
              onClick={handleEditScopes}
              requiredScopes={[scopes.MANAGE_LPVILLAGE_SCOPES]}
            >
              Remove Scopes
            </ScopedCta>
          )}
        </Grid>
        <Grid item>
          <CreateRole />
        </Grid>
        <Grid item>
          <CreateScope />
        </Grid>
        <Grid item>
          <DeleteRole />
        </Grid>
      </Grid>
      <Grid item xs={11}>
        <StyledTable
          rowKey="id"
          dataSource={modifiedRowData.length ? modifiedRowData : rowData}
          columns={columns}
          size="small"
          pagination={pagination}
          loading={loading}
          scroll={{ y: get(windowSize, 'height', 0) * 0.65 }}
          onChange={handleTableChange}
        />
      </Grid>

      <AddScopeToRole
        role={role}
        closeModal={closeModalAddScope}
        visible={visibleAddScope}
      />
      <EditRole
        originalRole={role}
        visible={visibleEditRole}
        closeModal={closeModalEditRole}
      />
    </Grid>
  );
};

export default LPRolesTable;
