import { useContext, useMemo } from 'react';

import useBoxesStore from './useBoxesStore';
import boxesPostProcessor from '../helpers/boxesPostProcessor';
import { IArticle } from '../models/box';
import { validationErrorParser } from '@/helpers/beErrorMsgParser';
import usePaginatedTableData from '@/hooks/usePaginatedTableData';
import { ArticlesContext } from '@/shared/providers/articlesProvider';

const parsingOptions = {
  errorParser: validationErrorParser,
  trackError: false,
};

const useGetBoxes = (partnerId = '') => {
  const dispatch = useBoxesStore((state) => state.dispatch);
  const order = useBoxesStore((state) => state.order);
  const sortBy = useBoxesStore((state) => state.sortBy);
  const offset = useBoxesStore((state) => state.offset);
  const pageSize = useBoxesStore((state) => state.pageSize);
  const searchTerms = useBoxesStore((state) => state.searchTerms);
  const articles = useContext(ArticlesContext) as IArticle[];
  const productLifecycleStatuses = useBoxesStore(
    (state) => state.productLifecycleStatuses
  );
  const productDescription = useBoxesStore((state) => state.productDescription);

  const queryParams = useMemo(() => {
    if (partnerId) {
      return {
        pageSize,
        offset,
        sortBy,
        order,
        ...searchTerms,
        productLifecycleStatuses,
        productDescription,
        partnerId,
      };
    }

    return {
      pageSize,
      offset,
      sortBy,
      order,
      ...searchTerms,
      ...(productLifecycleStatuses && { productLifecycleStatuses }),
      ...(productDescription && { articleCode: productDescription }),
    };
  }, [
    offset,
    order,
    pageSize,
    partnerId,
    searchTerms,
    sortBy,
    productLifecycleStatuses,
    productDescription,
  ]);

  usePaginatedTableData({
    params: {
      path: '/boxes',
      method: 'get',
      reducer: boxesPostProcessor,
      reducerExtra: articles,
    },
    dispatch,
    queryParams,
    queryParamsOptions: { skipEmptyString: true },
    service: 'device',
    errorParsingOptions: parsingOptions,
  });
};

export default useGetBoxes;
