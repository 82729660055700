import React from 'react';

import { Descriptions, Typography } from 'antd';

const BoxHardware = ({ box }: any) => (
  <Descriptions bordered size="middle">
    <Descriptions.Item label="Tablet SN">
      <Typography.Text>{box?.tabletSerialNumber}</Typography.Text>
    </Descriptions.Item>
    <Descriptions.Item label="BLE Mac Address ">
      <Typography.Text>{box?.bluetoothMacAddress}</Typography.Text>
    </Descriptions.Item>
    <Descriptions.Item label="Wifi Mac Address">
      <Typography.Text>{box?.wifiMacAddress}</Typography.Text>
    </Descriptions.Item>
    <Descriptions.Item label="IMEI">
      <Typography.Text>{box?.imei}</Typography.Text>
    </Descriptions.Item>
    <Descriptions.Item label="IMSI">
      <Typography.Text>{box?.imsi}</Typography.Text>
    </Descriptions.Item>
    <Descriptions.Item label="ICCID">
      <Typography.Text>{box?.iccid}</Typography.Text>
    </Descriptions.Item>
    <Descriptions.Item label="PCBA SN">
      <Typography.Text>{box?.electronicCircuitBoard}</Typography.Text>
    </Descriptions.Item>
    <Descriptions.Item label="Battery SN">
      <Typography.Text>{box?.batterySerialNumber}</Typography.Text>
    </Descriptions.Item>
    <Descriptions.Item label="Camera SN">
      <Typography.Text>{box?.cameraSerialNumber}</Typography.Text>
    </Descriptions.Item>
    <Descriptions.Item label="EPaper LUT Version">
      <Typography.Text>{box?.ePaperLut}</Typography.Text>
    </Descriptions.Item>
    <Descriptions.Item label="EPaper Batch Number">
      <Typography.Text>{box?.ePaperBatch}</Typography.Text>
    </Descriptions.Item>
  </Descriptions>
);

export default BoxHardware;
