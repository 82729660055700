import { format, parseISO } from 'date-fns';
import get from 'lodash/get';

export const DATETIME_DISPLAY_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const DATE_DISPLAY_FORMAT = 'yyyy-MM-dd';

const parseDate = (unixTimestamp) => {
  if (typeof unixTimestamp !== 'number') return '';
  if (unixTimestamp === -62135596800) return '';
  const date = new Date(unixTimestamp * 1000);
  const formatted = formatDate(date);

  return formatted;
};

export function formatDate(date: Date): string {
  return format(date, DATETIME_DISPLAY_FORMAT);
}

export function formatISODate(
  isoDate,
  displayFormat = DATETIME_DISPLAY_FORMAT
): string {
  if (!isoDate) {
    return '';
  }

  return format(parseISO(isoDate), displayFormat);
}

const parseDateWithSeparator = (
  unixTimestamp: number,
  separator: string
): string => {
  const date = new Date(unixTimestamp * 1000);
  const formatted = format(
    date,
    `yy${separator}MM${separator}dd${separator}HH${separator}mm${separator}ss`
  );

  return get(formatted, 'error', formatted);
};

export const formatStringToDate = (
  date: string,
  displayFormat: string = DATETIME_DISPLAY_FORMAT
): string => {
  if (!date) return '';

  return format(parseISO(date), displayFormat);
};

const parseDateYYMMDD = (unixTimestamp: number, separator: string): string => {
  const date = new Date(unixTimestamp * 1000);
  const formatted = format(date, `yy${separator}MM${separator}dd`);

  return get(formatted, 'error', formatted);
};

const parseDateTime = (unixTimestamp: number, separator: string): string => {
  const date = new Date(unixTimestamp * 1000);
  const formatted = format(date, `HH${separator}mm${separator}ss`);

  return get(formatted, 'error', formatted);
};

const parseAllTrackingCodeDates = (codes) =>
  codes.map((code) => ({
    ...code,
    date: parseDate(code.createTime),
  }));

const parseSecondsDuration = (seconds) => {
  const data = Number(seconds);
  const d = Math.floor(data / (3600 * 24));
  const h = Math.floor((data % (3600 * 24)) / 3600);
  const m = Math.floor((data % 3600) / 60);
  const s = Math.floor((data % 3600) % 60);

  const dDisplay = d > 0 ? d + (d === 1 ? ' day, ' : ' days, ') : '';
  const hDisplay = h + (h === 1 ? ' hour, ' : ' hours, ');
  const mDisplay = m + (m === 1 ? ' minute, ' : ' minutes, ');
  const sDisplay = s + (s === 1 ? ' second' : ' seconds');

  return dDisplay + hDisplay + mDisplay + sDisplay;
};

export const momentToUtc = (momentInstance: moment.Moment) =>
  momentInstance.add(momentInstance.utcOffset(), 'minutes').unix();

const dateUtils = {
  formatDate,
  parseDate,
  parseDateYYMMDD,
  parseDateTime,
  parseDateWithSeparator,
  parseAllTrackingCodeDates,
  parseSecondsDuration,
};

export default dateUtils;
