import React from 'react';

import { Tabs } from 'antd';
import { TabPaneProps } from 'antd/es/tabs';

const { TabPane: OrigTabPane } = Tabs;

interface ITabPane extends TabPaneProps {
  currentTab?: string;
  identifier?: string;
  remount?: boolean;
}

const TabPane: React.FC<ITabPane> = ({
  currentTab,
  children,
  identifier,
  remount = false,
  ...rest
}) => {
  if (remount && (!currentTab || !identifier)) {
    console.warn(
      'TabPane: In order to remount the Tab you need to pass `currentTab` and `identifier` props!'
    );
  }
  if (!remount) {
    return <OrigTabPane {...rest}>{children}</OrigTabPane>;
  }

  return currentTab === identifier ? (
    <OrigTabPane {...rest}>{children}</OrigTabPane>
  ) : null;
};

export default TabPane;
