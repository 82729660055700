import { useCallback } from 'react';

import axios from 'axios';

import useAppState from '@/hooks/useAppState';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useImages = () => {
  const token = useAppState((state) => state.token);
  const addError = useError();
  const [, makeRequest] = useImperativeRequestWrapper('device');

  const postQualityCheckImages = useCallback(
    async (images: any[]) => {
      const bodyFormData = new FormData();
      images.forEach((img) => bodyFormData.append('files', img));

      const { data, error } = await makeRequest({
        path: `/quality-checks/images`,
        method: 'post',
        body: bodyFormData,
        headers: { 'content-type': 'multipart/form-data' },
      });
      if (error) {
        addError(error);

        return { success: false, data: null };
      }

      return { success: true, data };
    },
    [addError, makeRequest]
  );

  const getImage = useCallback(
    async (url: string) => {
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'arraybuffer',
      });
      const { data, headers } = res;
      if (!data) {
        addError('Error fetching the images you uploaded');

        return { success: false, data: null };
      }

      return { success: true, data: { data, headers } };
    },
    [addError, token]
  );

  return { postQualityCheckImages, getImage };
};

export default useImages;
