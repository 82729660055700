import pick from 'lodash/pick';

const getPrimaryAddress = async ({ accountId, makeRequest }) => {
  const { data } = await makeRequest({
    path: `/admin/addresses/byaccount/${accountId}?pageSize=100`,
    method: 'get',
  });
  if (data && data.items) {
    const addr =
      data.items.find((r) => r.isPrimary && r.types.includes('billing')) ||
      data.items.find((r) => r.isPrimary);
    if (addr) {
      return {
        ...pick(addr, [
          'firstName',
          'lastName',
          'company',
          'displayName',
          'street',
          'street2',
          'houseNumber',
          'postalCode',
          'city',
          'state',
          'countryCode',
        ]),
        isPrimary: false,
      };
    }
  }
};

export default getPrimaryAddress;
