import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import useImages from '../hooks/useImages';

const Wrapper = styled.img`
  height: 10rem;
  max-width: 100%;
  border-radius: 5px;
  margin-top: 1rem;
  margin-right: 0.5rem;
`;

const ImageThumbnail = ({ url }: { url: string }) => {
  const { getImage } = useImages();
  const [image, setImage] = useState<string>('');
  const [type, setType] = useState<string>('');

  useEffect(() => {
    getImage(url).then(({ success, data }) => {
      if (success) {
        if (data?.data) {
          setImage(new Buffer(data.data, 'binary').toString('base64'));
          setType(data?.headers['content-type']!);
        }
      }
    });
  }, [url, getImage]);

  return <Wrapper alt="" src={`data:${type};base64,${image}`} />;
};

export default ImageThumbnail;
