import React, { useCallback, useMemo, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { isEmpty, some } from 'lodash';
import styled from 'styled-components';

import CreateShipmentAddressForm from './CreateShipmentAddressForm';
import CreateShipmentCarrierForm from './CreateShipmentCarrierForm';
import CreateShipmenReturnForm from './CreateShipmentReturnForm';
import CreateShipmentSenderAddressSelection from './CreateShipmentSenderAddressSelection';
import useCreateShipment from '../hooks/useCreateShipment';
import useUploadLabelImage from '../hooks/useUploadLabelImage';
import { ICreateShipment, INITIAL_CreateShipment } from '../models/shipment';
import CustomModal from '@/components/CustomModal';
import Dropdown from '@/components/forms/Dropdown';
import NextPreviousFooterModal from '@/components/NextPreviousFooterModal';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';
import { SearchPartner } from '@/features/lp-village';
import { useServiceLevels } from '@/features/serviceLevels';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const TitleWrapper = styled.h2`
  padding-bottom: 1rem;
`;

const CreateShipment = () => {
  const [, createShipment] = useCreateShipment();
  const uploadLabelImage = useUploadLabelImage();
  const [visible, setVisible] = useState(false);
  const openModal = useCallback(() => setVisible(true), []);
  const closeModal = useCallback(() => setVisible(false), []);
  const successMsg = useToasts((state) => state.addItem);

  const formatShipmentRequest = useCallback(
    (
      shipment: ICreateShipment,
      carrier: string,
      originalShipmentId?: string
    ) => ({
      recipient: {
        address: shipment.recipient.address,
        notificationEmail: shipment.recipient.notificationEmail,
      },
      sender: {
        address: shipment.sender.address,
      },
      originalShipmentId: originalShipmentId,
      serviceLevel: shipment.serviceLevel,
      weightInGrams: shipment.weightInGrams,
      carrierCode: carrier,
      partnerId: shipment.sender.accountId,
    }),
    []
  );

  const handleFileUpload = useCallback(
    async (uploadFile, shipmentData) => {
      await uploadLabelImage(
        shipmentData.shipment.id,
        shipmentData.shipment.versionHash,
        uploadFile
      );
    },
    [uploadLabelImage]
  );

  const handleSubmit: CreateShipmentModalSubmitHandler = useCallback(
    async ({ shipment, file, carrier, returnShipment, returnFile }) => {
      const { success, data } = await createShipment(
        formatShipmentRequest(shipment, carrier)
      );
      const originalShipmentId = data?.shipment?.id;
      if (success) {
        if (file) {
          await handleFileUpload(file, data);
        }
        if (returnShipment) {
          const { success, data: returnData } = await createShipment(
            formatShipmentRequest(returnShipment, carrier, originalShipmentId)
          );
          if (success) {
            if (returnFile) {
              await handleFileUpload(returnFile, returnData);
            }
          }
        }
        successMsg(
          { msg: 'Shipment successfully created', type: MessageType.Success },
          'root',
          {}
        );
        closeModal();
      }
    },
    [
      closeModal,
      createShipment,
      successMsg,
      formatShipmentRequest,
      handleFileUpload,
    ]
  );

  return (
    <>
      <ScopedCta
        component={PillButton}
        icon={<PlusOutlined />}
        onClick={openModal}
        requiredScopes={[scopes.EDIT_SHIPMENTS]}
        data-testid="createShipmentButton"
      >
        Create Shipment
      </ScopedCta>
      {visible && (
        <CreateShipmentModal onClose={closeModal} onSubmit={handleSubmit} />
      )}
    </>
  );
};

type CreateShipmentModalSubmitHandler = ({
  shipment,
}: {
  shipment: ICreateShipment;
  file?: File | null;
  carrier: string;
  returnShipment?: ICreateShipment;
  returnFile?: File | null;
}) => Promise<void>;

function CreateShipmentModal({
  onClose,
  onSubmit,
}: {
  onClose: () => void;
  onSubmit: CreateShipmentModalSubmitHandler;
}) {
  const [step, setStep] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [carrier, setCarrier] = useState<string>('LPCarrier');
  const [serviceLevel, setServiceLevel] = useState<string>('1');
  const [shipment, setShipment] = useState(INITIAL_CreateShipment);
  const [partnerId, setPartnerId] = useState<string>('');
  const [selectedPartner, setSelectedPartner] = useState<string>();
  const [partnerAddressId, setPartnerAddressId] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const [returnFile, setReturnFile] = useState<File | null>(null);
  const [addReturn, setAddReturn] = useState<boolean>(false);
  const [returnShipment, setReturnShipement] = useState(INITIAL_CreateShipment);

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    if (addReturn) {
      await onSubmit({ shipment, file, carrier, returnShipment, returnFile });
    } else {
      await onSubmit({ shipment, file, carrier });
    }
    setSubmitting(false);
  }, [
    onSubmit,
    shipment,
    file,
    carrier,
    returnShipment,
    addReturn,
    returnFile,
  ]);

  const isRecipientAddressInvalid = useMemo(() => {
    const {
      street2,
      state,
      company,
      displayName,
      houseNumber,
      ...requiredAddressFields
    } = {
      notificationEmail: shipment.recipient.notificationEmail,
      ...shipment.recipient.address,
    };

    return some(requiredAddressFields, isEmpty);
  }, [shipment]);

  const validateReturnAddressFormRec = useCallback(() => {
    const {
      street2,
      state,
      company,
      displayName,
      houseNumber,
      ...requiredAddressFields
    } = {
      notificationEmail: returnShipment.recipient.notificationEmail,
      ...returnShipment.recipient.address,
    };

    return some(requiredAddressFields, isEmpty);
  }, [returnShipment]);

  const validateReturnAddressFormSen = useCallback(() => {
    const {
      street2,
      state,
      company,
      displayName,
      houseNumber,
      ...requiredAddressFields
    } = {
      ...returnShipment.sender.address,
    };

    return some(requiredAddressFields, isEmpty);
  }, [returnShipment]);

  const isCarrierShipmentValid = useCallback(
    () => carrier !== 'LPCarrier' && file === null,
    [carrier, file]
  );

  const isCarrierShipmentReturnValid = useCallback(
    () => carrier !== 'LPCarrier' && returnFile === null,
    [carrier, returnFile]
  );

  const invalidSteps = useMemo(
    () =>
      addReturn
        ? [
            !partnerId,
            !partnerAddressId,
            isRecipientAddressInvalid,
            false,
            isCarrierShipmentValid(),
            validateReturnAddressFormRec() || validateReturnAddressFormSen(),
            isCarrierShipmentReturnValid(),
          ]
        : [
            !partnerId,
            !partnerAddressId,
            isRecipientAddressInvalid,
            false,
            isCarrierShipmentValid(),
          ],
    [
      isRecipientAddressInvalid,
      partnerAddressId,
      partnerId,
      isCarrierShipmentValid,
      addReturn,
      isCarrierShipmentReturnValid,
      validateReturnAddressFormRec,
      validateReturnAddressFormSen,
    ]
  );

  const { result: serviceLevels } = useServiceLevels();

  const serviceLevelsOptionsDropDown = useCallback(() => {
    if (serviceLevels.state === 'success') {
      return serviceLevels.resource.items.map((sl) => ({
        value: sl.name,
        id: sl.id.toString(),
      }));
    }

    return [];
  }, [serviceLevels]);

  const modalFooterButtons = useMemo(
    () =>
      NextPreviousFooterModal({
        step,
        setStep,
        stepInvalid: invalidSteps,
        handleSubmit,
        closeModal: onClose,
        loading: submitting,
      }),
    [step, onClose, handleSubmit, submitting, invalidSteps]
  );

  const handlePartnerAddressIdChange = useCallback(
    (id) => {
      setShipment((prev) => ({
        ...prev,
        sender: {
          ...prev.sender,
          accountId: partnerId,
          addressId: id,
        },
      }));
      setPartnerAddressId(id);
    },
    [partnerId, setPartnerAddressId]
  );

  const onChangeDropDown = useCallback(
    (option) => {
      setShipment({
        ...shipment,
        serviceLevel: option.id,
      });
      setServiceLevel(option.id);
    },
    [setShipment, shipment]
  );

  const handlePartnerSelectServiceLevel = useCallback(
    (svLvl) => {
      setShipment({
        ...shipment,
        serviceLevel: svLvl,
      });
      setServiceLevel(svLvl);
    },
    [setShipment, setServiceLevel, shipment]
  );

  const createShipmentStep = useMemo(() => {
    switch (step) {
      case 0:
        return (
          <>
            <SearchPartner
              searchMultiple={true}
              partnerId={partnerId}
              setPartnerId={setPartnerId}
              selectedPartner={selectedPartner}
              setSelectedpartner={setSelectedPartner}
              setServiceLevel={handlePartnerSelectServiceLevel}
            />
          </>
        );
      case 1:
        return (
          <>
            <TitleWrapper>You need to choose the partner address:</TitleWrapper>
            <CreateShipmentSenderAddressSelection
              addressType="sender"
              handlePartnerAddressIdChange={handlePartnerAddressIdChange}
              partnerId={partnerId}
              partnerAddressId={partnerAddressId}
              setShipment={setShipment}
            />
          </>
        );
      case 2:
        return (
          <CreateShipmentAddressForm
            addressType="recipient"
            shipment={shipment}
            setShipment={setShipment}
          />
        );
      case 3:
        return (
          <>
            <TitleWrapper>Select a service level (OPTIONAL)</TitleWrapper>
            <Dropdown
              options={serviceLevelsOptionsDropDown()}
              getOptionValue={(option) => option['value']}
              getOptionLabel={(option) => option['value']}
              value={serviceLevelsOptionsDropDown().find(
                (option) => option.id === serviceLevel
              )}
              onChange={onChangeDropDown}
              is
            />
          </>
        );
      case 4:
        return (
          <>
            <Checkbox
              checked={addReturn}
              onChange={(e) => setAddReturn(e.target.checked)}
            >
              Create return shipment
            </Checkbox>
            <CreateShipmentCarrierForm
              carrier={carrier}
              setCarrier={setCarrier}
              file={file}
              setFile={setFile}
              setShipment={setShipment}
              shipment={shipment}
            />
          </>
        );
      case 5:
        return (
          <CreateShipmenReturnForm
            originalShipment={shipment}
            returnShipment={returnShipment}
            setReturnShipment={setReturnShipement}
          />
        );
      case 6:
        return (
          <CreateShipmentCarrierForm
            dropdownDisabled={true}
            carrier={carrier}
            setCarrier={setCarrier}
            file={returnFile}
            setFile={setReturnFile}
            shipment={returnShipment}
            setShipment={setReturnShipement}
          />
        );
      default:
        return <></>;
    }
  }, [
    handlePartnerAddressIdChange,
    partnerAddressId,
    partnerId,
    shipment,
    step,
    carrier,
    serviceLevel,
    file,
    onChangeDropDown,
    serviceLevelsOptionsDropDown,
    selectedPartner,
    addReturn,
    returnShipment,
    returnFile,
    handlePartnerSelectServiceLevel,
  ]);

  return (
    <CustomModal
      destroyOnClose={false}
      visible
      title="Create a shipment"
      onCancel={onClose}
      footer={modalFooterButtons}
      width="80%"
    >
      {createShipmentStep}
    </CustomModal>
  );
}

export default CreateShipment;
