import { useCallback, useState } from 'react';

import axios from 'axios';

export interface IUseImperativeRequest {
  path: string;
  method?: 'get' | 'post';
  headers?: any;
  body?: { [key: string]: string };
  timeout?: number;
}

interface IUseImperativeRequestUrlToken {
  url: string;
  token: string;
}

const useImperativeRequest = ({
  url,
  token,
}: IUseImperativeRequestUrlToken): any => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);

  const makeRequest = useCallback(
    async ({
      path = '/',
      method = 'get',
      body,
      headers = {},
      timeout = 0,
    }: IUseImperativeRequest) => {
      try {
        setLoading(true);

        const { data } = await axios({
          url: `${url}${path}`,
          method,
          data: body,
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
          timeout,
        });

        setError('');
        setData(data);
        setLoading(false);

        return { data, error: '' };
      } catch (err: any) {
        setError(err.toString());
        console.error(err);
        setLoading(false);

        return { data: null, error: err };
      }
    },
    [token, url]
  );

  return [{ error, loading, data }, makeRequest];
};

export default useImperativeRequest;
