import React from 'react';

import ServiceLevelForm from '../components/ServiceLevelForm';
import useAddServiceLevel from '../hooks/useAddServiceLevel';
interface EditServiceLevelProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  initialServiceLevel: any;
  fetchServiceLevels: () => void;
}

const DuplicateServiceLevelModal = ({
  initialServiceLevel,
  visible,
  setVisible,
  fetchServiceLevels,
}: EditServiceLevelProps) => {
  const { loading, setLoading, addServiceLevel } = useAddServiceLevel();

  return (
    <ServiceLevelForm
      cloneMode={true}
      onSubmit={addServiceLevel}
      loading={loading}
      setLoading={setLoading}
      initialServiceLevel={{
        ...initialServiceLevel,
        name: `${initialServiceLevel.name} (Copy)`,
      }}
      visible={visible}
      setVisible={setVisible}
      fetchServiceLevels={fetchServiceLevels}
      isSameConfigInvalid={false}
      title="Duplicate Service Level"
      subtitle="Change at least the name and the description to create a new service level."
    />
  );
};

export default DuplicateServiceLevelModal;
