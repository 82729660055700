import React, { ReactNode } from 'react';

import styled from 'styled-components';

interface IGMInfoWindow {
  header: string;
  content: ReactNode;
}

const InfoWindowLabel = styled.span`
  display: block;
  font-size: 12px !important;
  font-weight: bold;
`;

const InfoWindowText = styled.span`
  display: block;
  font-size: 12px !important;
  max-width: 10rem;
`;

export const GMInfoWindowContent = ({ header, content }: IGMInfoWindow) => (
  <>
    <InfoWindowLabel>{header}</InfoWindowLabel>
    <InfoWindowText data-testid="infoWindowText">{content}</InfoWindowText>
  </>
);
