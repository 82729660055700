import has from 'lodash/has';

import dates from '@/helpers/dates';

const lpShopsPostProcessor = (data) => {
  const parsedItems = data.items.map((item) => ({
    ...item,
    partnerId: item.partner.id,
    parsedCreatedAt: has(item, 'createdAt')
      ? dates.parseDate(item.createdAt)
      : '',
    parsedUpdatedAt: has(item, 'updatedAt')
      ? dates.parseDate(item.updatedAt)
      : '',
  }));

  return { ...data, items: parsedItems };
};

export default lpShopsPostProcessor;
