import React, { useCallback, useState } from 'react';

import { DatePicker, Select } from 'antd';
import forEach from 'lodash/forEach';
import moment from 'moment';
import styled from 'styled-components';

import SearchField from './forms/SearchField';
import SearchButton from './SearchButton';
import SearchResetButton from './SearchResetButton';
const { Option } = Select;
const { RangePicker } = DatePicker;

interface IField {
  label?: string;
  placeholder?: string;
  key: string;
}

interface IBillingServerSearch {
  fields: IField[];
  dispatch: any;
  storeSearchTerms: {
    [key: string]: string | number | undefined;
  };
  label?: string;
  setBillingWindow?: any;
}

const FormWrapper = styled.form`
  width: 100%;
`;

const LabelWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1rem;
`;

const SearchWrapper = styled.div`
  flex: 1;
  margin-right: 1rem;
`;

const ButtonWrapper = styled.div`
  margin-right: 0.5rem;
`;

const trimSearchTerms = (searchTerms) => {
  const result = {};
  forEach(searchTerms, (value: string | number, key: string) => {
    if (typeof value === 'string') {
      value = value.trim();
    }
    result[key] = value;
  });

  return result;
};

const BillingServerSearch = ({
  fields,
  dispatch,
  storeSearchTerms,
  label = 'Search by:',
  setBillingWindow,
}: IBillingServerSearch) => {
  const INITIAL_STATE = useCallback(
    () =>
      fields.reduce((a, b) => {
        a[b.key] = storeSearchTerms[b.key] || '';

        return a;
      }, {}),
    [fields, storeSearchTerms]
  );

  const CLEARED_STATE = useCallback(
    () =>
      fields.reduce((a, b) => {
        a[b.key] = '';

        return a;
      }, {}),
    [fields]
  );

  const [searchTerms, setSearchTerms] = useState<{
    [key: string]: string | number;
  }>(INITIAL_STATE);

  const [dateValues, setDateValues] = useState(undefined);

  const handleChange = useCallback(
    (event) => {
      setSearchTerms({
        ...searchTerms,
        [event.target.name]: event.target.value,
      });
    },
    [searchTerms, setSearchTerms]
  );

  const handleContractStateChange = useCallback(
    (contractState) => {
      setSearchTerms({
        ...searchTerms,
        activeState: contractState.length === 1 ? contractState[0] : 'ALL',
      });
    },
    [searchTerms, setSearchTerms]
  );

  const handleSubmit = useCallback(
    (event) => {
      if (event.preventDefault) {
        event.preventDefault();
      }
      const trimmedTerms = trimSearchTerms(searchTerms);
      setSearchTerms(trimmedTerms);
      if (searchTerms.activeState === '') {
        trimmedTerms['activeState'] = 'ALL';
      }
      dispatch({ type: 'updateSearchTerms', args: trimmedTerms });
    },
    [dispatch, searchTerms, setSearchTerms]
  );

  const handleReset = useCallback(() => {
    setSearchTerms(CLEARED_STATE);
    dispatch({ type: 'updateSearchTerms', args: CLEARED_STATE });
    setDateValues(undefined);
  }, [setSearchTerms, dispatch, CLEARED_STATE]);

  const handleDateSelection = useCallback(
    (ogDates, dateString) => {
      setSearchTerms({
        ...searchTerms,
        activeFrom:
          dateString[0] === ''
            ? 0
            : moment(dateString[0], 'YYYY.MM.DD').startOf('day').unix(),
        activeTo:
          dateString[1] === ''
            ? 0
            : moment(dateString[1], 'YYYY.MM.DD').endOf('day').unix(),
      });
      setBillingWindow(dateString);
      setDateValues(ogDates);
    },
    [searchTerms, setSearchTerms, setBillingWindow]
  );

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <LabelWrapper>{label}</LabelWrapper>
      <StyledRow>
        {fields.map((field: IField) => {
          if (field.key === 'range') {
            return (
              <SearchWrapper key={field.key}>
                <RangePicker
                  allowEmpty={[true, true]}
                  onChange={handleDateSelection}
                  value={dateValues}
                />
              </SearchWrapper>
            );
          }
          if (field.key === 'activeState') {
            return (
              <SearchWrapper style={{ flex: 2 }} key={field.key}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select"
                  defaultValue={['DEPLOYED', 'ARRIVED']}
                  onChange={handleContractStateChange}
                >
                  <Option value="DEPLOYED">Deployed</Option>
                  <Option value="ARRIVED">Arrived</Option>
                </Select>
              </SearchWrapper>
            );
          }

          return (
            <SearchWrapper key={field.key}>
              <SearchField
                key={field.key}
                value={searchTerms[field.key].toString()}
                name={field.key}
                placeholder={field.placeholder}
                label={field.label}
                handleChange={handleChange}
                width="100%"
              />
            </SearchWrapper>
          );
        })}
        <ButtonWrapper>
          <SearchButton />
        </ButtonWrapper>
        <ButtonWrapper>
          <SearchResetButton handleReset={handleReset} />
        </ButtonWrapper>
      </StyledRow>
    </FormWrapper>
  );
};

export default BillingServerSearch;
