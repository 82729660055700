import React from 'react';

function DollarIcon(props) {
  return (
    <svg
      cursor="pointer"
      width="2em"
      height="2em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M12.6832 11.3169H12.7525L12.7327 11.307C13.4852 11.307 14.208 11.5941 14.7624 12.109C15.2872 12.6337 15.5842 13.3565 15.5842 14.0991C15.5842 15.6634 14.3169 16.9307 12.7525 16.9307H12.6832V18.4951C12.6832 18.8713 12.3763 19.1783 12 19.1783C11.6238 19.1783 11.3169 18.8713 11.3169 18.4951V16.9307H11.2476C9.97034 16.9307 8.87133 16.0991 8.52479 14.8614C8.50499 14.7822 8.50499 14.7327 8.50499 14.6634C8.50499 14.2872 8.81192 13.9802 9.18816 13.9802C9.51489 13.9802 9.79212 14.208 9.87133 14.5347C10.0495 15.1387 10.6139 15.5644 11.2575 15.5644H11.3268V12.6733H11.2575C9.69311 12.6733 8.42578 11.406 8.42578 9.84163C8.42578 9.07926 8.71291 8.38619 9.24756 7.85153C9.77232 7.32678 10.4951 7.02975 11.2377 7.02975H11.307V5.46539C11.307 5.08916 11.6139 4.78223 11.9901 4.78223C12.3664 4.78223 12.6733 5.08916 12.6733 5.46539V7.02975H12.7426C14.0198 7.02975 15.1387 7.89114 15.4654 9.10896C15.5149 9.28718 15.4852 9.4753 15.3961 9.63371C15.307 9.80203 15.1585 9.91094 14.9802 9.96044C14.8119 10.0099 14.6238 9.99015 14.4555 9.89114C14.2971 9.80203 14.1882 9.65351 14.1387 9.4753C13.9604 8.84163 13.406 8.40599 12.7525 8.40599H12.6832V11.3169ZM13.7723 15.1585C14.0396 14.8812 14.1783 14.5149 14.1783 14.1288L14.1684 14.1189C14.1684 13.7129 14.0297 13.3664 13.7624 13.0991C13.4852 12.8218 13.1387 12.6733 12.7525 12.6733H12.6832V15.5842H12.7525C13.1387 15.5842 13.505 15.4357 13.7723 15.1585ZM11.2278 8.43569C10.8614 8.43569 10.4852 8.58421 10.2179 8.86143L10.208 8.87134C9.95053 9.12876 9.80202 9.4951 9.80202 9.88124C9.80202 10.6832 10.4555 11.3268 11.2476 11.3268H11.2971V8.43569H11.2278Z"
        fill="#2B2B2B"
      />
    </svg>
  );
}

export default DollarIcon;
