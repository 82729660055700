import { useCallback } from 'react';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useAddAccountToPartner: any = (id) => {
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpVillage');

  const addAccount = useCallback(
    async (data) => {
      const { error } = await makeRequest({
        path: `/admin/partners/${id}/accounts`,
        method: 'post',
        body: data,
      });
      if (!!error) {
        addError(error);

        return { success: false };
      }
      addSuccessMsg(
        {
          msg: 'Account was successfully added to partner',
          type: MessageType.Success,
        },
        'root',
        {}
      );

      return { success: true };
    },
    [id, makeRequest, addSuccessMsg, addError]
  );

  return [{ loading }, addAccount];
};
export default useAddAccountToPartner;
