import has from 'lodash/has';

import { nonPaginatedContributionsProcessor } from '@/features/sharing-angels';
import dates from '@/helpers/dates';

const getRecipientEmailsField = (emailsArray) => {
  if (emailsArray.length === 0) {
    return '';
  } else if (emailsArray.length === 1) {
    return emailsArray[0];
  }

  return `${emailsArray[0]} +${emailsArray.length - 1} more`;
};

const invitationCodePostProcessor = (record) => ({
  ...record,
  parsedCreatedAt: !!record.createdAt ? dates.parseDate(record.createdAt) : '',
  parsedUpdatedAt: !!record.updatedAt ? dates.parseDate(record.updatedAt) : '',
  parsedExpiredAt:
    record.expiredAt > 0 ? dates.parseDate(record.expiredAt) : '',
  parsedRevokedAt:
    record.revokedAt > 0 ? dates.parseDate(record.revokedAt) : '',
  parsedEmailsField: !!record.recipientEmails
    ? getRecipientEmailsField(record.recipientEmails)
    : '',
  numberOfContributions: has(record, 'contributions')
    ? record.contributions.length
    : 0,
  contributions: has(record, 'contributions')
    ? nonPaginatedContributionsProcessor(record.contributions)
    : [],
});

const invitationCodesPostProcessor = (data) => {
  const parsedItems = data.items.map((item) =>
    invitationCodePostProcessor(item)
  );

  return { ...data, items: parsedItems };
};

export default invitationCodesPostProcessor;
