import React from 'react';

import styled from 'styled-components';
const SVG = styled.svg`
  cursor: pointer;
`;
const ReuploadIcon = ({
  fill = '#38a169',
  width = '2.5em',
  height = '2.5em',
  viewBox = '0 0 24 24',
}) => (
  <SVG width={width} height={height} viewBox={viewBox}>
    <defs>
      <path
        id="icons-24-px-reupload_svg__a"
        d="M13 3.3a.683.683 0 01.276.056l.024.012a.607.607 0 01.051.026l.045.029.012.008.018.013a.97.97 0 01.06.052l.009.009 2 2a.7.7 0 01-.903 1.064l-.087-.074-.806-.806.001 2.978c0 1.615-1.302 2.936-2.924 3.028l-.181.005H7a.7.7 0 01-.095-1.394L7 10.3h3.595c.891 0 1.624-.665 1.699-1.494l.006-.14-.001-2.977-.804.806a.701.701 0 01-.903.074l-.087-.074a.701.701 0 01-.074-.903l.074-.087 2-2 .067-.06.013-.009-.08.069a.766.766 0 01.104-.086l.058-.035.045-.022.04-.016a.472.472 0 01.109-.032l.037-.007A.845.845 0 0113 3.3zm-4-3a.7.7 0 01.095 1.394L9 1.7H5.4c-.894 0-1.62.66-1.694 1.493l-.006.14-.001 3.978.806-.806a.7.7 0 011.064.903l-.074.087-2 2a.701.701 0 01-.903.074l-.087-.074-2-2a.7.7 0 01.903-1.064l.087.074.804.806.001-3.978C2.3 1.713 3.594.397 5.218.305L5.4.3H9z"
      />
    </defs>
    <g transform="translate(4 6)" fill="none" fillRule="evenodd">
      <mask id="icons-24-px-reupload_svg__b" fill="#fff">
        <use xlinkHref="#icons-24-px-reupload_svg__a" />
      </mask>
      <use
        fill={fill}
        fillRule="nonzero"
        xlinkHref="#icons-24-px-reupload_svg__a"
      />
      <g fill={fill} mask="url(#icons-24-px-reupload_svg__b)">
        <path d="M-4-6h24v24H-4z" />
      </g>
    </g>
  </SVG>
);

export default ReuploadIcon;
