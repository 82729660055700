import { useCallback, useEffect, useState } from 'react';

import pick from 'lodash/pick';

import { IModifiedShop, INITIAL_ModifiedShop, IShop } from '../models/shop';
import dates from '@/helpers/dates';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { ILPVillageAddress } from '@/models/address';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

export interface ILPShopState {
  error: string;
  loading: boolean;
  data: IModifiedShop;
  deleted?: boolean;
}

export const INITIAL_STATE = {
  error: '',
  loading: true,
  data: INITIAL_ModifiedShop,
};

const EDITABLE_PATHS = [
  'name',
  'partnerId',
  'website',
  'phoneNumber',
  'description',
  'carrierCodes',
  'openTimes',
  'serviceIds',
  'thumbnail',
  'address.city',
  'address.company',
  'address.countryCode',
  'address.displayName',
  'address.firstName',
  'address.houseNumber',
  'address.lastName',
  'address.postalCode',
  'address.street',
  'address.street2',
];

const useLPShop = (id: string) => {
  const successMsg = useToasts((state) => state.addItem);
  const [shop, setShop] = useState<ILPShopState>(INITIAL_STATE);

  const [, makeRequestGet] = useImperativeRequestWrapper('lpVillage');
  const [, makeRequestPut] = useImperativeRequestWrapper('lpVillage');
  const addError = useError();

  const setShopWithProcessing = useCallback(
    async (data: IShop) => {
      try {
        const processedShop = {
          ...data,
          partnerId: data.partner.id,
          parsedCreatedAt: dates.parseDate(data.createdAt),
          parsedUpdatedAt: dates.parseDate(data.updatedAt),
        };
        setShop((prev) => ({ ...prev, loading: false, data: processedShop }));
      } catch (err: any) {
        setShop((prev) => ({ ...prev, loading: false, error: err.toString() }));
        addError(err);
      }
    },
    [setShop, addError]
  );

  const getShop = useCallback(() => {
    makeRequestGet({
      path: `/admin/shops/${id}`,
      method: 'get',
    }).then(async (res) => {
      if (!!res.error) {
        setShop((prev) => ({ ...prev, loading: false, error: res.error }));
        addError(res.error);

        return;
      }
      await setShopWithProcessing(res.data);
    });
  }, [makeRequestGet, addError, id, setShopWithProcessing]);

  const updateShop = useCallback(
    async (body: Partial<IModifiedShop>) => {
      const res = await makeRequestPut({
        path: `/admin/shops/${id}`,
        method: 'patch',
        body,
      });
      if (!!res.error) {
        setShop((prev) => ({ ...prev, loading: false, error: res.error }));
        addError(res.error);

        return { success: false };
      }
      await setShopWithProcessing(res.data);
      successMsg(
        { msg: 'Shop was successfully updated', type: MessageType.Success },
        'root',
        {}
      );

      return { success: true };
    },
    [addError, id, makeRequestPut, setShopWithProcessing, successMsg]
  );

  const updateShopInfo = useCallback(
    async (editedData: Partial<IModifiedShop>) => await updateShop(editedData),
    [updateShop]
  );

  const updateShopAddress = useCallback(
    async (editedData: Partial<ILPVillageAddress>) => {
      const cleanEditedData = pick({ address: editedData }, EDITABLE_PATHS);

      return await updateShop(cleanEditedData as IModifiedShop);
    },
    [updateShop]
  );

  useEffect(() => {
    if (!id) return;
    getShop();
  }, [getShop, id]);

  return { shop, updateShopInfo, updateShopAddress, setShop };
};

export default useLPShop;
