import { useCallback } from 'react';

import useContributionStore from './useContributionStore';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

export enum REWARD_STATUS {
  REWARD_STATUS_UNSPECIFIED = 'REWARD_STATUS_UNSPECIFIED',
  REWARD_STATUS_NOT_AVAILABLE = 'REWARD_STATUS_NOT_AVAILABLE',
  REWARD_STATUS_AVAILABLE = 'REWARD_STATUS_AVAILABLE',
  REWARD_STATUS_IN_PROGRESS = 'REWARD_STATUS_IN_PROGRESS',
  REWARD_STATUS_IN_FAILURE_TO_PAY = 'REWARD_STATUS_IN_FAILURE_TO_PAY',
  REWARD_STATUS_PAID_OUT = 'REWARD_STATUS_PAID_OUT',
}

export enum REWARD_STATUS_LABEL {
  REWARD_STATUS_UNSPECIFIED = 'Unpecified',
  REWARD_STATUS_NOT_AVAILABLE = 'Not available',
  REWARD_STATUS_AVAILABLE = 'Available',
  REWARD_STATUS_IN_PROGRESS = 'In progress',
  REWARD_STATUS_IN_FAILURE_TO_PAY = 'In failure to pay',
  REWARD_STATUS_PAID_OUT = 'Paid out',
}

export interface IrewardTransaction {
  contribution_id: string;
  reward_estimated_date: string;
  reward_status: REWARD_STATUS;
  transaction_amount: number;
  transaction_at: string;
  transaction_id: string;
  transaction_receipt_id: string;
}

const useReward = () => {
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpAccountV3');
  const contribution = useContributionStore((state) => state.contribution);
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);

  const getContributionRewardTransaction = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: `/contributions/${contribution.id}/reward`,
      method: 'GET',
      body: {},
    });

    if (error) {
      addError(error);
    }
    let isValaible = false;

    if (data) {
      const currentDate = Number(Date.now().toString().slice(0, -3));
      const rewardEstimatedDate = Number(
        new Date(data.reward_transaction.reward_estimated_date)
          .getTime()
          .toString()
          .slice(0, -3)
      );

      isValaible =
        data.reward_transaction.reward_status ===
          REWARD_STATUS.REWARD_STATUS_NOT_AVAILABLE &&
        rewardEstimatedDate > 0 &&
        currentDate >= rewardEstimatedDate;
    }

    return {
      success: !error,
      ...(data && { data: data.reward_transaction }),
      isValaible,
    };
  }, [makeRequest, contribution.id, addError]);

  const updateContributionRewardStatus = useCallback(
    async (accountId: string) => {
      const { error, data } = await makeRequest({
        path: `/contributions/${contribution.id}/reward_status`,
        method: 'PATCH',
        body: {
          lpaccount_id: accountId,
          reward_status: REWARD_STATUS.REWARD_STATUS_AVAILABLE,
        },
      });

      if (error) {
        addError(error);

        return { success: false };
      }

      if (data) {
        addSuccessMsg(
          {
            msg: 'Reward activated',
            type: MessageType.Success,
          },
          'root',
          {}
        );

        return { success: true, ...(data && { data: data }) };
      }
    },
    [addError, contribution.id, makeRequest, addSuccessMsg]
  );

  return {
    updateContributionRewardStatus,
    loading,
    getContributionRewardTransaction,
  };
};

export default useReward;
