import React from 'react';

import { Col, Divider, Row, Card } from 'antd';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import LPAccountForm from './forms/LPAccountForm';
import NotesForm from './forms/NotesForm';
import ActionBarRow from './partials/ActionBarRow';
import AvatarRow from './partials/AvatarRow';
import DataTabs from './partials/DataTabs';
import useLPAccountUser from '../hooks/useLPAccountUser';
import ResourceNotFound from '@/components/ResourceNotFound';
import H2 from '@/components/text/H2';

const Wrapper = styled.div`
  margin-bottom: 3rem;
`;

const FormRow = styled(Row)`
  margin-top: 1rem;
`;

const DeletedH2 = styled(H2)`
  color: ${(props) => props.theme.colors.adminPanel.error};
`;

const LPAccountUserDetailsContent = () => {
  const { id } = useParams<{ id: string }>();
  const { errorGet, user, getUser, updateUser } = useLPAccountUser(id!);
  const {
    loading,
    data: { identities, parsedDeletedAt, notes, businessType },
  } = user;

  if (errorGet) {
    return <ResourceNotFound resourceName="LP Account" resourceId={id} />;
  }

  return (
    <Wrapper>
      <Wrapper>
        <Row>
          <Col md={24}>{user && <AvatarRow id={id!} user={user} />}</Col>
          <Col md={24}>
            <ActionBarRow id={id!} user={user} getUser={getUser} />
          </Col>
        </Row>
        {parsedDeletedAt && (
          <>
            <Row>
              <DeletedH2 data-testid="userDeleted">DELETED</DeletedH2>
            </Row>
          </>
        )}
        <FormRow>
          <Col xxl={10} xl={14} lg={15}>
            <LPAccountForm user={user} saveUserData={updateUser} />
          </Col>
          <Col xxl={1} xl={2} lg={1} md={0} />
          <Col xxl={7} xl={8} lg={8} md={15}>
            <Card title="Notes">
              <Row>
                <Col span={24}>
                  <NotesForm id={id!} getUser={getUser} notes={notes} />
                </Col>
              </Row>
            </Card>
          </Col>
        </FormRow>
        <Divider />
      </Wrapper>
      <DataTabs
        id={id!}
        identities={identities}
        loading={loading}
        userType={businessType}
      />
    </Wrapper>
  );
};

export default LPAccountUserDetailsContent;
