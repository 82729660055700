import { create } from 'zustand';

export interface IAppState {
  token: string;
  error: string;
  loading: boolean;
  scopes: string[];
  setAppState: (appState: {
    token?: string;
    error?: string;
    loading?: boolean;
    scopes?: string[];
  }) => void;
}

const useAppState = create<IAppState>((set) => ({
  token: '',
  error: '',
  loading: true,
  scopes: [],
  setAppState: (appState) => set((state) => ({ ...state, ...appState })),
}));

export default useAppState;
