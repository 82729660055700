import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';

import { Col, Row } from 'antd';
import styled from 'styled-components';

import BoxContractTable from '../components/BoxContractTable';
import Error from '@/components/Error';
import { scopes } from '@/config';
import useAppState from '@/hooks/useAppState';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const RewardWrapper = styled(Row)`
  font-size: 20px;
  font-weight: bold;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const BoxContractByAccountTable = ({
  id,
  userType,
}: {
  id: string;
  userType?: string;
}) => {
  const userScopes = useAppState((state) => state.scopes);
  const [rentOverview, setRentOverview] = useState<any>();

  const [, makeRequest] = useImperativeRequestWrapper('lpAccountV3');

  const getRent = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: `/${id}/rent_overview`,
      method: 'get',
    });

    if (error) {
      return { success: false };
    }

    setRentOverview(data.rent_overview);
  }, [id, makeRequest]);

  useEffect(() => {
    if (userType?.toLocaleLowerCase() === 'personal') {
      getRent();
    }
  }, [getRent, id, userType]);

  if (!userScopes.includes(scopes.VIEW_LPACCOUNT)) {
    return <Error message="Sorry, you are not allowed to view this!" />;
  }

  return (
    <>
      {userType?.toLocaleLowerCase() === 'personal' && (
        <RewardWrapper justify="center">
          <Col span={6}>Rent available : {rentOverview?.available!} €</Col>
          <Col span={6}>Rent paid out: {rentOverview?.paid_out!} €</Col>
          <Col span={6}>Rent total: {rentOverview?.total!} €</Col>
        </RewardWrapper>
      )}
      <BoxContractTable filterByAccountId={true} accountId={id} />
    </>
  );
};

export default BoxContractByAccountTable;
