export interface ITos {
  id: string;
  createdAt: number;
  updatedAt: number;
  activeAt: number;
  serviceType: TServiceType;
  tosLocation: string;
}

export interface IModifiedTos extends ITos {
  tosId: string;
  parsedCreatedAt: string;
  parsedUpdatedAt: string;
  parsedActiveAt: string;
}

export type TServiceType = 'financial_models' | 'PaaS' | 'website';
export const SERVICE_TYPES = ['financial_models', 'PaaS', 'website'];

export const INITIAL_STATE_TOS: IModifiedTos = {
  id: '',
  tosId: '',
  createdAt: 0,
  updatedAt: 0,
  activeAt: 0,
  serviceType: 'PaaS',
  tosLocation: '',
  parsedCreatedAt: '',
  parsedUpdatedAt: '',
  parsedActiveAt: '',
};
