import styled from 'styled-components';

export default styled.h1`
  text-transform: uppercase;
  margin-top: 0;
  margin: 1rem 0 0;
  font-family: TTNormsPro-Bold;
  font-size: 2.5rem;
  letter-spacing: 0.3rem;
`;
