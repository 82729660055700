import React from 'react';

import get from 'lodash/get';
import styled, { ThemeProps } from 'styled-components';

interface IFieldError {
  name?: string;
  message?: string;
}

interface IFieldErrorStyled {
  mt?: string;
  mb?: string;
  fs?: string;
  pos?: string;
  left?: string;
  top?: string;
  bottom?: string;
  pl?: string;
  ml?: string;
  ta?: string;
  theme?: any;
}

const FieldErrorStyled = styled.p<IFieldErrorStyled & ThemeProps<any>>`
  font-family: ${({ theme }) =>
    get(
      theme,
      'fontStyles.adminPanel.fontFamily',
      theme.fontStyles.adminPanel.afontFamily
    )};
  color: ${({ theme }) =>
    get(theme, 'colors.adminPanel.error', theme.colors.adminPanel.error)};
  font-size: ${({ fs }) => fs || '0.8rem'};
  position: ${({ pos }) => pos || 'static'};
  left: ${({ left }) => left || 0};
  top: ${({ top }) => top || 'auto'};
  bottom: ${({ bottom }) => bottom || 'auto'};
  padding-left: ${({ pl }) => pl || 0};
  margin-left: ${({ ml }) => ml || 0};
  margin-top: ${({ mt }) => mt || 0};
  margin-bottom: ${({ mb }) => mb || '1rem'};
  text-align: ${({ ta }) => ta || 'left'};
`;

const FieldError = ({ message, ...props }: IFieldError & IFieldErrorStyled) => (
  <FieldErrorStyled {...props}>{message}</FieldErrorStyled>
);

export default FieldError;
