import React from 'react';

import { Col, Row, Collapse } from 'antd';
import styled from 'styled-components';

import RecipientSenderForm from './RecipientSenderForm';
import TextInput from '@/components/forms/TextInput';
import SectionHeader from '@/components/SectionHeader';

const { Panel } = Collapse;

const Wrapper = styled.div`
  margin-left: 1.5rem;
`;

const ShipmentDetailsForm = ({ shipment }: { shipment: any }) => {
  const { quote, recipientSender, recipient, sender } = shipment;

  return (
    <Collapse ghost>
      <Panel
        header={<SectionHeader title="Quote" />}
        key="1"
        collapsible={!quote.id ? 'disabled' : undefined}
      >
        <Wrapper>
          <Row gutter={24}>
            <Col span={12}>
              <TextInput
                disabled
                label="Quote id"
                name="quoteId"
                value={quote.id}
              />
            </Col>
            <Col span={12}>
              <TextInput
                disabled
                label="Carrier Service"
                name="carrierService"
                value={quote.carrierService}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <TextInput
                disabled
                label="Price (in cents)"
                name="priceInCents"
                value={quote.priceInCents}
              />
            </Col>

            <Col span={12}>
              <TextInput
                disabled
                label="Currency"
                name="currency"
                value={quote.currency}
              />
            </Col>
          </Row>
        </Wrapper>
      </Panel>
      <Panel header={<SectionHeader title="Recipient" />} key="2">
        <Wrapper>
          <RecipientSenderForm
            recipientSender={recipientSender[0]}
            account={recipient}
          />
        </Wrapper>
      </Panel>
      <Panel header={<SectionHeader title="Sender" />} key="3">
        <Wrapper>
          <RecipientSenderForm
            recipient={false}
            recipientSender={recipientSender[1]}
            account={sender}
          />
        </Wrapper>
      </Panel>
    </Collapse>
  );
};
export default ShipmentDetailsForm;
