export const theme = {
  colors: {
    brand: '#38a169',
    darkgreen: '#0C6B64',
    darkbrand: '#1E9560', // TODO : revoir la gestion des couleurs de l'admin
    green: '#90EE90',
    black: '#000',
    neonyellow: '#eeff00',
    white: '#fff',
    grey: '#f5f5f6',
    greyVarF2: '#F2F2F2',
    darkgrey: '#a6a6a7',
    error: '#ff0000',
    errorBc: '#ff5050',
    attention: '#ffbd30',
    orange: '#ffa500',
    lightgreen: '#e6f5ec',
    lightgrey: '#e6e6e6',
    lightRed: '#eb6f6c',
    blue: '#0066cc',
  },
  shadows: {
    longShadow: 'box-shadow: 0px 8px 24px 0 rgba(0, 0, 0, 0.2)',
  },
  fonts: {
    monospace: 'TTNormsPro-Regular',
    sansserif: 'TTNormsPro-Regular',
  },
  transitions: {
    short: (property) => `${property} 0.2s ease-out`,
  },
};

interface IAuth {
  domain: string;
  clientId: string;
  audience: string;
  scope: string;
}

export const scopes: any = {
  //Box
  REGISTER_BOX: 'register:Box',
  EDIT_BOX_LIFECYCLE: 'edit:BoxLifeCycle',
  EDIT_BOX_QUALITY_CHECK: 'edit:BoxQualityCheck',
  EDIT_BOX_CONFIG: 'edit:BoxConfig',

  //Buy'N'rent
  VIEW_BUY_N_RENT: 'view:BuyNRent',
  EDIT_BUY_N_RENT_CONTRACT: 'edit:BuyNRentContract',
  MANAGE_BUY_N_RENT_CONTRACT: 'manage:BuyNRentContract',
  EDIT_BUY_N_RENT_OFFER: 'edit:BuyNRentOffer',
  MANAGE_BUY_N_RENT_OFFER: 'manage:BuyNRentOffer',

  //Sharing Angel
  VIEW_SHARING_ANGELS: 'view:SharingAngels',
  EDIT_SHARING_ANGELS: 'edit:SharingAngelsContributions',
  VIEW_SHARING_ANGELS_CONTRIBUTION: 'view:SharingAngelsContributions',
  MANAGE_SHARING_ANGELS: 'manage:SharingAngelsContributions',
  EDIT_SHARING_ANGELS_OFFER: 'edit:SharingAngelsOffer',
  MANAGE_SHARING_ANGELS_OFFER: 'manage:SharingAngelsOffer',

  //LPAccount
  VIEW_LPACCOUNT: 'view:LPAccount',
  EDIT_LPACCOUNT: 'edit:LPAccount',
  MANAGE_LPACCOUNT: 'manage:LPAccount',

  //LPVillage
  VIEW_LPVILLAGE: 'view:LPVillage',
  EDIT_LPVILLAGE_PARTNER: 'edit:LPVillagePartners',
  MANAGE_LPVILLAGE_PARTNER: 'manage:LPVillagePartners',
  MANAGE_LPVILLAGE_API_KEY: 'manage:LPVillagePartnersAPIKey',
  EDIT_LPVILLAGE_SHOPS: 'edit:LPVillageShops',
  MANAGE_LPVILLAGE_SHOPS: 'manage:LPVillageShops',
  EDIT_LPVILLAGE_ROLES: 'edit:LPVillageRoles',
  MANAGE_LPVILLAGE_ROLES: 'manage:LPVillageRoles',
  EDIT_LPVILLAGE_SCOPES: 'edit:LPVillageScopes',
  MANAGE_LPVILLAGE_SCOPES: 'manage:LPVillageScopes',
  EDIT_LPVILLAGE_SERVICES: 'edit:LPVillageServices',
  MANAGE_LPVILLAGE_SERVICES: 'manage:LPVillageServices',

  //TOS
  MANAGE_TOS: 'manage:TOS',

  //Shipments
  VIEW_SHIPMENTS: 'view:Shipments',
  EDIT_SHIPMENTS: 'edit:Shipments',
  MANAGE_SHIPMENTS: 'manage:Shipments',

  //Quality checks
  VIEW_QUALITY_CHECKS: 'view:QualityCheckTypes',
  EDIT_QUALITY_CHECKS: 'edit:QualityCheckTypes',
  MANAGE_QUALITY_CHECKS: 'manage:QualityCheckTypes',

  //Service Levels
  VIEW_SERVICE_LEVELS: 'view:ServiceLevels',
  EDIT_SERVICE_LEVELS: 'edit:ServiceLevels',
  MANAGE_SERVICE_LEVELS: 'manage:ServiceLevels',

  //Changelog
  VIEW_CHANGELOG: 'view:Changelog',

  //Billing
  MANAGE_BILLING: 'manage:ShipmentBilling',

  //'''product'''
  EDIT_PRODUCT: 'edit:Products',
  VIEW_PRODUCT: 'view:Products',
  MANAGE_PRODUCT: 'manage:Products',
  EDIT_PRODUCT_OWNER: 'edit:ProductsOwner',
  VIEW_PRODUCT_OWNER: 'view:ProductsOwner',

  //Elabel
  EDIT_SHIPMENT_LABEL: 'edit:ShipmentLabel',

  //???/
  EDIT_SHIPMENT_CARRIER: 'edit:ShipmentCarrier',

  //WALLET
  VIEW_WALLET: 'view:Wallet',

  //TRANSACTION
  VIEW_TRANSACTION: 'view:Transaction',
  EDIT_TRANSACTION: 'edit:Transaction',
};

function concatenateScopes() {
  const scopesArray = Object.values(scopes);

  return scopesArray.join(' ');
}

export const auth: IAuth = {
  domain: process.env.REACT_APP_AUTH_DOMAIN!,
  clientId: process.env.REACT_APP_AUTH_CLIENT_ID!,
  audience: process.env.REACT_APP_AUTH_AUDIENCE!,
  scope: `openid profile email ${concatenateScopes()}`,
};

export const tracking = {
  TRACKING_ENABLED: !!process.env.REACT_APP_TRACKING,
  KLAVIYO_API_KEY: 'Rwid85',
  KLAVIYO_TRACK_URL: 'https://a.klaviyo.com/api/track?data=',
  KLAVIYO_IDENTIFY_URL: 'https://a.klaviyo.com/api/identify?data=',
  GOOGLE_TRACKING_ID: 'UA-85246508-1',
  SENTRY_DSN:
    'https://d90ce8a4acb847e2a187eb073f1f46ee@o422670.ingest.sentry.io/5355911',
  DEBUG_SENTRY: !!process.env.REACT_APP_DEBUG_SENTRY,
};

export const COUNTRY_CODES_JSON_PATH = `${process.env.PUBLIC_URL}/countryCodeData.json`;
