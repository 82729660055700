export type TOfferType =
  | 'default'
  | 'pioneer'
  | 'gift'
  | 'thebag'
  | 'reward'
  | 'unknown';

export interface IOffer {
  id: string;
  label: string;
  durationInMonth: number;
  multiplierInCents: number;
  createdBy: string;
  createdAt: number;
  updatedAt: number;
  activeAt: number;
  deactivatedAt: number;
  deactivationPaymentBufferInSec: number;
  offerType: TOfferType;
  state: 'notYetActive' | 'active' | 'deactivated';
}

export interface ICreateOffer {
  label: string;
  durationInMonth: string;
  multiplierInCents: string;
  deactivationPaymentBufferInSec: string;
  offerType: TOfferType;
}

export interface IModifiedOffer extends IOffer {
  parsedCreatedAt: string;
  parsedUpdatedAt: string;
  parsedActiveAt: string;
  parsedDeactivatedAt: string;
  parsedMultiplier: string;
  parsedDeactivatedPaymentBuffer: string;
}

export const INITIAL_CreateOffer: ICreateOffer = {
  label: '',
  durationInMonth: '',
  multiplierInCents: '',
  deactivationPaymentBufferInSec: '2419200',
  offerType: 'default',
};
