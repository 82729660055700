import { useCallback } from 'react';

import useContractsStore from './useContractsStore';
import boxContractsPostProcessor from '../helpers/boxContractsPostProcessor';
import { CreateBoxContract } from '../models/boxContract';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import { storeSelector } from '@/helpers/paginatedStoreHelpers';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useCreateBoxContract = () => {
  const [{ loading }, makeRequest] =
    useImperativeRequestWrapper('serviceLpAccount');
  const [, makeRefetch] = useImperativeRequestWrapper('serviceLpAccount');
  const addError = useError();
  const { dispatch, order, sortBy, offset, pageSize, filters } =
    useContractsStore(storeSelector);
  const addToast = useToasts((state) => state.addItem);

  const createBoxContract = useCallback(
    async (body: CreateBoxContract) => {
      const { error, data } = await makeRequest({
        path: '/admin/box-contracts',
        method: 'post',
        body: {
          ...body,
          totalAmountInCents: parseInt(body.totalAmountInCents) * 100,
          numOfBox: parseInt(body.numOfBox),
        },
      });
      if (error) {
        addError(error);

        return { success: false };
      }
      await getPaginatedTableData({
        params: {
          path: '/admin/box-contracts',
          method: 'get',
        },
        reducer: boxContractsPostProcessor,
        dispatch,
        queryParams: {
          pageSize: pageSize,
          offset: offset,
          sortBy: sortBy,
          order: order,
          ...filters,
        },
        queryParamsOptions: { skipEmptyString: true, skipNull: true },
        addError,
        makeRequest: makeRefetch,
      });
      addToast(
        {
          msg: `Successfully created box contract with ID: ${data?.id}`,
          type: MessageType.Success,
          close: true,
        },
        '',
        { duration: 30000 }
      );

      return { success: true };
    },
    [
      makeRequest,
      dispatch,
      offset,
      order,
      sortBy,
      pageSize,
      filters,
      makeRefetch,
      addError,
      addToast,
    ]
  );

  return { createBoxContract, loading };
};

export default useCreateBoxContract;
