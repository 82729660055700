import useRefreshShipments from './useRefreshShipments';
import { ICreatePartnerShipment, ICreateShipment } from '../models/shipment';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

function useCreateShipment(): [
  { loading: boolean },
  (shipment: ICreateShipment) => Promise<{ success: boolean; data?: any }>,
  (
    shipment: ICreatePartnerShipment,
    partnerId: string
  ) => Promise<{ success: boolean; data?: any }>,
] {
  const addError = useError();
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('shipment');
  const successMsg = useToasts((state) => state.addItem);
  const getShipments = useRefreshShipments();

  const createShipment = async (shipment: ICreateShipment) => {
    const { error, data } = await makeRequest({
      path: '/admin/shipments',
      method: 'post',
      body: {
        ...shipment,
        weightInGrams: Math.round(
          parseFloat(shipment.weightInGrams.replace(',', '.')) * 1000
        ),
        serviceLevel: parseInt(shipment.serviceLevel),
        createAsPurchased: true,
        carrierCode: shipment.carrierCode,
      },
    });

    if (error) {
      addError(error);

      return { success: false };
    }

    await getShipments();

    return { success: true, data: data };
  };

  const createPartnerShipment = async (
    shipment: ICreatePartnerShipment,
    partnerId: string
  ) => {
    const { error, data } = await makeRequest({
      path: `/partners/${partnerId}/shipments`,
      method: 'post',
      body: shipment,
    });
    if (error) {
      addError(error, { parseBeErrorMsg: true });

      return { success: false };
    }
    successMsg(
      { msg: 'Shipment was successfully added', type: MessageType.Success },
      'root',
      {}
    );

    await getShipments();

    return { success: true, data };
  };

  return [{ loading }, createShipment, createPartnerShipment];
}

export default useCreateShipment;
