import React, { useCallback, useEffect, useState } from 'react';

import { Col, Radio, Row } from 'antd';
import styled from 'styled-components';

import {
  ChecklistValues,
  TBoxChecklist,
  TModifiedBoxChecklist,
} from '../models/boxChecklist';
import PillButton from '@/components/PillButton';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useRemoteDataWrapper from '@/hooks/useRemoteDataWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const CHECKLIST_COPY: Partial<TBoxChecklist> = {
  eLabelTransmission: 'ELabel Transmission',
  firmware: 'Firmware Version',
  lockCommand: 'Lock Command',
  readState: 'Read Shipment State',
  receiveCommand: 'Receive Command',
  resetCommand: 'Reset Command',
  returnLabel: 'Return Label',
  tracking: 'Tracking',
};

const getKey = (e) => e.target.value.split('.')[0];
const getVal = (e) => e.target.value.split('.')[1];

const checklistReducer = (
  apiData: Partial<TBoxChecklist>
): TModifiedBoxChecklist =>
  Object.keys(CHECKLIST_COPY).reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: {
        text: CHECKLIST_COPY[curr] || '',
        value: apiData[curr] || ChecklistValues.UNKNOWN,
      },
    }),
    {}
  ) as TModifiedBoxChecklist;

const reverseReducer = (state: TModifiedBoxChecklist): Partial<TBoxChecklist> =>
  Object.keys(state).reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: state[curr].value,
    }),
    {}
  ) as TBoxChecklist;

interface IChecklist {
  boxId: string;
}

const BoxChecklist: React.FC<IChecklist> = ({ boxId }) => {
  const [state, setState] = useState<TModifiedBoxChecklist | null>(null);
  const [initialState, setInitialState] =
    useState<TModifiedBoxChecklist | null>(null);

  const { data, error } = useRemoteDataWrapper(
    {
      path: `/boxes/${boxId}/checklist`,
    },
    'device'
  );

  const [{ loading }, makeRequest] = useImperativeRequestWrapper('device');

  const addError = useError();

  useEffect(() => {
    if (error && addError) {
      addError('Box status could not be fetched');
    }
  }, [addError, error]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const reduced = checklistReducer(data);
    setState(reduced);
    setInitialState(reduced);
  }, [data]);

  const handleChange = useCallback((e) => {
    setState((prev) => {
      if (!prev) {
        return null;
      }

      return {
        ...prev,
        [getKey(e)]: { ...prev[getKey(e)], value: getVal(e) },
      };
    });
  }, []);

  const successMsg = useToasts((state) => state.addItem);

  // no useCallback here because updates on every state change anyway
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!state) {
      return;
    }
    const { error } = await makeRequest({
      path: `/boxes/${boxId}/checklist`,
      method: 'patch',
      body: reverseReducer(state),
    });
    if (error) {
      addError('Could not update box status');

      return;
    }
    successMsg(
      { msg: 'Successfully updated Checklist', type: MessageType.Success },
      'root',
      {}
    );
    setInitialState(state);
  };

  const handleCancel = useCallback(
    (e) => {
      e.preventDefault();
      setState(initialState);
    },
    [initialState]
  );

  if (!boxId || !state) {
    return null;
  }

  return (
    <>
      {Object.keys(CHECKLIST_COPY).map((key) => (
        <Row key={key} gutter={24}>
          <Col span={6}>
            <p>{state[key]?.text}</p>
          </Col>
          <Col>
            <Radio.Group
              defaultValue={`${key}.${state[key].value}`}
              value={`${key}.${state[key].value}`}
              onChange={handleChange}
            >
              <Radio value={`${key}.${ChecklistValues.OK}`}>
                {ChecklistValues.OK}
              </Radio>
              <StyledRadio color="red" value={`${key}.${ChecklistValues.NOT}`}>
                {ChecklistValues.NOT}
              </StyledRadio>
              <StyledRadio
                color="orange"
                value={`${key}.${ChecklistValues.UNKNOWN}`}
              >
                {ChecklistValues.UNKNOWN}
              </StyledRadio>
            </Radio.Group>
          </Col>
        </Row>
      ))}
      <Row gutter={[24, 24]}>
        <Col>
          <PillButton type="ghost" onClick={handleCancel}>
            Cancel
          </PillButton>
        </Col>
        <Col>
          <PillButton onClick={handleSubmit} loading={loading}>
            Submit
          </PillButton>
        </Col>
      </Row>
    </>
  );
};

export default BoxChecklist;

// MARKUP
const StyledRadio = styled(Radio)<{ color: string }>`
  & .ant-radio-inner {
    border-color: ${({ color }) => `${color} !important`};
    &::after {
      border-color: ${({ color }) => `${color} !important`};
      background-color: ${({ color }) => `${color} !important`};
    }
  }
  & .ant-radio-checked::after {
    border-color: ${({ color }) => `${color} !important`};
  }
`;
