import { useCallback, useEffect, useState } from 'react';

import {
  useRefreshAccounts,
  INITIAL_UserWithRoles,
  IUserWithRoles,
} from '@/features/lp-account-users';
import { IRole, EMPTY_role } from '@/features/lp-account-users';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useUpdateAccountRole = (
  id,
  domain,
  closeModal,
  setFooter,
  viewModalAccount
) => {
  const addError = useError();
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpVillage');
  const successMsg = useToasts((state) => state.addItem);
  const [selectedRole, setSelectedRole] = useState<IRole>(EMPTY_role);
  const [account, setAccount] = useState<IUserWithRoles>(INITIAL_UserWithRoles);
  const getAccounts = useRefreshAccounts({ id, domain });

  const updateRole = useCallback(
    async (role: IRole, accountId: string) => {
      const { error, data } = await makeRequest({
        path: `/admin/${domain}/${id}/accounts/${accountId}`,
        method: 'put',
        body: { roleId: role.id },
      });
      if (error) {
        addError(error);

        return { success: false };
      }
      successMsg(
        { msg: 'Role was successfully updated!', type: MessageType.Success },
        'root',
        {}
      );

      return { success: true, data: data };
    },
    [makeRequest, successMsg, addError, id, domain]
  );

  const handleSubmitRoles = useCallback(async () => {
    setFooter('viewEditDelete');
    const res = await updateRole(selectedRole, account.id);
    if (res.success) {
      setAccount({ ...account, ...res.data, id: res.data.accountId });
      await getAccounts();
      closeModal();
    } else {
      setFooter('edit');
    }
  }, [setFooter, closeModal, selectedRole, updateRole, account, getAccounts]);

  useEffect(() => {
    if (!viewModalAccount) {
      setAccount(INITIAL_UserWithRoles);
      setSelectedRole(EMPTY_role);

      return;
    }
    setSelectedRole(viewModalAccount.roles || EMPTY_role);
    setAccount(viewModalAccount);
  }, [viewModalAccount]);

  return {
    loading,
    selectedRole,
    setSelectedRole,
    account,
    handleSubmitRoles,
  };
};
export default useUpdateAccountRole;
