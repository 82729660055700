import has from 'lodash/has';

import { PaginatedResponse } from '../hooks/useGetBoxConfigHistory';
import { BoxConfigHistoryItem } from '../models/boxConfig';
import dates from '@/helpers/dates';

export const boxConfigHistoryPostProcessor = (
  data: PaginatedResponse<BoxConfigHistoryItem>
) => {
  const parsedItems = data.items.map((item) => ({
    ...item,
    parsedCreatedAt: has(item, 'createdAt')
      ? dates.parseDate(item.createdAt)
      : '',
    createdByType: has(item, 'createdBy.email')
      ? item.createdBy.email
      : item.createdBy.id,
    type: item.createdBy.type,
  }));

  return { ...data, items: parsedItems };
};
