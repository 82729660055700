import React from 'react';

import { Col, Descriptions, Row, Typography } from 'antd';

interface billingAddress {
  city: string;
  company: string;
  country_code: string;
  created_at: string;
  created_by: string;
  display_name: string;
  first_name: string;
  house_number: number;
  id: string;
  last_name: string;
  original_address_id: string;
  position: Object;
  postal_code: string;
  state: string;
  street: string;
  street2: string;
  updated_at: string;
  version_hash: string;
}
const TransactionModalAddress = ({ address }: { address: billingAddress }) => (
  <Row gutter={24}>
    <Col></Col>
    <Descriptions>
      <Descriptions.Item label="First Name">
        <Typography.Text>{address?.first_name}</Typography.Text>
      </Descriptions.Item>
      <Descriptions.Item label="Last Name">
        <Typography.Text>{address?.last_name}</Typography.Text>
      </Descriptions.Item>
    </Descriptions>
    <Descriptions>
      <Descriptions.Item label="street">
        <Typography.Text>{address?.street}</Typography.Text>
      </Descriptions.Item>
    </Descriptions>
    <Descriptions>
      <Descriptions.Item label="street2">
        <Typography.Text>{address?.street2}</Typography.Text>
      </Descriptions.Item>
    </Descriptions>
    <Descriptions>
      <Descriptions.Item label="Postal code">
        <Typography.Text>{address?.postal_code}</Typography.Text>
      </Descriptions.Item>
      <Descriptions.Item label="City">
        <Typography.Text>{address?.city}</Typography.Text>
      </Descriptions.Item>
    </Descriptions>
  </Row>
);

export default TransactionModalAddress;
