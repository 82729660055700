import { useCallback, useMemo, useState } from 'react';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useSaveAccountNote: any = (accountId, getUser, notes) => {
  const addError = useError();
  const [, makeRequest] = useImperativeRequestWrapper('serviceLpAccount');
  const successMsg = useToasts((state) => state.addItem);
  const [newNote, setNewNote] = useState<string | null>(null);

  const addNote = useCallback(
    async (note) => {
      const { error } = await makeRequest({
        path: `/admin/accounts/${accountId}/note`,
        method: 'post',
        body: {
          note: note,
        },
      });
      if (error) {
        addError(error);

        return { success: false };
      }

      successMsg(
        { msg: 'The note was successfully added', type: MessageType.Success },
        'root',
        {}
      );

      return { success: true };
    },
    [makeRequest, accountId, addError, successMsg]
  );

  const handleSaveNote = useCallback(async () => {
    await addNote(newNote);
    await getUser();
  }, [addNote, newNote, getUser]);

  const handleCancelNote = useCallback(() => {
    if (notes && notes.length) {
      setNewNote(notes[0].note);
    } else {
      setNewNote(null);
    }
  }, [notes]);

  const handleSetNote = useCallback((e) => {
    setNewNote(e.target.value);
  }, []);

  const noteHasBeenModified = useMemo(() => {
    if (notes && notes.length && newNote) {
      return newNote !== notes[0].note;
    }

    return newNote;
  }, [newNote, notes]);

  return [
    handleSaveNote,
    handleCancelNote,
    handleSetNote,
    newNote,
    setNewNote,
    noteHasBeenModified,
  ];
};
export default useSaveAccountNote;
