import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useGetSingleAccount = () => {
  const [, makeRequest] = useImperativeRequestWrapper('serviceLpAccount');

  const getSingleAccount = async (searchEmail) => {
    const queryString = new URLSearchParams({
      email: searchEmail,
    }).toString();
    const { error, data } = await makeRequest({
      path: `/admin/accounts/search-id?${queryString}`,
      method: 'get',
    });
    if (error || !data) {
      return { success: false, error: error };
    }

    return { success: true, id: data.id };
  };

  return getSingleAccount;
};

export default useGetSingleAccount;
