import React from 'react';

import Dropdown from '@/components/forms/Dropdown';
import Label from '@/components/forms/Label';

const TYPES = [
  { name: 'PaaS' },
  { name: 'website' },
  { name: 'financial_models' },
];

const ServiceTpeTosSelector = ({
  onChange,
  value,
}: {
  onChange: any;
  value?: { name: string };
}) => (
  <>
    <Label>Service Type *</Label>
    <Dropdown
      options={TYPES}
      getOptionLabel={(option) => option.name}
      mw="35rem"
      onChange={onChange}
      value={value}
    />
  </>
);

export default ServiceTpeTosSelector;
