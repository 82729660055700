import React, { useCallback, useMemo, useState } from 'react';

import DragAndDrop from '@/components/DragAndDrop';
import Spacer from '@/components/layout/Spacer';
import useError from '@/hooks/useError';

export const SupportedLangs = ['en', 'de', 'fr'] as const;

export type TSupportedLangs = (typeof SupportedLangs)[number];

export type TFiles = { [key in TSupportedLangs]: File | null };

interface IToSDndUpload {
  lang: TSupportedLangs;
  setLangFile: (lang: TSupportedLangs, file: [File] | null) => void;
  files: TFiles;
}
export const useTosLogic = (INITIAL_STATE: TFiles) => {
  const [files, setFiles] = useState<TFiles>(INITIAL_STATE);

  const addError = useError();

  const isInvalid = useMemo(
    () => Object.values(files).some((val) => !val),
    [files]
  );

  const setLangFile = useCallback(
    (lang: 'en' | 'de' | 'fr', file: [File] | null) => {
      if (!file) {
        setFiles((prev) => ({ ...prev, [lang]: null }));

        return;
      }
      if (file[0].name.substr(0, 2) !== lang) {
        addError(`File needs to have format ${lang}_<name>.pdf`);

        return;
      }

      setFiles((prev) => ({ ...prev, [lang]: file[0] }));
    },
    [addError]
  );

  const resetFiles = useCallback(
    () => setFiles(INITIAL_STATE),
    [INITIAL_STATE]
  );

  return { isInvalid, files, setLangFile, resetFiles };
};

const ToSDndUpload = ({ lang, setLangFile, files }: IToSDndUpload) => (
  <DragAndDrop
    height="7rem"
    onDrop={(file) => setLangFile(lang, file)}
    multiple={false}
    accept={{ 'application/pdf': ['.pdf'] }}
    text={`Add ${lang.toUpperCase()} language file here, formatted as ${lang}_<name>.pdf`}
    removeFiles={() => setLangFile(lang, null)}
    fileDropped={!!files[lang]}
  />
);

export const TosUploads = (props: Omit<IToSDndUpload, 'lang'>) => (
  <>
    {SupportedLangs.map((lang) => (
      <div key={lang}>
        <ToSDndUpload lang={lang} {...props} /> <Spacer v="1rem" />
      </div>
    ))}
  </>
);
