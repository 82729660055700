import { useState } from 'react';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useGetELabelShipmentBinary = () => {
  const addError = useError();
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('shipment');
  const [data, setData] = useState<string | null>(null);

  const fetch = async (id) => {
    const { error, data } = await makeRequest({
      path: `/shipments/${id}/elabel/binary?format=BMP`,
      method: 'get',
    });
    if (error) {
      addError(error);

      return { success: false };
    }

    const processedData = new Buffer(data, 'binary').toString('base64');
    setData(processedData);
  };

  return {
    fetch,
    data,
    loading,
  };
};
export default useGetELabelShipmentBinary;
