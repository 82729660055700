import React from 'react';

import { withTheme } from 'styled-components';

const BusinessIcon = (props) => (
  <svg
    width={22}
    height={29}
    viewBox="0 0 22 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={21.5}
      y={0.5}
      width={28}
      height={21}
      rx={0.5}
      transform="rotate(90 21.5 .5)"
      stroke="#000"
    />
    <path
      d="m9.5 25.5 2-1.5 2.5 1.5 2.5-1.5"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      stroke="#000"
      strokeLinecap="round"
      d="M5.5 6.5h8M5.5 11.5h11M5.5 15.5h11M5.5 19.5h11"
    />
  </svg>
);

export default withTheme(BusinessIcon);
