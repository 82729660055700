import React, { useEffect } from 'react';

import { ColumnProps } from 'antd/lib/table';
import styled from 'styled-components';

import AddAccountModal from '../../components/AddAccountModal';
import useAddAccountToShop from '../hooks/useAddAccountToShop';
import {
  useGetAccounts,
  useLPUsersStore,
  useRefreshAccounts,
  ViewAccountModal,
  LPAccountUsersTable,
} from '@/features/lp-account-users';
import { getRandomInt } from '@/helpers/utilities';
import useAntdColumns from '@/hooks/useAntdColumns';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const WrapperButton = styled.div`
  margin-bottom: 1rem;
`;

const WrapperAccountsTableShop = ({ id }) => {
  const defaultSort = useLPUsersStore((state) => state.defaultSort);
  const dispatch = useLPUsersStore((state) => state.dispatch);
  const getShopAccounts = useRefreshAccounts({ id, domain: 'shops' });
  useGetAccounts({ id, domain: 'shops' });
  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: ['accountId', 'email', 'firstName', 'lastName', 'roleName'],
    columnsSpecialProps: {
      email: { width: '20rem' },
    },
    addDefaultColumns: true,
    defaultSort: defaultSort,
    eyeLinkProps: {
      onClick: (record) => {
        dispatch({
          type: 'updateViewModal',
          args: {
            viewModalAccount: record,
          },
        });
      },
    },
  });
  useEffect(() => () => dispatch({ type: 'reset' }), [dispatch]);

  return (
    <Wrapper>
      <WrapperButton>
        <AddAccountModal
          id={id}
          useAddAccount={useAddAccountToShop}
          onSuccess={getShopAccounts}
          domain="shops"
        />
      </WrapperButton>
      <LPAccountUsersTable
        columns={columns}
        rowKey={(record) => record.id + getRandomInt(1000)}
      />
      <ViewAccountModal id={id} domain="shops" onSuccess={getShopAccounts} />
    </Wrapper>
  );
};
export default WrapperAccountsTableShop;
