import { useCallback, useContext } from 'react';

import { find } from 'lodash';

import { IArticle } from '../models/box';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';
import { ArticlesContext } from '@/shared/providers/articlesProvider';

const useEditProduct = () => {
  const articles = useContext(ArticlesContext) as IArticle[];
  const [{ loading: editLoading }, makeEditeRequest] =
    useImperativeRequestWrapper('device');
  const successMsgToast = useToasts((state) => state.addItem);
  const errorMsgToast = useToasts((state) => state.addItem);

  const editProduct = useCallback(
    async ({ LpUI, makeRequest, method, body = {}, errorMsg, successMsg }) => {
      const { error, data } = await makeRequest({
        path: `/products/${LpUI}`,
        method,
        body,
      });
      if (error) {
        errorMsgToast({ msg: errorMsg, type: MessageType.Error }, 'root', {});

        return { success: false };
      }
      successMsgToast(
        { msg: successMsg, type: MessageType.Success },
        'root',
        {}
      );
      data.productDescription =
        find(articles, ['code', data.articleCode])?.description || 'Unknown';

      return { success: true, data: data };
    },
    [successMsgToast, errorMsgToast, articles]
  );

  const editProductType = async (LpUI: string, data: any) => {
    const success = await editProduct({
      LpUI,
      makeRequest: makeEditeRequest,
      body: { ...data },
      method: 'PATCH',
      errorMsg: 'An error occured: the product type has not been updated',
      successMsg: 'The product type has been succefully updated',
    });

    return success;
  };

  return { editLoading, editProductType };
};

export default useEditProduct;
