import React, { useState, useRef, useCallback, useEffect } from 'react';

import { format } from 'date-fns';
import isEmpty from 'lodash/fp/isEmpty';
import get from 'lodash/get';
import { CSVLink } from 'react-csv';

import PillButton from '@/components/PillButton';

const headers = [
  { label: 'Contribution-ID', key: 'id' },
  { label: 'Account-ID', key: 'accountId' },
  { label: 'Created At', key: 'parsedCreatedAt' },
  { label: 'Updated At', key: 'parsedUpdatedAt' },
  { label: 'Total Amount', key: 'parsedTotalAmount' },
  { label: 'Payment Unique ID', key: 'paymentUniqueId' },
  { label: 'Payment Status', key: 'paymentStatus' },
  { label: 'Payment Method', key: 'paymentMethod' },
  { label: 'Payment Date', key: 'parsedPaymentDate' },
  { label: 'State', key: 'state' },
  { label: 'Offer-ID', key: 'parsedOffer.id' },
  { label: 'Offer Type', key: 'parsedOffer.offerType' },
  { label: 'Offer Multiplier', key: 'parsedOffer.parsedMultiplier' },
  { label: 'Contract Address First Name', key: 'address.firstName' },
  {
    label: 'Contract Address Last Name',
    key: 'address.lastName',
  },
  {
    label: 'Contract Address Company',
    key: 'address.company',
  },
];

const ContributionsCSVReport = ({
  getContributionsData,
  isInvalid,
  numberOfRecords,
}) => {
  const [csvLoading, setCsvLoading] = useState(false);
  const [csvData, setCsvData] = useState(null);
  const csvLink = useRef<any>();

  const handleCsvClick = useCallback(async () => {
    setCsvLoading(true);
    try {
      const result = await getContributionsData(numberOfRecords);
      setCsvData(result);
    } catch (err) {
      console.error(err);
    }
    setCsvLoading(false);
  }, [getContributionsData, numberOfRecords]);

  // Trigger download every time csvData changes
  useEffect(() => {
    if (isEmpty(csvData)) return;

    get(csvLink, 'current.link').click();
  }, [csvData]);

  return (
    <>
      <CSVLink
        data={csvData || []}
        headers={headers}
        filename={`contributions_${format(new Date(), 'HHmm-ddMMyyyy')}.csv`}
        separator=";"
        ref={csvLink}
      />
      <PillButton
        loading={csvLoading}
        disabled={csvLoading || isInvalid}
        onClick={handleCsvClick}
      >
        Download CSV Report
      </PillButton>
    </>
  );
};

export default ContributionsCSVReport;
