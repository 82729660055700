import React, { useMemo } from 'react';

import { Button, Tooltip } from 'antd';
import styled from 'styled-components';

const ButtonWrapper = styled.span`
  margin-left: 8px;
`;

const Span = styled.span`
  margin-right: 8px;
`;

interface IViewEditFooterModal {
  footerMode;
  editText?: string;
  deleteText?: string;
  isInvalid: boolean;
  loading: boolean;
  handleSubmit: any;
  handleDelete?: any;
  setFooterMode: any;
  closeModal: any;
  disableEditDelete?: boolean; //Remove when endpoint ready (edit partner addresses)
  disableEdit?: boolean; //Remove when endpoint ready (add role to partner account)
  onEditCancel?: any;
}

const ViewEditFooterModalFactory = ({
  footerMode,
  editText,
  deleteText,
  isInvalid,
  loading,
  handleSubmit,
  handleDelete,
  setFooterMode,
  closeModal,
  disableEditDelete,
  disableEdit,
  onEditCancel,
}: IViewEditFooterModal) => {
  const EditButton = useMemo(() => {
    if (isInvalid) {
      return (
        <ButtonWrapper key="submit">
          <Tooltip placement="topRight" title="Please fill all required fields">
            <Button type="primary" disabled={true}>
              Save
            </Button>
          </Tooltip>
        </ButtonWrapper>
      );
    }

    return (
      <Button
        key="save"
        type="primary"
        loading={loading}
        onClick={handleSubmit}
      >
        Save
      </Button>
    );
  }, [isInvalid, handleSubmit, loading]);
  const defaultView = useMemo(() => {
    if (handleDelete && handleSubmit) {
      return 'viewEditDelete';
    }
    if (handleDelete) {
      return 'viewDelete';
    }
    if (handleSubmit) {
      return 'viewEdit';
    }

    return 'view';
  }, [handleSubmit, handleDelete]);

  switch (footerMode) {
    case 'edit':
      return [
        <Button
          key="cancel"
          onClick={() => {
            onEditCancel && onEditCancel();
            setFooterMode(defaultView);
          }}
        >
          Cancel
        </Button>,
        EditButton,
      ];
    case 'delete':
      return [
        <Span key="message">Are you sure ?</Span>,
        <Button key="no" onClick={() => setFooterMode(defaultView)}>
          No
        </Button>,
        <Button key="yes" danger onClick={handleDelete} loading={loading}>
          Yes
        </Button>,
      ];
    case 'viewEdit':
      return [
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          key="edit"
          type="primary"
          onClick={() => setFooterMode('edit')}
          disabled={disableEdit}
        >
          {editText || 'Edit'}
        </Button>,
      ];
    case 'viewDelete':
      return [
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button key="delete" danger onClick={handleDelete}>
          {deleteText || 'Delete'}
        </Button>,
      ];
    case 'viewEditDelete':
      return [
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          key="delete"
          danger
          onClick={() => setFooterMode('delete')}
          loading={loading}
          disabled={disableEditDelete}
        >
          {deleteText || 'Delete'}
        </Button>,
        <Button
          key="edit"
          type="primary"
          onClick={() => setFooterMode('edit')}
          disabled={disableEditDelete || disableEdit}
        >
          {editText || 'Edit'}
        </Button>,
      ];
    case 'view':
      return [
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
      ];
  }
};

export default ViewEditFooterModalFactory;
