import { useMemo } from 'react';

import axios, { AxiosInstance } from 'axios';

import axiosResponseInterceptor from './axiosResponseInterceptor';
import { APIServices, TAPIService } from '../configs/app';
import { useAuthToken } from '../hooks/useAuthToken';

export const ApiClients = Object.entries(APIServices).reduce(
  (acc, [key, url]) => {
    acc[key] = (token: string) =>
      axios.create({
        baseURL: url,
        headers: { Authorization: `Bearer ${token}` },
      });

    return acc;
  },
  {} as Record<TAPIService, (token: string) => AxiosInstance>
);

export function useApiClient(key: TAPIService): AxiosInstance {
  const token = useAuthToken();
  const client = useMemo(() => ApiClients[key](token), [key, token]);
  axiosResponseInterceptor(client);

  return client;
}
