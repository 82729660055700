import React, { useEffect, useState } from 'react';

import useGetTotals from '../hooks/useGetTotals';

export const VillageTotalContext = React.createContext({});

const VillageTotalProvider = ({ children }) => {
  const [data, setData] = useState<number>(0);
  const { getVillageTotal } = useGetTotals();

  useEffect(() => {
    async function asyncWrapper() {
      const total = await getVillageTotal();
      setData(total);
    }
    asyncWrapper();
  }, [getVillageTotal]);

  const { Provider } = VillageTotalContext;

  return <Provider value={data}>{children}</Provider>;
};

export default VillageTotalProvider;
