import React from 'react';

import { Spin } from 'antd';
import styled from 'styled-components';
const Wrapper = styled.div`
  text-align: center;
  padding: 2rem;
`;

const SpinLoader = () => (
  <Wrapper>
    <Spin />
  </Wrapper>
);

export default SpinLoader;
