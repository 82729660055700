import React, { useEffect } from 'react';

import InvitationCodesTable from './InvitationCodesTable';
import invitationCodesPostProcessor from '../helpers/invitationCodesProcessor';
import useAccountInvitationsStore from '../hooks/useAccountInvitationsStore';
import usePaginatedTableData from '@/hooks/usePaginatedTableData';

const LPAccountInvitationCodes = ({ id }) => {
  const rowData = useAccountInvitationsStore((state) => state.rowData);
  const loading = useAccountInvitationsStore((state) => state.loading);
  const pageSize = useAccountInvitationsStore((state) => state.pageSize);
  const offset = useAccountInvitationsStore((state) => state.offset);
  const sortBy = useAccountInvitationsStore((state) => state.sortBy);
  const order = useAccountInvitationsStore((state) => state.order);
  const total = useAccountInvitationsStore((state) => state.total);
  const filters = useAccountInvitationsStore((state) => state.filters);
  const dispatch = useAccountInvitationsStore((state) => state.dispatch);

  const { onlyActive, invitationTypes } = filters;

  usePaginatedTableData({
    params: {
      path: `/admin/invitation-codes`,
      method: 'get',
      reducer: invitationCodesPostProcessor,
    },
    dispatch,
    queryParams: {
      pageSize,
      offset,
      sortBy,
      order,
      onlyActive,
      invitationTypes,
      issuerUserId: id,
    },
    service: 'serviceLpAccount',
  });

  useEffect(() => () => dispatch({ type: 'reset' }), [dispatch]);

  return (
    <InvitationCodesTable
      rowData={rowData}
      pageSize={pageSize}
      offset={offset}
      total={total}
      loading={loading}
      dispatch={dispatch}
      showSizeChanger={false}
    />
  );
};

export default LPAccountInvitationCodes;
