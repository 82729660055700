import React, { useCallback, useState } from 'react';

import useSearchShop from '../hooks/useSearchShop';
import SearchObject from '@/components/SearchObject';
import useError from '@/hooks/useError';

interface ISearchShop {
  disabled?: boolean;
  shopId?: string;
  setShopId: (id: string) => void;
}

const SearchShop = ({ disabled, shopId, setShopId }: ISearchShop) => {
  const addError = useError();
  const getSingleShop = useSearchShop();
  const [searchField, setSearchField] = useState<string>('');

  const handleFindName = useCallback(
    (searchName: string) => {
      if (searchName) {
        getSingleShop(searchName).then((res) => {
          if (!res.success) {
            res.size === 0
              ? addError('No shop found')
              : addError('Multiple shops found');
          } else {
            setShopId(res.shop.id);
            setSearchField(res.shop.name);
          }
        });
      }
    },
    [addError, getSingleShop, setShopId]
  );

  return (
    <SearchObject
      objectId={shopId}
      setObjectId={setShopId}
      searchField={searchField}
      setSearchField={setSearchField}
      disabled={disabled}
      placeholder="Name..."
      searchLabel="Shop (search by name)"
      foundLabel="Shop"
      handleFind={handleFindName}
    />
  );
};

export default SearchShop;
