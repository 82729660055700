import styled, { keyframes, css } from 'styled-components';

const loadKS = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

interface ISimpleCircle {
  bg?: string;
  size?: string;
  duration?: string;
  lineWidth?: string;
}

const defaults = {
  size: '2rem',
  color: '#25b676',
  bg: '#fff',
  lineWidth: '0.2rem',
  duration: '2s',
};

const SimpleCircle = styled.div<ISimpleCircle>`
  transform-origin: center center;
  border: ${({ bg, lineWidth }) =>
    `${lineWidth || defaults.lineWidth} solid ${bg || defaults.bg}`};
  border-right-color: ${({ color }) => color || defaults.color};
  width: ${({ size }) => size || defaults.size};
  height: ${({ size }) => size || defaults.size};
  border-radius: 50%;
  animation: ${({ duration }) => css`
    ${loadKS} ${duration || defaults.duration} infinite ease-out
  `};
  box-sizing: border-box;
  flex-grow: 0;
  flex-shrink: 0;
`;

export default SimpleCircle;
