import React, { useCallback, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import ServiceTypeTosSelector from './ServiceTypeTosSelector';
import {
  SupportedLangs,
  TFiles,
  TosUploads,
  useTosLogic,
} from './ToSFileUploads';
import useCreateTos from '../hooks/useCreateTos';
import { TServiceType } from '../models/tos';
import CancelSubmitFooterModal from '@/components/CancelSubmitFooterModal';
import CustomModal from '@/components/CustomModal';
import DateTimePicker from '@/components/forms/DateTimePicker';
import Label from '@/components/forms/Label';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';

const WrapperTime = styled(Label)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
  margin: 1.5rem 0;
`;

export const EMPTY_FIlES = SupportedLangs.reduce((accum, currentValue) => {
  accum[currentValue] = null;

  return accum;
}, {}) as TFiles;

const CreateTos = () => {
  const { isInvalid, setLangFile, files, resetFiles } =
    useTosLogic(EMPTY_FIlES);

  const { loading, uploadTos } = useCreateTos();
  const [visible, setVisible] = useState<boolean>(false);
  const [serviceType, setServiceType] = useState<TServiceType>('PaaS');
  const [activeAt, setActiveAt] = useState<number | null>(null);
  const openModal = useCallback(() => setVisible(true), []);
  const closeModal = useCallback(() => {
    setVisible(false);
    setServiceType('PaaS');
    resetFiles();
    setActiveAt(null);
  }, [resetFiles]);

  const handleSubmit = useCallback(async () => {
    const { success } = await uploadTos(files, serviceType, activeAt);
    success && closeModal();
  }, [files, uploadTos, closeModal, serviceType, activeAt]);

  const modalFooterButtons = CancelSubmitFooterModal(
    isInvalid,
    handleSubmit,
    closeModal,
    loading
  );

  // TODO: is there a specific scope ?
  return (
    <>
      <ScopedCta
        component={PillButton}
        icon={<PlusOutlined />}
        onClick={openModal}
        requiredScopes={[scopes.MANAGE_TOS]}
      >
        Upload ToS
      </ScopedCta>

      <CustomModal
        title="Upload new ToS version"
        open={visible}
        destroyOnClose={true}
        footer={modalFooterButtons}
        onCancel={closeModal}
      >
        <ServiceTypeTosSelector
          onChange={(e) => setServiceType(e.name)}
          value={{ name: serviceType }}
        />
        <WrapperTime>
          <Label>Active At</Label>
          <DateTimePicker
            setUnixTimeStamp={setActiveAt}
            useDefaultTime={false}
            withTime={false}
            disabledPastDate={true}
          />
        </WrapperTime>
        <TosUploads setLangFile={setLangFile} files={files} />
      </CustomModal>
    </>
  );
};

export default CreateTos;
