import React, { useMemo, useState } from 'react';

import useBoxOffersStore from '../hooks/useBoxOffersStore';
import useEditBoxOffer from '../hooks/useEditBoxOffer';
import useRefreshBoxContracts from '../hooks/useRefreshBoxContracts';
import CustomModal from '@/components/CustomModal';
import Error from '@/components/Error';
import DateTimePicker from '@/components/forms/DateTimePicker';
import Label from '@/components/forms/Label';
import Spacer from '@/components/layout/Spacer';

const EditOfferDates = () => {
  const state = useBoxOffersStore((state) => state.additionalState);
  const dispatch = useBoxOffersStore((state) => state.dispatch);
  const isOpen = !!(
    state?.editActivationDateId || state?.editDeactivationDateId
  );

  const refresh = useRefreshBoxContracts();

  const { activateLoading, deactivateLoading, activateOffer, deactivateOffer } =
    useEditBoxOffer();

  const [date, setDate] = useState<number | null>(null);

  const isInvalidDate = !!(date && date < Date.now() / 1000);

  const handleClose = () => {
    setDate(null);
    dispatch({
      type: 'updateAdditionalState',
      args: {
        additionalState: {
          editActivationDateId: null,
          editDeactivationDateId: null,
        },
      },
    });
  };

  const isActivation = !!state?.editActivationDateId;

  const COPY = useMemo(
    () =>
      isActivation
        ? {
            title: 'Set/Edit Activation Date',
            label: 'Activation Date',
          }
        : {
            title: 'Set/Edit Deactivation Date',
            label: 'Deactivation Date',
          },
    [isActivation]
  );

  const handleSubmit = async () => {
    if (!date) {
      return;
    }
    if (isActivation) {
      const { success } = await activateOffer(
        state?.editActivationDateId || '',
        date
      );
      if (success) {
        handleClose();
        await refresh();
      }

      return;
    }
    const { success } = await deactivateOffer(
      state?.editDeactivationDateId || '',
      date
    );
    if (success) {
      handleClose();
      await refresh();
    }
  };

  return (
    <CustomModal
      title={COPY.title}
      width="30rem"
      open={isOpen}
      onCancel={handleClose}
      destroyOnClose={true}
      okText="Submit"
      okButtonProps={{
        disabled: !date || isInvalidDate,
        loading: activateLoading || deactivateLoading,
      }}
      onOk={handleSubmit}
    >
      <Label>{COPY.label}:</Label>
      <Spacer v="1rem" />
      <DateTimePicker
        setUnixTimeStamp={setDate}
        useDefaultTime={false}
        disabledPastDate={true}
      />
      <Spacer v="1rem" />
      {isInvalidDate && <Error message="Date/time cannot be in the past!" />}
    </CustomModal>
  );
};

export default EditOfferDates;
