import React, { useState } from 'react';

import {
  FormControl,
  Input,
  Button,
  ButtonGroup,
  FormLabel,
  VStack,
  Select,
  FormErrorMessage,
  HStack,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';

import { DatetimePicker } from './DatetimePicker';
import { NumberInput } from './NumberInput';
import { BoxContractUpdateRequest } from '../hooks/useUpdateBoxContract';
import {
  BoxContract,
  BoxContractStates,
  PaymentMethods,
  PaymentProcessors,
  PaymentStatuses,
} from '../models/boxContract';
import { editBoxContractSchema } from '../schemas/boxContractSchema';
import FormRow from '@/components/forms/FormRow';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';
import {
  LpAccountAddressSelector,
  SearchAccount,
} from '@/features/lp-account-users';

export interface EditBoxContractFormProps {
  contract: BoxContract;
  onSubmit: (contract: BoxContractUpdateRequest) => void | Promise<void>;
}

export function EditBoxContractForm({
  contract,
  onSubmit,
}: EditBoxContractFormProps) {
  const initialValues = getInitialValues(contract);
  const [showLinkAccount, setShowLinkAccount] = useState<boolean>(false);

  return (
    <Formik<EditBoxContractFormValues>
      initialValues={initialValues}
      validationSchema={editBoxContractSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await onSubmit({
          ...values,
          numOfBox: parseInt(values.numOfBox),
          totalAmountInCents: parseFloat(values.totalAmount) * 100,
          email: values.accountId ? '' : values.email,
        });
        setSubmitting(false);
      }}
    >
      {({
        handleSubmit,
        handleReset,
        handleChange,
        handleBlur,
        values,
        isSubmitting,
        dirty,
        setFieldValue,
        setTouched,
        errors,
        touched,
        isValid,
      }) => (
        <form onSubmit={handleSubmit} onReset={handleReset} noValidate>
          <VStack alignItems="flex-start" spacing={6}>
            <HStack>
              <Button onClick={() => setShowLinkAccount((prev) => !prev)}>
                {showLinkAccount ? 'Cancel' : 'Link Account'}
              </Button>
              {contract.accountId && (
                <Link to={`/lp-account/${contract.accountId}`}>
                  <Button> To LP-Account</Button>
                </Link>
              )}
            </HStack>
            <FormRow>
              {showLinkAccount && (
                <FormControl
                  id="accountId"
                  isInvalid={
                    errors.accountId !== undefined && touched.accountId
                  }
                >
                  <SearchAccount
                    accountId={touched.accountId ? values.accountId : ''}
                    setAccountId={(value) => {
                      setFieldValue('accountId', value);
                      setTouched({ accountId: true });
                    }}
                  />
                </FormControl>
              )}
              {values.accountId !== '' &&
                touched.accountId &&
                showLinkAccount && (
                  <FormControl
                    id="addressId"
                    isInvalid={
                      errors.addressId !== undefined && touched.accountId
                    }
                  >
                    <LpAccountAddressSelector
                      onChange={(value) => setFieldValue('addressId', value)}
                      accountId={values.accountId}
                      addressId={values.addressId}
                    />
                  </FormControl>
                )}
            </FormRow>
            {contract.accountId !== '' && (
              <FormRow>
                <VStack width="50%" alignItems="flex-start">
                  <FormLabel>First Name</FormLabel>
                  <Input name="firstName" value={contract.firstName} disabled />
                </VStack>
                <VStack width="50%" alignItems="flex-start">
                  <FormLabel>Last Name</FormLabel>
                  <Input name="lastName" value={contract.lastName} disabled />
                </VStack>
              </FormRow>
            )}
            <FormRow>
              <FormControl
                id="email"
                isInvalid={errors.email !== undefined && touched.email}
              >
                <FormLabel>Email</FormLabel>
                <Input
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={contract.accountId !== '' || showLinkAccount}
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>
            </FormRow>
            <FormRow>
              <FormControl
                id="acceptedTosVersion"
                isInvalid={
                  errors.acceptedTosVersion !== undefined &&
                  touched.acceptedTosVersion
                }
              >
                <FormLabel>Accepted ToS version</FormLabel>
                <Input
                  name="acceptedTosVersion"
                  value={values.acceptedTosVersion}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormErrorMessage>{errors.acceptedTosVersion}</FormErrorMessage>
              </FormControl>
            </FormRow>
            <FormRow>
              <FormControl
                id="activatedAt"
                isInvalid={
                  errors.activatedAt !== undefined &&
                  touched.activatedAt !== undefined
                }
              >
                <FormLabel>Activated At</FormLabel>
                <DatetimePicker
                  name="activatedAt"
                  id="activatedAt"
                  value={values.activatedAt}
                  dateFormat="dd/MM/yyyy HH:mm"
                  onBlur={handleBlur}
                  onChange={(date) => {
                    setFieldValue('activatedAt', date);
                  }}
                />

                <FormErrorMessage>
                  <>{errors.activatedAt}</>
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="state"
                isInvalid={errors.state !== undefined && touched.state}
              >
                <FormLabel>Contract State</FormLabel>
                <Select
                  name="state"
                  value={values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="" disabled />
                  {BoxContractStates.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.state}</FormErrorMessage>
              </FormControl>
            </FormRow>
            <FormRow>
              <FormControl
                id="numOfBox"
                isInvalid={errors.numOfBox !== undefined && touched.numOfBox}
              >
                <FormLabel>Number of Boxes</FormLabel>
                <NumberInput
                  name="numOfBox"
                  precision={0}
                  value={values.numOfBox}
                  onChange={(value) => setFieldValue('numOfBox', value)}
                  onBlur={handleBlur}
                />
                <FormErrorMessage>{errors.numOfBox}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="totalAmountInCents"
                isInvalid={
                  errors.totalAmount !== undefined && touched.totalAmount
                }
              >
                <FormLabel>Total Amount</FormLabel>
                <NumberInput
                  name="totalAmount"
                  precision={2}
                  value={`€ ${values.totalAmount}`}
                  onChange={(value) =>
                    setFieldValue('totalAmount', value.replace(/^€ /, ''))
                  }
                  onBlur={handleBlur}
                />
                <FormErrorMessage>{errors.totalAmount}</FormErrorMessage>
              </FormControl>
            </FormRow>
            <FormRow>
              <FormControl
                id="paymentStatus"
                isRequired
                isInvalid={
                  errors.paymentStatus !== undefined && touched.paymentStatus
                }
              >
                <FormLabel>Payment Status</FormLabel>
                <Select
                  name="paymentStatus"
                  value={values.paymentStatus}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="" disabled />
                  {PaymentStatuses.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.paymentStatus}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="paymentProcessor"
                isRequired
                isInvalid={
                  errors.paymentProcessor !== undefined &&
                  touched.paymentProcessor
                }
              >
                <FormLabel>Payment Processor</FormLabel>
                <Select
                  name="paymentProcessor"
                  value={values.paymentProcessor}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="" disabled />
                  {PaymentProcessors.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.paymentProcessor}</FormErrorMessage>
              </FormControl>
            </FormRow>
            <FormRow>
              <FormControl
                id="paymentDate"
                isRequired
                isInvalid={
                  errors.paymentDate !== undefined &&
                  touched.paymentDate !== undefined
                }
              >
                <FormLabel>Payment Date</FormLabel>
                <DatetimePicker
                  name="paymentDate"
                  id="paymentDate"
                  value={values.paymentDate}
                  dateFormat="dd/MM/yyyy HH:mm"
                  onBlur={handleBlur}
                  onChange={(date) => {
                    setFieldValue('paymentDate', date);
                  }}
                />
                <FormErrorMessage>
                  <>{errors.paymentDate}</>
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="paymentUniqueId"
                isRequired
                isInvalid={
                  errors.paymentUniqueId !== undefined &&
                  touched.paymentUniqueId
                }
              >
                <FormLabel>Payment Unique Id</FormLabel>
                <Input
                  name="paymentUniqueId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.paymentUniqueId}
                />
                <FormErrorMessage>{errors.paymentUniqueId}</FormErrorMessage>
              </FormControl>
              <FormControl
                id="paymentReceivedAt"
                isInvalid={
                  errors.paymentReceivedAt !== undefined &&
                  touched.paymentReceivedAt !== undefined
                }
              >
                <FormLabel>Payment Received At</FormLabel>
                <DatetimePicker
                  name="paymentReceivedAt"
                  id="paymentReceivedAt"
                  value={values.paymentReceivedAt}
                  dateFormat="dd/MM/yyyy HH:mm"
                  onBlur={handleBlur}
                  onChange={(date) => {
                    setFieldValue('paymentReceivedAt', date);
                  }}
                />

                <FormErrorMessage>
                  <>{errors.paymentReceivedAt}</>
                </FormErrorMessage>
              </FormControl>
            </FormRow>
            <FormRow>
              <FormControl
                id="paymentMethod"
                isRequired
                isInvalid={
                  errors.paymentMethod !== undefined && touched.paymentMethod
                }
              >
                <FormLabel>Payment Method</FormLabel>
                <Select
                  name="paymentMethod"
                  value={values.paymentMethod}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="" disabled />
                  {PaymentMethods.map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.paymentMethod}</FormErrorMessage>
              </FormControl>
            </FormRow>
            <ButtonGroup mt={4}>
              <ScopedCta
                component={Button}
                size="sm"
                variant="solid"
                type="submit"
                disabled={isSubmitting || !dirty || !isValid}
                requiredScopes={[scopes.MANAGE_BUY_N_RENT_CONTRACT]}
              >
                Save
              </ScopedCta>
              <Button
                size="sm"
                variant="outline"
                type="reset"
                disabled={isSubmitting || !dirty}
              >
                Reset
              </Button>
            </ButtonGroup>
          </VStack>
        </form>
      )}
    </Formik>
  );
}

interface EditBoxContractFormValues {
  paymentStatus: string;
  paymentProcessor: string;
  paymentDate: Date;
  paymentUniqueId: string;
  paymentMethod: string;
  paymentReceivedAt: Date;
  state: string;
  acceptedTosVersion: string;
  numOfBox: string;
  totalAmount: string;
  email: string;
  accountId: string;
  addressId: string;
  activatedAt: Date;
}

function getInitialValues(contract: BoxContract): EditBoxContractFormValues {
  return {
    paymentStatus: contract.paymentStatus,
    paymentProcessor: contract.paymentProcessor,
    paymentDate: contract.paymentDate,
    paymentUniqueId: contract.paymentUniqueId,
    paymentMethod: contract.paymentMethod,
    paymentReceivedAt: contract.paymentReceivedAt,
    state: contract.state,
    acceptedTosVersion: contract.acceptedTosVersion,
    numOfBox: contract.numOfBox.toString(),
    totalAmount: (contract.totalAmountInCents / 100).toFixed(2),
    email: contract.email,
    accountId: contract.accountId,
    addressId: contract.address.id,
    activatedAt: contract.activatedAt,
  };
}
