import React, { useCallback } from 'react';

import { Grid } from '@material-ui/core';
import { ColumnProps } from 'antd/lib/table';
import get from 'lodash/get';

import { TLPRolesSortBy } from '../../roles/hooks/useRolesStore';
import useGetScopes from '../hooks/useGetScopes';
import useScopesStore from '../hooks/useScopesStore';
import StyledTable from '@/components/tables/StyledTable';
import {
  getCurrentPage,
  getOffset,
  parseAntdSorter,
} from '@/helpers/antdTable';
import useAntdColumns from '@/hooks/useAntdColumns';
import useWindowSize from '@/hooks/useWindowSize';

const LPScopesTable = () => {
  useGetScopes();
  const rowData = useScopesStore((state) => state.rowData);
  const loading = useScopesStore((state) => state.loading);
  const pageSize = useScopesStore((state) => state.pageSize);
  const offset = useScopesStore((state) => state.offset);
  const total = useScopesStore((state) => state.total);
  const defaultSort = useScopesStore((state) => state.defaultSort);
  const dispatch = useScopesStore((state) => state.dispatch);
  const windowSize = useWindowSize();

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: ['scopeId', 'name', 'description'],
    addDefaultColumns: true,
    defaultSort: defaultSort,
  });

  const pagination = {
    current: getCurrentPage(pageSize, offset),
    pageSize,
    total,
    showSizeChanger: true,
  };

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const [sortField, sortOrder] = parseAntdSorter(sorter);
      dispatch({
        type: 'updateSorting',
        args: { sortBy: sortField as TLPRolesSortBy, order: sortOrder },
      });
      dispatch({
        type: 'updatePagination',
        args: {
          offset: getOffset(pagination.pageSize, pagination.current),
          pageSize: pagination.pageSize,
        },
      });
    },
    [dispatch]
  );

  return (
    <Grid container justifyContent="center">
      <Grid item xs={11}>
        <StyledTable
          rowKey="id"
          dataSource={rowData}
          columns={columns}
          size="small"
          pagination={pagination}
          loading={loading}
          scroll={{ y: get(windowSize, 'height', 0) * 0.65 }}
          onChange={handleTableChange}
        />
      </Grid>
    </Grid>
  );
};

export default LPScopesTable;
