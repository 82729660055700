import { useCallback } from 'react';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

export enum TRANSACTION_STATE {
  TRANSACTION_STATE_UNSPECIFIED = 'TRANSACTION_STATE_UNSPECIFIED',
  TRANSACTION_STATE_PENDING = 'TRANSACTION_STATE_PENDING',
  TRANSACTION_STATE_DONE = 'TRANSACTION_STATE_DONE',
  TRANSACTION_STATE_FAILED = 'TRANSACTION_STATE_FAILED',
}
export enum TRANSACTION_STATE_LABEL {
  TRANSACTION_STATE_UNSPECIFIED = 'Uunspecified',
  TRANSACTION_STATE_PENDING = 'Pending',
  TRANSACTION_STATE_DONE = 'Done',
  TRANSACTION_STATE_FAILED = 'Failed',
}

export enum TRANSACTION_OBJECT {
  TRANSACTION_OBJECT_TYPE_UNSPECIFIED = 'TRANSACTION_OBJECT_TYPE_UNSPECIFIED',
  TRANSACTION_OBJECT_TYPE_REWARD = 'TRANSACTION_OBJECT_TYPE_REWARD',
}

export enum TRANSACTION_OBJECT_LABEL {
  TRANSACTION_OBJECT_TYPE_UNSPECIFIED = 'Unspecified',
  TRANSACTION_OBJECT_TYPE_REWARD = 'Reward',
}

export interface IBillingAddress {
  city: string;
  company: string;
  country_code: string;
  created_at: string;
  created_by: string;
  display_name: string;
  first_name: string;
  house_number: string;
  id: string;
  last_name: string;
  original_address_id: string;
  position: {
    latitude: number;
    longitude: number;
  };
  postal_code: string;
  state: string;
  street: string;
  street_2: string;
  updated_at: string;
  version_hash: string;
}

export interface Itransaction {
  amount: number;
  billing_address: IBillingAddress | undefined;
  certificate_id: string;
  certificate_url: string;
  created_at: string;
  id: string;
  state: TRANSACTION_STATE | undefined;
  transaction_object_id: string;
  transaction_object_type: TRANSACTION_OBJECT | undefined;
  updated_at: string;
  wire: {
    bank_account_number: string;
    bank_title: string;
    beneficiary_name: string;
    bic: string;
    iban: string;
    swift_code: string;
  };
}

export interface Itransactions {
  total: number;
  transactions: Itransaction[];
}

const useTransaction = () => {
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpAccountV3');

  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);

  const getTransaction = useCallback(
    async (transactionId: string) => {
      const { error, data } = await makeRequest({
        path: `/transactions/${transactionId}`,
        method: '',
        body: {},
      });

      if (error) {
      }

      if (data) {
        return data.transaction;
      }
    },
    [makeRequest]
  );

  const getListTransaction = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: `/transactions`,
      method: 'GET',
      body: {},
    });

    if (error) {
      addError(error);
    }

    if (data) {
      return data;
    }
  }, [makeRequest, addError]);

  const updateTransaction = useCallback(
    async (transactionId: string, params: any) => {
      const { error, data } = await makeRequest({
        path: `/transactions/${transactionId}`,
        method: 'PATCH',
        body: {
          ...(params.certificateId && {
            certificate_id: params.certificateId,
          }),
          ...(params.certificat && {
            certificate_url: params.certificateID,
          }),
        },
      });

      if (error) {
        addError(error);
      }

      if (data) {
        addSuccessMsg(
          {
            msg: 'Successfully update transaction',
            type: MessageType.Success,
          },
          'root',
          {}
        );

        return { data: data.transaction };
      }

      return { data: {} };
    },
    [addError, makeRequest, addSuccessMsg]
  );
  const updateTransactionState = useCallback(
    async (transactionId: string, state: TRANSACTION_STATE) => {
      const { error, data } = await makeRequest({
        path: `/transactions/${transactionId}/state`,
        method: 'PATCH',
        body: {
          state,
        },
      });

      if (error) {
        addError(error);
      }

      if (data) {
        addSuccessMsg(
          {
            msg: 'Successfully update transaction state',
            type: MessageType.Success,
          },
          'root',
          {}
        );

        return { data: data.transaction };
      }

      return { data: {} };
    },
    [addError, makeRequest, addSuccessMsg]
  );

  return {
    loading,
    getTransaction,
    getListTransaction,
    updateTransaction,
    updateTransactionState,
  };
};

export default useTransaction;
