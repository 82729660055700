import { create } from 'zustand';

import { IModifiedTos, TServiceType } from '../models/tos';
import {
  getInitialState,
  IPaginatedStore,
  IReducer,
  reducer,
} from '@/helpers/paginatedStoreHelpers';

type TFilters = {
  serviceType?: TServiceType;
};

const INITIAL_STATE = getInitialState<IModifiedTos, TFilters>({
  sortBy: 'updatedAt',
  order: 'DESC',
  filters: {
    serviceType: undefined,
  },
});

const useTosStore = create<IPaginatedStore<IModifiedTos, TFilters>>((set) => ({
  ...INITIAL_STATE,
  dispatch: (input: IReducer<IModifiedTos, TFilters>) =>
    set((state) => reducer(state, input, INITIAL_STATE)),
}));

export default useTosStore;
