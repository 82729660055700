import React, { useCallback, useEffect, useMemo } from 'react';

import { ColumnProps } from 'antd/lib/table';
import get from 'lodash/get';
import styled from 'styled-components';

import GenerateContribsReport from './GenerateContribsReport';
import contributionsPostProcessor from '../../helpers/contributionsPostProcessor';
import ServerSearch from '@/components/ServerSearch';
import StyledTable from '@/components/tables/StyledTable';
import {
  getCurrentPage,
  getOffset,
  parseAntdSorter,
  getDefaultSortOrder,
} from '@/helpers/antdTable';
import useAntdColumns from '@/hooks/useAntdColumns';
import usePaginatedTableData from '@/hooks/usePaginatedTableData';
import useWindowSize from '@/hooks/useWindowSize';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-bottom: 1rem;
`;

const SearchWrapper = styled.div`
  min-width: 40rem;
  text-align: left;
  display: flex;
  align-items: center;
  flex: 3;
`;

const CtaWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
  flex: 1;
  align-items: center;
`;

interface IContributionsTable {
  store: any;
  showSizeChanger: boolean;
  hasFilters: boolean | Record<string, boolean>;
  hasReport?: boolean;
  endpoint: string;
  searchActive?: boolean;
  hasSearch?: boolean;
}

const ContributionsTable = ({
  store,
  showSizeChanger,
  hasFilters,
  hasReport = true,
  endpoint,
  searchActive,
  hasSearch,
}: IContributionsTable) => {
  const rowData = store((state) => state.rowData);
  const loading = store((state) => state.loading);
  const pageSize = store((state) => state.pageSize);
  const sortBy = store((state) => state.sortBy);
  const order = store((state) => state.order);
  const offset = store((state) => state.offset);
  const filters = store((state) => state.filters);
  const total = store((state) => state.total);
  const defaultSort = store((state) => state.defaultSort);
  const dispatch = store((state) => state.dispatch);
  const searchTerms = store((state) => state.searchTerms);

  const queryParams = useMemo(
    () => (searchActive ? searchTerms : filters),
    [searchActive, searchTerms, filters]
  );

  usePaginatedTableData({
    params: {
      path: endpoint,
      method: 'get',
      reducer: contributionsPostProcessor,
    },
    dispatch,
    queryParams: {
      pageSize,
      offset,
      sortBy,
      order,
      ...queryParams,
    },
    queryParamsOptions: {
      skipEmptyString: true,
      skipNull: true,
    },
    service: 'serviceLpAccount',
  });

  const pagination = {
    current: getCurrentPage(pageSize, offset),
    pageSize,
    total,
    showSizeChanger,
  };

  const windowSize = useWindowSize();

  useEffect(
    () => () => {
      dispatch({ type: 'updateDefaultSort' });
    },
    [dispatch]
  );

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const [sortField, sortOrder] = parseAntdSorter(sorter);
      dispatch({
        type: 'updateSorting',
        args: { sortBy: sortField as string, order: sortOrder },
      });
      dispatch({
        type: 'updatePagination',
        args: {
          offset: getOffset(pagination.pageSize, pagination.current),
          pageSize: pagination.pageSize,
        },
      });
      dispatch({
        type: 'updateFilters',
        args: {
          filters: {
            paymentStatus: get(filters, 'paymentStatus', []),
            paymentMethod: get(filters, 'paymentMethod', []),
            cancelled: get(filters, 'state', null),
            offerType: get(filters, 'offerType', []),
          },
        },
      });
    },
    [dispatch]
  );

  const hasFilter = useCallback(
    (type: string) => hasFilters === true || get(hasFilters, type, false),
    [hasFilters]
  );

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: [
      'totalAmountInCents',
      'paymentUniqueId',
      'paymentStatus',
      'paymentMethod',
      'paymentDate',
      'state',
      'notes',
    ],
    columnsSpecialProps: {
      totalAmountInCents: {
        sorter: hasFilter('totalAmountInCents'),
        render: (text, record) => record.parsedTotalAmount,
        defaultSortOrder: getDefaultSortOrder(
          defaultSort,
          'totalAmountInCents'
        ),
        width: '8rem',
      },
      paymentStatus: {
        filters: hasFilter('paymentStatus')
          ? [
              { text: 'Rejected', value: 'rejected' },
              { text: 'Canceled', value: 'canceled' },
              { text: 'Pending', value: 'pending' },
              { text: 'Completed', value: 'completed' },
              { text: 'Refund Requested', value: 'refund_requested' },
              { text: 'Refund Initiated', value: 'refund_initiated' },
              { text: 'Refunded', value: 'refunded' },
              { text: 'Unknown', value: 'unknown' },
            ]
          : undefined,
        filteredValue: get(filters, 'paymentStatus'),
        filterMultiple: false,
        width: '6rem',
      },
      paymentMethod: {
        filters: hasFilter('paymentMethod')
          ? [
              { text: 'Credit Card', value: 'CREDIT_CARD' },
              { text: 'Giropay', value: 'GIROPAY' },
              { text: 'Sofort', value: 'SOFORT' },
              { text: 'Wire', value: 'WIRE' },
              //TODO: add back once BE has fixed
              // { text: 'Unknown', value: 'UNKNOWN' },
            ]
          : undefined,
        filteredValue: get(filters, 'paymentMethod'),
        filterMultiple: false,
        width: '7rem',
      },
      paymentDate: {
        sorter: hasFilter('paymentDate'),
        defaultSortOrder: getDefaultSortOrder(defaultSort, 'paymentDate'),
        width: '6rem',
        render: (text, record) => record.parsedPaymentDate,
      },
      state: {
        filters: hasFilter('state')
          ? [{ text: 'Show canceled', value: 'true' }]
          : undefined,
        filteredValue: get(filters, 'cancelled'),
        width: '8rem',
      },
      offerType: {
        render: (text, record) => get(record, 'parsedOffer.offerType', ''),
        filters: hasFilter('offerType')
          ? [
              { text: 'default', value: 'default' },
              { text: 'pioneer', value: 'pioneer' },
              { text: 'gift', value: 'gift' },
              { text: 'thebag', value: 'thebag' },
              { text: 'reward', value: 'reward' },
              { text: 'singular', value: 'singular' },
              // TODO: add back in once BE has fixed
              // { text: 'Unknown', value: 'unknown' },
            ]
          : undefined,
        filterMultiple: false,
        filteredValue: get(filters, 'offerType'),
      },
    },
    addDefaultColumns: true,
    defaultSort: defaultSort,
    eyeLinkProps: { to: '/sharing-angels/contributions' },
  });

  return (
    <Wrapper>
      <RowWrapper>
        {hasSearch && (
          <SearchWrapper>
            <ServerSearch
              fields={[
                { key: 'paymentUniqueId', placeholder: 'Payment Unique ID' },
                { key: 'inviteCode', placeholder: 'Invited by Code' },
                { key: 'city', placeholder: 'City' },
                { key: 'email', placeholder: 'Email' },
                { key: 'phoneNumber', placeholder: 'Phone Number' },
                { key: 'countryCode', placeholder: 'Country' },
              ]}
              dispatch={dispatch}
              storeSearchTerms={searchTerms}
            />
          </SearchWrapper>
        )}
        {hasReport && (
          <CtaWrapper>
            <GenerateContribsReport endpoint={endpoint} store={store} />
          </CtaWrapper>
        )}
      </RowWrapper>
      <StyledTable
        rowKey="id"
        dataSource={rowData}
        columns={columns}
        size="small"
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 'max-content', y: get(windowSize, 'height', 0) * 0.65 }}
      />
    </Wrapper>
  );
};

export default ContributionsTable;
