import { create } from 'zustand';

import { BoxContract } from '../models/boxContract';
import {
  getInitialState,
  IPaginatedStore,
  IReducer,
  reducer,
} from '@/helpers/paginatedStoreHelpers';

type TFilters = {
  boxContractOfferType?: string;
  paymentMethod?: string;
  paymentStatus?: string;
};

const INITIAL_STATE = getInitialState<BoxContract, TFilters>({
  sortBy: 'paymentDate',
  order: 'ASC',
});

const useContractsStore = create<IPaginatedStore<BoxContract, TFilters>>(
  (set) => ({
    ...INITIAL_STATE,
    dispatch: (input: IReducer<BoxContract, TFilters>) =>
      set((state) => reducer(state, input, INITIAL_STATE)),
  })
);

export default useContractsStore;
