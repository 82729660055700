import React, { useState, useCallback, useMemo } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import AddAccountForm from './AddAccountForm';
import CancelSubmitFooterModal from '@/components/CancelSubmitFooterModal';
import CustomModal from '@/components/CustomModal';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

interface IRoleAccount {
  accountId: string;
  roleId: string;
}

interface IAddAccount {
  id: string;
  useAddAccount: (
    id: string
  ) => [{ loading: boolean }, (input: IRoleAccount) => { success: boolean }];
  onSuccess?: any;
  domain?: 'partners' | 'shops';
}

const AddAccountModal = ({
  id,
  useAddAccount,
  onSuccess,
  domain,
}: IAddAccount) => {
  const [account, setAccount] = useState<IRoleAccount>({
    accountId: '',
    roleId: '',
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const [{ loading }, addAccount] = useAddAccount(id);

  const handleSubmit = useCallback(async () => {
    const { success } = await addAccount(account);
    if (success) {
      if (onSuccess) {
        onSuccess();
      }
      closeModal();
      setAccount({ accountId: '', roleId: '' });
    }
  }, [account, addAccount, closeModal, onSuccess]);

  const isInvalid = useMemo(
    () => !account.accountId || !account.roleId,
    [account]
  );

  const modalFooterButtons = useMemo(
    () => CancelSubmitFooterModal(isInvalid, handleSubmit, closeModal, loading),
    [closeModal, handleSubmit, isInvalid, loading]
  );

  return (
    <>
      <Wrapper>
        <ScopedCta
          component={PillButton}
          onClick={() => setShowModal(true)}
          icon={<PlusOutlined />}
          requiredScopes={[scopes.EDIT_LPVILLAGE_PARTNER]}
        >
          Add Account
        </ScopedCta>
      </Wrapper>
      <CustomModal
        destroyOnClose={true}
        open={showModal}
        title="Add Account"
        onCancel={closeModal}
        onOk={handleSubmit}
        footer={modalFooterButtons}
        width="40%"
      >
        <AddAccountForm
          accountData={account}
          setAccountData={setAccount}
          domain={domain}
        />
      </CustomModal>
    </>
  );
};
export default AddAccountModal;
