import * as React from 'react';

function PlusIcon(props) {
  return (
    <svg
      cursor="pointer"
      width="2em"
      height="2em"
      viewBox="0 0 512 512"
      {...props}
    >
      <path d="M384 265H264v119h-17V265H128v-17h119V128h17v120h120v17z" />
    </svg>
  );
}

export default PlusIcon;
