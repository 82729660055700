import { useCallback } from 'react';

import { BoxConfig } from '../models/boxConfig';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

type UpdateBoxConfiguration = (id: string) => Promise<BoxConfig>;

export function useGetBoxConfiguration(): UpdateBoxConfiguration {
  const addError = useError();
  const [, makeRequest] = useImperativeRequestWrapper('device');

  const getBoxConfiguration = useCallback(
    async (id: string) => {
      const { error, data } = await makeRequest({
        path: `/boxes/${id}/configuration`,
        method: 'get',
      });
      if (error) {
        addError(error);

        return {};
      }

      return data;
    },
    [makeRequest, addError]
  );

  return getBoxConfiguration;
}
