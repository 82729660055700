import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Button, Tooltip } from 'antd';
import styled from 'styled-components';

import getActiveOffers from '../../helpers/getActiveOffers';
import useAddContribution from '../../hooks/useAddContribution';
import useRefreshAccountContribs from '../../hooks/useRefreshAccountContribs';
import AddContributionForm from '../AddContributionForm';
import AddressForm from '@/components/AddressForm';
import CustomModal from '@/components/CustomModal';
import { getPrimaryAddress } from '@/features/lp-account-users';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const ContributionFormWrapper = styled.div`
  padding-bottom: 26.85rem;
`;

const TitleWrapper = styled.h2`
  padding-bottom: 1rem;
`;

const ButtonWrapper = styled.span`
  margin-left: 8px;
`;

const INITIAL_STATE_ADDRESS = {
  firstName: '',
  lastName: '',
  company: '',
  displayName: '',
  street: '',
  street2: '',
  houseNumber: '',
  postalCode: '',
  city: '',
  state: '',
  countryCode: '',
  isPrimary: true,
  types: ['contract'],
};

const INITIAL_STATE_CONTRIBUTION = {
  offerId: '',
  totalAmountInCents: '',
  currency: '',
  inviteCode: '',
  paymentMethod: '',
  paymentUniqueId: '',
  acceptedTosVersion: '',
  addressId: '',
};

const DisabledButton = ({ step }) => (
  <ButtonWrapper>
    <Tooltip placement="topRight" title="Please fill all required fields">
      <Button key="submit" type="primary" disabled={true}>
        {step === 0 ? 'Next' : 'Submit'}
      </Button>
    </Tooltip>
  </ButtonWrapper>
);

const AddContributionModal = ({ accountId, visible, onClose }) => {
  const [address, setAddress] = useState(INITIAL_STATE_ADDRESS);
  const [activeOffers, setActiveOffers] = useState([]);
  const [contribution, setContribution] = useState(INITIAL_STATE_CONTRIBUTION);
  const [step, setStep] = useState(0);
  const [{ loading }, addContribution, addAddress] = useAddContribution();
  const getContributions = useRefreshAccountContribs();
  const [unixTimeStamp, setUnixTimeStamp] = useState<number | null>(null);
  const [backdating, setBackdating] = useState<boolean>(false);

  const [, makeRequest] = useImperativeRequestWrapper('serviceLpAccount');
  const isInvalidContribution = useMemo(
    () =>
      !contribution.totalAmountInCents ||
      parseInt(contribution.totalAmountInCents) <= 0 ||
      !contribution.offerId ||
      !contribution.currency ||
      !contribution.paymentMethod ||
      (backdating && !unixTimeStamp),
    [contribution, backdating, unixTimeStamp]
  );

  const isInvalidAddress = useMemo(
    () =>
      !address.firstName ||
      !address.lastName ||
      !address.street ||
      !address.postalCode ||
      !address.city ||
      !address.countryCode,
    [address]
  );

  const setInitialAddress = useCallback(async () => {
    const addr = await getPrimaryAddress({ accountId, makeRequest });
    if (addr) {
      setAddress({ ...addr, types: ['contract'] });
    }
  }, [accountId, makeRequest, setAddress]);

  const fetchActiveOffers = useCallback(async () => {
    const offers = await getActiveOffers({ makeRequest });
    if (offers) {
      setActiveOffers(offers);
    }
  }, [setActiveOffers, makeRequest]);

  useEffect(() => {
    if (visible) {
      setInitialAddress();
      fetchActiveOffers();
    }
  }, [visible, setInitialAddress, fetchActiveOffers]);

  useEffect(() => {
    if (unixTimeStamp) {
      setContribution((contribution) => ({
        ...contribution,
        createdAt: unixTimeStamp,
      }));
    }
  }, [unixTimeStamp]);

  const closeModal = useCallback(() => {
    setStep(0);
    setContribution(INITIAL_STATE_CONTRIBUTION);
    setAddress(INITIAL_STATE_ADDRESS);
    setUnixTimeStamp(null);
    onClose();
    setBackdating(false);
  }, [onClose, setStep]);
  const handleSubmit = useCallback(async () => {
    const addressId = await addAddress({ address, accountId });
    if (addressId) {
      const { success } = await addContribution({
        accountId,
        addressId,
        contribution,
      });
      if (success) {
        closeModal();
        getContributions(accountId);
      }
    }
  }, [
    contribution,
    address,
    accountId,
    addAddress,
    addContribution,
    closeModal,
    getContributions,
  ]);

  const getFooterButtons = useMemo(() => {
    if (step === 0) {
      return [
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Fragment key="next">
          {isInvalidAddress ? (
            <DisabledButton step={step} />
          ) : (
            <Button type="primary" loading={false} onClick={() => setStep(1)}>
              Next
            </Button>
          )}
        </Fragment>,
      ];
    }
    if (step === 1) {
      return [
        <Button key="previous" onClick={() => setStep(0)}>
          Previous
        </Button>,
        <Fragment key="submit">
          {isInvalidContribution ? (
            <DisabledButton step={step} />
          ) : (
            <Button
              type="primary"
              loading={loading}
              onClick={handleSubmit}
              data-testid="submit-contribution"
            >
              Submit
            </Button>
          )}
        </Fragment>,
      ];
    }
  }, [
    step,
    closeModal,
    isInvalidAddress,
    isInvalidContribution,
    handleSubmit,
    loading,
  ]);

  const getForm: JSX.Element = useMemo(() => {
    if (step === 0) {
      return (
        <>
          <TitleWrapper>You first need to add a contract address.</TitleWrapper>
          <AddressForm
            address={address}
            setAddress={setAddress}
            hideAddressTypeFields={true}
          />
        </>
      );
    }

    return (
      <ContributionFormWrapper>
        <TitleWrapper>Now add the contribution.</TitleWrapper>
        <AddContributionForm
          setUnixTimeStamp={setUnixTimeStamp}
          contribution={contribution}
          setContribution={setContribution}
          activeOffers={activeOffers}
          setBackdating={setBackdating}
          backdating={backdating}
        />
      </ContributionFormWrapper>
    );
  }, [step, address, contribution, activeOffers, backdating]);

  return (
    <div data-testid="add-contribution-modal">
      <CustomModal
        destroyOnClose={true}
        open={visible}
        title="Add a contribution"
        onCancel={closeModal}
        footer={getFooterButtons}
        width="80%"
      >
        {getForm}
      </CustomModal>
    </div>
  );
};

export default AddContributionModal;
