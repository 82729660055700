import React from 'react';

import { Text, styled } from '@lp/ds-next';
import { Paper } from '@mui/material';
import { Switch } from 'antd';

import secursusLogo from '@/assets/images/secursus-logo@2x.png';

const Wrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1rem;
  border-radius: 10px;
`;
const InsuranceWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
  gap: 10px;
`;

const InsuranceState = ({ secursusEnabled }) => (
  <Wrapper elevation={0}>
    <Text variant="titleL">Insurance</Text>
    <InsuranceWrapper>
      <img src={secursusLogo} width="60px" alt="insurance-logo" />
      <Switch disabled checked={secursusEnabled} />
      Is {secursusEnabled ? 'active' : 'inactive'}
    </InsuranceWrapper>
  </Wrapper>
);

export default InsuranceState;
