import { useMutation } from '@tanstack/react-query';

import { Iproduct } from '../models/product';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { MutationConfig } from '@/lib/react-query';

export const updateProduct =
  (makeRequest: any) =>
  async ({
    values,
    lpUi,
  }: {
    values: Partial<Iproduct>;
    lpUi: string;
  }): Promise<Iproduct> => {
    const { data, error } = await makeRequest({
      path: `/products/${lpUi}`,
      method: 'patch',
      body: values,
    });

    if (!data || error) {
      throw new Error(error);
    }

    return data;
  };

type UseUpdateBoxOptions = {
  config?: MutationConfig<ReturnType<typeof updateProduct>>;
};

export const useUpdateProduct = ({ config }: UseUpdateBoxOptions = {}) => {
  const [, makeRequest] = useImperativeRequestWrapper('device');

  return useMutation({
    onSuccess: (_data) => {
      // TODO: Uncomment the day we will have and use the getProduct query hook
      // queryClient.refetchQueries({ queryKey: ['product', data.id] });
    },
    ...config,
    mutationFn: updateProduct(makeRequest),
  });
};
