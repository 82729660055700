import React from 'react';

import Dropdown from '../../../components/forms/Dropdown';
import Label from '../../../components/forms/Label';
const PAYMENT_METHODS = [
  { value: 'CREDIT_CARD', label: 'CREDIT_CARD' },
  { value: 'WIRE', label: 'WIRE' },
  { value: 'STRIPE_SOFORT', label: 'SOFORT' },
  { value: 'STRIPE_GIROPAY', label: 'GIROPAY' },
];

const PaymentMethodSelector = ({
  label,
  onChange,
  paymentMethod,
}: {
  label: string;
  onChange: any;
  paymentMethod: string;
}) => {
  const isOptionSelected = (option, selectValue) =>
    selectValue.some((val) => val.value === option.value);

  return (
    <>
      {label && <Label>{label}</Label>}
      <Dropdown
        isOptionSelected={isOptionSelected}
        defaultValue={PAYMENT_METHODS[0]}
        options={PAYMENT_METHODS}
        getOptionValue={(option) => option['value']}
        getOptionLabel={(option) => option['label']}
        value={
          paymentMethod !== ''
            ? {
                value: paymentMethod,
                label: PAYMENT_METHODS.find((p) => p.value === paymentMethod)
                  ?.label,
              }
            : PAYMENT_METHODS[0]
        }
        onChange={onChange}
      />
    </>
  );
};

export default PaymentMethodSelector;
