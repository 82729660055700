import { useMutation } from '@tanstack/react-query';

import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { MutationConfig } from '@/lib/react-query';

export const createPartner =
  ({ makeRequest }: { makeRequest: any }) =>
  async (values: any): Promise<any> => {
    const { error, data } = await makeRequest({
      path: '/partners',
      method: 'post',
      body: values,
    });

    if (!data || error) {
      throw error;
    }

    return { success: true, data: data };
  };

type UseCreatePartnerOptions = {
  config?: MutationConfig<ReturnType<typeof createPartner>>;
};

export const useCreatePartner = ({ config }: UseCreatePartnerOptions = {}) => {
  const [, makeRequest] = useImperativeRequestWrapper('lpVillageV3');

  return useMutation({
    ...config,
    mutationFn: createPartner({ makeRequest }),
  });
};
