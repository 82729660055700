import get from 'lodash/get';
import queryString from 'query-string';

const getPaginatedTableData = async ({
  params,
  dispatch,
  reducer,
  queryParams,
  queryParamsOptions = {},
  addError,
  makeRequest,
  items = 'items',
}) => {
  if (dispatch) {
    dispatch({ type: 'updateLoading', args: { loading: true } });
  }
  const withQueryParams = {
    ...params,
    path: `${params.path}?${queryString.stringify(
      queryParams,
      queryParamsOptions
    )}`,
  };

  let { data, error } = await makeRequest(withQueryParams);
  if (reducer && data?.items) {
    try {
      data = reducer({ ...data, items: data[items] });
    } catch (err) {
      error = err;
    }
  }
  if (dispatch) {
    dispatch({ type: 'updateLoading', args: { loading: false } });
    dispatch({ type: 'updateError', args: { error } });
    if (data) {
      dispatch({
        type: 'updateRowData',
        args: { rowData: get(data, items, []) },
      });
      dispatch({
        type: 'updateTotal',
        args: { total: get(data, 'total', 0) },
      });
      dispatch({
        type: 'updateCount',
        args: { count: get(data, 'count', 0) },
      });
    } else {
      dispatch({ type: 'updateRowData', args: { rowData: [] } });
    }
    if (error) {
      addError(error);
    }
  }

  return { data, error };
};

export default getPaginatedTableData;
