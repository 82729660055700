import { useCallback } from 'react';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useRemoveAccount = (domain: 'shops' | 'partners') => {
  const addError = useError();
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpVillage');
  const successMsg = useToasts((state) => state.addItem);

  const removeAccount = useCallback(
    async (id, accountId) => {
      const { error } = await makeRequest({
        path: `/admin/${domain}/${id}/accounts/${accountId}`,
        method: 'delete',
      });
      if (error) {
        addError(error);

        return { success: false };
      }
      successMsg(
        { msg: 'Account was successfully removed!', type: MessageType.Success },
        'root',
        {}
      );

      return { success: true };
    },
    [makeRequest, addError, successMsg, domain]
  );

  return { loading, removeAccount };
};
export default useRemoveAccount;
