import React, { useCallback, useEffect, useState } from 'react';

import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';

import PartnerBusinessInformationSelector from './PartnerBusinessInformationSelector';
import PartnerInternalSelector from './PartnerInternalSelector';
import { ICreatePartner, IModifiedPartner } from '../models/partner';
import IntegerInput from '@/components/forms/IntegerInput';
import TextareaInput from '@/components/forms/TextareaInput';
import TextInput from '@/components/forms/TextInput';
/* import ContactOffer from '../../../OfferTemplate/components/ContactOffer'; Blocked because waiting new specification */

const PartnerForm = ({
  data,
  setData,
  hideNonEditableFields = false,
}: // formikOffer,
{
  data: Partial<IModifiedPartner> & ICreatePartner;
  setData?: (res: any) => void;
  hideNonEditableFields?: boolean;
  // formikOffer?: any;
}) => {
  const {
    name,
    website,
    phoneNumber,
    internal,
    description,
    businessInformation: { status },
    maxNbOfShipments,
    nbOfShipments,
  } = data;

  const [isModificable, setIsModifiable] = useState<boolean>(false);
  const [initMaxNbOfShipments, setInitMaxNbOfShipments] = useState<number>(0);

  // All this callbacks should be removed and use Formik instead
  // when the v3 create partner will be available

  const handleTextChange = useCallback(
    (e) => {
      const field = { name: e.target.name, value: e.target.value };

      if (setData) {
        setData((prev) => ({ ...prev, [field.name]: field.value }));
      }
    },
    [setData]
  );
  const handleNumberChange = useCallback(
    (e) => {
      const field = { name: e.target.name, value: e.target.value };

      if (setData) {
        setData((prev) => ({ ...prev, [field.name]: parseInt(field.value) }));
      }
    },
    [setData]
  );

  const handleInternalChange = useCallback(
    (value) => {
      if (setData) {
        setData((prev) => ({ ...prev, internal: value }));
      }
    },
    [setData]
  );

  const handleStatusChange = useCallback(
    (status) => {
      if (setData) {
        setData((prev) => ({
          ...prev,
          businessInformation: {
            ...prev.businessInformation,
            status: status,
          },
        }));
      }
    },
    [setData]
  );

  const handleTypeChange = useCallback(
    (type) => {
      if (setData) {
        setData((prev) => ({
          ...prev,
          businessInformation: {
            type: type,
            ...(type === 'TEST' && { status: 'VALIDATED' }),
          },
        }));
      }
    },
    [setData]
  );
  useEffect(() => {
    if (!isModificable) {
      setInitMaxNbOfShipments(() => maxNbOfShipments);
    }
  }, [isModificable, maxNbOfShipments]);

  return (
    <>
      <Row gutter={24}>
        <Col span={!hideNonEditableFields ? 12 : 24}>
          <TextInput
            onChange={handleTextChange}
            label="Name *"
            name="name"
            value={name}
          />
          <TextInput
            onChange={handleTextChange}
            label="Phone Number *"
            name="phoneNumber"
            value={phoneNumber}
          />
          <TextInput
            onChange={handleTextChange}
            label="Website *"
            name="website"
            value={website}
          />
          <TextareaInput
            onChange={handleTextChange}
            label="Description *"
            name="description"
            value={description}
            rows={3}
          />
          {/*  Blocked because waiting new specification         {formikOffer && formikOffer.values && (
            <>
              <p>Contract informations</p>
              <Col>
                <ContactOffer formik={formikOffer} />
              </Col>
            </>
          )} */}
        </Col>

        <Col span={12}>
          <Row>
            <Col span={24}>
              <PartnerInternalSelector
                onChange={handleInternalChange}
                value={{ is_internal: internal }}
                style={{ marginBottom: '2rem' }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <PartnerBusinessInformationSelector
                onChangeType={handleTypeChange}
                onChangeStatus={handleStatusChange}
                value={{ type: data.businessInformation.type, status: status }}
                style={{ marginBottom: '2rem' }}
              />

              {(data.businessInformation.status !== 'VALIDATED' ||
                data.businessInformation.type === 'TEST') && (
                <div
                  style={{
                    display: 'flex',
                    gap: '20px',
                    alignItems: 'center',
                    marginTop: '0.25rem',
                    marginBottom: '2rem',
                  }}
                >
                  <div>
                    <IntegerInput
                      label="Number of Shipments"
                      name="nbOfShipments"
                      value={nbOfShipments?.toString()}
                      disabled
                    />
                  </div>
                  <div>
                    <IntegerInput
                      onChange={handleNumberChange}
                      label="Max of Shipments"
                      name="maxNbOfShipments"
                      value={`${maxNbOfShipments}`}
                      disabled={!isModificable}
                    />
                  </div>

                  {!isModificable ? (
                    <EditOutlined
                      onClick={() => setIsModifiable(!isModificable)}
                      style={{ fontSize: '24px', paddingBottom: '1rem' }}
                    />
                  ) : (
                    <CloseOutlined
                      onClick={() => {
                        setIsModifiable(!isModificable);
                        //reset value
                        if (setData) {
                          setData((prev) => ({
                            ...prev,
                            maxNbOfShipments: initMaxNbOfShipments,
                          }));
                        }
                        setInitMaxNbOfShipments(initMaxNbOfShipments);
                      }}
                      style={{
                        fontSize: '24px',
                        paddingBottom: '1rem',
                        color: '#ff4d4f',
                      }}
                    />
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default PartnerForm;
