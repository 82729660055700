import React from 'react';

import styled from 'styled-components';

import ActiveIcon from '@/components/icons/ActiveIcon';
import InActiveIcon from '@/components/icons/InActiveIcon';
import PendingIcon from '@/components/icons/PendingIcon';
import SVG from '@/components/icons/SVG';

const Wrapper = styled.div`
  margin: 1rem;
  display: flex;
  align-items: center;
`;

const OffersTableLegend = () => (
  <Wrapper>
    <SVG viewBox="0 0 18 18" width="1em" height="1em">
      <rect
        fill="rgba(48, 181, 120, 0.3)"
        height="18"
        width="18"
        y="-1"
        x="-1"
      />
    </SVG>
    &nbsp; = &nbsp; Active Default Offer &nbsp; &nbsp; &nbsp;
    <ActiveIcon />
    &nbsp; =&nbsp; Active &nbsp; &nbsp; &nbsp;
    <PendingIcon />
    &nbsp; =&nbsp; Not yet activated &nbsp; &nbsp; &nbsp;
    <InActiveIcon />
    &nbsp; =&nbsp; Deactivated
  </Wrapper>
);

export default OffersTableLegend;
