import { useCallback } from 'react';

import pick from 'lodash/pick';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useArchivePartner: any = (id) => {
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpVillage');

  const archivePartner = useCallback(
    async (partner) => {
      const { error } = await makeRequest({
        path: `/admin/partners/${id}`,
        method: 'put',
        body: {
          ...pick(partner, [
            'description',
            'name',
            'phoneNumber',
            'type',
            'website',
          ]),
          archived: true,
        },
      });
      if (!!error) {
        addError(error);

        return { success: false };
      }
      addSuccessMsg(
        {
          msg: 'Partner was successfully archived!',
          type: MessageType.Success,
        },
        'root',
        {}
      );

      return { success: true };
    },
    [id, makeRequest, addSuccessMsg, addError]
  );

  return [{ loading }, archivePartner];
};
export default useArchivePartner;
