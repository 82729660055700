export type TBoxChecklist = {
  boxId: string;
  createdAt: number;
  eLabelTransmission: string;
  firmware: string;
  id: string;
  lockCommand: string;
  readState: string;
  receiveCommand: string;
  resetCommand: string;
  returnLabel: string;
  tracking: string;
  updatedAt: number;
};

export enum ChecklistValues {
  UNKNOWN = 'UNKNOWN',
  OK = 'OK',
  NOT = 'NOT_OK',
}

export type TChecklistItem = {
  text: string;
  value: ChecklistValues;
};

export type TModifiedBoxChecklist = {
  [K in keyof TBoxChecklist]: TChecklistItem;
};
