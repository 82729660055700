import React, { useCallback, useContext, useEffect } from 'react';

import { Grid } from '@lp/ds-next';
import { ColumnProps } from 'antd/lib/table';
import get from 'lodash/get';

import CreatePartner from '../components/CreatePartner';
import ImportCSVFile from '../components/ImportCSVFile';
import useGetPartners from '../hooks/useGetPartners';
import useLPPartnersStore, {
  TLPPartnersSortBy,
} from '../hooks/useLPPartnersStore';
import ServerSearch from '@/components/ServerSearch';
import StyledTable from '@/components/tables/StyledTable';
import TablesTotals from '@/components/TablesTotals';
import {
  getCurrentPage,
  getOffset,
  parseAntdSorter,
} from '@/helpers/antdTable';
import useAntdColumns from '@/hooks/useAntdColumns';
import useWindowSize from '@/hooks/useWindowSize';
import { VillageTotalContext } from '@/shared/providers/villageTotalProvider';

const LPPartnersTable = () => {
  useGetPartners();
  const rowData = useLPPartnersStore((state) => state.rowData);
  const loading = useLPPartnersStore((state) => state.loading);
  const pageSize = useLPPartnersStore((state) => state.pageSize);
  const searchTerms = useLPPartnersStore((state) => state.searchTerms);
  const offset = useLPPartnersStore((state) => state.offset);
  const total = useLPPartnersStore((state) => state.total);
  const defaultSort = useLPPartnersStore((state) => state.defaultSort);
  const dispatch = useLPPartnersStore((state) => state.dispatch);
  const windowSize = useWindowSize();
  const vTotal = useContext(VillageTotalContext);

  useEffect(
    () => () => {
      dispatch({ type: 'reset' });
    },
    [dispatch]
  );

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: ['partnerId', 'name', 'phoneNumber', 'villageServiceLevel'],
    addDefaultColumns: true,
    defaultSort: defaultSort,
    eyeLinkProps: { to: '/lp-village/partners' },
  });

  const pagination = {
    current: getCurrentPage(pageSize, offset),
    pageSize,
    total,
    showSizeChanger: true,
  };

  const handleTableChange = useCallback(
    (pagination, _filters, sorter) => {
      const [sortField, sortOrder] = parseAntdSorter(sorter);
      dispatch({
        type: 'updateSorting',
        args: { sortBy: sortField as TLPPartnersSortBy, order: sortOrder },
      });
      dispatch({
        type: 'updatePagination',
        args: {
          offset: getOffset(pagination.pageSize, pagination.current),
          pageSize: pagination.pageSize,
        },
      });
    },
    [dispatch]
  );

  return (
    <Grid container justifyContent="center" px="5rem">
      <Grid item mobile={12}>
        <ServerSearch
          storeSearchTerms={searchTerms}
          dispatch={dispatch}
          fields={[
            { key: 'name', placeholder: 'Name' },
            { key: 'id', placeholder: 'Id' },
            { key: 'businessType', placeholder: 'Account type' },
          ]}
        />
      </Grid>
      <Grid item mobile={12}>
        <Grid container gap={2}>
          <Grid item container>
            <Grid
              item
              container
              justifyContent="flex-end"
              alignItems="center"
              gap={2}
            >
              <Grid item>
                <ImportCSVFile
                  buttonText="Import Late Shops CSV"
                  modalTitle="Import List of Late Shops"
                />
              </Grid>
              <Grid item>
                <CreatePartner />
              </Grid>
            </Grid>
          </Grid>
          <Grid item mobile={12}>
            <StyledTable
              rowKey="id"
              dataSource={rowData}
              columns={columns}
              size="small"
              pagination={pagination}
              loading={loading}
              scroll={{ y: get(windowSize, 'height', 0) * 0.65 }}
              onChange={handleTableChange}
              footer={() => <TablesTotals total={total} gTotal={vTotal} />}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LPPartnersTable;
