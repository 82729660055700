import React from 'react';

import { useNavigate } from 'react-router-dom';

import { auth } from './config';
import Auth0Provider from '@/shared/auth0';

export const Auth0ProviderWithNavigate = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState: any) => {
    navigate(appState?.returnTo || appState?.targetUrl || '/');
  };

  return (
    <Auth0Provider
      domain={auth.domain}
      client_id={auth.clientId}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={auth.audience}
      scope={auth.scope}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
