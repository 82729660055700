import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { ERPContract } from '../models/partnerV3';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { queryClient } from '@/lib/react-query';

export const syncContract = async ({
  partnershipId,
  makeRequest,
}: {
  partnershipId?: string;
  makeRequest: any;
}): Promise<ERPContract> => {
  const { data, error } = await makeRequest({
    path: `/partners/${partnershipId}/contracts/sync-erp`,
    method: 'post',
  });

  if (!data || error) {
    throw new Error(error.response?.data?.message ?? 'An error occurred');
  }

  return data.contract;
};

type UseContractSyncOptions = {
  partnershipId?: string;
};

export const useUpdateContractSync = ({
  partnershipId,
}: UseContractSyncOptions = {}) => {
  const { id: partnerId } = useParams<{ id: string }>();

  let id = partnershipId;
  if (!id) {
    id = partnerId;
  }

  const [, makeRequest] = useImperativeRequestWrapper('lpVillageV3');

  return useMutation({
    onSuccess(data) {
      // Refresh partner info after sync
      queryClient.setQueryData(['partnership', id, 'contract'], data);
    },
    mutationFn: () => syncContract({ partnershipId: id, makeRequest }),
  });
};
