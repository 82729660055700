import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { Checkbox, Col, Row } from 'antd';
import styled from 'styled-components';

import CreateShipmentAddressForm from './CreateShipmentAddressForm';
import CreateShipmentSenderAddressSelection from './CreateShipmentSenderAddressSelection';
import { ICreateShipment } from '../models/shipment';
import TextInput from '@/components/forms/TextInput';

const TitleWrapper = styled.h2`
  padding-bottom: 1rem;
`;
const CreateShipmenReturnForm = ({
  returnShipment,
  setReturnShipment,
  originalShipment,
}: {
  returnShipment: ICreateShipment;
  setReturnShipment: Dispatch<SetStateAction<ICreateShipment>>;
  originalShipment: ICreateShipment;
}) => {
  const [returnToNewPartner, setReturnToNewpartner] = useState<boolean>(false);

  const handleNotifEmailChange = useCallback(
    (e) => {
      setReturnShipment((prev) => ({
        ...prev,
        recipient: {
          ...prev.recipient,
          notificationEmail: e.target.value,
        },
      }));
    },
    [setReturnShipment]
  );

  const setAddressRecipient = useCallback(
    (address) => {
      setReturnShipment((prev) => ({
        ...prev,
        recipient: {
          ...prev.recipient,
          address: { ...prev.recipient.address, ...address },
        },
      }));
    },
    [setReturnShipment]
  );

  const setAddressSender = useCallback(
    (address) => {
      setReturnShipment((prev) => ({
        ...prev,
        sender: {
          ...prev.sender,
          address: { ...prev.sender.address, ...address },
        },
      }));
    },
    [setReturnShipment]
  );

  const handlePartnerAddressIdChange = useCallback(
    (id) => {
      setReturnShipment((prev) => ({
        ...prev,
        recipient: {
          ...prev.recipient,
          accountId: returnShipment.recipient.accountId,
          addressId: id,
        },
      }));
    },
    [returnShipment.recipient.accountId, setReturnShipment]
  );

  useEffect(() => {
    setReturnShipment((prev) => ({
      ...prev,
      serviceLevel: originalShipment.serviceLevel,
    }));
    setAddressSender(originalShipment.recipient.address);
    setAddressRecipient(originalShipment.sender.address);
  }, [
    setAddressSender,
    setAddressRecipient,
    originalShipment.recipient.address,
    originalShipment.sender.address,
    originalShipment.serviceLevel,
    setReturnShipment,
  ]);

  return (
    <>
      <TitleWrapper>Return shipment</TitleWrapper>
      <Row justify="end">
        <Checkbox
          checked={returnToNewPartner}
          onChange={(e) => setReturnToNewpartner(e.target.checked)}
        >
          Return to another partner
        </Checkbox>
      </Row>
      <Row gutter={32}>
        <Col span={12}>
          <CreateShipmentAddressForm
            addressType="sender"
            shipment={returnShipment}
            setShipment={setReturnShipment}
          />
        </Col>
        <Col span={12}>
          {!returnToNewPartner && (
            <>
              <TitleWrapper>Please select the recipient address</TitleWrapper>
              <TextInput
                onChange={handleNotifEmailChange}
                label="Notification Email *"
                name="notificationEmail"
                value={returnShipment.recipient.notificationEmail}
              />
              <CreateShipmentSenderAddressSelection
                addressType="recipient"
                handlePartnerAddressIdChange={handlePartnerAddressIdChange}
                partnerId={originalShipment.sender.accountId || ''}
                partnerAddressId={originalShipment.sender.addressId || ''}
                setShipment={setReturnShipment}
              />
            </>
          )}
          {returnToNewPartner && (
            <CreateShipmentAddressForm
              addressType="recipient"
              shipment={returnShipment}
              setShipment={setReturnShipment}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default CreateShipmenReturnForm;
