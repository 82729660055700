import React, { useEffect, useMemo, useState } from 'react';

import { Col, DatePicker, Row, TimePicker } from 'antd';
import { getUnixTime, format } from 'date-fns';
import toSafeInteger from 'lodash/toSafeInteger';
import moment from 'moment';

const parseDateTimeStrings = (dateString, timeString) => {
  const dateArray = dateString.split('-').map((item) => toSafeInteger(item));
  const timeArray = timeString.split(':').map((item) => toSafeInteger(item));
  const dateTime = new Date(
    dateArray[0],
    dateArray[1] - 1,
    dateArray[2],
    timeArray[0],
    timeArray[1],
    timeArray[2]
  );
  const unixDateTime = getUnixTime(dateTime);

  return unixDateTime;
};

export const futureDateCheck = (current) =>
  current && current > moment().endOf('day');
export const pastDateCheck = (current) =>
  current && current < moment().startOf('day');

interface DateTimePicketProps {
  setUnixTimeStamp: (e: number | null) => void;
  disabledFutureDate?: boolean;
  disabledPastDate?: boolean;
  useDefaultTime?: boolean;
  withTime?: boolean;
  value?: number | null;
}

const DateTimePicker = ({
  setUnixTimeStamp,
  disabledFutureDate = false,
  disabledPastDate = false,
  useDefaultTime = true,
  withTime = true,
  value,
}: DateTimePicketProps) => {
  const [date, setDate] = useState<string | null>(null);
  const [time, setTime] = useState<string | null>(
    useDefaultTime ? '12:00:00' : null
  );

  useEffect(() => {
    value && setTime(format(value * 1000, 'HH:mm:SS'));
    value && setDate(format(value * 1000, 'yyyy-MM-dd'));
  }, [value]);

  const disabledDate = useMemo(() => {
    if (disabledFutureDate) {
      return disabledFutureDate ? futureDateCheck : undefined;
    }

    if (disabledPastDate) {
      return disabledPastDate ? pastDateCheck : undefined;
    }

    return undefined;
  }, [disabledFutureDate, disabledPastDate]);

  useEffect(() => {
    if (date && !time && !withTime) {
      setUnixTimeStamp(
        parseDateTimeStrings(date, format(new Date(), 'HH:mm:SS'))
      );

      return;
    }
    if (!date || !time) {
      setUnixTimeStamp(null);

      return;
    }

    setUnixTimeStamp(parseDateTimeStrings(date, time));
  }, [date, time, setUnixTimeStamp, withTime]);

  return (
    <Row>
      <Col span={12}>
        <DatePicker
          disabledDate={disabledDate}
          onChange={(date, dateString) => {
            setDate(dateString);
          }}
          value={
            value
              ? moment.unix(value)
              : date
                ? moment(date, 'YYYY-MM-DD')
                : undefined
          }
        />
      </Col>
      <Col span={12}>
        <TimePicker
          defaultValue={
            useDefaultTime
              ? moment().startOf('day').hour(12).minute(0)
              : undefined
          }
          onChange={(time, timeString) => {
            setTime(timeString);
          }}
          value={
            value
              ? moment.unix(value)
              : time
                ? moment(time, 'HH:mm:ss')
                : undefined
          }
        />
      </Col>
    </Row>
  );
};

export default DateTimePicker;
