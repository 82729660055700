import React from 'react';

import { Col, Row } from 'antd';

import TextInput from '@/components/forms/TextInput';

const ContributionForm = ({ contribution, id }) => {
  const {
    parsedTotalAmount,
    paymentMethod,
    paymentStatus,
    parsedPaymentDate,
    paymentUniqueId,
    paymentProcessor,
    inviteCode,
    parsedCreatedAt,
    parsedUpdatedAt,
    state,
    acceptedTosVersion,
    parsedStartDate,
    versionHash,
    parsedOffer,
  } = contribution;

  return (
    <>
      <Row gutter={24}>
        <Col span={16}>
          <TextInput
            disabled
            label="Contribution-ID"
            name="contributionId"
            value={id}
          />
        </Col>
        <Col span={parsedOffer.offerType === 'thebag' ? 6 : 8}>
          <TextInput
            disabled
            label="Total Contribution Amount"
            name="totalAmount"
            value={parsedTotalAmount}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={8}>
          <TextInput
            disabled
            label="Created at"
            name="createdAt"
            value={parsedCreatedAt}
          />
        </Col>
        <Col span={8}>
          <TextInput
            disabled
            label="Updated at"
            name="updatedAt"
            value={parsedUpdatedAt}
          />
        </Col>
        <Col span={8}>
          <TextInput
            disabled
            label="Start Date"
            name="startDate"
            value={parsedStartDate}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={8}>
          <TextInput
            disabled
            label="Invited By Code"
            name="inviteCode"
            value={inviteCode}
          />
        </Col>
        <Col span={8}>
          <TextInput
            label="Payment Unique ID"
            name="paymentUniqueID"
            disabled
            value={paymentUniqueId}
          />
        </Col>
        <Col span={8}>
          <TextInput
            label="Payment Status"
            name="paymentStatus"
            disabled
            value={paymentStatus}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={8}>
          <TextInput
            label="Payment Method"
            name="paymentMethod"
            disabled
            value={paymentMethod}
          />
        </Col>
        <Col span={8}>
          <TextInput
            label="Payment Date"
            name="paymentDate"
            disabled
            value={parsedPaymentDate}
          />
        </Col>
        <Col span={8}>
          <TextInput
            label="Payment Processor"
            name="paymentProcessor"
            disabled
            value={paymentProcessor}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={8}>
          <TextInput label="State" name="state" disabled value={state} />
        </Col>
        <Col span={8}>
          <TextInput
            label="Accepted ToS version"
            name="acceptedTosVersion"
            disabled
            value={acceptedTosVersion}
          />
        </Col>
        <Col span={8}>
          <TextInput
            label="Version Hash"
            name="versionHash"
            disabled
            value={versionHash}
          />
        </Col>
      </Row>
    </>
  );
};

export default ContributionForm;
