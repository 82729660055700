export const appRoutes = {
  ROOT: '/',
  LOGIN: '/login',
  PAGE_404: '/404',
  PRODUCTS: {
    ROOT: '/products',
    PRODUCTS_ID: ':id',
  },
  SHIPMENTS: '/shipments',
  LPACCOUNT: '/lp-account',
  SHARING_ANGELS: {
    ROOT: '/sharing-angels',
    CONTRIBUTIONS: 'contributions',
    CONTRIBUTIONS_ID: ':id',
    OFFERS: 'offers',
    OFFERS_ID: ':id',
  },
  LPVILLAGE: {
    ROOT: '/lp-village',
    PARTNERS: {
      ROOT: 'partners',
      PARTNER_ID: ':id',
      CREATE: 'create',
    },
    SHOPS: {
      ROOT: 'shops',
      SHOP_ID: ':id',
    },
    ROLES: 'roles',
    SCOPES: 'scopes',
  },
  BUY_N_RENT: {
    ROOT: '/buy-n-rent',
    CONTRACTS: 'contracts',
    CONTRACTS_ID: 'contracts/:id',
    OFFERS: 'offers',
  },
  TOS: {
    ROOT: '/tos',
    TOS_ID: ':id',
  },
  QUALITY_CHECKS: {
    ROOT: '/quality-check-types',
    QUALITY_CHECKS_ID: ':id',
  },
  SERVICE_LEVELS: '/service-levels',
  OFFER_TEMPLATE: {
    ROOT: '/offer-template',
    TEMPLATE_ID: ':offerId',
    CREATE: '/create',
  },
  CHANGELOG: {
    ROOT: '/changelog',
  },
  TRANSACTION: {
    ROOT: '/transaction',
    DETAILS: ':transactionId',
  },
  DASHBOARD: '/dashboard',
};
