import React from 'react';

import styled from 'styled-components';

import Dropdown from '@/components/forms/Dropdown';
import Label from '@/components/forms/Label';

const LabelWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

const OfferSelector = ({
  label,
  offers,
  onChange,
  offerId,
}: {
  label: string;
  offers: Array<any>;
  onChange: any;
  offerId: any;
}) => {
  const isOptionSelected = (option, selectValue) =>
    selectValue.some((val) => val.value === option.value);

  return (
    <>
      {label && (
        <LabelWrapper>
          <>
            <Label>
              <>{label}</>
            </Label>
          </>
        </LabelWrapper>
      )}
      <Dropdown
        defaultValue={offers[0]}
        isOptionSelected={isOptionSelected}
        options={offers}
        getOptionValue={(option) => option['id']}
        getOptionLabel={(option) =>
          `${option['label']} - ${option['durationInMonth']} months - ${option['multiplierInCents']} % - ${option['offerType']}`
        }
        value={offerId ? offers.find((el) => el.id === offerId) : offers[0]}
        mw="35rem"
        onChange={onChange}
      />
    </>
  );
};

export default OfferSelector;
