import { useCallback } from 'react';

import useError from './useError';
import useImperativeRequestWrapper from './useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useImportCSV: any = () => {
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpVillage');
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);

  const importCSV = useCallback(
    async (file) => {
      const bodyFormData = new FormData();
      bodyFormData.append('shopsFile', file);

      const result = await makeRequest({
        path: '/admin/bulk-shops',
        method: 'POST',
        body: bodyFormData,
        headers: { 'content-type': 'multipart/form-data' },
      });
      if (!!result.error) {
        addError(result.error);

        return { success: false };
      }
      addSuccessMsg(
        {
          msg: 'The CSV file was successfully uploaded',
          type: MessageType.Success,
        },
        'root',
        {}
      );

      return { success: true };
    },
    [addError, addSuccessMsg, makeRequest]
  );

  return [{ loading }, importCSV];
};

export default useImportCSV;
