import React, { useCallback, useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { ColumnProps } from 'antd/es/table';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

import CreateTos from '../components/CreateTos';
import useGetTos from '../hooks/useGetTos';
import useTosStore from '../hooks/useTosStore';
import { SERVICE_TYPES } from '../models/tos';
import PageTitle from '@/components/layout/PageTitle';
import StyledTable from '@/components/tables/StyledTable';
import useAntdColumns from '@/hooks/useAntdColumns';
import useWindowSize from '@/hooks/useWindowSize';

const TosListView = () => {
  const { getTos } = useGetTos();
  const rowData = useTosStore((state) => state.rowData);
  const dispatch = useTosStore((state) => state.dispatch);
  const loading = useTosStore((state) => state.loading);
  const defaultSort = useTosStore((state) => state.defaultSort);
  const windowSize = useWindowSize();

  useEffect(() => {
    getTos();
  }, [getTos]);

  useEffect(
    () => () => {
      dispatch({ type: 'reset' });
    },
    [dispatch]
  );

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: [
      'tosId',
      'parsedCreatedAt',
      'parsedUpdatedAt',
      'activeAt',
      'serviceType',
      'tosLocation',
    ],
    addDefaultColumns: false,
    columnsSpecialProps: {
      activeAt: {
        render: (text, record) => record.parsedActiveAt,
      },
      serviceType: {
        filters: SERVICE_TYPES.map((r) => ({ text: r, value: r })),
        filterMultiple: false,
      },
      tosLocation: {
        render: (text) => (
          <a href={text} target="_blank" rel="noreferrer">
            {text.substring(0, 50)}...
          </a>
        ),
      },
    },
    defaultSort: defaultSort,
    eyeLinkProps: { to: '/tos' },
  });

  const handleTableChange = useCallback(
    (pagination, filters) => {
      dispatch({
        type: 'updateFilters',
        args: {
          filters: {
            serviceType: isArray(get(filters, 'serviceType'))
              ? filters.serviceType[0]
              : null,
          },
        },
      });
    },
    [dispatch]
  );

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={11}>
        <PageTitle title="TERMS OF SERVICE" />
      </Grid>
      <Grid container justifyContent="flex-end" item xs={11}>
        <CreateTos />
      </Grid>

      <Grid item xs={11}>
        <StyledTable
          rowKey="id"
          dataSource={rowData}
          columns={columns}
          size="small"
          loading={loading}
          scroll={{ y: get(windowSize, 'height', 0) * 0.65 }}
          onChange={handleTableChange}
        />
      </Grid>
    </Grid>
  );
};

export default TosListView;
