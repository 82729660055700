import { useCallback, useEffect, useMemo, useState } from 'react';

import { pick } from 'lodash';
import isEqual from 'lodash/isEqual';
import { ObjectSchema } from 'yup';

const useEditRecord = <
  TRecord,
  EditablePaths extends Extract<keyof TRecord, string>,
>({
  editable,
  originalRecord,
  updateRecord,
  validationSchema,
}: {
  editable: readonly EditablePaths[];
  originalRecord: TRecord;
  updateRecord: (
    input: Pick<TRecord, EditablePaths>
  ) => Promise<{ success: boolean }>;
  validationSchema?: ObjectSchema<any>;
}) => {
  const [editedData, setEditedData] = useState<Pick<TRecord, EditablePaths>>(
    pick(originalRecord, editable) as Pick<TRecord, EditablePaths>
  );

  useEffect(() => {
    setEditedData(
      pick(originalRecord, editable) as Pick<TRecord, EditablePaths>
    );
  }, [originalRecord, editable]);

  const handleSubmit = useCallback(async () => {
    const { success } = await updateRecord(editedData);
    if (!success) {
      setEditedData(
        pick(originalRecord, editable) as Pick<TRecord, EditablePaths>
      );
    }
  }, [updateRecord, originalRecord, editedData, editable]);

  const handleCancel = useCallback(() => {
    setEditedData(
      pick(originalRecord, editable) as Pick<TRecord, EditablePaths>
    );
  }, [originalRecord, editable]);

  const validation = useMemo(() => {
    try {
      validationSchema?.validateSync(editedData);

      return { isValid: true, message: '' };
    } catch (error: any) {
      return { isValid: false, message: error.message };
    }
  }, [editedData, validationSchema]);

  const recordHasNotBeenModified = useMemo(
    () => isEqual(editedData, pick(originalRecord, editable)),
    [originalRecord, editedData, editable]
  );

  return {
    editedData,
    handleSubmit,
    handleCancel,
    isInvalid: !validation.isValid,
    errorMessage: validation.message,
    recordHasNotBeenModified,
    setEditedData,
  };
};

export default useEditRecord;
