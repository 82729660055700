import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useAddContribution: any = () => {
  const [{ loading }, makeRequest] =
    useImperativeRequestWrapper('serviceLpAccount');
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);

  const addAddress = async ({ address, accountId }) => {
    const { data, error } = await makeRequest({
      path: '/admin/addresses',
      method: 'post',
      body: { ...address, accountId: accountId },
    });
    if (error) {
      addError(error);

      return;
    }

    return data.id;
  };

  const addContribution = async ({ accountId, addressId, contribution }) => {
    const { error } = await makeRequest({
      path: '/admin/contributions',
      method: 'post',
      body: {
        accountId: accountId,
        offerId: contribution.offerId,
        totalAmountInCents: parseInt(contribution.totalAmountInCents) * 100,
        currency: contribution.currency,
        inviteCode: contribution.inviteCode,
        paymentMethod: contribution.paymentMethod,
        acceptedTosVersion: '',
        addressId: addressId,
        createdAt: contribution.createdAt,
        paymentStatus: contribution.paymentStatus,
        state: contribution.state,
      },
    });
    if (error) {
      addError(error);

      return { success: false };
    }
    addSuccessMsg(
      {
        msg: 'Contribution was successfully added!',
        type: MessageType.Success,
      },
      'root',
      {}
    );

    return { success: true };
  };

  return [{ loading }, addContribution, addAddress];
};

export default useAddContribution;
