import useRefreshRoles from './useRefreshRoles';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useDeleteScopeFromRole: any = () => {
  const addError = useError();
  const [, makeRequest] = useImperativeRequestWrapper('lpVillage');
  const getRoles = useRefreshRoles();
  const successMsg = useToasts((state) => state.addItem);

  const deleteScope = async (role, scopes) => {
    const { error } = await makeRequest({
      path: `/admin/roles/${role.id}/scopes`,
      method: 'delete',
      body: scopes,
    });
    if (error) {
      addError(error);

      return { success: false };
    }
    await getRoles();
    successMsg(
      {
        msg: `${scopes.length} scope${scopes.length > 1 ? 's' : ''} ${
          scopes.length > 1 ? 'were' : 'was'
        } successfully deleted from "${role.name}"`,
        type: MessageType.Success,
      },
      '',
      {}
    );

    return { success: true };
  };

  return deleteScope;
};
export default useDeleteScopeFromRole;
