import React, { useCallback, useState, useRef, MutableRefObject } from 'react';

import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Grid,
  Stack,
  NewTextField as TextField,
  Select,
  Box,
  ButtonV2 as Button,
  NewTooltip as Tooltip,
} from '@lp/ds-next';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import TrackingCodesTable from './TrackingCodesTable';
import useLPPartner from '../../lp-village/partners/api/useLPPartner';
import { useCreateTrackingCodeOnShipment } from '../api/trackingCode/createTrackingCode';
import { ITrackingCode } from '../types/trackingCode';
import { PhoneNumber } from '@/components/forms/phoneNumber';
import useInputFocus from '@/hooks/useInputFocus';

const LANG_OPTIONS = [
  { label: 'EN', value: 'EN' },
  { label: 'FR', value: 'FR' },
  { label: 'DE', value: 'DE' },
];
const INITIAL_STATE_TRACKINGCODE: ITrackingCode = {
  email: '',
  firstName: '',
  lastName: '',
  lang: 'EN',
  role: 1,
  shipmentId: '',
  phoneNumber: '',
};

const validationSchema = object().shape({
  firstName: string().required('First Name is required!'),
  lastName: string().required('Last name is required!'),
  email: string().email('Invalid email format!').required('Email is required!'),
});

const TrackingCodeForm = ({ id, contractStateStatus, partnerId }) => {
  const [ref] = useInputFocus({ initialFocus: true });
  const [trackingCode, setTrackingCode] = useState<any>(
    INITIAL_STATE_TRACKINGCODE
  );
  const [displayDeliveryKeyForm, setDisplayDeliveryKeyForm] =
    useState<boolean>(false);

  const tableRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  const {
    partnerState: { loading, data },
  } = useLPPartner(partnerId!);

  const { mutate: createTrackingCode, isPending } =
    useCreateTrackingCodeOnShipment({
      shipmentId: id,
    });

  const handleTextChange = useCallback(
    (e) => {
      setTrackingCode({ ...trackingCode, [e.target.name]: e.target.value });
    },
    [setTrackingCode, trackingCode]
  );

  const shareDeliveryKeyform = useFormik({
    initialValues: trackingCode,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      values.shipmentId = id;

      await createTrackingCode({ values, shipmentId: id });
      setTrackingCode(INITIAL_STATE_TRACKINGCODE);
      setDisplayDeliveryKeyForm(false);
    },
    initialTouched: {
      email: false,
      firstName: false,
      lastName: false,
      lang: false,
      phoneNumber: false,
    },
  });

  return (
    <Box pb="2rem">
      {displayDeliveryKeyForm && (
        <form
          onSubmit={shareDeliveryKeyform.handleSubmit}
          data-testid="delivery-key-form"
        >
          <Grid container pb="1rem">
            <Grid item mobile={6}>
              <Stack direction="row" gap="1rem">
                <TextField
                  ref={ref}
                  onChange={handleTextChange}
                  label="First Name"
                  name="firstName"
                  value={shareDeliveryKeyform.values.firstName}
                  data-testid="delivery-key-firstName"
                  required
                  onBlur={shareDeliveryKeyform.handleBlur}
                  error={
                    shareDeliveryKeyform.touched.firstName &&
                    !!shareDeliveryKeyform.errors.firstName
                  }
                  helperText={shareDeliveryKeyform.errors.firstName as any}
                />
                <TextField
                  onChange={handleTextChange}
                  label="Last Name"
                  name="lastName"
                  value={shareDeliveryKeyform.values.lastName}
                  data-testid="delivery-key-lastName"
                  required
                  onBlur={shareDeliveryKeyform.handleBlur}
                  error={
                    shareDeliveryKeyform.touched.lastName &&
                    !!shareDeliveryKeyform.errors.lastName
                  }
                  helperText={shareDeliveryKeyform.errors.lastName as any}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container pb="1rem">
            <Grid item mobile={6}>
              <Stack direction="row" gap="1rem">
                <TextField
                  onChange={handleTextChange}
                  label="Email"
                  name="email"
                  value={shareDeliveryKeyform.values.email}
                  data-testid="delivery-key-email"
                  required
                  onBlur={shareDeliveryKeyform.handleBlur}
                  error={
                    shareDeliveryKeyform.touched.email &&
                    !!shareDeliveryKeyform.errors.email
                  }
                  helperText={shareDeliveryKeyform.errors.email as any}
                />
                <Stack
                  direction="row"
                  width="100%"
                  spacing={1}
                  alignItems="center"
                >
                  <PhoneNumber
                    label="Phone number"
                    defaultCountryCode="FR"
                    name="phoneNumber"
                    value={shareDeliveryKeyform.values.phoneNumber}
                    data-testid="delivery-key-phoneNumber"
                    fullWidth
                    disabled={!loading && !data.sms_enabled}
                    countrySelectorMaxHeight="12.5rem"
                    onChange={(value) => {
                      shareDeliveryKeyform.setFieldValue('phoneNumber', value);
                    }}
                    error={!!shareDeliveryKeyform.errors.phone_number}
                    helperText={shareDeliveryKeyform.errors.phone_number as any}
                  />
                  <Tooltip title="Optional: If empty, then no text message is sent.">
                    <InfoCircleOutlined style={{ fontSize: '1.5rem' }} />
                  </Tooltip>
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item mobile={3}>
              <Select
                label="Language"
                options={LANG_OPTIONS}
                name="lang"
                value={shareDeliveryKeyform.values.lang || 'EN'}
                onChange={shareDeliveryKeyform.handleChange}
                data-testid="delivery-key-langue"
              />
            </Grid>
            <Grid item mobile={3}>
              <Stack direction="row" justifyContent="space-between" pl="5px">
                <Button
                  onClick={shareDeliveryKeyform.submitForm}
                  disabled={
                    !shareDeliveryKeyform.values?.firstName ||
                    !shareDeliveryKeyform.values?.lastName ||
                    !shareDeliveryKeyform.values?.email ||
                    isPending
                  }
                  data-testid="delivery-key-cta-create"
                >
                  Send
                </Button>
                <Button
                  size="large"
                  variant="secondary"
                  onClick={() => {
                    setDisplayDeliveryKeyForm(!displayDeliveryKeyForm);
                    setTrackingCode(INITIAL_STATE_TRACKINGCODE);
                  }}
                  data-testid="delivery-key-cta-create-cancel"
                >
                  Cancel
                </Button>
              </Stack>
            </Grid>
          </Grid>
          {contractStateStatus && (
            <p style={{ paddingTop: '1rem', color: 'orange' }}>
              Check contract state of shipment before create delivery key
            </p>
          )}
        </form>
      )}

      <Stack direction="row" justifyContent="flex-end" pb="1rem">
        <Tooltip title=" Share a new delivery key">
          <Button
            data-testid="delivery-key-cta-share"
            size="small"
            onClick={() => {
              setDisplayDeliveryKeyForm(!displayDeliveryKeyForm);
              setTimeout(() => {
                if (tableRef.current) {
                  tableRef.current.scrollIntoView({ behavior: 'smooth' });
                }
              }, 0);
            }}
            disabled={displayDeliveryKeyForm}
          >
            +
          </Button>
        </Tooltip>
      </Stack>
      <TrackingCodesTable ref={tableRef} />
    </Box>
  );
};
export default TrackingCodeForm;
