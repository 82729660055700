import { useCallback, useEffect, useState } from 'react';

import { IModifiedLPAccountUser } from '../models/lpaccount';
import { profileSchema } from '../schemas/lpaccountSchema';
import dates from '@/helpers/dates';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

export interface ILPAccountUserState {
  error: string;
  loading: boolean;
  data: IModifiedLPAccountUser;
}

const useLPAccountUser: (id: string) => {
  user: ILPAccountUserState;
  setUser: (res: any) => void;
  getUser: () => void;
  updateUser: (
    data: Pick<IModifiedLPAccountUser, 'firstName' | 'lastName' | 'phoneNumber'>
  ) => Promise<{ success: boolean }>;
  errorGet: string;
  errorPut: string;
} = (id) => {
  const [user, setUser] = useState<ILPAccountUserState>({
    error: '',
    loading: true,
    data: {
      id: '',
      createdAt: 0,
      updatedAt: 0,
      parsedCreatedAt: '',
      parsedUpdatedAt: '',
      firstName: '',
      givenName: '',
      lastName: '',
      phoneNumber: '',
      phoneNumberVerified: false,
      email: '',
      emailVerified: false,
      accountVerified: false,
      publicHandle: '',
      profilePicture: '',
      identities: [],
      notes: [],
      preferredLanguage: '',
      businessType: '',
    },
  });

  const [{ error: errorGet }, makeRequestGet] =
    useImperativeRequestWrapper('serviceLpAccount');
  const [{ error: errorPut }, makeRequestPut] =
    useImperativeRequestWrapper('serviceLpAccount');

  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);

  const setUserWithProcessing = useCallback(
    async (data) => {
      try {
        const validated = await profileSchema.validate(data.profile);
        const withDates = {
          ...validated,
          parsedCreatedAt: dates.parseDate(validated.createdAt),
          parsedUpdatedAt: dates.parseDate(validated.updatedAt),
          parsedDeletedAt: validated.deletedAt
            ? dates.parseDate(validated.deletedAt)
            : '',
        } as IModifiedLPAccountUser;
        setUser((prev) => ({ ...prev, loading: false, data: withDates }));
      } catch (err: any) {
        setUser((prev) => ({ ...prev, loading: false, error: err.toString() }));
        addError(err);
      }
    },
    [setUser, addError]
  );

  const getUser = useCallback(() => {
    makeRequestGet({
      path: `/admin/accounts/${id}`,
      method: 'get',
    }).then(async (res) => {
      if (!!res.error) {
        setUser((prev) => ({ ...prev, loading: false, error: res.error }));
        addError(res.error);

        return;
      }
      await setUserWithProcessing(res.data);
    });
  }, [makeRequestGet, addError, id, setUserWithProcessing]);

  const updateUser = useCallback(
    async (account) => {
      const { error, data } = await makeRequestPut({
        path: `/admin/accounts/${id}`,
        method: 'put',
        body: account,
      });
      if (error) {
        addError(error);

        return { success: false };
      }
      setUser((prev) => ({ ...prev, loading: true }));
      await setUserWithProcessing(data);
      addSuccessMsg(
        {
          msg: 'Account details were successfully updated',
          type: MessageType.Success,
        },
        'root',
        {}
      );

      setUser((prev) => ({ ...prev, loading: false }));

      return { success: true };
    },
    [addError, addSuccessMsg, id, makeRequestPut, setUserWithProcessing]
  );

  useEffect(() => {
    if (!id) return;
    getUser();
  }, [getUser, id]);

  return {
    errorGet,
    errorPut,
    user,
    setUser: setUserWithProcessing,
    getUser,
    updateUser,
  };
};

export default useLPAccountUser;
