import React from 'react';

import { Navigate, Routes, Route } from 'react-router-dom';

import Layout from './layout/Layout';
import ProtectedRoute from './ProtectedRoute';
import { BoxesRouter } from '../features/boxes';
import { BuyNRentRouter } from '../features/buy-n-rent';
import { LPAccountUsers } from '../features/lp-account-users';
import { VillageRouter } from '../features/lp-village';
import { BoxQualityCheckRouter } from '../features/quality-checks';
import { ServiceLevelsRouter } from '../features/serviceLevels';
import { SharingAngelsRouter } from '../features/sharing-angels';
import { ShipmentsRouter } from '../features/shipments';
import { TosRouter } from '../features/tos';
/* import { OfferTemplateRouter } from '../features/OfferTemplate'; */
import { TransactionRouter } from '../features/transaction';
import useAppState from '../hooks/useAppState';
import { scopes } from '@/config';
import { appRoutes } from '@/configs/appRoutes';
import { ChangelogRoutes } from '@/features/changelog';
import { DashboardRouter } from '@/features/dashboard';
import { useAuth0 } from '@/shared/auth0';

const MainRouter = () => {
  const { isAuthenticated } = useAuth0();
  const userScopes = useAppState((state) => state.scopes);

  const privateRoutes = [
    {
      path: `${appRoutes.SHIPMENTS}/*`,
      component: ShipmentsRouter,
      requiredScopes: [scopes.VIEW_SHIPMENTS],
    },
    {
      path: `${appRoutes.PRODUCTS.ROOT}/*`,
      component: BoxesRouter,
      requiredScopes: [scopes.VIEW_PRODUCT],
    },
    {
      path: `${appRoutes.LPACCOUNT}/*`,
      component: LPAccountUsers,
      requiredScopes: [scopes.VIEW_LPACCOUNT],
    },
    {
      path: `${appRoutes.SHARING_ANGELS.ROOT}/*`,
      component: SharingAngelsRouter,
      requiredScopes: [scopes.VIEW_SHARING_ANGELS],
    },
    {
      path: `${appRoutes.LPVILLAGE.ROOT}/*`,
      component: VillageRouter,
      requiredScopes: [scopes.VIEW_LPVILLAGE],
    },
    {
      path: `${appRoutes.BUY_N_RENT.ROOT}/*`,
      component: BuyNRentRouter,
      requiredScopes: [scopes.VIEW_BUY_N_RENT],
    },
    {
      path: `${appRoutes.TOS.ROOT}/*`,
      component: TosRouter,
      requiredScopes: [scopes.MANAGE_TOS],
    },
    {
      path: `${appRoutes.QUALITY_CHECKS.ROOT}/*`,
      component: BoxQualityCheckRouter,
      requiredScopes: [scopes.VIEW_QUALITY_CHECKS],
    },
    {
      path: `${appRoutes.SERVICE_LEVELS}/*`,
      component: ServiceLevelsRouter,
      requiredScopes: [scopes.VIEW_SERVICE_LEVELS],
    },
    {
      path: `${appRoutes.CHANGELOG.ROOT}/*`,
      component: ChangelogRoutes,
      requiredScopes: [scopes.VIEW_CHANGELOG],
    },
    {
      path: `${appRoutes.DASHBOARD}/*`,
      component: DashboardRouter,
      requiredScopes: [scopes.VIEW_CHANGELOG],
    },
    /* Blocked because waiting new specification    {
      path: `${appRoutes.OFFER_TEMPLATE.ROOT}/*`,
      component: OfferTemplateRouter,
      requiredScopes: [scopes.VIEW_SERVICE_LEVELS],
    }, */
    {
      path: `${appRoutes.TRANSACTION.ROOT}/*`,
      component: TransactionRouter,
      requiredScopes: [scopes.VIEW_TRANSACTION],
    },
  ];

  return (
    <Layout>
      <Routes>
        {privateRoutes.map((appRoute, index) => (
          <Route
            key={`${index}`}
            path={appRoute.path}
            element={
              <ProtectedRoute
                path={appRoute.path}
                component={appRoute.component}
                authenticated={isAuthenticated}
                userScopes={userScopes}
                requiredScopes={appRoute.requiredScopes}
                key={`${index}`}
              />
            }
          />
        ))}
        <Route
          path="/"
          element={
            <Navigate
              to={isAuthenticated ? '/products' : appRoutes.LOGIN}
              replace
            />
          }
        />
        <Route path="*" element={<Navigate to="/products" replace />} />
      </Routes>
    </Layout>
  );
};

export default MainRouter;
