import React, { useCallback, useEffect, useState } from 'react';

import { Radio } from 'antd';

import { theme } from '@/config';

const BillingState = ({
  isBillable,
  isPaid,
  handleClick,
}: {
  isPaid: boolean;
  isBillable: boolean;
  handleClick: (body: {}) => void;
  disabled?: boolean;
}) => {
  const [billState, setBillSate] = useState<boolean>(false);

  const BILL_STATUS = [
    {
      label: billState ? 'To bill' : ' Not to bill',
      value: billState ? false : true,
      ...(!billState && {
        style: {
          background: theme.colors.errorBc,
          border: theme.colors.errorBc,
          color: theme.colors.white,
        },
      }),
    },
  ];

  const handleChange = useCallback(() => {
    setBillSate(!billState);

    handleClick({
      isBillable: !billState,
      isBilled: false,
      invoiceId: '',
      paidCost: 0,
    });
  }, [billState, handleClick]);

  useEffect(() => {
    setBillSate(isBillable);
  }, [isBillable]);

  return (
    <div>
      <Radio.Group
        name="partner-status"
        options={BILL_STATUS}
        onChange={() => (!isPaid ? handleChange() : null)}
        optionType="button"
        buttonStyle="solid"
        value={billState}
      />
    </div>
  );
};

export default BillingState;
