import { useCallback, useEffect, useMemo, useState } from 'react';

import { IBoxEvent, IShipment } from '../models/shipment';
import boxEventsSchema from '../schemas/boxEventSchema';
import dates from '@/helpers/dates';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

export const GPS_ACQUIRE = 'GPS_ACQUIRE';
export const GEOLOCATION_INFO = 'GEOLOCATION_INFO';

const PAGE_SIZE = 100;

const SUPPORTED_TYPES = [GPS_ACQUIRE, GEOLOCATION_INFO];

const getTimeRange = (data: IBoxEvent[]) => {
  if (data.length === 0) {
    return { latestEventDate: '', oldestEventDate: '' };
  }
  const latestEvent = data[0];
  const oldestEvent = data.slice(-1)[0];
  const latestEventDate = dates.parseDate(latestEvent.timestamp);
  const oldestEventDate = dates.parseDate(oldestEvent.timestamp);

  return { latestEventDate, oldestEventDate };
};

const useShipmentMapEvents = (shipment: IShipment) => {
  const [data, setData] = useState<IBoxEvent[] | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalNrOfPages, setTotalNrOfPages] = useState(1);
  const [timeRange, setTimeRange] = useState({ from: '', to: '' });
  const addError = useError();

  const offset = useMemo(() => (currentPage - 1) * PAGE_SIZE, [currentPage]);

  const [{ loading }, makeRequest] = useImperativeRequestWrapper('tracking');

  const paginationParams = useMemo(
    () =>
      new URLSearchParams({
        offset: `${offset}`,
        pageSize: `${PAGE_SIZE}`,
        order: 'DESC',
        sortBy: 'timestamp',
      }),
    [offset]
  );

  const getShipmentEvents = useCallback(
    async (shipmentId: string) => {
      const { data, error } = await makeRequest({
        path: `/shipments/${shipmentId}/events?types=${SUPPORTED_TYPES.join(
          ','
        )}&${paginationParams}`,
      });
      if (error) {
        addError(error);

        return;
      }
      try {
        const validatedData = (await boxEventsSchema.validate(
          data.events || []
        )) as IBoxEvent[];
        const { latestEventDate, oldestEventDate } =
          getTimeRange(validatedData);
        setData(validatedData || []);
        setTimeRange({ to: oldestEventDate, from: latestEventDate });

        setTotalNrOfPages(data.total ? Math.ceil(data.total / PAGE_SIZE) : 1);
      } catch (e) {
        addError(error);
      }
    },
    [makeRequest, addError, paginationParams]
  );

  useEffect(() => {
    if (shipment.box.id) {
      getShipmentEvents(shipment.id);
    }
  }, [shipment, getShipmentEvents]);

  return {
    events: data,
    currentPage,
    setCurrentPage,
    loading,
    totalNrOfPages,
    timeRange,
  };
};

export default useShipmentMapEvents;
