import React, { Dispatch, SetStateAction, useCallback } from 'react';

import { Col, Row } from 'antd';
import styled from 'styled-components';

import BoxOfferSelector from './BoxOfferSelector';
import {
  CreateBoxContract,
  PaymentMethods,
  PaymentProcessors,
  PaymentStatuses,
  BoxContractStates,
} from '../models/boxContract';
import DateTimePicker from '@/components/forms/DateTimePicker';
import IntegerInput from '@/components/forms/IntegerInput';
import Label from '@/components/forms/Label';
import TextInput from '@/components/forms/TextInput';
import SimpleSelector from '@/components/SimpleSelector';
import { LpAccountAddressSelector } from '@/features/lp-account-users';
import { SearchAccount } from '@/features/lp-account-users';
import { SearchPartner, SearchShop } from '@/features/lp-village';

interface ICreateBoxContractForm {
  setBoxContract: Dispatch<SetStateAction<CreateBoxContract>>;
  boxContract: CreateBoxContract;
}

const MarginRow = styled(Row)`
  margin-bottom: 2rem;
`;

const CreateBoxContractForm = ({
  boxContract,
  setBoxContract,
}: ICreateBoxContractForm) => {
  const handleInputChange = useCallback(
    (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setBoxContract((prev) => ({
        ...prev,
        [name]: value,
      }));
    },
    [setBoxContract]
  );

  const handlePaymentDateChange = useCallback(
    (value) => {
      setBoxContract((prev) => ({
        ...prev,
        paymentDate: value,
      }));
    },
    [setBoxContract]
  );

  const handlepaymentReceivedAtChange = useCallback(
    (value) => {
      setBoxContract((prev) => ({ ...prev, paymentReceivedAt: value }));
    },
    [setBoxContract]
  );
  const handleChangeAccountId = useCallback(
    (accountId: string) => {
      setBoxContract((prev) => ({
        ...prev,
        accountId,
        email: accountId ? '' : prev.email,
      }));
    },
    [setBoxContract]
  );

  const handleChangePartnerId = useCallback(
    (partnerId: string) => {
      setBoxContract((prev) => ({ ...prev, partnerId }));
    },
    [setBoxContract]
  );

  const handleChangeShopId = useCallback(
    (shopId: string) => {
      setBoxContract((prev) => ({ ...prev, shopId }));
    },
    [setBoxContract]
  );

  return (
    <div>
      <MarginRow gutter={24}>
        <Col span={12}>
          <BoxOfferSelector
            onChange={(boxContractOfferID: string) =>
              setBoxContract((prev) => ({ ...prev, boxContractOfferID }))
            }
          />
        </Col>
        <Col />
      </MarginRow>
      <MarginRow gutter={24}>
        <Col span={12}>
          <SearchAccount
            accountId={boxContract.accountId}
            setAccountId={handleChangeAccountId}
          />
        </Col>
        {boxContract.accountId && (
          <Col span={12}>
            <LpAccountAddressSelector
              onChange={(addressId: string) =>
                setBoxContract((prev) => ({ ...prev, addressId }))
              }
              accountId={boxContract.accountId}
              addressId={boxContract.addressId}
            />
          </Col>
        )}
      </MarginRow>
      <MarginRow gutter={24}>
        <Col span={12}>
          <TextInput
            label="Or enter an email"
            name="email"
            value={boxContract.email}
            onChange={handleInputChange}
            disabled={!!boxContract.accountId}
          />
        </Col>
      </MarginRow>
      <Row gutter={24}>
        <Col span={6}>
          <Label>Payment Date *</Label>
          <DateTimePicker
            setUnixTimeStamp={handlePaymentDateChange}
            useDefaultTime={false}
          />
        </Col>
        <Col span={6}>
          <Label>payment Received At *</Label>
          <DateTimePicker
            setUnixTimeStamp={handlepaymentReceivedAtChange}
            useDefaultTime={false}
          />
        </Col>
        <Col span={12}>
          <IntegerInput
            onWheel={(e) => e.target.blur()}
            label="Total amount in (in Euro) *"
            name="totalAmountInCents"
            value={boxContract.totalAmountInCents}
            type="number"
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            label="Accepted ToS Version *"
            name="acceptedTosVersion"
            value={boxContract.acceptedTosVersion}
            onChange={handleInputChange}
          />
        </Col>
        <Col span={12}>
          <TextInput
            label="Broker-ID"
            name="brokerId"
            value={boxContract.brokerId}
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            label="Payment Unique ID *"
            name="paymentUniqueId"
            value={boxContract.paymentUniqueId}
            onChange={handleInputChange}
          />
        </Col>
        <Col span={12}>
          <IntegerInput
            label="Number of Boxes *"
            name="numOfBox"
            value={boxContract.numOfBox}
            type="number"
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <SearchPartner
            partnerId={boxContract.partnerId}
            setPartnerId={handleChangePartnerId}
          />
        </Col>
        <Col span={12}>
          <SearchShop
            shopId={boxContract.shopId}
            setShopId={handleChangeShopId}
          />
        </Col>
      </Row>
      <MarginRow gutter={24}>
        <Col span={12}>
          <SimpleSelector
            label="Payment method *"
            options={PaymentMethods}
            onChange={(paymentMethod) =>
              setBoxContract((prev) => ({ ...prev, paymentMethod }))
            }
            value={boxContract.paymentMethod}
          />
        </Col>
        <Col span={12}>
          <SimpleSelector
            label="Payment processor *"
            options={PaymentProcessors}
            onChange={(paymentProcessor) =>
              setBoxContract((prev) => ({ ...prev, paymentProcessor }))
            }
            value={boxContract.paymentProcessor}
          />
        </Col>
      </MarginRow>
      <MarginRow gutter={24}>
        <Col span={12}>
          <SimpleSelector
            label="Payment status *"
            options={PaymentStatuses}
            onChange={(paymentStatus) =>
              setBoxContract((prev) => ({ ...prev, paymentStatus }))
            }
            value={boxContract.paymentStatus}
          />
        </Col>
        <Col span={12}>
          <SimpleSelector
            label="State *"
            options={BoxContractStates}
            onChange={(state) => setBoxContract((prev) => ({ ...prev, state }))}
            value={boxContract.state}
          />
        </Col>
      </MarginRow>
    </div>
  );
};

export default CreateBoxContractForm;
