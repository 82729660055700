import React from 'react';

import styled from 'styled-components';

import NewsletterTable from '../NewsletterTable';
import SocialMediaTable from '../SocialMediaTable';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const DashboardView = () => (
  <Wrapper>
    <SocialMediaTable />
    <NewsletterTable />
  </Wrapper>
);

export default DashboardView;
