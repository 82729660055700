import React, { useCallback, useEffect } from 'react';

import { ColumnProps } from 'antd/es/table';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import styled from 'styled-components';

import ActiveRelations from './ActiveRelations';
import CreateRelation from './CreateRelation';
import useBoxesRelationsStore from '../hooks/useBoxesRelationsStore';
import { TBoxesSortBy } from '../hooks/useBoxesStore';
import useBoxRelations from '../hooks/useBoxRelations';
import { RELATION_TYPES } from '../models/box';
import EyeLink from '@/components/EyeLink';
import StyledTable from '@/components/tables/StyledTable';
import { scopes } from '@/config';
import {
  getCurrentPage,
  getOffset,
  parseAntdSorter,
} from '@/helpers/antdTable';
import useAntdColumns from '@/hooks/useAntdColumns';
import useAppState from '@/hooks/useAppState';
import useWindowSize from '@/hooks/useWindowSize';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const getPath = (objType: string) => {
  switch (objType) {
    case 'LP_ACCOUNT':
      return '/lp-account';
    case 'LP_SHOP':
      return '/lp-village/shops';
    case 'LP_PARTNER':
      return '/lp-village/partners';
    default:
      return '';
  }
};

const BoxManagement = ({ id }: { id: string }) => {
  const {
    getActiveRelations,
    createRelation,
    activeRelations,
    getRelations,
    loading: activeLoading,
  } = useBoxRelations(id);

  useEffect(() => {
    getRelations();
  }, [getRelations]);

  useEffect(() => {
    getActiveRelations();
  }, [getActiveRelations]);

  const rowData = useBoxesRelationsStore((state) => state.rowData);
  const loading = useBoxesRelationsStore((state) => state.loading);
  const pageSize = useBoxesRelationsStore((state) => state.pageSize);
  const offset = useBoxesRelationsStore((state) => state.offset);
  const total = useBoxesRelationsStore((state) => state.total);
  const defaultSort = useBoxesRelationsStore((state) => state.defaultSort);
  const dispatch = useBoxesRelationsStore((state) => state.dispatch);
  const windowSize = useWindowSize();
  const pagination = {
    current: getCurrentPage(pageSize, offset),
    pageSize,
    total,
    showSizeChanger: false,
  };
  const userScopes = useAppState((state) => state.scopes);
  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const [sortField, sortOrder] = parseAntdSorter(sorter);
      dispatch({
        type: 'updateSorting',
        args: { sortBy: sortField as TBoxesSortBy, order: sortOrder },
      });
      dispatch({
        type: 'updatePagination',
        args: {
          offset: getOffset(pagination.pageSize, pagination.current),
        },
      });
      dispatch({
        type: 'updateFilters',
        args: {
          relationType: isArray(get(filters, 'relationType'))
            ? filters.relationType[0]
            : null,
        },
      });
    },
    [dispatch]
  );

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: [
      'relationId',
      'parsedActiveSince',
      'objectType',
      'objectId',
      'emailOrName',
      'relationType',
    ],
    addDefaultColumns: true,
    defaultSort: defaultSort,
    columnsSpecialProps: {
      relationType: {
        filters: RELATION_TYPES.map((r) => ({ text: r, value: r })),
        filterMultiple: false,
      },
      emailOrName: {
        render: (_, record) => {
          switch (record.objectType) {
            case 'LP_SHOP':
            case 'LP_PARTNER':
              return record.object.name;
            case 'LP_ACCOUNT':
              return record.object.email
                ? record.object.email
                : `${record.object.firstName} ${record.object.lastName}`;
          }
        },
        width: '12rem',
      },
      parsedActiveSince: {
        width: '7rem',
      },
    },
    eyeLinkProps: {
      rest: {
        render: (text, record) => {
          const objType = record.objectType;
          const path = getPath(objType);

          return <EyeLink to={`${path}/${record.objectId}`} />;
        },
      },
    },
  });

  return (
    <>
      <Wrapper>
        <ActiveRelations activeRelations={activeRelations} />
        <CreateRelation
          getActiveRelations={getActiveRelations}
          createRelation={createRelation}
          loading={activeLoading}
        />
      </Wrapper>
      <StyledTable
        rowKey="id"
        dataSource={rowData.filter(
          (row) =>
            !['OWNER'].includes(row.relationType) ||
            (userScopes.includes(scopes.VIEW_PRODUCT_OWNER) &&
              ['OWNER'].includes(row.relationType))
        )}
        columns={columns}
        size="small"
        pagination={pagination}
        loading={loading}
        scroll={{ y: get(windowSize, 'height', 0) * 0.65 }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default BoxManagement;
