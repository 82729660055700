import useScopesStore from './useScopesStore';
import scopesPostProcessor from '../helpers/scopesPostProcessor';
import usePaginatedTableData from '@/hooks/usePaginatedTableData';

const useGetScopes = () => {
  const dispatch = useScopesStore((state) => state.dispatch);
  const order = useScopesStore((state) => state.order);
  const sortBy = useScopesStore((state) => state.sortBy);
  const offset = useScopesStore((state) => state.offset);
  const pageSize = useScopesStore((state) => state.pageSize);

  usePaginatedTableData({
    params: {
      path: '/admin/scopes',
      method: 'get',
      reducer: scopesPostProcessor,
    },
    dispatch,
    queryParams: Object.assign({
      pageSize: pageSize,
      offset: offset,
      sortBy: sortBy,
      order: order,
    }),
    queryParamsOptions: { skipEmptyString: true },
    service: 'lpVillage',
  });
};
export default useGetScopes;
