import { create } from 'zustand';

import { IScope } from '../../roles/models/role';

export type TLPScopesSortBy = 'name' | 'createdAt' | 'updatedAt';

interface IScopesStore {
  rowData: IScope[];
  pageSize: number;
  offset: number;
  sortBy: TLPScopesSortBy;
  order: 'ASC' | 'DESC';
  defaultSort: {
    sortBy: TLPScopesSortBy;
    order: 'ASC' | 'DESC';
  };
  error: string | boolean;
  loading: boolean;
  count: number;
  dispatch: (args: TLPScopesReducerInput) => void;
  total: number;
}

type TLPScopesReducerInput =
  | {
      type: 'updateRowData';
      args: { rowData: any[] };
    }
  | {
      type: 'updateError';
      args: { error: string | boolean };
    }
  | {
      type: 'updateLoading';
      args: { loading: boolean };
    }
  | {
      type: 'updatePagination';
      args: { pageSize: number; offset: number };
    }
  | {
      type: 'updateSorting';
      args: {
        sortBy: TLPScopesSortBy;
        order: 'ASC' | 'DESC';
      };
    }
  | {
      type: 'updateCount';
      args: { count: number };
    }
  | {
      type: 'updateTotal';
      args: { total: number };
    }
  | {
      type: 'updateDefaultSort';
    }
  | {
      type: 'reset';
    };

const lpScopesReducer = (state: IScopesStore, input: TLPScopesReducerInput) => {
  switch (input.type) {
    case 'updateRowData':
      return { ...state, rowData: input.args.rowData };
    case 'updateError':
      return { ...state, error: input.args.error };
    case 'updateLoading':
      return { ...state, loading: input.args.loading };
    case 'updatePagination':
      return {
        ...state,
        pageSize: input.args.pageSize,
        offset: input.args.offset,
      };
    case 'updateSorting':
      return { ...state, sortBy: input.args.sortBy, order: input.args.order };
    case 'updateCount':
      return { ...state, count: input.args.count };
    case 'updateTotal':
      return { ...state, total: input.args.total };
    case 'updateDefaultSort':
      return {
        ...state,
        defaultSort: { sortBy: state.sortBy, order: state.order },
      };
    case 'reset':
      return INITIAL_STATE;
  }
};

const INITIAL_STATE: Omit<IScopesStore, 'dispatch'> = {
  rowData: [],
  error: '',
  loading: false,
  pageSize: 25,
  offset: 0,
  sortBy: 'updatedAt',
  order: 'DESC',
  defaultSort: {
    sortBy: 'updatedAt',
    order: 'DESC',
  },
  count: 0,
  total: 0,
};

const useScopesStore = create<IScopesStore>((set) => ({
  ...INITIAL_STATE,
  dispatch: (input) => set((state) => lpScopesReducer(state, input)),
}));

export default useScopesStore;
