import { useEffect } from 'react';

import get from 'lodash/get';
import queryString, { StringifyOptions } from 'query-string';

import useError, { IErrorOptions } from './useError';
import useRemoteDataWrapper from './useRemoteDataWrapper';
import { TAPIService } from '../configs/app';
import { IUseRemoteData } from '@/shared/hooks/useRemoteData';

type TDispatchInput =
  | {
      type: 'updateRowData';
      args: { rowData: any[] };
    }
  | {
      type: 'updateError';
      args: { error: string | boolean };
    }
  | {
      type: 'updateLoading';
      args: { loading: boolean };
    }
  | {
      type: 'updateCount';
      args: { count: number };
    }
  | {
      type: 'updateTotal';
      args: { total: number };
    };

interface IUsePaginatedTableData {
  params: IUseRemoteData;
  queryParams: {
    pageSize?: number;
    sortBy?: string;
    offset?: number;
    order?: 'ASC' | 'DESC';
    [key: string]: any;
  };
  dispatch?: (args: TDispatchInput) => void;
  queryParamsOptions?: StringifyOptions;
  service: TAPIService;
  errorParsingOptions?: IErrorOptions;
}

const usePaginatedTableData = ({
  params,
  dispatch,
  queryParams,
  queryParamsOptions,
  service,
  errorParsingOptions,
}: IUsePaginatedTableData) => {
  const withQueryParams = {
    ...params,
    path: `${params.path}?${queryString.stringify(
      queryParams,
      queryParamsOptions
    )}`,
  };

  const { error, loading, data } = useRemoteDataWrapper(
    withQueryParams,
    service
  );
  const addError = useError();

  useEffect(() => {
    if (!dispatch) return;

    dispatch({ type: 'updateError', args: { error } });
    if (error) {
      if (errorParsingOptions) {
        addError(error, errorParsingOptions);
      } else {
        addError(error);
      }
    }
  }, [dispatch, error, addError, errorParsingOptions]);

  useEffect(() => {
    if (!dispatch) return;
    dispatch({ type: 'updateLoading', args: { loading } });
  }, [dispatch, loading]);

  useEffect(() => {
    if (!dispatch) return;

    if (data) {
      dispatch({
        type: 'updateRowData',
        args: { rowData: get(data, 'items', []) },
      });
      dispatch({
        type: 'updateTotal',
        args: { total: get(data, 'total', 0) },
      });
      dispatch({
        type: 'updateCount',
        args: { count: get(data, 'count', 0) },
      });

      return;
    }
    dispatch({ type: 'updateRowData', args: { rowData: [] } });
  }, [dispatch, data]);

  return { error, loading, data };
};

export default usePaginatedTableData;
