import pick from 'lodash/pick';

import useRefreshRoles from './useRefreshRoles';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useEditRole: any = () => {
  const addError = useError();
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpVillage');
  const successMsg = useToasts((state) => state.addItem);
  const getRoles = useRefreshRoles();

  const editRole = async (role) => {
    const { error } = await makeRequest({
      path: `/admin/roles/${role.id}`,
      method: 'put',
      body: pick(role, 'name', 'description', 'type'),
    });
    if (error) {
      addError(error);

      return { success: false };
    }
    successMsg(
      { msg: 'The role was successfully modified', type: MessageType.Success },
      'root',
      {}
    );
    await getRoles();

    return { success: true };
  };

  return [{ loading }, editRole];
};

export default useEditRole;
