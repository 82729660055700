import React, { FormEventHandler, ReactNode } from 'react';

import styled from 'styled-components';

import TextInput from './forms/TextInput';
import useInputFocus from '../hooks/useInputFocus';

const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

interface IYesConfirm {
  text: ReactNode;
  isInvalid: boolean;
  confirmationInput: { data: string; touched: boolean };
  handleChange: FormEventHandler<HTMLInputElement>;
}

const YesConfirmForm = ({
  text,
  isInvalid,
  confirmationInput,
  handleChange,
}: IYesConfirm) => {
  const [ref] = useInputFocus({ initialFocus: true });

  return (
    <div>
      <Wrapper>{text}</Wrapper>
      <TextInput
        ref={ref}
        name="confirmation"
        error={isInvalid && confirmationInput.touched ? 'Invalid!' : ''}
        isTouched={confirmationInput.touched}
        value={confirmationInput.data}
        onChange={handleChange}
      />
    </div>
  );
};
export default YesConfirmForm;
