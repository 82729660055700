import React, { useState } from 'react';

import { Tabs } from 'antd';

import { IIdentity } from '../../models/lpaccount';
import LPAccountBankInfo from '../LPAccountBankInfo';
import LPAccountInvitationCodes from '../LPAccountInvitationCodes';
import LPAccountPartnershipsTable from '../LPAccountPartnershipsTable';
import LPAccountUserAddressTable from '../LPAccountUserAddressTable';
import LPAccountUserIdentitiesTable from '../LPAccountUserIdentitiesTable';
import { BoxContractByAccountTable } from '@/features/buy-n-rent';
import { ContributionsByAccountTable } from '@/features/sharing-angels';

const { TabPane } = Tabs;

interface Props {
  id: string;
  identities: IIdentity[];
  loading: boolean;
  userType?: string;
}

const DataTabs = ({ id, identities, loading, userType }: Props) => {
  const [tab, setTab] = useState('contributions');

  return (
    <>
      <Tabs type="card" activeKey={tab} onTabClick={(key, _) => setTab(key)}>
        <TabPane tab="Contributions" key="contributions" />
        <TabPane tab="Buy&Rent" key="buyAndRent" />
        <TabPane tab="Identities" key="identities" />
        <TabPane tab="Addresses" key="addresses" />
        <TabPane tab="Invitation codes" key="invitations" />
        <TabPane tab="Partnerships" key="partnerships" />
        <TabPane tab="Bank Information" key="bankInformation" />
      </Tabs>
      {tab === 'identities' && (
        <LPAccountUserIdentitiesTable
          identities={identities}
          loading={loading}
        />
      )}
      {tab === 'addresses' && <LPAccountUserAddressTable id={id} />}
      {tab === 'contributions' && <ContributionsByAccountTable id={id} />}
      {tab === 'buyAndRent' && (
        <BoxContractByAccountTable id={id} userType={userType} />
      )}
      {tab === 'invitations' && <LPAccountInvitationCodes id={id} />}
      {tab === 'partnerships' && <LPAccountPartnershipsTable id={id} />}
      {tab === 'bankInformation' && <LPAccountBankInfo id={id} />}
    </>
  );
};

export default DataTabs;
