import React, { useEffect, useState } from 'react';

import useGetTotals from '../hooks/useGetTotals';

export const ShipmentsTotalContext = React.createContext({});

const ShipmentsTotalProvider = ({ children }) => {
  const [data, setData] = useState<number>(0);
  const { getShipmentsTotal } = useGetTotals();

  useEffect(() => {
    async function asyncWrapper() {
      const total = await getShipmentsTotal();
      setData(total);
    }
    asyncWrapper();
  }, [getShipmentsTotal]);

  const { Provider } = ShipmentsTotalContext;

  return <Provider value={data}>{children}</Provider>;
};

export default ShipmentsTotalProvider;
