import { useCallback } from 'react';

import { ServiceLevel } from '../models/serviceLevel';
import { useApiClient } from '@/helpers/ApiClients';
import { useFetchResource } from '@/hooks/useFetchResource';

const useGetPartnerServiceLevel = () => {
  const device = useApiClient('device');

  const getPartnerServiceLevel = useCallback(
    (id: string) =>
      device
        .get<ServiceLevel>(`/partners/${id}/service-level`)
        .then((response) => response.data),
    [device]
  );

  return getPartnerServiceLevel;
};

const usePartnerServiceLevel = (id: string) => {
  const getPartnerServiceLevel = useGetPartnerServiceLevel();

  const fetchFunction = useCallback(
    () => getPartnerServiceLevel(id),
    [getPartnerServiceLevel, id]
  );

  return useFetchResource(fetchFunction);
};

export default usePartnerServiceLevel;
