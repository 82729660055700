import { create } from 'zustand';

export interface IServiceLevelEntry {
  id: number;
  name: string;
}

interface ServiceLevelsState {
  serviceLevels: Array<IServiceLevelEntry>;
  setServiceLevels: (input: Array<IServiceLevelEntry>) => void;
}

const useServiceLevelsStore = create<ServiceLevelsState>((set) => ({
  serviceLevels: [],
  setServiceLevels: (input) => set(() => ({ serviceLevels: input })),
}));

export default useServiceLevelsStore;
