import { IApiKey, IFormattedApiKey } from '../models/apiKey';
import datePostProcessor from '@/helpers/datePostProcessor';

interface IProcessedServices {
  count: number;
  total: number;
  items: IFormattedApiKey[];
}

const lpApiKeysPostProcessor = (data: {
  items: IApiKey[];
}): IProcessedServices | {} => {
  if (!data || !data?.items?.length) {
    return {};
  }

  return datePostProcessor({ ...data, items: data.items });
};

export default lpApiKeysPostProcessor;
