import { useCallback, useEffect, useState } from 'react';

import { DeliveryStatus } from '../models/shipment';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

export type SET_TRANSITION_KEY =
  | 'setPreTransit'
  | 'setInTransit'
  | 'setOutForDelivery'
  | 'setAvailableForPickup'
  | 'setDelivered'
  | 'setReturnToSender'
  | 'setCancelled'
  | 'setFailure'
  | 'setError'
  | 'setDestroyed';

export const TRANSITIONS: {
  [key in Exclude<DeliveryStatus, 'INVALID'>]: {
    [key in SET_TRANSITION_KEY]?: DeliveryStatus;
  };
} = {
  NONE: {
    setPreTransit: 'PRE_TRANSIT',
  },
  PRE_TRANSIT: {
    setInTransit: 'IN_TRANSIT',
    setOutForDelivery: 'OUT_FOR_DELIVERY',
    setAvailableForPickup: 'AVAILABLE_FOR_PICKUP',
    setDelivered: 'DELIVERED',
    setReturnToSender: 'RETURN_TO_SENDER',
    setCancelled: 'CANCELLED',
    setFailure: 'FAILURE',
    setError: 'ERROR',
    setDestroyed: 'DESTROYED',
  },
  IN_TRANSIT: {
    setOutForDelivery: 'OUT_FOR_DELIVERY',
    setAvailableForPickup: 'AVAILABLE_FOR_PICKUP',
    setDelivered: 'DELIVERED',
    setReturnToSender: 'RETURN_TO_SENDER',
    setCancelled: 'CANCELLED',
    setFailure: 'FAILURE',
    setError: 'ERROR',
    setDestroyed: 'DESTROYED',
  },
  OUT_FOR_DELIVERY: {
    setAvailableForPickup: 'AVAILABLE_FOR_PICKUP',
    setDelivered: 'DELIVERED',
    setReturnToSender: 'RETURN_TO_SENDER',
    setCancelled: 'CANCELLED',
    setFailure: 'FAILURE',
    setError: 'ERROR',
    setDestroyed: 'DESTROYED',
  },
  AVAILABLE_FOR_PICKUP: {
    setDelivered: 'DELIVERED',
    setReturnToSender: 'RETURN_TO_SENDER',
    setCancelled: 'CANCELLED',
    setFailure: 'FAILURE',
    setError: 'ERROR',
    setDestroyed: 'DESTROYED',
  },
  DELIVERED: {
    setCancelled: 'CANCELLED',
    setFailure: 'FAILURE',
    setError: 'ERROR',
  },
  RETURN_TO_SENDER: {
    setCancelled: 'CANCELLED',
    setFailure: 'FAILURE',
    setError: 'ERROR',
  },
  CANCELLED: {},
  FAILURE: {},
  ERROR: {},
  DESTROYED: {},
};

export const useChangeStatus: any = (id) => {
  const addError = useError();
  const [status, setStatus] = useState<DeliveryStatus>('NONE');
  const [, makeRequest] = useImperativeRequestWrapper('shipment');
  const successMsg = useToasts((state) => state.addItem);

  const updateShipmentStatus = useCallback(
    async (status) => {
      const { error } = await makeRequest({
        path: `/admin/shipments/${id}/delivery_status`,
        method: 'post',
        body: { deliveryStatus: status },
      });
      if (error) {
        addError(error);

        return { success: false };
      }
      successMsg(
        { msg: 'Status was successfully updated', type: MessageType.Success },
        'root',
        {}
      );

      return { success: true };
    },
    [makeRequest, addError, successMsg, id]
  );

  const getShipmentStatus = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: `/admin/shipments/${id}/delivery_status`,
      method: 'get',
    });
    if (error) {
      addError(error);

      return { success: false, data: {} };
    }

    return { success: true, data: data };
  }, [makeRequest, addError, id]);

  const handleSubmit = useCallback(
    async (action) => {
      const { success } = await updateShipmentStatus(
        TRANSITIONS[status][action]
      );
      if (success) {
        const { success, data } = await getShipmentStatus();
        if (success) {
          setStatus(data.deliveryStatus);
        }
      }
    },
    [status, updateShipmentStatus, getShipmentStatus]
  );

  useEffect(() => {
    getShipmentStatus().then(({ success, data }) => {
      if (success) {
        setStatus(data.deliveryStatus);
      }
    });
  }, [getShipmentStatus]);

  return [status, handleSubmit];
};

export default useChangeStatus;
