export enum SIM_STATUS {
  STATUS_UNSPECIFIED = 'STATUS_UNSPECIFIED',
  STATUS_NEW = 'STATUS_NEW',
  STATUS_READY = 'STATUS_READY',
  STATUS_ACTIVE = 'STATUS_ACTIVE',
  STATUS_INACTIVE = 'STATUS_INACTIVE',
  STATUS_SCHEDULED = 'STATUS_SCHEDULED',
}

export interface ISimStatus {
  sim: {
    account_sid: string;
    created_at: number;
    fleet_sid: string;
    iccid: string;
    sid: string;
    status: SIM_STATUS;
    unique_name: string;
    updated_at: number;
    update_history: {
      sim_updates: IHistoryVersion[];
    };
  };
}

export interface IHistoryVersion {
  created_at: number;
  status: SIM_STATUS;
  update_completed_at: number;
  updated_at: number;
  version: string;
}
