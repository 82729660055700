import { Table } from 'antd';
import styled from 'styled-components';

const StyledTable = styled(Table).attrs(() => ({
  bordered: true,
}))<any>`
  font-family: TTNormsPro-Regular;
  width: 100%;
  & .ant-table {
    font-size: 0.8rem;
  }
`;
export default StyledTable;
