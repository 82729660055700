import { create } from 'zustand';

export type TOffersSortBy =
  | 'createdAt'
  | 'updatedAt'
  | 'activeAt'
  | 'deactivatedAt'
  | 'offerType'
  | 'label';

type TOfferType = 'default' | 'pioneer' | 'gift' | 'thebag' | 'reward';

interface IOffersStore {
  rowData: any[];
  pageSize: number;
  offset: number;
  sortBy: TOffersSortBy;
  order: 'ASC' | 'DESC';
  defaultSort: {
    sortBy: TOffersSortBy;
    order: 'ASC' | 'DESC';
  };
  error: string | boolean;
  loading: boolean;
  dispatch: (args: TOffersReducerInput) => void;
  count: number;
  total: number;
  onlyActive: boolean;
  offerTypes: TOfferType[];
  showAddModal: boolean;
  newOfferId: null | string;
  defaultActiveOfferId: string | null;
}

type TOffersReducerInput =
  | {
      type: 'updateRowData';
      args: { rowData: any[] };
    }
  | {
      type: 'updateError';
      args: { error: string | boolean };
    }
  | {
      type: 'updateLoading';
      args: { loading: boolean };
    }
  | {
      type: 'updatePagination';
      args: { pageSize: number; offset: number };
    }
  | {
      type: 'updateSorting';
      args: {
        sortBy: TOffersSortBy;
        order: 'ASC' | 'DESC';
      };
    }
  | {
      type: 'updateCount';
      args: { count: number };
    }
  | {
      type: 'updateTotal';
      args: { total: number };
    }
  | {
      type: 'updateFilters';
      args: { onlyActive: boolean; offerTypes: TOfferType[] };
    }
  | {
      type: 'updateAddModal';
      args: { showAddModal: boolean };
    }
  | {
      type: 'updateDefaultSort';
    }
  | {
      type: 'updateNewOfferId';
      args: { newOfferId: string | null };
    }
  | {
      type: 'updateDefaultActiveOfferId';
      args: { defaultActiveOfferId: string };
    }
  | {
      type: 'reset';
    };

const offersReducer = (state: IOffersStore, input: TOffersReducerInput) => {
  switch (input.type) {
    case 'updateRowData':
      return { ...state, rowData: input.args.rowData };
    case 'updateError':
      return { ...state, error: input.args.error };
    case 'updateLoading':
      return { ...state, loading: input.args.loading };
    case 'updatePagination':
      return {
        ...state,
        pageSize: input.args.pageSize,
        offset: input.args.offset,
      };
    case 'updateSorting':
      return { ...state, sortBy: input.args.sortBy, order: input.args.order };
    case 'updateCount':
      return { ...state, count: input.args.count };
    case 'updateTotal':
      return { ...state, total: input.args.total };
    case 'updateFilters':
      return {
        ...state,
        onlyActive: input.args.onlyActive,
        offerTypes: input.args.offerTypes,
      };
    case 'updateAddModal':
      return { ...state, showAddModal: input.args.showAddModal };
    case 'updateNewOfferId':
      return { ...state, newOfferId: input.args.newOfferId };
    case 'updateDefaultSort':
      return {
        ...state,
        defaultSort: { sortBy: state.sortBy, order: state.order },
      };
    case 'updateDefaultActiveOfferId':
      return {
        ...state,
        defaultActiveOfferId: input.args.defaultActiveOfferId,
      };
    case 'reset':
      return INITIAL_STATE;
  }
};

const INITIAL_STATE: Omit<IOffersStore, 'dispatch'> = {
  rowData: [],
  error: '',
  loading: false,
  pageSize: 50,
  offset: 0,
  sortBy: 'updatedAt',
  order: 'DESC',
  defaultSort: {
    sortBy: 'updatedAt',
    order: 'DESC',
  },
  count: 0,
  total: 0,
  onlyActive: false,
  offerTypes: [],
  showAddModal: false,
  newOfferId: null,
  defaultActiveOfferId: null,
};

const useOffersStore = create<IOffersStore>((set) => ({
  ...INITIAL_STATE,
  dispatch: (input) => set((state) => offersReducer(state, input)),
}));

export default useOffersStore;
