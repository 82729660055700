import { useCallback } from 'react';

import * as Sentry from '@sentry/react';
import get from 'lodash/get';

import beErrorMsgParser from '../helpers/beErrorMsgParser';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

export interface IErrorOptions {
  addToast?: boolean;
  trackError?: boolean;
  parseBeErrorMsg?: boolean;
  errorParser?: (err: any) => string;
}

const DEFAULT_OPTIONS = {
  addToast: true,
  trackError: true,
  parseBeErrorMsg: true,
  errorParser: beErrorMsgParser,
};

const useError = () => {
  const addErrorMsg = useToasts((state) => state.addItem);

  const addError = useCallback(
    (
      error: any,
      {
        addToast = DEFAULT_OPTIONS.addToast,
        trackError = DEFAULT_OPTIONS.trackError,
        parseBeErrorMsg = DEFAULT_OPTIONS.parseBeErrorMsg,
        errorParser = DEFAULT_OPTIONS.errorParser,
      }: IErrorOptions = DEFAULT_OPTIONS
    ) => {
      if (addToast) {
        let errorStr;
        if (parseBeErrorMsg && errorParser) {
          errorStr = errorParser(error);
        } else {
          errorStr = error.toString();
        }
        addErrorMsg({ msg: errorStr, type: MessageType.Error }, 'root', {
          duration: 3000,
        });
      }
      if (trackError) {
        Sentry.captureException(error, {
          extra: error ? get(error, 'response.data', {}) : {},
        });
      }
    },
    [addErrorMsg]
  );

  return addError;
};

export default useError;
