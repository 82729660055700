import React, { useState } from 'react';

import FormTextInput from '@/shared/components/FormTextInput/FormTextInput';

export const nonNumberError = (field) => {
  const parsedFloat = parseFloat(field);

  return isNaN(parsedFloat);
};

interface IFloatInput {
  value: string;
  hasOtherError?: boolean;
  otherErrorMsg?: boolean;
}

const FloatInput = ({
  value,
  hasOtherError,
  otherErrorMsg,
  ...rest
}: IFloatInput & any) => {
  const [isTouched, setIsTouched] = useState(false);

  return (
    <FormTextInput
      type="number"
      onWheel={(e) => e.target.blur()}
      onFocus={() => setIsTouched(true)}
      step={1}
      errorMsg={
        otherErrorMsg || (nonNumberError(value) ? 'Must be a number!' : '')
      }
      hasError={hasOtherError || nonNumberError(value)}
      isTouched={isTouched}
      value={value}
      {...rest}
    />
  );
};

export default FloatInput;
