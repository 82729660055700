import React, { useEffect } from 'react';

import { Input, Form } from 'antd';

import useSaveAccountNote from '../../hooks/useSaveAccountNote';
import { profileSchema } from '../../schemas/lpaccountSchema';
import EditButtonsOnForm from '@/components/EditButtonsOnForm';
import useAntdForm, { getValidatorAntdRules } from '@/hooks/useAntdForm';

interface Props {
  id: string;
  getUser: () => void;
  notes: any;
}

const NotesForm = ({ id, getUser, notes }: Props) => {
  const [handleSaveNote, , handleSetNote, newNote, setNewNote] =
    useSaveAccountNote(id, getUser, notes);

  const { form, handleFieldsChanged, isFieldsTouched, resetFields } =
    useAntdForm();

  useEffect(() => {
    if (notes && notes.length) {
      setNewNote(notes[0].note);
    }

    resetFields();
  }, [notes, setNewNote, resetFields]);

  return (
    <Form
      form={form}
      name="accountNote"
      initialValues={{
        notes: (notes && notes[0]?.note) || '',
      }}
      onFieldsChange={handleFieldsChanged}
    >
      <Form.Item
        name="notes"
        {...getValidatorAntdRules(profileSchema, 'notes')}
      >
        <Input.TextArea
          data-testid="accountNote"
          value={newNote}
          onChange={handleSetNote}
          rows={6}
          name="notes"
        />
      </Form.Item>
      <EditButtonsOnForm
        handleCancel={resetFields}
        handleSaveEdit={handleSaveNote}
        disabledCancel={!isFieldsTouched}
        disabledSubmit={!isFieldsTouched}
        name="accountNote"
      />
    </Form>
  );
};

export default NotesForm;
