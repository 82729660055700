import React, { useEffect } from 'react';

import ContributionsTable from './contributionsTable/ContributionsTable';
import useLPAccountContributionsStore from '../hooks/useLPAccountContributionsStore';
import Error from '@/components/Error';
import { scopes } from '@/config';
import useAppState from '@/hooks/useAppState';

const ContributionsByAccountTable = ({ id }) => {
  const dispatch = useLPAccountContributionsStore((state) => state.dispatch);
  const userScopes = useAppState((state) => state.scopes);

  useEffect(() => () => dispatch({ type: 'reset' }), [dispatch]);

  if (!userScopes.includes(scopes.VIEW_SHARING_ANGELS)) {
    return <Error message="Sorry, you are not allowed to view this!" />;
  }

  return (
    <ContributionsTable
      store={useLPAccountContributionsStore}
      showSizeChanger={false}
      hasFilters={{ state: true }}
      endpoint={`/admin/contributions/byaccount/${id}`}
    />
  );
};

export default ContributionsByAccountTable;
