import React, { Dispatch, SetStateAction, useCallback, ReactNode } from 'react';

import styled from 'styled-components';

import TextInput from './forms/TextInput';
import useInputFocus from '../hooks/useInputFocus';

const StyledDiv = styled.div`
  margin-bottom: 1rem;
`;

interface IInputIdForm {
  placeholder: string;
  text: ReactNode;
  hasError: boolean;
  inputtedId: { data: string; touched: boolean };
  setId: Dispatch<SetStateAction<{ data: string; touched: boolean }>>;
  inputName: string;
}

const InputIdForm = ({
  placeholder,
  text,
  hasError,
  inputtedId,
  setId,
  inputName,
}: IInputIdForm) => {
  const [ref] = useInputFocus({ initialFocus: true });

  const handleChange = useCallback(
    (event) => {
      setId({
        touched: true,
        data: event.target.value,
      });
    },
    [setId]
  );

  return (
    <div data-testid={inputName}>
      <StyledDiv>{text}</StyledDiv>
      <TextInput
        ref={ref}
        placeholder={placeholder}
        name={inputName}
        error={
          hasError && inputtedId.touched ? 'The inputted ID does not match' : ''
        }
        isTouched={inputtedId.touched}
        value={inputtedId.data}
        onChange={handleChange}
        data-testid={hasError ? 'fieldError' : ''}
      />
    </div>
  );
};
export default InputIdForm;
