import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ExclamationOutlined } from '@ant-design/icons';
import { Col, Row, Modal } from 'antd';
import get from 'lodash/get';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import CancelContribution from './CancelContribution';
import ContributionForm from './ContributionForm';
import ModifyContribution from './ModifyContribution';
import ModifyPayment from './ModifyPayment/ModifyPayment';
import RewardInformation from './RewardInformation';
import useContribution from '../../hooks/saContributions/useContribution';
import useContributionStore from '../../hooks/saContributions/useContributionStore';
import useReward, {
  REWARD_STATUS,
  IrewardTransaction,
} from '../../hooks/saContributions/useReward';
import useSaveContributionNote from '../../hooks/saContributions/useSaveContributionNote';
import AddressForm from '@/components/AddressForm';
import EditButtonsOnForm from '@/components/EditButtonsOnForm';
import TextareaInput from '@/components/forms/TextareaInput';
import TextInput from '@/components/forms/TextInput';
import PillButton from '@/components/PillButton';
import useError from '@/hooks/useError';

const Wrapper = styled.div`
  margin-bottom: 3rem;
`;

const CenteredCol = styled(Col)`
  text-align: center;
`;

const SubHeader = styled.div`
  font-size: 18px;
  font-family: TTNormsPro-Bold;
  margin-bottom: 1rem;
`;

const ActionRow = styled(Row)`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.5rem;
`;

const SubHeadWrapper = styled(Row)`
  margin-top: 2rem;
`;

const RowWrapper = styled(Row)`
  display: flex;
  align-items: flex-end;
`;

const WrapperButtons = styled.div`
  margin-bottom: 2rem;
  margin-left: 1rem;
  display: flex;
  justify-content: flex-start;
`;

const SAContributionDetailContent = () => {
  const { id } = useParams<{ id: string }>();
  const history = useNavigate();
  const addError = useError();
  const { getContribution } = useContribution(id!);
  const contribution = useContributionStore((state) => state.contribution);
  const addNote = useSaveContributionNote();
  const [newNote, setNewNote] = useState<string>();
  const [isRewardValidated, setIsRewardValidated] = useState<boolean>(true);
  const [rewardTransaction, setRewardTransaction] =
    useState<IrewardTransaction>({
      contribution_id: '',
      reward_estimated_date: '',
      reward_status: REWARD_STATUS.REWARD_STATUS_UNSPECIFIED,
      transaction_amount: 0,
      transaction_at: '',
      transaction_id: '',
      transaction_receipt_id: '',
    });

  const { getContributionRewardTransaction, updateContributionRewardStatus } =
    useReward();

  const {
    accountId,
    parsedOffer,
    paymentMethod,
    paymentStatus,
    address,
    notes,
  } = contribution;

  const { confirm } = Modal;

  useEffect(() => {
    const getReward = async () => {
      const { data, isValaible } = await getContributionRewardTransaction();

      setRewardTransaction(data);
      setIsRewardValidated(isValaible);
    };
    setNewNote(notes);
    if (contribution.id) {
      getReward();
    }
  }, [
    notes,
    contribution.paymentDate,
    getContributionRewardTransaction,
    contribution.id,
  ]);

  const showModifyButton = useMemo(
    () =>
      (paymentMethod === 'WIRE' && paymentStatus === 'pending') ||
      paymentStatus === 'refund_requested' ||
      paymentStatus === 'completed' ||
      paymentStatus === 'refund_initiated',
    [paymentMethod, paymentStatus]
  );

  const handleOfferLink = useCallback(
    () =>
      get(parsedOffer, 'id', false)
        ? history(`/sharing-angels/offers/${get(parsedOffer, 'id', '')}`)
        : addError(new Error('Unable to find Offer')),
    [parsedOffer, history, addError]
  );

  const handleAccountLink = useCallback(
    () =>
      !!accountId
        ? history(`/lp-account/${accountId}`)
        : addError(new Error('Unable to find Account')),
    [accountId, history, addError]
  );

  const handleSetNote = useCallback((e) => {
    setNewNote(e.target.value);
  }, []);

  const handleSaveNote = useCallback(async () => {
    await addNote(newNote);
    await getContribution();
    setNewNote(undefined);
  }, [addNote, newNote, getContribution]);

  const handleCancelNote = useCallback(() => {
    setNewNote(notes);
  }, [notes]);

  const noteHasBeenModified = useMemo(
    () => newNote !== notes,
    [newNote, notes]
  );

  const handleActiveReward = useCallback(async () => {
    const { success, data } = await updateContributionRewardStatus(accountId);

    if (success && data) {
      setRewardTransaction((prev: any) => ({
        ...prev,
        ...{
          reward_status: data.reward_status,
        },
      }));
    }
  }, [updateContributionRewardStatus, accountId]);

  const showConfirmRewardActive = useCallback(() => {
    confirm({
      okText: 'Yes',
      title: 'Confirm active reward',
      icon: <ExclamationOutlined />,
      content: 'Are you sur you want to active reward?',
      onOk() {
        handleActiveReward();
      },
      onCancel() {},
    });
  }, [handleActiveReward, confirm]);

  return (
    <Wrapper>
      <ActionRow gutter={24}>
        <Col>{showModifyButton && <ModifyPayment />}</Col>
        <Col>
          <ModifyContribution contribution={contribution} id={id} />
        </Col>
        <Col>
          <CancelContribution getContribution={getContribution} />
        </Col>
      </ActionRow>
      <ContributionForm contribution={contribution} id={id} />
      <RowWrapper>
        <Col span={8}>
          <TextareaInput
            data-testid="contributionNote"
            name="note"
            value={newNote}
            onChange={handleSetNote}
            label="Add a note"
          />
        </Col>
        <Col span={5}>
          <WrapperButtons>
            <EditButtonsOnForm
              disabledCancel={!noteHasBeenModified}
              disabledSubmit={!noteHasBeenModified || !newNote}
              handleSaveEdit={handleSaveNote}
              handleCancel={handleCancelNote}
              name="contributionNote"
            />
          </WrapperButtons>
        </Col>
      </RowWrapper>
      <SubHeadWrapper>
        <SubHeader>CONTRIBUTOR</SubHeader>
      </SubHeadWrapper>
      <Row gutter={24}>
        <Col span={18}>
          <TextInput
            label="Account-ID"
            name="accountId"
            disabled
            value={accountId}
          />
        </Col>
        <CenteredCol span={6}>
          <PillButton onClick={handleAccountLink} mw="10rem" size="large">
            To LP-Account
          </PillButton>
        </CenteredCol>
      </Row>

      <SubHeadWrapper>
        <SubHeader>OFFER</SubHeader>
      </SubHeadWrapper>
      <Row gutter={24}>
        <Col span={9}>
          <TextInput
            label="Offer-ID"
            name="offerId"
            disabled
            value={get(parsedOffer, 'id', '')}
          />
        </Col>
        <Col span={9}>
          <TextInput
            label="Offer Label"
            name="label"
            disabled
            value={get(parsedOffer, 'label', '')}
          />
        </Col>
        <CenteredCol span={6}>
          <PillButton onClick={handleOfferLink} mw="10rem" size="large">
            To Offer
          </PillButton>
        </CenteredCol>
      </Row>
      <Row gutter={24}>
        <Col span={9}>
          <TextInput
            label="Offer Multiplier"
            name="multiplier"
            disabled
            value={get(parsedOffer, 'parsedMultiplier', '')}
          />
        </Col>
        <Col span={9}>
          <TextInput
            label="Offer Type"
            name="offerType"
            disabled
            value={get(parsedOffer, 'offerType', '')}
          />
        </Col>
      </Row>
      <RewardInformation
        isRewardValidated={isRewardValidated}
        rewardTransaction={rewardTransaction}
        showConfirmRewardActive={showConfirmRewardActive}
      />
      {address && (
        <>
          <SubHeadWrapper>
            <SubHeader>CONTRACT ADDRESS</SubHeader>
          </SubHeadWrapper>
          <AddressForm address={address} disabled={true} />
        </>
      )}
    </Wrapper>
  );
};

export default SAContributionDetailContent;
