import { useEffect, useRef } from 'react';

const useInputFocus: any = ({ initialFocus }) => {
  const ref = useRef<HTMLInputElement>();
  const focus = ref.current?.focus;

  useEffect(() => {
    if (initialFocus) {
      ref.current?.focus();
    }
  }, [initialFocus]);

  return [ref, focus];
};
export default useInputFocus;
