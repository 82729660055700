import React, { useCallback } from 'react';

import ServiceLevelForm from '../components/ServiceLevelForm';
import useEditServiceLevel from '../hooks/useEditServiceLevel';

interface EditServiceLevelProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  initialServiceLevel: any;
  fetchServiceLevels: () => void;
}

const EditServiceLevelModal = ({
  initialServiceLevel,
  visible,
  setVisible,
  fetchServiceLevels,
}: EditServiceLevelProps) => {
  const { loading, editServiceLevel, setLoading } = useEditServiceLevel();

  const onSubmit = useCallback(
    (body) => editServiceLevel({ ...body, id: initialServiceLevel.id }),
    [editServiceLevel, initialServiceLevel]
  );

  return (
    <ServiceLevelForm
      onSubmit={onSubmit}
      loading={loading}
      setLoading={setLoading}
      initialServiceLevel={initialServiceLevel}
      visible={visible}
      setVisible={setVisible}
      fetchServiceLevels={fetchServiceLevels}
      isSameConfigInvalid={false}
      title="Edit Service Level"
    />
  );
};

export default EditServiceLevelModal;
