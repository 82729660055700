import { useCallback } from 'react';

import useApiKeyStore from './useApiKeyStore';
import lpApiKeysPostProcessor from '../helpers/lpApiKeysPostProcessor';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useGetApiKeys = () => {
  const dispatch = useApiKeyStore((state) => state.dispatch);
  const order = useApiKeyStore((state) => state.order);
  const sortBy = useApiKeyStore((state) => state.sortBy);
  const offset = useApiKeyStore((state) => state.offset);
  const pageSize = useApiKeyStore((state) => state.pageSize);

  const addError = useError();
  const [, makeRequest] = useImperativeRequestWrapper('lpVillage');

  const getApiKeys = useCallback(
    async (partnerId: string) => {
      const { data, error } = await getPaginatedTableData({
        params: {
          path: `/admin/partners/${partnerId}/apikeys`,
          method: 'get',
        },
        reducer: lpApiKeysPostProcessor,
        dispatch,
        addError,
        makeRequest,
        queryParams: {
          pageSize: pageSize,
          offset: offset,
          sortBy: sortBy,
          order: order,
        },
        queryParamsOptions: { skipEmptyString: true, skipNull: true },
      });

      return { data, error };
    },
    [addError, dispatch, makeRequest, order, sortBy, pageSize, offset]
  );

  return getApiKeys;
};

export default useGetApiKeys;
