import React, { useCallback } from 'react';

import { Radio } from 'antd';
import styled from 'styled-components';

import useGetShipmentQuotes from '../hooks/useGetShipmentQuotes';
import { IModifiedShipment, IQuote, IServiceLevel } from '../models/shipment';
import Error from '@/components/Error';

const Wrapper = styled.div`
  min-height: 10rem;
`;

const RadioWrapper = styled.div`
  margin: 1rem 5rem;
`;

const StyledRadio = styled(Radio)`
  display: block;
  height: 2rem;
  line-height: 2rem;
`;

const getQuoteText = (quote: IQuote) => {
  const { currency, priceInCents, carrierService, carrierCode } = quote;
  const parsedPrice = (priceInCents / 100).toFixed(2);

  return `${carrierCode} ${carrierService} | Price: ${currency} ${parsedPrice}`;
};

const getServiceLevelText = (sl: IServiceLevel) => {
  const { currency, priceInCents, serviceLevel } = sl;
  const parsedPrice = (priceInCents / 100).toFixed(2);

  return `Service level: ${serviceLevel} | Price: ${currency} ${parsedPrice}`;
};

const BuyQuoteForm = ({
  setQuoteId,
  shipment,
  setServiceLevelId,
}: {
  setQuoteId: (quoteId: string) => void;
  setServiceLevelId: (serviceLevelId: string) => void;
  shipment: IModifiedShipment;
}) => {
  const { quotes, quoteServiceLevels, loading } =
    useGetShipmentQuotes(shipment);

  const handleChange = useCallback(
    (e) => {
      setQuoteId(e.target.value);
    },
    [setQuoteId]
  );

  const handleChangeServiceLevel = useCallback(
    (e) => {
      setServiceLevelId(e.target.value);
    },
    [setServiceLevelId]
  );

  if ((!quotes || quotes.length === 0) && !loading) {
    return (
      <Wrapper>
        <Error message="No quotes found!" />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      Please select a quote from the list below:
      <RadioWrapper>
        <Radio.Group>
          {quotes.map(
            (quote) =>
              quote.id && (
                <StyledRadio
                  value={quote.id}
                  key={quote.id}
                  onChange={handleChange}
                >
                  {getQuoteText(quote)}
                </StyledRadio>
              )
          )}
        </Radio.Group>
      </RadioWrapper>
      {shipment.shipmentType === 'PARTNER' && (
        <>
          Please select a service level from the list below:
          <RadioWrapper>
            <Radio.Group>
              {quoteServiceLevels.map(
                (sl) =>
                  sl.id && (
                    <StyledRadio
                      value={sl.id}
                      key={sl.id}
                      onChange={handleChangeServiceLevel}
                    >
                      {getServiceLevelText(sl)}
                    </StyledRadio>
                  )
              )}
            </Radio.Group>
          </RadioWrapper>
        </>
      )}
    </Wrapper>
  );
};

export default BuyQuoteForm;
