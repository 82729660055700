import { useCallback } from 'react';

import queryString from 'query-string';

import useTosStore from './useTosStore';
import tosPostProcessor, {
  singleTosPostProcessor,
} from '../helpers/tosPostProcessor';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useGetTos = () => {
  const dispatch = useTosStore((state) => state.dispatch);
  const filters = useTosStore((state) => state.filters);

  const [, makeRequest] = useImperativeRequestWrapper('tos');
  const [, makeRequestSingle] = useImperativeRequestWrapper('tos');
  const addError = useError();

  const queryParams = queryString.stringify(
    { serviceType: filters.serviceType || null },
    {
      skipNull: true,
    }
  );

  const getTos = useCallback(() => {
    makeRequest({
      path: `/admin/tos/history${queryParams ? `?${queryParams}` : ''}`,
      method: 'get',
    }).then(({ data, error }) => {
      if (error) {
        addError(error);
      }
      try {
        const processedData = tosPostProcessor(data);
        dispatch({
          type: 'updateRowData',
          args: {
            rowData: processedData,
          },
        });
      } catch (e) {
        addError(e);
      }
    });
  }, [addError, dispatch, makeRequest, queryParams]);

  const getSingleTos = useCallback(
    async (id: string) => {
      const { data, error } = await makeRequestSingle({
        path: `/tos/${id}`,
        method: 'get',
      });
      if (error) {
        addError(error);

        return { success: false };
      }
      const processedData = singleTosPostProcessor(data);

      return { success: true, data: processedData };
    },
    [addError, makeRequestSingle]
  );

  return { getTos, getSingleTos };
};

export default useGetTos;
