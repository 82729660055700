import React from 'react';

import styled from 'styled-components';

import P from './text/P';

const TitleCopyWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const TitleCopy = ({ children }) => (
  <TitleCopyWrapper>
    <P>{children}</P>
  </TitleCopyWrapper>
);

export default TitleCopy;
