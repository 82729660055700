import React from 'react';

import { IdcardOutlined } from '@ant-design/icons';
import { styled, Text, Stack, Grid } from '@lp/ds-next';
import { Paper } from '@mui/material';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

import { IPartnerV3 } from '../models/partnerV3';
import Avatar from '@/components/Avatar';
import { formatStringToDate } from '@/helpers/dates';
import { isUUID } from '@/helpers/uuid';

const PartnerInfoWrapper = styled(Paper)`
  padding: 1rem;
  border-radius: 10px;
  div {
    p {
      height: 30px;
      align-item: center;
      padding: 3px 6px;
    }
  }
`;

const PieceOfInformation = ({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode;
}) => (
  <Stack
    direction={{ mobile: 'column', tablet: 'row' }}
    justifyContent="space-between"
    alignItems={{ mobile: 'flex-start', tablet: 'center' }}
    spacing={{ mobile: 0, tablet: 2 }}
  >
    <Text variant="titleS">{label} :</Text>
    <span>{value}</span>
  </Stack>
);

const AvatarInfosCard = ({ partnerData }: { partnerData: IPartnerV3 }) => {
  const { thumbnail, id, created_at, updated_at, created_by } = partnerData;

  return (
    <PartnerInfoWrapper elevation={0}>
      <Grid container gap={2}>
        <Grid item>
          <Avatar height="5rem" width="5rem" src={thumbnail} />
        </Grid>
        <Grid item>
          <Stack direction="column" spacing={0.5}>
            <PieceOfInformation
              label="Partner-ID"
              value={
                <Typography.Text copyable={{ text: id }}>{id}</Typography.Text>
              }
            />
            <PieceOfInformation
              label="Created by"
              value={
                <>
                  {created_by}{' '}
                  {isUUID(created_by) && (
                    <Link to={`/lp-account/${created_by}`}>
                      <IdcardOutlined />
                    </Link>
                  )}
                </>
              }
            />
            <PieceOfInformation
              label="Created at"
              value={formatStringToDate(created_at)}
            />
            <PieceOfInformation
              label="Updated at"
              value={formatStringToDate(updated_at)}
            />
          </Stack>
        </Grid>
      </Grid>
    </PartnerInfoWrapper>
  );
};

export default AvatarInfosCard;
