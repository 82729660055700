import React, { useCallback, useMemo, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';

import ScopeForm from './ScopeForm';
import { INITIAL_CreateScope } from '../../roles/models/role';
import useCreateScope from '../hooks/useCreateScope';
import CancelSubmitFooterModal from '@/components/CancelSubmitFooterModal';
import CustomModal from '@/components/CustomModal';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';

const CreateScope = () => {
  const [{ loading }, createScope] = useCreateScope();
  const [visible, setVisible] = useState(false);
  const [scope, setScope] = useState(INITIAL_CreateScope);
  const openModal = useCallback(() => setVisible(true), []);
  const closeModal = useCallback(() => setVisible(false), []);

  const handleSubmit = useCallback(async () => {
    const { success } = await createScope(scope);
    if (success) {
      setScope(INITIAL_CreateScope);
      closeModal();
    }
  }, [setScope, closeModal, scope, createScope]);

  const isInvalid = useMemo(() => !scope.name || !scope.description, [scope]);

  const modalFooterButtons = useMemo(
    () => CancelSubmitFooterModal(isInvalid, handleSubmit, closeModal, loading),
    [closeModal, handleSubmit, isInvalid, loading]
  );

  return (
    <div>
      <ScopedCta
        component={PillButton}
        icon={<PlusOutlined />}
        onClick={openModal}
        requiredScopes={[scopes.EDIT_LPVILLAGE_SCOPES]}
        data-testid="setScopeButton"
      >
        Create Scope
      </ScopedCta>

      <CustomModal
        title="Add a new scope"
        open={visible}
        destroyOnClose={true}
        footer={modalFooterButtons}
        onCancel={closeModal}
      >
        <ScopeForm scope={scope} setScope={setScope} />
      </CustomModal>
    </div>
  );
};

export default CreateScope;
