import { create } from 'zustand';

import {
  IModifiedLPAccountUser,
  INITIAL_UserWithRoles,
} from '../models/lpaccount';

export type TLPAccountSortBy =
  | 'firstName'
  | 'lastName'
  | 'createdAt'
  | 'updatedAt';

interface ILPUsersStore {
  user: IModifiedLPAccountUser;
  rowData: IModifiedLPAccountUser[];
  pageSize: number;
  offset: number;
  sortBy: TLPAccountSortBy;
  order: 'ASC' | 'DESC';
  defaultSort: {
    sortBy: TLPAccountSortBy;
    order: 'ASC' | 'DESC';
  };
  error: string | boolean;
  loading: boolean;
  count: number;
  searchTerms: {
    [key: string]: string;
  };
  dispatch: (args: TLPUsersReducerInput) => void;
  total: number;
  viewModalAccount: null | IModifiedLPAccountUser;
  filters: { [key: string]: any };
}

type TLPUsersReducerInput =
  | {
      type: 'updateRowData';
      args: { rowData: any[] };
    }
  | {
      type: 'updateError';
      args: { error: string | boolean };
    }
  | {
      type: 'updateLoading';
      args: { loading: boolean };
    }
  | {
      type: 'updatePagination';
      args: { pageSize: number; offset: number };
    }
  | {
      type: 'updateSorting';
      args: {
        sortBy: 'firstName' | 'lastName' | 'createdAt' | 'updatedAt';
        order: 'ASC' | 'DESC';
      };
    }
  | {
      type: 'updateCount';
      args: { count: number };
    }
  | {
      type: 'updateTotal';
      args: { total: number };
    }
  | {
      type: 'updateDefaultSort';
    }
  | {
      type: 'updateSearchTerms';
      args: { [key: string]: string };
    }
  | {
      type: 'updateFilters';
      args: {
        filters: {
          [key: string]: any;
        };
      };
    }
  | {
      type: 'updateViewModal';
      args: { viewModalAccount: null | IModifiedLPAccountUser };
    }
  | {
      type: 'reset';
    };

const lpUsersReducer = (state: ILPUsersStore, input: TLPUsersReducerInput) => {
  switch (input.type) {
    case 'updateRowData':
      return { ...state, rowData: input.args.rowData };
    case 'updateError':
      return { ...state, error: input.args.error };
    case 'updateLoading':
      return { ...state, loading: input.args.loading };
    case 'updatePagination':
      return {
        ...state,
        pageSize: input.args.pageSize,
        offset: input.args.offset,
      };
    case 'updateSorting':
      return { ...state, sortBy: input.args.sortBy, order: input.args.order };
    case 'updateCount':
      return { ...state, count: input.args.count };
    case 'updateTotal':
      return { ...state, total: input.args.total };
    case 'updateDefaultSort':
      return {
        ...state,
        defaultSort: { sortBy: state.sortBy, order: state.order },
      };
    case 'updateFilters':
      return { ...state, filters: input.args.filters };
    case 'updateSearchTerms':
      return { ...state, searchTerms: input.args, offset: 0 };
    case 'updateViewModal':
      return {
        ...state,
        viewModalAccount: input.args.viewModalAccount,
      };
    case 'reset':
      return INITIAL_STATE;
  }
};

const INITIAL_STATE: Omit<ILPUsersStore, 'dispatch'> = {
  user: INITIAL_UserWithRoles,
  rowData: [],
  error: '',
  loading: false,
  pageSize: 50,
  offset: 0,
  sortBy: 'updatedAt',
  order: 'DESC',
  defaultSort: {
    sortBy: 'updatedAt',
    order: 'DESC',
  },
  count: 0,
  total: 0,
  searchTerms: {
    firstName: '',
    lastName: '',
    email: '',
  },
  filters: {
    accountVerified: [],
  },
  viewModalAccount: null,
};

const useLPUsersStore = create<ILPUsersStore>((set) => ({
  ...INITIAL_STATE,
  dispatch: (input) => set((state) => lpUsersReducer(state, input)),
}));

export default useLPUsersStore;
