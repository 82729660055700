import * as React from 'react';

function PaidIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M6.98023 9.30511H19.2394C19.6268 9.30511 19.9404 8.99148 19.9404 8.60406V2.10089C19.9404 1.71347 19.6268 1.39984 19.2394 1.39984H6.98023C6.5928 1.39984 6.27917 1.71347 6.27917 2.10089V8.59484C6.27917 8.98226 6.5928 9.29589 6.98023 9.29589V9.30511ZM7.3123 2.19314H18.9073C19.0457 2.19314 19.1564 2.30383 19.1564 2.44219V8.27198C19.1564 8.41035 19.0457 8.52104 18.9073 8.52104H7.3123C7.17394 8.52104 7.06324 8.41035 7.06324 8.27198V2.43297C7.06324 2.2946 7.17394 2.18391 7.3123 2.18391V2.19314Z"
          fill="#555555"
        />
        <path
          d="M6.6759 10.1353H18.1971C18.5845 10.1353 18.8982 10.0338 18.8982 9.64641V9.56339H6.92496C6.43607 9.56339 6.0302 9.16674 6.0302 8.66863V2.45142H5.94718C5.55976 2.45142 5.48596 2.76504 5.48596 3.15247V8.95458C5.48596 9.60951 6.02097 10.1445 6.6759 10.1445V10.1353Z"
          fill="#555555"
        />
        <path
          d="M6.41755 10.9102H17.4499C17.8373 10.9102 18.1509 10.8087 18.1509 10.4213V10.3382H6.44522C5.79952 10.3382 5.27373 9.81245 5.27373 9.16675V3.21704H5.19071C4.80329 3.21704 4.72949 3.53067 4.72949 3.91809V9.22209C4.72949 10.1538 5.48589 10.9102 6.41755 10.9102Z"
          fill="#555555"
        />
        <path
          d="M7.17395 2.42377V3.62293L8.41924 2.2854H7.31232C7.23852 2.2854 7.17395 2.34997 7.17395 2.42377Z"
          fill="#555555"
        />
        <path
          d="M18.8982 2.25769H17.7913L19.0365 3.59522V2.39606C19.0365 2.32226 18.972 2.25769 18.8982 2.25769Z"
          fill="#555555"
        />
        <path
          d="M7.17395 8.26276C7.17395 8.33656 7.23852 8.40113 7.31232 8.40113H8.41924L7.17395 7.0636V8.26276Z"
          fill="#555555"
        />
        <path
          d="M18.8982 8.40113C18.972 8.40113 19.0365 8.33656 19.0365 8.26276V7.0636L17.7913 8.40113H18.8982Z"
          fill="#555555"
        />
        <path
          d="M13.3728 6.5839C12.6902 6.5839 12.4411 5.98432 12.4411 5.98432H13.4189V5.53232H12.3028C12.3028 5.53232 12.2843 5.45853 12.2843 5.35706C12.2843 5.25559 12.3028 5.1818 12.3028 5.1818H13.4189V4.72981H12.4411C12.4411 4.72981 12.6902 4.13022 13.3728 4.13022C13.917 4.13022 14.2306 4.53609 14.2306 4.53609L14.6365 4.11177C14.6365 4.11177 14.2306 3.53064 13.382 3.53064C12.1275 3.53064 11.7493 4.73903 11.7493 4.73903H11.2235V5.19102H11.6663C11.6663 5.19102 11.6478 5.26482 11.6478 5.36629C11.6478 5.46775 11.6663 5.54155 11.6663 5.54155H11.2235V5.99354H11.7493C11.7493 5.99354 12.1275 7.20193 13.382 7.20193C14.2399 7.20193 14.6642 6.6208 14.6642 6.6208L14.2583 6.19648C14.2583 6.19648 13.9262 6.60235 13.382 6.60235L13.3728 6.5839Z"
          fill="#555555"
        />
        <path
          d="M20.59 11.88C20.39 11.77 19.83 11.59 19.08 12.26C17.94 13.28 16.81 13.87 16.18 14.15C16.04 13.89 15.77 13.72 15.42 13.68C15.19 13.65 14.95 13.68 14.66 13.69C13.98 13.74 13.13 13.81 12.1 13.23C10.3 12.21 8.67003 13.38 8.66003 13.39L5.96003 15.07C5.90003 15 5.83003 14.96 5.74003 14.93C5.63003 14.9 5.51003 14.91 5.41003 14.97L3.22003 16.22C3.01003 16.34 2.94003 16.6 3.05003 16.81L5.87003 21.82C5.93003 21.92 6.02003 22 6.13003 22.03C6.17003 22.04 6.21003 22.05 6.25003 22.05C6.33003 22.05 6.40003 22.03 6.47003 21.99L8.66003 20.74C8.87003 20.62 8.94003 20.36 8.83003 20.15L8.75003 20.02C9.41003 19.55 10.94 18.6 12.56 18.51C17.52 18.25 20.95 13.81 21.06 13.01C21.13 12.51 20.95 12.08 20.59 11.89V11.88ZM15.41 14.6C15.41 14.6 15.4 14.64 15.39 14.64C15.36 14.71 15.29 14.85 15.09 15.01C14.51 15.49 12.54 15.32 11.8 14.96C11.7 14.91 11.58 14.9 11.47 14.94C11.36 14.98 11.27 15.06 11.22 15.16C11.17 15.26 11.16 15.38 11.2 15.49C11.24 15.6 11.32 15.69 11.42 15.74C12.34 16.2 14.7 16.48 15.66 15.69C15.86 15.52 16.03 15.33 16.14 15.13C16.63 14.94 18.13 14.3 19.68 12.92C19.98 12.65 20.16 12.65 20.19 12.65C20.2 12.66 20.23 12.73 20.22 12.86V12.88C20.17 13.14 17.22 17.4 12.53 17.64C10.73 17.73 9.07003 18.74 8.34003 19.25L6.42003 15.84L9.18003 14.13C9.23003 14.09 10.41 13.28 11.7 14.01C12.96 14.72 14.05 14.64 14.76 14.58C14.99 14.56 15.2 14.54 15.34 14.56C15.4 14.56 15.44 14.58 15.44 14.57C15.44 14.57 15.44 14.6 15.44 14.61L15.41 14.6ZM7.86003 20.19L6.43003 21.01L4.04003 16.77L5.47003 15.95L7.86003 20.19Z"
          fill="#555555"
        />
      </g>
      <defs>
        <clipPath id="clip0_810_18957">
          <rect
            width="18.08"
            height="21.05"
            fill="white"
            transform="translate(3 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PaidIcon;
