import { useCallback } from 'react';

import { useGetBoxContract } from './useGetBoxContract';
import { BoxContract } from '../models/boxContract';
import { UseFetchResource, useFetchResource } from '@/hooks/useFetchResource';

export function useBoxContract(id: string): UseFetchResource<BoxContract> {
  const getBoxContract = useGetBoxContract();

  const fetchFunction = useCallback(
    () => getBoxContract(id),
    [getBoxContract, id]
  );

  return useFetchResource(fetchFunction);
}
