import { useCallback } from 'react';

import { boxConfigHistoryPostProcessor } from '../helpers/boxConfigHistoryPostProcessor';
import { BoxConfigHistoryItem } from '../models/boxConfig';
import { useApiClient } from '@/helpers/ApiClients';
import { PaginationParams } from '@/hooks/usePagination';

export interface GetBoxConfigHistoryParams extends Partial<PaginationParams> {}

export interface PaginatedResponse<Type> {
  items: Type[];
  count: number;
  total: number;
}

export type GetBoxConfigHistory = (
  boxId: string,
  params?: GetBoxConfigHistoryParams
) => Promise<PaginatedResponse<BoxConfigHistoryItem>>;

const useGetBoxConfigHistory = (): GetBoxConfigHistory => {
  const device = useApiClient('device');

  const getBoxConfigHistory = useCallback(
    (
      boxId: string,
      {
        page = 1,
        pageSize = 100,
        orderColumn = 'createdAt',
        orderDirection = 'DESC',
      }: GetBoxConfigHistoryParams = {}
    ) =>
      device
        .get<PaginatedResponse<BoxConfigHistoryItem>>(
          `/boxes/${boxId}/configuration/history`,
          {
            params: {
              offset: (page - 1) * pageSize,
              pageSize,
              sortBy: orderColumn,
              order: orderDirection,
            },
          }
        )
        .then((response) => boxConfigHistoryPostProcessor(response.data)),
    [device]
  );

  return getBoxConfigHistory;
};

export default useGetBoxConfigHistory;
