import React from 'react';

import styled from 'styled-components';

import Dropdown from '@/components/forms/Dropdown';
import Label from '@/components/forms/Label';

const LabelWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

const TYPES = [
  { name: 'registration' },
  { name: 'billing' },
  { name: 'sender' },
];

const LPVillageAddressTypeSelector = ({
  label,
  onChange,
  value,
  disabled = false,
}: {
  label: string;
  onChange: any;
  value?: string;
  disabled?: boolean;
}) => (
  <>
    <LabelWrapper>
      <Label>{label}</Label>
    </LabelWrapper>
    <Dropdown
      isDisabled={disabled}
      options={TYPES}
      getOptionLabel={(option) => option.name}
      mw="35rem"
      onChange={onChange}
      value={{ name: value }}
    />
  </>
);

export default LPVillageAddressTypeSelector;
