import React, { useCallback } from 'react';

import { Col, Row } from 'antd';

import PartnerShopTypeSelector from '../../components/PartnerShopTypeSelector';
import { TPartnerShopType } from '../../services/models/service';
import { ICreateRole } from '../models/role';
import TextareaInput from '@/components/forms/TextareaInput';
import TextInput from '@/components/forms/TextInput';
import useInputFocus from '@/hooks/useInputFocus';

const RoleForm = ({
  role,
  setRole,
}: {
  role: ICreateRole;
  setRole?: (res: any) => void;
}) => {
  const [ref] = useInputFocus({ initialFocus: true });
  const { name, description } = role;

  const handleTextChange = useCallback(
    (e) => {
      if (setRole) {
        setRole({ ...role, [e.target.name]: e.target.value });
      }
    },
    [setRole, role]
  );

  const handleDropDownChange = useCallback(
    (e) => {
      if (setRole) {
        setRole({ ...role, type: e.name });
      }
    },
    [setRole, role]
  );

  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            ref={ref}
            onChange={handleTextChange}
            label="Name *"
            name="name"
            value={name}
          />
        </Col>
        <Col span={12}>
          <TextareaInput
            onChange={handleTextChange}
            label="Description *"
            name="description"
            value={description}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <PartnerShopTypeSelector
            onChange={handleDropDownChange}
            value={{ name: role?.type as TPartnerShopType }}
          />
        </Col>
      </Row>
    </>
  );
};
export default RoleForm;
