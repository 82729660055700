import React, { useEffect } from 'react';

import { Route, Routes } from 'react-router-dom';

import SAOfferDetails from '../components/saOfferDetails/SAOfferDetails';
import SAOffersTable from '../components/saOffersTable/SAOffersTable';
import useOffersStore from '../hooks/useOffersStore';
import { appRoutes } from '@/configs/appRoutes';

const SAOffers = () => {
  const dispatch = useOffersStore((state) => state.dispatch);

  useEffect(() => () => dispatch({ type: 'reset' }), [dispatch]);

  return (
    <Routes>
      <Route path={appRoutes.ROOT} element={<SAOffersTable />} />
      <Route path=":id" element={<SAOfferDetails />} />
    </Routes>
  );
};

export default SAOffers;
