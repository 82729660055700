import React, { useState } from 'react';

import { Button, Tooltip } from 'antd';
import get from 'lodash/get';
import styled from 'styled-components';

import useLPAddressStore from '../hooks/useLPAddressStore';
import AddressForm from '@/components/AddressForm';
import CustomModal from '@/components/CustomModal';
import addressesPostProcessor from '@/helpers/addressesPostProcessor';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import {
  INITIAL_CreateLPAccountAddress,
  INITIAL_LPAccountAddress,
} from '@/models/address';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const ButtonWrapper = styled.span`
  margin-left: 8px;
`;

const AddAddressModal = ({ accountId }) => {
  const showAddModal = useLPAddressStore((state) => state.showAddModal);
  const dispatch = useLPAddressStore((state) => state.dispatch);
  const pageSize = useLPAddressStore((state) => state.pageSize);
  const offset = useLPAddressStore((state) => state.offset);
  const sortBy = useLPAddressStore((state) => state.sortBy);
  const order = useLPAddressStore((state) => state.order);
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);

  const [address, setAddress] = useState(INITIAL_CreateLPAccountAddress);

  const isInvalid =
    !address.firstName ||
    !address.lastName ||
    !address.street ||
    !address.postalCode ||
    !address.city ||
    !address.countryCode;

  const handleSubmit = () => {
    makeRequest({
      path: '/admin/addresses',
      method: 'post',
      body: { ...address, accountId: accountId },
    }).then(({ data, error }) => {
      if (error) {
        addError(error);

        return;
      }
      dispatch({
        type: 'updateNewAddressId',
        args: { newAddressId: get(data, 'id', null) },
      });
      closeModal();
      addSuccessMsg(
        { msg: 'Address was successfully added!', type: MessageType.Success },
        'root',
        {}
      );
      getPaginatedTableData({
        params: {
          path: `/admin/addresses/byaccount/${accountId}`,
          method: 'get',
        },
        dispatch,
        queryParams: { pageSize, offset, sortBy, order },
        reducer: addressesPostProcessor,
        addError,
        makeRequest,
      });
      setTimeout(function () {
        dispatch({
          type: 'updateNewAddressId',
          args: { newAddressId: null },
        });
      }, 2000);
    });
  };

  const [{ loading }, makeRequest] =
    useImperativeRequestWrapper('serviceLpAccount');

  const closeModal = () => {
    dispatch({ type: 'updateAddModal', args: { showAddModal: false } });
    setAddress(INITIAL_LPAccountAddress);
  };

  return (
    <CustomModal
      destroyOnClose={true}
      open={showAddModal}
      title="Add Address (*=required)"
      onCancel={closeModal}
      onOk={handleSubmit}
      footer={[
        <Button key="Cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <>
          {isInvalid ? (
            <ButtonWrapper>
              <Tooltip
                placement="topRight"
                title="Please fill all required fields"
              >
                <Button key="submit" type="primary" disabled={true}>
                  Submit
                </Button>
              </Tooltip>
            </ButtonWrapper>
          ) : (
            <Button type="primary" loading={loading} onClick={handleSubmit}>
              Submit
            </Button>
          )}
        </>,
      ]}
      width="80%"
    >
      <AddressForm address={address} setAddress={setAddress} />
    </CustomModal>
  );
};

export default AddAddressModal;
