import React from 'react';

import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Text } from '@lp/ds-next';
import { Radio, RadioChangeEvent, Tooltip } from 'antd';
import styled from 'styled-components';

import {
  PARTNER_BUSINESS_STATUS,
  PARTNER_BUSINESS_TYPE,
} from '../models/partnerV3';
import Label from '@/components/forms/Label';

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  padding-bottom: 16px;
  justify-content: space-between;
  width: 100%;
  > div {
    margin-right: 20px;
    width: 50%;
  }
`;

const LabelWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

const PARTNER_TYPE = [
  { label: 'TEST', value: PARTNER_BUSINESS_TYPE.TEST },
  { label: 'PRO', value: PARTNER_BUSINESS_TYPE.PRO },
];
const PARTNER_STATUS = {
  PARTNER_BUSINESS_TYPE_PRO: [
    { label: 'PENDING', value: PARTNER_BUSINESS_STATUS.PENDING },
    { label: 'BLOCKED', value: PARTNER_BUSINESS_STATUS.BLOCKED },
    { label: 'VALIDATED', value: PARTNER_BUSINESS_STATUS.VALIDATED },
  ],
  PARTNER_BUSINESS_TYPE_TEST: [
    { label: 'BLOCKED', value: PARTNER_BUSINESS_STATUS.BLOCKED },
    { label: 'VALIDATED', value: PARTNER_BUSINESS_STATUS.VALIDATED },
  ],
};

interface IPartnerBusinessInformationSelectorProps {
  onChangeStatus: Function;
  onChangeType: Function;
  value?: { type: string; status: string };
  style?: any;
  error?: any;
}

const PartnerBusinessInformationSelectorV3 = ({
  onChangeStatus,
  onChangeType,
  value,
  style,
  error,
}: IPartnerBusinessInformationSelectorProps) => (
  <ContainerWrapper style={style}>
    <div>
      <LabelWrapper>
        <Label>Account type *</Label>
      </LabelWrapper>
      <Radio.Group
        name="partner-type"
        options={PARTNER_TYPE}
        onChange={({ target: { value } }: RadioChangeEvent) =>
          onChangeType(value)
        }
        optionType="button"
        buttonStyle="solid"
        value={value?.type}
        data-testid="partnerType"
      />
      {error?.business_information?.type && (
        <Text
          variant="bodyTextXS"
          color="#FF3838"
          sx={{ paddingTop: '0.5rem' }}
        >
          {error?.business_information?.type}
        </Text>
      )}
    </div>
    <div>
      <LabelWrapper>
        <Label>
          Account status *&nbsp;
          {value?.type === PARTNER_BUSINESS_TYPE.TEST && (
            <Tooltip title="Actif is display for validated value">
              <QuestionCircleTwoTone twoToneColor="#a1debd" />
            </Tooltip>
          )}
        </Label>
      </LabelWrapper>
      <Radio.Group
        name="partner-status"
        options={PARTNER_STATUS[value?.type!]}
        onChange={({ target: { value } }: RadioChangeEvent) =>
          onChangeStatus(value)
        }
        optionType="button"
        buttonStyle="solid"
        value={value?.status}
        data-testid="partnerStatus"
      />
      {!value?.status && error?.business_information?.status && (
        <Text variant="bodyTextXS" color="#FF3838">
          {error?.business_information?.status}
        </Text>
      )}
    </div>
  </ContainerWrapper>
);

export default PartnerBusinessInformationSelectorV3;
