import has from 'lodash/has';

import dates from '@/helpers/dates';

interface ITrackingCode {
  id?: string;
  accountId?: string;
  createdAt?: string;
  updatedAt?: string;
  role?: {
    name?: string;
  };
}

const lpAccountPostProcessor = (data) => {
  if (!data) {
    return {};
  }
  const parsedItems = data.items.map((item: ITrackingCode) => ({
    ...item,
    id: has(item, 'accountId') ? item.accountId : item?.id,
    parsedCreatedAt: has(item, 'createdAt')
      ? dates.parseDate(item.createdAt)
      : '',
    parsedUpdatedAt: has(item, 'updatedAt')
      ? dates.parseDate(item.updatedAt)
      : '',
    roleName: has(item, 'role') ? item.role?.name : '',
  }));

  return { ...data, items: parsedItems };
};

export const lpAccountSearchPostProcessor = (data) => {
  const parsedItems = data.items.map((item) => ({
    ...item,
    parsedCreatedAt: has(item, 'createdAt')
      ? dates.parseDate(Math.floor(Date.parse(item.createdAt) / 1000))
      : '',
    parsedUpdatedAt: has(item, 'updatedAt')
      ? dates.parseDate(Math.floor(Date.parse(item.updatedAt) / 1000))
      : '',
  }));

  return { ...data, items: parsedItems };
};

export default lpAccountPostProcessor;
