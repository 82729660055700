import React, { useCallback, useState } from 'react';

import { StepProps, Steps } from 'antd';

import BillingModal from './BillingModal';
import BillingState from './BillingState';

export enum STEP_ITEMS_STATUS {
  WAIT = 'wait',
  ERROR = 'error',
  FINISH = 'finish',
  PROCESS = 'process',
}

const BillingManager = ({
  shipment,
  onUpdate,
}: {
  shipment: any;
  onUpdate: (body: {}) => void;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const stepItems: StepProps[] = [
    {
      title: (
        <BillingState
          handleClick={onUpdate}
          isBillable={shipment.isBillable}
          isPaid={shipment.isPaid}
          disabled={shipment.isBilled}
        />
      ),
      status: shipment.isBillable
        ? STEP_ITEMS_STATUS.FINISH
        : STEP_ITEMS_STATUS.ERROR,
    },
    ...(shipment.contractState === 'ARRIVED'
      ? [
          {
            title: 'Billed',
            status:
              shipment.isBilled && shipment.isBillable
                ? STEP_ITEMS_STATUS.FINISH
                : shipment.isBillable
                  ? STEP_ITEMS_STATUS.PROCESS
                  : STEP_ITEMS_STATUS.WAIT,
            onClick: () => {
              if (shipment.isBillable && !shipment.isPaid) {
                setIsOpen(!isOpen);
              }
            },
            disabled: !shipment.isBillable,
          },
          {
            title: 'PAID',
            status: shipment.isPaid
              ? STEP_ITEMS_STATUS.FINISH
              : shipment.isBilled
                ? STEP_ITEMS_STATUS.PROCESS
                : STEP_ITEMS_STATUS.WAIT,
            ...(shipment.isBilled && {
              onClick: () => {
                if (
                  shipment.paidCost !== undefined &&
                  shipment.invoiceID !== ''
                ) {
                  setIsOpen(!isOpen);
                }
              },
            }),
            disabled: !shipment.isBilled,
          },
        ]
      : []),
  ];

  const handleSubmit = useCallback(
    ({ isBilled, paidCost, invoiceId, isPaid }) => {
      if (isBilled && isPaid) {
        onUpdate({
          isPaid: isPaid,
          paidCost: paidCost,
          invoiceId: invoiceId,
        });
      } else {
        onUpdate({
          isBilled: true,
          paidCost: +paidCost,
          invoiceId: invoiceId,
        });
      }
    },
    [onUpdate]
  );

  return (
    <>
      <Steps items={stepItems} style={{ cursor: 'pointer' }} />
      <BillingModal
        isOpen={isOpen}
        onCancel={() => setIsOpen(!isOpen)}
        onSave={handleSubmit}
        shipment={shipment}
      />
    </>
  );
};

export default BillingManager;
