import React from 'react';

import { Route, Routes } from 'react-router-dom';

import LPScopesTable from './views/LPScopesTable';
import { appRoutes } from '@/configs/appRoutes';

const LPScopes = () => (
  <>
    <Routes>
      <Route path={appRoutes.ROOT} element={<LPScopesTable />} />;
    </Routes>
  </>
);

export default LPScopes;
