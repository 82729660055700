import React from 'react';

import { Button as AntdButton } from 'antd';
import { ButtonProps } from 'antd/lib/button';
interface IPillButton {
  loading?:
    | boolean
    | {
        delay?: number;
      };
  width?: string;
  mw?: string;
}

const PillButton: React.FC<ButtonProps & IPillButton> = ({
  children,
  width,
  mw,

  ...rest
}) => (
  <AntdButton
    shape="round"
    type="primary"
    {...rest}
    style={{ width: width, minWidth: mw }}
  >
    {children}
  </AntdButton>
);

export default PillButton;
