import React, { useMemo } from 'react';

import { Grid } from '@material-ui/core';
import { Tabs } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';

import PageTitle from './PageTitle';

const { TabPane } = Tabs;

const DashboardTabs = ({
  title,
  tabs,
}: {
  title: string;
  tabs: { name: string; key: string }[];
}) => {
  const history = useNavigate();
  const { pathname } = useLocation();
  const tab = pathname.split('/')[2];

  const getActiveTab = useMemo(
    () =>
      tab
        ? tabs.map((t) => t.key).includes(tab)
          ? tab
          : tabs[0].key
        : undefined,
    [tab, tabs]
  );

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={11}>
        <PageTitle title={title} />
      </Grid>
      <Grid item xs={11}>
        <Tabs
          type="card"
          activeKey={getActiveTab}
          onChange={(key) => history(`${key}`)}
        >
          {tabs.map((t) => (
            <TabPane tab={t.name} key={t.key} />
          ))}
        </Tabs>
      </Grid>
    </Grid>
  );
};

export default DashboardTabs;
