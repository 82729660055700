import get from 'lodash/get';
import isString from 'lodash/isString';

const truncateText = (text: string, length: number) => {
  if (text.length <= length) {
    return text;
  }

  return `${text.substr(0, length)}...`;
};

// This helper steps through the different error formats our endpoints returns
// and returns a parsed error when a match is found
const beErrorMsgParser = (error) => {
  //LpError format (latest & preferred response type)
  const errorMessage = get(error, 'response.data.message', '');
  const errorCode = get(error, 'response.data.code', '');

  if (errorCode && errorMessage && isString(errorMessage)) {
    return `Error: ${truncateText(errorMessage, 80)}, Code: ${errorCode}`;
  }

  //Some endpoints return "reason" instead of "message" field
  const errorReason = get(error, 'response.data.reason', '');

  if (errorCode && errorReason && isString(errorMessage)) {
    return `Error: ${truncateText(errorReason, 80)}, Code:${errorCode}`;
  }

  // Old error format (deprecated by BE)
  const fullErrorMSg = get(error, 'response.data.details', '');
  if (fullErrorMSg && isString(fullErrorMSg)) {
    const splitMsg = fullErrorMSg.split('desc = ');
    if (splitMsg.length !== 2) {
      return `Error: ${truncateText(fullErrorMSg, 80)}`;
    }

    return `Error: ${truncateText(splitMsg[1], 80)}`;
  }

  //If no matches are found
  return error.toString();
};

export const validationErrorParser = (error): string => {
  const kind = get(error, 'response.data.kind', '');
  if (kind && kind === 'Validation') {
    return 'Sorry, no exact match was found!';
  }

  return error.toString();
};

export default beErrorMsgParser;
