import { useCallback, useEffect, useState } from 'react';

import queryString from 'query-string';

import useLPUsersStore from './useLPUsersStore';
import { ILPAccountUser } from '../../lp-account-users';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useGetIcons = () => {
  const [, makeRequest] = useImperativeRequestWrapper('serviceLpAccount');
  const [buyNRenters, setBuyNRenters] = useState<ILPAccountUser[]>([]);
  const [contributors, setContributors] = useState<ILPAccountUser[]>([]);
  const [bothUsers, setBothUsers] = useState<ILPAccountUser[]>([]);
  const pageSize = useLPUsersStore((state) => state.pageSize);
  const offset = useLPUsersStore((state) => state.offset);
  const sortBy = useLPUsersStore((state) => state.sortBy);
  const order = useLPUsersStore((state) => state.order);
  const queryParams = queryString.stringify({
    pageSize: pageSize,
    offset: offset,
    sortBy: sortBy,
    order: order,
  });
  const getBuyNRenters = useCallback(async () => {
    const { data, error } = await makeRequest({
      path: `/admin/accounts?${queryParams}&invest=buyNRenters`,
      method: 'get',
    });

    if (error) {
      return { success: false };
    }
    Array.isArray(data?.items) && setBuyNRenters(data.items);
  }, [makeRequest, queryParams]);
  useEffect(() => {
    getBuyNRenters().then((r) => r);
  }, [getBuyNRenters]);
  const getContributors = useCallback(async () => {
    const { data, error } = await makeRequest({
      path: `/admin/accounts?${queryParams}&invest=contributors`,
      method: 'get',
    });

    if (error) {
      return { success: false };
    }

    Array.isArray(data?.items) && setContributors(data.items);
  }, [makeRequest, queryParams]);
  useEffect(() => {
    getContributors().then((r) => r);
  }, [getContributors]);

  const getBothUsers = useCallback(async () => {
    const { data, error } = await makeRequest({
      path: `/admin/accounts?${queryParams}&invest=contributorAndBuyNRenters`,
      method: 'get',
    });

    if (error) {
      return { success: false };
    }
    Array.isArray(data?.items) && setBothUsers(data.items);
  }, [makeRequest, queryParams]);
  useEffect(() => {
    getBothUsers().then((r) => r);
  }, [getBothUsers]);

  return [buyNRenters, contributors, bothUsers];
};

export default useGetIcons;
