import React from 'react';

import Dropdown from './forms/Dropdown';
import Label from './forms/Label';
interface ISimpleSelector<K> {
  label?: string;
  options: K[] | readonly K[];
  value?: K | null;
  onChange: (value: K) => any;
}

const SimpleSelector = <K extends string | number>({
  label,
  options,
  value,
  onChange,
}: ISimpleSelector<K>) => {
  const parsedOptions = [...options].map((option) => ({ value: option }));

  return (
    <>
      {label && <Label>{label}</Label>}
      <Dropdown
        options={parsedOptions}
        mw="100%"
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.value}
        value={value ? { value } : null}
        onChange={(option) => onChange(option.value as K)}
      />
    </>
  );
};

export default SimpleSelector;
