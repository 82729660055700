import { IService } from '../../services/models/service';

export interface IPartner {
  archived: boolean;
  accountId: string;
  deleted: boolean;
  createdAt: number;
  createdBy: string;
  description: string;
  id: string;
  name: string;
  phoneNumber: string;
  thumbnail: string;
  type: string;
  internal: boolean;
  updatedAt: number;
  website: string;
  services: IService[] | null;
  servicePool: IService[] | null;
  secursusEnabled?: boolean;
  businessInformation: IBusinessInformation;
  maxNbOfShipments: number;
  nbOfShipments: number;
  remoteControlEnabled?: boolean;
  fwUpdateEnabled?: boolean;
  flightModeEnabled?: boolean;
  clientId: String;
}

export interface IModifiedPartner extends IPartner {
  parsedCreatedAt: string;
  parsedUpdatedAt: string;
  serviceIds?: string[];
  demoEnabled?: boolean;
  servicePoolIds?: string[];
}

export interface ICreatePartner
  extends Pick<
    IPartner,
    | 'name'
    | 'phoneNumber'
    | 'website'
    | 'description'
    | 'thumbnail'
    | 'type'
    | 'accountId'
    | 'internal'
    | 'businessInformation'
    | 'maxNbOfShipments'
    | 'clientId'
  > {}

export const INITIAL_Partner: IPartner = {
  archived: false,
  deleted: false,
  accountId: '',
  createdAt: 0,
  createdBy: '',
  description: '',
  id: '',
  name: '',
  phoneNumber: '',
  thumbnail: '',
  type: '',
  updatedAt: 0,
  website: '',
  services: [],
  servicePool: [],
  internal: false,
  businessInformation: {
    status: '',
    type: '',
  },
  maxNbOfShipments: 0,
  nbOfShipments: 0,
  remoteControlEnabled: false,
  fwUpdateEnabled: false,
  flightModeEnabled: false,
  clientId: '',
};

export const INITIAL_ModifiedPartner: IModifiedPartner = {
  ...INITIAL_Partner,
  parsedCreatedAt: '',
  parsedUpdatedAt: '',
};

export const INITIAL_CreatePartner: ICreatePartner = {
  clientId: '',
  name: '',
  phoneNumber: '',
  website: '',
  description: '',
  thumbnail: '',
  type: 'ORG', // The field is require but not use after in project for the moment
  accountId: '',
  internal: false,
  businessInformation: {
    status: '',
    type: '',
  },
  maxNbOfShipments: 0,
};

export interface IBusinessInformation {
  type: string;
  status: string;
}
