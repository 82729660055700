import { create } from 'zustand';

import { IApiKey } from '../models/apiKey';
import { INITIAL_Pagination } from '@/hooks/useServerSideTable';

export type TLPApiKeySortBy =
  | 'description'
  | 'createdAt'
  | 'updatedAt'
  | 'expiresAfter';

interface IApiKeyStore {
  rowData: IApiKey[];
  defaultSort: {
    sortBy: TLPApiKeySortBy | string;
    order: 'ASC' | 'DESC';
  };
  error: string | boolean;
  loading: boolean;
  dispatch: (args: TLPApiKeyReducerInput) => void;
  pageSize: number;
  offset: number;
  total: number;
  count: number;
  sortBy: TLPApiKeySortBy | string;
  order: 'ASC' | 'DESC';
}

type TLPApiKeyReducerInput =
  | {
      type: 'updateRowData';
      args: { rowData: any[] };
    }
  | {
      type: 'updateError';
      args: { error: string | boolean };
    }
  | {
      type: 'updateLoading';
      args: { loading: boolean };
    }
  | {
      type: 'updatePagination';
      args: { pageSize: number; offset: number };
    }
  | {
      type: 'updateSorting';
      args: {
        sortBy: TLPApiKeySortBy | string;
        order: 'ASC' | 'DESC';
      };
    }
  | {
      type: 'updateCount';
      args: { count: number };
    }
  | {
      type: 'updateTotal';
      args: { total: number };
    }
  | {
      type: 'updateDefaultSort';
    }
  | {
      type: 'reset';
    };

const lpApiKeyReducer = (state: IApiKeyStore, input: TLPApiKeyReducerInput) => {
  switch (input.type) {
    case 'updateRowData':
      return { ...state, rowData: input.args.rowData };
    case 'updateError':
      return { ...state, error: input.args.error };
    case 'updateLoading':
      return { ...state, loading: input.args.loading };
    case 'updatePagination':
      return {
        ...state,
        pageSize: input.args.pageSize,
        offset: input.args.offset,
      };
    case 'updateSorting':
      return { ...state, sortBy: input.args.sortBy, order: input.args.order };
    case 'updateCount':
      return { ...state, count: input.args.count };
    case 'updateTotal':
      return { ...state, total: input.args.total };
    case 'updateDefaultSort':
      return {
        ...state,
        defaultSort: { sortBy: state.sortBy, order: state.order },
      };
    case 'reset':
      return INITIAL_STATE;
  }
};

const INITIAL_STATE: Omit<IApiKeyStore, 'dispatch'> = {
  rowData: [],
  error: '',
  ...INITIAL_Pagination,
  pageSize: 25,
  sortBy: 'updatedAt',
  loading: false,
  defaultSort: {
    sortBy: 'updatedAt',
    order: 'DESC',
  },
};

export const rowDataSelector = (state) => state.rowData;
export const loadingSelector = (state) => state.loading;
export const pageSizeSelector = (state) => state.pageSize;
export const offsetSelector = (state) => state.offset;
export const totalSelector = (state) => state.total;
export const defaultSortSelector = (state) => state.defaultSort;
export const dispatchSelector = (state) => state.dispatch;

const useApiKeyStore = create<IApiKeyStore>((set) => ({
  ...INITIAL_STATE,
  dispatch: (input) => set((state) => lpApiKeyReducer(state, input)),
}));

export default useApiKeyStore;
