import { create } from 'zustand';

import { TLifecyclestatuses } from '../../../boxes/models/boxLifecycle'; //'../models/boxLifecycle';

export type TBoxesSortBy = 'createdAt' | 'updatedAt';

export interface IProduct {
  mechanical_configuration: string;
  lifecycle_status: 'INIT';
  sim_status: string;
  sim_status_updated_at: string;
  shop_name: string;
  product_id: string;
  lp_ui: string;
  alias: string;
  location: null;
  location_updated_at: string;
  battery_level: number;
  temperature_internal: number;
  humidity_internal: number;
  temperature_external: number;
  humidity_external: number;
  pressure: number;
  contract_state: string;
  contract_state_updated_at: string;
  last_shipment_id: string;
  lock_state: boolean;
  firmware_version: string;
  shipments_operated: number;
  distance_travelled: number;
  updated_at: string;
  current_screen: string;
  closing_state: string;
  holder_type: string;
  holder_id: string;
  holder_name: string;
  shop_id: string;
  article_code: string;
  article_description: string;
  partner_id: string;
  partner_since: string;
  parsedCreatedAt?: string;
  parsedUpdatedAt?: string;
  parsedManufacturingDate?: string;
  productDescription: string;
}

interface IBoxesStore {
  rowData: IProduct[];
  pageSize: number;
  offset: number;
  sortBy: TBoxesSortBy;
  order: 'ASC' | 'DESC';
  defaultSort: {
    sortBy: TBoxesSortBy;
    order: 'ASC' | 'DESC';
  };
  error: string | boolean;
  loading: boolean;
  productLifecycleStatuses: TLifecyclestatuses | TLifecyclestatuses[] | null;
  productDescription: [];
  count: number;
  dispatch: (args: TBoxesReducerInput) => void;
  total: number;
  searchTerms: {
    thingName: string;
    boxId: string;
    bleMacAddress: string;
    partnerId: string;
    holderId: string;
    notesSearchTerm: string;
  };
}

type TBoxesReducerInput =
  | {
      type: 'updateRowData';
      args: { rowData: any[] };
    }
  | {
      type: 'updateError';
      args: { error: string | boolean };
    }
  | {
      type: 'updateLoading';
      args: { loading: boolean };
    }
  | {
      type: 'updatePagination';
      args: { pageSize: number; offset: number };
    }
  | {
      type: 'updateSorting';
      args: {
        sortBy: TBoxesSortBy;
        order: 'ASC' | 'DESC';
      };
    }
  | {
      type: 'updateFilters';
      args: {
        productLifecycleStatuses: TLifecyclestatuses[];
        productDescription: [];
      };
    }
  | {
      type: 'refreshTable';
    }
  | {
      type: 'updateCount';
      args: { count: number };
    }
  | {
      type: 'updateTotal';
      args: { total: number };
    }
  | {
      type: 'updateDefaultSort';
    }
  | {
      type: 'updateSearchTerms';
      args: {
        thingName: string;
        boxId: string;
        bleMacAddress: string;
        partnerId: string;
        holderId: string;
        notesSearchTerm: string;
      };
    }
  | {
      type: 'reset';
    };

const boxesReducer = (state: IBoxesStore, input: TBoxesReducerInput) => {
  switch (input.type) {
    case 'updateRowData':
      return { ...state, rowData: input.args.rowData };
    case 'updateError':
      return { ...state, error: input.args.error };
    case 'updateLoading':
      return { ...state, loading: input.args.loading };
    case 'updatePagination':
      return {
        ...state,
        pageSize: input.args.pageSize,
        offset: input.args.offset,
      };
    case 'updateSorting':
      return { ...state, sortBy: input.args.sortBy, order: input.args.order };
    case 'refreshTable':
      return {
        ...state,
        pageSize:
          state.pageSize % 5 === 0 ? state.pageSize + 1 : state.pageSize - 1,
      };
    case 'updateCount':
      return { ...state, count: input.args.count };
    case 'updateTotal':
      return { ...state, total: input.args.total };
    case 'updateDefaultSort':
      return {
        ...state,
        defaultSort: { sortBy: state.sortBy, order: state.order },
      };
    case 'updateSearchTerms':
      return {
        ...state,
        searchTerms: {
          ...input.args,
          ...(input.args['thingName'] && {
            thingName: input.args['thingName'].replaceAll(/[/\s+|-]/g, ''),
          }),
        },
        offset: 0,
      };

    case 'updateFilters':
      return {
        ...state,
        productLifecycleStatuses: input.args.productLifecycleStatuses,
        productDescription: input.args.productDescription,
      };
    case 'reset':
      return INITIAL_STATE;
  }
};

const INITIAL_STATE: Omit<IBoxesStore, 'dispatch'> = {
  rowData: [],
  error: '',
  loading: false,
  pageSize: 50,
  offset: 0,
  sortBy: 'updatedAt',
  order: 'DESC',
  defaultSort: {
    sortBy: 'updatedAt',
    order: 'DESC',
  },
  count: 0,
  total: 0,
  productLifecycleStatuses: [],
  productDescription: [],
  searchTerms: {
    thingName: '',
    boxId: '',
    bleMacAddress: '',
    partnerId: '',
    holderId: '',
    notesSearchTerm: '',
  },
};

const useProductPartnerStore = create<IBoxesStore>((set) => ({
  ...INITIAL_STATE,
  dispatch: (input) => set((state) => boxesReducer(state, input)),
}));

export default useProductPartnerStore;
