import { useCallback } from 'react';

import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useEditShipment = () => {
  const [{ loading: deleteLoading }, makeDeleteRequest] =
    useImperativeRequestWrapper('shipment');
  const [{ loading: archiveLoading }, makeArchiveRequest] =
    useImperativeRequestWrapper('shipment');
  const successMsgToast = useToasts((state) => state.addItem);
  const errorMsgToast = useToasts((state) => state.addItem);

  const editShipment = useCallback(
    async ({
      shipmentId,
      makeRequest,
      method,
      body = {},
      errorMsg,
      successMsg,
    }) => {
      const { error } = await makeRequest({
        path: `/shipments/${shipmentId}`,
        method,
        body,
      });
      if (error) {
        errorMsgToast({ msg: errorMsg, type: MessageType.Error }, 'root', {});

        return { success: false };
      }
      successMsgToast(
        { msg: successMsg, type: MessageType.Success },
        'root',
        {}
      );

      return { success: true };
    },
    [successMsgToast, errorMsgToast]
  );

  const deleteShipment = (shipmentId: string) =>
    editShipment({
      shipmentId,
      makeRequest: makeDeleteRequest,
      method: 'DELETE',
      errorMsg: 'An error occured: the shipment has not been deleted',
      successMsg: 'The shipment has been succefully deleted',
    });

  const archiveShipment = (shipmentId: string) =>
    editShipment({
      shipmentId,
      makeRequest: makeArchiveRequest,
      method: 'PATCH',
      errorMsg: 'An error occured: the shipment has not been archived',
      successMsg: 'The shipment has been succefully archived',
      body: { isArchived: true },
    });

  const unArchiveShipment = (shipmentId: string) =>
    editShipment({
      shipmentId,
      makeRequest: makeArchiveRequest,
      method: 'PATCH',
      errorMsg: 'An error occured: the shipment has not been unArchived',
      successMsg: 'The shipment has been succefully unarchived',
      body: { isArchived: false },
    });

  const restoreShipment = (shipmentId: string) =>
    editShipment({
      shipmentId,
      makeRequest: makeArchiveRequest,
      method: 'PATCH',
      errorMsg: 'An error occured: the shipment has not been restores',
      successMsg: 'The shipment has been succefully restored',
      body: { restoreShipment: true },
    });

  const updateBilling = (shipmentId: string, body: {}) =>
    editShipment({
      shipmentId,
      makeRequest: makeArchiveRequest,
      method: 'PATCH',
      errorMsg: 'An error occured: billing status has not been changed',
      successMsg: 'The billing status has been succefully changed',
      body: body,
    });

  return {
    deleteLoading,
    deleteShipment,
    archiveLoading,
    archiveShipment,
    unArchiveShipment,
    restoreShipment,
    updateBilling,
  };
};

export default useEditShipment;
