import React, { useCallback, useMemo } from 'react';

import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  VStack,
} from '@chakra-ui/react';
import { Switch } from '@lp/ds-next';
import { Formik } from 'formik';

import { ILPAccountUserState } from '../../hooks/useLPAccountUser';
import { PreferredLanguage } from '../../models/lpaccount';
import { updateProfileSchema } from '../../schemas/lpaccountSchema';
import EditButtonsOnForm from '@/components/EditButtonsOnForm';
import FormRow from '@/components/forms/FormRow';
import { scopes } from '@/config';

interface Props {
  user: ILPAccountUserState;
  saveUserData: (data) => Promise<{ success: boolean }>;
}

const LANG_OPTIONS: { value: PreferredLanguage; label: string }[] = [
  { value: 'en', label: 'English' },
  { value: 'fr', label: 'French' },
  { value: 'de', label: 'German' },
];

const LPAccountForm = ({ user, saveUserData }: Props) => {
  const initialValues = useMemo(() => user.data, [user]);
  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true);
      await saveUserData(values);
      setSubmitting(false);
    },
    [saveUserData]
  );

  if (user.loading) {
    return <></>;
  }

  return (
    <>
      <Formik<UpdateUserProfileFormValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={updateProfileSchema}
      >
        {({
          handleChange,
          handleSubmit,
          handleReset,
          handleBlur,
          values,
          isSubmitting,
          dirty,
          errors,
        }) => (
          <form onSubmit={handleSubmit} onReset={handleReset} noValidate>
            <VStack alignItems="flex-start" spacing={6}>
              <FormRow>
                <FormControl id="firstName">
                  <FormLabel>First Name</FormLabel>
                  <Input
                    name="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                  />
                </FormControl>
                <FormControl id="lastName">
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    name="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                  />
                </FormControl>
                <FormControl id="givenName" isDisabled>
                  <FormLabel>Given Name</FormLabel>
                  <Input name="givenName" value={values.givenName} />
                </FormControl>
              </FormRow>
              <FormRow>
                <FormControl id="phoneNumber">
                  <FormLabel>Phone Number</FormLabel>
                  <Input
                    name="phoneNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phoneNumber}
                  />
                </FormControl>
                <FormControl id="emailVerified" style={{ marginLeft: '4rem' }}>
                  <FormLabel>Email Verified</FormLabel>
                  <Switch
                    id="emailVerified"
                    onChange={handleChange}
                    checked={values.emailVerified}
                  />
                </FormControl>
                <FormControl id="accountVerified" style={{ marginLeft: '0' }}>
                  <FormLabel>Account Verified</FormLabel>
                  <Switch
                    id="accountVerified"
                    onChange={handleChange}
                    checked={values.accountVerified}
                  />
                </FormControl>
              </FormRow>
              <FormRow width="100%">
                <FormControl
                  isRequired
                  id="preferredLanguage"
                  isInvalid={errors.preferredLanguage !== undefined}
                >
                  <FormLabel>Preferred Language</FormLabel>
                  <Select
                    name="preferredLanguage"
                    placeholder="Select language"
                    value={values.preferredLanguage as string}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {LANG_OPTIONS.map(({ value, label }) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    {errors.preferredLanguage}
                  </FormErrorMessage>
                </FormControl>
                <FormControl id="publicHandle" isDisabled>
                  <FormLabel>Public Handle</FormLabel>
                  <Input name="publicHandle" value={values.publicHandle} />
                </FormControl>
              </FormRow>
              <HStack width="100%" justify="flex-end">
                <EditButtonsOnForm
                  handleCancel={handleReset}
                  handleSaveEdit={handleSubmit}
                  disabledCancel={isSubmitting || !dirty}
                  disabledSubmit={isSubmitting || !dirty}
                  requiredScopes={[scopes.EDIT_LPACCOUNT]}
                />
              </HStack>
            </VStack>
          </form>
        )}
      </Formik>
    </>
  );
};

interface UpdateUserProfileFormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailVerified: boolean;
  accountVerified: boolean;
  preferredLanguage: PreferredLanguage;
  publicHandle: string;
  givenName: string;
}

export default LPAccountForm;
