import { useCallback } from 'react';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useDeletePartner: any = (partnerId) => {
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpVillage');

  const deletePartner = useCallback(async () => {
    const result = await makeRequest({
      path: `/admin/partners/${partnerId}`,
      method: 'DELETE',
    });
    if (!!result.error) {
      addError(result.error);

      return { success: false };
    }
    addSuccessMsg(
      { msg: 'Partner was successfully deleted', type: MessageType.Success },
      'root',
      {}
    );

    return { success: true };
  }, [addError, addSuccessMsg, makeRequest, partnerId]);

  return [{ loading }, deletePartner];
};
export default useDeletePartner;
