import React from 'react';

import { Box, Grid, RadioButton, Text } from '@lp/ds-next';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { map } from 'lodash/fp';

import {
  IPartnerV3,
  IPartnerV3Update,
  Languages,
  LanguagesKeys,
} from '../../../models/partnerV3';
import { languages } from '@/configs/languages';

const LanguagesManagement = ({
  partner,
  updatePartner,
}: {
  partner: IPartnerV3;
  updatePartner: (
    data: Partial<IPartnerV3Update>
  ) => Promise<{ success: boolean }>;
}) => (
  <Grid item mobile={12} tablet={4}>
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend">
        <Text variant="titleM">BOX Languages Management</Text>
      </FormLabel>
      <FormGroup sx={{ padding: '1rem' }}>
        <RadioGroup
          value={partner.product_default_language}
          name="product_default_language"
          onChange={(event) => {
            updatePartner({
              product_default_language: event?.target.value as LanguagesKeys,
            });
          }}
        >
          {map(
            (lang) => (
              <Box width="fit-content">
                <RadioButton
                  key={lang}
                  label={languages[lang.slice(-2)]}
                  value={lang}
                  checked={lang === partner.product_default_language}
                />
              </Box>
            ),
            Languages
          )}
        </RadioGroup>
      </FormGroup>
    </FormControl>
  </Grid>
);

export default LanguagesManagement;
