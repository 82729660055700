import React, { Dispatch, SetStateAction } from 'react';

import {
  Box,
  Text,
  Paper,
  Grid,
  Pagination,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@lp/ds-next';
import { BeatLoader } from 'react-spinners';

import { HeaderToolbar } from './HeaderToolbar';
import { Row } from './Row';
import { TableHeader } from './TableHeader';
import { ChangelogResponse, HeadCell, Order } from '../../../types/changelog';
import { TargetsKeys } from '@/features/changelog/api/getChangelog';

const ROW_HEIGHT = 52;
const ROW_HEIGHT_HIDDEN = 0.5;

// Add rows + hidden (exapandle) rows
const computeTableHeight = (count) =>
  ROW_HEIGHT * count + ROW_HEIGHT_HIDDEN * count;

export function ChangelogTable<T>({
  isPending,
  error,
  data,
  headCells,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  pageSize,
  pageOffset,
  setPageOffset,
  title,
  filters,
  refetch,
  totalChangelogs,
  target,
  globalOpen,
  setGlobalOpen,
}: {
  data?: ChangelogResponse<T>;
  isPending: boolean;
  error: Error | null;
  headCells: HeadCell[];
  order: Order;
  setOrder: Dispatch<SetStateAction<Order>>;
  orderBy: string;
  setOrderBy: Dispatch<SetStateAction<any>>;
  pageSize: number;
  pageOffset: number;
  setPageOffset: Dispatch<SetStateAction<number>>;
  title: string;
  filters?: JSX.Element;
  refetch: () => void;
  totalChangelogs: number;
  target: TargetsKeys;
  globalOpen: boolean;
  setGlobalOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const theme = useTheme();

  const nbCells = headCells.length + 1;

  const handleRequestSort = (
    _: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    pageOffset / pageSize > 0
      ? Math.max(0, (1 + pageOffset / pageSize) * pageSize - totalChangelogs)
      : 0;

  // Show loading, error or data
  const showRows = () => {
    if (isPending && !data) {
      return (
        <TableRow
          data-testid="changelog-table-loader"
          style={{
            height: computeTableHeight(pageSize),
          }}
        >
          <TableCell colSpan={nbCells}>
            <Grid container justifyContent="center" alignContent="center">
              <BeatLoader size={12} color={theme.palette.custom.primary[120]} />
            </Grid>
          </TableCell>
        </TableRow>
      );
    }

    if (error) {
      return (
        <TableRow
          style={{
            height: computeTableHeight(pageSize),
          }}
        >
          <TableCell colSpan={nbCells} align="center">
            <Text variant="bodyTextM" sx={{ display: 'inline' }}>
              Error, please{' '}
              <Text
                color="custom.primary.100"
                variant="bodyTextM"
                onClick={refetch}
                sx={{ display: 'inline', cursor: 'pointer' }}
              >
                refresh
              </Text>{' '}
              page or try again later.
            </Text>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <>
        {data?.[target.responseKey].map((row: any) => (
          <Row<T>
            key={row.changelog.id}
            row={row}
            headCells={headCells}
            globalOpen={globalOpen}
          />
        ))}
        {emptyRows > 0 && (
          <TableRow
            style={{
              height: computeTableHeight(emptyRows),
            }}
          >
            <TableCell colSpan={nbCells} />
          </TableRow>
        )}
      </>
    );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ p: '1rem', gap: '0.5rem' }}>
        <HeaderToolbar
          title={title}
          filters={filters}
          total={totalChangelogs}
        />
        <TableContainer>
          <Table
            data-testid="changelog-table"
            sx={{ minWidth: '21.875rem' }}
            size="small"
          >
            <TableHeader
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              setGlobalOpen={setGlobalOpen}
              globalOpen={globalOpen}
            />
            <TableBody>{showRows()}</TableBody>
          </Table>
        </TableContainer>
        <Grid container justifyContent="center">
          <Pagination
            totalData={totalChangelogs}
            dataPerPage={pageSize}
            page={pageOffset / pageSize + 1}
            onChange={(page) => {
              setPageOffset(pageSize * (page - 1));
            }}
            isInputContol={true}
          />
        </Grid>
      </Paper>
    </Box>
  );
}
