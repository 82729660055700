import { useCallback, useState } from 'react';

import useBoxesRelationsStore from './useBoxesRelationsStore';
import { boxRelationsPostProcessor } from '../helpers/boxesPostProcessor';
import { IRelation } from '../models/box';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useBoxRelations = (id: string) => {
  const addError = useError();
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('device');

  const [activeRelations, setActiveRelations] = useState<IRelation[]>([]);

  const dispatch = useBoxesRelationsStore((state) => state.dispatch);
  const order = useBoxesRelationsStore((state) => state.order);
  const sortBy = useBoxesRelationsStore((state) => state.sortBy);
  const offset = useBoxesRelationsStore((state) => state.offset);
  const pageSize = useBoxesRelationsStore((state) => state.pageSize);
  const relationType = useBoxesRelationsStore((state) => state.relationType);

  const getRelations = useCallback(async () => {
    const { data, error } = await getPaginatedTableData({
      params: {
        path: `/boxes/${id}/relations`,
        method: 'get',
      },
      reducer: boxRelationsPostProcessor,
      dispatch,
      addError,
      makeRequest,
      queryParams: {
        pageSize,
        offset,
        sortBy,
        order,
        relationType,
      },
      queryParamsOptions: { skipEmptyString: true, skipNull: true },
    });

    return { data, error };
  }, [
    addError,
    dispatch,
    makeRequest,
    order,
    sortBy,
    pageSize,
    offset,
    id,
    relationType,
  ]);

  const getActiveRelations = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: `/boxes/${id}/relations?active=true`,
      method: 'get',
    });
    if (error) {
      addError(error);

      return { success: false };
    }
    setActiveRelations(data.items);

    return { success: true };
  }, [makeRequest, addError, id]);

  const createRelation = useCallback(
    async (body) => {
      const { error, data } = await makeRequest({
        path: `/boxes/${id}/relations`,
        method: 'post',
        body: body,
      });
      if (error) {
        addError(error);
      }
      await getRelations();

      return { success: true, data: data };
    },
    [makeRequest, addError, id, getRelations]
  );

  return {
    loading,
    activeRelations,
    setActiveRelations,
    getActiveRelations,
    getRelations,
    createRelation,
  };
};

export default useBoxRelations;
