import React from 'react';

import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  createColumnHelper,
} from '@tanstack/react-table';
import moment from 'moment';
import styled from 'styled-components';

import { formatDate, formatISODate } from '@/helpers/dates';

export const StyledTable = styled.table`
  border-collapse: initial;
  border-spacing: 0 0.5rem;
`;

export const ListTbodyStyled = styled.tbody`
  //background: ${({ theme }) => theme.colors.black[10]};
`;

const ListTbodyTrStyled = styled.tr`
  background-color: ${({ theme }) => theme.colors.white};
  height: 80px;
  :hover {
    background-color: #f0fcf8; /* ${({ theme }) => theme.colors.white}; */
  }
`;

const ListTbodyTdStyled = styled.td<{
  isFirst: boolean;
  isLast: boolean;
}>`
  padding-bottom: 16px;
  padding-bottom: 16px;
  border-radius: ${({ isFirst, isLast }) =>
    isFirst ? '12px 0 0 12px' : isLast ? '0 12px 12px 0' : 'none'};
  padding-left: 24px;
  padding-right: 24px;
`;

const List = ({ data, columns }) => {
  const columnHelper = createColumnHelper<any>();

  columns = columns.map((column: any) => {
    //format row type Date after render
    if (column.dataType) {
      column.cell = (info) => {
        const value = info.getValue();
        if (value instanceof Date) {
          return formatDate(value);
        } else if (moment(value, moment.ISO_8601, true).isValid()) {
          return formatISODate(value);
        }
      };
    }

    return columnHelper.accessor(column.accessor, {
      id: column.accessor,
      header: () => column.header,
      ...(column.headerEmphasis && { headerEmphasis: column.headerEmphasis }),
      ...(column.cell && { cell: column.cell }),
    });
  });

  const tableInstance = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <StyledTable>
      <thead>
        {tableInstance.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <ListTbodyStyled>
        {tableInstance.getRowModel().rows.map((row) => (
          <ListTbodyTrStyled key={row.id}>
            {row.getVisibleCells().map((cell, idx) => (
              <ListTbodyTdStyled
                key={cell.id}
                isFirst={idx === 0}
                isLast={idx === row.getVisibleCells().length - 1}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </ListTbodyTdStyled>
            ))}
          </ListTbodyTrStyled>
        ))}
      </ListTbodyStyled>
    </StyledTable>
  );
};

export default List;
