import { useCallback } from 'react';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useGetAvailableScopes = (role) => {
  const addError = useError();
  const [, makeRequest] = useImperativeRequestWrapper('lpVillage');

  const getScopes = useCallback(async () => {
    const queryString = new URLSearchParams({ pageSize: '100' }).toString();
    const { data, error } = await makeRequest({
      path: `/admin/scopes?${queryString}`,
      method: 'get',
    });
    if (error) {
      addError(error);

      return [];
    }
    if (role.scopes) {
      return data.items.filter(
        (d) => !role.scopes.map((r) => r.name).includes(d.name)
      );
    }

    return data.items;
  }, [addError, makeRequest, role.scopes]);

  return getScopes;
};
export default useGetAvailableScopes;
