import React from 'react';

import { Grid } from '@lp/ds-next';

import { Carriers } from './Settings/Carriers';
import LanguagesManagement from './Settings/Languages';
import { Toggles } from './Settings/Toggles';
import { IPartnerV3, IPartnerV3Update } from '../../models/partnerV3';

const PartnerSettings = ({
  partner,
  updatePartner,
}: {
  partner: IPartnerV3;
  updatePartner: (data: IPartnerV3Update) => Promise<{ success: boolean }>;
}) => (
  <Grid container sx={{ pt: '1.25rem' }} spacing={1}>
    <Toggles partner={partner} updatePartner={updatePartner} />
    <Carriers partner={partner} updatePartner={updatePartner} />
    <LanguagesManagement partner={partner} updatePartner={updatePartner} />
  </Grid>
);

export default PartnerSettings;
