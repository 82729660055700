import React from 'react';

import { Routes, Route } from 'react-router-dom';

import ProductDetailView from './views/ProductDetailView';
import ProductsListView from './views/ProductsListView';
import { QualityCheckDetailContent } from '../quality-checks';
import ScopedRoute from '@/components/ScopedRoute';
import { scopes } from '@/config';
import { appRoutes } from '@/configs/appRoutes';
import useAppState from '@/hooks/useAppState';
import ArticlesProvider from '@/shared/providers/articlesProvider';

const BoxesRouter = () => {
  const userScopes = useAppState((state) => state.scopes);

  return (
    <ArticlesProvider>
      <Routes>
        <Route
          path={appRoutes.ROOT}
          element={
            <ScopedRoute
              component={<ProductsListView />}
              userScopes={userScopes}
              requiredScopes={[scopes.VIEW_PRODUCT]}
            />
          }
        />
        <Route
          path={appRoutes.PRODUCTS.PRODUCTS_ID}
          element={
            <ScopedRoute
              component={<ProductDetailView />}
              userScopes={userScopes}
              requiredScopes={[scopes.VIEW_PRODUCT]}
            />
          }
        />

        <Route
          path="/:id/quality-check/:qcId"
          element={<QualityCheckDetailContent />}
        />
      </Routes>
    </ArticlesProvider>
  );
};

export default BoxesRouter;
