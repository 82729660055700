import { useCallback, useEffect, useState } from 'react';

import boxClosestToDatePostProcessor from '../helpers/boxClosestToDatePostProcessor';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useBoxFirmwareByDates = (id, deployedAt) => {
  const [, makeRequest] = useImperativeRequestWrapper('device');
  const [firmwareClosestToDate, setFirmwareClosestToDate] = useState<string>();
  const addError = useError();

  const getFirmwareByDates = useCallback(() => {
    makeRequest({
      path: `/boxes/${id}/firmware-version/history`,
      method: 'get',
    }).then((res) => {
      if (!!res.error) {
        addError(res.error);

        return;
      }
      try {
        const processedData = boxClosestToDatePostProcessor(
          res.data.items,
          deployedAt
        );
        setFirmwareClosestToDate(processedData);
      } catch (err) {
        addError(err);
      }
    });
  }, [id, makeRequest, addError, deployedAt]);
  useEffect(() => {
    if (!id) return;
    getFirmwareByDates();
  }, [getFirmwareByDates, id]);

  return { firmwareClosestToDate, getFirmwareByDates };
};

export default useBoxFirmwareByDates;
