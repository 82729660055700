import React, { useCallback, useState } from 'react';

import SearchObject from '../../../components/SearchObject';
import useGetSingleAccount from '../hooks/useGetSingleAccount';
import useError from '@/hooks/useError';

interface ISearchAccount {
  disabled?: boolean;
  accountId: string | undefined;
  setAccountId: (id: string) => void;
}

const SearchAccount = ({
  disabled,
  accountId,
  setAccountId,
}: ISearchAccount) => {
  const addError = useError();
  const getSingleAccount = useGetSingleAccount();
  const [searchField, setSearchField] = useState<string>('');

  const handleFindEmail = useCallback(
    (searchEmail: string) => {
      if (searchEmail !== '') {
        getSingleAccount(searchEmail).then((res) => {
          if (!res.success) {
            addError('Unable to find account!', { trackError: false });

            return;
          }
          setAccountId(res.id);
        });
      }
    },
    [getSingleAccount, addError, setAccountId]
  );

  return (
    <SearchObject
      objectId={accountId}
      setObjectId={setAccountId}
      searchField={searchField}
      setSearchField={setSearchField}
      disabled={disabled}
      placeholder="Email..."
      searchLabel="Account (enter valid email address)"
      foundLabel="Account"
      handleFind={handleFindEmail}
    />
  );
};

export default SearchAccount;
