import { has } from 'lodash';

import dates from '@/helpers/dates';

export const boxQualityChecklist = (data) => {
  if (!data) {
    return {};
  }
  const items = data.items || [];

  const parsedItems = items.map((item) => ({
    ...item,
    parsedCreatedAt: has(item, 'createdAt')
      ? dates.parseDate(item.createdAt)
      : '',
    parsedUpdatedAt: has(item, 'updatedAt')
      ? dates.parseDate(item.updatedAt)
      : '',
    createdBy: has(item, 'createdBy.email') ? item.createdBy.email : '',
    updatedBy: has(item, 'updatedBy.email') ? item.updatedBy.email : '',
  }));

  return { ...data, items: parsedItems };
};
