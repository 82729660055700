export interface IQualityCheckType {
  createdAt: number;
  parsedCreatedAt: string;
  parsedUpdatedAt: string;
  createdBy: {
    email: string;
    id: string;
  };
  description: string;
  expectedLevel: string;
  id: string;
  image: string;
  isArchived: boolean;
  name: string;
  updatedAt: number;
  updatedBy: {
    email: string;
    id: string;
  };
  zone: string;
}

export interface ICreateQualityCheckType {
  description: string;
  expectedLevel: string;
  image: string;
  name: string;
  zone: string;
}

export interface IQualityChecklistLight {
  id: string;
  createdAt: number;
  updatedAt: number;
  parsedCreatedAt: number;
  parsedUpdatedAt: number;
  createdBy: {
    id: string;
    email: string;
  };
  updatedBy: {
    id: string;
    email: string;
  };
  boxId: string;
  shipmentId?: string;
}

export enum ZonesNames {
  'frontFace' = 'Front Face',
  'backFace' = 'Back Face',
  'lateralFace' = 'Lateral Face',
  'topFace' = 'Top Face',
  'bottomFace' = 'Bottom Face',
  'internalFaceOfLID' = 'Internal Face of LID',
  'internalFaceOfBody' = 'Internal Face of Body',
  'other' = 'Other',
}

export type TStatus = 'OK' | 'NOK' | 'UNKNOWN';

interface IQualityCheckItem {
  id: string;
  qualityCheckType: IQualityCheckType;
  status: TStatus;
  comment: string;
  images: string[];
}

export interface IQualityChecklist extends IQualityChecklistLight {
  items: IQualityCheckItem[];
}

export interface ICreateQualityCheckItem {
  comment: string;
  images: string[];
  qualityCheckTypeId: string;
  status: TStatus;
}

export interface ICreateQualityChecklist {
  boxId: string;
  shipmentId?: string;
  items: ICreateQualityCheckItem[];
}

export const INITIAL_QualityCheckType = {
  createdAt: 0,
  parsedCreatedAt: '',
  parsedUpdatedAt: '',
  createdBy: {
    email: '',
    id: '',
  },
  description: '',
  expectedLevel: '',
  id: '',
  image: '',
  isArchived: true,
  name: '',
  updatedAt: 0,
  updatedBy: {
    email: '',
    id: '',
  },
  zone: '',
};

export const INITIAL_CreateQualityCheckType = {
  description: '',
  expectedLevel: '',
  image: '',
  name: '',
  zone: '',
};

export const INITIAL_QualityCheck = {
  comment: '',
  images: [],
  qualityCheckTypeId: '',
  status: 'UNKNOWN' as TStatus,
};

export const INITIAL_CreateQualityChecklist = {
  boxId: '',
  items: [] as ICreateQualityCheckItem[],
};

export const INITIAL_QualityChecklist = {
  id: '',
  createdAt: 0,
  updatedAt: 0,
  parsedCreatedAt: 0,
  parsedUpdatedAt: 0,
  createdBy: {
    id: '',
    email: '',
  },
  updatedBy: {
    id: '',
    email: '',
  },
  boxId: '',
  shipmentId: '',
  items: [],
};
