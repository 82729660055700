import React from 'react';

import { ColumnProps } from 'antd/lib/table';

import { IIdentity } from '../models/lpaccount';
import ActiveIcon from '@/components/icons/ActiveIcon';
import InActiveIcon from '@/components/icons/InActiveIcon';
import StyledTable from '@/components/tables/StyledTable';
import useAntdColumns from '@/hooks/useAntdColumns';

const LPAccountUserIdentitiesTable = ({
  identities,
  loading,
}: {
  identities: IIdentity[];
  loading: boolean;
}) => {
  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: [
      'identityId',
      'connection',
      'providerUserId',
      'provider',
      'isSocial',
    ],
    columnsSpecialProps: {
      isSocial: {
        align: 'center',
        render: (_, record) =>
          record.isSocial ? <ActiveIcon /> : <InActiveIcon />,
      },
    },
  });

  return (
    <StyledTable
      rowKey="id"
      columns={columns}
      dataSource={identities}
      size="small"
      pagination={false}
      loading={loading}
    />
  );
};

export default LPAccountUserIdentitiesTable;
