import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { ERPContract } from '../models/partnerV3';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export class NotFoundError extends Error {}

export const getERPContract = async ({
  partnershipId,
  makeRequest,
}: {
  partnershipId: string;
  makeRequest: any;
}): Promise<ERPContract> => {
  const { data, error } = await makeRequest({
    path: `/partners/${partnershipId}/contracts`,
  });

  if (error) {
    if (error.response.status === 404) {
      throw new NotFoundError();
    }
    throw new Error(error);
  }

  return data.contract;
};

type QueryFnType = typeof getERPContract;

type UsePartnershipOptions = {
  partnershipId?: string;
  config?: QueryConfig<QueryFnType>;
};

export const useERPContract = ({
  partnershipId,
  config,
}: UsePartnershipOptions = {}) => {
  const { id: partnerId } = useParams<{ id: string }>();

  let id = partnershipId;
  if (!id) {
    id = partnerId;
  }

  const [, makeRequest] = useImperativeRequestWrapper('lpVillageV3');

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['partnership', id, 'contract'],
    queryFn: () => getERPContract({ partnershipId: id!, makeRequest }),
  });
};
