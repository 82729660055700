import React, { Dispatch, SetStateAction, useCallback } from 'react';

import { Col, Row } from 'antd';

import { CreateBoxOffer, BoxOfferType } from '../models/boxContract';
import Error from '@/components/Error';
import DateTimePicker from '@/components/forms/DateTimePicker';
import IntegerInput from '@/components/forms/IntegerInput';
import Label from '@/components/forms/Label';
import TextInput from '@/components/forms/TextInput';
import SimpleSelector from '@/components/SimpleSelector';

interface ICreateBoxOfferForm {
  boxOffer: CreateBoxOffer;
  setBoxOffer: Dispatch<SetStateAction<CreateBoxOffer>>;
  isInvalidDate: boolean;
}

const CreateBoxOfferForm = ({
  boxOffer,
  setBoxOffer,
  isInvalidDate,
}: ICreateBoxOfferForm) => {
  const handleInputChange = useCallback(
    (event) => {
      event.persist();
      setBoxOffer((prev) => ({
        ...prev,
        [event.target.name]: event.target.value,
      }));
    },
    [setBoxOffer]
  );

  const handleActivationDateChange = useCallback(
    (value: number | null) => {
      setBoxOffer((prev) => ({ ...prev, activationDate: value }));
    },
    [setBoxOffer]
  );

  return (
    <div>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            label="Label *"
            name="label"
            value={boxOffer.label}
            onChange={handleInputChange}
          />
        </Col>
        <Col span={12}>
          <Row align="bottom" gutter={24}>
            <Col span={12}>
              <Label>Activation Date *</Label>
              <DateTimePicker
                setUnixTimeStamp={handleActivationDateChange}
                useDefaultTime={false}
              />
            </Col>
            <Col span={12}>
              {isInvalidDate && (
                <Error message="Date/time cannot be in the past!" />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <SimpleSelector
            label="Offer type *"
            options={['buy_and_rent', 'buy']}
            onChange={(offerType: BoxOfferType) =>
              setBoxOffer((prev) => ({ ...prev, offerType }))
            }
            value={boxOffer.offerType}
          />
        </Col>
        <Col span={12}>
          <IntegerInput
            label="Duration in months *"
            name="durationInMonth"
            value={boxOffer.durationInMonth}
            type="number"
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            label=" % of the services we have to pay"
            name="rentServicePercentage"
            value={boxOffer.rentServicePercentage}
            type="number"
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <IntegerInput
            label="Initial customer payment (in Euro) *"
            name="firstTrancheInEuro"
            value={boxOffer.firstTrancheInEuro}
            type="number"
            onChange={handleInputChange}
          />
        </Col>
        <Col span={12}>
          <IntegerInput
            label="Immediate commission to sales partner (in Euro) *"
            name="immediateProvisionInEuro"
            value={boxOffer.immediateProvisionInEuro}
            type="number"
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <IntegerInput
            label="Price per Box (in Euro) *"
            name="totalPriceInEuro"
            value={boxOffer.totalPriceInEuro}
            type="number"
            onChange={handleInputChange}
          />
        </Col>
        <Col span={12}>
          <IntegerInput
            label="Total Commission to sales partner (in Euro) *"
            name="totalProvisionInEuro"
            value={boxOffer.totalProvisionInEuro}
            type="number"
            onChange={handleInputChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CreateBoxOfferForm;
