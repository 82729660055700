import { IRole, IScope } from '@/features/lp-account-users';

export type { IRole, IScope };

export interface ICreateScope extends Pick<IScope, 'name' | 'description'> {}

export interface ICreateRole
  extends Pick<IRole, 'name' | 'description' | 'type'> {}

export const INITIAL_CreateRole: ICreateRole = {
  name: '',
  description: '',
  type: '',
};

export const INITIAL_CreateScope: ICreateScope = {
  name: '',
  description: '',
};
