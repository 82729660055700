import { useCallback } from 'react';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useDeleteShop: any = (shopId) => {
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpVillage');
  const successMsg = useToasts((state) => state.addItem);
  const addError = useError();

  const deleteShop = useCallback(async () => {
    const { error } = await makeRequest({
      path: `/admin/shops/${shopId}`,
      method: 'delete',
    });
    if (error) {
      addError(error);

      return { success: false };
    }
    successMsg(
      { msg: 'Shop was successfully deleted', type: MessageType.Success },
      'root',
      {}
    );

    return { success: true };
  }, [successMsg, addError, makeRequest, shopId]);

  return [{ loading }, deleteShop];
};
export default useDeleteShop;
