import React, { useCallback } from 'react';

import { Popover, Tag } from 'antd';
import styled from 'styled-components';

const TagWrapper = styled(Tag)`
  margin-bottom: 0.1rem;
`;

const generateTags = (data: any[], DataTag: any) => {
  let content = data.map((d) => DataTag(d.name, d.id));
  if (data.length > 3) {
    content = [
      ...content.slice(0, 3),
      <Popover
        key="etc"
        content={content}
        title="Full list of scopes"
        trigger="click"
      >
        <TagWrapper color="green" style={{ cursor: 'pointer' }}>
          ...
        </TagWrapper>
      </Popover>,
    ];
  }

  return content;
};

const FormatListTags = ({
  data,
  closable = false,
  onClose = () => {},
  role,
  fullList = false,
}: {
  data: any[];
  closable: boolean;
  onClose: Function;
  role?: any;
  fullList?: boolean;
}) => {
  const DataTag = useCallback(
    (name, id) => (
      <TagWrapper
        key={id}
        closable={closable}
        onClose={(e) => {
          e.preventDefault();
          role ? onClose(role, id) : onClose(id);
        }}
        color="green"
      >
        {name}
      </TagWrapper>
    ),
    [closable, onClose, role]
  );

  if (!data) {
    return <></>;
  }

  if (fullList) {
    return <>{data.map((d) => DataTag(d.name, d.id))}</>;
  }

  return <>{generateTags(data, DataTag)}</>;
};

export default FormatListTags;
