import React, { useEffect, useState } from 'react';

import { Grid, Paper } from '@lp/ds-next';
import { FormikProps, FormikValues, useFormik } from 'formik';
import { isEmpty } from 'lodash/fp';

import { ChangelogTable } from './Table';
import { ChangelogResponse, HeadCell, Order } from '../../../types/changelog';
import Filters from '../Filters';
import { TAPIService } from '@/configs/app';
import {
  TargetsKeys,
  useChangelog,
} from '@/features/changelog/api/getChangelog';
import { formatQueryParamsFilter } from '@/helpers/urlParams';

export const FilterModalContext = React.createContext({
  open: false,
  setOpen: (_open: boolean) => {},
});

export const ChangelogTableAndFilters = function <
  // API response type
  ModelChangelog,
  // Formik values for the filters
  ModelFormValues extends FormikValues | undefined = undefined,
  // Sortable fields for the API
  ModelSortableFields = undefined,
>({
  // Initial filters if any
  initialFilters,
  // Filters component
  filtersComponent,
  // Initial orderBy if any
  initialOrderBy,
  // Head cells for the table
  headCells,
  // Target for the API (URI and response type)
  target,
  // Which service for the API
  service,
  title,
}: {
  initialFilters?: ModelFormValues;
  filtersComponent?: (props: {
    formik: FormikProps<ModelFormValues>;
  }) => JSX.Element;
  initialOrderBy?: ModelSortableFields;
  headCells: HeadCell[];
  target: TargetsKeys;
  service: TAPIService;
  title: string;
}) {
  // Keep a copy of total changelogs because data.total_size is unset on every request
  const [totalChangelogs, setTotalChangelogs] = useState<number>(0);
  const [globalOpen, setGlobalOpen] = useState(false);
  const [pageSize] = useState<number>(10);
  const [pageOffset, setPageOffset] = useState<number>(0);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<ModelSortableFields | undefined>(
    initialOrderBy
  );
  const [searchFilters, setSearchFilters] = useState<
    ModelFormValues | undefined
  >(initialFilters);

  const [openFilterModal, setOpenFilterModal] = useState(false);

  // Initialize formik if any filters provided
  const formik = useFormik<any>({
    initialValues: initialFilters || {},
    onSubmit: (values) => {
      setPageOffset(0);
      setGlobalOpen(false);
      setSearchFilters(values);
    },
    onReset: () => {
      setOrderBy(initialOrderBy);
      setOrder('desc');
      setPageOffset(0);
      setSearchFilters(initialFilters);
      setOpenFilterModal(false);
    },
  });

  // Fetch the changelogs from API
  const { isPending, error, data, refetch } = useChangelog<ModelChangelog>({
    target,
    service,
    queryParams: {
      'list_request.page_size': pageSize,
      'list_request.page_offset': pageOffset,
      // Appends order info only if present
      ...(!!initialOrderBy
        ? { 'list_request.order_by': `${orderBy} ${order}` }
        : {}),
      ...(!isEmpty(initialFilters)
        ? { 'list_request.filter': formatQueryParamsFilter(searchFilters) }
        : {}),
    },
  });

  // Reset globalOpen on page change
  useEffect(() => {
    setGlobalOpen(false);
  }, [pageOffset]);

  useEffect(() => {
    if (data?.total_size) {
      setTotalChangelogs(parseInt(data?.total_size, 10));
    }
  }, [data]);

  const filters = filtersComponent && (
    <Filters filters={filtersComponent({ formik })} formik={formik} />
  );

  return (
    <FilterModalContext.Provider
      value={{ open: openFilterModal, setOpen: setOpenFilterModal }}
    >
      <Grid container columnSpacing="1rem" flexWrap="nowrap">
        {/* Hide filters if none */}
        {filtersComponent && (
          <Grid
            item
            laptop={2}
            sx={{
              minWidth: '15.625rem',
              display: { mobile: 'none', laptop: 'block' },
            }}
          >
            <Paper sx={{ padding: '1rem' }}>{filters}</Paper>
          </Grid>
        )}

        <Grid item mobile={12}>
          <ChangelogTable<ModelChangelog>
            isPending={isPending}
            error={error}
            data={data as ChangelogResponse<ModelChangelog>}
            headCells={headCells}
            order={order}
            orderBy={orderBy as string}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            pageSize={pageSize}
            pageOffset={pageOffset}
            setPageOffset={setPageOffset}
            title={title}
            filters={filters}
            refetch={refetch}
            totalChangelogs={
              totalChangelogs || parseInt(data?.total_size || '0', 10)
            }
            target={target}
            globalOpen={globalOpen}
            setGlobalOpen={setGlobalOpen}
          />
        </Grid>
      </Grid>
    </FilterModalContext.Provider>
  );
};
