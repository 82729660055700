import React, { useCallback, useMemo, useState } from 'react';

import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import {
  InfoWindow,
  Marker,
  MarkerClusterer,
  Polyline,
} from '@react-google-maps/api';
import styled from 'styled-components';

import GeolocationMarker from './GeolocationMarker';
import { GMInfoWindowContent } from './GMInfoWindowContent';
import { IShipmentMap } from './IShipmentMap';
import {
  getBounds,
  getPositions,
  reduceAddressLocation,
} from '../../helpers/mapHelpers';
import useShipmentMapEvents, {
  GEOLOCATION_INFO,
  GPS_ACQUIRE,
} from '../../hooks/useShipmentMapEvents';
import SmallBoxImg from '@/assets/images/box.png';
import ClusterImg from '@/assets/images/m1.svg';
import RecipientImg from '@/assets/images/map-pin-recipient.png';
import SenderImg from '@/assets/images/map-pin-sender.png';
import Map from '@/components/Map';
import { theme } from '@/config';

const SelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`;

const TextWrapper = styled.div`
  margin: 0 2rem;
  text-align: center;
  line-height: 1.6;
`;

const IconWrapper = styled.div<{ isDisabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  cursor: ${({ isDisabled }) => (isDisabled ? 'auto' : 'pointer')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
`;

const CLUSTERER_OPTIONS = {
  styles: [
    {
      url: ClusterImg,
      height: 40,
      width: 36,
      textColor: 'white',
      textSize: 15,
    },
  ],
};

const ShipmentMap = ({ trackingData, shipment }: IShipmentMap) => {
  const { boxPos, senderPos, recipientPos } = getPositions({
    trackingData,
    shipment,
  });

  const { events, totalNrOfPages, setCurrentPage, currentPage, timeRange } =
    useShipmentMapEvents(shipment);

  const eventPositions =
    events?.map((event) => ({
      lat: event.coordinate.latitude,
      lng: event.coordinate.longitude,
    })) || [];

  const geoLocationEvents = useMemo(
    () => events?.filter((e) => e.type === GEOLOCATION_INFO) || [],
    [events]
  );
  const gpsAquireEvents = useMemo(
    () => events?.filter((e) => e.type === GPS_ACQUIRE) || [],
    [events]
  );

  const clustered = useCallback(
    (clusterer): any => {
      const geoLocationMarkers = geoLocationEvents.map((event) => (
        <GeolocationMarker event={event} key={event.id} clusterer={clusterer} />
      ));
      const gpsAquireMarkers = gpsAquireEvents.map((event) => (
        <GeolocationMarker event={event} key={event.id} clusterer={clusterer} />
      ));

      return [...geoLocationMarkers, gpsAquireMarkers];
    },
    [geoLocationEvents, gpsAquireEvents]
  );

  // Compute the path line only for the current page events
  const polylinePath = useMemo(
    () =>
      geoLocationEvents
        // Filter out events without coordinates
        .filter(
          ({ coordinate: { latitude, longitude } }) => latitude && longitude
        )
        .map((event) => ({
          lat: event.coordinate.latitude,
          lng: event.coordinate.longitude,
        })),
    [geoLocationEvents]
  );

  const bounds = getBounds([
    boxPos,
    senderPos,
    recipientPos,
    ...eventPositions,
  ]);
  const [infoWindows, setInfowindows] = useState({
    sender: false,
    recipient: false,
  });

  const handleHover = useCallback(
    (type: 'sender' | 'recipient', value: boolean) => {
      setInfowindows((prev) => ({ ...prev, [type]: value }));
    },
    []
  );

  return (
    <>
      {events && events.length > 0 && timeRange.to && timeRange.from && (
        <SelectorWrapper>
          <IconWrapper
            isDisabled={currentPage === totalNrOfPages}
            onClick={() => setCurrentPage((prev) => prev + 1)}
          >
            <LeftOutlined />
            Earlier events
          </IconWrapper>
          <TextWrapper>
            Displaying THE BOX geolocation events <br />
            between <strong>{timeRange.from}</strong> and{' '}
            <strong>{timeRange.to}</strong>
          </TextWrapper>
          <IconWrapper
            isDisabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => prev - 1)}
          >
            Later events
            <RightOutlined />
          </IconWrapper>
        </SelectorWrapper>
      )}
      <Map bounds={bounds} height="50rem" width="75%">
        <>
          {boxPos && (
            <Marker
              icon={SmallBoxImg}
              position={boxPos}
              data-testid="boxMarker"
            />
          )}
          {senderPos && (
            <Marker
              data-testid="senderMarker"
              position={senderPos}
              icon={SenderImg}
              onMouseOver={() => handleHover('sender', true)}
              onMouseOut={() => handleHover('sender', false)}
            >
              {infoWindows.sender && (
                <InfoWindow options={{ disableAutoPan: true }}>
                  <GMInfoWindowContent
                    header="Sender"
                    content={reduceAddressLocation(shipment.sender.address)}
                  />
                </InfoWindow>
              )}
            </Marker>
          )}
          {recipientPos && (
            <Marker
              data-testid="recipientMarker"
              position={recipientPos}
              icon={RecipientImg}
              onMouseOver={() => handleHover('recipient', true)}
              onMouseOut={() => handleHover('recipient', false)}
            >
              {infoWindows.recipient && (
                <InfoWindow options={{ disableAutoPan: true }}>
                  <GMInfoWindowContent
                    header="Recipient"
                    content={reduceAddressLocation(shipment.recipient.address)}
                  />
                </InfoWindow>
              )}
            </Marker>
          )}
          <MarkerClusterer options={CLUSTERER_OPTIONS}>
            {clustered}
          </MarkerClusterer>
          <Polyline
            path={polylinePath}
            options={{
              strokeColor: theme.colors.brand,
            }}
          />
        </>
      </Map>
    </>
  );
};

export default ShipmentMap;
