export const Languages = {
  english: 'PRODUCT_DEFAULT_LANGUAGE_EN',
  french: 'PRODUCT_DEFAULT_LANGUAGE_FR',
  german: 'PRODUCT_DEFAULT_LANGUAGE_DE',
} as const;

export type LanguagesKeys = (typeof Languages)[keyof typeof Languages];

export interface IPartnerV3 {
  is_archived: boolean;
  deleted: boolean;
  created_at: string;
  updated_at: string;
  created_by: string;
  description: string;
  id: string;
  phone_number: string;
  thumbnail: string;
  type: string;
  is_internal: boolean;
  demo_enabled: boolean;
  secursus_enabled?: boolean;
  business_information: IBusinessInformationV3;
  max_nb_of_shipments: number;
  nb_of_shipments: number;
  remote_control_enabled?: boolean;
  fw_update_enabled?: boolean;
  flight_mode_enabled?: boolean;
  partner_id_card: IPartnerIdCardV3;
  sms_enabled: boolean;
  product_default_language: LanguagesKeys;
  code_opening_enabled: boolean;
  carriers?: string[];
  lp_account_id?: string;
}

export interface ICreatePartnerV3
  extends Pick<
    IPartnerV3,
    | 'phone_number'
    | 'description'
    | 'thumbnail'
    | 'type'
    | 'is_internal'
    | 'business_information'
    | 'max_nb_of_shipments'
    | 'nb_of_shipments'
    | 'partner_id_card'
    | 'lp_account_id'
  > {}

export type IPartnerV3Update = Partial<
  Omit<
    IPartnerV3,
    | 'created_at'
    | 'updated_at'
    | 'created_by'
    | 'id'
    | 'type'
    | 'secursus_enabled'
  >
>;

export const editablePath = [
  'description',
  'phone_number',
  'demo_enabled',
  'is_internal',
  'business_information',
  'max_nb_of_shipments',
  'fw_update_enabled',
  'remote_control_enabled',
  'flight_mode_enabled',
  'partner_id_card',
  'sms_enabled',
  'product_default_language',
  'code_opening_enabled',
] as const;

export const INITIAL_PartnerV3: IPartnerV3 = {
  is_archived: false,
  // Local field to handle deletion
  deleted: false,
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  created_by: '',
  description: '',
  id: '',
  phone_number: '',
  thumbnail: '',
  type: '',
  demo_enabled: false,
  is_internal: false,
  business_information: {
    status: '',
    type: '',
  },
  max_nb_of_shipments: 0,
  nb_of_shipments: 0,
  remote_control_enabled: false,
  fw_update_enabled: false,
  flight_mode_enabled: false,
  sms_enabled: false,
  product_default_language: Languages.english,
  code_opening_enabled: false,
  partner_id_card: {
    client_id: '',
    client_name: '',
    country_code: '',
    email: '',
    erp_synchronized_at: undefined,
    internal_sales_name: '',
    company_id: '',
    vat_number: '',
    website: '',
  },
  lp_account_id: '',
};

export const INITIAL_CreatePartnerV3: ICreatePartnerV3 = {
  phone_number: '',
  description: '',
  thumbnail: '',
  type: 'ORG', // The field is require but not use after in project for the moment
  is_internal: false,
  business_information: {
    status: '',
    type: '',
  },
  max_nb_of_shipments: 0,
  nb_of_shipments: 0,
  partner_id_card: {
    client_id: '',
    client_name: '',
    country_code: '',
    email: '',
    internal_sales_name: '',
    company_id: '',
    vat_number: '',
    website: '',
  },
  lp_account_id: '',
};

export enum PARTNER_BUSINESS_TYPE {
  TEST = 'PARTNER_BUSINESS_TYPE_TEST',
  PRO = 'PARTNER_BUSINESS_TYPE_PRO',
  PERSONAL = 'PARTNER_BUSINESS_TYPE_PERSONAL',
}

export enum PARTNER_BUSINESS_STATUS {
  PENDING = 'PARTNER_BUSINESS_STATUS_PENDING',
  BLOCKED = 'PARTNER_BUSINESS_STATUS_BLOCKED',
  VALIDATED = 'PARTNER_BUSINESS_STATUS_VALIDATED',
}

export interface IBusinessInformationV3 {
  type: PARTNER_BUSINESS_TYPE | '';
  status: PARTNER_BUSINESS_STATUS | '';
}

export interface ERPContract {
  business_contractor: string;
  contract_id: string;
  created_at: string;
  end_at: string;
  erp_sync_at: string;
  partner_id: string;
}

export interface IPartnerIdCardV3 {
  client_id?: string;
  client_name: string;
  country_code: string;
  email?: string;
  erp_synchronized_at?: string;
  internal_sales_name?: string;
  company_id?: string;
  vat_number?: string;
  website?: string;
}
