import pick from 'lodash/pick';

const getActiveOffers = async ({ makeRequest }) => {
  const { data } = await makeRequest({
    path: `/admin/offers?onlyActive=true`,
    method: 'get',
  });

  if (data && data.items) {
    return data.items.map((offer) =>
      pick(offer, [
        'id',
        'label',
        'multiplierInCents',
        'durationInMonth',
        'offerType',
      ])
    );
  }
};

export default getActiveOffers;
