import React, { useCallback } from 'react';

import { Col, Row } from 'antd';
import get from 'lodash/get';
import styled from 'styled-components';

import PaymentMethodSelector from '../../../features/sharing-angels/components/PaymentMethodSelector';
import OfferSelector from '../components/OfferSelector';
import CurrencySelector from '@/components/forms/CurrencySelector';
import DateTimePicker from '@/components/forms/DateTimePicker';
import Label from '@/components/forms/Label';
import TextInput from '@/components/forms/TextInput';
import useInputFocus from '@/hooks/useInputFocus';
import Toggle from '@/shared/components/Toggle';

const LabelWrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledToggle = styled(Toggle)`
  margin-left: 1rem;
`;

const LABELS = {
  totalAmountInCents: 'Contribution Amount',
  currency: 'Currency',
  offerType: 'Offer Type',
  inviteCode: 'Invite Code',
  paymentMethod: 'Payment Method',
  paymentUniqueId: 'Payment Unique Id',
};

const AddContributionForm = ({
  contribution,
  setContribution,
  activeOffers,
  setUnixTimeStamp,
  setBackdating,
  backdating,
}) => {
  const [ref] = useInputFocus({ initialFocus: true });
  const getLabel = useCallback(
    (name: string, required: boolean = false) =>
      required ? `${get(LABELS, name, '')} *` : get(LABELS, name, ''),
    []
  );
  const handleInputChange = useCallback(
    (event) => {
      setContribution({
        ...contribution,
        [event.target.name]: event.target.value,
      });
    },
    [contribution, setContribution]
  );

  const handleOfferChange = useCallback(
    (option) => {
      setContribution({
        ...contribution,
        offerId: option.id,
      });
    },
    [contribution, setContribution]
  );
  const handleCurrencyChange = useCallback(
    (option) => {
      setContribution({
        ...contribution,
        currency: option.value,
      });
    },
    [contribution, setContribution]
  );

  const handleToggleChange = useCallback(() => {
    setBackdating((prev) => !prev);
  }, [setBackdating]);

  const handlePaymentMethodChange = useCallback(
    (option) => {
      setContribution({
        ...contribution,
        paymentMethod: option.value,
      });
    },
    [contribution, setContribution]
  );

  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            ref={ref}
            label={getLabel('totalAmountInCents', true)}
            name="totalAmountInCents"
            onChange={handleInputChange}
            value={contribution.totalAmountInCents}
            disabled={false}
            type="number"
          />
        </Col>
        <Col span={12} data-testid="currencySelector">
          <CurrencySelector
            label={getLabel('currency', true)}
            onChange={handleCurrencyChange}
            currency={contribution.currency}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            label={getLabel('inviteCode', false)}
            name="inviteCode"
            onChange={handleInputChange}
            value={contribution.inviteCode}
            disabled={false}
          />
        </Col>
        <Col span={12} data-testid="offerSelector">
          <OfferSelector
            label={getLabel('offerType', true)}
            offers={activeOffers}
            onChange={handleOfferChange}
            offerId={contribution.offerId}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12} data-testid="dateSelector">
          <LabelWrapper>
            <Label>Backdate Contribution</Label>
            <StyledToggle checked={backdating} toggle={handleToggleChange} />
          </LabelWrapper>
          {backdating && (
            <DateTimePicker
              setUnixTimeStamp={setUnixTimeStamp}
              disabledFutureDate={true}
            />
          )}
        </Col>
        <Col span={12} data-testid="paymentMethodSelector">
          <PaymentMethodSelector
            label={getLabel('paymentMethod', true)}
            onChange={handlePaymentMethodChange}
            paymentMethod={contribution.paymentMethod}
          />
        </Col>
      </Row>
    </>
  );
};

export default AddContributionForm;
