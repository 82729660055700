import React from 'react';

import get from 'lodash/get';
import styled, { ThemeProps } from 'styled-components';

export interface ITextInput {
  name: string;
  error?: any;
  onChange?: React.ChangeEventHandler<EventTarget>;
  onBlur?: React.FocusEventHandler<EventTarget>;
  type?: string;
  label?: string;
  value?: string | number;
  defaultValue?: string | number;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  autoComplete?: string;
  mb?: string;
  mr?: string;
  width?: string;
  maxLength?: number;
  icon?: React.ReactNode;
  theme?: any;
  isTouched?: boolean;
  ff?: string;
  currency?: string;
}

const StyledInputContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Add = styled.div`
  content: '';
  display: block;
  position: absolute;
  right: 0.5rem;
  transform: translateY(-50%);
  top: 50%;
  display: inline-flex;
  padding-left: 0.4rem;
  align-items: center;
`;

const Sign = styled.span`
  line-height: 0;
`;

const Styled = styled.input<ITextInput & ThemeProps<any>>`
  font-family: ${({ ff, theme }) =>
    ff ||
    get(
      theme,
      'fontStyles.adminPanel.fontFamily',
      theme.fontStyles.adminPanel.fontFamily
    )};
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  margin-right: ${({ mr }) => mr || 0};
  padding: 0.6rem;
  box-sizing: border-box;
  font-size: 0.9rem;
  border: none;
  background-color: ${({ theme }) =>
    get(theme, 'colors.adminPanel.white', theme.colors.adminPanel.white)};
  box-shadow: ${({ error, isTouched, theme }) =>
    error && isTouched === true
      ? `inset 0px 0px 0px 1px ${get(
          theme,
          'colors.error',
          theme.colors.adminPanel.error
        )}`
      : `inset 0px -1px 0px 0px ${get(
          theme,
          'colors.brand',
          theme.colors.adminPanel.brand
        )}`};
  outline: none;
  border-radius: 0;
  appearance: none;
  transition: ${({ theme }) =>
    get(
      theme,
      'transitions.adminPanel.short',
      theme.transitions.adminPanel.short
    )('background-color')};
  &:focus {
    background-color: ${({ theme }) =>
      get(theme, 'adminPanel.colors.grey', theme.colors.adminPanel.grey)};
    box-shadow: ${({ theme }) =>
      get(theme, 'shadows.adminPanel.big', theme.shadows.adminPanel.big)};
    transition: ${({ theme }) =>
      get(
        theme,
        'transitions.short',
        theme.transitions.adminPanel.short
      )('background-color')};
  }
  &:disabled {
    color: ${({ theme }) =>
      get(
        theme,
        'colors.adminPanel.darkergrey',
        theme.colors.adminPanel.darkergrey
      )};
  }
`;

const TextInput = ({ icon, ...rest }: ITextInput) => {
  const { value, currency, ...props } = rest;
  const val =
    currency && value
      ? value
          .toString()
          .replace(/[ ]*,[ ]*|[ ]+/g, '')
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      : value;

  return (
    <StyledInputContainer>
      <Styled {...props} value={val} />
      <Add>
        {currency && <Sign>{currency}</Sign>}
        {icon && (
          <>
            <span style={{ width: '0.5rem' }} /> {icon}
          </>
        )}
      </Add>
    </StyledInputContainer>
  );
};

export default TextInput;
