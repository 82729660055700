import React, { useEffect, useState } from 'react';

import { IBANForm, Text } from '@livingpackets/design-system-react';
import styled from 'styled-components';

import useLPAccountWallet, {
  IWallet,
  TRANSACTION_METHOD_STATE,
  TRANSACTION_METHOD_TYPE,
} from '../hooks/useLPAccountWallet';

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
`;

const TagWrapper = styled.span`
  display: inline-box;
  text-align: center;
  padding: 10px 10px;
  background: #ff5050;
  border-radius: 20px;
  margin-bottom: 1em;
  color: #ffffff;
  font-weight: bold;
`;

const LPAccountBankInfo = ({ id }: { id: string }) => {
  const { getWallet } = useLPAccountWallet();
  const [wallet, setWallet] = useState<IWallet | undefined>();

  useEffect(() => {
    const initWallet = async () => {
      setWallet(await getWallet(id));
    };

    initWallet();
  }, [getWallet, id]);

  return (
    <div style={{ width: '700px' }}>
      {wallet?.transaction_methods
        .filter((transactionMethod) => !transactionMethod.deleted_at)
        .map((transactionMethod, index) => {
          if (
            transactionMethod.type ===
            TRANSACTION_METHOD_TYPE.TRANSACTION_METHOD_TYPE_WIRE
          ) {
            if (transactionMethod.wire.iban) {
              return (
                <React.Fragment key={`wire-iban-${index}`}>
                  <TitleWrapper>
                    <Text fontStyle="titleL">IBAN</Text>
                    {(transactionMethod.state as string) ===
                      'TRANSACTION_METHOD_STATE_NOT_VALID' && (
                      <TagWrapper>
                        {TRANSACTION_METHOD_STATE[
                          transactionMethod.state
                        ].toUpperCase()}
                      </TagWrapper>
                    )}
                  </TitleWrapper>
                  <IBANForm
                    ibanData={{
                      bankName: transactionMethod.wire.bank_title,
                      beneficiaryName: transactionMethod.wire.beneficiary_name,
                      bicCode: transactionMethod.wire.bic,
                      ibanCode: transactionMethod.wire.iban,
                    }}
                    // TODO: remove after next design system release
                    // eslint-disable-next-line react/no-children-prop
                    children={undefined}
                    isDisabled={true}
                    id=""
                  />
                </React.Fragment>
              );
            }
          }

          return <></>;
        })}
    </div>
  );
};

export default LPAccountBankInfo;
