import {
  INITIAL_ModifiedBoxNote,
  TBoxNote,
  TModifiedBoxNote,
} from '../../models/boxNote';
import { getCurrentPage } from '@/helpers/antdTable';
import dates from '@/helpers/dates';
import { ServerTableState } from '@/hooks/useServerSideTable';

export type TBoxNotesState = ServerTableState<TModifiedBoxNote[]>;
export type TBoxNotesActionTypes =
  | 'updatePagination'
  | 'updateRowDataFromResponse'
  | 'deleteNote'
  | 'updateSingleNote';

export type TBoxNotesActions = {
  type: TBoxNotesActionTypes;
  args: any;
};

const modifyBoxNote = (noteElement: TBoxNote): TModifiedBoxNote => ({
  ...noteElement,
  parsedCreatedAt: dates.parseDate(noteElement.createdAt || 0),
  parsedUpdatedAt: dates.parseDate(noteElement.updatedAt || 0),
  isDirty: false,
});

const updateDataFromResponse = (
  res: TBoxNote[],
  rowData: TModifiedBoxNote[]
): TModifiedBoxNote[] =>
  res.map((el) => {
    const correlatedLocalItem = rowData.find((local) => el.id === local.id);
    if (correlatedLocalItem?.isDirty) {
      return {
        ...modifyBoxNote(el),
        note: correlatedLocalItem.note,
        isDirty: true,
      };
    }

    return modifyBoxNote(el);
  });

const boxNotesReducer = (
  state: TBoxNotesState,
  { type, args }: TBoxNotesActions
) => {
  switch (type) {
    case 'updatePagination':
      return {
        ...state,
        pagination: { ...state.pagination, ...args },
      };
    case 'updateRowDataFromResponse':
      const isFirstPage = getCurrentPage(args.pageSize, args.offset) === 1;
      let firstStateItem = state.rowData[0];
      if (!firstStateItem?.isDirty || firstStateItem?.id !== '') {
        firstStateItem = INITIAL_ModifiedBoxNote;
      }

      const newRowData = isFirstPage
        ? [firstStateItem, ...updateDataFromResponse(args.items, state.rowData)]
        : updateDataFromResponse(args.items, state.rowData);

      return {
        ...state,
        rowData: newRowData,
        pagination: {
          ...state.pagination,
          total: args.total,
          offset: args.offset,
          pageSize: args.pageSize,
        },
      };
    case 'updateSingleNote':
      return {
        ...state,
        rowData: state.rowData.map((item) => {
          if (item.id !== args.id) {
            return item;
          }

          return { ...item, ...args };
        }),
      };
    case 'deleteNote':
      return {
        ...state,
        rowData: state.rowData.filter((item) => {
          if (item.id !== args.id) {
            return true;
          }

          return false;
        }),
      };
    default:
      return state;
  }
};

export default boxNotesReducer;
