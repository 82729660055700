import { ComponentType, FormEventHandler } from 'react';

import styled from 'styled-components';

export interface StyledInputProps {
  name?: string;
  description?: string;
  error?: string;
  onChange?: FormEventHandler<HTMLInputElement>;
  onBlur?: FormEventHandler<HTMLInputElement>;
  type?: string;
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  isTouched?: boolean;
  placeholder?: string;
  value?: string;
  autoComplete?: string;
  width?: string;
  as?: keyof JSX.IntrinsicElements | ComponentType<any>;
}

const StyledInput = styled.input<StyledInputProps>`
  display: block;
  width: 100%;
  margin-bottom: 2rem;
  margin-top: 0.25rem;
  padding: 0.4rem;
  box-sizing: border-box;
  font-size: 0.9rem;
  border: none;
  background-color: ${(props) =>
    props.disabled || props.placeholder
      ? props.theme.colors.white
      : props.theme.colors.adminPanel.lightgreen};
  box-shadow: ${(props) =>
    props.error
      ? `inset 0px 0px 0px 1px ${props.theme.colors.adminPanel.error}`
      : `inset 0px -2px 0px 0px ${props.theme.colors.adminPanel.brand}`};
  font-family: TTNormsPro-Regular;
  outline: none;
  transition: ${(props) =>
    props.theme.transitions.adminPanel.short('background-color')};
  &:focus {
    background-color: ${(props) => props.theme.colors.adminPanel.grey};
    transition: ${(props) =>
      props.theme.transitions.adminPanel.short('background-color')};
  }
`;

export default StyledInput;
