import { useCallback } from 'react';

import omit from 'lodash/omit';

import useBoxOffersStore from './useBoxOffersStore';
import useEditBoxOffer from './useEditBoxOffer';
import boxOffersPostProcessor from '../helpers/boxOfferPostProcessor';
import { CreateBoxOffer } from '../models/boxContract';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import { storeSelector } from '@/helpers/paginatedStoreHelpers';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useCreateBoxOffer = () => {
  const [{ loading }, makeRequest] =
    useImperativeRequestWrapper('serviceLpAccount');
  const [, makeRefetch] = useImperativeRequestWrapper('serviceLpAccount');
  const addError = useError();
  const { dispatch, order, sortBy, offset, pageSize, filters } =
    useBoxOffersStore(storeSelector);
  const addSuccessMsg = useToasts((state) => state.addItem);

  const { activateOffer, activateLoading } = useEditBoxOffer();

  const createBoxOffer = useCallback(
    async (body: CreateBoxOffer) => {
      const { data, error } = await makeRequest({
        path: '/admin/box-contract-offers',
        method: 'post',
        body: {
          ...omit(body, 'paymentReceivedAt'),
          durationInMonth: parseInt(body.durationInMonth),
          firstTrancheInEuro: parseInt(body.firstTrancheInEuro),
          immediateProvisionInEuro: parseInt(body.immediateProvisionInEuro),
          totalPriceInEuro: parseInt(body.totalPriceInEuro),
          totalProvisionInEuro: parseInt(body.totalProvisionInEuro),
          rentServicePercentage: parseFloat(body.rentServicePercentage),
        },
      });
      if (error || !data.id) {
        addError(error || 'Something went wrong!');

        return { success: false };
      }
      addSuccessMsg(
        {
          msg: 'Successfully created box contract offer',
          type: MessageType.Success,
        },
        'root',
        {}
      );
      const { success } = await activateOffer(
        data.id,
        body.paymentReceivedAt || 0
      );
      if (!success) {
        addError(
          'Unable to set activation date for the added box contract offer!'
        );
      }
      await getPaginatedTableData({
        params: {
          path: '/admin/box-contract-offers',
          method: 'get',
        },
        reducer: boxOffersPostProcessor,
        dispatch,
        queryParams: {
          pageSize: pageSize,
          offset: offset,
          sortBy: sortBy,
          order: order,
          ...filters,
        },
        queryParamsOptions: { skipEmptyString: true, skipNull: true },
        addError,
        makeRequest: makeRefetch,
      });

      return { success: true };
    },
    [
      makeRequest,
      dispatch,
      offset,
      order,
      sortBy,
      pageSize,
      filters,
      makeRefetch,
      addError,
      addSuccessMsg,
      activateOffer,
    ]
  );

  return { createBoxOffer, loading: loading || activateLoading };
};

export default useCreateBoxOffer;
