import { useCallback } from 'react';

import { IServiceLevel } from '../models/serviceLevel';
import { useApiClient } from '@/helpers/ApiClients';
import { useFetchResource } from '@/hooks/useFetchResource';

const useGetServiceLevel = () => {
  const device = useApiClient('deviceV3');

  const getServiceLevel = useCallback(
    (id: number) =>
      device
        .get<IServiceLevel>(`/service_levels/${id}`)
        .then((response) => response.data),
    [device]
  );

  return getServiceLevel;
};

const useServiceLevel = (id: number) => {
  const getServiceLevel = useGetServiceLevel();

  const fetchFunction = useCallback(
    () => getServiceLevel(id),
    [getServiceLevel, id]
  );

  return useFetchResource(fetchFunction);
};

export default useServiceLevel;
