import { useCallback } from 'react';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useEditBoxOffer = () => {
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);

  const [{ loading: activateLoading }, makeActivateRequest] =
    useImperativeRequestWrapper('serviceLpAccount');
  const [{ loading: deactivateLoading }, makeDeactivateRequest] =
    useImperativeRequestWrapper('serviceLpAccount');

  const activateOffer = useCallback(
    async (offerId: string, date: number) => {
      const { data, error } = await makeActivateRequest({
        path: `/admin/box-contract-offers/${offerId}/activate`,
        method: 'patch',
        body: {
          activationDate: date,
        },
      });

      if (error) {
        addError(error);

        return { success: false };
      }
      addSuccessMsg(
        { msg: 'Successfully set activation date!', type: MessageType.Success },
        'root',
        {}
      );

      return { offer: data, success: true };
    },
    [makeActivateRequest, addError, addSuccessMsg]
  );
  const deactivateOffer = useCallback(
    async (offerId: string, date: number) => {
      const { data, error } = await makeDeactivateRequest({
        path: `/admin/box-contract-offers/${offerId}/deactivate`,
        method: 'patch',
        body: {
          deactivationDate: date,
        },
      });

      if (error) {
        addError(error);

        return { success: false };
      }
      addSuccessMsg(
        {
          msg: 'Successfully set deactivation date!',
          type: MessageType.Success,
        },
        'root',
        {}
      );

      return { offer: data, success: true };
    },
    [makeDeactivateRequest, addError, addSuccessMsg]
  );

  return { activateLoading, deactivateLoading, activateOffer, deactivateOffer };
};

export default useEditBoxOffer;
