import { object, string, array, number } from 'yup';

const withPaginationSchema = (schema) =>
  object().shape({
    pageSize: number().required(),
    offset: number().required(),
    sortBy: string().required(),
    order: string().matches(/(ASC|DESC)/),
    count: number().required(),
    total: number().required(),
    items: array().of(schema),
  });

export default withPaginationSchema;
