import React, { useCallback } from 'react';

import { Col, Row } from 'antd';

import TextareaInput from '@/components/forms/TextareaInput';
import TextInput from '@/components/forms/TextInput';
import useInputFocus from '@/hooks/useInputFocus';

const ScopeForm = ({ scope, setScope }) => {
  const [ref] = useInputFocus({ initialFocus: true });
  const { name, description } = scope;

  const handleTextChange = useCallback(
    (e) => {
      if (setScope) {
        setScope({ ...scope, [e.target.name]: e.target.value });
      }
    },
    [setScope, scope]
  );

  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            ref={ref}
            onChange={handleTextChange}
            label="Name *"
            name="name"
            value={name}
          />
        </Col>
        <Col span={12}>
          <TextareaInput
            onChange={handleTextChange}
            label="Description *"
            name="description"
            value={description}
          />
        </Col>
      </Row>
    </>
  );
};

export default ScopeForm;
