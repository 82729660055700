import React, { useContext, useEffect, useState } from 'react';

import createAuth0Client, { Auth0ClientOptions } from '@auth0/auth0-spa-js';

const DEFAULT_REDIRECT_CALLBACK = (_) =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext<any>(null);
export const useAuth0 = () => useContext(Auth0Context);
const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const initAuth0 = async () => {
      try {
        const auth0Cypress = JSON.parse(
          window.localStorage.getItem('auth0Cypress') || '{}'
        );

        if (
          window.Cypress !== undefined &&
          Object.keys(auth0Cypress).length !== 0
        ) {
          if (window.localStorage.getItem('auth0Cypress')) {
            setAuth0(auth0Cypress);
            setIsAuthenticated(true);
            setUser(auth0Cypress.body.decodedToken.user);
            setLoading(false);
          }
        } else {
          const auth0FromHook: any = await createAuth0Client({
            ...initOptions,
          } as Auth0ClientOptions);
          setAuth0(auth0FromHook);

          if (
            window.location.search.includes('code=') &&
            window.location.search.includes('state=')
          ) {
            const { appState } = await auth0FromHook.handleRedirectCallback();
            onRedirectCallback(appState);
          }

          const isAuthenticated = await auth0FromHook.isAuthenticated();

          setIsAuthenticated(isAuthenticated);

          if (isAuthenticated) {
            const user = await auth0FromHook.getUser();
            setUser(user);
          }
        }
        setLoading(false);
      } catch (err: any) {
        setError(err);
        setLoading(false);
      }
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const getToken = async () => {
    const { audience, scope } = initOptions;
    try {
      return await auth0Client.getTokenSilently({
        audience,
        scope,
      });
    } catch (err) {
      try {
        return await auth0Client.getTokenWithPopup({
          audience,
          scope,
        });
      } catch (err) {
        setError(error);

        return '';
      }
    }
  };

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error: any) {
      setError(error);
    } finally {
      setPopupOpen(false);
    }
    try {
      const user = await auth0Client.getUser();
      setIsAuthenticated(true);
      setUser(user);
    } catch (error: any) {
      setError(error);
    }
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    try {
      await auth0Client.handleRedirectCallback();
      const user = await auth0Client.getUser();
      setLoading(false);
      setIsAuthenticated(true);
      setUser(user);
    } catch (err: any) {
      setLoading(false);
      setError(err);
    }
  };

  const getUser = async (...p) => {
    try {
      return await auth0Client.getUser(...p);
    } catch (error: any) {
      setError(error);
    }
  };

  const getIdTokenClaims = async (...p) => {
    try {
      return await auth0Client.getIdTokenClaims(...p);
    } catch (error: any) {
      setError(error);
    }
  };

  const loginWithRedirect = async (...p) => {
    try {
      return await auth0Client.loginWithRedirect(...p);
    } catch (error: any) {
      setError(error);
    }
  };

  const logout = async (...p) => await auth0Client.logout(...p);

  return (
    <Auth0Context.Provider
      value={{
        error,
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getToken,
        getUser,
        getIdTokenClaims,
        loginWithRedirect,
        logout,
        setError,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export default Auth0Provider;
