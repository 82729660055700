import React, { useCallback, useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { Anchor, Row, Descriptions, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';

import ImageThumbnail from './ImageThumbnail';
import useQualityCheck from '../hooks/useQualityCheck';
import {
  INITIAL_QualityChecklist,
  IQualityChecklist,
  ZonesNames,
} from '../models/qualityCheck';
import PageTitle from '@/components/layout/PageTitle';
import PillButton from '@/components/PillButton';
import H2 from '@/components/text/H2';

const { Link } = Anchor;

const AnchorWrapper = styled.div`
  margin: 1.5rem 0;
`;

const TopWrapper = styled.div`
  width: 3rem;
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: 0.5rem;
  position: fixed;
  bottom: 0;
  left: 8.5rem;
  .ant-anchor-ink {
    display: none;
  }
`;

const QualityCheckDetailContent = ({ theme }) => {
  const { id, qcId } = useParams<{ id: string; qcId: string }>();
  const { getQualityCheck } = useQualityCheck(id!);

  const [checklist, setChecklist] = useState<IQualityChecklist>(
    INITIAL_QualityChecklist
  );

  const statusColor = useCallback(
    (status: string) => ({
      backgroundColor:
        status === 'OK' ? theme.colors.lightgreen : theme.colors.lightRed,
    }),
    [theme]
  );

  useEffect(() => {
    getQualityCheck(qcId!).then(({ success, data }) => {
      success && setChecklist(data);
    });
  }, [qcId, getQualityCheck]);

  return (
    <Grid id="top" container justifyContent="center" spacing={3}>
      <Grid item xs={10}>
        <PageTitle title="QUALITY CHECK DETAILS" />
      </Grid>

      <Grid item xs={10}>
        <Row justify="end">
          <PillButton>
            <RouterLink to={`/products/${checklist.boxId}`}>
              Go to THE BOX
            </RouterLink>
          </PillButton>
        </Row>

        <Descriptions title={<H2>General Data</H2>} bordered column={2}>
          <Descriptions.Item label="Checklist-ID">
            <Typography.Text copyable={true}>
              {checklist.id || ''}
            </Typography.Text>
          </Descriptions.Item>

          <Descriptions.Item label="Created By">
            {checklist.createdBy.email}
          </Descriptions.Item>

          <Descriptions.Item label="Created At">
            {checklist.parsedCreatedAt}
          </Descriptions.Item>

          <Descriptions.Item label="Updated At">
            {checklist.parsedUpdatedAt}
          </Descriptions.Item>

          <Descriptions.Item label="Box-ID">
            {checklist.boxId}
          </Descriptions.Item>

          <Descriptions.Item label="Shipment-ID">
            {checklist.shipmentId}
          </Descriptions.Item>
        </Descriptions>
        <AnchorWrapper>
          <H2>Go to</H2>
          <Anchor affix={false}>
            {Object.keys(ZonesNames).map((z) => (
              <Link key={z} href={`#${z}`} title={ZonesNames[z]} />
            ))}
          </Anchor>
        </AnchorWrapper>
        {Object.keys(ZonesNames).map((z) => (
          <div key={z}>
            <H2 style={{ paddingTop: '1.5rem' }} id={z}>
              {ZonesNames[z]}
            </H2>
            {checklist.items
              .filter((item) => item.qualityCheckType.zone === z)
              .map((item) => (
                <Descriptions
                  bordered
                  key={item.id}
                  style={{ marginTop: '1.5rem' }}
                  column={2}
                >
                  <Descriptions.Item
                    span={2}
                    style={{ textTransform: 'uppercase', fontWeight: 'bold' }}
                    label="Name"
                  >
                    {item.qualityCheckType.name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Expected Level">
                    {item.qualityCheckType.expectedLevel}
                  </Descriptions.Item>
                  <Descriptions.Item>
                    <ImageThumbnail url={item.qualityCheckType.image} />
                  </Descriptions.Item>
                  <Descriptions.Item label="Comment" span={2}>
                    {item.comment}
                  </Descriptions.Item>
                  <Descriptions.Item label="Images" span={2}>
                    {item.images.map((url) => (
                      <ImageThumbnail key={url} url={url} />
                    ))}
                  </Descriptions.Item>
                  <Descriptions.Item
                    style={statusColor(item.status)}
                    label="Status"
                    span={2}
                  >
                    {item.status}
                  </Descriptions.Item>
                </Descriptions>
              ))}
          </div>
        ))}
        <TopWrapper>
          <Anchor affix={false}>
            <Link href="#top" title="Top" />
          </Anchor>
        </TopWrapper>
      </Grid>
    </Grid>
  );
};

export default withTheme(QualityCheckDetailContent);
