import React from 'react';

import { Col, Row } from 'antd';
import styled from 'styled-components';

import { REWARD_STATUS_LABEL } from '../../hooks/saContributions/useReward';
import TextInput from '@/components/forms/TextInput';
import PillButton from '@/components/PillButton';
import { formatISODate } from '@/helpers/dates';

const SubHeadWrapper = styled(Row)`
  margin-top: 2rem;
`;
const SubHeader = styled.div`
  font-size: 18px;
  font-family: TTNormsPro-Bold;
  margin-bottom: 1rem;
`;
const RewardInformation = ({
  isRewardValidated,
  rewardTransaction,
  showConfirmRewardActive,
}) => (
  <>
    <SubHeadWrapper>
      <SubHeader>REWARD</SubHeader>
    </SubHeadWrapper>
    <Row gutter={24}>
      <Col span={9}>
        <TextInput
          label="Payment ID"
          name="paymentId"
          disabled
          value={rewardTransaction?.transaction_id}
        />
      </Col>
      <Col span={9}>
        <TextInput
          label="Amount"
          name="amount"
          disabled
          value={rewardTransaction?.transaction_amount}
        />
      </Col>
    </Row>
    <Row gutter={24}>
      <Col span={9}>
        <TextInput
          label="Transaction date"
          name="paymentDate"
          disabled
          value={formatISODate(rewardTransaction?.transaction_at)}
        />
      </Col>
      <Col span={9}>
        <TextInput
          label="Reward estimated date"
          name="rewardEstimatedDate"
          disabled
          value={formatISODate(rewardTransaction?.reward_estimated_date)}
        />
      </Col>
    </Row>
    <Row gutter={24}>
      <Col span={9}>
        <TextInput
          label="State"
          name="state"
          disabled
          value={REWARD_STATUS_LABEL[rewardTransaction?.reward_status]}
        />
      </Col>
      <Col span={4}>
        <PillButton
          onClick={showConfirmRewardActive}
          disabled={!isRewardValidated}
        >
          Active Reward
        </PillButton>
      </Col>
    </Row>
  </>
);

export default RewardInformation;
