import React from 'react';

import { Grid, GridItem } from '@chakra-ui/react';
import styled from 'styled-components';

import CopyToClipboard from '@/components/CopyToClipboard';
import DownArrowIcon from '@/components/icons/DownArrowIcon';
import { formatISODate } from '@/helpers/dates';

const CardWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-right: 25px;
  width: 100%;
`;

const DateWrapper = styled.div`
  background: #d9d9d9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  font-family: TTNormsPro-bold;
  height: 20px;
`;

const TimeWrapper = styled.div`
  color: #808080;
`;

const SourceWrapper = styled.div`
  color: #808080;
  display: flex;
  justify-content: end;
  margin-right: 25px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

const TitleWrapper = styled.h2`
  font-family: TTNormsPro-bold;
  font-size: 20px;
  display: flex;
  align-items: end;
  height: 100%;
`;

const TextWrapper = styled.div`
  padding-bottom: 10px;
`;

const ArrowWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
`;

const DownArrowWrapper = styled.div`
  margin-right: 5px;
`;

const TimeLineAltCard = ({ timeLineEvent }) => (
  <>
    <Grid templateRows="repeat(2, 1fr)" h="128px" templateColumns="">
      <GridItem colSpan={1}>
        <Grid templateColumns="105px repeat(3, 1fr)">
          <GridItem>
            <DateWrapper>
              {formatISODate(timeLineEvent.created_at, 'dd/MM/yyyy')}
            </DateWrapper>
          </GridItem>
          <GridItem>
            <TimeWrapper>
              {formatISODate(timeLineEvent.created_at, 'HH:mm:ss')}
            </TimeWrapper>
          </GridItem>
          <GridItem colStart={4} colEnd={5}>
            <SourceWrapper>
              {`${
                timeLineEvent.source_type === 'physical_station'
                  ? `${timeLineEvent.source_name} / ${timeLineEvent.station_source}`
                  : timeLineEvent.station_source
              }`}
            </SourceWrapper>
          </GridItem>
        </Grid>
      </GridItem>
      <ArrowWrapper>
        <DownArrowWrapper>
          <DownArrowIcon />
        </DownArrowWrapper>
        <CardWrapper>
          <Grid
            h="100%"
            templateAreas={`'marg title icon'
                          'marg text icon'`}
            gridTemplateRows="1fr 1fr"
            gridTemplateColumns="26px 3fr 1fr"
          >
            <GridItem gridArea="marg"></GridItem>
            <GridItem gridArea="title">
              <TitleWrapper>{timeLineEvent.sub_status}</TitleWrapper>
            </GridItem>
            <GridItem gridArea="text">
              <TextWrapper>
                by <b>{timeLineEvent.created_by.email}</b>
                <div>{timeLineEvent.created_by.id}</div>
              </TextWrapper>
            </GridItem>
            <GridItem gridArea="icon">
              <IconWrapper>
                <CopyToClipboard data="test" round={true} />
              </IconWrapper>
            </GridItem>
          </Grid>
        </CardWrapper>
      </ArrowWrapper>
    </Grid>
  </>
);

export default TimeLineAltCard;
