import React from 'react';

import { HStack, Text, VStack } from '@chakra-ui/react';
import { NewTextField as TextField } from '@lp/ds-next';
import { useParams } from 'react-router-dom';

import ChangePartnerServiceLevel from './ChangePartnerServiceLevel';
import usePartnerServiceLevel from '../../../serviceLevels/hooks/usePartnerServiceLevel';
import { IPartnerV3, IPartnerV3Update } from '../models/partnerV3';
import Loader from '@/components/Loader';

const PartnerServiceLevel = ({
  partner,
  updatePartner,
}: {
  partner: IPartnerV3;
  updatePartner: (data: IPartnerV3Update) => Promise<{ success: boolean }>;
}) => {
  const { id } = useParams<{ id: string }>();
  const { result, invalidate: fetchPartnerServiceLevel } =
    usePartnerServiceLevel(id!);

  if (result.state === 'idle' || result.state === 'loading') {
    return <Loader size={32} />;
  }

  if (result.state === 'error') return <>Error fetching service level.</>;

  const { resource: serviceLevel } = result;

  return (
    <HStack alignItems="flex-start">
      <VStack alignItems="flex-start" spacing="1rem" width="70%">
        <HStack>
          <Text fontWeight="500" textTransform="uppercase">
            Name:
          </Text>
          <Text>{serviceLevel.name}</Text>
        </HStack>
        <HStack alignItems="flex-start">
          <Text fontWeight="500" textTransform="uppercase">
            Description:
          </Text>
          <Text>{serviceLevel.description}</Text>
        </HStack>
        <Text fontWeight="500" textTransform="uppercase">
          Box configuration:
        </Text>
        <TextField
          multiline
          rows={40}
          fullWidth
          placeholder="Box Configuration"
          name="boxConfiguration"
          value={JSON.stringify(serviceLevel.boxConfiguration, null, 2)}
          disabled
        />
      </VStack>
      <HStack spacing="0.5rem" width="30%">
        <ChangePartnerServiceLevel
          currentServiceLevel={serviceLevel}
          fetchPartnerServiceLevel={fetchPartnerServiceLevel}
          partner={partner}
          updatePartner={updatePartner}
        />
      </HStack>
    </HStack>
  );
};

export default PartnerServiceLevel;
