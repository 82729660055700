import React from 'react';

import { Route, Routes } from 'react-router-dom';

import Dashboard from './Dashboard';
import ScopedRoute from '@/components/ScopedRoute';
import { scopes } from '@/config';
import { appRoutes } from '@/configs/appRoutes';
import useAppState from '@/hooks/useAppState';

export const DashboardRouter = () => {
  const userScopes = useAppState((state) => state.scopes);

  return (
    <>
      <Routes>
        <Route
          path={appRoutes.ROOT}
          element={
            <ScopedRoute
              component={<Dashboard />}
              userScopes={userScopes}
              requiredScopes={[scopes.VIEW_LPACCOUNT]}
            />
          }
        />
      </Routes>
    </>
  );
};
