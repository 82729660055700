import { useMutation } from '@tanstack/react-query';

import { Iproduct } from '../models/product';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { MutationConfig } from '@/lib/react-query';

export const updateNote =
  (makeRequest: any) =>
  async ({
    note,
    productId,
  }: {
    note: string;
    productId: string;
  }): Promise<Iproduct> => {
    const { data, error } = await makeRequest({
      path: `/boxes/${productId}/notes`,
      method: 'post',
      body: { note },
    });

    if (!data || error) {
      throw new Error(error);
    }

    return data;
  };

type UseUpdateBoxOptions = {
  config?: MutationConfig<ReturnType<typeof updateNote>>;
};

export const useUpdateNote = ({ config }: UseUpdateBoxOptions = {}) => {
  const [, makeRequest] = useImperativeRequestWrapper('device');

  return useMutation({
    onSuccess: (_data) => {
      // TODO: Uncomment the day we will have and use the getProduct query hook
      // queryClient.refetchQueries({ queryKey: ['product', data.boxId, 'note', data.id] });
    },
    ...config,
    mutationFn: updateNote(makeRequest),
  });
};
