import React from 'react';

import styled from 'styled-components';

const SVG = styled.svg`
  cursor: pointer;
`;

const DeleteIcon = (props) => (
  <SVG width={12} height={14} {...props}>
    <defs>
      <path
        d="M6 6h1v6H6V6zm3 0h1v6H9V6zM2 3v1h1v10a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4h1V3H2zm2 11V4h8v10H4zM6 1h4v1H6V1z"
        id="delete_svg__a"
      />
    </defs>
    <use
      fill="#000"
      xlinkHref="#delete_svg__a"
      transform="translate(-2 -1)"
      fillRule="evenodd"
    />
  </SVG>
);

export default DeleteIcon;
