import React, { useCallback, useMemo, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Descriptions, Typography } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';

import CreateApiKeyForm from './CreateApiKeyForm';
import useCreateApiKey from '../hooks/useCreateApiKey';
import { INITIAL_CreateApiKey } from '../models/apiKey';
import CancelSubmitFooterModal from '@/components/CancelSubmitFooterModal';
import CustomModal from '@/components/CustomModal';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';

const CreateApiKey = ({ partnerId }: { partnerId: string }) => {
  const [formModalVisible, setFormModalVisible] = useState<boolean>(false);
  const [apiResultModalVisible, setApiResultModalVisible] =
    useState<boolean>(false);
  const [apiKeyEntry, setApiKeyEntry] = useState(INITIAL_CreateApiKey);
  const [createResult, setCreateResult] = useState<any>(null);
  const openFormModal = useCallback(
    () => setFormModalVisible(true),
    [setFormModalVisible]
  );
  const closeFormModal = useCallback(() => {
    setApiKeyEntry(INITIAL_CreateApiKey);
    setFormModalVisible(false);
    setCreateResult(null);
  }, [setCreateResult, setFormModalVisible, setApiKeyEntry]);
  const [{ loading }, createApiKey] = useCreateApiKey(partnerId);

  const handleSubmit = useCallback(async () => {
    const { success, ...result } = await createApiKey(apiKeyEntry);
    setCreateResult(result);
    setFormModalVisible(false);
    setApiResultModalVisible(true);
    if (success) {
      setApiKeyEntry(INITIAL_CreateApiKey);
    }
  }, [
    createApiKey,
    apiKeyEntry,
    setApiKeyEntry,
    setFormModalVisible,
    setApiResultModalVisible,
    setCreateResult,
  ]);

  const isInvalid = useMemo(
    //() => !apiKeyEntry.description || apiKeyEntry.scope?.length === 0,
    () => !apiKeyEntry.description,
    [apiKeyEntry]
  );

  const modalFooterButtons = useMemo(
    () =>
      CancelSubmitFooterModal(isInvalid, handleSubmit, closeFormModal, loading),
    [closeFormModal, handleSubmit, isInvalid, loading]
  );

  return (
    <div>
      <ScopedCta
        component={PillButton}
        icon={<PlusOutlined />}
        onClick={openFormModal}
        requiredScopes={[scopes.MANAGE_LPVILLAGE_API_KEY]}
        data-testid="createApiKeyButton"
      >
        Add API key
      </ScopedCta>

      <CustomModal
        title="Add a new API key"
        open={formModalVisible}
        destroyOnClose={true}
        footer={modalFooterButtons}
        onCancel={closeFormModal}
        width="50%"
      >
        <CreateApiKeyForm apiKey={apiKeyEntry} setApiKey={setApiKeyEntry} />
      </CustomModal>

      <CustomModal
        title="Generated API Key"
        open={apiResultModalVisible}
        destroyOnClose={true}
        width="50%"
        footer={
          <Button onClick={() => setApiResultModalVisible(false)}>Close</Button>
        }
      >
        <Paragraph>This key is only shown once</Paragraph>
        <Descriptions bordered>
          <Descriptions.Item>
            <Typography.Text copyable={true}>
              {createResult?.apiKey}
            </Typography.Text>
          </Descriptions.Item>
        </Descriptions>
      </CustomModal>
    </div>
  );
};

export default CreateApiKey;
