import React, { useState } from 'react';

import FormTextInput from '@/shared/components/FormTextInput/FormTextInput';

export const nonIntegerError = (field) => {
  const parsedInt = parseInt(field, 10);

  return field !== parsedInt.toString() || parsedInt < 0;
};

interface IIntegerInput {
  value: string;
  hasOtherError?: boolean;
  otherErrorMsg?: boolean;
}

const IntegerInput = ({
  value,
  hasOtherError,
  otherErrorMsg,
  ...rest
}: IIntegerInput & any) => {
  const [isTouched, setIsTouched] = useState(false);

  return (
    <FormTextInput
      type="number"
      onWheel={(e) => e.target.blur()}
      onFocus={() => setIsTouched(true)}
      step={1}
      errorMsg={
        otherErrorMsg || (nonIntegerError(value) ? 'Must be an Integer!' : '')
      }
      hasError={hasOtherError || nonIntegerError(value)}
      isTouched={isTouched}
      value={value}
      {...rest}
    />
  );
};

export default IntegerInput;
