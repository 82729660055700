import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useAddPartnerAddress: any = () => {
  const addError = useError();
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpVillage');
  const addSuccessMsg = useToasts((state) => state.addItem);

  const addPartnerAddress = async (partnerId, address) => {
    const { error, data } = await makeRequest({
      path: `/admin/partners/${partnerId}/addresses`,
      method: 'post',
      body: { ...address },
    });

    if (error) {
      addError(error);

      return { success: false };
    }
    addSuccessMsg(
      { msg: 'Address was successfully added!', type: MessageType.Success },
      'root',
      {}
    );

    return { success: true, data: data };
  };

  return [{ loading }, addPartnerAddress];
};
export default useAddPartnerAddress;
