import { useCallback } from 'react';

import { ElabelResponse, ShipmentLabelImage } from '../models/shipment';
import { useApiClient } from '@/helpers/ApiClients';

export function useGetShipmentElabel(): (
  id: string
) => Promise<ShipmentLabelImage> {
  const shipmentService = useApiClient('shipment');

  const getELabel = useCallback(
    (id: string) =>
      shipmentService
        .get<ElabelResponse>(`/admin/shipments/${id}/elabel`)
        .then((response) => response.data.elabel)
        .then((elabel) => ({
          img: {
            image: elabel.payload.image,
            format: elabel.payload.format,
          },
          header: {
            signature: elabel.header.signature,
            crc: elabel.header.crc,
            seqNum: elabel.header.seqNum,
            versionHash: elabel.header.versionHash,
          },
        })),
    [shipmentService]
  );

  return getELabel;
}
