import React from 'react';

import { Col, Row } from 'antd';

import TextInput from '@/components/forms/TextInput';
import { COUNTRY_CODES_JSON_PATH } from '@/config';
import useCountryCodes from '@/shared/hooks/useCountryCodes';

const RecipientSenderForm = ({
  recipient = true,
  recipientSender,
  account,
}) => {
  const [, getCountryNameFromCode] = useCountryCodes({
    localCountryCodesPath: COUNTRY_CODES_JSON_PATH,
  });

  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            disabled
            label={recipient ? 'Recipient' : 'Sender'}
            name="recipientSender"
            value={recipientSender}
          />
        </Col>
        <Col span={12}>
          <TextInput
            disabled
            label="Display Name"
            name="displayName"
            value={account.address.displayName}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput disabled label="ID" name="id" value={account.id} />
        </Col>

        <Col span={12}>
          <TextInput
            disabled
            label="Notification Email"
            name="email"
            value={account.notificationEmail}
          />
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            disabled
            label="House No."
            name="houseNumber"
            value={account.address.houseNumber}
          />
        </Col>
        <Col span={12}>
          <TextInput
            disabled
            label="Street"
            name="street"
            value={account.address.street}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            disabled
            label="Postal Code"
            name="postalCode"
            value={account.address.postalCode}
          />
        </Col>
        <Col span={12}>
          <TextInput
            disabled
            label="City"
            name="city"
            value={account.address.city}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            disabled
            label="State"
            name="state"
            value={getCountryNameFromCode(account.address.state)}
          />
        </Col>
        <Col span={12}>
          <TextInput
            disabled
            label="Country"
            name="country"
            value={getCountryNameFromCode(account.address.countryCode)}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            disabled
            label="Company"
            name="company"
            value={account.address.company}
          />
        </Col>
      </Row>
    </>
  );
};

export default RecipientSenderForm;
