import React from 'react';

import {
  QuestionOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Tooltip } from 'antd';

import PendingIcon from '@/components/icons/PendingIcon';
import { SIM_STATUS } from '@/features/boxes/models/sim';

type SimStatusProps = {
  status: SIM_STATUS;
};

const SimStatus = ({ status }: SimStatusProps) => {
  const ICONS = {
    [SIM_STATUS.STATUS_ACTIVE]: <CheckOutlined style={{ color: '#38a169' }} />,
    [SIM_STATUS.STATUS_SCHEDULED]: <PendingIcon />,
    [SIM_STATUS.STATUS_INACTIVE]: (
      <CloseOutlined style={{ color: '#ff4d4f' }} />
    ),
    DEFAULT: <QuestionOutlined />,
  };
  const simIcon = ICONS[status] || ICONS.DEFAULT;

  return <Tooltip title={status}>{simIcon}</Tooltip>;
};

export default SimStatus;
