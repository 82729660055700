import useLPPartnersStore from './useLPPartnersStore';
import lpPartnersPostProcessor from '../helpers/lpPartnersPostProcessor';
import usePaginatedTableData from '@/hooks/usePaginatedTableData';

const useGetPartners = () => {
  const dispatch = useLPPartnersStore((state) => state.dispatch);
  const order = useLPPartnersStore((state) => state.order);
  const sortBy = useLPPartnersStore((state) => state.sortBy);
  const offset = useLPPartnersStore((state) => state.offset);
  const pageSize = useLPPartnersStore((state) => state.pageSize);
  const searchTerms = useLPPartnersStore((state) => state.searchTerms);

  usePaginatedTableData({
    params: {
      path: '/admin/partners',
      method: 'get',
      reducer: lpPartnersPostProcessor,
    },
    dispatch,
    queryParams: Object.assign({
      pageSize: pageSize,
      offset: offset,
      sortBy: sortBy,
      order: order,
      ...searchTerms,
    }),
    queryParamsOptions: { skipEmptyString: true },
    service: 'lpVillage',
  });
};

export default useGetPartners;
