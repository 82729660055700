import React, { useCallback, useMemo, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import useDeletePartner from '../hooks/useDeletePartner';
import { IPartnerV3, INITIAL_PartnerV3 } from '../models/partnerV3';
import CustomModal from '@/components/CustomModal';
import InputIdForm from '@/components/InputIdForm';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import P from '@/components/text/P';
import { scopes } from '@/config';

const StyledP = styled(P)`
  margin-bottom: 1rem;
`;

const DeletePartner = ({
  partner,
  loading,
  setPartner,
}: {
  partner: IPartnerV3;
  loading: boolean;
  setPartner: any;
}) => {
  const [{ loading: deleteLoading }, deletePartner] = useDeletePartner(
    partner.id
  );
  const [showModal, setShowModal] = useState(false);

  const [inputtedId, setInputtedId] = useState({
    data: '',
    touched: false,
  });

  const closeModal = useCallback(() => {
    setShowModal(false);
    setInputtedId({ data: '', touched: false });
  }, []);

  const hasError = useMemo(
    () => inputtedId.data.trim() !== partner.id,
    [partner.id, inputtedId.data]
  );

  const handleSubmit = useCallback(async () => {
    const { success } = await deletePartner();
    if (success) {
      closeModal();
      setPartner({ ...INITIAL_PartnerV3, deleted: true });
    }
  }, [deletePartner, closeModal, setPartner]);

  return (
    <>
      <ScopedCta
        component={PillButton}
        type="danger"
        icon={<DeleteOutlined />}
        loading={loading || deleteLoading}
        onClick={() => setShowModal(true)}
        requiredScopes={[scopes.MANAGE_LPVILLAGE_PARTNER]}
      >
        Delete Partner
      </ScopedCta>
      <CustomModal
        open={showModal}
        onCancel={closeModal}
        title="You are about to delete this partner"
        okText="Delete Partner"
        cancelText="Close"
        okButtonProps={{
          danger: true,
          disabled: hasError,
          loading: deleteLoading,
        }}
        width="30%"
        onOk={handleSubmit}
      >
        <InputIdForm
          placeholder="Partner-ID"
          text={
            <StyledP>
              To delete this partner, please enter the partner&apos;s ID:
            </StyledP>
          }
          hasError={hasError}
          inputtedId={inputtedId}
          setId={setInputtedId}
          inputName="delete-partner-modal"
        />
      </CustomModal>
    </>
  );
};

export default DeletePartner;
