import get from 'lodash/get';
import styled, { ThemeProps } from 'styled-components';

interface IFormFieldWrapper {
  width?: string;
  mb?: string;
  ta?: string;
}

const FormFieldWrapper = styled.div<IFormFieldWrapper & ThemeProps<any>>`
  display: block;
  position: relative;
  text-align: ${({ ta }) => ta || 'left'};
  width: ${({ width }) => width || '100%'};
  margin-bottom: ${({ mb }) => mb || '4rem'};
  color: ${({ theme }) => get(theme, 'colors.black', defaults.colors.black)};
`;

const defaults = {
  colors: { black: '#ccc' },
};

export default FormFieldWrapper;
