import React from 'react';

import SVG from '@/shared/components/Svg';
const defaults = {
  width: '1.5rem',
  height: '1.5rem',
  fill: '#000000',
  viewBox: '0 0 24 24',
};

const PencilIcon = ({
  width = defaults.width,
  height = defaults.height,
  fill = defaults.fill,
  viewBox = defaults.viewBox,
  ...props
}) => (
  <SVG width={width} height={height} viewBox={viewBox} {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Edit">
        <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
        <path
          d="M17.7673537,4 C17.1959684,4 16.6245831,4.21759868 16.188396,4.65378289 L15.2632254,5.57894737 L18.4211409,8.73684211 L19.3463115,7.81167763 C20.2178962,6.94009868 20.2178962,5.52615132 19.3463115,4.65378289 C18.9101244,4.21759868 18.3387391,4 17.7673537,4 Z M14.0790071,6.76315789 L5,15.8421053 L5,19 L8.15791551,19 L17.2369226,9.92105263 L14.0790071,6.76315789 Z"
          id="Shape"
          fill={fill}
          fillRule="nonzero"
        ></path>
      </g>
    </g>
  </SVG>
);

export default PencilIcon;
