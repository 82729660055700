import React, { forwardRef, Ref } from 'react';

import styled from 'styled-components';

import Label from './Label';
import StyledInput, { StyledInputProps } from './StyledInput';
import Error from '@/components/Error';

const Wrapper = styled.div<any>`
  display: block;
  width: ${(props) => props.width || '100%'};
`;

const TextInput = forwardRef(
  (props: StyledInputProps, ref: Ref<HTMLInputElement>) => {
    const { label, error, width, type, isTouched, ...rest } = props;

    return (
      <Wrapper width={width}>
        <Label>
          {label}
          <StyledInput
            {...rest}
            ref={ref}
            error={error}
            type={type || 'text'}
          />
          {isTouched && <Error message={error} />}
        </Label>
      </Wrapper>
    );
  }
);

TextInput.displayName = 'TextInput';

export default TextInput;
