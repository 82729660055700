import React, { useState } from 'react';

import { EditOutlined } from '@ant-design/icons';

import EditContributionModal from './EditContributionModal';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';

const ModifyContribution = ({ contribution, id }) => {
  const [showModal, setShowModal] = useState(false);
  const { versionHash } = contribution;

  return (
    <div>
      <ScopedCta
        component={PillButton}
        icon={<EditOutlined />}
        onClick={() => setShowModal(true)}
        requiredScopes={[scopes.EDIT_SHARING_ANGELS]}
      >
        Edit Contribution
      </ScopedCta>
      <EditContributionModal
        contributionId={id}
        versionHash={versionHash}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
};

export default ModifyContribution;
