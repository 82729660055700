import React from 'react';

import { Grid } from '@material-ui/core';
import { Route, Routes } from 'react-router-dom';

import CreateShop from './components/CreateShop';
import LPShopsTable from './components/LPShopsTable';
import LPShopDetails from './views/LPShopDetails';
import { appRoutes } from '@/configs/appRoutes';

const ShopsTable = () => (
  <Grid container justifyContent="center" spacing={3}>
    <Grid item xs={11}>
      <CreateShop />
      <LPShopsTable />
    </Grid>
  </Grid>
);

const LPShops = () => (
  <Routes>
    <Route path={appRoutes.ROOT} element={<ShopsTable />} />
    <Route
      path={appRoutes.LPVILLAGE.SHOPS.SHOP_ID}
      element={<LPShopDetails />}
    />
  </Routes>
);

export default LPShops;
