import React from 'react';

import get from 'lodash/get';
import styled from 'styled-components';

import P from './text/P';

const ErrorMessage = styled(P)`
  color: ${(props) => props.theme.colors.error};
  font-size: 0.8rem;
`;

const Error = (props: { message?: string }) => (
  <ErrorMessage>{get(props, 'message', '').toString()}</ErrorMessage>
);

export default Error;
