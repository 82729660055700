import React, { useCallback, useState } from 'react';

import RelationForm from './RelationForm';
import CancelSubmitFooterModal from '@/components/CancelSubmitFooterModal';
import CustomModal from '@/components/CustomModal';
import PillButton from '@/components/PillButton';

const CreateRelation = ({
  getActiveRelations,
  loading,
  createRelation,
}: {
  getActiveRelations: () => void;
  createRelation: (relation) => Promise<{ success: boolean; data: any }>;
  loading: boolean;
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [newRelation, setNewRelation] = useState({
    relationType: '',
    objectType: '',
    objectId: '',
  });

  const closeModal = useCallback(() => {
    setShowModal(false);
    setNewRelation({
      relationType: '',
      objectType: '',
      objectId: '',
    });
  }, []);

  const handleSubmit = useCallback(async () => {
    const { success } = await createRelation(newRelation);
    if (success) {
      closeModal();
      getActiveRelations();
    }
  }, [createRelation, newRelation, closeModal, getActiveRelations]);

  const isInvalid =
    !newRelation.objectType ||
    !newRelation.relationType ||
    !newRelation.objectId;

  const modalFooterButtons = CancelSubmitFooterModal(
    isInvalid,
    handleSubmit,
    closeModal,
    loading
  );

  return (
    <div>
      <PillButton onClick={() => setShowModal(true)}>
        Create Relation
      </PillButton>
      <CustomModal
        destroyOnClose={true}
        open={showModal}
        title="Create Relation"
        onCancel={closeModal}
        onOk={handleSubmit}
        footer={modalFooterButtons}
        width="40%"
      >
        <RelationForm relation={newRelation} setRelation={setNewRelation} />
      </CustomModal>
    </div>
  );
};

export default CreateRelation;
