import React, { useCallback } from 'react';

import { parseISO } from 'date-fns';

import useGetShipmentHistoryEvents from '../hooks/useGetShipmentHistoryEvents';
import StyledTable from '@/components/tables/StyledTable';
import dates from '@/helpers/dates';

export interface IshipmentHistoryTableRow {
  eventName: string;
  boxEvent: string;
  boxDate: string;
  senderEvent: string;
  commandType: string;
  senderDate: string;
  key: string;
  rowFull: boolean;
}

const EventsTable = ({ id }) => {
  const historyEvents = useGetShipmentHistoryEvents(id);

  const addBasicRow = useCallback((formatedData, event) => {
    formatedData.push(
      event.changeType === 'product' || event.changeType === 'eventBus'
        ? {
            eventName: event.payload.To,
            boxEvent: `Product ID : ${event.changedBy}`,
            commandType: event.payload.CommandTypeName,
            boxDate: dates.formatDate(parseISO(event.changedAt)),
            senderEvent: 'N/A',
            senderDate: '',
            key: event.id,
            rowFull: false,
          }
        : {
            eventName: event.payload.To,
            boxEvent: 'N/A',
            commandType: event.payload.CommandTypeName,
            boxDate: '',
            senderEvent: `${event.changeType} - ${event.changedBy}`,
            senderDate: dates.formatDate(parseISO(event.changedAt)),
            key: event.id,
            rowFull: false,
          }
    );
  }, []);

  const formatTable = useCallback(
    (events) => {
      const formatedData: Array<IshipmentHistoryTableRow> = [];
      events.forEach((event) => {
        const foundIndex = formatedData.findIndex(
          (prevEvent) => prevEvent.eventName === event.payload.To
        );

        if (foundIndex !== -1) {
          if (
            event.changeType === 'product' ||
            event.changeType === 'eventBus'
          ) {
            if (formatedData[foundIndex].boxEvent === 'N/A') {
              formatedData[foundIndex].boxEvent = `Box ID : ${event.changedBy}`;
              formatedData[foundIndex].boxDate = dates.formatDate(
                parseISO(event.changedAt)
              );
              formatedData[foundIndex].rowFull = true;
            } else {
              addBasicRow(formatedData, event);
            }
          } else {
            if (formatedData[foundIndex].senderEvent === 'N/A') {
              formatedData[foundIndex].senderEvent =
                `${event.changeType} - ${event.changedBy}`;
              formatedData[foundIndex].senderDate = dates.formatDate(
                parseISO(event.changedAt)
              );
              formatedData[foundIndex].rowFull = true;
            } else {
              addBasicRow(formatedData, event);
            }
          }

          return;
        }

        addBasicRow(formatedData, event);
      });

      return formatedData;
    },
    [addBasicRow]
  );

  const dataSource = formatTable(historyEvents);

  const columns = [
    {
      title: 'Event',
      dataIndex: 'eventName',
    },
    {
      title: 'System',
      dataIndex: 'senderEvent',
    },
    {
      title: 'Command type',
      dataIndex: 'commandType',
    },
    {
      title: 'System date',
      dataIndex: 'senderDate',
    },
    {
      title: 'Product',
      dataIndex: 'boxEvent',
    },
    {
      title: 'Product date',
      dataIndex: 'boxDate',
    },
  ];

  return (
    <>
      <StyledTable
        dataSource={dataSource}
        size="small"
        columns={columns}
        pagination={false}
      />
    </>
  );
};

export default EventsTable;
