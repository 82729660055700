import React from 'react';

import SVG from './SVG';
const FromIcon = (props) => (
  <SVG width="1em" height="1em" viewBox="0 0 17 16" {...props}>
    <path
      d="M16.5 5.75v10H.25v-10H1.5v8.75h13.75V5.75h1.25zM9 5.125V12H7.75V5.125H4L8.375.75l4.375 4.375H9z"
      fill="#000"
      fillRule="evenodd"
    />
  </SVG>
);

export default FromIcon;
