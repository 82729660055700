import React from 'react';

import { styled } from '@lp/ds-next';
const Main = styled('div')`
  width: calc(100vw - 8rem);
  background: ${({ theme }) => theme.palette.custom.neutral.black[3]};
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 72px);
  overflow-x: hidden;
  border-radius: 10px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }
`;

const MainFile = ({ children }) => <Main>{children}</Main>;

MainFile.displayName = 'MainFile';

export default MainFile;
