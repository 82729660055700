import { useCallback } from 'react';

import useGetTos from './useGetTos';
import { SupportedLangs, TFiles } from '../components/ToSFileUploads';
import { TServiceType } from '../models/tos';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useCreateTos = () => {
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('tos');
  const [{ loading: loadingEdit }, makeRequestEdit] =
    useImperativeRequestWrapper('tos');
  const { getTos } = useGetTos();
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);

  const uploadTos = useCallback(
    async (
      files: TFiles,
      serviceType: TServiceType = 'PaaS',
      activeAt: number | null
    ) => {
      const bodyFormData = new FormData();
      SupportedLangs.forEach((lang) => {
        bodyFormData.append('pdf', files[lang] || '');
      });

      bodyFormData.append('serviceType', serviceType);
      activeAt && bodyFormData.append('activeAt', activeAt.toString());

      const { error } = await makeRequest({
        path: '/admin/tos',
        method: 'post',
        body: bodyFormData,
        headers: { 'content-type': 'multipart/form-data' },
      });

      if (error) {
        addError(error);

        return { success: false };
      }
      addSuccessMsg(
        {
          msg: 'The ToS file was successfully uploaded',
          type: MessageType.Success,
        },
        'root',
        {}
      );

      await getTos();

      return { success: true };
    },
    [getTos, addError, addSuccessMsg, makeRequest]
  );

  const editTos = useCallback(
    async (id: string, files: TFiles | null, activeAt: number | null) => {
      const bodyFormData = new FormData();
      if (files) {
        SupportedLangs.forEach((lang) => {
          bodyFormData.append('pdf', files[lang] || '');
        });
      }
      if (activeAt) {
        bodyFormData.append('activeAt', activeAt.toString());
      }

      const { error } = await makeRequestEdit({
        path: `/admin/tos/${id}`,
        method: 'put',
        body: bodyFormData,
        headers: { 'content-type': 'multipart/form-data' },
      });

      if (error) {
        addError(error);

        return { success: false };
      }
      addSuccessMsg(
        { msg: 'The ToS was successfully updated', type: MessageType.Success },
        'root',
        {}
      );

      return { success: true };
    },
    [addError, addSuccessMsg, makeRequestEdit]
  );

  return { loading, loadingEdit, uploadTos, editTos };
};

export default useCreateTos;
