import React, { useCallback, useMemo, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import PartnerForm from './PartnerForm';
import useAddPartnerAddress from '../hooks/useAddPartnerAddress';
import useCreatePartner from '../hooks/useCreatePartner';
import { INITIAL_CreatePartner } from '../models/partner';
import { AddressTypesKeys } from '../types/address';
import AddressForm from '@/components/AddressForm';
import CustomModal from '@/components/CustomModal';
import NextPreviousFooterModal from '@/components/NextPreviousFooterModal';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';
import { appRoutes } from '@/configs/appRoutes';
import { SearchAccount } from '@/features/lp-account-users';
import { INITIAL_CreateVillageAddress } from '@/models/address';
const addAddressOptions = [
  { label: 'Billing', value: 'billing' },
  { label: 'Sender', value: 'sender' },
];

const TitleWrapper = styled.h2`
  padding-bottom: 1rem;
`;

const CreatePartner = () => {
  const navigate = useNavigate();
  const [{ loading }, createPartner] = useCreatePartner();
  const [visible, setVisible] = useState(false);
  const [partner, setPartner] = useState(INITIAL_CreatePartner);
  const [step, setStep] = useState(0);
  const [accountId, setAccountId] = useState('');
  const [address, setAddress] = useState(INITIAL_CreateVillageAddress);
  const [addAddresses, setAddAddresses] = useState<AddressTypesKeys[]>([]);
  const [{ loading: addAddressLoading }, addPartnerAddress] =
    useAddPartnerAddress();

  const closeModal = useCallback(() => {
    setVisible(false);
    setAccountId('');
    setStep(0);
    setPartner(INITIAL_CreatePartner);
    setAddress(INITIAL_CreateVillageAddress);
    setAddAddresses([]);
  }, []);

  const handleAddAddresses = useCallback(
    (checkedValues) => setAddAddresses(checkedValues),
    [setAddAddresses]
  );

  const handleSubmit = useCallback(async () => {
    const { success, data: newPartner } = await createPartner({
      ...partner,
      address,
      accountId,
    });
    if (success) {
      addAddresses.forEach((type) => {
        addPartnerAddress(newPartner.id, { ...address, type: type });
      });
      closeModal();
      setAccountId('');
    }
  }, [
    createPartner,
    closeModal,
    partner,
    address,
    accountId,
    addPartnerAddress,
    addAddresses,
  ]);

  const isInvalidPartner = useMemo(
    () =>
      !partner.name ||
      !partner.phoneNumber ||
      !partner.website ||
      !partner.description ||
      accountId === '',
    [partner, accountId]
  );

  const form = useMemo(() => {
    switch (step) {
      case 0:
        return (
          <>
            <TitleWrapper>Add the partner&apos;s details.</TitleWrapper>
            <SearchAccount accountId={accountId} setAccountId={setAccountId} />
            <PartnerForm
              data={partner}
              setData={setPartner}
              hideNonEditableFields={true}
            />
          </>
        );
      case 1:
        return (
          <>
            <TitleWrapper>Add the partner&apos;s address.</TitleWrapper>
            <AddressForm
              address={address}
              setAddress={setAddress}
              hideAddressTypeFields={true}
              hidePrimary={true}
            />
            Add additional addresses:&nbsp;
            <Checkbox.Group
              options={addAddressOptions}
              onChange={handleAddAddresses}
              value={addAddresses}
            />
          </>
        );
    }
  }, [
    address,
    partner,
    accountId,
    setAccountId,
    step,
    addAddresses,
    handleAddAddresses,
  ]);

  const isInvalidAddress = useMemo(() => {
    const {
      street2,
      state,
      company,
      displayName,
      houseNumber,
      ...requiredAddressFields
    } = address;

    return some(requiredAddressFields, isEmpty);
  }, [address]);

  const modalFooterButtons = useMemo(
    () =>
      NextPreviousFooterModal({
        step,
        setStep,
        stepInvalid: [isInvalidPartner, isInvalidAddress],
        handleSubmit,
        closeModal,
        loading: loading || addAddressLoading,
      }),
    [
      step,
      closeModal,
      handleSubmit,
      isInvalidPartner,
      isInvalidAddress,
      loading,
      addAddressLoading,
    ]
  );

  return (
    <div>
      <ScopedCta
        component={PillButton}
        icon={<PlusOutlined />}
        onClick={() => navigate(appRoutes.LPVILLAGE.PARTNERS.CREATE)}
        requiredScopes={[scopes.EDIT_LPVILLAGE_PARTNER]}
        data-testid="createPartnerButton"
      >
        Add Partner
      </ScopedCta>

      <CustomModal
        title="Add a new partner"
        open={visible}
        destroyOnClose={true}
        footer={modalFooterButtons}
        onCancel={closeModal}
        width="50%"
      >
        {form}
      </CustomModal>
    </div>
  );
};

export default CreatePartner;
