import React, { useCallback, useState } from 'react';

import BuyQuoteForm from './BuyQuoteForm';
import useBuyShipmentQuote from '../hooks/useBuyShipmentQuote';
import { IModifiedShipment } from '../models/shipment';
import CustomModal from '@/components/CustomModal';
import PillButton from '@/components/PillButton';

const BuyAQuoteModal = ({
  shipment,
  getShipment,
}: {
  shipment: IModifiedShipment;
  getShipment: () => void;
}) => {
  const [visible, setVisible] = useState(false);
  const [quoteId, setQuoteId] = useState('');
  const [serviceLevelId, setServiceLevelId] = useState('');

  const { loading, buyQuote } = useBuyShipmentQuote();

  const openModal = useCallback(() => {
    setVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setVisible(false);
    setQuoteId('');
  }, []);

  const handleSubmit = useCallback(async () => {
    const { success } = await buyQuote({
      quoteId,
      serviceLevelId,
      shipmentId: shipment.id,
      versionHash: shipment.versionHash,
    });
    if (success) {
      getShipment();
      closeModal();
    }
  }, [
    buyQuote,
    closeModal,
    quoteId,
    serviceLevelId,
    shipment.id,
    shipment.versionHash,
    getShipment,
  ]);

  return (
    <>
      <PillButton onClick={openModal}>Buy a quote</PillButton>
      <CustomModal
        title={`Buy a quote for Shipment ID ${shipment.id}`}
        width="60%"
        open={visible}
        onCancel={closeModal}
        destroyOnClose={true}
        okText="Buy Quote"
        okButtonProps={{
          disabled:
            !quoteId ||
            (shipment.shipmentType === 'PARTNER' && !serviceLevelId),
          loading,
        }}
        onOk={handleSubmit}
      >
        <BuyQuoteForm
          setQuoteId={setQuoteId}
          setServiceLevelId={setServiceLevelId}
          shipment={shipment}
        />
      </CustomModal>
    </>
  );
};

export default BuyAQuoteModal;
