import has from 'lodash/has';

import { IModifiedTos, ITos } from '../models/tos';
import dates from '@/helpers/dates';

export const singleTosPostProcessor = (tos: ITos): IModifiedTos => ({
  ...tos,
  tosId: tos.id,
  parsedCreatedAt: has(tos, 'createdAt') ? dates.parseDate(tos.createdAt) : '',
  parsedUpdatedAt: has(tos, 'updatedAt') ? dates.parseDate(tos.updatedAt) : '',
  parsedActiveAt: has(tos, 'activeAt') ? dates.parseDate(tos.activeAt) : '',
});

const tosPostProcessor = (data: ITos[]): IModifiedTos[] => {
  if (!data) {
    return [];
  }

  return data?.map((item) => singleTosPostProcessor(item));
};

export default tosPostProcessor;
