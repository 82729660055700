import { useCallback } from 'react';

import has from 'lodash/has';

import useQualityCheckStore from './useQualityCheckStore';
import { boxQualityChecklist } from '../helpers/boxQualityChecklist';
import { ICreateQualityChecklist } from '../models/qualityCheck';
import dates from '@/helpers/dates';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useQualityCheck = (boxId: string) => {
  const addError = useError();
  const successMsg = useToasts((state) => state.addItem);
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('device');
  const [, makeRequestSingle] = useImperativeRequestWrapper('device');
  const [{ loading: loadingDownload }, makeRequestDownload] =
    useImperativeRequestWrapper('device');
  const [{ loading: loadingCreate }, makeRequestCreate] =
    useImperativeRequestWrapper('device');
  const { dispatch, pageSize, offset, sortBy, order } = useQualityCheckStore();

  const getQualityChecklists = useCallback(async () => {
    await getPaginatedTableData({
      params: {
        path: `/boxes/${boxId}/quality-checks`,
        method: 'get',
      },
      reducer: boxQualityChecklist,
      dispatch,
      addError,
      makeRequest,
      queryParams: {
        pageSize: pageSize,
        offset: offset,
        sortBy: sortBy,
        order: order,
      },
      queryParamsOptions: { skipEmptyString: true, skipNull: true },
    });
  }, [boxId, addError, dispatch, makeRequest, order, sortBy, pageSize, offset]);

  const getQualityCheck = useCallback(
    async (id: string) => {
      const { data, error } = await makeRequestSingle({
        path: `/boxes/${boxId}/quality-checks/${id}`,
        method: 'get',
      });
      if (error) {
        addError(error);

        return { success: false, data: null };
      }
      const processedData = {
        ...data,
        parsedCreatedAt: has(data, 'createdAt')
          ? dates.parseDate(data.createdAt)
          : '',
        parsedUpdatedAt: has(data, 'updatedAt')
          ? dates.parseDate(data.updatedAt)
          : '',
      };

      return { success: true, data: processedData };
    },
    [boxId, addError, makeRequestSingle]
  );

  const createChecklist = useCallback(
    async (qualityCheck: ICreateQualityChecklist) => {
      const { error } = await makeRequestCreate({
        path: `/boxes/${boxId}/quality-checks`,
        method: 'post',
        body: qualityCheck,
      });
      if (error) {
        addError('There was a problem sending the checklist');

        return { success: false };
      }
      successMsg(
        { msg: 'Successfully added checklist', type: MessageType.Success },
        'root',
        {}
      );

      return { success: true };
    },
    [boxId, makeRequestCreate, addError, successMsg]
  );

  const downloadChecklists = useCallback(async () => {
    const { error, data } = await makeRequestDownload({
      path: `/boxes/${boxId}/quality-checks/csv-export`,
      method: 'get',
    });
    if (error) {
      addError('There was a problem downloading the checklists');

      return { success: false, data: null };
    }

    return { success: true, data };
  }, [boxId, makeRequestDownload, addError]);

  return {
    loading,
    loadingCreate,
    loadingDownload,
    getQualityCheck,
    getQualityChecklists,
    createChecklist,
    downloadChecklists,
  };
};

export default useQualityCheck;
