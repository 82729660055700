import { useCallback, useEffect } from 'react';

import useContributionStore from './useContributionStore';
import { contributionPostProcessor } from '../../helpers/contributionsPostProcessor';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useContribution = (id: string) => {
  const setContribution = useContributionStore(
    (state) => state.setContribution
  );
  const setError = useContributionStore((state) => state.setError);
  const setLoading = useContributionStore((state) => state.setLoading);
  const setReset = useContributionStore((state) => state.setReset);

  const [, makeRequest] = useImperativeRequestWrapper('serviceLpAccount');
  const addError = useError();

  const getContribution = useCallback(() => {
    setLoading(true);
    makeRequest({
      path: `/admin/contributions/${id}`,
      method: 'get',
    }).then(async (res) => {
      if (!!res.error) {
        addError(res.error);
        setError(res.error);
        setLoading(false);

        return;
      }
      try {
        const processedContribution = contributionPostProcessor(res.data);
        setContribution(processedContribution);
        setLoading(false);
      } catch (err: any) {
        addError(err);
        setError(err);
        setLoading(false);
      }
    });
  }, [makeRequest, id, addError, setContribution, setError, setLoading]);

  useEffect(() => {
    if (!id) return;
    getContribution();

    return () => setReset();
  }, [id, getContribution, setReset]);

  return { getContribution };
};

export default useContribution;
