import omit from 'lodash/omit';

import { AddressTypesKeys } from '@/features/lp-village/partners/types/address';

export interface IBaseAddress {
  displayName?: string;
  company: string;
  firstName: string;
  lastName: string;
  houseNumber?: string;
  street: string;
  street2: string;
  postalCode: string;
  city: string;
  state: string;
  countryCode: string;
}

export function formatAddress<Address extends IBaseAddress>({
  displayName,
  street,
  houseNumber,
  postalCode,
  city,
}: Address): string {
  const formattedDisplayName = displayName && `(${displayName})`;

  return `${formattedDisplayName} ${street} ${houseNumber}, ${postalCode} ${city}`;
}

export interface ILPAccountAddress extends IBaseAddress {
  id: string;
  versionHash: string;
  accountId: string;
  creatorId: string;
  isPrimary: boolean;
  types: ('shipping' | 'billing' | 'unknown')[];
  position: {
    latitude?: number;
    longitude?: number;
  };

  createdAt: number;
  updatedAt: number;
}

export interface IModifiedLPAccountAddress extends ILPAccountAddress {
  parsedCreatedAt: string;
  parsedUpdatedAt: string;
}

export type TStakeholder = 'shop' | 'partner';

export interface ILPVillageAddress
  extends Omit<ILPAccountAddress, 'isPrimary' | 'types' | 'accountId'> {
  type: AddressTypesKeys | null;
  stakeholder: TStakeholder | null;
  building: string;
}

export interface IShipmentAddress
  extends Omit<
    ILPAccountAddress,
    'isPrimary' | 'types' | 'accountId' | 'creatorId'
  > {
  originalAddressId: string;
  createdBy: string;
}
export interface ICreateLPAccountAddress
  extends Omit<
    ILPAccountAddress,
    'id' | 'creatorId' | 'position' | 'createdAt' | 'updatedAt' | 'versionHash'
  > {}

export interface ICreateVillageAddress extends IBaseAddress {}

export interface ICreateShipmentAddress extends IBaseAddress {}

export interface IAddToPartnerAddress extends IBaseAddress {
  type: AddressTypesKeys | null;
  building: string;
  addressTypes: string[];
  id?: string;
  phoneNumber: string;
  email: string;
  createdAt?: number;
  updatedAt?: number;
}

export const INITIAL_BaseAddress: IBaseAddress = {
  displayName: '',
  company: '',
  firstName: '',
  lastName: '',
  houseNumber: '',
  street: '',
  street2: '',
  postalCode: '',
  city: '',
  state: '',
  countryCode: '',
};

export const INITIAL_LPAccountAddress: ILPAccountAddress = {
  ...INITIAL_BaseAddress,
  id: '',
  versionHash: '',
  accountId: '',
  creatorId: '',
  isPrimary: false,
  types: [],
  position: {},
  createdAt: 0,
  updatedAt: 0,
};

export const INITIAL_ModifiedLPAccountAddress = {
  ...INITIAL_LPAccountAddress,
  parsedCreatedAt: '',
  parsedUpdatedAt: '',
};

export const INITIAL_LPVillageAddress: ILPVillageAddress = {
  ...omit(INITIAL_LPAccountAddress, ['isPrimary', 'types', 'accountId']),
  type: null,
  stakeholder: null,
  building: '',
};

export const INITIAL_ShipmentAddress: IShipmentAddress = {
  ...omit(INITIAL_LPAccountAddress, [
    'isPrimary',
    'types',
    'accountId',
    'creatorId',
  ]),
  originalAddressId: '',
  createdBy: '',
};

export const INITIAL_CreateLPAccountAddress: ICreateLPAccountAddress = {
  ...INITIAL_BaseAddress,
  isPrimary: false,
  accountId: '',
  types: [],
};

export const INITIAL_CreateVillageAddress: ICreateVillageAddress =
  INITIAL_BaseAddress;

export const INITIAL_CreateShipmentAddress: ICreateShipmentAddress =
  INITIAL_BaseAddress;

export const INITIAL_AddToPartnerAddress: IAddToPartnerAddress = {
  ...INITIAL_BaseAddress,
  type: null,
  building: '',
  addressTypes: [],
  phoneNumber: '',
  email: '',
};
