export interface IScope {
  createdAt: number;
  createdBy: string;
  description: string;
  id: string;
  name: string;
  updatedAt: number;
}

export interface IRole {
  createdAt: number;
  createdBy: string;
  description: string;
  id: string;
  name: string;
  scopes: IScope[];
  type: string;
  updatedAt: number;
}

export const EMPTY_role = {
  createdAt: 0,
  createdBy: '',
  description: '',
  id: '',
  name: '',
  scopes: [
    {
      createdAt: 0,
      createdBy: '',
      description: '',
      id: '',
      name: '',
      updatedAt: 0,
    },
  ],
  type: '',
  updatedAt: 0,
};

export interface IIdentity {
  id: string;
  userId: string;
  connection: string;
  providerUserId: string;
  provider: string;
  isSocial: boolean;
}

export interface IAccountNote {
  id: string;
  createdAt: number;
  note: string;
  user_id: string;
}

export interface ILPAccountUser {
  id: string;
  createdAt: number;
  updatedAt: number;
  firstName: string;
  givenName: string;
  lastName: string;
  phoneNumber: string;
  phoneNumberVerified: boolean;
  email: string;
  emailVerified: boolean;
  accountVerified: boolean;
  publicHandle: string;
  profilePicture: string;
  identities: IIdentity[];
  notes: IAccountNote[] | null;
  preferredLanguage: PreferredLanguage;
  businessType: string;
}

export type PreferredLanguage = '' | 'en' | 'fr' | 'de';

export interface IModifiedLPAccountUser extends ILPAccountUser {
  parsedCreatedAt: string;
  parsedUpdatedAt: string;
  parsedDeletedAt?: string;
}

export interface IUserWithRoles extends IModifiedLPAccountUser {
  role: IRole;
}

export const INITIAL_UserWithRoles: IUserWithRoles = {
  id: '',
  firstName: '',
  createdAt: 0,
  updatedAt: 0,
  parsedCreatedAt: '',
  parsedUpdatedAt: '',
  lastName: '',
  givenName: '',
  phoneNumber: '',
  phoneNumberVerified: false,
  email: '',
  emailVerified: false,
  accountVerified: false,
  publicHandle: '',
  profilePicture: '',
  identities: [],
  notes: [],
  role: {
    createdAt: 0,
    createdBy: '',
    description: '',
    id: '',
    name: '',
    scopes: [],
    type: '',
    updatedAt: 0,
  },
  preferredLanguage: '',
  businessType: '',
};
