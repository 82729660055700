import useAppState from './useAppState';
import { APIServices, TAPIService } from '../configs/app';
import useImperativeRequest from '../shared/hooks/useImperativeRequest';

const useImperativeRequestWrapper = (service: TAPIService) => {
  const token = useAppState((state) => state.token);

  return useImperativeRequest({ url: APIServices[service], token: token });
};

export default useImperativeRequestWrapper;
