import { useCallback } from 'react';

import useBoxOffersStore from './useBoxOffersStore';
import boxOffersPostProcessor from '../helpers/boxOfferPostProcessor';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import { storeSelector } from '@/helpers/paginatedStoreHelpers';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useRefreshBoxContracts = () => {
  const {
    dispatch,
    order,
    sortBy,
    offset,
    pageSize,

    filters,
  } = useBoxOffersStore(storeSelector);
  const addError = useError();
  const [, makeRequest] = useImperativeRequestWrapper('serviceLpAccount');

  const getBoxContracts = useCallback(async () => {
    const { data, error } = await getPaginatedTableData({
      params: {
        path: '/admin/box-contract-offers',
        method: 'get',
      },
      reducer: boxOffersPostProcessor,
      dispatch,
      addError,
      makeRequest,
      queryParams: {
        pageSize: pageSize,
        offset: offset,
        sortBy: sortBy,
        order: order,
        ...filters,
      },
      queryParamsOptions: { skipEmptyString: true, skipNull: true },
    });

    return { data, error };
  }, [
    addError,
    dispatch,
    makeRequest,
    order,
    sortBy,
    pageSize,
    offset,
    filters,
  ]);

  return getBoxContracts;
};

export default useRefreshBoxContracts;
