import React, { useCallback, useEffect, useState } from 'react';

import { Radio, Space } from 'antd';

import useSearchPartner from '../hooks/useSearchPartner';
import SearchObject from '@/components/SearchObject';
import useError from '@/hooks/useError';

interface ISearchPartner {
  disabled?: boolean;
  partnerId?: string;
  searchMultiple?: boolean;
  setPartnerId: (id: string) => void;
  selectedPartner?: string;
  setSelectedpartner?: (partner: string | undefined) => void;
  setServiceLevel?: (id: string) => void;
}

const SearchPartner = ({
  disabled = false,
  searchMultiple = false,
  partnerId,
  setPartnerId,
  selectedPartner,
  setSelectedpartner,
  setServiceLevel,
}: ISearchPartner) => {
  const addError = useError();
  const { getSinglePartner, getMultiplePartner } = useSearchPartner();
  const [searchField, setSearchField] = useState<string>('');
  const [searchResults, setSearchResults] = useState<Array<any>>([]);

  const handleFindName = useCallback(
    (searchName: string) => {
      if (searchName) {
        getSinglePartner(searchName).then((res) => {
          if (!res.success) {
            res.size === 0
              ? addError('No partner found')
              : addError('Multiple partners found');
          } else {
            setPartnerId(res.partner.id);
            setSearchField(res.partner.name);
          }
        });
      }
    },
    [addError, getSinglePartner, setPartnerId]
  );

  const handleFindMultipleByName = useCallback(
    (searchName: string) => {
      if (searchName) {
        getMultiplePartner(searchName).then((res) => {
          if (!res.success) {
            res.size === 0
              ? addError('No partner found')
              : addError('An Error has occured, please retry later');
          } else {
            setSearchResults(res.partners);
          }
        });
      }
    },
    [addError, getMultiplePartner]
  );

  const handlePartnerChoice = useCallback(
    (e) => {
      setPartnerId(e.target.value);
      const selectedpartner = searchResults.find(
        (res) => res.id === e.target.value
      );
      const parnterName = selectedpartner.name;
      const partnerServiceLvl = selectedpartner.serviceLevel.id.toString();
      setSearchField(parnterName);

      if (setSelectedpartner) {
        setSelectedpartner(parnterName);
      }
      if (setServiceLevel) {
        setServiceLevel(partnerServiceLvl);
      }
    },
    [
      setPartnerId,
      setSearchField,
      searchResults,
      setSelectedpartner,
      setServiceLevel,
    ]
  );

  useEffect(() => {
    setSearchField(selectedPartner ? selectedPartner : '');
  }, [setSearchField, selectedPartner]);

  return (
    <>
      <SearchObject
        searchField={searchField}
        setSearchField={setSearchField}
        objectId={partnerId}
        setObjectId={setPartnerId}
        disabled={disabled}
        placeholder="Name..."
        searchLabel="Partner (search by name)"
        foundLabel="Partner"
        setSearchResults={setSearchResults}
        handleFind={searchMultiple ? handleFindMultipleByName : handleFindName}
      />
      {searchResults.length > 0 && (
        <Radio.Group onChange={(e) => handlePartnerChoice(e)}>
          <Space direction="vertical">
            {searchResults.map((partner) => (
              <Radio value={partner.id} key={partner.id}>
                {partner.name}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      )}
    </>
  );
};

export default SearchPartner;
