import React from 'react';

import { Col, Collapse, Row } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import get from 'lodash/get';

import TextInput from '@/components/forms/TextInput';
import StyledTable from '@/components/tables/StyledTable';
import useAntdColumns from '@/hooks/useAntdColumns';
import useWindowSize from '@/hooks/useWindowSize';

const { Panel } = Collapse;

const InvitationCode = ({ invitationCode }) => {
  const {
    id,
    issuerUserId,
    numberOfContributions,
    contributions,
    recipientEmails,
    code,
    createdBy,
    parsedExpiredAt,
    parsedRevokedAt,
    incentiveType,
    invitationType,
    offerType,
    status,
    parsedCreatedAt,
    parsedUpdatedAt,
  } = invitationCode;

  const windowSize = useWindowSize();

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: [
      'contributionId',
      'accountId',
      'parsedTotalAmount',
      'paymentUniqueId',
      'paymentStatus',
      'paymentMethod',
      'parsedPaymentDate',
      'state',
      'offerType',
    ],
    columnsSpecialProps: {
      offerType: {
        render: (_, record) => get(record, 'parsedOffer.offerType', ''),
      },
    },
    defaultSort: {
      sortBy: 'updatedAt',
      order: 'DESC',
    },
    addDefaultColumns: true,
    eyeLinkProps: { to: '/sharing-angels/contributions' },
  });

  return (
    <div>
      <Row gutter={24}>
        <Col span={8}>
          <TextInput
            label="Invitation Code ID"
            name="id"
            value={id}
            disabled={true}
          />
        </Col>
        <Col span={8}>
          <TextInput
            label="Issuer User Id"
            name="issuerUserId"
            value={issuerUserId}
            disabled={true}
          />
        </Col>
        <Col span={8}>
          <TextInput label="Code" name="code" value={code} disabled={true} />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={8}>
          <TextInput
            label="Created by"
            name="createdBy"
            value={createdBy}
            disabled={true}
          />
        </Col>
        <Col span={8}>
          <TextInput
            label="Created at"
            name="createdAt"
            value={parsedCreatedAt}
            disabled={true}
          />
        </Col>
        <Col span={8}>
          <TextInput
            label="Updated at"
            name="updatedAt"
            value={parsedUpdatedAt}
            disabled={true}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={8}>
          <TextInput
            label="Expired at"
            name="expiredAt"
            value={parsedExpiredAt}
            disabled={true}
          />
        </Col>
        <Col span={8}>
          <TextInput
            label="Revoked at"
            name="revokedAt"
            value={parsedRevokedAt}
            disabled={true}
          />
        </Col>
        <Col span={8}>
          <TextInput
            label="status"
            name="status"
            value={status}
            disabled={true}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={8}>
          <TextInput
            label="Incentive type"
            name="incentiveType"
            value={incentiveType}
            disabled={true}
          />
        </Col>
        <Col span={8}>
          <TextInput
            label="Invitation type"
            name="invitationType"
            value={invitationType}
            disabled={true}
          />
        </Col>
        <Col span={8}>
          <TextInput
            label="offerType"
            name="offerType"
            value={offerType}
            disabled={true}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TextInput
            label="Recipient Emails"
            name="recipientEmails"
            value={recipientEmails ? recipientEmails.join() : ''}
            disabled={true}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {numberOfContributions > 0 ? (
            <Collapse>
              <Panel
                header={`View ${numberOfContributions} contribution(s)`}
                key="1"
              >
                <StyledTable
                  rowKey="id"
                  dataSource={contributions}
                  columns={columns}
                  size="small"
                  scroll={{ y: get(windowSize, 'height', 0) * 0.65 }}
                />
              </Panel>
            </Collapse>
          ) : (
            'This Invitation Code has no Contributions'
          )}
        </Col>
      </Row>
    </div>
  );
};

export default InvitationCode;
