import useAppState from './useAppState';
import { APIServices, TAPIService } from '../configs/app';
import useRemoteData, { IUseRemoteData } from '@/shared/hooks/useRemoteData';

const useRemoteDataWrapper = (params: IUseRemoteData, service: TAPIService) => {
  const apiService = service
    ? APIServices[service]
    : APIServices['serviceLpAccount'];
  const token = useAppState((state) => state.token);
  const tokenAndApiUrl = { apiUrl: apiService, token: token };

  return useRemoteData({ ...params, tokenAndApiUrl });
};

export default useRemoteDataWrapper;
