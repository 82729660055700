import { useCallback } from 'react';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useGetTotals = () => {
  const [, makeRequestDevice] = useImperativeRequestWrapper('device');
  const [, makeRequestShipment] = useImperativeRequestWrapper('shipment');
  const [, makeRequestVillage] = useImperativeRequestWrapper('lpVillage');
  const [, makeRequestAccounts] =
    useImperativeRequestWrapper('serviceLpAccount');

  const addError = useError();

  const getProductTotal = useCallback(async () => {
    const { data, error } = await makeRequestDevice({
      path: `/boxes`,
      method: 'get',
    });
    if (!!error) {
      addError(error);

      return 0;
    }

    return data.total;
  }, [makeRequestDevice, addError]);

  const getShipmentsTotal = useCallback(async () => {
    const { data, error } = await makeRequestShipment({
      path: `/shipments?getAll=true`,
      method: 'get',
    });
    if (error) {
      addError(error);

      return 0;
    }

    return data.total;
  }, [makeRequestShipment, addError]);

  const getVillageTotal = useCallback(async () => {
    const { data, error } = await makeRequestVillage({
      path: `/admin/partners`,
      method: 'get',
    });
    if (!!error) {
      addError(error);

      return 0;
    }

    return data.total;
  }, [makeRequestVillage, addError]);

  const getAccountsTotal = useCallback(async () => {
    const { data, error } = await makeRequestAccounts({
      path: `/admin/accounts?sortBy=updatedAt`,
      method: 'get',
    });
    if (!!error) {
      addError(error);

      return 0;
    }

    return data.total;
  }, [makeRequestAccounts, addError]);

  return {
    getProductTotal,
    getShipmentsTotal,
    getVillageTotal,
    getAccountsTotal,
  };
};

export default useGetTotals;
