import React, {
  useEffect,
  MutableRefObject,
  useRef,
  CSSProperties,
} from 'react';

import styled, { ThemeProps, withTheme } from 'styled-components';

import { theme } from '../../../config';
import { MessageType } from '../../hooks/useToasts.types';
import AlertIcon from '@/components/icons/AlertIcon';
import CheckIcon from '@/components/icons/CheckIcon';
import CrossIcon from '@/components/icons/CrossIcon';
import PencilIcon from '@/components/icons/PencilIcon';
import SimpleCircle from '@/shared/components/SimpleCircle';

const INMS = 300;
const OUTMS = 200;

const SCMessage = styled.div<{ bc: string }>`
  width: 100%;
  max-width: 30rem;
  border-radius: 4px;
  background-color: ${({ bc }) => bc};
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: 1rem;
  transition: all ${INMS}ms ease;
  overflow: hidden;
  opacity: 0;
  transform: scale(0);
  position: relative;
  &.cancel {
    transition: all ${OUTMS}ms ease;
  }
`;

const Content = styled.div<{ theme: any }>`
  display: flex;
  align-items: center;
  padding: 1.157rem 1.3125rem;
  font-family: ${({ theme }) => theme.fontStyles.adminPanel.fontFamily};
  font-size: 0.9375rem;
  color: ${({ theme }) => theme.colors.white};
`;

const CloseIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
`;

const ToastMsg = styled.span`
  padding-left: 0.5rem;
  padding-right: 1.5rem;
  pointer-events: all;
  @media (min-width: ___CSS_0___) {
    padding-left: 1.3rem;
  }
`;

const icons: any = {
  error: AlertIcon,
  success: CheckIcon,
  info: AlertIcon,
  edit: PencilIcon,
};

const colors: any = {
  error: theme.colors.errorBc,
  success: theme.colors.brand,
  info: theme.colors.blue,
  edit: theme.colors.black,
};

export interface IMessage {
  msg?: string;
  type: MessageType;
  onClick?: (e: React.MouseEvent) => void;
  showClose?: boolean;
  cancel?: boolean;
  style?: CSSProperties;
  render?: React.FC<{ closeModal?: (e: React.MouseEvent) => void }>;
}
const Message: React.FC<IMessage & ThemeProps<any>> = ({
  msg,
  type,
  onClick,
  cancel,
  showClose,
  theme,
  style,
  render: Component,
}) => {
  const typeColor: string = colors[type];
  const TypeIcon: any = icons[type];

  const animTimeout = useRef<number>();
  const ref = useRef() as MutableRefObject<HTMLDivElement>;

  const collapseSection = (element: any) => {
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.scrollHeight;

    // temporarily disable all css transitions
    var elementTransition = element.style.transition;
    element.style.transition = '';

    // on the next frame (as soon as the previous style change has taken effect),
    // explicitly set the element's height to its current pixel height, so we
    // aren't transitioning out of 'auto'
    requestAnimationFrame(function () {
      element.style.height = `${sectionHeight}px`;
      element.style.transition = elementTransition;

      // on the next frame (as soon as the previous style change has taken effect),
      // have the element transition to height: 0
      requestAnimationFrame(function () {
        element.style.transform = 'scale(0)';
        element.style.opacity = 0;

        animTimeout.current = window.setTimeout(
          () =>
            requestAnimationFrame(function () {
              element.style.marginTop = 0;
              element.style.marginBottom = 0;
              element.style.paddingTop = 0;
              element.style.paddingBottom = 0;
              element.style.height = 0;
            }),
          OUTMS
        );
      });
    });
  };

  const expandSection = (element: any) => {
    requestAnimationFrame(() => {
      element.style.transform = 'scale(1)';
      element.style.opacity = 1;
    });
  };

  useEffect(() => {
    if (!cancel && ref.current) {
      expandSection(ref.current);
    }
    if (cancel && ref.current) {
      collapseSection(ref.current);
    }

    return () => {
      if (animTimeout.current) {
        window.clearTimeout(animTimeout.current);
      }
    };
  }, [cancel, ref]);

  return (
    <SCMessage
      ref={ref}
      className={cancel ? 'cancel' : ''}
      bc={typeColor}
      data-testid={`toast toast-${type}`}
      style={style}
    >
      <Content theme={theme}>
        {type === MessageType.Loading && (
          <SimpleCircle size="1.25rem" bg={theme.colors.darkbrand} />
        )}
        {type !== MessageType.Loading && (
          <TypeIcon
            stroke={theme.colors.white}
            fill={type === MessageType.Edit ? '#fff' : typeColor}
          />
        )}
        {Component ? (
          <ToastMsg>
            <Component closeModal={onClick} />
          </ToastMsg>
        ) : (
          msg && <ToastMsg data-testid="toastMessage">{msg}</ToastMsg>
        )}
        <CloseIcon>
          {showClose && (
            <CrossIcon
              data-testid="toastClose"
              fill={theme.colors.white}
              stroke={typeColor}
              cursor="pointer"
              onClick={onClick}
            />
          )}
        </CloseIcon>
      </Content>
    </SCMessage>
  );
};

export default withTheme(Message);
