import React, { forwardRef } from 'react';

import { Input } from '@chakra-ui/react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

interface DatetimePickerProps
  extends Omit<
    ReactDatePickerProps,
    | 'customInput'
    | 'value'
    | 'selected'
    | 'showMonthDropdown'
    | 'showYearDropdown'
    | 'dropdownMode'
    | 'showTimeInput'
  > {
  value: Date;
  onChange: (date: Date) => void;
}

const CustomDatePickerInput = forwardRef<HTMLInputElement>((props, ref) => (
  <Input ref={ref} {...props} readOnly />
));

CustomDatePickerInput.displayName = 'CustomDatePickerInput';

export function DatetimePicker({
  value,
  onChange,
  ...rest
}: DatetimePickerProps) {
  return (
    <DatePicker
      customInput={<CustomDatePickerInput />}
      selected={value}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      showTimeInput
      onChange={onChange}
      {...rest}
    />
  );
}
