import React, { useCallback, useEffect, useState } from 'react';

import styled from 'styled-components';

import Dropdown from '../../../components/forms/Dropdown';
import Label from '../../../components/forms/Label';
import useError from '../../../hooks/useError';
import useImperativeRequestWrapper from '../../../hooks/useImperativeRequestWrapper';
import { ILPAccountAddress } from '../../../models/address';

const LabelWrapper = styled.div`
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
`;

const QueryParams = new URLSearchParams({
  offset: '0',
  pageSize: '100',
  sortBy: 'updatedAt',
  order: 'DESC',
}).toString();

const LpAccountAddressSelector = ({
  onChange,
  addressId,
  disabled,
  accountId,
}: {
  onChange: (input: string) => void;
  addressId?: string;
  disabled?: boolean;
  accountId: string;
  filterByType?: string;
}) => {
  const addError = useError();
  const [addresses, setAddresses] = useState<ILPAccountAddress[]>([]);

  const value =
    addresses.length > 0 && addressId
      ? addresses.find((x) => x.id === addressId)
      : undefined;

  const [{ loading }, makeRequest] =
    useImperativeRequestWrapper('serviceLpAccount');

  const getAddresses = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: `/admin/addresses/byaccount/${accountId}?${QueryParams}`,
      method: 'get',
    });
    if (error) {
      addError(error);

      return;
    }
    if (data?.items) {
      setAddresses(data.items);
    }
  }, [makeRequest, addError, accountId]);

  useEffect(() => {
    getAddresses();
  }, [getAddresses]);

  return (
    <>
      <LabelWrapper>
        <Label>Address *</Label>
      </LabelWrapper>
      {addresses.length === 0 ? (
        <Dropdown
          options={[]}
          mw="100%"
          placeholder={loading ? 'Loading...' : 'No options'}
        />
      ) : (
        <Dropdown
          options={addresses}
          getOptionLabel={(option: ILPAccountAddress) =>
            `${option.types && JSON.stringify(option.types)} | ${
              option.street
            } ${option.houseNumber}, ${option.city}`
          }
          mw="100%"
          getOptionValue={(option) => option.id}
          onChange={(value: ILPAccountAddress) => onChange(value.id)}
          isDisabled={disabled}
          value={value}
        />
      )}
    </>
  );
};

export default LpAccountAddressSelector;
