import { useMutation } from '@tanstack/react-query';

import { ITrackingCode } from '../../types/trackingCode';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { MutationConfig, queryClient } from '@/lib/react-query';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

export const createTrackingCodeOnShipment =
  (makeRequest: any) =>
  async ({
    values,
    shipmentId,
  }: {
    values: ITrackingCode;
    shipmentId: string;
  }): Promise<ITrackingCode> => {
    const { data, error } = await makeRequest({
      path: '/admin/tracking-codes',
      method: 'post',
      body: { ...values, shipmentId },
    });

    if (!data || error) {
      throw new Error(error);
    }

    return data;
  };

type UseCreateTrackingCodeOnShipmentOptions = {
  shipmentId: string;
  config?: MutationConfig<ReturnType<typeof createTrackingCodeOnShipment>>;
};

export const useCreateTrackingCodeOnShipment = ({
  shipmentId,
  config,
}: UseCreateTrackingCodeOnShipmentOptions) => {
  const addError = useError();
  const successMsg = useToasts((state) => state.addItem);
  const [, makeRequest] = useImperativeRequestWrapper('shipment');

  return useMutation({
    onError: (error) => {
      addError(error);
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['shipment', shipmentId, 'trackingCode'],
      });
      successMsg(
        {
          msg: 'Tracking code was successfully added',
          type: MessageType.Success,
        },
        'root',
        {}
      );
    },
    ...config,
    mutationFn: createTrackingCodeOnShipment(makeRequest),
  });
};
