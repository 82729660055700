import React from 'react';

import { Route, Routes } from 'react-router-dom';

import ServiceLevelsList from './views/ServiceLevelsList';
import ScopedRoute from '@/components/ScopedRoute';
import { scopes } from '@/config';
import { appRoutes } from '@/configs/appRoutes';
import useAppState from '@/hooks/useAppState';

const ServiceLevelsRouter = () => {
  const userScopes = useAppState((state) => state.scopes);

  return (
    <Routes>
      <Route
        path={appRoutes.ROOT}
        element={
          <ScopedRoute
            component={<ServiceLevelsList />}
            userScopes={userScopes}
            requiredScopes={[scopes.VIEW_SERVICE_LEVELS]}
          />
        }
      />
    </Routes>
  );
};

export default ServiceLevelsRouter;
