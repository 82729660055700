import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import useGetAvailableScopes from '../../roles/hooks/useGetAvailableScopes';
import Dropdown from '@/components/forms/Dropdown';
import Label from '@/components/forms/Label';

const LabelWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

const ScopesSelector = ({ onChange, role }) => {
  const getScopes = useGetAvailableScopes(role);
  const [data, setData] = useState();

  useEffect(() => {
    getScopes().then((res) => setData(res));
  }, [getScopes]);

  return (
    <>
      <LabelWrapper>
        <Label>Available Scopes</Label>
      </LabelWrapper>
      <Dropdown
        options={data}
        getOptionLabel={(option) => option.name}
        mw="35rem"
        onChange={onChange}
      />
    </>
  );
};
export default ScopesSelector;
