import React, { useCallback, useEffect, useState } from 'react';

import { EditOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  Divider,
  Descriptions,
  Typography,
  Row,
  Col,
  Radio,
  RadioChangeEvent,
} from 'antd';

import ProductTypesModal from './ProductTypesModal';
import { useSim } from '../api/sim/getSim';
import { useUpdateSimStatus } from '../api/sim/updateSim';
import { ISimStatus, SIM_STATUS } from '../models/sim';
import SimStatus from '@/components/icons/SimStatus';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import SpinLoader from '@/components/SpinLoader';
import H2 from '@/components/text/H2';
import { scopes } from '@/config';
import { useApiClient } from '@/helpers/ApiClients';

const ProductDetailsForm = ({ box, shipment, nbShipment }) => {
  const shipmentService = useApiClient('shipment');

  const {
    data: dataSim,
    isPending,
    refetch,
  } = useSim({
    lpUI: box.lpUI,
    config: {
      enabled: !!box.lpUI,
    },
  });

  const { mutate: updateSimStatus } = useUpdateSimStatus();

  const [currentShipment, setCurrentShipment] = useState(shipment);

  const [productDescritpionState, setProductDescriptionState] =
    useState<string>('unknown');
  const [articleCodeState, setArticleCodeState] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);

  const [simStatusEditable, setSimStatusEditable] = useState(false);
  const [simStatusEditableState, setSimStatusEditableState] = useState(false);

  const [displaySwitchSimStatus, setDisplaySwitchSimStatus] =
    useState<boolean>(false);
  const [simStatus, setSimStatus] = useState({
    account_sid: '',
    created_at: 0,
    fleet_sid: '',
    iccid: '',
    sid: '',
    status: SIM_STATUS.STATUS_UNSPECIFIED,
    unique_name: '',
    updated_at: 0,
    update_history: {
      sim_updates: [
        {
          created_at: 0,
          status: SIM_STATUS.STATUS_UNSPECIFIED,
          update_completed_at: 0,
          updated_at: 0,
          version: '',
        },
      ],
    },
  });

  const checkSimStatus = useCallback((sim: ISimStatus) => {
    if (
      [SIM_STATUS.STATUS_INACTIVE, SIM_STATUS.STATUS_ACTIVE].includes(
        sim.sim.status
      )
    ) {
      setSimStatusEditable(true);
    }
  }, []);

  const {
    articleCode,
    ePaperDisplay,
    firmwareVersion,
    mechanicalConfiguration,
    modemFirmwareVersion,
    parsedCreatedAt,
    parsedManufacturingDate,
    parsedUpdatedAt,
    productDescription,
    productLifecycle,
    productMBOMReference,
    productMBOMVersion,
    lpUI,
  } = box;

  const handleEditProduct = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const handleGetSim = useCallback(async () => {
    await refetch();
  }, [refetch]);

  const handleChangeStatus = useCallback(
    (status) => {
      updateSimStatus({ status, lpUI: box.lpUI });
      setSimStatusEditableState(!simStatusEditableState);
      setDisplaySwitchSimStatus(false);
    },
    [box.lpUI, simStatusEditableState, updateSimStatus]
  );

  useEffect(() => {
    if (box.id) {
      setCurrentShipment(shipment);
    }

    setProductDescriptionState(productDescription);
    setArticleCodeState(articleCode);

    if (lpUI && dataSim && simStatus.sid === '') {
      setSimStatus(dataSim.sim);
      checkSimStatus(dataSim);
    }
  }, [
    setProductDescriptionState,
    productDescription,
    setArticleCodeState,
    articleCode,
    box.id,
    shipmentService,
    handleGetSim,
    simStatus.sid,
    lpUI,
    checkSimStatus,
    dataSim,
    shipment,
  ]);

  return (
    <>
      <Divider />
      <Descriptions title={<H2>Tablet Data</H2>} bordered size="middle">
        <Descriptions.Item label="LPUI">
          <Typography.Text copyable={lpUI}>{lpUI || ''}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Lifecycle">
          <Typography.Text>{productLifecycle || 'Unknown'}</Typography.Text>
        </Descriptions.Item>
        <Descriptions.Item label="Number of shipments">
          {nbShipment || ''}
        </Descriptions.Item>
        <Descriptions.Item label="Shipment ID">
          <Typography.Text>{currentShipment?.id || ''}</Typography.Text>
        </Descriptions.Item>

        <Descriptions.Item label="Firmware Version">
          {firmwareVersion || ''}
        </Descriptions.Item>
        <Descriptions.Item label="ePaper Display">
          {ePaperDisplay || ''}
        </Descriptions.Item>
        <Descriptions.Item label="Modem firmware version">
          {modemFirmwareVersion || ''}
        </Descriptions.Item>

        <Descriptions.Item label="Created At">
          {parsedCreatedAt || ''}
        </Descriptions.Item>
        <Descriptions.Item label="Updated At">
          {parsedUpdatedAt || ''}
        </Descriptions.Item>

        <Descriptions.Item label="Sim Status">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              width: '350px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                width: '100%',
                height: '24px',
              }}
            >
              {isPending && <SpinLoader />}
              {!isPending && (
                <>
                  {!isPending && !displaySwitchSimStatus && (
                    <>
                      <p>{simStatus?.status} </p>
                      <SimStatus status={simStatus?.status} />
                    </>
                  )}

                  {displaySwitchSimStatus && (
                    <Radio.Group
                      name="partner-type"
                      options={[
                        { label: 'ACTIVE', value: 'STATUS_ACTIVE' },
                        {
                          label: 'INACTIVE',
                          value: 'STATUS_INACTIVE',
                        },
                      ]}
                      onChange={({ target: { value } }: RadioChangeEvent) =>
                        handleChangeStatus(value)
                      }
                      optionType="button"
                      buttonStyle="solid"
                      value={simStatus?.status}
                    />
                  )}

                  <PillButton
                    type="ghost"
                    onClick={handleGetSim}
                    disabled={isPending}
                  >
                    <ReloadOutlined />
                  </PillButton>

                  <PillButton
                    type="ghost"
                    onClick={() => {
                      setSimStatusEditableState(!simStatusEditableState);
                      setDisplaySwitchSimStatus(true);
                    }}
                    disabled={!simStatusEditable || simStatusEditableState}
                  >
                    <EditOutlined />
                  </PillButton>
                </>
              )}
            </div>
          </div>
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Descriptions bordered title={<H2>Product Data</H2>}>
        <Descriptions.Item label="Article Code">
          {articleCodeState || ''}
        </Descriptions.Item>
        <Descriptions.Item label="Article description">
          <Row align="middle" gutter={16}>
            <Col>{productDescritpionState || ''}</Col>
            <Col>
              <ScopedCta
                component={PillButton}
                onClick={handleEditProduct}
                icon={<EditOutlined />}
                requiredScopes={[scopes.EDIT_PRODUCT]}
                type="ghost"
              />
            </Col>
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label="Mechanical configuration">
          {mechanicalConfiguration || ''}
        </Descriptions.Item>
        <Descriptions.Item label="MBOM Reference">
          {productMBOMReference || ''}
        </Descriptions.Item>
        <Descriptions.Item label="MBOM Version">
          {productMBOMVersion || ''}
        </Descriptions.Item>
        <Descriptions.Item label="Manufacturing Date">
          {parsedManufacturingDate || ''}
        </Descriptions.Item>
      </Descriptions>
      <ProductTypesModal
        box={box}
        showModal={showModal}
        setShowModal={setShowModal}
        defaultChoice={articleCode}
        setProductDescriptionState={setProductDescriptionState}
        setArticleCode={setArticleCodeState}
      />
    </>
  );
};
export default ProductDetailsForm;
