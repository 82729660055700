import React, {
  ReactChild,
  ReactChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import styled from 'styled-components';

import mapStyleForGoogleMap from '@/helpers/mapStyleForGoogleMap';

export interface ILatLngLiters {
  north: number;
  south: number;
  west: number;
  east: number;
}

interface IMap {
  center?: { lat: number; lng: number };
  height?: string;
  width?: string;
  zoom?: number;
  children: ReactChild | ReactChildren;
  bounds?: ILatLngLiters;
}

const Wrapper = styled.div<{ width?: string; height?: string }>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '400px'};
`;

const StyledMap = styled((props) => (
  <GoogleMap
    onLoad={props.onLoad}
    mapContainerClassName={props.className}
    zoom={props.zoom}
    center={props.center}
    options={props.options}
  >
    {props.children}
  </GoogleMap>
))`
  height: 100%;
  width: 100%;
`;

const Map = ({ center, zoom, children, height, width, bounds }: IMap) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyB9AepWjMHR-uU7YhxZvi7YD1XUUfNUzzY',
  });
  const [map] = useState<any>(null);

  useEffect(() => {
    if (map && bounds) {
      map.fitBounds(bounds);
    }
  }, [bounds, map]);

  const onLoad = useCallback(
    (mapInstance: any) => {
      if (!bounds) {
        return;
      }
      mapInstance.fitBounds(bounds);
    },
    [bounds]
  );

  if (loadError) console.error(loadError);

  return isLoaded ? (
    <Wrapper width={width} height={height}>
      <StyledMap
        onLoad={onLoad}
        zoom={zoom}
        center={center}
        options={{
          styles: mapStyleForGoogleMap,
          mapTypeControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: true,
          zoomControl: true,
        }}
      >
        {children}
      </StyledMap>
    </Wrapper>
  ) : null;
};

export default Map;
