import React from 'react';

import { ArrowRightIcon, ButtonV2 as Button, Grid, Text } from '@lp/ds-next';

import { FilterModalContext } from './Changelog';

const Filters = ({
  filters,
  formik,
}: {
  filters: React.ReactElement;
  formik: any;
}) => {
  const { setOpen } = React.useContext(FilterModalContext);

  return (
    <Grid container direction="column" rowSpacing="2rem">
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Text variant="titleM">Filters</Text>
          </Grid>
          <Grid item>
            <Text
              data-testid="clear-button"
              variant="titleM"
              color="custom.primary.100"
              onClick={() => formik.resetForm()}
              sx={{ cursor: 'pointer' }}
            >
              Clear
            </Text>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column" rowSpacing="1rem">
          {filters}
        </Grid>
      </Grid>
      <Grid item>
        <Button
          data-testid="search-button"
          fullWidth
          color="primary"
          onClick={() => {
            setOpen(false);
            formik.handleSubmit();
          }}
          icon={ArrowRightIcon}
        >
          Search
        </Button>
      </Grid>
    </Grid>
  );
};

export default Filters;
