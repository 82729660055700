import { useCallback } from 'react';

import useRefreshRoles from './useRefreshRoles';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useDeleteRole: any = () => {
  const addError = useError();
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpVillage');
  const getRoles = useRefreshRoles();
  const successMsg = useToasts((state) => state.addItem);

  const deleteRole = useCallback(
    async (id) => {
      const { error } = await makeRequest({
        path: `/admin/roles/${id}`,
        method: 'delete',
      });
      if (error) {
        addError(error);

        return { success: false };
      }
      await getRoles();
      successMsg(
        { msg: 'The role was successfully deleted', type: MessageType.Success },
        'root',
        {}
      );

      return { success: true };
    },
    [addError, getRoles, makeRequest, successMsg]
  );

  return [{ loading }, deleteRole];
};
export default useDeleteRole;
