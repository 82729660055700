import { ILPAccountAddress } from '@/models/address';

export const PaymentProcessors = ['IBAN', 'STRIPE_PAYMENT_INTENT'] as const;

export const PaymentStatuses = [
  'pending',
  'completed',
  'canceled',
  'rejected',
  'refunded',
  'error',
  'refund_initiated',
  'refund_requested',
  'unknown',
] as const;

export const PaymentMethods = [
  'CREDIT_CARD',
  'GIROPAY',
  'SOFORT',
  'WIRE',
] as const;

export const BoxContractStates = [
  'REQUESTED',
  'ACTIVE',
  'CANCELLED',
  'EXPIRED',
  'REFUNDED',
] as const;

type PaymentProcessor = (typeof PaymentProcessors)[number];

type PaymentStatus = (typeof PaymentStatuses)[number];

type PaymentMethod = (typeof PaymentMethods)[number];

type BoxContractState = (typeof BoxContractStates)[number];

export interface BoxContractOffer {
  activeAt: string;
  createdAt: string;
  createdBy: string;
  deactivatedAt: string;
  durationInMonth: number;
  firstTrancheInEuro: number;
  id: string;
  immediateProvisionInEuro: number;
  label: string;
  offerType: string;
  totalPriceInEuro: number;
  totalProvisionInEuro: number;
  updatedAt: string;
  rentServicePercentage: number;
}

export interface ModifiedBoxContractOffer extends BoxContractOffer {
  parsedActiveAt: string;
  parsedCreatedAt: string;
  parsedDeactivatedAt: string;
  parsedUpdatedAt: string;
  boxOfferId: string;
}

export interface SerializedBoxContract {
  acceptedTosVersion: string;
  accountId: string;
  addressId: string;
  account: {
    firstName: string;
    lastName: string;
    email: string;
  };
  email: string;
  paymentReceivedAt: number;
  address: ILPAccountAddress;
  boxContractOffer: BoxContractOffer;
  brokerId: string;
  contractLocation: string;
  createdAt: number;
  id: string;
  numOfBox: number;
  partnerId: string;
  paymentDate: number;
  paymentMethod: PaymentMethod;
  paymentProcessor: PaymentProcessor;
  paymentStatus: PaymentStatus;
  paymentUniqueId: string;
  shopId: string;
  state: BoxContractState;
  totalAmountInCents: number;
  updatedAt: number;
  versionHash: string;
  orders: number;
  productIDs: string[];
  activatedAt: number;
}

export interface BoxContract
  extends Omit<
    SerializedBoxContract,
    | 'createdAt'
    | 'updatedAt'
    | 'paymentReceivedAt'
    | 'paymentDate'
    | 'account'
    | 'activatedAt'
  > {
  createdAt: Date;
  updatedAt: Date;
  paymentReceivedAt: Date;
  paymentDate: Date;
  activatedAt: Date;
  firstName: string;
  lastName: string;
}

export interface CreateBoxContract {
  acceptedTosVersion: string;
  accountId?: string;
  paymentReceivedAt: number | null;
  paymentDate: number | null;
  addressId?: string;
  email?: string;
  boxContractOfferID: string;
  brokerId?: string;
  createdAt: number | null;
  numOfBox: string;
  partnerId?: string;
  paymentMethod: PaymentMethod | '';
  paymentProcessor: PaymentProcessor | '';
  paymentStatus: PaymentStatus | '';
  paymentUniqueId: string;
  shopId?: string;
  state: BoxContractState | '';
  totalAmountInCents: string;
  productIDs?: string[];
}

export type BoxOfferType = 'buy_and_rent' | 'buy';

export interface CreateBoxOffer {
  durationInMonth: string;
  firstTrancheInEuro: string;
  immediateProvisionInEuro: string;
  label: string;
  offerType: BoxOfferType | null;
  totalPriceInEuro: string;
  totalProvisionInEuro: string;
  paymentReceivedAt: number | null;
  rentServicePercentage: string;
}

export const INITIAL_CreateBoxContract: CreateBoxContract = {
  acceptedTosVersion: '',
  paymentReceivedAt: null,
  paymentDate: null,
  addressId: '',
  boxContractOfferID: '',
  brokerId: '',
  createdAt: null,
  numOfBox: '',
  partnerId: '',
  paymentMethod: '',
  paymentProcessor: '',
  paymentStatus: '',
  paymentUniqueId: '',
  shopId: '',
  state: '',
  totalAmountInCents: '',
  email: '',
};

export const INITIAL_CreateBoxContractOffer: CreateBoxOffer = {
  durationInMonth: '',
  firstTrancheInEuro: '',
  immediateProvisionInEuro: '',
  label: '',
  offerType: null,
  totalPriceInEuro: '',
  totalProvisionInEuro: '',
  paymentReceivedAt: null,
  rentServicePercentage: '0.0',
};
