import { useContext, useMemo } from 'react';

import { IArticle } from '../../../boxes/models/box';
import productsPostProcessor from '../helpers/productPostProcessor';
import useProductPartnerStore from '../store/useProductPartnerStore';
import { validationErrorParser } from '@/helpers/beErrorMsgParser';
import usePaginatedTableData from '@/hooks/usePaginatedTableData';
import { ArticlesContext } from '@/shared/providers/articlesProvider';

const parsingOptions = {
  errorParser: validationErrorParser,
  trackError: false,
};

const useGetProductPatner = (partnerId = '') => {
  const dispatch = useProductPartnerStore((state) => state.dispatch);
  const offset = useProductPartnerStore((state) => state.offset);
  const pageSize = useProductPartnerStore((state) => state.pageSize);
  const searchTerms = useProductPartnerStore((state) => state.searchTerms);
  const articles = useContext(ArticlesContext) as IArticle[];
  const productLifecycleStatuses = useProductPartnerStore(
    (state) => state.productLifecycleStatuses
  );
  const productDescription = useProductPartnerStore(
    (state) => state.productDescription
  );

  const formatQueryParamsFilter = (...args) => {
    if (args) {
      const criterias: any = [];
      let criteria: any = '';

      args.forEach((arg: any) => {
        const key = Object.keys(arg);
        if (arg[key[0]] && arg[key[0]].length > 0) {
          criteria = arg[key[0]]
            .map((v: any) => [key[0], `'${v}'`].join('='))
            .join(' OR ');

          criterias.push(`(${criteria})`);
        }
      });

      return criterias.join(' AND ');
    }
  };

  const queryParams = useMemo(() => {
    let listFilter = {};

    if (productDescription || productLifecycleStatuses) {
      listFilter = {
        'list_request.filter': formatQueryParamsFilter(
          { article_code: productDescription },
          { product_lifecycle: productLifecycleStatuses }
        ),
      };
    }

    return {
      ...{ 'list_request.page_size': pageSize },
      ...{ 'list_request.page_offset': offset },
      ...searchTerms,
      ...listFilter,
    };
  }, [
    offset,

    pageSize,
    searchTerms,
    productDescription,
    productLifecycleStatuses,
  ]);

  usePaginatedTableData({
    params: {
      path: `/partners/${partnerId}/products/-/state`,
      method: 'get',
      reducer: productsPostProcessor,
      reducerExtra: articles,
    },
    dispatch,
    queryParams,
    queryParamsOptions: { skipEmptyString: true },
    service: 'deviceV3',
    errorParsingOptions: parsingOptions,
  });
};

export default useGetProductPatner;
