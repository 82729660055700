import { useCallback, useContext, useEffect, useState } from 'react';

import { boxPostProcessor } from '../helpers/boxesPostProcessor';
import { IArticle, IModifiedBox, INITIAL_ModifiedBox } from '../models/box';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { ArticlesContext } from '@/shared/providers/articlesProvider';

const useGetBox = (id) => {
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('device');
  const [box, setBox] = useState<IModifiedBox>(INITIAL_ModifiedBox);
  const addError = useError();
  const articles = useContext(ArticlesContext) as IArticle[];

  const getBox = useCallback(() => {
    makeRequest({
      path: `/products/${id}`,
      method: 'get',
    }).then((res) => {
      if (!!res.error) {
        addError(res.error);

        return;
      }
      try {
        const processedData = boxPostProcessor(res.data, articles);
        setBox(processedData);
      } catch (err) {
        addError(err);
      }
    });
  }, [id, makeRequest, addError, articles]);

  useEffect(() => {
    if (!id) return;
    getBox();
  }, [getBox, id]);

  return { box, getBox, loading };
};
export default useGetBox;
