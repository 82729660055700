import React, { useCallback, useMemo, useState } from 'react';

import { ExclamationOutlined } from '@ant-design/icons';
import { FolderOpenOutlined } from '@ant-design/icons';
import { Col, Divider, Modal, Row, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import BuyAQuoteModal from './BuyAQuoteModal';
import LPCarrierStatus from './LPCarrierStatus';
import ShipmentMapWrapper from './map/ShipmentMapWrapper';
import ShipmentDetailsForm from './ShipmentDetailsForm';
import ShipmentELabel from './ShipmentELabel';
import ShipmentForm from './ShipmentForm';
import ShipmentStateEventHistory from './ShipmentStateEventHistory';
import TrackingCodeForm from './TrackingCodeForm';
import BoxFirmwareHistory from '../../boxes/components/BoxFirmwareHistory';
import useBoxFirmwareByDates from '../../boxes/hooks/useBoxFirmareByDates';
import useServiceLevelsStore from '../../serviceLevels/hooks/useServiceLevelsStore';
import useEditShipment from '../hooks/useEditShipment';
import useGetShipment from '../hooks/useGetShipment';
import BillingManager from '@/components/billing/BillingManager';
import TabPane from '@/components/layout/TabPane';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import H2 from '@/components/text/H2';
import { scopes } from '@/config';
import { BoxTelemetryEvents } from '@/features/boxes';
import useAppState from '@/hooks/useAppState';

const ELABEL_STATES = ['PURCHASED', 'DEPLOYED', 'ARRIVED', 'TRANSFERRED'];

const StyledSpan = styled.span`
  opacity: 0.7;
`;
const { confirm } = Modal;

const ShipmentDetailsContent = () => {
  const userScopes = useAppState((state) => state.scopes);
  const { id } = useParams<{ id: string }>();
  const [shipment, getShipment] = useGetShipment(id);
  const serviceLevelsList = useServiceLevelsStore(
    (state) => state.serviceLevels
  );
  const [tab, setTab] = useState('details');
  const {
    deleteShipment,
    archiveShipment,
    unArchiveShipment,
    restoreShipment,
    updateBilling,
  } = useEditShipment();

  const hasElabel = ELABEL_STATES.includes(shipment.contractState);
  const canChangeStatus =
    shipment.contractState !== 'DRAFT' && shipment.carrierCode === 'LPCarrier';
  const canBuyQuote =
    shipment.quote?.id === '' && shipment.contractState === 'DRAFT';
  const canArchive =
    shipment.contractState === 'ARRIVED' && !shipment.isArchived;
  const canUnarchive =
    shipment.contractState === 'ARRIVED' && shipment.isArchived;
  const canDelete =
    ((shipment.contractState === 'DRAFT' ||
      shipment.contractState === 'PURCHASED') &&
      shipment.deletedAt === 0) ||
    ((shipment.contractState === 'DRAFT' ||
      shipment.contractState === 'PURCHASED') &&
      !shipment.deletedAt);
  const canRestore =
    (shipment.contractState === 'DRAFT' ||
      shipment.contractState === 'PURCHASED') &&
    shipment.deletedAt &&
    shipment.deletedAt !== 0;

  //Replace id by name of the service level
  serviceLevelsList.forEach((srvLvl) => {
    if (shipment.serviceLevel === srvLvl.id) {
      shipment.serviceLevel = srvLvl.name;
    }
  });

  const handleArchive = useCallback(async () => {
    await archiveShipment(shipment.id);
    getShipment();
  }, [shipment, archiveShipment, getShipment]);

  const handleUnArchive = useCallback(async () => {
    await unArchiveShipment(shipment.id);
    getShipment();
  }, [shipment, unArchiveShipment, getShipment]);

  const handleDelete = useCallback(async () => {
    await deleteShipment(shipment.id);
    getShipment();
  }, [shipment, deleteShipment, getShipment]);

  const handleRestore = useCallback(async () => {
    await restoreShipment(shipment.id);
    getShipment();
  }, [shipment, restoreShipment, getShipment]);

  const showConfirmArchive = useCallback(() => {
    confirm({
      okText: 'Yes',
      title: 'Archive Shipment',
      icon: <ExclamationOutlined />,
      content: 'Are you sur you want to archive this shipment?',
      onOk() {
        handleArchive();
      },
      onCancel() {},
    });
  }, [handleArchive]);

  const showConfirmUnArchive = useCallback(() => {
    confirm({
      okText: 'Yes',
      title: 'Unarchive Shipment',
      icon: <ExclamationOutlined />,
      content: 'Are you sur you want to unarchive this shipment?',
      onOk() {
        handleUnArchive();
      },
      onCancel() {},
    });
  }, [handleUnArchive]);

  const showConfirmDelete = useCallback(() => {
    confirm({
      okText: 'Yes',
      title: 'Delete Shipment',
      icon: <ExclamationOutlined />,
      content: 'Are you sur you want to delete this shipment?',
      onOk() {
        handleDelete();
      },
      onCancel() {},
    });
  }, [handleDelete]);

  const showConfirmRestore = useCallback(() => {
    confirm({
      okText: 'Yes',
      title: 'Restore Shipment',
      icon: <ExclamationOutlined />,
      content: 'Are you sur you want to restore this shipment?',
      onOk() {
        handleRestore();
      },
      onCancel() {},
    });
  }, [handleRestore]);

  const showLink = useMemo(() => {
    if (shipment.returnShipmentId || shipment.originalShipmentId) {
      return shipment.returnShipmentId
        ? shipment.returnShipmentId
        : shipment.originalShipmentId;
    }

    return false;
  }, [shipment]);

  const setBillable = useCallback(
    async (body) => {
      await updateBilling(shipment.id, body);
      getShipment();
    },
    [updateBilling, getShipment, shipment]
  );

  const status = useCallback(() => {
    let status: string = shipment.contractState;
    if (shipment.isArchived) {
      status = shipment.contractState === 'ARRIVED' ? 'ARCHIVED' : 'DELETED';
    }
    if (shipment.deletedAt > 0) {
      status = 'DELETED';
    }

    return status;
  }, [shipment.contractState, shipment.isArchived, shipment.deletedAt]);

  const { firmwareClosestToDate } = useBoxFirmwareByDates(
    shipment.boxId,
    shipment.deployedAt
  );

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col>
          <H2>
            <StyledSpan>STATE:</StyledSpan> &nbsp;
            {status()}
          </H2>
        </Col>
        {firmwareClosestToDate && (
          <Col>
            <H2>
              <StyledSpan>Firmware shipped:</StyledSpan> &nbsp;
              {firmwareClosestToDate}
            </H2>
          </Col>
        )}
        {shipment.reference && (
          <Col>
            <H2>
              <StyledSpan>Shipment name:</StyledSpan> &nbsp;
              {shipment.reference}
            </H2>
          </Col>
        )}
      </Row>
      <Row style={{ paddingTop: '15px', paddingBottom: '15px' }}>
        <Col flex="auto">
          {userScopes.includes(scopes.MANAGE_BILLING) && (
            <BillingManager shipment={shipment} onUpdate={setBillable} />
          )}
        </Col>
        <Col flex="auto">
          <Row gutter={[24, 24]} justify="end">
            {canArchive && (
              <Col>
                <ScopedCta
                  component={PillButton}
                  icon={<FolderOpenOutlined />}
                  type="default"
                  onClick={showConfirmArchive}
                  danger
                  requiredScopes={[scopes.MANAGE_SHIPMENTS]}
                >
                  Archive Shipment
                </ScopedCta>
              </Col>
            )}
            {canUnarchive && (
              <Col>
                <ScopedCta
                  component={PillButton}
                  icon={<FolderOpenOutlined />}
                  type="default"
                  onClick={showConfirmUnArchive}
                  danger
                  requiredScopes={[scopes.MANAGE_SHIPMENTS]}
                >
                  Unarchive Shipment
                </ScopedCta>
              </Col>
            )}
            {canDelete && (
              <Col>
                <ScopedCta
                  component={PillButton}
                  icon={<FolderOpenOutlined />}
                  type="default"
                  onClick={showConfirmDelete}
                  danger
                  requiredScopes={[scopes.MANAGE_SHIPMENTS]}
                >
                  Delete Shipment
                </ScopedCta>
              </Col>
            )}
            {!!canRestore && (
              <Col>
                <ScopedCta
                  component={PillButton}
                  icon={<FolderOpenOutlined />}
                  type="default"
                  onClick={showConfirmRestore}
                  danger
                  requiredScopes={[scopes.MANAGE_SHIPMENTS]}
                >
                  Restore Shipment
                </ScopedCta>
              </Col>
            )}
            {canBuyQuote && (
              <Col>
                <BuyAQuoteModal shipment={shipment} getShipment={getShipment} />
              </Col>
            )}
            {showLink && (
              <Col>
                <PillButton>
                  <Link to={`/shipments/${showLink}`}>
                    Go to {shipment.returnShipmentId ? 'return' : 'initial'}{' '}
                    shipment
                  </Link>
                </PillButton>
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      <ShipmentForm shipment={shipment} />
      <Divider />
      <Tabs
        type="card"
        activeKey={tab}
        onTabClick={(key) => setTab(key)}
        data-testid="shipment-tabs"
      >
        <TabPane
          remount
          tab="Details"
          key="details"
          identifier="details"
          currentTab={tab}
        >
          <ShipmentDetailsForm shipment={shipment} />
        </TabPane>
        <TabPane remount tab="Map" key="map" identifier="map" currentTab={tab}>
          {shipment.id && <ShipmentMapWrapper shipment={shipment} />}
        </TabPane>
        <TabPane
          remount
          tab="Delivery Key"
          key="trackingCode"
          identifier="trackingCode"
          currentTab={tab}
        >
          {id && (
            <TrackingCodeForm
              id={id}
              contractStateStatus={
                shipment.contractState !== 'PURCHASED' ||
                shipment.contractState !== 'TRANSFERRED'
              }
              partnerId={shipment.partnerId}
            />
          )}
        </TabPane>
        <TabPane
          remount
          tab="LPCarrier"
          key="lpCarrier"
          identifier="lpCarrier"
          disabled={!canChangeStatus}
          currentTab={tab}
        >
          <LPCarrierStatus shipmentId={id} />
        </TabPane>
        <TabPane
          remount
          tab="Telemetry Events"
          key="telemetry"
          identifier="telemetry"
          disabled={!shipment.boxId}
          currentTab={tab}
        >
          <BoxTelemetryEvents id={id} isShipment />
        </TabPane>
        <TabPane
          remount
          tab="Event History"
          key="eventHistory"
          identifier="eventHistory"
          currentTab={tab}
        >
          <ShipmentStateEventHistory id={id} />
        </TabPane>
        <TabPane
          tab="ELabel"
          key="eLabel"
          identifier="eLabel"
          disabled={!hasElabel}
          currentTab={tab}
        >
          {/* eLabel is not conditionally rendered to avoid requesting data on every tab switch TODO: pass tab prop to comp */}
          <ShipmentELabel shipment={shipment} />
        </TabPane>
        <TabPane
          remount
          tab="Firmware"
          key="firmware"
          identifier="firmware"
          currentTab={tab}
          disabled={!shipment?.boxId}
        >
          <BoxFirmwareHistory id={shipment?.boxId} />
        </TabPane>
      </Tabs>
    </>
  );
};
export default ShipmentDetailsContent;
