import { fromSnakeCaseDeep } from './utilities';

const axiosResponseInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.response.use((response: any) => {
    if (response?.data) {
      response.data = fromSnakeCaseDeep(response.data);

      return response;
    }
  });
};
export default axiosResponseInterceptor;
