import { useCallback } from 'react';

import serviceLevelsPostProcessor from '../helpers/serviceLevelPostProcessor';
import { ServiceLevel } from '../models/serviceLevel';
import { useApiClient } from '@/helpers/ApiClients';
import { useFetchResource } from '@/hooks/useFetchResource';

interface Response<Type> {
  items: Type[];
  total: number;
}

const useGetServiceLevels = () => {
  const device = useApiClient('deviceV3');

  const getServiceLevels = useCallback(
    () =>
      device
        .get<Response<ServiceLevel>>('/service_levels')
        .then((response) => serviceLevelsPostProcessor(response.data)),
    [device]
  );

  return getServiceLevels;
};

const useServiceLevels = () => {
  const getServiceLevels = useGetServiceLevels();

  const fetchFunction = useCallback(
    () => getServiceLevels(),
    [getServiceLevels]
  );

  return useFetchResource(fetchFunction);
};

export default useServiceLevels;
