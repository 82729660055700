import React, { useCallback, useMemo, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import ShopForm from './ShopForm';
import useCreateShop from '../hooks/useCreateShop';
import { INITIAL_CreateShop } from '../models/shop';
import AddressForm from '@/components/AddressForm';
import CustomModal from '@/components/CustomModal';
import NextPreviousFooterModal from '@/components/NextPreviousFooterModal';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';
import { INITIAL_CreateVillageAddress } from '@/models/address';

const TitleWrapper = styled.h2`
  padding-bottom: 1rem;
`;

const ButtonWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
  margin-bottom: 1rem;
`;

const CreateShop = () => {
  const [{ loading }, createShop] = useCreateShop();
  const [step, setStep] = useState(0);
  const [visible, setVisible] = useState(false);
  const [shop, setShop] = useState(INITIAL_CreateShop);
  const [address, setAddress] = useState(INITIAL_CreateVillageAddress);
  const openModal = useCallback(() => setVisible(true), []);
  const closeModal = useCallback(() => {
    setVisible(false);
    setStep(0);
  }, []);

  const handleSubmit = useCallback(async () => {
    const { success } = await createShop(shop, address);
    if (success) {
      setShop(INITIAL_CreateShop);
      setAddress(INITIAL_CreateVillageAddress);
      closeModal();
    }
  }, [createShop, closeModal, shop, address]);

  const isInvalidAddress =
    !address.firstName ||
    !address.lastName ||
    !address.street ||
    !address.postalCode ||
    !address.city ||
    !address.countryCode;

  const isInvalidShop = useMemo(
    () => !shop.name || !shop.phoneNumber || !shop.website || !shop.description,
    [shop]
  );

  const getForm: JSX.Element = useMemo(() => {
    if (step === 0) {
      return (
        <>
          <TitleWrapper>You first need to add an address.</TitleWrapper>
          <AddressForm
            address={address}
            setAddress={setAddress}
            hideAddressTypeFields={true}
            hidePrimary={true}
          />
        </>
      );
    }

    return (
      <>
        <TitleWrapper>Now add the shop details.</TitleWrapper>
        <ShopForm data={shop} setData={setShop} />
      </>
    );
  }, [step, address, shop]);

  const modalFooterButtons = useMemo(
    () =>
      NextPreviousFooterModal({
        step,
        setStep,
        stepInvalid: [isInvalidAddress, isInvalidShop],
        handleSubmit,
        closeModal,
        loading,
      }),
    [step, closeModal, handleSubmit, isInvalidAddress, isInvalidShop, loading]
  );

  return (
    <ButtonWrapper>
      <ScopedCta
        component={PillButton}
        icon={<PlusOutlined />}
        onClick={openModal}
        requiredScopes={[scopes.EDIT_LPVILLAGE_SHOPS]}
        data-testid="createShopButton"
      >
        Add Shop
      </ScopedCta>

      <CustomModal
        title="Add a new shop"
        open={visible}
        destroyOnClose={true}
        footer={modalFooterButtons}
        onCancel={closeModal}
        width="50%"
      >
        {getForm}
      </CustomModal>
    </ButtonWrapper>
  );
};

export default CreateShop;
