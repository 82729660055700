import React, { useCallback, useEffect, useState } from 'react';

import {
  BulletEmptyIcon,
  Chip,
  Grid,
  LinkIcon,
  Stack,
  Text,
  LoopIcon,
  DisconnectedIcon,
  Alert,
  // LockOnIcon,
  // LockOffIcon,
  useTheme,
} from '@lp/ds-next';
import { useParams } from 'react-router-dom';

import { CardCommand } from './CardCommand';
import useRemoteControl from './hook/useRemoteControl';
import { Loader } from '../../../../components/loaders/Loader';
import { IShipment } from '../../../shipments/models/shipment';
import useGetBox from '../../hooks/useGetBox';

export interface RemoteError {
  title: string;
  message: string;
}

export interface IBoxConnectedState {
  is_connected: boolean;
  is_direct_mode: boolean;
}

// Object with action card content
const cardRemoteContent = [
  {
    title: 'Lock/Unlock',
    subtitle: 'Lock / Unlock the product remotely',
    name: 'Lock/Unlock',
    command: 'Trigger command',
    statusKey: 'Status',
    status: '-',
    action: 'COMMAND_TYPE_LOCK_UNLOCK',
    activated: true,
    loader: (
      <Stack spacing={-3} marginTop={-5} marginBottom="0.8rem">
        <Loader />
        <Text>Locking/Unlocking in progress...</Text>
      </Stack>
    ),
  },
  {
    title: 'Received',
    subtitle: 'Be careful, this order is definitive',
    name: 'Confirm delivery',
    command: 'Trigger command',
    statusKey: 'Status',
    status: '-',
    action: 'COMMAND_TYPE_RECEIVED',
    activated: true,
    loader: (
      <Stack spacing={-3} marginTop={-5} marginBottom="0.8rem">
        <Loader />
        <Text>Confirmation of delivery in progress...</Text>
      </Stack>
    ),
  },
];

export const RemonteControlPanel = ({
  isOpen,
  shipment,
}: {
  isOpen: boolean;
  shipment: IShipment;
}) => {
  const [duringMax] = useState(120); // 2 minutes en secondes
  const [interval] = useState(2000); // 2 secondes en millisecondes
  const [startTime, setStartTime] = useState(Date.now() / 1000);
  const [isFirstOpenning, setIsFirstOpenning] = useState<boolean>(true);
  const [boxConnected, setBoxConnected] = useState<IBoxConnectedState>();
  // const [locked, setLocked] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [showFinalNotice, setShowFinalNotice] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<RemoteError>({
    title: '',
    message: '',
  });

  const isConnected = boxConnected?.is_connected === true;
  const isDirectMode = boxConnected?.is_direct_mode === true;

  const { id } = useParams<{ id: string }>();
  const { box } = useGetBox(id);
  const theme = useTheme();

  const { getConnectivity } = useRemoteControl(id); // to replace by the other one below
  // const { getConnectivity, getProductState } = useRemoteControl(id);

  // Call for check if direct mode is activated
  const connection = useCallback(async () => {
    const data = await getConnectivity();
    if (data && data.is_connected && data.is_direct_mode) {
      setError(false);
      setIsFirstOpenning(false);
    }

    setBoxConnected(data);
  }, [getConnectivity, setIsFirstOpenning]);

  // const getLockState = useCallback(async () => {
  //   const data = await getProductState(id);
  //   if (data) {
  //     setLocked(data.lock_state);
  //   }
  // }, [getProductState, id]);

  // Starts direct check active mode for 2min
  useEffect(() => {
    // getLockState();
    const connectInterval = setInterval(() => {
      if (isOpen) {
        if (!boxConnected && Date.now() / 1000 - startTime < duringMax) {
          connection();
          setLoader(true);
        } else {
          clearInterval(connectInterval);
          if (!boxConnected) {
            setError(true);
            setLoader(false);
          }
        }
      } else {
        clearInterval(connectInterval);
      }
    }, interval);

    return () => clearInterval(connectInterval);
  }, [boxConnected, interval, duringMax, startTime, connection, isOpen]);

  useEffect(() => {
    if (
      (isConnected && !isDirectMode) ||
      (!isConnected && !isDirectMode && !isFirstOpenning)
    ) {
      setErrorMessage({
        title: 'Connection is lost',
        message: 'Please refresh to resolve this or try later.',
      });
      setError(true);
    } else if (!isConnected && !isDirectMode && isFirstOpenning) {
      setErrorMessage({
        title: 'Connection impossible',
        message: 'If problem persist contact IT support.',
      });
    }
  }, [isConnected, isDirectMode, isFirstOpenning]);

  // Is called by the refresh button to restart the connection info request
  const forceRefresh = () => {
    connection();
    setStartTime(Date.now() / 1000);
  };

  return (
    <Grid container padding="1.5rem" maxWidth="56.375rem" gap="2rem">
      <Grid item container justifyContent="center">
        <Text variant="titleXL">Remote control</Text>
      </Grid>
      <Grid
        item
        container
        justifyContent={{ mobile: 'center', tablet: 'space-between' }}
        gap={{ mobile: '1.25rem', tablet: '0' }}
        padding="0 1.25rem"
        sx={{
          backgroundColor: 'custom.neutral.black.2',
          opacity: showFinalNotice ? '0.3' : '1',
          pointerEvents: showFinalNotice ? 'none' : 'initial',
        }}
        borderRadius="1.25rem"
        alignItems="center"
      >
        <Grid item display="flex" alignItems="center">
          <Text variant="titleL">{box.productDescription}</Text>
        </Grid>
        <Grid item display="flex" gap="1.5rem" alignItems="center">
          <Grid
            item
            onClick={() => {
              forceRefresh();
            }}
            data-testid="loop-refresh"
          >
            <LoopIcon
              style={{
                width: '1.3rem',
                height: '1.3rem',
                cursor: loader && !boxConnected ? 'initial' : 'pointer',
                color:
                  loader && !boxConnected
                    ? theme.palette.custom.neutral.black[30]
                    : theme.palette.custom.primary[100],
              }}
            />
          </Grid>
          <Chip
            state={isDirectMode ? 'default' : 'error'}
            type="tags"
            label="Direct mode"
            variant="titleS"
            icon={
              <BulletEmptyIcon
                style={{
                  width: '.75rem',
                  height: '.75rem',
                }}
              />
            }
            data-testid="direct-mode-info"
          />
          <Chip
            state={isConnected ? 'default' : 'idle'}
            type="tags"
            variant="titleS"
            label={isConnected ? 'Connected' : 'Disconnected'}
            icon={isConnected ? <LinkIcon /> : <DisconnectedIcon />}
            data-testid="connected-disconnected-info"
          />
          {/* <Chip
            state={locked ? 'default' : 'idle'}
            type="tags"
            variant="titleS"
            label={locked ? 'Locked' : 'Unlocked'}
            icon={locked ? <LockOnIcon /> : <LockOffIcon />}
          /> */}
        </Grid>
      </Grid>

      {error && (
        <Alert
          state="error"
          sx={{
            width: '100%',
            '& .MuiAlert-icon': {
              alignItems: 'center',
            },
          }}
          data-testid="toat-error-message"
        >
          <Text variant="titleXS" color="custom.error.red.100">
            {error && errorMessage.title}
          </Text>
          <Text variant="bodyTextS" color="custom.error.red.100">
            {error && errorMessage.message}
          </Text>
        </Alert>
      )}

      <Grid
        item
        container
        gap="2.75rem"
        sx={{
          opacity: !boxConnected || showFinalNotice ? '0.3' : '1',
          pointerEvents: showFinalNotice ? 'none' : 'initial',
        }}
        justifyContent={{ mobile: 'center' }}
        data-testid="opacity-final-notice"
      >
        {cardRemoteContent.map((item) => (
          <CardCommand
            key={item.action}
            cardContent={item}
            isConnected={isConnected}
            isDirectMode={isDirectMode}
            setShowFinalNotice={setShowFinalNotice}
            shipmentId={shipment.id}
            setErrorMessage={setErrorMessage}
            setError={setError}
            // getLockState={getLockState}
          />
        ))}
      </Grid>
      {showFinalNotice && (
        <Stack
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Text
            variant="titleL"
            color="custom.neutral.black.90"
            data-testid="end-process-message"
          >
            Delivery process successfully completed
          </Text>
        </Stack>
      )}
      {loader && !boxConnected && (
        <Stack
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          spacing={-3}
        >
          <Loader />
          <Text
            variant="titleS"
            color="custom.neutral.black.90"
            data-testid="connection-loader"
          >
            Connection in progress...
          </Text>
        </Stack>
      )}
    </Grid>
  );
};
