import React from 'react';

import { RoutesProps, useLocation } from 'react-router-dom';

import NotAllowed from '../views/NotAllowed';
import { useAuth0 } from '@/shared/auth0';

interface ProtectedRouteProps {
  exact?: boolean;
  path: string;
  component?: any;
  authenticated: boolean;
  userScopes: string[];
  render?: any;
  requiredScopes?: string[];
  scopes?;
}

const ProtectedRoute = (props: RoutesProps & ProtectedRouteProps) => {
  const {
    authenticated,
    requiredScopes,
    userScopes,
    component: Component,
  } = props;

  const location = useLocation();
  const { loginWithRedirect } = useAuth0();

  if (authenticated) {
    if (requiredScopes?.some((val) => userScopes.includes(val))) {
      return <Component {...props} />;
    }

    return <NotAllowed />;
  }

  loginWithRedirect({
    appState: { returnTo: location.pathname },
    ui_locales: 'en',
  });

  return;
};

export default ProtectedRoute;
