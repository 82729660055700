import { useCallback } from 'react';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useAddAccountToShop: any = (shopId) => {
  const successMsg = useToasts((state) => state.addItem);
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpVillage');
  const addError = useError();

  const addAccount = useCallback(
    async (data) => {
      const res = await makeRequest({
        path: `/admin/shops/${shopId}/accounts`,
        method: 'post',
        body: data,
      });
      if (!!res.error) {
        addError(res.error);

        return { success: false };
      }
      successMsg(
        { msg: 'Account was successfully added', type: MessageType.Success },
        'root',
        {}
      );

      return { success: true };
    },
    [addError, shopId, makeRequest, successMsg]
  );

  return [{ loading }, addAccount];
};
export default useAddAccountToShop;
