import React from 'react';

import {
  CountriesArray,
  PhoneNumber as DSPhoneNumber,
  PhoneNumberProps,
} from '@lp/ds-next';
import { LanguageTypes } from '@lp/ds-next/dist/components/PhoneNumber/PhoneNumber';

import { countriesData } from './countriesData';

/**
 * Intermediate component to wrap the PhoneNumber component from the design system
 * with the i18n translation function for inputPlaceholderLabel
 * and the countries data.
 * and the language.
 */
export const PhoneNumber = ({
  language,
  inputPlaceholderLabel,
  countries,
  ...rest
}: Omit<
  PhoneNumberProps,
  'language' | 'inputPlaceholderLabel' | 'countries'
> & {
  language?: LanguageTypes;
  inputPlaceholderLabel?: string;
  countries?: CountriesArray;
}) => (
  <DSPhoneNumber
    language={language || 'en'}
    inputPlaceholderLabel={inputPlaceholderLabel || 'Search for a country'}
    countries={countries || countriesData}
    {...rest}
  />
);
