import React, { useCallback, useMemo, useState } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Button as AntdButton } from 'antd';
import styled from 'styled-components';

import useQualityCheckType from '../hooks/useQualityCheckType';
import { IQualityCheckType } from '../models/qualityCheck';
import CustomModal from '@/components/CustomModal';
import InputIdForm from '@/components/InputIdForm';
import ScopedCta from '@/components/ScopedCta';
import P from '@/components/text/P';
import { scopes } from '@/config';

const StyledP = styled(P)`
  margin-bottom: 1rem;
`;

const Archived = styled(P)`
  font-size: 2rem;
  color: red;
  margin: 0;
  line-height: 2rem;
`;

const ArchiveQualityCheckType = ({
  qualityCheckType,
  setQualityCheckType,
}: {
  qualityCheckType: IQualityCheckType;
  setQualityCheckType: any;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [inputtedId, setInputtedId] = useState({
    data: '',
    touched: false,
  });

  const { archiveQualityCheckType, loadingArchiveType } = useQualityCheckType();

  const handleClick = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const closeModal = useCallback(() => {
    setShowModal(false);
    setInputtedId({ data: '', touched: false });
  }, []);

  const hasError = useMemo(
    () => inputtedId?.data?.trim() !== qualityCheckType.id,
    [qualityCheckType.id, inputtedId]
  );

  const handleSubmit = useCallback(async () => {
    const { success } = await archiveQualityCheckType(qualityCheckType.id);
    if (success) {
      closeModal();
      setQualityCheckType((prev) => ({ ...prev, isArchived: true }));
    }
  }, [
    archiveQualityCheckType,
    closeModal,
    qualityCheckType.id,
    setQualityCheckType,
  ]);

  return (
    <>
      {qualityCheckType.isArchived ? <Archived>ARCHIVED</Archived> : null}
      <ScopedCta
        component={AntdButton}
        type="primary"
        icon={<EditOutlined />}
        shape="round"
        onClick={handleClick}
        loading={loadingArchiveType}
        disabled={qualityCheckType.isArchived}
        requiredScopes={[scopes.MANAGE_QUALITY_CHECKS]}
      >
        Archive Quality Check Type
      </ScopedCta>
      <CustomModal
        open={showModal}
        onCancel={closeModal}
        title="You are about to archive this quality check type"
        okText="Archive Quality Check Type"
        cancelText="Close"
        okButtonProps={{
          disabled: hasError,
          loading: loadingArchiveType,
        }}
        width="30%"
        onOk={handleSubmit}
      >
        <InputIdForm
          placeholder="Quality-Check-Type-ID"
          text={
            <StyledP>
              To archive this quality check type, please enter its ID:
            </StyledP>
          }
          hasError={hasError}
          inputtedId={inputtedId}
          setId={setInputtedId}
          inputName="archive-quality-check-type-modal"
        />
      </CustomModal>
    </>
  );
};

export default ArchiveQualityCheckType;
