import React from 'react';

import styled from 'styled-components';

import WarningIcon from './icons/WarningIcon';
import Spacer from './layout/Spacer';

const CenteredWrapper = styled.div`
  display: flex;
  margin-top: 8rem;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  flex-direction: column;
`;

const ResourceNotFound = ({
  resourceName,
  resourceId,
  wrapperStyle = {},
}: {
  resourceName?: string;
  resourceId?: string;
  wrapperStyle?: any;
}) => (
  <CenteredWrapper style={wrapperStyle}>
    <WarningIcon />
    <Spacer v="2rem" />
    <div>
      Sorry, the specified {resourceName || 'Resource'}{' '}
      {resourceId && `with ID: "${resourceId}"`} was not found!
    </div>
  </CenteredWrapper>
);

export default ResourceNotFound;
