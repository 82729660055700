import React from 'react';

import { NewTooltip as Tooltip, Text } from '@lp/ds-next';
import { Link } from 'react-router-dom';

import { formatDate } from '@/helpers/dates';

export const dateFormatter = (value) => formatDate(new Date(value));

export const linkToAccountFormatter = (value) => {
  if (!value) return '-';

  if (value.match(/-/)) {
    return (
      <Tooltip title={value}>
        <Text
          component={Link}
          variant="bodyTextL"
          color="custom.primary.100"
          to={`/lp-account/${value}`}
          target="_blank"
        >
          {`${value.slice(0, 12)}…${value.slice(25, 36)}`}
        </Text>
      </Tooltip>
    );
  }

  return <Text variant="bodyTextL">{value}</Text>;
};
