import { useEffect } from 'react';

import get from 'lodash/get';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

export const useDefaultActiveOffer = (dispatch: any = null) => {
  const [{ data, error, loading }, makeRequest] =
    useImperativeRequestWrapper('serviceLpAccount');
  const addError = useError();

  useEffect(() => {
    makeRequest({
      path: '/admin/offers/active/default',
      method: 'get',
    }).then((res) => {
      if (!!res.error) {
        addError(new Error('Unable to find default active offer!'));

        return;
      }
      if (get(res, 'data.offer.id', '') && dispatch) {
        dispatch({
          type: 'updateDefaultActiveOfferId',
          args: { defaultActiveOfferId: res.data.offer.id },
        });
      }
    });
  }, [makeRequest, addError, dispatch]);

  return { data, error, loading };
};
