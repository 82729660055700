import React from 'react';

import { HStack } from '@chakra-ui/react';

const FormRow = ({
  children,
  width,
}: {
  children: React.ReactNode;
  width?: string;
}) => (
  <HStack spacing={6} width={width || '100%'} alignItems="flex-start">
    {children}
  </HStack>
);
export default FormRow;
