import React, { useEffect } from 'react';

import { Route, Routes } from 'react-router-dom';

import useTosStore from './hooks/useTosStore';
import TosDetailView from './views/TosDetailView';
import TosListView from './views/TosListView';
import ScopedRoute from '@/components/ScopedRoute';
import { scopes } from '@/config';
import { appRoutes } from '@/configs/appRoutes';
import useAppState from '@/hooks/useAppState';

const TosRouter = () => {
  const dispatch = useTosStore((state) => state.dispatch);
  const userScopes = useAppState((state) => state.scopes);
  useEffect(() => () => dispatch({ type: 'reset' }), [dispatch]);

  return (
    <Routes>
      <Route
        path={appRoutes.ROOT}
        element={
          <ScopedRoute
            component={<TosListView />}
            userScopes={userScopes}
            requiredScopes={[scopes.MANAGE_TOS]}
          />
        }
      />
      <Route path={appRoutes.TOS.TOS_ID} element={<TosDetailView />} />
    </Routes>
  );
};

export default TosRouter;
