import React, { useCallback } from 'react';

import { Col, Row, Checkbox } from 'antd';
import get from 'lodash/get';
import styled from 'styled-components';

import CountrySelector from './forms/CountrySelector';
import TextInput from './forms/TextInput';
import useInputFocus from '../hooks/useInputFocus';
import { LPVillageAddressTypeSelector } from '@/features/lp-village';
import Label from '@/shared/components/Label';
import Toggle from '@/shared/components/Toggle';

const options = [
  { label: 'Shipping', value: 'shipping' },
  { label: 'Billing', value: 'billing' },
  { label: 'Contract', value: 'contract' },
  { label: 'Unknown', value: 'unknown' },
];

const Wrapper = styled.div`
  margin-top: 1rem;
`;

const MarginRow = styled(Row)`
  margin-bottom: 1rem;
`;

const LABELS = {
  firstName: 'First Name',
  lastName: 'Last Name',
  company: 'Company',
  displayName: 'Display Name',
  street: 'Street',
  street2: 'Street cont.',
  houseNumber: 'House Number',
  postalCode: 'Postal Code',
  state: 'State',
  city: 'City',
  country: 'Country',
  type: 'Type',
};

interface Props {
  address: any;
  setAddress?: (input: any) => any;
  disabled?: boolean;
  disableTypeSelector?: boolean;
  hideAddressTypeFields?: boolean;
  hidePrimary?: boolean;
  initialFocus?: boolean;
  isPartnerOrShop?: boolean;
}

const AddressForm = ({
  address,
  setAddress = () => {},
  disabled = false,
  disableTypeSelector = false,
  hideAddressTypeFields = false,
  hidePrimary = false,
  initialFocus = true,
  isPartnerOrShop = false,
}: Props) => {
  const [ref] = useInputFocus({ initialFocus: initialFocus });
  const getLabel = useCallback(
    (name: string, required: boolean = false) =>
      required && !disabled
        ? `${get(LABELS, name, '')} *`
        : get(LABELS, name, ''),
    [disabled]
  );

  const handleChangeText = useCallback(
    (event) => {
      setAddress({ ...address, [event.target.name]: event.target.value });
    },
    [setAddress, address]
  );

  const handleCountryCodeChange = useCallback(
    (option) => {
      setAddress({ ...address, countryCode: option });
    },
    [setAddress, address]
  );

  const handleTypesChange = useCallback(
    (checkedValues) =>
      setAddress((prev) => ({ ...prev, types: checkedValues })),
    [setAddress]
  );

  const handleTypeChange = useCallback(
    (value) => setAddress((prev) => ({ ...prev, type: value.name })),
    [setAddress]
  );

  const handleToggleChange = useCallback(() => {
    !disabled &&
      setAddress((prev) => ({ ...prev, isPrimary: !prev.isPrimary }));
  }, [disabled, setAddress]);

  return (
    <>
      {!hidePrimary && (
        <MarginRow>
          <Toggle
            checked={address.isPrimary}
            text="Primary Address"
            toggle={handleToggleChange}
          />
        </MarginRow>
      )}
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            ref={ref}
            label={getLabel('firstName', true)}
            name="firstName"
            onChange={handleChangeText}
            value={address.firstName}
            disabled={disabled}
          />
        </Col>
        <Col span={12}>
          <TextInput
            label={getLabel('lastName', true)}
            name="lastName"
            onChange={handleChangeText}
            value={address.lastName}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <TextInput
          label={getLabel('company')}
          name="company"
          onChange={handleChangeText}
          value={address.company}
          disabled={disabled}
        />
      </Row>
      <Row>
        <TextInput
          label={getLabel('displayName')}
          name="displayName"
          onChange={handleChangeText}
          value={address.displayName}
          disabled={disabled}
        />
      </Row>
      <Row>
        <TextInput
          label={getLabel('street', true)}
          name="street"
          onChange={handleChangeText}
          value={address.street}
          disabled={disabled}
        />
      </Row>
      <Row>
        <TextInput
          label={getLabel('street2')}
          name="street2"
          onChange={handleChangeText}
          value={address.street2}
          disabled={disabled}
        />
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            label={getLabel('houseNumber', false)}
            name="houseNumber"
            onChange={handleChangeText}
            value={address.houseNumber}
            disabled={disabled}
          />
        </Col>
        <Col span={12}>
          <TextInput
            label={getLabel('postalCode', true)}
            name="postalCode"
            onChange={handleChangeText}
            value={address.postalCode}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            label={getLabel('state', false)}
            name="state"
            onChange={handleChangeText}
            value={address.state}
            disabled={disabled}
          />
        </Col>
        <Col span={12}>
          <TextInput
            label={getLabel('city', true)}
            name="city"
            onChange={handleChangeText}
            value={address.city}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <CountrySelector
            label={getLabel('country', true)}
            width="100%"
            name="country"
            onChange={handleCountryCodeChange}
            countryCodeValue={address.countryCode}
            isDisabled={disabled}
          />
        </Col>
        <Col span={12}>
          {!hideAddressTypeFields &&
            (isPartnerOrShop ? (
              <LPVillageAddressTypeSelector
                label={getLabel('type', true)}
                onChange={handleTypeChange}
                value={address.type}
                disabled={disabled || disableTypeSelector}
              />
            ) : (
              <>
                <div>
                  <Label>{getLabel('type')}</Label>
                </div>
                <Wrapper>
                  <Checkbox.Group
                    options={options}
                    onChange={handleTypesChange}
                    value={address.types}
                    disabled={disabled}
                  />
                </Wrapper>
              </>
            ))}
        </Col>
      </Row>
    </>
  );
};

export default AddressForm;
