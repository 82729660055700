import { useQuery } from '@tanstack/react-query';

import { Address } from '../types/address';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export type AddressesResponse = {
  total: string;
  items: Address[];
};

export const getAddressesPartner = async ({
  partnerId,
  makeRequest,
}: {
  partnerId: string;
  makeRequest: any;
}): Promise<AddressesResponse> => {
  const { data, error } = await makeRequest({
    path: `/admin/partners/${partnerId}/addresses`,
  });

  if (error) {
    throw new Error(error);
  }

  return data;
};

type QueryFnType = typeof getAddressesPartner;

type UseAddressPartnerOptions = {
  partnerId: string;
  config?: QueryConfig<QueryFnType>;
};

export const usePartnerAddresses = ({
  partnerId,
  config,
}: UseAddressPartnerOptions) => {
  const [, makeRequest] = useImperativeRequestWrapper('lpVillage');

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['partner', partnerId, 'addresses'],
    queryFn: () => getAddressesPartner({ partnerId, makeRequest }),
  });
};
