import { useCallback } from 'react';

import useLPAccountContributionsStore from './useLPAccountContributionsStore';
import contributionsPostProcessor from '../helpers/contributionsPostProcessor';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useRefreshAccountContribs = () => {
  const addError = useError();
  const pageSize = useLPAccountContributionsStore((state) => state.pageSize);
  const sortBy = useLPAccountContributionsStore((state) => state.sortBy);
  const order = useLPAccountContributionsStore((state) => state.order);
  const offset = useLPAccountContributionsStore((state) => state.offset);
  const dispatch = useLPAccountContributionsStore((state) => state.dispatch);

  const [, makeRequest] = useImperativeRequestWrapper('serviceLpAccount');

  const getContributions = useCallback(
    async (id: string) => {
      const { data, error } = await getPaginatedTableData({
        params: {
          path: `/admin/contributions/byaccount/${id}`,
          method: 'get',
        },
        reducer: contributionsPostProcessor,
        dispatch,
        addError,
        makeRequest,
        queryParams: {
          pageSize,
          offset,
          sortBy,
          order,
        },
        queryParamsOptions: {
          skipEmptyString: true,
          skipNull: true,
        },
      });

      return { data, error };
    },
    [dispatch, addError, makeRequest, pageSize, offset, sortBy, order]
  );

  return getContributions;
};

export default useRefreshAccountContribs;
