import has from 'lodash/has';

const concatNames = (obj) => {
  const firstName =
    has(obj, 'firstName') && obj.firstName !== ' ' && obj.firstName !== ''
      ? obj.firstName
      : '';
  const lastName =
    has(obj, 'lastName') && obj.lastName !== ' ' && obj.lastName !== ''
      ? obj.lastName
      : '';
  const hasLastName = !(lastName === '');
  const hasFirstName = !(firstName === '');
  if (!hasLastName) return firstName;
  if (!hasFirstName) return lastName;

  return `${lastName}, ${firstName}`;
};

const insertAfterIndex = (
  arg: Array<number | string> | number | string,
  index: number,
  content: any
): string => {
  const argAsArray =
    typeof arg === 'string'
      ? arg.split('')
      : typeof arg === 'number'
        ? arg.toString().split('')
        : arg;

  const output = argAsArray.reduce<Array<string | number>>(
    (out, el, i) => (i === index ? out.concat(el, content) : out.concat(el)),
    []
  );

  return output.join('');
};

const concatUtils = { concatNames, insertAfterIndex };

export default concatUtils;
