import React from 'react';

import { ChangelogTableAndFilters } from './commons/Changelog';
import { Targets } from '../api/getChangelog';
import { dateFormatter } from '../helpers/cellsFormatter';
import { HeadCell } from '../types/changelog';
import { LPAccountsChangelog } from '../types/lp_accounts_changelog';

const headCells: HeadCell[] = [
  {
    id: 'created_at',
    path: 'changelog.created_at',
    label: 'Updated at',
    formatter: dateFormatter,
  },
  {
    id: 'changelog_item_type',
    path: 'changelog.changelog_item_type',
    label: 'Item Type',
  },
  {
    id: 'created_by_id',
    path: 'changelog.created_by_id',
    label: 'Changed by',
  },
];

export const LPAccount = () => (
  <ChangelogTableAndFilters<LPAccountsChangelog>
    target={Targets.LPAccount}
    service="lpAccountV3"
    headCells={headCells}
    title="LP Account"
  />
);
