import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useAddSocialMediaData: any = () => {
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpAccountV3');
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);

  const addSocialMediaData = async (socialMediaData) => {
    const { error } = await makeRequest({
      path: '/sm_articles',
      method: 'post',
      body: { sm_articles: socialMediaData },
    });
    if (error) {
      addError(error);

      return { success: false };
    }
    addSuccessMsg(
      {
        msg: 'Social media article was successfully added!',
        type: MessageType.Success,
      },
      'root',
      {}
    );

    return { success: true };
  };

  return [{ loading }, addSocialMediaData];
};

export default useAddSocialMediaData;
