import React, { CSSProperties } from 'react';

import styled, { ThemeProps, withTheme } from 'styled-components';

const Wrapper = styled.div<{ theme: any }>`
  font-family: ${({ theme }) => theme.fontStyles.adminPanel.monospace};
  color: ${({ theme }) => theme.colors.brand};

  display: flex;
  align-items: center;
  font-size: 0.75rem;
  line-height: 2.75;
  text-align: center;
`;

interface IToggleStyled {
  checked: boolean;
  mr?: string;
  onColor?: string;
  offColor?: string;
  handleColor?: string;
  wrapperStyle?: CSSProperties;
  toggleStyle?: CSSProperties;
}

interface IToggle extends IToggleStyled {
  checked: boolean;
  toggle: (e: React.SyntheticEvent<HTMLSpanElement>) => void;
  text?: string;
}

const SCToggle = styled.span<IToggleStyled>`
  background: ${({ checked, offColor, onColor, theme }) =>
    checked
      ? onColor || theme.colors.adminPanel.brand
      : offColor || theme.colors.adminPanel.greyVarF2};
  display: block;
  width: 2.4375rem;
  height: 1.25rem;
  position: relative;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  transition: all 0.2s ease;
  border-radius: 0.625rem;
  padding: 0.0625rem;
  margin-right: ${({ mr }) => mr || '0.2rem'};
  transition: background 0.1s ease;
  &:after {
    background: ${({ handleColor, theme }) =>
      handleColor || theme.colors.white};
    left: ${({ checked }) => (checked ? '55%' : 0)};
    content: '';
    border-radius: 50%;
    transition: left 0.3s ease;
    position: relative;
    display: block;
    width: 1.125rem;
    height: 1.125rem;
  }
`;

const Toggle: React.FC<IToggle & ThemeProps<any>> = ({
  checked,
  text,
  wrapperStyle,
  toggleStyle,
  theme,
  toggle,
  ...rest
}) => (
  <Wrapper theme={theme} style={wrapperStyle}>
    <SCToggle
      theme={theme}
      style={toggleStyle}
      checked={checked}
      onClick={toggle}
      {...rest}
    />
    {text}
  </Wrapper>
);

export default withTheme(Toggle);
