import React from 'react';

import styled from 'styled-components';

import SVG from './SVG';

const Wrapper = styled.div`
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const EditIcon = (props) => (
  <Wrapper onClick={props.onClick}>
    <SVG
      cursor="pointer"
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      {...props}
    >
      <defs>
        <path
          d="M1.5 13.5h13v1h-13v-1zm11.207-9a1 1 0 0 0 0-1.414l-1.793-1.793a1.001 1.001 0 0 0-1.414 0L2 8.793V12h3.207l7.5-7.5zm-2.5-2.5L12 3.793l-1.5 1.5L8.707 3.5l1.5-1.5zM3 11V9.207l5-5L9.793 6l-5 5H3z"
          id="EditIcon_svg__a"
        />
      </defs>
      <use
        fill="#000"
        xlinkHref="#EditIcon_svg__a"
        transform="translate(-1 -1)"
        fillRule="evenodd"
      />
    </SVG>
  </Wrapper>
);

export default EditIcon;
