import { useCallback, useState } from 'react';

import { IServiceLevel } from '../models/serviceLevel';
import { useApiClient } from '@/helpers/ApiClients';

const useAddServiceLevel = () => {
  const [loading, setLoading] = useState(false);
  const device = useApiClient('deviceV3');

  const addServiceLevel = useCallback(
    (body: IServiceLevel) => {
      setLoading(true);

      return device.post<IServiceLevel>(`/service_levels`, body).then(() => {
        setLoading(false);
      });
    },
    [device]
  );

  return { loading, addServiceLevel, setLoading };
};

export default useAddServiceLevel;
