import React, { useCallback, useEffect } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Grid } from '@material-ui/core';
import { ColumnProps } from 'antd/lib/table';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import styled from 'styled-components';

import AddOfferModal from './AddOfferModal';
import OffersTableLegend from './OffersTableLegend';
import offersPostProcessor from '../../helpers/offersPostProcessor';
import { useDefaultActiveOffer } from '../../hooks/useDefaultActiveOffer';
import useOffersStore, { TOffersSortBy } from '../../hooks/useOffersStore';
import ActiveIcon from '@/components/icons/ActiveIcon';
import InActiveIcon from '@/components/icons/InActiveIcon';
import PendingIcon from '@/components/icons/PendingIcon';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import StyledTable from '@/components/tables/StyledTable';
import { scopes } from '@/config';
import {
  getDefaultSortOrder,
  getCurrentPage,
  getOffset,
  parseAntdSorter,
} from '@/helpers/antdTable';
import useAntdColumns from '@/hooks/useAntdColumns';
import usePaginatedTableData from '@/hooks/usePaginatedTableData';
import useWindowSize from '@/hooks/useWindowSize';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
`;

const RowWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const getStateIcon = (state) => {
  switch (state) {
    case 'active':
      return <ActiveIcon />;
    case 'deactivated':
      return <InActiveIcon />;
    case 'notYetActive':
      return <PendingIcon />;
  }

  return '';
};

const SAOffersTable = () => {
  const rowData = useOffersStore((state) => state.rowData);
  const loading = useOffersStore((state) => state.loading);
  const dispatch = useOffersStore((state) => state.dispatch);
  const total = useOffersStore((state) => state.total);
  const pageSize = useOffersStore((state) => state.pageSize);
  const offset = useOffersStore((state) => state.offset);
  const sortBy = useOffersStore((state) => state.sortBy);
  const order = useOffersStore((state) => state.order);
  const defaultSort = useOffersStore((state) => state.defaultSort);
  const onlyActive = useOffersStore((state) => state.onlyActive);
  const offerTypes = useOffersStore((state) => state.offerTypes);
  const newOfferId = useOffersStore((state) => state.newOfferId);
  const defaultActiveOfferId = useOffersStore(
    (state) => state.defaultActiveOfferId
  );

  const windowSize = useWindowSize();

  usePaginatedTableData({
    params: {
      path: '/admin/offers',
      method: 'get',
      reducer: offersPostProcessor,
    },
    dispatch,
    queryParams: {
      pageSize,
      offset,
      sortBy,
      order,
      onlyActive,
      offerTypes,
    },
    service: 'serviceLpAccount',
  });

  useDefaultActiveOffer(dispatch);

  const pagination = {
    current: getCurrentPage(pageSize, offset),
    pageSize,
    total,
    showSizeChanger: true,
  };

  useEffect(
    () => () => {
      dispatch({ type: 'updateDefaultSort' });
    },
    [dispatch]
  );

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const [sortField, sortOrder] = parseAntdSorter(sorter);
      // TODO: add validation that sortField is a valid field
      dispatch({
        type: 'updateSorting',
        args: { sortBy: sortField as TOffersSortBy, order: sortOrder },
      });
      dispatch({
        type: 'updatePagination',
        args: {
          offset: getOffset(pagination.pageSize, pagination.current),
          pageSize: pagination.pageSize,
        },
      });
      dispatch({
        type: 'updateFilters',
        args: {
          onlyActive: isArray(get(filters, 'state'))
            ? filters.state[0] === 'true'
            : false,
          offerTypes: isArray(get(filters, 'offerType'))
            ? filters.offerType
            : [],
        },
      });
    },
    [dispatch]
  );

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: [
      'offerId',
      'createdBy',
      'label',
      'parsedMultiplier',
      'offerType',
      'activeAt',
      'durationInMonth',
      'deactivatedAt',
      'state',
    ],
    columnsSpecialProps: {
      label: {
        sorter: true,
        defaultSortOrder: getDefaultSortOrder(defaultSort, 'label'),
      },
      parsedMultiplier: {
        render: (_, record) => record.parsedMultiplier,
        align: 'center',
        width: '8rem',
      },
      offerType: {
        sorter: true,
        width: '8rem',
        defaultSortOrder: getDefaultSortOrder(defaultSort, 'offerType'),
        filters: [
          { text: 'default', value: 'default' },
          { text: 'pioneer', value: 'pioneer' },
          { text: 'gift', value: 'gift' },
          { text: 'thebag', value: 'thebag' },
          { text: 'reward', value: 'reward' },
          { text: 'singular', value: 'singular' },
        ],
        filterMultiple: false,
      },
      activeAt: {
        render: (_, record) => record.parsedActiveAt,
        sorter: true,
        defaultSortOrder: getDefaultSortOrder(defaultSort, 'activeAt'),
      },
      durationInMonth: { align: 'center' },
      deactivatedAt: {
        render: (_, record) => record.parsedDeactivatedAt,
        sorter: true,
        defaultSortOrder: getDefaultSortOrder(defaultSort, 'deactivatedAt'),
      },
      state: {
        render: (_, record) => getStateIcon(record.state),
        align: 'center',
        width: '6rem',
        fixed: 'right',
        filters: [{ text: 'Only Active', value: 'true' }],
      },
    },
    addDefaultColumns: true,
    defaultSort: defaultSort,
    eyeLinkProps: {
      to: '/sharing-angels/offers',
    },
  });

  const getRowClassName = useCallback(
    (record) => {
      if (!record) {
        return '';
      }
      if (record.id === newOfferId) {
        return 'newRecord';
      }
      if (record.id === defaultActiveOfferId) {
        return 'defaultActiveOffer';
      }
    },
    [newOfferId, defaultActiveOfferId]
  );

  return (
    <>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={11}>
          <Wrapper>
            <RowWrapper>
              <OffersTableLegend />
              <ScopedCta
                component={PillButton}
                icon={<PlusOutlined />}
                onClick={() =>
                  dispatch({
                    type: 'updateAddModal',
                    args: { showAddModal: true },
                  })
                }
                requiredScopes={[scopes.EDIT_SHARING_ANGELS_OFFER]}
              >
                Add offer
              </ScopedCta>
            </RowWrapper>
            <StyledTable
              rowClassName={(record) => getRowClassName(record)}
              rowKey="id"
              dataSource={rowData}
              columns={columns}
              size="small"
              loading={loading}
              pagination={pagination}
              scroll={{ y: get(windowSize, 'height', 0) * 0.65 }}
              onChange={handleTableChange}
            />
          </Wrapper>
        </Grid>
      </Grid>
      <AddOfferModal />
    </>
  );
};

export default SAOffersTable;
