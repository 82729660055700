import React from 'react';

import { Col, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import styled from 'styled-components';

import AddContribution from '../../../sharing-angels/components/AddContribution';
import { ILPAccountUserState } from '../../hooks/useLPAccountUser';
import DeleteLpAccount from '../DeleteLpAccount';
import SendVerificationEmail from '../SendVerificationEmail';
import InlineSpacer from '@/components/layout/InlineSpacer';
import SendResetPasswordLink from '@/components/SendResetPasswordLink';

const ButtonsWrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
`;

const ButtonRow = styled.div<any>`
  display: flex;
  flex-direction: ${(props) => (props.column ? 'column' : 'row')};
  margin-bottom: 1rem;
  & > button:not(:last-child) {
    margin-bottom: ${(props) => (props.column ? '1rem' : 'inherit')};
  }
`;

interface Props {
  id: string;
  user: ILPAccountUserState;
  getUser: () => void;
}

const ActionBarRow = ({ id, user, getUser }: Props) => {
  const {
    data: { parsedDeletedAt },
  } = user;
  const breakpoints = useBreakpoint();

  return (
    <Row justify="space-between">
      <Col xxl={20} xl={18} lg={18} md={24}>
        <ButtonsWrapper>
          <ButtonRow column={!breakpoints.md}>
            <SendVerificationEmail
              user={user.data}
              userLoading={user.loading}
              getUser={getUser}
            />
            <InlineSpacer width="1rem" />
            <SendResetPasswordLink
              user={user.data}
              userLoading={user.loading}
              getUser={getUser}
            />
            <InlineSpacer width="1rem" />
            <AddContribution accountId={id} />
          </ButtonRow>
        </ButtonsWrapper>
      </Col>
      <Col>
        {!parsedDeletedAt && (
          <ButtonRow>
            <DeleteLpAccount user={user.data} getUser={getUser} />
          </ButtonRow>
        )}
      </Col>
    </Row>
  );
};

export default ActionBarRow;
