import React from 'react';

import { withTheme } from 'styled-components';

import SVG from './SVG';

const CopyIcon = (props) => (
  <SVG width="1em" height="1em" viewBox="0 0 14 16" cursor="pointer" {...props}>
    <g
      fill={
        props.isCopied ? props.theme.colors.brand : props.theme.colors.black
      }
      fillRule="nonzero"
    >
      <path d="M9.625 0H.875v13h3.5v3h8.75V3h-3.5V0zm-5.25 12H1.75V1h7v2H4.375v9zm7.875 3h-7V4h7v11z" />
      <path d="M7 6h3.5v1H7zM7 9h3.5v1H7zM7 12h3.5v1H7z" />
    </g>
  </SVG>
);

export default withTheme(CopyIcon);
