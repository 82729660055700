import { useCallback, useEffect, useState } from 'react';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useGetArticleCodes = () => {
  const [, makeRequest] = useImperativeRequestWrapper('device');
  const [articles, setArticles] = useState<any>();
  const addError = useError();

  const getArticleCodes = useCallback(() => {
    makeRequest({
      path: `/articles`,
      method: 'get',
    }).then((res) => {
      if (!!res.error) {
        addError(res.error);

        return;
      }
      setArticles(res.data);
    });
  }, [makeRequest, addError]);
  useEffect(() => {
    getArticleCodes();
  }, [getArticleCodes]);

  return { articles, getArticleCodes };
};

export default useGetArticleCodes;
