import useRefreshPartners from './useRefreshPartners';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useCreatePartner: any = () => {
  const addError = useError();
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpVillage');
  const getPartners = useRefreshPartners();
  const successMsg = useToasts((state) => state.addItem);

  const createPartner = async (partner) => {
    // it not necessary during creation
    if (!partner.maxNbOfShipments) {
      delete partner.maxNbOfShipments;
    }

    const { error, data } = await makeRequest({
      path: '/admin/partners',
      method: 'post',
      body: partner,
    });

    if (error) {
      addError(error);

      return { success: false };
    }
    await getPartners();
    successMsg(
      { msg: 'Partner was successfully added', type: MessageType.Success },
      'root',
      {}
    );

    return { success: true, data };
  };

  return [{ loading }, createPartner];
};

export default useCreatePartner;
