import React from 'react';

import { Grid } from '@material-ui/core';

import SAOfferDetailsContent from './SAOfferDetailsContent';
import PageTitle from '@/components/layout/PageTitle';

const SAOfferDetails = () => (
  <Grid container justifyContent="center" spacing={3}>
    <Grid item xs={11}>
      <PageTitle title="OFFER" />
    </Grid>
    <Grid item xs={11}>
      <SAOfferDetailsContent />
    </Grid>
  </Grid>
);

export default SAOfferDetails;
