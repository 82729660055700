import React, { Fragment } from 'react';

import { Button, Tooltip } from 'antd';
import styled from 'styled-components';

const ButtonWrapper = styled.span`
  margin-left: 8px;
`;

const DisabledButton = ({ step }) => (
  <ButtonWrapper>
    <Tooltip placement="topRight" title="Please fill all required fields">
      <Button key="submit" type="primary" disabled={true}>
        {step === 0 ? 'Next' : 'Submit'}
      </Button>
    </Tooltip>
  </ButtonWrapper>
);

/**
 * A component to display previous/next button in modals
 *
 * @param {number} step  the current modal step (value from parent hook)
 * @param {any} setStep reference to the step hook in the parent modal
 * @param {boolean[]} stepInvalide An array values created by validation functions to disable next/submit button
 * @param {any} handleSubmit A callback to pass to the submit button
 * @param {any} closeModal A callack to close the modal on cancel
 * @param {boolean} loading Value of loading caluclated from parent state
 * @returns
 */
const NextPreviousFooterModal = ({
  step,
  setStep,
  stepInvalid,
  handleSubmit,
  closeModal,
  loading,
}: {
  step: number;
  setStep: any;
  stepInvalid: boolean[];
  handleSubmit: any;
  closeModal: any;
  loading: boolean;
}) => {
  if (step === 0) {
    return [
      <Button key="cancel" onClick={closeModal}>
        Cancel
      </Button>,
      <Fragment key="next">
        {stepInvalid[step] ? (
          <DisabledButton step={step} />
        ) : (
          <Button
            type="primary"
            loading={false}
            onClick={() => setStep((step) => step + 1)}
          >
            Next
          </Button>
        )}
      </Fragment>,
    ];
  } else if (step === stepInvalid.length - 1) {
    return [
      <Button key="previous" onClick={() => setStep((step) => step - 1)}>
        Previous
      </Button>,
      <Fragment key="submit">
        {stepInvalid[step] ? (
          <DisabledButton step={step} />
        ) : (
          <Button type="primary" loading={loading} onClick={handleSubmit}>
            Submit
          </Button>
        )}
      </Fragment>,
    ];
  }

  return [
    <Button key="previous" onClick={() => setStep((step) => step - 1)}>
      Previous
    </Button>,
    <Fragment key="next">
      {stepInvalid[step] ? (
        <DisabledButton step={step} />
      ) : (
        <Button
          type="primary"
          loading={false}
          onClick={() => setStep((step) => step + 1)}
        >
          Next
        </Button>
      )}
    </Fragment>,
  ];
};

export default NextPreviousFooterModal;
