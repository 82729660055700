import React, { useEffect } from 'react';

import { AntDesignOutlined, FieldTimeOutlined } from '@ant-design/icons';
import { Row, Col, Typography, Avatar, Tag } from 'antd';
import styled from 'styled-components';

import { ILPAccountUserState } from '../../hooks/useLPAccountUser';
import useUpdateAccountEmail from '../../hooks/useUpdateAccountEmail';
import InlineSpacer from '@/components/layout/InlineSpacer';
import { scopes } from '@/config';
import useAppState from '@/hooks/useAppState';

const AvatarTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AvatarTitleText = styled(Typography.Text)`
  font-size: 21px;
`;

const AvatarRowWrapper = styled(Row)`
  margin-bottom: 1rem;
`;

const AvatarWrapper = styled.div`
  align-self: center;
`;

interface Props {
  id: string;
  user: ILPAccountUserState;
}

/**
 *  A component the display a header with account informations
 *
 * @component
 * @param {string} id the id of the accoundt
 * @param {hook} user a reference to the parents component user hook
 */
const AvatarRow = ({ id, user }: Props) => {
  /**
   * Is the email input editable
   */
  let editable: any = undefined;

  /**
   * The current user Scopes
   */
  const userScopes = useAppState((state) => state.scopes);

  /**
   * Data to display extracted from user
   */
  const {
    data: {
      email,
      profilePicture,
      parsedDeletedAt,
      parsedCreatedAt,
      parsedUpdatedAt,
      businessType,
    },
  } = user;

  /**
   * Email edtion related hooks
   */
  const [handleUpdateEmail, accountEmail, setAccountEmail] =
    useUpdateAccountEmail(id, email);

  /**
   * sets the accounts on render
   */
  useEffect(() => {
    setAccountEmail(email);
  }, [email, setAccountEmail]);

  /**
   * defines an edit callback if user as matching scopes
   */
  if (userScopes.includes(scopes.EDIT_LPACCOUNT)) {
    editable = { onChange: handleUpdateEmail };
  }

  return (
    <AvatarRowWrapper>
      <Col xl={2}>
        <AvatarWrapper>
          <Avatar
            size={{ xs: 30, sm: 70, md: 65, lg: 70, xl: 78, xxl: 90 }}
            src={profilePicture ? profilePicture : undefined}
            icon={!profilePicture ? <AntDesignOutlined /> : undefined}
          />
        </AvatarWrapper>
      </Col>
      <Col xl={22}>
        <Row>
          <Col xl={14}>
            <AvatarTextWrapper>
              <AvatarTitleText strong copyable={true} editable={editable}>
                {accountEmail}
              </AvatarTitleText>
              <Row>
                <Col
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  xl={16}
                  xxl={12}
                >
                  <b>Account-ID</b>
                  <Typography.Text copyable={true}>{id}</Typography.Text>
                </Col>
                <Col style={{ display: 'flex', flexDirection: 'column' }}>
                  <b>Account-Type</b>
                  <Tag
                    color="#A5A2A2"
                    style={{ width: '100px', fontSize: 'bold' }}
                  >
                    {businessType}
                  </Tag>
                </Col>
              </Row>
            </AvatarTextWrapper>
          </Col>
          <Col>
            <Row justify="end">
              <Col>
                <Typography.Text strong>
                  <FieldTimeOutlined /> Created At
                </Typography.Text>
                <Typography.Paragraph>{parsedCreatedAt}</Typography.Paragraph>
              </Col>
              <InlineSpacer width="1rem" />
              <Col>
                <Typography.Text strong>
                  <FieldTimeOutlined /> Updated At
                </Typography.Text>
                <Typography.Paragraph>{parsedUpdatedAt}</Typography.Paragraph>
              </Col>
              {parsedDeletedAt && (
                <>
                  <InlineSpacer width="1rem" />
                  <Col>
                    <Typography.Text strong>
                      <FieldTimeOutlined /> Deleted At
                    </Typography.Text>
                    <Typography.Paragraph>
                      {parsedCreatedAt}
                    </Typography.Paragraph>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </AvatarRowWrapper>
  );
};

export default AvatarRow;
