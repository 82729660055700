import React from 'react';

import styled from 'styled-components';

import InlineSpacer from './layout/InlineSpacer';
import PillButton from './PillButton';
import ScopedCta from './ScopedCta';

const StyledButtonsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`;

interface IEditButtonsOnForm {
  handleCancel: () => void;
  handleSaveEdit: () => void;
  disabledCancel: boolean;
  disabledSubmit: boolean;
  name?: string;
  requiredScopes?: string[];
}

const EditButtonsOnForm = ({
  handleCancel,
  handleSaveEdit,
  disabledCancel,
  disabledSubmit,
  name,
  requiredScopes,
}: IEditButtonsOnForm) => (
  <StyledButtonsRow>
    <PillButton
      type="default"
      disabled={disabledCancel}
      onClick={handleCancel}
      data-testid={`${name ? `${name}-` : ''}cancel`}
    >
      Cancel
    </PillButton>
    <InlineSpacer width="1rem" />
    <ScopedCta
      component={PillButton}
      disabled={disabledSubmit}
      type="primary"
      onClick={handleSaveEdit}
      data-testid={`${name ? `${name}-` : ''}submit`}
      requiredScopes={requiredScopes}
    >
      Submit
    </ScopedCta>
  </StyledButtonsRow>
);

export default EditButtonsOnForm;
