import { create } from 'zustand';

export interface IContributionsStore {
  rowData: any[];
  pageSize: number;
  offset: number;
  sortBy: TContributionsSortBy;
  order: 'ASC' | 'DESC';
  defaultSort: {
    sortBy: TContributionsSortBy;
    order: 'ASC' | 'DESC';
  };
  error: string | boolean;
  loading: boolean;
  dispatch: (args: TContributionsReducerInput) => void;
  count: number;
  filters: { [key: string]: any };
  total: number;
  searchTerms: {
    paymentUniqueId: string;
  };
}

export type TContributionsSortBy =
  | 'createdAt'
  | 'updatedAt'
  | 'paymentDate'
  | 'totalAmountInCents'
  | 'paymentStatus';

export type TContributionsReducerInput =
  | {
      type: 'updateRowData';
      args: { rowData: any[] };
    }
  | {
      type: 'updateError';
      args: { error: string | boolean };
    }
  | {
      type: 'updateLoading';
      args: { loading: boolean };
    }
  | {
      type: 'updatePagination';
      args: { pageSize: number; offset: number };
    }
  | {
      type: 'updateSorting';
      args: {
        sortBy: TContributionsSortBy;
        order: 'ASC' | 'DESC';
      };
    }
  | {
      type: 'updateCount';
      args: { count: number };
    }
  | {
      type: 'updateTotal';
      args: { total: number };
    }
  | {
      type: 'updateFilters';
      args: {
        filters: {
          [key: string]: any;
        };
      };
    }
  | {
      type: 'updateDefaultSort';
    }
  | {
      type: 'updateSearchTerms';
      args: { [key: string]: string };
    }
  | {
      type: 'reset';
    };

export const contributionsReducer = (
  state: IContributionsStore,
  input: TContributionsReducerInput,
  INITIAL_STATE
) => {
  switch (input.type) {
    case 'updateRowData':
      return { ...state, rowData: input.args.rowData };
    case 'updateError':
      return { ...state, error: input.args.error };
    case 'updateLoading':
      return { ...state, loading: input.args.loading };
    case 'updatePagination':
      return {
        ...state,
        pageSize: input.args.pageSize,
        offset: input.args.offset,
      };
    case 'updateSorting':
      return { ...state, sortBy: input.args.sortBy, order: input.args.order };
    case 'updateCount':
      return { ...state, count: input.args.count };
    case 'updateFilters':
      return { ...state, filters: input.args.filters };
    case 'updateTotal':
      return { ...state, total: input.args.total };
    case 'updateDefaultSort':
      return {
        ...state,
        defaultSort: { sortBy: state.sortBy, order: state.order },
      };
    case 'updateSearchTerms':
      return {
        ...state,
        searchTerms: input.args,
        filters: INITIAL_STATE.filters,
        offset: 0,
      };
    case 'reset':
      return INITIAL_STATE;
  }
};

export const INITIAL_STATE: Omit<IContributionsStore, 'dispatch'> = {
  rowData: [],
  error: '',
  loading: false,
  pageSize: 50,
  offset: 0,
  sortBy: 'updatedAt',
  order: 'DESC',
  defaultSort: {
    sortBy: 'updatedAt',
    order: 'DESC',
  },
  count: 0,
  filters: {
    paymentMethod: [],
    paymentStatus: [],
    offerType: [],
    cancelled: null,
  },
  total: 0,
  searchTerms: {
    paymentUniqueId: '',
  },
};

const useContributionsStore = create<IContributionsStore>((set) => ({
  ...INITIAL_STATE,
  dispatch: (input) =>
    set((state) => contributionsReducer(state, input, INITIAL_STATE)),
}));

export default useContributionsStore;
