import React from 'react';
const DownArrowIcon = (props) => (
  <svg
    width={21}
    height={26}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 20H0a1 1 0 0 0 1 1v-1Zm20 0-10-5.774v11.547L21 20ZM0 0v20h2V0H0Zm1 21h11v-2H1v2Z"
      fill="#008379"
    />
  </svg>
);

export default DownArrowIcon;
