import React, { useContext, useState } from 'react';

import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import { Grid, NewTextField as TextField } from '@lp/ds-next';
import { useFormikContext } from 'formik';

import { PartnerData } from '../../api/useLPPartner';
import {
  IPartnerV3,
  PARTNER_BUSINESS_STATUS,
  PARTNER_BUSINESS_TYPE,
} from '../../models/partnerV3';
import { CurrentPartnerContext } from '../../views/LPPartnerDetails';

const NumberShipments = () => {
  const { partnerState: partner } = useContext<PartnerData>(
    CurrentPartnerContext
  );

  const [canUpdate, setCanUpdate] = useState(false);

  const {
    handleChange,
    values,
    setFieldValue,
    initialValues: { max_nb_of_shipments: initMaxNbOfShipments },
  } = useFormikContext<IPartnerV3>();

  if (
    values.business_information.status === PARTNER_BUSINESS_STATUS.VALIDATED &&
    values.business_information.type !== PARTNER_BUSINESS_TYPE.TEST
  ) {
    return null;
  }

  return (
    <Grid container gap={2}>
      <Grid item mobile={12} tablet={4}>
        <TextField
          label="Number of Shipments"
          value={partner.data?.nb_of_shipments}
          disabled
        />
      </Grid>
      <Grid item mobile={10} tablet={4}>
        <TextField
          type="number"
          label="Max of Shipments"
          name="max_nb_of_shipments"
          value={values.max_nb_of_shipments}
          onChange={handleChange}
          disabled={!canUpdate}
        />
      </Grid>

      <Grid item mobile={1}>
        {!canUpdate ? (
          <EditOutlined
            onClick={() => setCanUpdate(!canUpdate)}
            style={{ fontSize: '24px', paddingBottom: '1rem' }}
          />
        ) : (
          <CloseOutlined
            onClick={() => {
              setCanUpdate(!canUpdate);
              // Reset value
              setFieldValue('max_nb_of_shipments', initMaxNbOfShipments);
            }}
            style={{
              fontSize: '24px',
              paddingBottom: '1rem',
              color: '#ff4d4f',
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default NumberShipments;
