import React from 'react';

import { Col, Row } from 'antd';
import styled from 'styled-components';

import RoleSelector from '../../roles/components/RoleSelector';
import { IRole } from '../../roles/models/role';
import { AccountForm, IUserWithRoles } from '@/features/lp-account-users';

const StyledCol = styled(Col)`
  margin-bottom: 1rem;
`;

const PartnerAccountForm = ({
  account,
  selectedRole,
  setSelectedRole,
  disabled = true,
  domain,
}: {
  account: IUserWithRoles;
  selectedRole: IRole;
  setSelectedRole: any;
  disabled?: boolean;
  domain: 'partners' | 'shops';
}) => (
  <>
    <AccountForm account={account} />
    <Row gutter={24}>
      <StyledCol span={16} />
      <Col span={8}>
        <RoleSelector
          onChange={setSelectedRole}
          role={selectedRole}
          disabled={disabled}
          domain={domain}
        />
      </Col>
    </Row>
  </>
);

export default PartnerAccountForm;
