const boxClosestToDatePostProcessor = (data, timeSearched) => {
  if (!data || data.length === 0) {
    return '';
  }
  if (data.length === 1) {
    return data[0].boxFirmwareVersion;
  }
  const firmwareAtTime = data.filter(
    (firmware) => firmware.createdAt <= timeSearched
  );

  return firmwareAtTime.length > 0
    ? firmwareAtTime[0].boxFirmwareVersion
    : 'Unknown';
};

export default boxClosestToDatePostProcessor;
