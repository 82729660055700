import { useMutation } from '@tanstack/react-query';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { MutationConfig, queryClient } from '@/lib/react-query';

export const deleteAddressPartner =
  (makeRequest: any) =>
  async ({ addressId }: { addressId: string }): Promise<void> => {
    const { error } = await makeRequest({
      path: `/admin/addresses/${addressId}`,
      method: 'delete',
    });

    if (error) {
      throw new Error(error);
    }
  };

type UseDeleteAddressOptions = {
  partnerId: string;
  config?: MutationConfig<ReturnType<typeof deleteAddressPartner>>;
};

export const useDeletePartnerAddress = ({
  partnerId,
  config,
}: UseDeleteAddressOptions) => {
  const [, makeRequest] = useImperativeRequestWrapper('lpVillage');
  const addError = useError();

  return useMutation({
    onError: () => {
      addError(`Unable to delete the partner address, an error occurred.`);
    },
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: ['partner', partnerId, 'addresses'],
      });
    },
    ...config,
    mutationFn: deleteAddressPartner(makeRequest),
  });
};
