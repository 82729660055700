import get from 'lodash/get';
import styled, { ThemeProps } from 'styled-components';

const defaults = {
  colors: {
    black: '#000',
  },
  fonts: {
    sansserif: 'TTNormsPro-Regular',
  },
};

const Label = styled.label<{ color?: string } & ThemeProps<any>>`
  font-family: ${({ theme }) =>
    get(theme, 'fonts.sansserif', defaults.fonts.sansserif)};
  color: ${({ color, theme }) =>
    color || get(theme, 'colors.black', defaults.colors.black)};
`;

export default Label;
