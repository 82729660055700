import { useCallback } from 'react';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useChangePartnerServiceLevel = () => {
  const addError = useError();
  const [, makeRequest] = useImperativeRequestWrapper('device');

  const successMsg = useToasts((state) => state.addItem);

  const changeServiceLevel = useCallback(
    async (partnerId: string, serviceLevelId: number) => {
      const { error } = await makeRequest({
        path: `/partners/${partnerId}/service-level`,
        method: 'put',
        body: { id: serviceLevelId },
      });
      if (error) {
        addError(error);

        return;
      }
      successMsg(
        {
          msg: 'Successfully changed service level',
          type: MessageType.Success,
        },
        'root',
        {}
      );
    },
    [makeRequest, addError, successMsg]
  );

  return changeServiceLevel;
};

export default useChangePartnerServiceLevel;
