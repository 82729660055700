import { useCallback, useState } from 'react';

type OrderDirection = 'DESC' | 'ASC';

export interface PaginationParams {
  page: number;
  pageSize: number;
  orderDirection: OrderDirection;
  orderColumn: string;
}

const DEFAULT_INITIAL_PAGINATION_PARAMS: PaginationParams = {
  page: 1,
  pageSize: 100,
  orderDirection: 'DESC',
  orderColumn: 'updatedAt',
};

export interface UsePaginationValues {
  params: PaginationParams;
  setParams: (params: Partial<PaginationParams>) => void;
}

export function usePagination(
  initialPaginationParams: PaginationParams = DEFAULT_INITIAL_PAGINATION_PARAMS
): UsePaginationValues {
  const [params, setParams] = useState<PaginationParams>(
    initialPaginationParams
  );

  const setPartialParams = useCallback(
    (newParams: Partial<PaginationParams>) =>
      setParams((prevParams) => ({ ...prevParams, ...newParams })),
    [setParams]
  );

  return {
    params,
    setParams: setPartialParams,
  };
}
