import React from 'react';

import { withTheme } from 'styled-components';
const TabletIcon = (props) => (
  <svg
    width={39}
    height={23}
    viewBox="0 0 39 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} y={1.5} width={37} height={20.7143} rx={0.5} stroke="black" />
    <rect
      x={9.75}
      y={5.32143}
      width={18.5}
      height={13.0714}
      rx={0.25}
      fill="#D9D9D9"
      stroke="black"
      strokeWidth={0.5}
    />
    <path
      d="M1 0.5C0.723858 0.5 0.5 0.723858 0.5 1C0.5 1.27614 0.723858 1.5 1 1.5V0.5ZM38 1H38.5C38.5 0.723858 38.2761 0.5 38 0.5V1ZM37.5 21.5C37.5 21.7761 37.7239 22 38 22C38.2761 22 38.5 21.7761 38.5 21.5H37.5ZM1 1.5H38V0.5H1V1.5ZM37.5 1V21.5H38.5V1H37.5Z"
      fill="black"
    />
    <rect
      x={30.1429}
      y={5.07143}
      width={2.71429}
      height={2.71429}
      rx={1.35714}
      fill="black"
    />
    <rect
      x={5.42857}
      y={5.07143}
      width={2.71429}
      height={2.71429}
      rx={1.35714}
      fill="black"
    />
    <rect
      x={30.1429}
      y={15.7857}
      width={2.71429}
      height={2.71429}
      rx={1.35714}
      fill="black"
    />
    <rect
      x={5.42859}
      y={15.7857}
      width={2.71429}
      height={2.71429}
      rx={1.35714}
      fill="black"
    />
  </svg>
);

export default withTheme(TabletIcon);
