import React from 'react';

import { Stack, Text, Divider, Grid } from '@lp/ds-next';
import { Card, CardContent } from '@mui/material';
import { useFormikContext } from 'formik';

import NumberShipments from './NumberShipments';
import { IPartnerV3 } from '../../models/partnerV3';
import PartnerBusinessInformationSelectorV3 from '../PartnerBusinessInformationSelectorV3';
import PartnerInternalSelector from '../PartnerInternalSelector';

const AccountManagement = () => {
  const { values, setFieldValue, errors } = useFormikContext<IPartnerV3>();

  return (
    <Card elevation={0} sx={{ borderRadius: '10px' }}>
      <CardContent>
        <Grid container direction="column">
          {/* ----- Header ----- */}
          <Grid item mobile={12}>
            <Stack direction="row" justifyContent="space-between">
              <Text variant="titleL" component="div">
                Account Management
              </Text>
            </Stack>
            <Divider sx={{ mt: '10px', mb: '20px' }} />
          </Grid>
          {/* ----- Account Management ----- */}
          <Grid item mobile={12} container direction="row" spacing={1}>
            <Grid item mobile={12} tablet={4}>
              <PartnerInternalSelector
                onChange={(value: boolean) => {
                  setFieldValue('is_internal', value);
                }}
                name="is_internal"
                value={{ is_internal: values.is_internal }}
                style={{ marginBottom: '2rem' }}
                data-testid="partnerIsInternal"
              />
            </Grid>
            <Grid item mobile={12} tablet={8}>
              <PartnerBusinessInformationSelectorV3
                onChangeType={(type: string) =>
                  setFieldValue('business_information.type', type)
                }
                onChangeStatus={(status: string) =>
                  setFieldValue('business_information.status', status)
                }
                value={{
                  type: values.business_information.type,
                  status: values.business_information.status,
                }}
                error={errors}
              />
            </Grid>
            <Grid item mobile={12}>
              <NumberShipments />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AccountManagement;
