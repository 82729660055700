import { useCallback, useContext } from 'react';

import boxesPostProcessor from '../helpers/boxesPostProcessor';
import { IArticle } from '../models/box';
import { BoxFirmwareHistoryItem } from '../models/boxFirmware';
import { useApiClient } from '@/helpers/ApiClients';
import { PaginationParams } from '@/hooks/usePagination';
import { ArticlesContext } from '@/shared/providers/articlesProvider';

export interface GetBoxFirmwareHistoryParams
  extends Partial<PaginationParams> {}

export interface PaginatedResponse<Type> {
  items: Type[];
  count: number;
  total: number;
}

export type GetBoxFirmwareHistory = (
  boxId: string,
  params?: GetBoxFirmwareHistoryParams
) => Promise<PaginatedResponse<BoxFirmwareHistoryItem>>;

const useGetBoxFirmwareHistory = (): GetBoxFirmwareHistory => {
  const device = useApiClient('device');
  const articles = useContext(ArticlesContext) as IArticle[];

  const getBoxFirmwareHistory = useCallback(
    (
      boxId: string,
      {
        page = 1,
        pageSize = 100,
        orderColumn = 'createdAt',
        orderDirection = 'DESC',
      }: GetBoxFirmwareHistoryParams = {}
    ) =>
      device
        .get<PaginatedResponse<BoxFirmwareHistoryItem>>(
          `/boxes/${boxId}/firmware-version/history`,
          {
            params: {
              offset: (page - 1) * pageSize,
              pageSize,
              sortBy: orderColumn,
              order: orderDirection,
            },
          }
        )
        .then((response) => boxesPostProcessor(response.data, articles)),
    [device, articles]
  );

  return getBoxFirmwareHistory;
};

export default useGetBoxFirmwareHistory;
