import { useCallback } from 'react';

import useQualityCheckTypeStore from './useQualityCheckTypeStore';
import { ICreateQualityCheckType } from '../models/qualityCheck';
import datePostProcessor from '@/helpers/datePostProcessor';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useQualityCheckType = () => {
  const addError = useError();
  const successMsg = useToasts((state) => state.addItem);
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('device');
  const [, makeRequestSingle] = useImperativeRequestWrapper('device');
  const [{ loading: loadingCreate }, makeRequestCreateType] =
    useImperativeRequestWrapper('device');
  const [{ loading: loadingArchiveType }, makeRequestArchiveType] =
    useImperativeRequestWrapper('device');
  const { dispatch, pageSize, offset, sortBy, order } =
    useQualityCheckTypeStore();

  const getQualityCheckTypes = useCallback(async () => {
    await getPaginatedTableData({
      params: {
        path: `/quality-checks/types`,
        method: 'get',
      },
      reducer: datePostProcessor,
      dispatch,
      addError,
      makeRequest,
      queryParams: {
        pageSize: pageSize,
        offset: offset,
        sortBy: sortBy,
        order: order,
      },
      queryParamsOptions: { skipEmptyString: true, skipNull: true },
    });
  }, [addError, dispatch, makeRequest, order, sortBy, pageSize, offset]);

  const getQualityCheckType = useCallback(
    async (id: string) => {
      const { data, error } = await makeRequestSingle({
        path: `/quality-checks/types/${id}`,
        method: 'get',
      });
      if (error) {
        addError(error);

        return { success: false, data: null };
      }

      return { success: true, data };
    },
    [addError, makeRequestSingle]
  );

  const createQualityCheckType = useCallback(
    async (qualityCheckType: ICreateQualityCheckType) => {
      const { error } = await makeRequestCreateType({
        path: '/quality-checks/types',
        method: 'post',
        body: qualityCheckType,
      });
      if (error) {
        addError(error);

        return { success: false };
      }
      successMsg(
        {
          msg: 'Quality check type successfully added',
          type: MessageType.Success,
        },
        'root',
        {}
      );

      return { success: true };
    },
    [addError, makeRequestCreateType, successMsg]
  );

  const archiveQualityCheckType = useCallback(
    async (id: string) => {
      const { error } = await makeRequestArchiveType({
        path: `/quality-checks/types/${id}/archive`,
        method: 'post',
      });
      if (error) {
        addError(error);

        return { success: false };
      }
      successMsg(
        {
          msg: 'Quality check type successfully archived',
          type: MessageType.Success,
        },
        'root',
        {}
      );

      return { success: true };
    },
    [addError, makeRequestArchiveType, successMsg]
  );

  return {
    loading,
    loadingCreate,
    loadingArchiveType,
    createQualityCheckType,
    getQualityCheckTypes,
    getQualityCheckType,
    archiveQualityCheckType,
  };
};
export default useQualityCheckType;
