import has from 'lodash/has';

import { SerializedBoxContract, BoxContract } from '../models/boxContract';

interface IGetBoxContractsResponse {
  count: number;
  total: number;
  items: SerializedBoxContract[];
}

interface IProcessBoxContracts {
  count: number;
  total: number;
  items: BoxContract[];
}

export function deserializeBoxContract(
  source: SerializedBoxContract
): BoxContract {
  return {
    ...source,
    createdAt: new Date(source.createdAt * 1000),
    updatedAt: new Date(source.updatedAt * 1000),
    paymentReceivedAt: new Date(source.paymentReceivedAt * 1000),
    activatedAt: new Date(source.activatedAt * 1000),
    paymentDate: new Date(source.paymentDate * 1000),
    email: has(source, 'account.email') ? source.account.email : source.email,
    firstName: has(source, 'account.firstName') ? source.account.firstName : '',
    lastName: has(source, 'account.lastName') ? source.account.lastName : '',
  };
}

export function serializeBoxContract(
  contract: BoxContract
): SerializedBoxContract {
  return {
    ...contract,
    createdAt: contract.createdAt.valueOf() / 1000,
    updatedAt: contract.updatedAt.valueOf() / 1000,
    paymentReceivedAt: contract.paymentReceivedAt.valueOf() / 1000,
    activatedAt: contract.activatedAt.valueOf() / 1000,
    paymentDate: contract.paymentDate.valueOf() / 1000,
    account: {
      email: contract.email,
      firstName: contract.firstName,
      lastName: contract.lastName,
    },
  };
}

const boxContractsPostProcessor = (
  data: IGetBoxContractsResponse | undefined
): IProcessBoxContracts | {} => {
  if (!data?.items) {
    return {};
  }

  return { ...data, items: data.items.map(deserializeBoxContract) };
};

export default boxContractsPostProcessor;
