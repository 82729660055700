import React from 'react';

import get from 'lodash/get';
import styled, { ThemeProps } from 'styled-components';

import SimpleCircle from './SimpleCircle';

export enum ButtonSize {
  Small = '2rem',
  Large = 'auto',
}

interface IButton {
  name?: string;
  width?: string;
  height?: ButtonSize | string;
  mw?: string;
  mt?: string;
  mb?: string;
  mr?: string;
  pad?: string;
  bg?: string;
  bs?: string;
  br?: string;
  border?: string;
  bc?: string;
  justify?: string;
  icon?: string;
  color?: string;
  ta?: string;
  h_td?: string;
  ls?: string;
  onClick?: (e?: React.SyntheticEvent) => void;
  children?: React.ReactNode;
  theme?: any;
  tabIndex?: number;
  isDisabled?: boolean;
  isLoading?: boolean;
  loadBg?: string;
}

const ButtonStyled = styled.span<IButton & ThemeProps<any>>`
  border-radius: ${({ br }) => br || '4px'};
  border: ${({ border }) => border || 'none'};
  border-color: ${({ bc }) => bc || 'none'};
  box-shadow: ${({ theme, bs }) =>
    bs || get(theme, 'shadows.very_big', theme.shadows.adminPanel.very_big)};
  background: ${({ theme, bg, isDisabled }) =>
    isDisabled
      ? get(theme, 'colors.greyAlt', theme.colors.adminPanel.grey)
      : bg || get(theme, 'colors.brand', theme.colors.adminPanel.brand)};
  pointer-events: ${({ isDisabled, isLoading }) =>
    isDisabled || isLoading ? 'none' : 'all'};
  font-family: ${({ theme }) =>
    get(
      theme,
      'fonts.adminPanel.fontFamily',
      theme.fontStyles.adminPanel.fontFamily
    )};
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  max-width: ${(props) => props.mw || 'none'};
  margin-top: ${({ mt }) => mt || 0};
  margin-bottom: ${({ mb }) => mb || 0};
  margin-right: ${({ mr }) => mr || 0};
  color: ${({ theme, color }) =>
    color ||
    get(theme, 'colors.adminPanel.white', theme.colors.adminPanel.white)};
  padding: ${({ pad }) => pad || '0.8rem 1.5rem'};
  justify-content: ${({ justify }) => justify || 'center'};
  text-align: ${({ ta }) => ta || 'center'};
  letter-spacing: ${({ ls }) => ls || '1.07px'};
  display: flex;
  align-items: center;
  font-size: 1rem;
  outline: none;
  cursor: pointer;
  transition:
    background 0.2s linear,
    color 0.2s linear;
  &:hover {
    text-decoration: ${({ h_td }) => h_td || 'underline'};
  }
`;

const Button = ({
  height = ButtonSize.Large,
  tabIndex = 0,
  isDisabled,
  isLoading,
  children,
  loadBg,
  ...rest
}: IButton) => (
  <ButtonStyled
    height={height}
    aria-disabled={!!isDisabled}
    aria-live={!!isLoading ? 'polite' : 'off'}
    aria-busy={!!isLoading}
    isDisabled={isDisabled}
    isLoading={isLoading}
    tabIndex={tabIndex}
    {...rest}
  >
    {isLoading && <SimpleCircle bg={loadBg} />}
    {!isLoading && children}
  </ButtonStyled>
);

export default Button;
