import React, { useCallback } from 'react';

import { Radio, Input, Col, Descriptions, Divider, Row } from 'antd';
import styled from 'styled-components';

import ImageThumbnail from './ImageThumbnail';
import useImages from '../hooks/useImages';
import {
  ICreateQualityCheckItem,
  IQualityCheckType,
} from '../models/qualityCheck';
import DragAndDrop from '@/components/DragAndDrop';
import Label from '@/components/forms/Label';
import H2 from '@/components/text/H2';

interface IChecklistItem {
  qualityCheckType: IQualityCheckType;
  setChecklistItem: (item: ICreateQualityCheckItem) => void;
  item: ICreateQualityCheckItem;
}

const Wrapper = styled.div`
  margin: 1.5rem 0;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const WrapperImages = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const ThumbnailWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TextAreaStyle = {
  marginBottom: '1.5rem',
};

const ChecklistItem = ({
  qualityCheckType,
  setChecklistItem,
  item,
}: IChecklistItem) => {
  const { name, expectedLevel, image } = qualityCheckType;
  const { postQualityCheckImages } = useImages();

  const handleRemoveImages = useCallback(() => {
    setChecklistItem({ ...item, images: [] });
  }, [setChecklistItem, item]);

  const handleDropImages = useCallback(
    async (droppedFiles) => {
      const { success, data } = await postQualityCheckImages(droppedFiles);
      success && setChecklistItem({ ...item, images: data?.urls });
    },
    [setChecklistItem, item, postQualityCheckImages]
  );

  const handleChangeStatus = useCallback(
    (e) => {
      setChecklistItem({ ...item, status: e.target.value });
    },
    [setChecklistItem, item]
  );

  const handleChangeComment = useCallback(
    (e) => {
      setChecklistItem({ ...item, comment: e.target.value });
    },
    [setChecklistItem, item]
  );

  return (
    <Wrapper>
      <Row gutter={24} justify="space-between">
        <Col span={12}>
          <Descriptions title={<H2>{name}</H2>} bordered>
            <Descriptions.Item label="Expected Level">
              {expectedLevel}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={6}>
          <ThumbnailWrapper>
            <ImageThumbnail url={image} />
          </ThumbnailWrapper>
        </Col>
      </Row>
      <FormWrapper>
        <Row gutter={24}>
          <Col span={24}>
            <Radio.Group
              style={{ width: '100%', marginBottom: '1rem' }}
              onChange={handleChangeStatus}
              value={item.status}
            >
              <Radio value="OK">OK</Radio>
              <Radio value="NOK">NOT OK</Radio>
            </Radio.Group>
          </Col>
          <Col span={12}>
            <Label>Free comment</Label>
            <Input.TextArea
              style={TextAreaStyle}
              rows={4}
              onChange={handleChangeComment}
              name="comment"
              value={item.comment}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <DragAndDrop
              onDrop={handleDropImages}
              text="Add images"
              multiple={true}
              accept={{
                'image/jpeg': [],
                'image/png': [],
              }}
              height="7rem"
              fileDropped={item.images.length > 0}
              removeFiles={handleRemoveImages}
            />
            {item.images.length > 0 && (
              <WrapperImages>
                {item.images.map((url) => (
                  <ImageThumbnail key={url} url={url} />
                ))}
              </WrapperImages>
            )}
          </Col>
        </Row>
      </FormWrapper>
      <Divider />
    </Wrapper>
  );
};

export default ChecklistItem;
