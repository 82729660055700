import { useCallback, useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';

import { IComposeAccountsUrl } from './useGetAccounts';
import useLPUsersStore from './useLPUsersStore';
import lpAccountPostProcessor from '../helpers/lpAccountPostProcessor';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useRefreshAccounts = ({ id, domain }: IComposeAccountsUrl) => {
  const addError = useError();
  const searchTerms = useLPUsersStore((state) => state.searchTerms);
  const dispatch = useLPUsersStore((state) => state.dispatch);
  const pageSize = useLPUsersStore((state) => state.pageSize);
  const offset = useLPUsersStore((state) => state.offset);
  const sortBy = useLPUsersStore((state) => state.sortBy);
  const order = useLPUsersStore((state) => state.order);

  const [, makeRequest] = useImperativeRequestWrapper(
    id ? 'lpVillage' : 'serviceLpAccount'
  );
  const searchActive = useMemo(
    () => !Object.values(searchTerms).every(isEmpty),
    [searchTerms]
  );

  const path = useMemo(
    () =>
      id
        ? `/admin/${domain}/${id}/accounts`
        : searchActive
          ? '/admin/accounts/search'
          : '/admin/accounts',
    [id, searchActive, domain]
  );

  const getAccounts = useCallback(async () => {
    await getPaginatedTableData({
      params: {
        path: path,
        method: 'get',
      },
      dispatch,
      queryParams: Object.assign(
        {
          pageSize,
          offset,
          sortBy,
          order,
        },
        searchActive ? searchTerms : {}
      ),
      queryParamsOptions: { skipEmptyString: true },
      reducer: lpAccountPostProcessor,
      addError,
      makeRequest,
    });
  }, [
    addError,
    makeRequest,
    path,
    dispatch,
    offset,
    order,
    pageSize,
    searchActive,
    searchTerms,
    sortBy,
  ]);

  return getAccounts;
};
export default useRefreshAccounts;
