import { useCallback, useState } from 'react';

import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

/**
 * A react custom hook used to update an account emal
 * @param {string} accountId the id of the modified account
 * @param {string} email the new email value
 * @returns
 */
const useUpdateAccountEmail: any = (accountId, email) => {
  const [, makeRequest] = useImperativeRequestWrapper('serviceLpAccount');
  const successMsg = useToasts((state) => state.addItem);
  const errorMsg = useToasts((state) => state.addItem);
  const [accountEmail, setAccountEmail] = useState<string>(email);

  /**
   * Calls the backend
   */
  const updateEmail = useCallback(
    async (newValue) => {
      const { error } = await makeRequest({
        path: `/admin/accounts/${accountId}/change-email`,
        method: 'put',
        body: {
          email: newValue,
        },
      });
      if (error) {
        error.response.status === 409
          ? errorMsg(
              {
                msg: 'This email is already used on another account',
                type: MessageType.Error,
              },
              'root',
              {}
            )
          : errorMsg(
              { msg: 'An unexpected error has', type: MessageType.Error },
              'root',
              {}
            );

        return { success: false };
      }
      successMsg(
        {
          msg: 'The email was successfully updated',
          type: MessageType.Success,
        },
        'root',
        {}
      );

      return { success: true };
    },
    [makeRequest, accountId, successMsg, errorMsg]
  );

  /**
   * performs some checks to update the UI
   */
  const handleUpdateEmail = useCallback(
    async (event) => {
      const result = await updateEmail(event);
      if (result.success) {
        setAccountEmail(event);
      }
    },
    [updateEmail]
  );

  return [handleUpdateEmail, accountEmail, setAccountEmail];
};
export default useUpdateAccountEmail;
