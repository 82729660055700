import { useCallback, useEffect, useState } from 'react';

import get from 'lodash/get';

import useServiceLevelsStore from '../../serviceLevels/hooks/useServiceLevelsStore';
import { IModifiedShipment, IQuote, IServiceLevel } from '../models/shipment';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

interface ICarrierService {
  carrierCode: string;
  service: string;
}

const carriersReducer = (
  data: { carrierCode: string; services: string[] }[]
) => {
  let output: ICarrierService[] = [];
  data.forEach((item) => {
    const spreadServices = item.services.map((service) => ({
      carrierCode: item.carrierCode,
      service,
    }));
    output = [...output, ...spreadServices];
  });

  return output;
};

const useGetShipmentQuotes = (shipment: IModifiedShipment) => {
  const [, makeCarriersRequest] = useImperativeRequestWrapper('shipment');
  const [, makeQuotesRequest] = useImperativeRequestWrapper('shipment');
  const [, makeServiceLevelRequest] = useImperativeRequestWrapper('shipment');

  const addError = useError();
  const [quotes, setQuotes] = useState<IQuote[]>([]);
  const [quoteServiceLevels, setQuoteServiceLevels] = useState<IServiceLevel[]>(
    []
  );
  const serviceLevels = useServiceLevelsStore((state) => state.serviceLevels);
  const [loading, setLoading] = useState(true);

  const getServiceLevels = useCallback(async () => {
    const { data, error } = await makeServiceLevelRequest({
      path: `/shipments/${shipment.id}/service-levels`,
      method: 'get',
    });
    if (error) {
      setLoading(false);
      addError(error);

      return;
    }
    data.forEach((quote) => {
      serviceLevels.forEach((serviceLevel) => {
        if (quote.serviceLevel === serviceLevel.id) {
          quote.serviceLevel = serviceLevel.name;
        }
      });
    });
    setQuoteServiceLevels(data);
  }, [makeServiceLevelRequest, shipment.id, addError, serviceLevels]);

  const getCarrierService = useCallback(async () => {
    const countryCode = get(shipment, 'sender.address.countryCode');
    if (!countryCode) {
      addError("Could not fine sender's country code");

      return;
    }
    const { data, error } = await makeCarriersRequest({
      path: `/carriers/${countryCode}`,
      method: 'get',
    });
    if (error) {
      addError(error);

      return;
    }
    let output: ICarrierService[] = [];
    try {
      output = carriersReducer(data);
    } catch (e) {
      addError('Unable to parse carriers!');

      return;
    }

    return output;
  }, [addError, makeCarriersRequest, shipment]);

  const getQuotes = useCallback(async () => {
    setLoading(true);
    const carriers = await getCarrierService();
    if (!carriers) {
      setLoading(false);

      return;
    }
    const { data, error } = await makeQuotesRequest({
      path: `/shipments/${shipment.id}/quotes`,
      method: 'post',
      body: carriers,
    });
    if (error) {
      setLoading(false);
      addError(error);

      return;
    }
    setQuotes(data);
    setLoading(false);
  }, [getCarrierService, makeQuotesRequest, shipment.id, addError]);

  useEffect(() => {
    getQuotes();
    shipment.shipmentType === 'PARTNER' && getServiceLevels();
  }, [getCarrierService, getServiceLevels, getQuotes, shipment.shipmentType]);

  return { quotes, quoteServiceLevels, loading };
};

export default useGetShipmentQuotes;
