import React, { ReactNode, useMemo } from 'react';

import isString from 'lodash/isString';
import { Accept, useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import ReuploadIcon from './icons/ReuploadIcon';

interface IDragAndDrop {
  height?: string;
  onDrop: (input: any) => void;
  multiple?: boolean;
  accept?: Accept;
  text?: ReactNode;
  removeFiles?: () => void;
  fileDropped?: boolean;
}

const Wrapper = styled.div<any>`
  height: ${({ height }) => height || '10rem'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 2px dashed ${(props) => props.theme.colors.brand};
  outline: none;
  background-color: ${(props) =>
    props.fileDropped ? 'rgba(48, 181, 120, 0.3)' : ''};
  cursor: pointer;
`;

const WrapperReupload = styled.div`
  cursor: pointer;
`;

const StyledP = styled.div`
  text-align: center;
`;

const DragAndDrop = ({
  height,
  onDrop,
  multiple,
  accept,
  text = "Drag 'n' drop your file here, or click to select a file",
  removeFiles,
  fileDropped = false,
}: IDragAndDrop) => {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      onDrop,
      multiple: multiple,
      accept: accept,
    });

  const InsideText = useMemo(() => {
    if (isDragActive) {
      return <StyledP>Drop the file here ...</StyledP>;
    }
    if (fileDropped) {
      return <StyledP>{acceptedFiles.map((f) => f.name).join(', ')}</StyledP>;
    }

    return isString(text) ? <StyledP>{text}</StyledP> : text;
  }, [isDragActive, acceptedFiles, fileDropped, text]);

  return (
    <Wrapper height={height} {...getRootProps()} fileDropped={fileDropped}>
      <input {...getInputProps()} disabled={fileDropped} />
      {InsideText}
      {fileDropped && removeFiles ? (
        <WrapperReupload onClick={removeFiles}>
          <ReuploadIcon />
        </WrapperReupload>
      ) : (
        ''
      )}
    </Wrapper>
  );
};

export default DragAndDrop;
