import React, { useState } from 'react';

import { Row } from 'antd';
import styled from 'styled-components';

import offersPostProcessor from '../../helpers/offersPostProcessor';
import useOffersStore from '../../hooks/useOffersStore';
import CustomModal from '@/components/CustomModal';
import Error from '@/components/Error';
import DateTimePicker from '@/components/forms/DateTimePicker';
import parseBeErrorMsg from '@/helpers/beErrorMsgParser';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const StyledRow = styled(Row)`
  margin-top: 1.5rem;
`;

const DeactivateOfferModal = ({ id, visible, onClose }) => {
  const [unixTimeStamp, setUnixTimeStamp] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);
  const dispatch = useOffersStore((state) => state.dispatch);
  const pageSize = useOffersStore((state) => state.pageSize);
  const offset = useOffersStore((state) => state.offset);
  const sortBy = useOffersStore((state) => state.sortBy);
  const order = useOffersStore((state) => state.order);
  const onlyActive = useOffersStore((state) => state.onlyActive);
  const offerTypes = useOffersStore((state) => state.offerTypes);

  const [, makeRequest] = useImperativeRequestWrapper('serviceLpAccount');

  const isInvalid = unixTimeStamp === null;

  const afterClose = () => {
    setUnixTimeStamp(null);
    setError(null);
    if (loading) {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    makeRequest({
      path: `/admin/offers/${id}/deactivate`,
      method: 'put',
      body: { deactivationDate: unixTimeStamp },
    }).then(async ({ _, error }) => {
      if (error) {
        addError(error, { trackError: false });
        setError(error);
        setLoading(false);

        return;
      }
      await getPaginatedTableData({
        params: {
          path: `/admin/offers`,
          method: 'get',
        },
        dispatch,
        queryParams: {
          pageSize,
          offset,
          sortBy,
          order,
          onlyActive,
          offerTypes,
        },
        reducer: offersPostProcessor,
        addError,
        makeRequest,
      });
      addSuccessMsg(
        {
          msg: 'Deactivation Date was successfully set',
          type: MessageType.Success,
        },
        'root',
        {}
      );

      onClose();
    });
  };

  return (
    <CustomModal
      open={visible}
      onCancel={onClose}
      title="Set Deactivation Date/Time"
      destroyOnClose={true}
      okButtonProps={{
        disabled: isInvalid,
        loading: loading,
      }}
      okText="Submit"
      afterClose={afterClose}
      onOk={handleSubmit}
    >
      <DateTimePicker
        setUnixTimeStamp={setUnixTimeStamp}
        useDefaultTime={false}
      />
      {error && (
        <StyledRow>
          <Error message={parseBeErrorMsg(error)} />
        </StyledRow>
      )}
    </CustomModal>
  );
};

export default DeactivateOfferModal;
