import React, { Dispatch, SetStateAction, useCallback } from 'react';

import { Col, Row } from 'antd';

import RoleSelector from '../roles/components/RoleSelector';
import { SearchAccount } from '@/features/lp-account-users';

interface IAddAccountForm {
  setAccountData: Dispatch<
    SetStateAction<{
      accountId: string;
      roleId: string;
    }>
  >;
  accountData: {
    accountId: string;
    roleId: string;
  };
  domain?: 'partners' | 'shops';
}

const AddAccountForm = ({
  setAccountData,
  accountData,
  domain,
}: IAddAccountForm) => {
  const handleChangeAccountId = useCallback(
    (accountId: string) => {
      setAccountData((prev) => ({
        ...prev,
        accountId,
      }));
    },
    [setAccountData]
  );

  const handleChangeDropdown = useCallback(
    (role) => {
      setAccountData({
        ...accountData,
        roleId: role.id,
      });
    },
    [setAccountData, accountData]
  );

  return (
    <Row gutter={24}>
      <Col span={24}>
        <SearchAccount
          accountId={accountData.accountId}
          setAccountId={handleChangeAccountId}
        />
      </Col>
      <Col span={24}>
        <RoleSelector onChange={handleChangeDropdown} domain={domain} />
      </Col>
    </Row>
  );
};
export default AddAccountForm;
