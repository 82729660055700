import { useCallback, useEffect, useState } from 'react';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useGetShipmentHistoryEvents = (id) => {
  const addError = useError();
  const [, makeRequest] = useImperativeRequestWrapper('shipment');
  const [events, setEvents] = useState<any>([]);

  const getShipmentHistoryEvents = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: `/admin/shipments/${id}/history_events?sortBy=changedAt`,
      method: 'get',
    });
    if (error) {
      addError(error);

      return { events: [] };
    }

    return { events: data.events || [] };
  }, [addError, id, makeRequest]);

  useEffect(() => {
    getShipmentHistoryEvents().then(({ events }) => {
      setEvents(events);
    });
  }, [getShipmentHistoryEvents]);

  return events;
};
export default useGetShipmentHistoryEvents;
