import get from 'lodash/get';

import { sendKlaviyoEvent } from './tracking';
import { IUseImperativeRequest } from '@/shared/hooks/useImperativeRequest';

interface ICompletedPaymentKlaviyo {
  accountId: string;
  makeRequest: (
    input: IUseImperativeRequest
  ) => Promise<{ data: any; error: any }>;
  amount: string;
  paymentMethod: string;
}

const completedPaymentKlaviyo = async ({
  accountId,
  makeRequest,
  amount,
  paymentMethod,
}: ICompletedPaymentKlaviyo) => {
  const { data } = await makeRequest({
    path: `/admin/accounts/${accountId}`,
    method: 'get',
  });
  if (data) {
    const email = get(data, 'profile.email', '');
    if (!email) {
      return;
    }
    await sendKlaviyoEvent({
      payload: {
        event: 'wire_completed',
        customer_properties: {
          $email: email,
        },
        properties: {
          amount,
          paymentMethod,
        },
      },
      type: 'track',
    });
  }
};

export default completedPaymentKlaviyo;
