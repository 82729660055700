import React from 'react';

import { Stack, Text } from '@lp/ds-next';

import { IHistoryVersion } from '../models/sim';
import { formatISODate } from '@/helpers/dates';

export interface CardSimVersionProps {
  historyVersion: IHistoryVersion;
}

const PieceOfInformation = ({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode;
}) => (
  <Stack
    direction={{ mobile: 'column', tablet: 'row' }}
    justifyContent="space-between"
    alignItems={{ mobile: 'flex-start', tablet: 'center' }}
    gap={{ mobile: 0, tablet: 2 }}
  >
    <Text variant="titleS">{label} :</Text>
    <span>{value}</span>
  </Stack>
);

const SimVersionDetails = ({ historyVersion }: CardSimVersionProps) => {
  const updated_at = formatISODate(
    historyVersion.updated_at,
    'yy-MM-dd HH:mm:ss'
  );
  const created_at = formatISODate(
    historyVersion.created_at,
    'yy-MM-dd HH:mm:ss'
  );
  const update_completed_at = formatISODate(
    historyVersion.update_completed_at,
    'yy-MM-dd HH:mm:ss'
  );

  return (
    <Stack
      direction={{ mobile: 'column', tablet: 'row' }}
      padding="0.7rem"
      borderRadius="0.625rem"
      justifyContent="space-between"
      sx={{ backgroundColor: 'custom.neutral.white.pure' }}
      data-testid="simVersionsCards"
    >
      <Stack>
        <PieceOfInformation label="Version" value={historyVersion.version} />
        <PieceOfInformation label="Status" value={historyVersion.status} />
      </Stack>
      <Stack>
        <PieceOfInformation label="Updated at" value={updated_at} />
        <PieceOfInformation label="Created at" value={created_at} />
        <PieceOfInformation label="Completed at" value={update_completed_at} />
      </Stack>
    </Stack>
  );
};

export default SimVersionDetails;
