import React from 'react';

import useClipboard from 'react-use-clipboard';
import { withTheme } from 'styled-components';

import CopyIcon from './icons/CopyIcon';
import CopyRoundIcon from './icons/CopyRoundIcon';

interface CopyToClipboardProps {
  data: string;
  theme: any;
  round?: boolean;
}
const CopyToClipboard = (props: CopyToClipboardProps) => {
  const [isCopied, setCopied] = useClipboard(props.data, {
    successDuration: 1000,
  });

  return !props.round ? (
    <CopyIcon onClick={setCopied} isCopied={isCopied} />
  ) : (
    <CopyRoundIcon onClick={setCopied} isCopied={isCopied} />
  );
};

export default withTheme(CopyToClipboard);
