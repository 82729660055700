import React, { useCallback, useEffect, useState } from 'react';

import { Radio, RadioChangeEvent, Row, Col, Alert, Space, Button } from 'antd';
import styled from 'styled-components';

import CustomModal from '../CustomModal';
import Label from '../forms/Label';
import TextInput from '../forms/TextInput';
import { theme } from '@/config';

const AlertWrapper = styled(Alert)`
  margin-top: 15px !important;
`;

const BillingModal = ({
  isOpen,
  onCancel,
  disabled,
  shipment,
  onSave,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onSave: ({ isBilled, paidCost, invoiceId, isPaid }) => void;
  disabled?: boolean;
  shipment?: any;
}) => {
  const [cost, setCost] = useState<string>('');
  const [invoiceId, setInvoiceId] = useState<string>('');
  const [isPaid, setIsPaid] = useState<boolean>(false);
  const [isPaidConfirm, setIsPaidConfirm] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const PAID_STATUS = [
    { label: 'PAID', value: true },
    {
      label: 'NOT PAID',
      value: false,
      disabled: shipment.isPaid,
      ...(!isPaid && {
        style: {
          background: theme.colors.attention,
          border: theme.colors.orange,
          color: theme.colors.white,
        },
      }),
    },
  ];

  const handleCancel = useCallback(() => {
    if (shipment.isPaid === false) {
      setIsPaidConfirm(false);
      setIsPaid(false);
    }
    onCancel();
  }, [onCancel, shipment.isPaid]);

  const footerModal = [
    <Button onClick={handleCancel} key="btn-close">
      Close
    </Button>,
    ...(!shipment.isPaid
      ? [
          <Button
            key="btn-save"
            onClick={() => {
              onSave({
                isBilled: shipment.isBilled,
                paidCost: cost,
                invoiceId,
                isPaid,
              });
            }}
            disabled={isDisabled}
          >
            Save
          </Button>,
        ]
      : []),
  ];

  useEffect(() => {
    setCost(shipment.isBilled ? shipment.paidCost : shipment.costPerShipment);
    setInvoiceId(shipment.invoiceId);
    setIsPaid(shipment.isPaid);

    if (shipment.isPaid) {
      setIsPaidConfirm(false);
    }

    if (shipment && shipment?.invoiceId && shipment?.invoiceId.length === 0) {
      setIsDisabled(true);
    }
  }, [shipment]);

  return (
    <>
      <CustomModal
        open={isOpen}
        title="Billing Management"
        footer={footerModal}
        onCancel={handleCancel}
      >
        <Row>
          <TextInput
            label="Invoice *"
            name="Invoice"
            onChange={({ target: { value } }: any) => {
              setInvoiceId(value);
              setIsDisabled(false);
              if (value.length === 0) {
                setIsDisabled(true);
              }
            }}
            value={invoiceId}
            disabled={disabled}
          />
          <TextInput
            label="Cost *"
            name="Cost"
            onChange={({ target: { value } }: any) => {
              setCost(value);
            }}
            value={cost}
            disabled={disabled}
          />
          {shipment.isBilled && (
            <Row justify="space-between">
              <Col span={24}>
                <Label>Shipment is </Label>
              </Col>
              <Col span={24}>
                <Radio.Group
                  name="paid-status"
                  options={PAID_STATUS}
                  onChange={({ target: { value } }: RadioChangeEvent) => {
                    setIsPaid(value);
                    setIsDisabled(true);
                    if (value === false) {
                      setIsPaidConfirm(false);
                      setIsDisabled(false);
                    }
                  }}
                  optionType="button"
                  buttonStyle="solid"
                  value={isPaid}
                />
              </Col>
            </Row>
          )}
          {isPaid && !shipment.isPaid && (
            <AlertWrapper
              message="You want change status shipment to PAID. Are you sure ? Because it's irreversible"
              type="warning"
              action={
                !isPaidConfirm ? (
                  <Space>
                    <Button
                      key="btn-accept"
                      onClick={() => {
                        setIsPaidConfirm(true);
                        setIsDisabled(false);
                      }}
                    >
                      Accept
                    </Button>
                    <Button
                      key="btn-decline"
                      onClick={() => {
                        setIsPaidConfirm(false);
                        setIsPaid(false);
                        setIsDisabled(false);
                      }}
                    >
                      Decline
                    </Button>
                  </Space>
                ) : (
                  <></>
                )
              }
            />
          )}
        </Row>
      </CustomModal>
    </>
  );
};

export default BillingModal;
