import React, { useMemo } from 'react';

import { Select, Form } from 'antd';
import styled from 'styled-components';

import { COUNTRY_CODES_JSON_PATH } from '@/config';
import useCountryCodes from '@/shared/hooks/useCountryCodes';

const SelectWrapper = styled(Select)`
  cursor: ${(props: { disabled: boolean }) =>
    props.disabled ? 'not-allowed' : ''};
`;

const CountrySelector = ({
  label,
  countryCodeValue,
  width = '60rem',
  isDisabled = false,
  ...rest
}: {
  label?: string;
  countryCodeValue?: string;
  isDisabled?: boolean;
} & any) => {
  const [countryCodes, getCountryNameFromCode] = useCountryCodes({
    localCountryCodesPath: COUNTRY_CODES_JSON_PATH,
  });

  const OptionData = useMemo(
    () =>
      countryCodes?.data?.map((option) => ({
        label: getCountryNameFromCode(option.alpha2Code),
        value: option.alpha2Code,
      })),
    [countryCodes, getCountryNameFromCode]
  );

  return (
    <Form.Item label={label}>
      <SelectWrapper
        showSearch={true}
        data-testid="dropdown"
        disabled={isDisabled}
        options={OptionData}
        optionFilterProp="label"
        width={width}
        value={countryCodeValue || null}
        {...rest}
      />
    </Form.Item>
  );
};

export default CountrySelector;
