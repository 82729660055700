import React from 'react';

import { Route, Routes } from 'react-router-dom';

import QualityCheckTypeDetailView from './views/QualityCheckTypeDetailView';
import QualityCheckTypeList from './views/QualityCheckTypeList';
import ScopedRoute from '@/components/ScopedRoute';
import { scopes } from '@/config';
import { appRoutes } from '@/configs/appRoutes';
import useAppState from '@/hooks/useAppState';

const BoxQualityCheck = () => {
  const userScopes = useAppState((state) => state.scopes);

  return (
    <Routes>
      <Route
        path={appRoutes.ROOT}
        element={
          <ScopedRoute
            component={<QualityCheckTypeList />}
            userScopes={userScopes}
            requiredScopes={[scopes.VIEW_QUALITY_CHECKS]}
          />
        }
      />
      <Route
        path={appRoutes.QUALITY_CHECKS.QUALITY_CHECKS_ID}
        element={
          <ScopedRoute
            component={<QualityCheckTypeDetailView />}
            userScopes={userScopes}
            requiredScopes={[scopes.VIEW_QUALITY_CHECKS]}
          />
        }
      />
    </Routes>
  );
};

export default BoxQualityCheck;
