import isEmpty from 'lodash/isEmpty';

import useLPShopsStore from './useLPShopsStore';
import lpShopsPostProcessor from '../helpers/lpShopsPostProcessor';
import usePaginatedTableData from '@/hooks/usePaginatedTableData';

const useGetShops = (partnerId?: string) => {
  const dispatch = useLPShopsStore((state) => state.dispatch);
  const order = useLPShopsStore((state) => state.order);
  const sortBy = useLPShopsStore((state) => state.sortBy);
  const offset = useLPShopsStore((state) => state.offset);
  const pageSize = useLPShopsStore((state) => state.pageSize);
  const serviceIds = useLPShopsStore((state) => state.serviceIds);

  usePaginatedTableData({
    params: {
      path: partnerId ? `/admin/partners/${partnerId}/shops` : '/admin/shops',
      method: 'get',
      reducer: lpShopsPostProcessor,
    },
    dispatch,
    queryParams: {
      pageSize,
      offset,
      sortBy,
      order,
      serviceIds: !isEmpty(serviceIds) ? serviceIds : undefined,
    },
    queryParamsOptions: {
      skipEmptyString: true,
      arrayFormat: 'separator',
      arrayFormatSeparator: ',',
    },
    service: 'lpVillage',
  });
};

export default useGetShops;
