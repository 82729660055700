import React from 'react';

import styled from 'styled-components';

import ShipmentMap from './ShipmentMap';
import useTrackingHistory from '../../hooks/useTrackingHistory';
import { IShipment } from '../../models/shipment';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 1rem;
  min-height: 30rem;
`;

const LoadingCard = styled.div`
  height: 30rem;
  width: 75%;
  background: ${({ theme }) => theme.colors.adminPanel.lightgrey};
`;

const ShipmentMapWrapper = ({ shipment }: { shipment: IShipment }) => {
  const { data, loading } = useTrackingHistory(shipment);

  return (
    <Container>
      {loading ? (
        <LoadingCard />
      ) : (
        <ShipmentMap shipment={shipment} trackingData={data} />
      )}
    </Container>
  );
};

export default ShipmentMapWrapper;
