import { useCallback } from 'react';

import { useGetShipmentElabel } from './useGetShipmentElabel';
import { ShipmentLabelImage } from '../models/shipment';
import { useFetchResource, UseFetchResource } from '@/hooks/useFetchResource';

export function useShipmentElabel(
  id: string
): UseFetchResource<ShipmentLabelImage> {
  const getELabel = useGetShipmentElabel();

  const fetchFunction = useCallback(() => getELabel(id), [getELabel, id]);

  return useFetchResource(fetchFunction);
}
