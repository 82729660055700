import { create } from 'zustand';

import { IModifiedLPAccountAddress } from '@/models/address';

export type TSortBy = string;

interface ILPAddressStore {
  rowData: IModifiedLPAccountAddress[];
  pageSize: number;
  offset: number;
  sortBy: TSortBy;
  order: 'ASC' | 'DESC';
  error: string | boolean;
  loading: boolean;
  count: number;
  dispatch: (args: TLPAddressReducerInput) => void;
  total: number;
  showAddModal: boolean;
  newAddressId: null | string;
  viewModalAddress: null | IModifiedLPAccountAddress;
}

type TLPAddressReducerInput =
  | {
      type: 'updateRowData';
      args: { rowData: any[] };
    }
  | {
      type: 'updateError';
      args: { error: string | boolean };
    }
  | {
      type: 'updateLoading';
      args: { loading: boolean };
    }
  | {
      type: 'updatePagination';
      args: { pageSize: number; offset: number };
    }
  | {
      type: 'updateSorting';
      args: {
        sortBy: TSortBy;
        order: 'ASC' | 'DESC';
      };
    }
  | {
      type: 'updateCount';
      args: { count: number };
    }
  | {
      type: 'updateTotal';
      args: { total: number };
    }
  | {
      type: 'reset';
    }
  | {
      type: 'updateAddModal';
      args: { showAddModal: boolean };
    }
  | {
      type: 'updateNewAddressId';
      args: { newAddressId: string | null };
    }
  | {
      type: 'updateViewModal';
      args: { viewModalAddress: null | IModifiedLPAccountAddress };
    };

const lpAddressReducer = (
  state: ILPAddressStore,
  input: TLPAddressReducerInput
) => {
  switch (input.type) {
    case 'updateRowData':
      return { ...state, rowData: input.args.rowData };
    case 'updateError':
      return { ...state, error: input.args.error };
    case 'updateLoading':
      return { ...state, loading: input.args.loading };
    case 'updatePagination':
      return {
        ...state,
        pageSize: input.args.pageSize,
        offset: input.args.offset,
      };
    case 'updateSorting':
      return { ...state, sortBy: input.args.sortBy, order: input.args.order };
    case 'updateCount':
      return { ...state, count: input.args.count };
    case 'updateTotal':
      return { ...state, total: input.args.total };
    case 'updateAddModal':
      return { ...state, showAddModal: input.args.showAddModal };
    case 'updateNewAddressId':
      return { ...state, newAddressId: input.args.newAddressId };
    case 'updateViewModal':
      return {
        ...state,
        viewModalAddress: input.args.viewModalAddress,
      };
    case 'reset':
      return INITIAL_STATE;
  }
};

const INITIAL_STATE: Omit<ILPAddressStore, 'dispatch'> = {
  rowData: [],
  error: '',
  loading: false,
  pageSize: 10,
  offset: 0,
  sortBy: 'updatedAt',
  order: 'DESC',
  count: 0,
  total: 0,
  showAddModal: false,
  newAddressId: null,
  viewModalAddress: null,
};

const useLPAddressStore = create<ILPAddressStore>((set) => ({
  ...INITIAL_STATE,
  dispatch: (input) => set((state) => lpAddressReducer(state, input)),
}));

export default useLPAddressStore;
