import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import EyeIcon from './icons/EyeIcon';

const BlockLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const BlockDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const EyeLink = ({ to, onClick }: { to?: string; onClick?: any }) =>
  to ? (
    <BlockLink data-testid="eyelink" to={to} onClick={onClick}>
      <EyeIcon />
    </BlockLink>
  ) : (
    <BlockDiv data-testid="eyelink" onClick={onClick}>
      <EyeIcon />
    </BlockDiv>
  );

export default EyeLink;
