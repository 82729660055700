import React, { useCallback, useMemo, useState } from 'react';

import { Grid } from '@material-ui/core';
import styled from 'styled-components';

import useChangeStatus, {
  SET_TRANSITION_KEY,
  TRANSITIONS,
} from '../hooks/useLPCarrierStatus';
import Dropdown from '@/components/forms/Dropdown';
import Label from '@/components/forms/Label';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';

const LABELS: { [key in SET_TRANSITION_KEY]: string } = {
  setPreTransit: 'Set PRE_TRANSIT',
  setInTransit: 'Set IN_TRANSIT',
  setOutForDelivery: 'Set OUT_FOR_DELIVERY',
  setAvailableForPickup: 'Set AVAILABLE_FOR_PICKUP',
  setDelivered: 'Set DELIVERED',
  setReturnToSender: 'Set RETURN_TO_SENDER',
  setCancelled: 'Set CANCELLED',
  setFailure: 'Set FAILURE',
  setError: 'Set ERROR',
  setDestroyed: 'Set DESTROYED',
};

const StyledButton = styled(PillButton)`
  margin-left: 1rem;
`;

const LPCarrierStatus = ({ shipmentId }) => {
  const [action, setAction] = useState({ action: '', label: '' });
  const [status, handleSubmit] = useChangeStatus(shipmentId);

  const availableActions = useMemo(() => {
    if (TRANSITIONS[status]) {
      return Object.keys(TRANSITIONS[status]).map((action) => ({
        action: action,
        label: LABELS[action],
      }));
    }

    return [];
  }, [status]);

  const handleOnChangeAction = useCallback((action) => {
    setAction(action);
  }, []);

  const handleSubmitAction = useCallback(async () => {
    await handleSubmit(action.action);
    setAction({ action: '', label: '' });
  }, [handleSubmit, action]);

  return (
    <Grid container spacing={1} style={{ marginBottom: '20rem' }}>
      <Grid item xs={6}>
        <Label>Current State: </Label>
        <h1>{status}</h1>
      </Grid>

      <Grid
        container
        item
        xs={6}
        style={{ justifyContent: 'flex-start', alignItems: 'baseline' }}
      >
        <Grid item xs={12}>
          <Label>Actions</Label>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ justifyContent: 'flex-start', alignItems: 'baseline' }}
        >
          <Dropdown
            options={availableActions}
            getOptionValue={(option) => option['label']}
            onChange={handleOnChangeAction}
            value={action || ''}
          />
          <ScopedCta component={StyledButton} onClick={handleSubmitAction}>
            Change status
          </ScopedCta>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default LPCarrierStatus;
