import React, { useCallback, useState } from 'react';

import { MailOutlined } from '@ant-design/icons';

import { IModifiedLPAccountUser } from '../models/lpaccount';
import CustomModal from '@/components/CustomModal';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const SendVerificationEmail = ({
  user,
  userLoading,
  getUser,
}: {
  user: IModifiedLPAccountUser;
  userLoading: boolean;
  getUser: () => void;
}) => {
  const successMsg = useToasts((state) => state.addItem);
  const [visible, setVisible] = useState<boolean>(false);
  const addError = useError();
  const [{ loading }, makeRequest] =
    useImperativeRequestWrapper('serviceLpAccount');
  const { id, identities } = user;

  const handleSubmit = useCallback(async () => {
    if (!id || !identities || identities.length === 0) {
      addError(new Error('Unable to find user identity'));
    }
    const identityId = identities[0].id;
    const { error } = await makeRequest({
      path: `/admin/accounts/${id}/verification-email`,
      method: 'POST',
      body: { identityId },
    });
    setVisible(false);
    if (error) {
      addError(error);

      return;
    }
    successMsg(
      {
        msg: 'Verification email was successfully sent',
        type: MessageType.Success,
      },
      'root',
      {}
    );
  }, [addError, makeRequest, successMsg, id, identities]);

  const showConfirm = useCallback(() => {
    getUser();
    setVisible(true);
  }, [getUser]);

  return (
    <>
      <ScopedCta
        component={PillButton}
        icon={<MailOutlined />}
        onClick={showConfirm}
        loading={loading}
        disabled={loading || userLoading}
        requiredScopes={[scopes.EDIT_LPACCOUNT]}
      >
        Send Verification Email
      </ScopedCta>
      <CustomModal
        title="Send Verification Email"
        open={visible}
        destroyOnClose={true}
        width="60%"
        forceRender={true}
        onCancel={() => setVisible(false)}
        okText="Send"
        onOk={handleSubmit}
      >
        Are you sure you want to send a verification email to {user.email}?
      </CustomModal>
    </>
  );
};

export default SendVerificationEmail;
