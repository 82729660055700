import React, { useCallback, useMemo, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button as AntdButton } from 'antd';

import CustomModal from '@/components/CustomModal';
import DragAndDrop from '@/components/DragAndDrop';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';
import useImportCSV from '@/hooks/useImportCSV';

//Change scope when new role created
const ImportCSVFile = ({ buttonText, modalTitle }) => {
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState(null);
  const openModal = useCallback(() => setVisible(true), []);
  const closeModal = useCallback(() => {
    setFile(null);
    setVisible(false);
  }, []);
  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const [, importCSV] = useImportCSV();

  const handleSubmit = useCallback(async () => {
    if (file) {
      const { success } = await importCSV(file);
      success && closeModal();
    }
  }, [file, closeModal, importCSV]);

  const modalFooterButtons = useMemo(
    () => [
      <AntdButton key="cancel" onClick={closeModal}>
        Cancel
      </AntdButton>,
      <AntdButton
        key="submit"
        type="primary"
        onClick={handleSubmit}
        disabled={!file}
      >
        Submit
      </AntdButton>,
    ],
    [closeModal, handleSubmit, file]
  );

  return (
    <div>
      <ScopedCta
        component={PillButton}
        icon={<PlusOutlined />}
        onClick={openModal}
        requiredScopes={[scopes.EDIT_LPVILLAGE_PARTNER]}
      >
        {buttonText}
      </ScopedCta>

      <CustomModal
        title={modalTitle}
        open={visible}
        destroyOnClose={true}
        footer={modalFooterButtons}
        onCancel={closeModal}
      >
        {/* TODO:  ADD controle file size min/max*/}
        <DragAndDrop
          onDrop={onDrop}
          multiple={false}
          accept={{ 'text/csv': ['.csv'] }}
          removeFiles={() => setFile(null)}
          fileDropped={!!file}
        />
      </CustomModal>
    </div>
  );
};

export default ImportCSVFile;
