import React, { Dispatch, SetStateAction, useCallback } from 'react';

import styled from 'styled-components';

import { ICreateShipment } from '../models/shipment';
import AddressForm from '@/components/AddressForm';
import TextInput from '@/components/forms/TextInput';

interface ICreateShipmentAddressForm {
  addressType: 'sender' | 'recipient';
  setShipment: Dispatch<SetStateAction<ICreateShipment>>;
  shipment: ICreateShipment;
}

const TitleWrapper = styled.h2`
  padding-bottom: 1rem;
`;

const CreateShipmentAddressForm = ({
  addressType,
  shipment,
  setShipment,
}: ICreateShipmentAddressForm) => {
  const handleNotifEmailChange = useCallback(
    (e) => {
      setShipment((prev) => ({
        ...prev,
        [addressType]: {
          ...shipment[addressType],
          notificationEmail: e.target.value,
        },
      }));
    },
    [setShipment, addressType, shipment]
  );

  const setAddressRecipient = useCallback(
    (address) => {
      setShipment((prev) => ({
        ...prev,
        recipient: {
          ...prev.recipient,
          address: { ...prev.recipient.address, ...address },
        },
      }));
    },
    [setShipment]
  );

  const setAddressSender = useCallback(
    (address) => {
      setShipment((prev) => ({
        ...prev,
        sender: {
          ...prev.sender,
          address: { ...prev.sender.address, ...address },
        },
      }));
    },
    [setShipment]
  );

  return (
    <>
      <TitleWrapper>You need to add the {addressType} address.</TitleWrapper>
      <TextInput
        onChange={handleNotifEmailChange}
        label={`Notification Email${addressType === 'recipient' ? ' *' : ''}`}
        name="notificationEmail"
        value={shipment[addressType].notificationEmail}
      />
      <AddressForm
        address={shipment[addressType].address}
        setAddress={
          addressType === 'sender' ? setAddressSender : setAddressRecipient
        }
        hideAddressTypeFields={true}
        hidePrimary={true}
        initialFocus={false}
      />
    </>
  );
};

export default CreateShipmentAddressForm;
