import React from 'react';

import styled from 'styled-components';
const SVG = styled.svg`
  cursor: pointer;
`;

const EyeIcon = (props) => (
  <SVG width="1.5em" height="1.5em" {...props} viewBox="0 0 16 16">
    <defs>
      <path
        d="M15.47 7.83A8.35 8.35 0 0 0 8 2.5 8.35 8.35 0 0 0 .53 7.83a.5.5 0 0 0 0 .34A8.35 8.35 0 0 0 8 13.5a8.35 8.35 0 0 0 7.47-5.33.5.5 0 0 0 0-.34zM8 12.5c-2.65 0-5.45-1.965-6.465-4.5C2.55 5.465 5.35 3.5 8 3.5s5.45 1.965 6.465 4.5C13.45 10.535 10.65 12.5 8 12.5zM8 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0 5a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
        id="EyeIcon_svg__a"
      />
    </defs>
    <use fill="#000" xlinkHref="#EyeIcon_svg__a" fillRule="evenodd" />
  </SVG>
);

export default EyeIcon;
