import React, { useCallback, useState } from 'react';

import { Button } from 'antd';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import InvitationCode from './InvitationCode';
import invitationCodesPostProcessor from '../helpers/invitationCodesProcessor';
import useAccountInvitationsStore from '../hooks/useAccountInvitationsStore';
import CustomModal from '@/components/CustomModal';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const Span = styled.span`
  margin-right: 8px;
`;

const getFooter = ({
  footer,
  closeModal,
  setFooter,
  loading,
  handleRevoke,
  disabled,
}) => {
  switch (footer) {
    case 'view':
      return [
        <Button key="Cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          key="Revoke"
          danger
          onClick={() => setFooter('revoke')}
          disabled={disabled}
        >
          Revoke
        </Button>,
      ];
    case 'revoke':
      return [
        <Span key="Are you">
          Are you sure you want to revoke this Invitation Code?
        </Span>,
        <Button key="No" onClick={() => setFooter('view')}>
          No
        </Button>,
        <Button key="Yes" danger onClick={handleRevoke} loading={loading}>
          Yes
        </Button>,
      ];
  }
};

const InvitationCodeModal = () => {
  const invitationCode = useAccountInvitationsStore(
    (state) => state.viewModalCode
  );
  const dispatch = useAccountInvitationsStore((state) => state.dispatch);
  const pageSize = useAccountInvitationsStore((state) => state.pageSize);
  const offset = useAccountInvitationsStore((state) => state.offset);
  const sortBy = useAccountInvitationsStore((state) => state.sortBy);
  const order = useAccountInvitationsStore((state) => state.order);
  const filters = useAccountInvitationsStore((state) => state.filters);
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);

  const { onlyActive, invitationTypes } = filters;

  const [footer, setFooter] = useState<'view' | 'revoke'>('view');
  const [{ loading }, makeRequest] =
    useImperativeRequestWrapper('serviceLpAccount');

  const closeModal = useCallback(() => {
    dispatch({ type: 'updateViewModal', args: { viewModalCode: {} } });
  }, [dispatch]);

  const afterClose = useCallback(() => {
    setFooter('view');
  }, [setFooter]);

  const handleRevoke = useCallback(async () => {
    const { error } = await makeRequest({
      path: `/admin/invitation-codes/${invitationCode.id}/revoke`,
      method: 'put',
    });
    if (error) {
      addError(error);
      setFooter('view');

      return;
    }
    addSuccessMsg(
      {
        msg: 'Invitation code was successfully revoked!',
        type: MessageType.Success,
      },
      'root',
      {}
    );
    getPaginatedTableData({
      params: {
        path: `/admin/invitation-codes`,
        method: 'get',
      },
      dispatch,
      queryParams: {
        pageSize,
        offset,
        sortBy,
        order,
        onlyActive,
        invitationTypes,
        issuerUserId: invitationCode.issuerUserId,
      },
      reducer: invitationCodesPostProcessor,
      addError,
      makeRequest,
    });
    closeModal();
  }, [
    addError,
    addSuccessMsg,
    dispatch,
    invitationCode,
    invitationTypes,
    makeRequest,
    offset,
    onlyActive,
    order,
    pageSize,
    sortBy,
    closeModal,
  ]);

  return (
    <CustomModal
      open={!isEmpty(invitationCode)}
      onCancel={closeModal}
      afterClose={afterClose}
      destroyOnClose={true}
      width="80%"
      footer={getFooter({
        footer,
        closeModal,
        setFooter,
        loading,
        handleRevoke,
        disabled: invitationCode.status === 'revoked',
      })}
    >
      <InvitationCode invitationCode={invitationCode} />
    </CustomModal>
  );
};

export default InvitationCodeModal;
