import React, { useState } from 'react';

import { FileExcelOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import ContributionsCSVReport from './ContributionsCSVReport';
import contributionsPostProcessor from '../../helpers/contributionsPostProcessor';
import CustomModal from '@/components/CustomModal';
import IntegerInput, { nonIntegerError } from '@/components/forms/IntegerInput';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import P from '@/components/text/P';
import { scopes } from '@/config';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const Row = styled.div`
  flex-direction: row;
  align-items: flex-end;
  display: flex;
`;

const InputWrapper = styled.div`
  width: 15rem;
  margin-right: 1rem;
  margin-top: 1rem;
`;

const LabelWrapper = styled.div`
  margin-bottom: 4.7rem;
`;

const getNumberOfRequests = (num) => Math.ceil(num / 100);
const getPageSize = (num) => (num < 100 ? num : 100);

const GenerateContribsReport = ({ endpoint, store }) => {
  const filters = store((state) => state.filters);
  const total = store((state) => state.total);
  const sortBy = store((state) => state.sortBy);
  const order = store((state) => state.order);
  const searchTerms = store((state) => state.searchTerms);

  const [showModal, setShowModal] = useState(false);
  const [numberOfRecords, setNumberOfRecords] = useState<number | undefined>(
    undefined
  );

  const [, makeRequest] = useImperativeRequestWrapper('serviceLpAccount');
  const addError = useError();

  const getContributionsData = async (total: number) => {
    let result: any[] = [];
    const numberOfRequests = getNumberOfRequests(total);
    let remainingRecords = total;
    for (let i = 0; i < numberOfRequests; i++) {
      const pageSize = getPageSize(remainingRecords);
      const { data, error } = await getPaginatedTableData({
        params: {
          path: endpoint,
          method: 'get',
        },
        queryParams: {
          pageSize,
          offset: i * 100,
          sortBy,
          order,
          ...filters,
          ...searchTerms,
        },
        queryParamsOptions: {
          skipEmptyString: true,
          skipNull: true,
        },
        dispatch: null,
        reducer: contributionsPostProcessor,
        addError,
        makeRequest,
      });

      remainingRecords = remainingRecords - pageSize;

      if (error) {
        throw new Error(error);
      }

      if (data && data.items) {
        result = result.concat(data.items);
      }
    }

    return result;
  };

  const handleClick = () => setShowModal(true);
  const handleCancel = () => setShowModal(false);

  const handleInputChange = (event) => setNumberOfRecords(event.target.value);
  const afterClose = () => setNumberOfRecords(undefined);

  const hasLimitError = numberOfRecords && numberOfRecords > total;
  const isInvalid = hasLimitError || nonIntegerError(numberOfRecords);

  return (
    <>
      <ScopedCta
        component={PillButton}
        onClick={handleClick}
        icon={<FileExcelOutlined />}
        requiredScopes={[
          scopes.VIEW_SHARING_ANGELS,
          scopes.EDIT_SHARING_ANGELS,
        ]}
      >
        Generate Report
      </ScopedCta>
      <CustomModal
        title="Generate Contributions Report"
        open={showModal}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose={true}
        afterClose={afterClose}
      >
        <P>
          To generate a report, please specify the number of records you wish to
          export. <br />
          <br />
          Any filters/sorting you have applied will be applied to the exported
          data.
        </P>
        <Row>
          <InputWrapper>
            <IntegerInput
              mb="4.5rem"
              placeholder="Number of records"
              value={numberOfRecords}
              onChange={handleInputChange}
              hasOtherError={hasLimitError}
              otherErrorMsg={
                hasLimitError ? 'Must be below or equal to maximum!' : ''
              }
            />
          </InputWrapper>
          <LabelWrapper>maximum: {total}</LabelWrapper>
        </Row>
        <ContributionsCSVReport
          getContributionsData={getContributionsData}
          isInvalid={isInvalid}
          numberOfRecords={numberOfRecords}
        />
      </CustomModal>
    </>
  );
};

export default GenerateContribsReport;
