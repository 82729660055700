import React, { useCallback, useMemo, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import useDeleteShop from '../hooks/useDeleteShop';
import { INITIAL_STATE } from '../hooks/useLPShop';
import CancelSubmitFooterModal from '@/components/CancelSubmitFooterModal';
import CustomModal from '@/components/CustomModal';
import InputIdForm from '@/components/InputIdForm';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';

const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

const DeleteShop = ({ shopId, setShop }) => {
  const [visible, setVisible] = useState(false);
  const [inputtedId, setInputtedId] = useState({ data: '', touched: false });
  const openModal = useCallback(() => setVisible(true), []);
  const closeModal = useCallback(() => setVisible(false), []);
  const [{ loading }, deleteShop] = useDeleteShop(shopId);

  const handleSubmit = useCallback(async () => {
    const { success } = await deleteShop();
    if (success) {
      closeModal();
      setShop({ ...INITIAL_STATE, deleted: true });
    }
  }, [deleteShop, closeModal, setShop]);

  const isInvalid = useMemo(
    () => shopId !== inputtedId.data,
    [inputtedId.data, shopId]
  );

  const modalFooterButtons = useMemo(
    () => CancelSubmitFooterModal(isInvalid, handleSubmit, closeModal, loading),
    [closeModal, handleSubmit, isInvalid, loading]
  );

  const idForm = useMemo(
    () => (
      <div>
        <InputIdForm
          placeholder="Shop-ID"
          text={
            <Wrapper>To delete the LP Shop, please enter the shop ID:</Wrapper>
          }
          hasError={isInvalid}
          inputtedId={inputtedId}
          setId={setInputtedId}
          inputName="delete-modal"
        />
      </div>
    ),
    [inputtedId, isInvalid]
  );

  return (
    <div>
      <ScopedCta
        component={PillButton}
        type="danger"
        icon={<DeleteOutlined />}
        onClick={openModal}
        requiredScopes={[scopes.MANAGE_LPVILLAGE_SHOPS]}
        data-testid="deleteShopButton"
      >
        Delete Shop
      </ScopedCta>

      <CustomModal
        title="Delete shop"
        open={visible}
        destroyOnClose={true}
        footer={modalFooterButtons}
        onCancel={closeModal}
        width="50%"
      >
        {idForm}
      </CustomModal>
    </div>
  );
};

export default DeleteShop;
