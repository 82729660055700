import { useCallback } from 'react';

import { Modal } from 'antd';

import useBoxOffersStore from './useBoxOffersStore';
import boxOffersPostProcessor from '../helpers/boxOfferPostProcessor';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import { storeSelector } from '@/helpers/paginatedStoreHelpers';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const { confirm } = Modal;

const useDeleteBoxOffer = () => {
  const [{ loading }, makeRequest] =
    useImperativeRequestWrapper('serviceLpAccount');
  const [, makeRefetch] = useImperativeRequestWrapper('serviceLpAccount');
  const addError = useError();
  const { dispatch, order, sortBy, offset, pageSize, filters } =
    useBoxOffersStore(storeSelector);
  const addSuccessMsg = useToasts((state) => state.addItem);

  const deleteBoxOfferRequest = useCallback(
    async (id: string) => {
      const { error } = await makeRequest({
        path: `/admin/box-contract-offers/${id}`,
        method: 'delete',
      });
      if (error) {
        addError(error);

        return { success: false };
      }
      await getPaginatedTableData({
        params: {
          path: '/admin/box-contract-offers',
          method: 'get',
        },
        reducer: boxOffersPostProcessor,
        dispatch,
        queryParams: {
          pageSize: pageSize,
          offset: offset,
          sortBy: sortBy,
          order: order,
          ...filters,
        },
        queryParamsOptions: { skipEmptyString: true, skipNull: true },
        addError,
        makeRequest: makeRefetch,
      });
      addSuccessMsg(
        {
          msg: `Successfully deleted box contract offer with id ${id}`,
          type: MessageType.Success,
        },
        'root',
        {}
      );

      return { success: true };
    },
    [
      makeRequest,
      dispatch,
      offset,
      order,
      sortBy,
      pageSize,
      filters,
      makeRefetch,
      addError,
      addSuccessMsg,
    ]
  );

  const deleteBoxOffer = (id: string) => {
    confirm({
      content: `Are you sure you went to delete box offer with id ${id}`,
      onOk: () => deleteBoxOfferRequest(id),
      maskClosable: false,
    });
  };

  return { deleteBoxOffer, loading };
};

export default useDeleteBoxOffer;
