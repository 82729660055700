import React, { useCallback, useRef, useState } from 'react';

import { Col, Image, Row, Typography } from 'antd';
import styled from 'styled-components';

import ImportLabelImage from '../components/ImportLabelImage';
import useGetELabelShipmentBinary from '../hooks/useGetELabelShipmentBinary';
import { useShipmentElabel } from '../hooks/useShipmentElabel';
import { IModifiedShipment } from '../models/shipment';
import TextInput from '@/components/forms/TextInput';
import Loader from '@/components/Loader';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';

const Wrapper = styled.div`
  display: flex;
`;

const InfoWrapper = styled.div`
  width: 35%;
  padding-right: 2rem;
  padding-top: 0.8rem;
`;

const StyledCol = styled(Col)`
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

const ShipmentELabel = ({ shipment }: { shipment: IModifiedShipment }) => {
  const { result, invalidate } = useShipmentElabel(shipment.id);
  const { fetch, data: binaryData, loading } = useGetELabelShipmentBinary();
  const [visible, setVisible] = useState<boolean>(false);
  const downloadRef = useRef<HTMLAnchorElement>(null);

  const handleDownloadBinaryClick = useCallback(async () => {
    if (downloadRef.current === null) return;

    await fetch(shipment.id);
    downloadRef.current.click();
  }, [fetch, shipment.id]);

  if (result.state === 'idle' || result.state === 'loading') {
    return <Loader size={16} />;
  }

  if (result.state === 'error') return <>Error fetching contract label.</>;

  const { resource: label } = result;

  return (
    <Wrapper>
      <InfoWrapper>
        <Row gutter={24}>
          <Col span={24}>
            <TextInput
              disabled
              label="Signature"
              name="signature"
              value={label.header.signature}
            />
          </Col>
          <Col span={24}>
            <TextInput
              disabled
              label="CRC"
              name="crc"
              value={label.header.crc.toString()}
            />
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <TextInput
              disabled
              label="Seq. Num."
              name="seqNum"
              value={label.header.seqNum.toString()}
            />
          </Col>
          <Col span={24}>
            <TextInput
              disabled
              label="Format"
              name="format"
              value={label.img.format}
            />
          </Col>
          <Col span={24}>
            {shipment.contractState === 'PURCHASED' && (
              <>
                <ScopedCta
                  component={PillButton}
                  disabled={loading}
                  onClick={handleDownloadBinaryClick}
                  requiredScopes={[scopes.EDIT_SHIPMENT_LABEL]}
                >
                  {loading ? 'Processing' : 'Download binary ELabel'}
                </ScopedCta>
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                <a
                  ref={downloadRef}
                  href={`data:application/octet-stream;base64,${binaryData}`}
                  download={`shipment_${shipment.id}_elabel.bmp`}
                  hidden
                />
              </>
            )}
          </Col>

          <StyledCol span={24}>
            <Typography.Text>
              This file is meant to be uploaded to THE BOX. It can be inspected
              with our LPtool.
            </Typography.Text>
          </StyledCol>

          {shipment.carrierCode === 'LPCarrier' &&
            shipment.contractState === 'PURCHASED' && (
              <Col>
                <ScopedCta
                  component={PillButton}
                  onClick={() => setVisible(true)}
                  requiredScopes={[scopes.EDIT_SHIPMENT_LABEL]}
                >
                  Import Image Label
                </ScopedCta>
              </Col>
            )}
        </Row>
      </InfoWrapper>
      {label.img.image && (
        <Image width="65%" src={`data:image/png;base64,${label.img.image}`} />
      )}
      {visible && (
        <ImportLabelImage
          visible={visible}
          setVisible={setVisible}
          invalidate={invalidate}
          versionHash={label.header.versionHash}
        />
      )}
    </Wrapper>
  );
};
export default ShipmentELabel;
