import { array, number, object, string } from 'yup';

const boxEventSchema = object().shape({
  consumedAt: string().default(''),
  coordinate: object()
    .shape({
      accuracy: number().default(0),
      latitude: number().default(0),
      longitude: number().default(0),
      placeId: string().default(''),
      timestamp: number().default(0),
    })
    .default({
      accuracy: 0,
      latitude: 0,
      longitude: 0,
      placeId: '',
      timestamp: 0,
    }),
  id: string().default(''),
  serviceLevel: number().default(0),
  shipmentId: string().default(''),
  thingName: string().default(''),
  timestamp: number().default(0),
  type: string().default(''),
  state: object().default({}),
});

const boxEventsSchema = array().of(boxEventSchema);

export default boxEventsSchema;
