import React, { useCallback, useEffect, useState } from 'react';

import { HStack, VStack } from '@chakra-ui/react';
import { Checkbox, Select } from 'antd';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import useChangePartnerServiceLevel from '../../../serviceLevels/hooks/useChangePartnerServiceLevel';
import useServiceLevels from '../../../serviceLevels/hooks/useServiceLevels';
import { ServiceLevel } from '../../../serviceLevels/models/serviceLevel';
import { IPartnerV3, IPartnerV3Update } from '../models/partnerV3';
import PillButton from '@/components/PillButton';
import { scopes } from '@/config';
import useAppState from '@/hooks/useAppState';
const { Option } = Select;

interface ChangePartnerServiceLevelProps {
  currentServiceLevel: ServiceLevel;
  fetchPartnerServiceLevel: () => void;
  partner: IPartnerV3;
  updatePartner: (data: IPartnerV3Update) => Promise<{ success: boolean }>;
}

const StyledSelect = styled(Select)`
  width: 11rem;
`;

const ChangePartnerServiceLevel = ({
  partner,
  updatePartner,
  currentServiceLevel,
  fetchPartnerServiceLevel,
}: ChangePartnerServiceLevelProps) => {
  const { id: partnerId } = useParams<{ id: string }>();
  const { result } = useServiceLevels();
  const userScopes = useAppState((state) => state.scopes);
  const changePartnerServiceLevel = useChangePartnerServiceLevel();
  const [serviceLevelId, setServiceLevelId] = useState<number>();
  const [demoEnabled, setDemoEnabled] = useState<boolean>(false);
  const [demoTouched, setDemoTouched] = useState<boolean>();

  const handleSubmit = useCallback(async () => {
    if (serviceLevelId) {
      await changePartnerServiceLevel(partnerId!, serviceLevelId);
      await fetchPartnerServiceLevel();
    }
    if (demoTouched) {
      await updatePartner({
        demo_enabled: demoEnabled,
      } as IPartnerV3Update);
      setDemoTouched(false);
    }
  }, [
    demoTouched,
    demoEnabled,
    updatePartner,
    serviceLevelId,
    changePartnerServiceLevel,
    partnerId,
    fetchPartnerServiceLevel,
  ]);

  useEffect(() => {
    setDemoEnabled(partner.demo_enabled);
  }, [partner.demo_enabled]);

  const handleDemoEnabled = useCallback((e) => {
    setDemoTouched(true);
    setDemoEnabled(e.target.checked);
  }, []);

  if (result.state !== 'success') return <></>;

  return (
    <VStack>
      <HStack width="100%">
        <StyledSelect
          placeholder="Service Levels"
          defaultValue={currentServiceLevel.name}
          onChange={(id) => setServiceLevelId(id as number)}
        >
          {result.resource.items
            .filter((item) => item.name !== 'Default offer')
            .map((sl: ServiceLevel) => (
              <Option
                key={sl.id.toString()}
                value={sl.id}
                disabled={sl.id === currentServiceLevel.id}
              >
                {sl.name}
              </Option>
            ))}
        </StyledSelect>
        <PillButton
          disabled={!serviceLevelId && !demoTouched}
          onClick={handleSubmit}
        >
          Submit
        </PillButton>
      </HStack>
      {userScopes.includes(scopes.MANAGE_SERVICE_LEVELS) && (
        <Checkbox checked={demoEnabled} onChange={handleDemoEnabled}>
          Demo Enabled
        </Checkbox>
      )}
    </VStack>
  );
};

export default ChangePartnerServiceLevel;
