import React, { useCallback, useMemo, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';

import CreateBoxOfferForm from './CreateBoxOfferForm';
import useCreateBoxOffer from '../hooks/useCreateBoxOffer';
import { INITIAL_CreateBoxContractOffer } from '../models/boxContract';
import CancelSubmitFooterModal from '@/components/CancelSubmitFooterModal';
import CustomModal from '@/components/CustomModal';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';
import { hasEmptyFields } from '@/helpers/forms';

const CreateBoxContractOffer = () => {
  const [visible, setVisible] = useState(false);
  const openModal = useCallback(() => setVisible(true), []);
  const closeModal = useCallback(() => setVisible(false), []);
  const [boxOffer, setBoxOffer] = useState(INITIAL_CreateBoxContractOffer);
  const { createBoxOffer, loading } = useCreateBoxOffer();

  const handleClose = useCallback(() => {
    closeModal();
    setBoxOffer(INITIAL_CreateBoxContractOffer);
  }, [closeModal]);

  const handleSubmit = useCallback(async () => {
    const { success } = await createBoxOffer(boxOffer);
    if (success) {
      handleClose();
    }
  }, [createBoxOffer, boxOffer, handleClose]);

  const isInvalidDate = useMemo(
    () => false,
    []
    // () =>
    //   !!(
    //     boxOffer.activationDate && boxOffer.activationDate < Date.now() / 1000
    //   ),
    // [boxOffer.activationDate]
  );

  const isInvalid = useMemo(
    () => hasEmptyFields(boxOffer) || isInvalidDate,
    [boxOffer, isInvalidDate]
  );

  const modalFooterButtons = useMemo(
    () => CancelSubmitFooterModal(isInvalid, handleSubmit, closeModal, loading),
    [closeModal, handleSubmit, isInvalid, loading]
  );

  return (
    <div>
      <ScopedCta
        component={PillButton}
        icon={<PlusOutlined />}
        onClick={openModal}
        requiredScopes={[scopes.EDIT_BUY_N_RENT_OFFER]}
      >
        Add box contract offer
      </ScopedCta>

      <CustomModal
        title="Add a new box contract offer"
        open={visible}
        destroyOnClose={true}
        footer={modalFooterButtons}
        onCancel={handleClose}
        width="80%"
      >
        <CreateBoxOfferForm
          boxOffer={boxOffer}
          setBoxOffer={setBoxOffer}
          isInvalidDate={false}
        />
      </CustomModal>
    </div>
  );
};

export default CreateBoxContractOffer;
