import React, { useMemo, useState } from 'react';

import { Chip } from '@lp/ds-next';
import { Marker, InfoWindow } from '@react-google-maps/api';

import useBoxLastLocation from '../hooks/useBoxLastLocation';
import SmallBoxImg from '@/assets/images/box.png';
import Loader from '@/components/Loader';
import Map from '@/components/Map';
import dates from '@/helpers/dates';

const BoxLastLocationMap = ({
  boxId,
  thingName,
}: {
  boxId: string;
  thingName: string;
}) => {
  const { result } = useBoxLastLocation(boxId!, thingName);
  const [infoWindows, setInfoWindows] = useState(true);

  // check if date is bigger than today + 7 days
  const isSuspiciousDate = useMemo(() => {
    if ((result as any)?.resource?.events?.length === 0) return 'idle';

    const date = new Date((result as any)?.resource?.events[0].timestamp);
    const today = new Date();
    today.setDate(today.getDate() + 7);

    return date > today ? 'error' : 'idle';
  }, [result]);
  // TODO : result state in ENUM
  if (result.state === 'idle' || result.state === 'loading') {
    return <Loader size={32} />;
  }

  if (result.state === 'error') return <>Error fetching last location.</>;

  if (result.resource.events.length !== 1) return <>No last know location.</>;

  const boxPosition = {
    lat: parseFloat(result.resource.events[0].coordinate.latitude),
    lng: parseFloat(result.resource.events[0].coordinate.longitude),
  };

  return (
    <div>
      <Map center={boxPosition} zoom={10} height="30rem">
        <Marker
          icon={SmallBoxImg}
          position={boxPosition}
          onClick={() => {
            setInfoWindows(!infoWindows);
          }}
        >
          {infoWindows && (
            <InfoWindow
              options={{ disableAutoPan: infoWindows }}
              position={{ lat: boxPosition.lat + 0.03, lng: boxPosition.lng }}
              onCloseClick={() => {
                setInfoWindows(false);
              }}
            >
              <Chip
                state={isSuspiciousDate}
                label={`Date last position: ${dates.parseDate(
                  result.resource.events[0].timestamp
                )}`}
                type="tags"
                variant="bodyTextM"
              />
            </InfoWindow>
          )}
        </Marker>
      </Map>
    </div>
  );
};

export default BoxLastLocationMap;
