import React, { useCallback, useEffect, useState } from 'react';

import styled from 'styled-components';

import { BoxContractOffer } from '../models/boxContract';
import Dropdown from '@/components/forms/Dropdown';
import Label from '@/components/forms/Label';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const LabelWrapper = styled.div`
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
`;

const QueryParams = new URLSearchParams({
  offset: '0',
  pageSize: '100',
  sortBy: 'updatedAt',
  order: 'DESC',
  onlyActive: 'true',
}).toString();

const BoxOfferSelector = ({
  onChange,
  boxOfferId,
  disabled,
}: {
  onChange: any;
  boxOfferId?: string;
  disabled?: boolean;
}) => {
  const addError = useError();
  const [boxOffers, setBoxOffers] = useState<BoxContractOffer[]>([]);

  const value =
    boxOffers.length > 0 && boxOfferId
      ? boxOffers.find((x) => x.id === boxOfferId)
      : undefined;

  const [{ loading }, makeRequest] =
    useImperativeRequestWrapper('serviceLpAccount');

  const getBoxOffers = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: `/admin/box-contract-offers?${QueryParams}`,
      method: 'get',
    });
    if (error) {
      addError(error);

      return;
    }
    if (data?.items) {
      setBoxOffers(data.items);
    }
  }, [makeRequest, addError]);

  useEffect(() => {
    getBoxOffers();
  }, [getBoxOffers]);

  return (
    <>
      <LabelWrapper>
        <Label>Select an active Box Contract Offer *</Label>
      </LabelWrapper>
      {boxOffers.length === 0 ? (
        <Dropdown
          options={[]}
          mw="100%"
          placeholder={loading ? 'Loading...' : 'No options'}
        />
      ) : (
        <Dropdown
          mw="100%"
          options={boxOffers}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.id}
          onChange={(value: BoxContractOffer) => onChange(value.id)}
          isDisabled={disabled}
          value={value}
        />
      )}
    </>
  );
};

export default BoxOfferSelector;
