import React, { useCallback, useEffect, useMemo, useState } from 'react';

import styled from 'styled-components';

import { IRole } from '../models/role';
import Dropdown from '@/components/forms/Dropdown';
import Label from '@/components/forms/Label';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const LabelWrapper = styled.div`
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
`;

const RoleSelector = ({
  onChange,
  role,
  disabled,
  domain,
}: {
  onChange: any;
  role?: IRole;
  disabled?: boolean;
  domain?: 'partners' | 'shops';
}) => {
  const addError = useError();
  const [roles, setRoles] = useState<IRole[]>([]);

  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpVillage');

  const getRoles = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: `/admin/roles?offset=0&order=DESC&pageSize=100&sortBy=updatedAt`,
      method: 'get',
    });
    if (error) {
      addError(error);

      return;
    }
    if (data?.items) {
      setRoles(data.items);
    }
  }, [makeRequest, addError]);

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  const options = useMemo(() => {
    if (!domain) {
      return roles;
    }

    return domain === 'partners'
      ? roles.filter((r) => r.type === 'partner')
      : roles.filter((r) => r.type === 'shop');
  }, [roles, domain]);

  return (
    <>
      <LabelWrapper>
        <Label>Roles</Label>
      </LabelWrapper>
      {options.length === 0 ? (
        <Dropdown
          mw="35rem"
          options={[]}
          placeholder={loading ? 'Loading...' : 'No options'}
        />
      ) : (
        <Dropdown
          options={options}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.name}
          mw="35rem"
          onChange={onChange}
          isDisabled={disabled}
          value={role}
        />
      )}
    </>
  );
};

export default RoleSelector;
