import React, { useCallback, useMemo, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import styled from 'styled-components';

import ImageThumbnail from './ImageThumbnail';
import useImages from '../hooks/useImages';
import useQualityCheckType from '../hooks/useQualityCheckType';
import {
  ICreateQualityCheckType,
  INITIAL_CreateQualityCheckType,
  ZonesNames,
} from '../models/qualityCheck';
import CancelSubmitFooterModal from '@/components/CancelSubmitFooterModal';
import CustomModal from '@/components/CustomModal';
import DragAndDrop from '@/components/DragAndDrop';
import Dropdown from '@/components/forms/Dropdown';
import Label from '@/components/forms/Label';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';

const WrapperLabel = styled.div`
  margin-bottom: 0.25rem;
`;

const DropdownMod = styled(Dropdown)`
  margin-bottom: 2rem;
`;

const WrapperImage = styled.div`
  display: flex;
  justify-content: center;
`;

const zoneOptions = Object.keys(ZonesNames).map((z) => ({
  zone: z,
  label: ZonesNames[z],
}));

const StyledInput = styled(Input)`
  margin-bottom: 1rem;
`;

const CreateQualityCheckType = () => {
  const {
    loadingCreate: loading,
    getQualityCheckTypes,
    createQualityCheckType,
  } = useQualityCheckType();
  const { postQualityCheckImages } = useImages();
  const [visible, setVisible] = useState<boolean>(false);
  const [qualityCheckType, setQualityCheckType] =
    useState<ICreateQualityCheckType>(INITIAL_CreateQualityCheckType);
  const openModal = useCallback(() => setVisible(true), []);
  const closeModal = useCallback(() => {
    setVisible(false);
    setQualityCheckType(INITIAL_CreateQualityCheckType);
  }, []);

  const isInvalid = useMemo(
    () =>
      !qualityCheckType.name ||
      !qualityCheckType.description ||
      !qualityCheckType.image ||
      !qualityCheckType.expectedLevel ||
      !qualityCheckType.zone,
    [qualityCheckType]
  );

  const handleTextChange = useCallback(
    (e) => {
      const field = { name: e.target.name, value: e.target.value };
      setQualityCheckType((prev) => ({ ...prev, [field.name]: field.value }));
    },
    [setQualityCheckType]
  );

  const handleDropImage = useCallback(
    async (droppedFiles) => {
      const { success, data } = await postQualityCheckImages(droppedFiles);
      success &&
        setQualityCheckType((prev) => ({ ...prev, image: data.urls[0] }));
    },
    [postQualityCheckImages]
  );

  const handleRemoveImages = useCallback(() => {
    setQualityCheckType((prev) => ({ ...prev, image: '' }));
  }, []);

  const handleDropdownChange = useCallback((e) => {
    setQualityCheckType((prev) => ({ ...prev, zone: e.zone }));
  }, []);

  const handleSubmit = useCallback(async () => {
    const { success } = await createQualityCheckType(qualityCheckType);
    if (success) {
      await getQualityCheckTypes();
      closeModal();
    }
  }, [
    closeModal,
    createQualityCheckType,
    qualityCheckType,
    getQualityCheckTypes,
  ]);

  const modalFooterButtons = CancelSubmitFooterModal(
    isInvalid,
    handleSubmit,
    closeModal,
    loading
  );

  return (
    <div>
      <ScopedCta
        component={PillButton}
        icon={<PlusOutlined />}
        onClick={openModal}
        requiredScopes={[scopes.EDIT_QUALITY_CHECKS]}
      >
        Create Quality Check Type
      </ScopedCta>

      <CustomModal
        title="Create Quality Check Type"
        open={visible}
        destroyOnClose={true}
        footer={modalFooterButtons}
        onCancel={closeModal}
      >
        <>
          <StyledInput
            onChange={handleTextChange}
            placeholder="Name"
            name="name"
          />
          <StyledInput
            onChange={handleTextChange}
            placeholder="Description"
            name="description"
          />
          <StyledInput
            onChange={handleTextChange}
            placeholder="Expected level"
            name="expectedLevel"
          />
          <WrapperLabel>
            <Label>Zone</Label>
          </WrapperLabel>
          <DropdownMod
            options={zoneOptions}
            getOptionValue={(option) => option['label']}
            getOptionLabel={(option) => option['label']}
            value={
              qualityCheckType.zone !== ''
                ? {
                    zone: qualityCheckType.zone,
                    label: ZonesNames[qualityCheckType.zone],
                  }
                : null
            }
            onChange={handleDropdownChange}
          />
          <WrapperLabel>
            <Label>Image</Label>
          </WrapperLabel>
          <DragAndDrop
            onDrop={handleDropImage}
            text="Add image"
            multiple={false}
            accept={{ 'image/jpeg:[], image/png': [] }}
            height="7rem"
            fileDropped={!!qualityCheckType.image}
            removeFiles={handleRemoveImages}
          />
          {qualityCheckType.image && (
            <WrapperImage>
              <ImageThumbnail url={qualityCheckType.image} />
            </WrapperImage>
          )}
        </>
      </CustomModal>
    </div>
  );
};

export default CreateQualityCheckType;
