import React, { useCallback, useState } from 'react';

import { FileExcelOutlined } from '@ant-design/icons';
import { saveAs } from 'file-saver';

import PillButton from '@/components/PillButton';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

interface IExportBoxLogs {
  id: string;
  isShipment: boolean;
}

const ExportBoxLogs = ({ id, isShipment }: IExportBoxLogs) => {
  const [csvLoading, setCsvLoading] = useState(false);

  const [, makeRequest] = useImperativeRequestWrapper('tracking');
  const addError = useError();

  const getBoxLogsData = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: `/${isShipment ? `shipments` : `boxes`}/${id}/events/csv-export`,
      method: 'get',
    });
    if (error) {
      addError(error);

      return;
    }

    return data;
  }, [addError, makeRequest, id, isShipment]);

  const handleClick = useCallback(async () => {
    setCsvLoading(true);
    const data = await getBoxLogsData();
    if (!data) {
      return;
    }
    try {
      const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
      saveAs(csvData, `export_${id}.csv`);
    } catch (error) {
      addError(error);

      return;
    }
    setCsvLoading(false);
  }, [addError, getBoxLogsData, setCsvLoading, id]);

  return (
    <PillButton
      onClick={handleClick}
      icon={<FileExcelOutlined />}
      loading={csvLoading}
      disabled={csvLoading}
    >
      Export Logs
    </PillButton>
  );
};

export default ExportBoxLogs;
