import { jwtDecode } from 'jwt-decode';
import get from 'lodash/get';

const getScopesFromToken = (token) => {
  const decodedToken = jwtDecode(token);
  const scopes: any = get(decodedToken, 'scope', []);

  if (scopes && scopes.length > 0) {
    const scopesArray = scopes.split(' ');

    return scopesArray;
  }

  return [];
};

export default getScopesFromToken;
