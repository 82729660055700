import {
  ICreateShipmentAddress,
  INITIAL_CreateShipmentAddress,
  IShipmentAddress,
} from '@/models/address';

export interface ICustomer {
  accountId: string;
  address: IShipmentAddress;
  id: string;
  notificationEmail: string;
}

export type TCarrierCodes =
  | 'Invalid'
  | 'CHRONOPOST'
  | 'DHL'
  | 'DHLExpress'
  | 'DPD'
  | 'HERMES'
  | 'LPCarrier'
  | 'UPS';

export interface ICarrierLabel {
  code: string;
  labelSource: string;
  rawData: number[];
}

export interface ICarrier {
  carrierCode: string;
}

export interface IQuote {
  currency: string;
  priceInCents: number;
  carrierService: string;
  carrierCode: TCarrierCodes;
  id: string;
}

export interface IServiceLevel {
  id: string;
  serviceLevel: number;
  currency: string;
  priceInCents: number;
}

export type TServiceLevel = string;

export interface IServiceLevelQuote {
  currency: string;
  error?: string;
  id: string;
  priceInCents: number;
  serviceLevel: TServiceLevel;
}

export type DeliveryStatus =
  | 'NONE'
  | 'INVALID'
  | 'PRE_TRANSIT'
  | 'IN_TRANSIT'
  | 'OUT_FOR_DELIVERY'
  | 'AVAILABLE_FOR_PICKUP'
  | 'DELIVERED'
  | 'RETURN_TO_SENDER'
  | 'CANCELLED'
  | 'DESTROYED'
  | 'FAILURE'
  | 'ERROR';

export type TContractState =
  | ''
  | 'DRAFT'
  | 'PURCHASED'
  | 'TRANSFERRED'
  | 'DEPLOYED'
  | 'ARRIVED'
  | 'ARCHIVED'
  | 'CANCELLED'
  | 'ERROR';

export const CONTRACT_STATES = [
  'DRAFT',
  'PURCHASED',
  'TRANSFERRED',
  'DEPLOYED',
  'ARRIVED',
  'ARCHIVED',
  'CANCELLED',
  'ERROR',
];

type TShipmentType = 'INVALID' | 'C_TO_C' | 'PARTNER' | 'TEST';

export interface IShipment {
  box: { bleMacAddress: string; id: string; thingName: string };
  boxWeightInGrams: number;
  carrier: ICarrier;
  carrierLabel: ICarrierLabel;
  carrierTrackingCode: string;
  contractState: TContractState;
  createdAt: number;
  createdBy: string;
  deployedBy: string;
  deliveryStatus: DeliveryStatus | null;
  deliveryTime?: 'ON_TIME' | 'DELAYED';
  id: string;
  isArchived: boolean;
  labelWeightInGrams: number;
  originalShipment?: IShipment;
  originalShipmentId?: string;
  partnerId?: string;
  quote?: IQuote;
  recipient: ICustomer;
  reference: string;
  returnShipment?: IShipment;
  returnShipmentId?: string;
  sender: ICustomer;
  serviceLevel: TServiceLevel;
  serviceLevelQuote?: IServiceLevelQuote;
  shipmentType: TShipmentType;
  updatedAt: number;
  versionHash: string;
  weightInGrams: number;
  parcelRequestId?: string;
  secursusParcelId?: string;
}

export type TAlert = { timestamp: number } & (
  | {
      type: 'INTERNAL_HUMIDITY_EXCEEDED';
      data: {
        humidity: number;
      };
    }
  | {
      type: 'SHOCK_EXCEEDED';
      data: {};
    }
  | {
      type: 'INTERNAL_TEMPERATURE_EXCEEDED';
      data: {
        temperature: number;
      };
    }
  | {
      type: 'BOX_OPENED';
      data: {
        switchOn: boolean;
        lightOn: boolean;
      };
    }
);

interface ICoordinate {
  latitude: number;
  longitude: number;
  placeId?: string;
  timestamp: number;
}

interface ILastKnownState {
  acceleration: number;
  humidityExternal: number;
  humidityInternal: number;
  pressure: number;
  temperatureExternal: number;
  temperatureInternal: number;
  timestamp: number;
  remainingTime: number;
  batteryLevel: number;
}

export interface ITrackingHistoryResponse {
  alerts: TAlert[];
  lastKnownLocation?: ICoordinate;
  lastKnownState?: ILastKnownState;
  rawPath: ICoordinate[];
  roadPath: ICoordinate[];
  shipmentId: string;
}

export interface IModifiedShipment extends IShipment {
  parsedCreatedAt: string;
  parsedUpdatedAt: string;
  recipientSender: [ICustomer | null, ICustomer | null];
  carrierCode: TCarrierCodes;
}

export interface ICreateShipment {
  recipient: {
    accountId?: string;
    addressId?: string;
    address: ICreateShipmentAddress;
    notificationEmail: string;
  };
  sender: {
    accountId?: string;
    address?: ICreateShipmentAddress;
    notificationEmail?: string;
    addressId?: string;
  };
  serviceLevel: string;
  weightInGrams: string;
  carrierLabel?: File | null;
  carrierCode?: string;
  partnerId?: string;
}

export interface ICreatePartnerShipment {
  boxId?: string;
  recipient: {
    accountId?: string;
    address: ICreateShipmentAddress;
    notificationEmail: string;
  };
  sender: {
    addressId?: string;
  };
  serviceLevel?: string;
  weightInGrams?: string;
}

export const INITIAL_Customer: ICustomer = {
  accountId: '',
  address: {
    id: '',
    createdAt: 0,
    createdBy: '',
    updatedAt: 0,
    versionHash: '',
    originalAddressId: '',
    firstName: '',
    lastName: '',
    company: '',
    displayName: '',
    street: '',
    street2: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    state: '',
    countryCode: '',
    position: {},
  },
  id: '',
  notificationEmail: '',
};

export interface ITrackingHistoryResponse {
  alerts: TAlert[];
  lastKnownLocation?: ICoordinate;
  lastKnownState?: ILastKnownState;
  rawPath: ICoordinate[];
  roadPath: ICoordinate[];
  shipmentId: string;
}

export interface IBoxEvent {
  consumedAt: string;
  coordinate: {
    accuracy: number;
    latitude: number;
    longitude: number;
    placeId: string;
    timestamp: number;
  };
  id: string;
  serviceLevel: number;
  shipmentId: string;
  state: any;
  thingName: string;
  timestamp: number;
  type: string;
}

export const INITIAL_Shipment: IModifiedShipment = {
  id: '',
  reference: '',
  isArchived: false,
  parsedCreatedAt: '',
  parsedUpdatedAt: '',
  createdBy: '',
  deployedBy: '',
  box: {
    bleMacAddress: '',
    id: '',
    thingName: '',
  },
  carrier: {
    carrierCode: '',
  },
  carrierTrackingCode: '',
  quote: {
    carrierCode: '' as TCarrierCodes,
    id: '',
    currency: '',
    priceInCents: 0,
    carrierService: '',
  },
  contractState: '',
  createdAt: 0,
  serviceLevel: '1',
  recipient: INITIAL_Customer,
  sender: INITIAL_Customer,
  shipmentType: '' as TShipmentType,
  updatedAt: 0,
  versionHash: '',
  recipientSender: [null, null],
  carrierCode: '' as TCarrierCodes,
  carrierLabel: {
    code: '' as TCarrierCodes,
    labelSource: '',
    rawData: [],
  },
  deliveryStatus: null,
  partnerId: '',
  boxWeightInGrams: 0,
  labelWeightInGrams: 0,
  weightInGrams: 0,
};

export const INITIAL_CreateShipment: ICreateShipment = {
  recipient: {
    accountId: '',
    address: INITIAL_CreateShipmentAddress,
    notificationEmail: '',
  },
  sender: {
    accountId: '',
    address: INITIAL_CreateShipmentAddress,
    notificationEmail: '',
    addressId: '',
  },
  serviceLevel: '1',
  weightInGrams: '0',
  partnerId: '',
};

export interface ShipmentLabelImage {
  img: { image: string; format: string };
  header: {
    signature: string;
    crc: number;
    seqNum: number;
    versionHash: string;
  };
}

export interface ElabelResponse {
  elabel: {
    payload: {
      image: string;
      format: string;
    };
    header: {
      signature: string;
      crc: number;
      seqNum: number;
      versionHash: string;
    };
  };
}
