import { create } from 'zustand';

import { IRelation, TRelationTypes } from '../models/box';

export type TRelationsSortBy = 'createdAt' | 'updatedAt';

interface IRelationsStore {
  rowData: IRelation[];
  pageSize: number;
  offset: number;
  sortBy: TRelationsSortBy;
  order: 'ASC' | 'DESC';
  defaultSort: {
    sortBy: TRelationsSortBy;
    order: 'ASC' | 'DESC';
  };
  error: string | boolean;
  loading: boolean;
  count: number;
  dispatch: (args: TRelationsReducerInput) => void;
  total: number;
  relationType: TRelationTypes | null;
}

type TRelationsReducerInput =
  | {
      type: 'updateRowData';
      args: { rowData: any[] };
    }
  | {
      type: 'updateError';
      args: { error: string | boolean };
    }
  | {
      type: 'updateLoading';
      args: { loading: boolean };
    }
  | {
      type: 'updatePagination';
      args: { offset: number };
    }
  | {
      type: 'updateSorting';
      args: {
        sortBy: TRelationsSortBy;
        order: 'ASC' | 'DESC';
      };
    }
  | {
      type: 'updateFilters';
      args: { relationType: TRelationTypes };
    }
  | {
      type: 'updateCount';
      args: { count: number };
    }
  | {
      type: 'updateTotal';
      args: { total: number };
    }
  | {
      type: 'updateDefaultSort';
    }
  | {
      type: 'reset';
    };

const relationsReducer = (
  state: IRelationsStore,
  input: TRelationsReducerInput
) => {
  switch (input.type) {
    case 'updateRowData':
      return { ...state, rowData: input.args.rowData };
    case 'updateError':
      return { ...state, error: input.args.error };
    case 'updateLoading':
      return { ...state, loading: input.args.loading };
    case 'updatePagination':
      return {
        ...state,
        offset: input.args.offset,
      };
    case 'updateSorting':
      return { ...state, sortBy: input.args.sortBy, order: input.args.order };
    case 'updateCount':
      return { ...state, count: input.args.count };
    case 'updateFilters':
      return {
        ...state,
        relationType: input.args.relationType,
      };
    case 'updateTotal':
      return { ...state, total: input.args.total };
    case 'updateDefaultSort':
      return {
        ...state,
        defaultSort: { sortBy: state.sortBy, order: state.order },
      };
    case 'reset':
      return INITIAL_STATE;
  }
};

const INITIAL_STATE: Omit<IRelationsStore, 'dispatch'> = {
  rowData: [],
  error: '',
  loading: false,
  pageSize: 10,
  offset: 0,
  sortBy: 'createdAt',
  order: 'DESC',
  defaultSort: {
    sortBy: 'createdAt',
    order: 'DESC',
  },
  count: 0,
  total: 0,
  relationType: null,
};

const useBoxesRelationsStore = create<IRelationsStore>((set) => ({
  ...INITIAL_STATE,
  dispatch: (input) => set((state) => relationsReducer(state, input)),
}));

export default useBoxesRelationsStore;
