import { useCallback } from 'react';

import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

export enum TRANSACTION_METHOD_STATE {
  TRANSACTION_METHOD_STATE_UNSPECIFIED = 'unspecified',
  TRANSACTION_METHOD_STATE_AVAILABLE = 'available',
  TRANSACTION_METHOD_STATE_NOT_VALID = 'not_valid',
  TRANSACTION_METHOD_STATE_DELETED = 'deleted',
}

export enum TRANSACTION_METHOD_TYPE {
  TRANSACTION_METHOD_TYPE_UNSPECIFIED = 'TRANSACTION_METHOD_TYPE_UNSPECIFIED',
  TRANSACTION_METHOD_TYPE_WIRE = 'TRANSACTION_METHOD_TYPE_WIRE',
}

export interface IWallet {
  transaction_methods: [
    {
      created_at: '2019-08-24T14:15:22Z';
      deleted_at: '2019-08-24T14:15:22Z';
      id: 'string';
      state: TRANSACTION_METHOD_STATE;
      type: TRANSACTION_METHOD_TYPE;
      wire: {
        bank_account_number: 'string';
        bank_title: 'string';
        beneficiary_name: 'string';
        bic: 'string';
        iban: 'string';
        swift_code: 'string';
      };
    },
  ];
}

const useLPAccountWallet = () => {
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpAccountV3');

  const getWallet = useCallback(
    async (account_id) => {
      const { error, data } = await makeRequest({
        path: `/${account_id}/wallet`,
        method: 'GET',
        body: {},
      });

      if (error) {
      }

      if (data) {
        return data.wallet;
      }
    },
    [makeRequest]
  );

  return { getWallet, loading };
};

export default useLPAccountWallet;
