import React, { useCallback } from 'react';

import { Stack } from '@lp/ds-next';
import { Link } from 'react-router-dom';

import useShipmentsStore, {
  dispatchSelector,
  searchTermsSelector,
} from '../../shipments/hooks/useShipmentsStore';
import PillButton from '@/components/PillButton';
import { IShipment } from '@/features/shipments/models/shipment';
// TODO(santi698): Improve this to make it into a public shipments feature module API

const GoToBoxShipmentButton = ({
  boxId,
  shipment,
  partnerId,
}: {
  boxId?: string;
  shipment?: IShipment;
  partnerId?: string;
}) => {
  const searchTerms = useShipmentsStore(searchTermsSelector);
  const dispatch = useShipmentsStore(dispatchSelector);

  const buttonClickHandler = useCallback(() => {
    if (boxId) {
      dispatch({ type: 'updateSearchTerms', args: { ...searchTerms, boxId } });
    }
    if (partnerId) {
      dispatch({
        type: 'updateSearchTerms',
        args: { ...searchTerms, partnerId },
      });
    }
  }, [dispatch, searchTerms, boxId, partnerId]);

  return (
    <Stack direction="row" gap="1rem">
      {boxId && shipment && (
        <Link to={`/shipments/${shipment.id}`}>
          <PillButton>To Shipment</PillButton>
        </Link>
      )}
      <Link to="/shipments" onClick={buttonClickHandler}>
        <PillButton>To Shipments</PillButton>
      </Link>
    </Stack>
  );
};

export default GoToBoxShipmentButton;
