import React from 'react';
const DashboardIcon = (props) => (
  <svg viewBox="0 0 24 24" width="2.25rem" height="2.25rem" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.4998 0.800049H2.4998C1.28478 0.800049
          0.299805 1.78502 0.299805 3.00005V6.00005C0.299805 7.21508 1.28478 8.20005 2.4998
          8.20005H5.4998C6.71483 8.20005 7.6998 7.21508 7.6998 6.00005V3.00005C7.6998 1.78502 6.71483
          0.800049 5.4998 0.800049ZM2.4998 2.20005H5.4998C5.94163 2.20005 6.2998 2.55822 6.2998
          3.00005V6.00005C6.2998 6.44188 5.94163 6.80005 5.4998 6.80005H2.4998C2.05798 6.80005
          1.6998 6.44188 1.6998 6.00005V3.00005C1.6998 2.55822 2.05798 2.20005 2.4998 2.20005ZM5.4998
          10.3H2.4998C1.28478 10.3 0.299805 11.285 0.299805 12.5V15.5C0.299805 16.7151 1.28478 17.7
          2.4998 17.7H5.4998C6.71483 17.7 7.6998 16.7151 7.6998 15.5V12.5C7.6998 11.285 6.71483 10.3 5.4998
          10.3ZM2.4998 11.7H5.4998C5.94163 11.7 6.2998 12.0582 6.2998 12.5V15.5C6.2998 15.9419 5.94163
          16.3 5.4998 16.3H2.4998C2.05798 16.3 1.6998 15.9419 1.6998 15.5V12.5C1.6998 12.0582 2.05798
          11.7 2.4998 11.7ZM11.9998 0.800049H14.9998C16.2148 0.800049 17.1998 1.78502 17.1998
          3.00005V6.00005C17.1998 7.21508 16.2148 8.20005 14.9998 8.20005H11.9998C10.7848 8.20005
          9.7998 7.21508 9.7998 6.00005V3.00005C9.7998 1.78502 10.7848 0.800049 11.9998 0.800049ZM14.9998
          2.20005H11.9998C11.558 2.20005 11.1998 2.55822 11.1998 3.00005V6.00005C11.1998 6.44188 11.558 6.80005
          11.9998 6.80005H14.9998C15.4416 6.80005 15.7998 6.44188 15.7998 6.00005V3.00005C15.7998 2.55822 15.4416
          2.20005 14.9998 2.20005ZM14.9998 10.3H11.9998C10.7848 10.3 9.7998 11.285 9.7998 12.5V15.5C9.7998 16.7151
          10.7848 17.7 11.9998 17.7H14.9998C16.2148 17.7 17.1998 16.7151 17.1998 15.5V12.5C17.1998 11.285 16.2148 10.3
          14.9998 10.3ZM11.9998 11.7H14.9998C15.4416 11.7 15.7998 12.0582 15.7998 12.5V15.5C15.7998 15.9419 15.4416 16.3
          14.9998 16.3H11.9998C11.558 16.3 11.1998 15.9419 11.1998 15.5V12.5C11.1998 12.0582 11.558 11.7 11.9998 11.7Z"
      fill="currentColor"
    />
  </svg>
);

export default DashboardIcon;
