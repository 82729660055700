import { useCallback, useState } from 'react';

import has from 'lodash/has';

import useDeleteScopeFromRole from '../hooks/useDeleteScopeFromRole';
import { IRole } from '../models/role';

const useModifyScopes: any = (rowData) => {
  const [updatedScopes, setUpdatedScopes] = useState<any>({});
  const [modifiedRowData, setModifiedRowData] = useState<IRole[]>([]);
  const [editScopes, setEditScopes] = useState<boolean>(false);
  const deleteScope = useDeleteScopeFromRole();

  const handleEditScopes = useCallback(() => {
    setEditScopes(true);
    setModifiedRowData(rowData);
  }, [rowData]);

  const computeScopesToKeep = useCallback(
    (roleId: string, scopeId: string) => {
      const modifiedRD = modifiedRowData.map((r) => {
        if (r.id === roleId) {
          const rest = r.scopes.filter((s) => s.id !== scopeId);

          return { ...r, scopes: rest };
        }

        return r;
      });

      setModifiedRowData(modifiedRD);
    },
    [modifiedRowData]
  );

  const handleCloseTag = useCallback(
    (role, scopeId) => {
      if (has(updatedScopes, role.id)) {
        setUpdatedScopes((updatedScopes) => ({
          ...updatedScopes,
          [role.id]: [...updatedScopes[role.id], { id: scopeId }],
        }));
      } else {
        setUpdatedScopes((updatedScopes) => ({
          ...updatedScopes,
          [role.id]: [{ id: scopeId }],
        }));
      }
      computeScopesToKeep(role.id, scopeId);
    },
    [updatedScopes, computeScopesToKeep]
  );

  const handleResetScopes = useCallback(() => {
    setEditScopes(false);
    setUpdatedScopes({});
    setModifiedRowData([]);
  }, []);

  const handleSaveDeletedScopes = useCallback(async () => {
    setEditScopes(false);
    for (const roleId in updatedScopes) {
      await deleteScope(
        rowData.find((r) => roleId === r.id),
        updatedScopes[roleId].map((s) => s.id)
      );
    }
    setUpdatedScopes({});
    setModifiedRowData([]);
  }, [deleteScope, updatedScopes, rowData]);

  return [
    modifiedRowData,
    editScopes,
    handleEditScopes,
    handleCloseTag,
    handleResetScopes,
    handleSaveDeletedScopes,
  ];
};

export default useModifyScopes;
