import { useCallback } from 'react';

import useLPPartnersStore from './useLPPartnersStore';
import lpPartnersPostProcessor from '../helpers/lpPartnersPostProcessor';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useRefreshPartners = () => {
  const dispatch = useLPPartnersStore((state) => state.dispatch);
  const order = useLPPartnersStore((state) => state.order);
  const sortBy = useLPPartnersStore((state) => state.sortBy);
  const pageSize = useLPPartnersStore((state) => state.pageSize);
  const offset = useLPPartnersStore((state) => state.offset);
  const addError = useError();
  const [, makeRequest] = useImperativeRequestWrapper('lpVillage');

  const getPartners = useCallback(async () => {
    const { data, error } = await getPaginatedTableData({
      params: {
        path: '/admin/partners',
        method: 'get',
      },
      reducer: lpPartnersPostProcessor,
      dispatch,
      addError,
      makeRequest,
      queryParams: Object.assign({
        pageSize: pageSize,
        offset: offset,
        sortBy: sortBy,
        order: order,
      }),
      queryParamsOptions: { skipEmptyString: true, skipNull: true },
    });

    return { data, error };
  }, [addError, dispatch, makeRequest, order, sortBy, offset, pageSize]);

  return getPartners;
};

export default useRefreshPartners;
