import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Header from '@/components/layout/Header';
import MainContainer from '@/components/layout/MainContainer';
import PublicMain from '@/components/layout/PublicMain';
import PillButton from '@/components/PillButton';
import H2 from '@/components/text/H2';

const CardBody = styled.div`
  text-align: center;
  position: relative;
  background: white;
  padding: 2rem 4rem;
  border-radius: 12px;
  @media all and (max-width: 992px) {
    width: 100%;
  }
`;

const NotFound = () => (
  <MainContainer>
    <Header />
    <PublicMain>
      <CardBody>
        <H2>Sorry, that page was not found!</H2>
        <Link to="/">
          <PillButton>Return to Home</PillButton>
        </Link>
      </CardBody>
    </PublicMain>
  </MainContainer>
);

export default NotFound;
