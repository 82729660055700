import { useCallback } from 'react';

import useBoxesLifecycleStore from './useBoxesLifecycleStore';
import datePostProcessor from '@/helpers/datePostProcessor';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useBoxLifecycle = () => {
  const addError = useError();
  const successMsg = useToasts((state) => state.addItem);
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('deviceV3');
  const [{ loading: loadingCreate }, makeRequestCreate] =
    useImperativeRequestWrapper('deviceV3');
  const { dispatch } = useBoxesLifecycleStore();

  const addLifecycleStatus = useCallback(
    async (
      boxId,
      { status, sub_status }: { status: string; sub_status?: string }
    ) => {
      const { error } = await makeRequestCreate({
        path: `/products/${boxId}/lifecycle-status`,
        method: 'post',
        body: { station_source: 'Admin Panel', status, sub_status },
      });

      if (error) {
        addError('There was a problem adding the new status');

        return { success: false };
      }
      successMsg(
        { msg: 'Successfully added status', type: MessageType.Success },
        'root',
        {}
      );

      return { success: true };
    },
    [makeRequestCreate, addError, successMsg]
  );

  const getLifecycleStatuses = useCallback(
    async (boxId) => {
      await getPaginatedTableData({
        params: {
          path: `/products/${boxId}/lifecycle-statuses`,
          method: 'get',
        },
        reducer: datePostProcessor,
        dispatch,
        addError,
        makeRequest,
        queryParams: {
          pageSize: 100,
          offset: 0,
        },
        queryParamsOptions: { skipEmptyString: true, skipNull: true },
        items: 'lifecycle_statuses',
      });
    },
    [addError, dispatch, makeRequest]
  );

  return { loading, loadingCreate, addLifecycleStatus, getLifecycleStatuses };
};

export default useBoxLifecycle;
