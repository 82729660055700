import React from 'react';

import BeatLoader from 'react-spinners/BeatLoader';
import styled, { withTheme } from 'styled-components';
const Wrapper = styled.div<any>`
  background: ${(props) =>
    props.absolute ? props.theme.colors.white : 'none'};
  opacity: ${(props) => (props.absolute ? props.opacity : '1')};
  position: ${(props) => (props.absolute ? 'absolute' : 'static')};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = (props: {
  absolute?: boolean;
  size: number | string;
  opacity?: string;
  theme: any;
  color?: string;
}) => (
  <Wrapper absolute={props.absolute} opacity={props.opacity}>
    <BeatLoader
      size={props.size}
      color={props.color || props.theme.colors.brand}
    />
  </Wrapper>
);

export default withTheme(Loader);
