import React from 'react';

import styled from 'styled-components';

import SpinLoader from '@/components/SpinLoader';

const Wrapper = styled.div`
  margin-top: 45vh;
  height: 100vh;
`;

const AppLoading = () => (
  <Wrapper>
    <SpinLoader />
  </Wrapper>
);

export default AppLoading;
