import React from 'react';

import styled from 'styled-components';

import OrigH2 from './text/H2';

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  & h2 {
    transition: color 0.2s linear;
  }
`;

const UserWrapper = styled.div`
  margin-right: 2rem;
`;

const H2 = styled(OrigH2)`
  margin: 0;
  cursor: pointer;
  :hover {
    color: ${(props) => props.theme.colors.adminPanel.brand};
    transition: color 0.2s linear;
  }
`;
const Logout = ({ data, children, onClick }) => (
  <Wrapper>
    <UserWrapper data-testid="email">{data.email}</UserWrapper>
    <H2 onClick={onClick} data-testid="logout">
      {children}
    </H2>
  </Wrapper>
);

export default Logout;
