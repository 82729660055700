import React, { useCallback, useMemo, useState } from 'react';

import { Row, Tabs } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { object, string } from 'yup';

import DeleteShop from './DeleteShop';
import ShopForm from './ShopForm';
import WrapperAccountsTableShop from './WrapperAccountTableShop';
import useLPShop from '../hooks/useLPShop';
import AddressForm from '@/components/AddressForm';
import Avatar from '@/components/Avatar';
import EditButtonsOnForm from '@/components/EditButtonsOnForm';
import TabPane from '@/components/layout/TabPane';
import PillButton from '@/components/PillButton';
import H2 from '@/components/text/H2';
import { scopes } from '@/config';
import useEditRecord from '@/hooks/useEditRecord';
import useError from '@/hooks/useError';

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 15rem;
`;

const AvatarWrapper = styled.div`
  margin-bottom: 2rem;
`;

const WrapperAddress = styled.div`
  margin-bottom: 3rem;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 1rem;
`;

const DeletedH2 = styled(H2)`
  color: ${(props) => props.theme.colors.adminPanel.error};
`;

const StyledTabs = styled(Tabs)`
  overflow: unset;
`;

const editable = [
  'name',
  'partnerId',
  'website',
  'phoneNumber',
  'description',
] as const;

const ShopSchema = object().shape({
  name: string().required(),
  website: string().required(),
  phoneNumber: string().required(),
  description: string().required(),
});

const addressEditable = [
  'firstName',
  'lastName',
  'displayName',
  'company',
  'houseNumber',
  'street',
  'street2',
  'postalCode',
  'city',
  'state',
  'countryCode',
] as const;

const AddressSchema = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  street: string().required(),
  postalCode: string().required(),
  city: string().required(),
  countryCode: string().required(),
});

const LPShopDetailsContent = () => {
  const { id } = useParams<{ id: string }>();
  const { shop, updateShopInfo, updateShopAddress, setShop } = useLPShop(id!);
  const [tab, setTab] = useState('address');
  const history = useNavigate();
  const addError = useError();

  const {
    editedData,
    setEditedData,
    handleSubmit,
    handleCancel,
    isInvalid,
    recordHasNotBeenModified,
  } = useEditRecord({
    editable,
    originalRecord: shop.data,
    updateRecord: updateShopInfo,
    validationSchema: ShopSchema,
  });

  const {
    editedData: editedAddress,
    setEditedData: setEditedAddress,
    handleSubmit: handleSubmitAddress,
    handleCancel: handleCancelAddress,
    isInvalid: isInvalidAddress,
    recordHasNotBeenModified: addressHasNotBeenModified,
  } = useEditRecord({
    editable: addressEditable,
    originalRecord: shop.data.address,
    updateRecord: updateShopAddress,
    validationSchema: AddressSchema,
  });

  const handleAccountLink = useCallback(
    () =>
      !!shop?.data?.partner?.id
        ? history(`/lp-village/partners/${shop.data.partner.id}`)
        : addError(new Error('Unable to find Partner')),
    [history, addError, shop]
  );

  const PartnerLink = useMemo(
    () => (
      <PillButton onClick={handleAccountLink} size="large">
        To LP-Partner
      </PillButton>
    ),
    [handleAccountLink]
  );

  return (
    <Wrapper>
      <StyledRow>
        <AvatarWrapper>
          <Avatar height="5rem" width="5rem" src={shop.data.thumbnail} />
        </AvatarWrapper>
        {!shop.deleted && <DeleteShop shopId={id} setShop={setShop} />}
      </StyledRow>
      {shop.deleted && (
        <Row>
          <DeletedH2 data-testid="userDeleted">DELETED</DeletedH2>
        </Row>
      )}
      <ShopForm
        data={{ ...shop.data, ...editedData }}
        setData={setEditedData}
        partnerLink={shop?.data?.partner?.id && PartnerLink}
      />
      <EditButtonsOnForm
        handleCancel={handleCancel}
        handleSaveEdit={handleSubmit}
        disabledCancel={recordHasNotBeenModified}
        disabledSubmit={recordHasNotBeenModified || isInvalid}
        requiredScopes={[scopes.EDIT_LPVILLAGE_SHOPS]}
      />
      <StyledTabs type="card" activeKey={tab} onTabClick={(key) => setTab(key)}>
        <TabPane
          tab="Address"
          key="address"
          currentTab={tab}
          identifier="address"
          remount
        >
          <WrapperAddress>
            <AddressForm
              address={{ ...shop.data.address, ...editedAddress }}
              setAddress={(values) => setEditedAddress(values)}
              isPartnerOrShop={true}
              hidePrimary={true}
              disableTypeSelector={true}
              initialFocus={false}
            />
          </WrapperAddress>

          <EditButtonsOnForm
            handleCancel={handleCancelAddress}
            handleSaveEdit={handleSubmitAddress}
            disabledCancel={addressHasNotBeenModified}
            disabledSubmit={addressHasNotBeenModified || isInvalidAddress}
          />
        </TabPane>
        <TabPane
          tab="Accounts"
          key="accounts"
          currentTab={tab}
          identifier="accounts"
          remount
        >
          <WrapperAccountsTableShop id={id} />
        </TabPane>
      </StyledTabs>
    </Wrapper>
  );
};

export default LPShopDetailsContent;
