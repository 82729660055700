import React from 'react';

import { TableCell, TableRow } from '@material-ui/core';
import { Form, Input, InputNumber } from 'antd';
import { DatePicker } from 'antd';

export const getRows = (step, columns) => {
  const getInputs = (col) => {
    if (col.key === 'newsletter_number') {
      return <InputNumber min={0} max={1000} />;
    } else if (col.key === 'publicated_at') {
      return <DatePicker />;
    }

    return <Input />;
  };

  const rows: any[] = [];

  for (let i = 0; i < 5; i++) {
    rows.push(
      <TableRow key={i}>
        {columns.map((col, index) => {
          if (step === 1 && col.language === 'english') {
            return (
              <TableCell key={index} component="th" scope="row">
                <Form.Item
                  key={index}
                  name={col.key + i}
                  rules={[{ required: true, message: 'Input is required' }]}
                >
                  {getInputs(col)}
                </Form.Item>
              </TableCell>
            );
          }
          if (step === 2 && col.language === 'french') {
            return (
              <TableCell key={index} component="th" scope="row">
                <Form.Item
                  key={index}
                  name={col.key + i}
                  rules={[{ required: true, message: 'Input is required' }]}
                >
                  {getInputs(col)}
                </Form.Item>
              </TableCell>
            );
          }
          if (step === 3 && col.language === 'german') {
            return (
              <TableCell key={index} component="th" scope="row">
                <Form.Item
                  key={index}
                  name={col.key + i}
                  rules={[{ required: true, message: 'Input is required' }]}
                >
                  {getInputs(col)}
                </Form.Item>
              </TableCell>
            );
          }

          return null;
        })}
      </TableRow>
    );
  }

  return rows;
};
