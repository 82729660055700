import styled from 'styled-components';

import bgImage from '@/assets/images/boxes.png';

export default styled.div`
  width: 70%;
  max-width: 1152px;
  margin: 0 auto;
  overflow: visible;
  margin-top: 50px;
  &:after {
    background-image: url(${bgImage});
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    left: 0;
    top: 0;
    z-index: -1;
    pointer-events: none;
  }
`;
