import { create } from 'zustand';

export interface IAccountInvitationsStore {
  rowData: any[];
  pageSize: number;
  offset: number;
  sortBy: string;
  order: 'ASC' | 'DESC';
  error: string | boolean;
  loading: boolean;
  dispatch: (args: TAccountInvitationsReducerInput) => void;
  count: number;
  total: number;
  filters: {
    onlyActive: boolean;
    invitationTypes: 'default' | 'personal'[];
  };
  viewModalCode: any;
}

export type TAccountInvitationsReducerInput =
  | {
      type: 'updateRowData';
      args: { rowData: any[] };
    }
  | {
      type: 'updateError';
      args: { error: string | boolean };
    }
  | {
      type: 'updateLoading';
      args: { loading: boolean };
    }
  | {
      type: 'updatePagination';
      args: { pageSize: number; offset: number };
    }
  | {
      type: 'updateSorting';
      args: {
        sortBy: string;
        order: 'ASC' | 'DESC';
      };
    }
  | {
      type: 'updateCount';
      args: { count: number };
    }
  | {
      type: 'updateTotal';
      args: { total: number };
    }
  | {
      type: 'updateFilters';
      args: {
        filters: {
          onlyActive: boolean;
          invitationTypes: 'default' | 'personal'[];
        };
      };
    }
  | { type: 'updateViewModal'; args: { viewModalCode: any } }
  | {
      type: 'reset';
    };

export const invitationsReducer = (
  state: IAccountInvitationsStore,
  input: TAccountInvitationsReducerInput,
  INITIAL_STATE
) => {
  switch (input.type) {
    case 'updateRowData':
      return { ...state, rowData: input.args.rowData };
    case 'updateError':
      return { ...state, error: input.args.error };
    case 'updateLoading':
      return { ...state, loading: input.args.loading };
    case 'updatePagination':
      return {
        ...state,
        pageSize: input.args.pageSize,
        offset: input.args.offset,
      };
    case 'updateSorting':
      return { ...state, sortBy: input.args.sortBy, order: input.args.order };
    case 'updateCount':
      return { ...state, count: input.args.count };
    case 'updateTotal':
      return { ...state, total: input.args.total };
    case 'updateFilters':
      return { ...state, filters: input.args.filters };
    case 'updateViewModal':
      return { ...state, viewModalCode: input.args.viewModalCode };
    case 'reset':
      return INITIAL_STATE;
  }
};

export const INITIAL_STATE: Omit<IAccountInvitationsStore, 'dispatch'> = {
  rowData: [],
  error: '',
  loading: false,
  pageSize: 10,
  offset: 0,
  sortBy: 'updatedAt',
  order: 'DESC',
  count: 0,
  total: 0,
  filters: {
    onlyActive: false,
    invitationTypes: [],
  },
  viewModalCode: {},
};

const useAccountInvitationsStore = create<IAccountInvitationsStore>((set) => ({
  ...INITIAL_STATE,
  dispatch: (input) =>
    set((state) => invitationsReducer(state, input, INITIAL_STATE)),
}));

export default useAccountInvitationsStore;
