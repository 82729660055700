import React, { useEffect, useCallback } from 'react';

import get from 'lodash/get';
import { Navigate } from 'react-router-dom';

import getScopesFromToken from '../helpers/getScopesFromToken';
import useAppState from '../hooks/useAppState';
import useError from '../hooks/useError';
import { useAuth0 } from '@/shared/auth0';

const AppState = ({ children }) => {
  const { isAuthenticated, user, getToken, loading } = useAuth0();
  const appStateToken = useAppState((state) => state.token);
  const { setAppState } = useAppState();
  const addError = useError();

  const getTokenFromAuth = useCallback(async () => {
    setAppState({ loading: true });
    let token;
    if (window.Cypress !== undefined) {
      const auth0Cypress = window.localStorage.getItem('auth0Cypress');

      if (auth0Cypress) {
        token = JSON.parse(auth0Cypress).body.access_token;
      }
    } else {
      token = await getToken();
    }

    if (!token) {
      setAppState({ loading: false, error: 'Unable to get token' });
      addError(new Error('Unable to get token'));

      return;
    }
    const scopes = getScopesFromToken(token);
    setAppState({ loading: false, error: '', token, scopes });
  }, [getToken, setAppState, addError]);

  useEffect(() => {
    if (!appStateToken && isAuthenticated && !loading) {
      getTokenFromAuth();
    } else if (!appStateToken && !isAuthenticated && !loading) {
      setAppState({ loading: false });
    }
  }, [appStateToken, getTokenFromAuth, setAppState, isAuthenticated, loading]);

  if (
    get(user, 'email_verified', null) === false &&
    process.env.REACT_APP_VERIFY_EMAIL
  ) {
    return <Navigate to="/verify-email" />;
  }

  return children;
};

export default AppState;
