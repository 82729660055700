import React, { useCallback, useEffect } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Grid } from '@material-ui/core';
import { ColumnProps } from 'antd/lib/table';
import get from 'lodash/get';
import styled from 'styled-components';

import CreateBoxContractOffer from './components/CreateBoxContractOffer';
import EditOfferDates from './components/EditOfferDates';
import boxOffersPostProcessor from './helpers/boxOfferPostProcessor';
import useBoxOffersStore from './hooks/useBoxOffersStore';
import useDeleteBoxOffer from './hooks/useDeleteBoxOffer';
import { ModifiedBoxContractOffer } from './models/boxContract';
import EditIcon from '@/components/icons/EditIcon';
import ScopedCta from '@/components/ScopedCta';
import StyledTable from '@/components/tables/StyledTable';
import { scopes } from '@/config';
import {
  getCurrentPage,
  getOffset,
  parseAntdSorter,
} from '@/helpers/antdTable';
import { storeSelector } from '@/helpers/paginatedStoreHelpers';
import useAntdColumns from '@/hooks/useAntdColumns';
import usePaginatedTableData from '@/hooks/usePaginatedTableData';
import useWindowSize from '@/hooks/useWindowSize';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Col = styled.div<{ flex?: number; marginRight?: string }>`
  flex: ${({ flex }) => flex || 1};
  margin-right: ${({ marginRight }) => marginRight || 0};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BoxOffersListView = () => {
  const {
    dispatch,
    order,
    sortBy,
    offset,
    pageSize,
    rowData,
    total,
    defaultSort,
    filters,
  } = useBoxOffersStore(storeSelector);

  const { deleteBoxOffer } = useDeleteBoxOffer();

  useEffect(() => () => dispatch({ type: 'reset' }), [dispatch]);

  const windowSize = useWindowSize();
  const { loading } = usePaginatedTableData({
    params: {
      path: '/admin/box-contract-offers',
      method: 'get',
      reducer: boxOffersPostProcessor,
    },
    dispatch,
    queryParams: {
      pageSize: pageSize,
      offset: offset,
      sortBy: sortBy,
      order: order,
      ...filters,
    },
    queryParamsOptions: { skipEmptyString: true, skipNull: true },
    service: 'serviceLpAccount',
  });

  const columns: ColumnProps<ModifiedBoxContractOffer>[] = useAntdColumns<
    ModifiedBoxContractOffer,
    'delete'
  >({
    columnsKeys: [
      'boxOfferId',
      'label',
      'offerType',
      'firstTrancheInEuro',
      'totalPriceInEuro',
      'durationInMonth',
      'rentServicePercentage',
      'activeAt',
      'deactivatedAt',
      'delete',
    ],
    addDefaultColumns: true,
    defaultSort,
    columnsSpecialProps: {
      activeAt: {
        render: (text, record) => (
          <Row>
            <Col flex={3} marginRight="0.25rem">
              {record.parsedActiveAt}
            </Col>
            <Col>
              <ScopedCta
                component={EditIcon}
                onClick={() =>
                  dispatch({
                    type: 'updateAdditionalState',
                    args: {
                      additionalState: {
                        editDeactivationDateId: null,
                        editActivationDateId: record.id,
                      },
                    },
                  })
                }
                requiredScopes={[scopes.MANAGE_BUY_N_RENT_OFFER]}
              />
            </Col>
          </Row>
        ),
        filters: [{ text: 'Show only active', value: 'only_active' }],
        filterMultiple: false,
        filteredValue: filters.onlyActive ? ['only_active'] : [],
        width: '7rem',
      },
      deactivatedAt: {
        render: (text, record) => (
          <Row>
            <Col flex={3} marginRight="0.25rem">
              {record.parsedDeactivatedAt}
            </Col>
            <Col>
              <ScopedCta
                component={EditIcon}
                onClick={() =>
                  dispatch({
                    type: 'updateAdditionalState',
                    args: {
                      additionalState: {
                        editDeactivationDateId: record.id,
                        editActivationDateId: null,
                      },
                    },
                  })
                }
                requiredScopes={[scopes.MANAGE_BUY_N_RENT_OFFER]}
              />
            </Col>
          </Row>
        ),
        width: '7rem',
      },
      firstTrancheInEuro: {
        render: (text, record) => `${record.firstTrancheInEuro} €`,
      },
      immediateProvisionInEuro: {
        render: (text, record) => `${record.immediateProvisionInEuro} €`,
      },
      totalPriceInEuro: {
        render: (text, record) => `${record.totalPriceInEuro} €`,
      },
      totalProvisionInEuro: {
        render: (text, record) => `${record.totalProvisionInEuro} €`,
      },
      delete: {
        align: 'center',
        render: (text, record) => (
          <ScopedCta
            component={DeleteOutlined}
            onClick={() => deleteBoxOffer(record.id)}
            requiredScopes={[scopes.MANAGE_BUY_N_RENT_OFFER]}
          />
        ),
        width: '4rem',
      },
    },
  });

  const pagination = {
    current: getCurrentPage(pageSize, offset),
    pageSize,
    total,
    showSizeChanger: true,
  };

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const [sortField, sortOrder] = parseAntdSorter(sorter);
      dispatch({
        type: 'updateSorting',
        args: { sortBy: sortField, order: sortOrder },
      });
      dispatch({
        type: 'updatePagination',
        args: {
          offset: getOffset(pagination.pageSize, pagination.current),
          pageSize: pagination.pageSize,
        },
      });
      const onlyActiveFilter = filters?.activeAt;
      dispatch({
        type: 'updateFilters',
        args: {
          filters: {
            onlyActive: !!(
              onlyActiveFilter &&
              onlyActiveFilter.length === 1 &&
              onlyActiveFilter[0] === 'only_active'
            ),
          },
        },
      });
    },
    [dispatch]
  );

  return (
    <>
      <Grid container justifyContent="center" spacing={3}>
        <Grid container item justifyContent="flex-end" xs={11}>
          <CreateBoxContractOffer />
        </Grid>
        <Grid item xs={11}>
          <StyledTable
            rowKey="id"
            dataSource={rowData}
            columns={columns}
            size="small"
            pagination={pagination}
            loading={loading}
            scroll={{ y: get(windowSize, 'height', 0) * 0.65 }}
            onChange={handleTableChange}
          />
        </Grid>
      </Grid>
      <EditOfferDates />
    </>
  );
};

export default BoxOffersListView;
