import { create } from 'zustand';

import {
  contributionsReducer,
  IContributionsStore,
  INITIAL_STATE,
} from './saContributions/useContributionsStore';

const MODIFIED_INITIAL_STATE = { ...INITIAL_STATE, pageSize: 10 };

const useLPAccountContributionsStore = create<IContributionsStore>((set) => ({
  ...MODIFIED_INITIAL_STATE,
  dispatch: (input) =>
    set((state) => contributionsReducer(state, input, MODIFIED_INITIAL_STATE)),
}));

export default useLPAccountContributionsStore;
