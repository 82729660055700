import React, { ReactNode } from 'react';

import { Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';

interface ICustomModal extends ModalProps {
  children: ReactNode;
  visible?: boolean;
}

const CustomModal = ({ children, ...rest }: ICustomModal) => (
  <Modal maskClosable={false} {...rest}>
    {children}
  </Modal>
);

export default CustomModal;
