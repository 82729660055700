import React, { useEffect } from 'react';

import { Route, Routes } from 'react-router-dom';

import SAContributionDetails from '../components/contributionDetails/SAContributionDetails';
import SAContributionsTable from '../components/contributionsTable/SAContributionsTable';
import useContributionsStore from '../hooks/saContributions/useContributionsStore';
import { appRoutes } from '@/configs/appRoutes';

const SAContributions = () => {
  const dispatch = useContributionsStore((state) => state.dispatch);

  useEffect(
    () => () => {
      dispatch({ type: 'reset' });
    },
    [dispatch]
  );

  return (
    <Routes>
      <Route path={appRoutes.ROOT} element={<SAContributionsTable />} />
      <Route path="/:id" element={<SAContributionDetails />} />
    </Routes>
  );
};

export default SAContributions;
