import React from 'react';

import { Routes, Route } from 'react-router-dom';

import ShipmentDetailsView from './views/ShipmentsDetailsView';
import ShipmentsListView from './views/ShipmentsListView';
import ScopedRoute from '@/components/ScopedRoute';
import { scopes } from '@/config';
import { appRoutes } from '@/configs/appRoutes';
import useAppState from '@/hooks/useAppState';
import ArticlesProvider from '@/shared/providers/articlesProvider';
import ShipmentsTotalProvider from '@/shared/providers/shipmentsTotalProvider';

const ShipmentsRouter = () => {
  const userScopes = useAppState((state) => state.scopes);

  return (
    <ArticlesProvider>
      <ShipmentsTotalProvider>
        <Routes>
          <Route
            path={appRoutes.ROOT}
            element={
              <ScopedRoute
                component={<ShipmentsListView />}
                userScopes={userScopes}
                requiredScopes={[scopes.VIEW_SHIPMENTS]}
              />
            }
          />
          <Route path="/:id" element={<ShipmentDetailsView />} />
        </Routes>
      </ShipmentsTotalProvider>
    </ArticlesProvider>
  );
};

export default ShipmentsRouter;
