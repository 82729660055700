import { useCallback, useEffect, useState } from 'react';

import shipmentsPostProcessor from '../helpers/shipmentsPostProcessor';
import { IModifiedShipment, INITIAL_Shipment } from '../models/shipment';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useGetShipment: any = (id) => {
  const [, makeRequest] = useImperativeRequestWrapper('shipment');
  const [shipment, setShipment] = useState<IModifiedShipment>(INITIAL_Shipment);
  const addError = useError();

  const getShipment = useCallback(() => {
    makeRequest({
      path: `/shipments/${id}`,
      method: 'get',
    }).then((res) => {
      if (!!res.error) {
        addError(res.error);

        return;
      }
      try {
        const processedData = shipmentsPostProcessor({
          items: [res.data.shipment],
        });
        setShipment(processedData.items[0]);
      } catch (err) {
        addError(err);
      }
    });
  }, [id, makeRequest, addError]);

  useEffect(() => {
    if (!id) return;
    getShipment();
  }, [getShipment, id]);

  return [shipment, getShipment];
};

export default useGetShipment;
