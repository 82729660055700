import React from 'react';

import { Grid, NewTextField as TextField } from '@lp/ds-next';

import { ChangelogTableAndFilters } from './commons/Changelog';
import { Targets } from '../api/getChangelog';
import {
  dateFormatter,
  linkToAccountFormatter,
} from '../helpers/cellsFormatter';
import { HeadCell } from '../types/changelog';
import {
  PartnerChangelog,
  PartnerChangelogFormValues,
  SortableFields,
} from '../types/partner_changelog';

const headCells: HeadCell[] = [
  {
    id: 'created_at',
    path: 'changelog.created_at',
    label: 'Updated at',
    order: true,
    formatter: dateFormatter,
  },
  {
    id: 'changelog_item_type',
    path: 'changelog.changelog_item_type',
    label: 'Item Type',
  },
  {
    id: 'client_name',
    path: 'client_name',
    label: 'Client Name',
  },
  {
    id: 'client_id',
    path: 'client_id',
    label: 'Client ID',
  },
  {
    id: 'created_by_id',
    path: 'changelog.created_by_id',
    label: 'Changed by',
    formatter: (value) => linkToAccountFormatter(value),
  },
];

const PartnerFilters = ({ formik }) => (
  <>
    <Grid item>
      <TextField
        label="Client ID"
        name="client_id"
        value={formik.values.client_id}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </Grid>
    <Grid item>
      <TextField
        label="Client Name"
        name="client_name"
        value={formik.values.client_name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
    </Grid>
  </>
);

const initialFilters: PartnerChangelogFormValues = {
  client_id: '',
  client_name: '',
};

export const Partner = () => (
  <ChangelogTableAndFilters<
    PartnerChangelog,
    PartnerChangelogFormValues,
    SortableFields
  >
    title="Partner"
    target={Targets.Partner}
    service="lpVillageV3"
    initialFilters={initialFilters}
    filtersComponent={PartnerFilters}
    initialOrderBy="created_at"
    headCells={headCells}
  />
);
