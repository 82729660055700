import { create } from 'zustand';

import { IModifiedShop } from '../models/shop';

export type TLPPartnersSortBy = 'name' | 'createdAt' | 'updatedAt';

interface ILPShopsStore {
  rowData: IModifiedShop[];
  pageSize: number;
  offset: number;
  sortBy: TLPPartnersSortBy;
  order: 'ASC' | 'DESC';
  defaultSort: {
    sortBy: TLPPartnersSortBy;
    order: 'ASC' | 'DESC';
  };
  error: string | boolean;
  loading: boolean;
  count: number;
  dispatch: (args: TLPShopsReducerInput) => void;
  total: number;
  serviceIds: string[] | null;
}

type TLPShopsReducerInput =
  | {
      type: 'updateRowData';
      args: { rowData: any[] };
    }
  | {
      type: 'updateError';
      args: { error: string | boolean };
    }
  | {
      type: 'updateLoading';
      args: { loading: boolean };
    }
  | {
      type: 'updatePagination';
      args: { pageSize: number; offset: number };
    }
  | {
      type: 'updateSorting';
      args: {
        sortBy: TLPPartnersSortBy;
        order: 'ASC' | 'DESC';
      };
    }
  | {
      type: 'updateCount';
      args: { count: number };
    }
  | {
      type: 'updateFilters';
      args: { serviceIds: string[] };
    }
  | {
      type: 'updateTotal';
      args: { total: number };
    }
  | {
      type: 'updateDefaultSort';
    }
  | {
      type: 'reset';
    };

const lpShopsReducer = (state: ILPShopsStore, input: TLPShopsReducerInput) => {
  switch (input.type) {
    case 'updateRowData':
      return { ...state, rowData: input.args.rowData };
    case 'updateError':
      return { ...state, error: input.args.error };
    case 'updateLoading':
      return { ...state, loading: input.args.loading };
    case 'updatePagination':
      return {
        ...state,
        pageSize: input.args.pageSize,
        offset: input.args.offset,
      };
    case 'updateSorting':
      return { ...state, sortBy: input.args.sortBy, order: input.args.order };
    case 'updateCount':
      return { ...state, count: input.args.count };
    case 'updateFilters':
      return {
        ...state,
        serviceIds: input.args.serviceIds,
      };
    case 'updateTotal':
      return { ...state, total: input.args.total };
    case 'updateDefaultSort':
      return {
        ...state,
        defaultSort: { sortBy: state.sortBy, order: state.order },
      };

    case 'reset':
      return INITIAL_STATE;
  }
};

const INITIAL_STATE: Omit<ILPShopsStore, 'dispatch'> = {
  rowData: [],
  error: '',
  loading: false,
  pageSize: 25,
  offset: 0,
  sortBy: 'updatedAt',
  order: 'DESC',
  defaultSort: {
    sortBy: 'updatedAt',
    order: 'DESC',
  },
  count: 0,
  total: 0,
  serviceIds: [],
};

const useLPShopsStore = create<ILPShopsStore>((set) => ({
  ...INITIAL_STATE,
  dispatch: (input) => set((state) => lpShopsReducer(state, input)),
}));

export default useLPShopsStore;
