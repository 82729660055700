import React from 'react';

import styled from 'styled-components';

import { TPartnerShopType } from '../services/models/service';
import Dropdown from '@/components/forms/Dropdown';
import Label from '@/components/forms/Label';

const LabelWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

const TYPES = [{ name: 'partner' }, { name: 'shop' }];

const PartnerShopTypeSelector = ({
  onChange,
  value,
}: {
  onChange: any;
  value?: { name: TPartnerShopType | '' };
}) => (
  <>
    <LabelWrapper>
      <Label>Type *</Label>
    </LabelWrapper>
    <Dropdown
      options={TYPES}
      getOptionLabel={(option) => option.name}
      mw="35rem"
      onChange={onChange}
      value={value}
    />
  </>
);

export default PartnerShopTypeSelector;
