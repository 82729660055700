import { BoxConfig } from '../models/boxConfig';
import { useApiClient } from '@/helpers/ApiClients';

type UpdateBoxConfiguration = (
  id: string,
  update: BoxConfig
) => Promise<BoxConfig>;

export function useUpdateBoxConfiguration(): UpdateBoxConfiguration {
  const device = useApiClient('device');

  return (id: string, update: BoxConfig) =>
    device
      .put<BoxConfig>(`/boxes/${id}/configuration`, update)
      .then((response) => response.data);
}
