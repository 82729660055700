import { object, string, date, number } from 'yup';

const boxContractSchema = object().shape({
  accountId: string().default(''),
  addressId: string().when('accountId', {
    is: (val: string) => val !== '',
    then: () => string().required(),
    otherwise: () => string(),
  }),
  email: string().when('accountId', {
    is: (val: string) => val === '',
    then: () => string().required(),
    otherwise: () => string(),
  }),
  acceptedTosVersion: string().required().default(''),
  activatedAt: date().nullable().default(null),
  boxContractOfferID: string().required().default(''),
  brokerId: string().default(''),
  createdAt: date().nullable().default(null),
  numOfBox: string().required().default(''),
  partnerId: string().default(''),
  paymentMethod: string().required().default(''),
  paymentProcessor: string().required().default(''),
  paymentStatus: string().required().default(''),
  paymentUniqueId: string().required().default(''),
  shopId: string().default(''),
  state: string().required().default(''),
  totalAmountInCents: string().required().default(''),
});

export const editBoxContractSchema = object().shape({
  paymentMethod: string().required().default(''),
  paymentProcessor: string().required().default(''),
  paymentStatus: string().required().default(''),
  paymentUniqueId: string().required().default(''),
  acceptedTosVersion: string().required().default(''),
  activatedAt: date().nullable().default(null),
  numOfBox: number().required().min(1),
  state: string().required().default(''),
  totalAmount: number().required(),
  accountId: string(),
  addressId: string().when('accountId', {
    is: (val) => val && val !== '',
    then: () => string().required(),
    otherwise: () => string(),
  }),
  email: string().when('accountId', {
    is: (val) => !val,
    then: () => string().email().required(),
    otherwise: () => string(),
  }),
});

export default boxContractSchema;
