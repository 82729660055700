import { deserializeBoxContract } from '../helpers/boxContractsPostProcessor';
import { BoxContract, SerializedBoxContract } from '../models/boxContract';
import { useApiClient } from '@/helpers/ApiClients';

export interface BoxContractUpdateRequest {
  paymentStatus: string;
  paymentProcessor: string;
  paymentDate: Date;
  paymentUniqueId: string;
  paymentMethod: string;
  paymentReceivedAt: Date;
  activatedAt: Date;
  state: string;
  acceptedTosVersion: string;
  totalAmountInCents: number;
  numOfBox: number;
  email: string;
}

type UpdateBoxContract = (
  id: string,
  update: BoxContractUpdateRequest
) => Promise<BoxContract>;

export function useUpdateBoxContract(): UpdateBoxContract {
  const lpAccount = useApiClient('serviceLpAccount');

  return (id: string, update: BoxContractUpdateRequest) =>
    lpAccount
      .patch<SerializedBoxContract>(`/admin/box-contracts/${id}`, {
        ...update,
        paymentReceivedAt: update.paymentReceivedAt.valueOf() / 1000,
        activatedAt: update.activatedAt.valueOf()
          ? update.activatedAt.valueOf() / 1000
          : null,
      })
      .then((response) => deserializeBoxContract(response.data));
}
