import React, { useMemo, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';

import { IServiceLevel } from '../../serviceLevels/models/serviceLevel';
import ServiceLevelForm from '../components/ServiceLevelForm';
import useAddServiceLevel from '../hooks/useAddServiceLevel';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';

interface AddServiceLevelProps {
  fetchServiceLevels: () => void;
  defaultConfig: IServiceLevel;
}

const AddServiceLevelModal = ({
  defaultConfig,
  fetchServiceLevels,
}: AddServiceLevelProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const { loading, addServiceLevel, setLoading } = useAddServiceLevel();

  const initialServiceLevel = useMemo(
    () => ({ ...defaultConfig, name: '', description: '' }),
    [defaultConfig]
  );

  return (
    <>
      <ScopedCta
        component={PillButton}
        icon={<PlusOutlined />}
        onClick={() => setVisible(true)}
        requiredScopes={[scopes.EDIT_SERVICE_LEVELS]}
      >
        Add Service Level
      </ScopedCta>

      {visible && (
        <ServiceLevelForm
          onSubmit={addServiceLevel}
          loading={loading}
          setLoading={setLoading}
          initialServiceLevel={initialServiceLevel}
          visible={visible}
          setVisible={setVisible}
          fetchServiceLevels={fetchServiceLevels}
        />
      )}
    </>
  );
};

export default AddServiceLevelModal;
