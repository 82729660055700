import React from 'react';
const ContributionIcon = () => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7977 0C18.2911 0 19.5017 1.21059 19.5017 2.70393C19.5017 3.88651 18.7425 4.89178
    17.6849 5.25896C17.533 6.85964 16.185 8.1118 14.5446 8.1118C12.8024 8.1118 11.39 6.69944 11.39 4.95721C11.39 3.31681 12.6421 1.96884
    14.2427 1.81687C14.6098 0.759233 15.6151 0 16.7977 0ZM18.1497 2.70393C18.1497 3.19243 17.8906 3.62042 17.5024 3.85798C17.1834 2.99996
    16.5017 2.31832 15.6437 1.99936C15.8812 1.61108 16.3092 1.35197 16.7977 1.35197C17.5444 1.35197 18.1497 1.95726 18.1497 2.70393ZM12.742
    4.95721C12.742 3.96165 13.549 3.15459 14.5446 3.15459C15.5401 3.15459 16.3472 3.96165 16.3472 4.95721C16.3472 5.95277 15.5401 6.75983
    14.5446 6.75983C13.549 6.75983 12.742 5.95277 12.742 4.95721Z"
      fill="#0CA86D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.84998 11C1.71219 11 1.59998 11.1122
    1.59998 11.25V17.25C1.59998 17.3878 1.71219 17.5 1.84998 17.5H3.83398C3.97176 17.5 4.08398
     17.3878 4.08398 17.25V12.2616C4.08387 12.2543 4.08387 12.2471 4.08398 12.2398V11.25C4.08398
     11.1122 3.97176 11 3.83398 11H1.84998ZM5.54325 10.8731C5.37077 10.0879 4.67079 9.5 3.83398
     9.5H1.84998C0.883756 9.5 0.0999756 10.2838 0.0999756 11.25V17.25C0.0999756 18.2162 0.883756
     19 1.84998 19H3.83398C4.53182 19 5.1345 18.5911 5.41546 18H6.33498C6.59978 18 6.86272 18.0325
     7.11636 18.0969L7.11849 18.0975L9.7572 18.7594C10.4385 18.9306 11.1484 18.9472 11.8356
     18.8093L14.7546 18.2233C15.6726 18.039 16.514 17.586 17.1743 16.9234L19.2382 14.8504C20.1191
     13.9665 20.1185 12.5344 19.2387 11.65C18.4437 10.8521 17.1843 10.7616 16.2842 11.4386L14.5479
     12.7462C14.548 12.7425 14.548 12.7387 14.548 12.735V12.432C14.548 11.3954 13.8453 10.4878
     12.8376 10.2356L10.5546 9.6626C10.1239 9.5546 9.68149 9.5 9.238 9.5C8.16669 9.5 7.1184 9.818
     6.22673 10.4158L5.54325 10.8731ZM5.58398 12.6506V16.5H6.33498C6.72178 16.5 7.10844 16.5474 7.48444
      16.6428L7.48559 16.6431L10.1225 17.3045C10.5871 17.4213 11.0715 17.4328 11.5404 17.3387L14.4593
      16.7527C15.0852 16.627 15.6598 16.318 16.1115 15.8648L18.1755 13.7918C18.4724 13.4939 18.4734 13.008
      18.1756 12.7083C17.9086 12.4403 17.4876 12.4104 17.1858 12.6374L14.7804 14.449C14.3053 14.8069 13.7277
      15 13.134 15H10.81C10.3958 15 10.06 14.6642 10.06 14.25C10.06 13.8358 10.3958 13.5 10.81 13.5H12.289C12.7052
      13.5 13.048 13.1604 13.048 12.735V12.432C13.048 12.0788 12.8089 11.7746 12.4731 11.6906L10.1896 11.1175C9.87829
      11.0395 9.5584 11 9.238 11C8.4635 11 7.70591 11.2299 7.06168 11.6619L5.58398 12.6506Z"
      fill="#0CA86D"
    />
  </svg>
);

export default ContributionIcon;
