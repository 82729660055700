import React, { Fragment, useMemo, useState } from 'react';

import { Button, Tooltip } from 'antd';
import get from 'lodash/get';
import toSafeInteger from 'lodash/toSafeInteger';
import styled from 'styled-components';

import CreateOfferForm from './CreateOfferForm';
import offersPostProcessor from '../../helpers/offersPostProcessor';
import useOffersStore from '../../hooks/useOffersStore';
import { INITIAL_CreateOffer } from '../../models/offer';
import CustomModal from '@/components/CustomModal';
import { nonIntegerError } from '@/components/forms/IntegerInput';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const ButtonWrapper = styled.span`
  margin-left: 8px;
`;

const AddOfferModal = () => {
  const showAddModal = useOffersStore((state) => state.showAddModal);
  const dispatch = useOffersStore((state) => state.dispatch);
  const pageSize = useOffersStore((state) => state.pageSize);
  const offset = useOffersStore((state) => state.offset);
  const sortBy = useOffersStore((state) => state.sortBy);
  const order = useOffersStore((state) => state.order);
  const onlyActive = useOffersStore((state) => state.onlyActive);
  const offerTypes = useOffersStore((state) => state.offerTypes);
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);

  const [offer, setOffer] = useState(INITIAL_CreateOffer);
  const [loading, setLoading] = useState(false);

  const isInvalid = useMemo(
    () =>
      !offer.label ||
      nonIntegerError(offer.durationInMonth) ||
      nonIntegerError(offer.multiplierInCents) ||
      nonIntegerError(offer.deactivationPaymentBufferInSec) ||
      !offer.offerType,
    [offer]
  );

  const handleSubmit = () => {
    setLoading(true);
    makeRequest({
      path: '/admin/offers',
      method: 'post',
      body: {
        ...offer,
        durationInMonth: toSafeInteger(offer.durationInMonth),
        multiplierInCents: toSafeInteger(offer.multiplierInCents),
        deactivationPaymentBufferInSec: toSafeInteger(
          offer.deactivationPaymentBufferInSec
        ),
      },
    }).then(({ data, error }) => {
      if (error) {
        addError(error);
        setLoading(false);

        return;
      }

      dispatch({
        type: 'updateNewOfferId',
        args: { newOfferId: get(data, 'id', null) },
      });
      closeModal();
      addSuccessMsg(
        { msg: 'Offer was successfully added!', type: MessageType.Success },
        'root',
        {}
      );

      getPaginatedTableData({
        params: {
          path: `/admin/offers`,
          method: 'get',
        },
        dispatch,
        queryParams: {
          pageSize,
          offset,
          sortBy,
          order,
          onlyActive,
          offerTypes,
        },
        reducer: offersPostProcessor,
        addError,
        makeRequest,
      });
      setTimeout(function () {
        dispatch({
          type: 'updateNewOfferId',
          args: { newOfferId: null },
        });
      }, 3000);
    });
  };

  const [, makeRequest] = useImperativeRequestWrapper('serviceLpAccount');

  const closeModal = () => {
    dispatch({ type: 'updateAddModal', args: { showAddModal: false } });
  };

  const afterClose = () => {
    setOffer(INITIAL_CreateOffer);
    if (loading) {
      setLoading(false);
    }
  };

  return (
    <CustomModal
      destroyOnClose={true}
      afterClose={afterClose}
      open={showAddModal}
      title="Add Offer (*=required)"
      onCancel={closeModal}
      onOk={handleSubmit}
      footer={[
        <Button key="cancel" onClick={closeModal}>
          Cancel
        </Button>,
        <Fragment key="submit">
          {isInvalid ? (
            <ButtonWrapper>
              <Tooltip
                placement="topRight"
                title="Please fill all required fields correctly"
              >
                <Button key="submit" type="primary" disabled={true}>
                  Submit
                </Button>
              </Tooltip>
            </ButtonWrapper>
          ) : (
            <Button type="primary" loading={loading} onClick={handleSubmit}>
              Submit
            </Button>
          )}
        </Fragment>,
      ]}
      width="80%"
    >
      <CreateOfferForm offer={offer} setOffer={setOffer} />
    </CustomModal>
  );
};

export default AddOfferModal;
