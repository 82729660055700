import React from 'react';

import styled from 'styled-components';

import Label from './Label';
import { StyledInputProps } from './StyledInput';
import Error from '@/components/Error';

const Wrapper = styled.div<any>`
  display: block;
  width: ${(props) => props.width || '100%'};
`;

const StyledTextarea = styled.textarea<StyledInputProps>`
  display: block;
  width: 100%;
  margin-bottom: 2rem;
  margin-top: 0.25rem;
  padding: 0.4rem;
  box-sizing: border-box;
  font-size: 0.9rem;
  border: none;
  background-color: ${(props) =>
    props.disabled || props.placeholder
      ? props.theme.colors.white
      : props.theme.colors.adminPanel.lightgreen};
  box-shadow: ${(props) =>
    props.error
      ? `inset 0px 0px 0px 2px ${props.theme.colors.adminPanel.error}`
      : `inset 0px -2px 0px 0px ${props.theme.colors.adminPanel.brand}`};
  font-family: TTNormsPro-Regular;
  outline: none;
  transition: ${(props) =>
    props.theme.transitions.adminPanel.short('background-color')}
  &:focus {
    background-color: ${(props) => props.theme.colors.adminPanel.grey};
  transition: ${(props) =>
    props.theme.transitions.adminPanel.short('background-color')}
  }
`;

const TextareaInput = (props: StyledInputProps & { rows?: number }) => {
  const { label, error, width, type, ...rest } = props;

  return (
    <Wrapper width={width}>
      <Label>
        {label}
        <StyledTextarea {...rest} error={error} type={type || 'text'} />
        <Error message={error} />
      </Label>
    </Wrapper>
  );
};

export default TextareaInput;
