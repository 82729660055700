import React, { useCallback } from 'react';

import { Col, Descriptions, Row, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import useBoxFirmwareHistory from '../hooks/useBoxFirmwareHistory';
import { IModifiedBox } from '../models/box';
import Loader from '@/components/Loader';
import StyledTable from '@/components/tables/StyledTable';
import { parseAntdSorter } from '@/helpers/antdTable';
import useAntdColumns from '@/hooks/useAntdColumns';
import { usePagination } from '@/hooks/usePagination';

interface boxFirmwareHistoryProps {
  box?: IModifiedBox;
  id?: string;
}

const BoxFirmwareHistory = ({ box, id }: boxFirmwareHistoryProps) => {
  const { params: paginationParams, setParams: setPaginationParams } =
    usePagination({
      page: 1,
      pageSize: 10,
      orderDirection: 'DESC',
      orderColumn: 'createdAt',
    });

  const { result: boxFirmwareHistory } = useBoxFirmwareHistory(
    id ? id : box!.id,
    paginationParams
  );

  const pagination = {
    current: paginationParams.page,
    pageSize: paginationParams.pageSize,
    total:
      boxFirmwareHistory.state === 'success'
        ? boxFirmwareHistory.resource.total
        : 0,
    showSizeChanger: false,
  };

  const handleTableChange = useCallback(
    ({ current: page, pageSize }, _filters, sorter) => {
      const [sortField] = parseAntdSorter(sorter);

      setPaginationParams({
        page,
        orderColumn: sortField,
        orderDirection: paginationParams.orderDirection,
        pageSize,
      });
    },
    [paginationParams.orderDirection, setPaginationParams]
  );

  const numberToHex = useCallback((val: Number) => {
    if (val) return val.toString(16).toLocaleUpperCase();
  }, []);

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: ['boxFirmwareVersion', 'parsedCreatedAt'],
    defaultSort: {
      sortBy: paginationParams.orderColumn,
      order: paginationParams.orderDirection,
    },
  });

  if (
    boxFirmwareHistory.state === 'idle' ||
    boxFirmwareHistory.state === 'loading'
  ) {
    return <Loader size={32} />;
  }

  return (
    <>
      <Row gutter={16}>
        <Col span={10}>
          <StyledTable
            rowKey="id"
            dataSource={
              boxFirmwareHistory.state === 'success' &&
              boxFirmwareHistory.resource.items
            }
            columns={columns}
            size="small"
            pagination={pagination}
            loading={boxFirmwareHistory.state !== 'success'}
            onChange={handleTableChange}
            scroll={{ x: '100%' }}
          />
        </Col>
        <Col span={14}>
          <Descriptions bordered size="middle">
            <Descriptions.Item label="Main Firmware version">
              <Typography.Text> {box?.firmwareVersion}</Typography.Text>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions bordered size="middle">
            <Descriptions.Item label=" Cortex FW version">
              <Typography.Text> {box?.cortexFirmwareVersion}</Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Cortex Boot version">
              <Typography.Text>{box?.cortexBootVersion} </Typography.Text>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions bordered size="middle">
            <Descriptions.Item label="Minus FW version">
              <Typography.Text>{box?.minusFirmwareVersion} </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Minus Boot version">
              <Typography.Text>{box?.minusBootVersion} </Typography.Text>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions bordered size="middle">
            <Descriptions.Item label="EPD FW version">
              <Typography.Text>{box?.ePaperDisplay} </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Modem FW version">
              <Typography.Text>{box?.modemFirmwareVersion} </Typography.Text>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions bordered size="middle">
            <Descriptions.Item label="BLE FUS Version">
              <Typography.Text>{box?.bleFusVersion} </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="BLE Stack version">
              <Typography.Text>{box?.bleStackVersion} </Typography.Text>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions bordered size="middle">
            <Descriptions.Item label="Wifi FW version">
              <Typography.Text>{box?.wifiFirmwareVersion} </Typography.Text>
            </Descriptions.Item>
          </Descriptions>
          <Descriptions bordered size="middle">
            <Descriptions.Item label="Article Embedded Code (Product Type) - value in hexa">
              <Typography.Text>
                {numberToHex(box?.ArticleEmbeddedCode!)}
              </Typography.Text>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </>
  );
};

export default BoxFirmwareHistory;
