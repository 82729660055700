import { SorterResult } from 'antd/lib/table/interface';

export const parseAntdSorter = (
  sorter: SorterResult<any>
): [any, 'ASC' | 'DESC'] =>
  sorter.order === 'descend' ? [sorter.field, 'DESC'] : [sorter.field, 'ASC'];

const getAntdSortOrder = (direction: 'DESC' | 'ASC') =>
  direction === 'DESC' ? 'descend' : 'ascend';

export const getDefaultSortOrder = (
  defaultSort: { sortBy: string; order: 'DESC' | 'ASC' },
  name: string
) =>
  defaultSort.sortBy === name ? getAntdSortOrder(defaultSort.order) : undefined;

export const getCurrentPage = (pageSize, offset) =>
  Math.round(offset / pageSize) + 1;
export const getOffset = (pageSize, currentPage) =>
  (currentPage - 1) * pageSize;
