import React from 'react';

import { Route, Routes } from 'react-router-dom';

import TransactionDetail from './views/TransactionDetail';
import TransactionList from './views/TransactionList';
import ScopedRoute from '@/components/ScopedRoute';
import { scopes } from '@/config';
import { appRoutes } from '@/configs/appRoutes';
import useAppState from '@/hooks/useAppState';

const TransactionRouter = () => {
  const useScopes = useAppState((state) => state.scopes);

  return (
    <Routes>
      <Route
        path={appRoutes.ROOT}
        element={
          <ScopedRoute
            component={<TransactionList />}
            userScopes={useScopes}
            requiredScopes={[scopes.VIEW_TRANSACTION]}
          />
        }
      />
      <Route
        path={appRoutes.TRANSACTION.DETAILS}
        element={
          <ScopedRoute
            component={<TransactionDetail />}
            userScopes={useScopes}
            requiredScopes={[scopes.VIEW_TRANSACTION]}
          />
        }
      />
    </Routes>
  );
};

export default TransactionRouter;
