import React from 'react';

import { Button as AntdButton, Tooltip } from 'antd';
import styled from 'styled-components';
const ButtonWrapper = styled.span`
  margin-left: 8px;
`;

const CancelSubmitFooterModal = (
  isInvalid,
  handleSubmit,
  closeModal,
  loading
) => [
  <AntdButton
    key="cancel"
    onClick={closeModal}
    data-testid="modal-cancel-button"
  >
    Cancel
  </AntdButton>,
  <ButtonWrapper key="submit">
    <Tooltip
      placement="topRight"
      title={isInvalid && 'Please fill all required fields'}
    >
      <AntdButton
        type="primary"
        data-testid="modal-submit-button"
        disabled={isInvalid}
        onClick={handleSubmit}
        loading={loading}
      >
        Submit
      </AntdButton>
    </Tooltip>
  </ButtonWrapper>,
];

export default CancelSubmitFooterModal;
