import React from 'react';

import Header from './Header';
import Main from './Main';
import MainContainer from './MainContainer';
import MainNavigation from './MainNavigation';
import TwoColumnWrapper from './TwoColumnWrapper';

const Layout = ({ children }) => (
  <MainContainer>
    <Header />
    <TwoColumnWrapper>
      <MainNavigation />
      <Main>{children}</Main>
    </TwoColumnWrapper>
  </MainContainer>
);

export default Layout;
