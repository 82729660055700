import React from 'react';

import Dropdown from './Dropdown';
import Label from './Label';
const CURRENCIES = [
  { label: 'EUR', value: 'EUR' },
  { label: 'USD', value: 'USD' },
];

const CurrencySelector = ({ label, onChange, currency }) => {
  const isOptionSelected = (option, selectValue) =>
    selectValue.some((val) => val.value === option.value);

  return (
    <>
      {label && <Label>{label}</Label>}

      <Dropdown
        isOptionSelected={isOptionSelected}
        defaultValue={CURRENCIES[0]}
        options={CURRENCIES}
        value={
          currency !== '' ? { label: currency, value: currency } : CURRENCIES[0]
        }
        onChange={onChange}
      />
    </>
  );
};

export default CurrencySelector;
