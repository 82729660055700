import React from 'react';

import { Col, Row } from 'antd';

import TextInput from '@/components/forms/TextInput';

const AccountForm = ({ account }) => {
  const {
    id,
    accountId,
    publicHandle,
    parsedCreatedAt,
    parsedUpdatedAt,
    firstName,
    lastName,
    email,
    phoneNumber,
  } = account;

  const actualId = id ? id : accountId;

  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            disabled
            label="Account-ID"
            name="accountId"
            value={actualId}
          />
        </Col>
        <Col span={12}>
          <TextInput
            disabled
            label="Public Handle"
            name="publicHandle"
            value={publicHandle}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            disabled
            label="Created at"
            name="parsedCreatedAt"
            value={parsedCreatedAt}
          />
        </Col>
        <Col span={12}>
          <TextInput
            disabled
            label="Updated at"
            name="parsedUpdatedAt"
            value={parsedUpdatedAt}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            label="First Name"
            name="firstName"
            disabled
            value={firstName}
          />
        </Col>
        <Col span={12}>
          <TextInput
            label="Last Name"
            name="lastName"
            disabled
            value={lastName}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput label="E-Mail" name="email" disabled value={email} />
        </Col>

        <Col span={12}>
          <TextInput
            label="Phone Number"
            name="phoneNumber"
            disabled
            value={phoneNumber}
          />
        </Col>
      </Row>
    </>
  );
};

export default AccountForm;
