import React, { ReactNode, useCallback } from 'react';

import { Col, Divider, Row } from 'antd';
import get from 'lodash/get';
import styled from 'styled-components';

import OpeningHours from './OpeningHours';
import { ICreateShop, IModifiedShop, IWeeklyOpeningTime } from '../models/shop';
import TextareaInput from '@/components/forms/TextareaInput';
import TextInput from '@/components/forms/TextInput';
import H2 from '@/components/text/H2';
import useInputFocus from '@/hooks/useInputFocus';

const ButtonLinkWrapper = styled(Col)`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
`;

const LABELS = {
  shopId: 'Shop-ID',
  name: 'Name',
  createdAt: 'Created at',
  updatedAt: 'Updated at',
  partnerId: 'Partner-ID',
  phoneNumber: 'Phone Number',
  website: 'Website',
  type: 'Type',
  description: 'Description',
};

const ShopForm = ({
  data,
  disabled = false,
  setData,
  partnerLink,
}: {
  data: Partial<IModifiedShop> & ICreateShop;
  disabled?: boolean;
  setData?: (res: any) => void;
  partnerLink?: ReactNode;
}) => {
  const [ref] = useInputFocus({ initialFocus: true });
  const {
    id,
    partnerId,
    name,
    parsedCreatedAt,
    parsedUpdatedAt,
    phoneNumber,
    website,
    description,
    openTimes,
  } = data;
  const getLabel = useCallback(
    (name: string, required: boolean = false) =>
      `${get(LABELS, name, '')} ${required && !disabled ? ' *' : ''}`,
    [disabled]
  );

  const handleOpenTimeChange = useCallback(
    (openTimes: IWeeklyOpeningTime) => {
      if (setData) {
        setData((prev) => ({
          ...prev,
          openTimes: openTimes,
        }));
      }
    },
    [setData]
  );

  const handleTextChange = useCallback(
    (e) => {
      const field = { name: e.target.name, value: e.target.value };
      if (setData) {
        setData((prev) => ({ ...prev, [field.name]: field.value }));
      }
    },
    [setData]
  );

  return (
    <>
      <Row gutter={24}>
        {id && (
          <Col span={12}>
            <TextInput
              disabled
              label={getLabel('shopId')}
              name="shopId"
              value={id}
            />
          </Col>
        )}
        <Col span={id ? 12 : 24}>
          <TextInput
            ref={ref}
            disabled={disabled}
            onChange={handleTextChange}
            label={getLabel('name', true)}
            name="name"
            value={name}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        {parsedCreatedAt && (
          <Col span={12}>
            <TextInput
              disabled
              label={getLabel('createdAt')}
              name="parsedCreatedAt"
              value={parsedCreatedAt}
            />
          </Col>
        )}
        {parsedUpdatedAt && (
          <Col span={12}>
            <TextInput
              disabled
              label={getLabel('updatedAt')}
              name="parsedUpdatedAt"
              value={parsedUpdatedAt}
            />
          </Col>
        )}
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            disabled={disabled}
            onChange={handleTextChange}
            label={getLabel('partnerId', false)}
            name="partnerId"
            value={partnerId}
          />
        </Col>
        <Col span={12}>
          <TextInput
            disabled={disabled}
            onChange={handleTextChange}
            label={getLabel('phoneNumber', true)}
            name="phoneNumber"
            value={phoneNumber}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            disabled={disabled}
            onChange={handleTextChange}
            label={getLabel('website', true)}
            name="website"
            value={website}
          />
        </Col>
        <Col span={12}>
          <TextareaInput
            disabled={disabled}
            onChange={handleTextChange}
            label={getLabel('description', true)}
            name="description"
            value={description}
            rows={3}
          />
        </Col>
      </Row>
      <Divider />
      <H2>Opening Hours</H2>
      {openTimes && (
        <OpeningHours openTimes={openTimes} onChange={handleOpenTimeChange} />
      )}
      <Divider />
      <Row gutter={24}>
        {partnerLink && (
          <ButtonLinkWrapper span={24}>{partnerLink}</ButtonLinkWrapper>
        )}
      </Row>
    </>
  );
};

export default ShopForm;
