import React from 'react';

function BilledIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <path
          d="M7.38995 13.82H16.2799C16.4299 13.82 16.5699 13.76 16.6799 13.65C16.7799 13.54 16.8499 13.4 16.8499 13.25C16.8499 13.1 16.7899 12.96 16.6799 12.85C16.5699 12.75 16.4299 12.69 16.2799 12.69H7.38995C7.07995 12.69 6.81995 12.94 6.81995 13.26C6.81995 13.58 7.06995 13.83 7.38995 13.83V13.82Z"
          fill="#555555"
        />
        <path
          d="M7.38995 18.58H16.2799C16.4299 18.58 16.5699 18.52 16.6799 18.41C16.7799 18.3 16.8499 18.16 16.8499 18.01C16.8499 17.86 16.7899 17.72 16.6799 17.61C16.5699 17.5 16.4299 17.44 16.2799 17.44H7.38995C7.07995 17.44 6.81995 17.69 6.81995 18.01C6.81995 18.33 7.06995 18.58 7.38995 18.58Z"
          fill="#555555"
        />
        <path
          d="M7.38995 16.2H16.2799C16.4299 16.2 16.5699 16.14 16.6799 16.03C16.7799 15.92 16.8499 15.78 16.8499 15.63C16.8499 15.48 16.7899 15.34 16.6799 15.23C16.5699 15.13 16.4299 15.06 16.2799 15.06H7.38995C7.07995 15.06 6.81995 15.31 6.81995 15.63C6.81995 15.95 7.06995 16.2 7.38995 16.2Z"
          fill="#555555"
        />
        <path
          d="M18.8099 2.32998L18.7399 2.43998L18.7999 2.32998C18.6999 2.27998 18.5599 2.24998 18.3699 2.24998H5.65992C5.65992 2.24998 5.37992 2.22998 5.20992 2.32998C5.02992 2.42998 4.91992 2.61998 4.91992 2.81998V21.19C4.91992 21.39 5.02992 21.58 5.20992 21.68C5.37992 21.78 5.59992 21.78 5.77992 21.68L7.07992 20.9L8.37992 21.68C8.55992 21.78 8.76992 21.78 8.94992 21.68L10.2499 20.9L11.5499 21.68C11.7199 21.78 11.9399 21.78 12.1199 21.68L13.4199 20.9L14.7199 21.68C14.8899 21.78 15.1099 21.78 15.2899 21.68L16.6099 20.89L18.2299 21.7C18.3099 21.74 18.3999 21.76 18.4999 21.76C18.5999 21.76 18.7099 21.73 18.7999 21.67C18.9699 21.57 19.0699 21.39 19.0699 21.19V2.80998C19.0699 2.60998 18.9699 2.42998 18.7999 2.32998H18.8099ZM17.9399 3.52998V20.27L16.8699 19.74C16.6999 19.64 16.4799 19.64 16.2999 19.74L15.0099 20.52L13.7099 19.74C13.5299 19.64 13.3199 19.64 13.1399 19.74L11.8399 20.52L10.5399 19.74C10.3599 19.64 10.1499 19.64 9.96992 19.74L8.66992 20.52L7.36992 19.74C7.27992 19.69 7.18992 19.66 7.08992 19.66C6.98992 19.66 6.88992 19.69 6.79992 19.74L6.05992 20.18V3.57998L17.9499 3.52998H17.9399Z"
          fill="#555555"
        />
        <path
          d="M12.22 11.26C13.51 11.26 14.15 10.39 14.15 10.39L13.54 9.74999C13.54 9.74999 13.04 10.36 12.21 10.36C11.19 10.36 10.81 9.44999 10.81 9.44999H12.29V8.76999H10.61C10.61 8.76999 10.59 8.65999 10.59 8.49999C10.59 8.33999 10.61 8.22999 10.61 8.22999H12.29V7.54999H10.81C10.81 7.54999 11.19 6.63999 12.21 6.63999C13.04 6.63999 13.5 7.24999 13.5 7.24999L14.11 6.60999C14.11 6.60999 13.5 5.73999 12.21 5.73999C10.31 5.73999 9.74995 7.55999 9.74995 7.55999H8.94995V8.23999H9.61995C9.61995 8.23999 9.59995 8.34999 9.59995 8.50999C9.59995 8.66999 9.61995 8.77999 9.61995 8.77999H8.94995V9.45999H9.74995C9.74995 9.45999 10.32 11.28 12.21 11.28L12.22 11.26Z"
          fill="#555555"
        />
      </g>
      <defs>
        <clipPath id="clip0_810_18956">
          <rect
            width="14.16"
            height="19.52"
            fill="white"
            transform="translate(4.91992 2.23999)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default BilledIcon;
