import React from 'react';

import NotAllowed from '@/views/NotAllowed';
interface ScopedRouteProps {
  component?: any;
  userScopes: string[];
  requiredScopes?: string[];
}

const ScopedRoute = ({
  requiredScopes,
  userScopes,
  component,
}: ScopedRouteProps) => {
  if (requiredScopes?.some((val) => userScopes.includes(val))) {
    return component;
  }

  return <NotAllowed />;
};

export default ScopedRoute;
