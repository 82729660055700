import React, { useCallback, useState } from 'react';

import { useParams } from 'react-router-dom';

import useUploadLabelImage from '../hooks/useUploadLabelImage';
import CancelSubmitFooterModal from '@/components/CancelSubmitFooterModal';
import CustomModal from '@/components/CustomModal';
import DragAndDrop from '@/components/DragAndDrop';
import useError from '@/hooks/useError';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

interface ImportLabelImageProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  invalidate: () => void;
  versionHash: string;
}

const ImportLabelImage = ({
  visible,
  setVisible,
  invalidate,
  versionHash,
}: ImportLabelImageProps) => {
  const { id } = useParams<{ id: string }>();
  const addError = useError();
  const addSuccess = useToasts((state) => state.addItem);
  const uploadLabelImage = useUploadLabelImage();
  const [file, setFile] = useState<File | null>(null);

  const handleSubmit = useCallback(async () => {
    try {
      await uploadLabelImage(id, versionHash, file);
      invalidate();
      setVisible(false);
      addSuccess(
        { msg: 'Label successfully uploaded', type: MessageType.Success },
        'root',
        {}
      );
    } catch (error) {
      addError(error);
    }
  }, [
    addError,
    addSuccess,
    invalidate,
    uploadLabelImage,
    id,
    file,
    versionHash,
    setVisible,
  ]);

  const isInvalid = !file;
  const loading = false;
  const closeModal = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const handleDropImage = useCallback(async (droppedFiles) => {
    droppedFiles[0] && setFile(droppedFiles[0]);
  }, []);

  const modalFooterButtons = CancelSubmitFooterModal(
    isInvalid,
    handleSubmit,
    closeModal,
    loading
  );

  return (
    <CustomModal
      title="Import Label Image"
      open={visible}
      destroyOnClose={true}
      footer={modalFooterButtons}
      onCancel={closeModal}
    >
      <DragAndDrop
        onDrop={handleDropImage}
        text="Drop a PNG file to replace the E-Label of this shipment"
        multiple={false}
        accept={{ 'image/png': [] }}
        height="7rem"
        fileDropped={!!file}
        removeFiles={() => setFile(null)}
      />
    </CustomModal>
  );
};

export default ImportLabelImage;
