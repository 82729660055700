import React from 'react';

import { Navigate } from 'react-router-dom';
import styled from 'styled-components';

import H2 from '../text/H2';
import { appRoutes } from '@/configs/appRoutes';
import { useAuth0 } from '@/shared/auth0';
import Button from '@/shared/components/Button';

const CardBody = styled.div`
  background: white;
  border-radius: 12px;
  padding: 4rem;
  width: 100%;
  max-width: 540px;
  position: relative;
  @media all and (max-width: 992px) {
    width: 100%;
  }
`;

const Headline = styled(H2)`
  margin-bottom: 25px;
`;

const SubHeader = styled.div`
  font-size: 16px;
  margin-bottom: 15px;
`;

const StyledButton = styled(Button)`
  font-family: TTNormsPro-Regular;
`;

const LoginCard = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const handleClick = async () => loginWithRedirect();

  return isAuthenticated ? (
    <Navigate to={appRoutes.ROOT} />
  ) : (
    <CardBody>
      <Headline>Welcome to the LivingPackets Admin Panel</Headline>
      <SubHeader>
        If you are an administrator, please go ahead and sign in:
      </SubHeader>
      <StyledButton onClick={handleClick} data-testid="toLogin">
        Sign in
      </StyledButton>
    </CardBody>
  );
};

export default LoginCard;
