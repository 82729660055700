import React, { useMemo } from 'react';

import { Col, Row } from 'antd';
import toSafeInteger from 'lodash/toSafeInteger';
import styled from 'styled-components';

import OfferTypeSelector from '../OfferTypeSelector';
import IntegerInput, { nonIntegerError } from '@/components/forms/IntegerInput';
import TextInput from '@/components/forms/TextInput';
import dates from '@/helpers/dates';
import useInputFocus from '@/hooks/useInputFocus';

const FlexRow = styled(Row)`
  display: flex !important;
  margin-top: 2rem;
`;

const StyledCol = styled(Col)`
  align-self: flex-end;
  margin-bottom: 4rem;
`;

const CreateOfferForm = ({ offer, setOffer }) => {
  const [ref] = useInputFocus({ initialFocus: true });

  const hasOfferWarning = useMemo(
    () =>
      offer.multiplierInCents &&
      !nonIntegerError(offer.multiplierInCents) &&
      toSafeInteger(offer.multiplierInCents) < 100,
    [offer.multiplierInCents]
  );

  const handleChangeText = (event) => {
    setOffer({ ...offer, [event.target.name]: event.target.value });
  };

  const handleOfferTypeChange = (option) => {
    setOffer({ ...offer, offerType: option.value });
  };

  return (
    <>
      <Row>
        <TextInput
          ref={ref}
          label="Label *"
          name="label"
          onChange={handleChangeText}
          value={offer.label}
        />
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <IntegerInput
            label="Duration in Months *"
            name="durationInMonth"
            onChange={handleChangeText}
            value={offer.durationInMonth}
          />
        </Col>
        <Col span={12}>
          <IntegerInput
            label="Multiplier in Percent *"
            name="multiplierInCents"
            onChange={handleChangeText}
            value={offer.multiplierInCents}
            hasOtherError={hasOfferWarning}
            otherErrorMsg={
              hasOfferWarning
                ? 'Warning: Offer has a multiplier below 100%!'
                : ''
            }
          />
        </Col>
      </Row>
      <Row>
        <OfferTypeSelector
          label="Offer Type"
          onChange={handleOfferTypeChange}
          offerType={offer.offerType}
        />
      </Row>
      <FlexRow gutter={24}>
        <Col span={6}>
          <IntegerInput
            label="Deactivation Payment Buffer (in seconds)"
            name="deactivationPaymentBufferInSec"
            value={offer.deactivationPaymentBufferInSec}
            onChange={handleChangeText}
          />
        </Col>
        <StyledCol span={18}>
          ...corresponds to{' '}
          {dates.parseSecondsDuration(offer.deactivationPaymentBufferInSec)}
        </StyledCol>
      </FlexRow>
    </>
  );
};
export default CreateOfferForm;
