import React, { useCallback, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';

import DeleteLpAccountModal from './DeleteLpAccountModal';
import { IModifiedLPAccountUser } from '../models/lpaccount';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';

const DeleteLpAccount = ({
  user,
  getUser,
}: {
  user: IModifiedLPAccountUser;
  getUser: any;
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleClick = useCallback(() => {
    setShowModal(true);
  }, []);

  return (
    <>
      <ScopedCta
        requiredScopes={[scopes.EDIT_LPACCOUNT]}
        component={PillButton}
        type="danger"
        onClick={handleClick}
        disabled={!user.id}
        icon={<DeleteOutlined />}
        data-testid="deleteAccBtn"
      >
        Delete LPAccount
      </ScopedCta>
      <DeleteLpAccountModal
        setShowModal={setShowModal}
        showModal={showModal}
        user={user}
        getUser={getUser}
      />
    </>
  );
};

export default DeleteLpAccount;
