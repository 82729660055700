import React from 'react';
const ChangelogIcon = (props) => (
  <svg viewBox="0 0 24 24" width="2.25rem" height="2.25rem" {...props}>
    <path
      d="M22,12c0-5.5-4.5-10-10-10C8.7,2,5.8,3.6,4,6V4.8C4,4.3,3.7,4,3.2,4S2.5,4.3,2.5,4.8v3c0,0.4,0.3,0.8,0.8,0.8h3
	C6.7,8.5,7,8.2,7,7.8C7,7.3,6.7,7,6.2,7H5.1C6.7,4.9,9.2,3.5,12,3.5c4.7,0,8.5,3.8,8.5,8.5c0,4.7-3.8,8.5-8.5,8.5
	c-4.7,0-8.5-3.8-8.5-8.5c0-0.4-0.3-0.7-0.7-0.7C2.3,11.3,2,11.6,2,12c0,5.5,4.5,10,10,10C17.5,22,22,17.5,22,12z M14.1,6.9L8,12.8
	l0,0c-0.3,0.3-0.4,0.6-0.4,1v2.2c0,0.4,0.3,0.7,0.7,0.7h2.2c0.4,0,0.7-0.1,1-0.4l6.1-5.9l0,0c0.4-0.4,0.4-0.9,0-1.3l-2.2-2.2
	C15.1,6.5,14.5,6.5,14.1,6.9z M9,15.3l0-1.5l4-3.8l1.5,1.5l-4,3.8l0,0L9,15.3z M15.5,10.4L14,8.9l0.8-0.8l1.5,1.5L15.5,10.4z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default ChangelogIcon;
