import useRefreshShops from './useRefreshShops';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useCreateShop: any = () => {
  const addError = useError();
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpVillage');
  const getShops = useRefreshShops();
  const successMsg = useToasts((state) => state.addItem);

  const createShop = async (shop, address) => {
    const { error } = await makeRequest({
      path: '/admin/shops',
      method: 'post',
      body: { ...shop, address: address },
    });
    if (error) {
      addError(error);

      return { success: false };
    }
    await getShops();
    successMsg(
      { msg: 'Shop was successfully added', type: MessageType.Success },
      'root',
      {}
    );

    return { success: true };
  };

  return [{ loading }, createShop];
};

export default useCreateShop;
