import {
  BoxContractOffer,
  ModifiedBoxContractOffer,
} from '../models/boxContract';
import datePostProcessor from '@/helpers/datePostProcessor';
import dates from '@/helpers/dates';

interface IGetBoxOffersResponse {
  count: number;
  total: number;
  items: BoxContractOffer[];
}

interface IProcessBoxOffers {
  count: number;
  total: number;
  items: ModifiedBoxContractOffer[];
}

const boxOfferPostProcessor = (
  offer: BoxContractOffer
): ModifiedBoxContractOffer => ({
  ...offer,
  parsedCreatedAt: offer.createdAt ? dates.parseDate(offer.createdAt) : '',
  parsedActiveAt: offer.activeAt ? dates.parseDate(offer.activeAt) : '',
  parsedDeactivatedAt: offer.deactivatedAt
    ? dates.parseDate(offer.deactivatedAt)
    : '',
  parsedUpdatedAt: offer.updatedAt ? dates.parseDate(offer.updatedAt) : '',
  boxOfferId: offer.id,
});

const boxOffersPostProcessor = (
  data: IGetBoxOffersResponse | undefined
): IProcessBoxOffers | {} => {
  if (!data?.items) {
    return { total: 0, count: 0, items: [] };
  }
  const processedItems = data.items.map((item) => boxOfferPostProcessor(item));

  return datePostProcessor({ ...data, items: processedItems });
};

export default boxOffersPostProcessor;
