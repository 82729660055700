import useRolesStore from './useRolesStore';
import datePostProcessor from '@/helpers/datePostProcessor';
import usePaginatedTableData from '@/hooks/usePaginatedTableData';

const useGetRoles = () => {
  const dispatch = useRolesStore((state) => state.dispatch);
  const order = useRolesStore((state) => state.order);
  const sortBy = useRolesStore((state) => state.sortBy);
  const offset = useRolesStore((state) => state.offset);
  const pageSize = useRolesStore((state) => state.pageSize);

  usePaginatedTableData({
    params: {
      path: '/admin/roles',
      method: 'get',
      reducer: datePostProcessor,
    },
    dispatch,
    queryParams: Object.assign({
      pageSize: pageSize,
      offset: offset,
      sortBy: sortBy,
      order: order,
    }),
    queryParamsOptions: { skipEmptyString: true },
    service: 'lpVillage',
  });
};

export default useGetRoles;
