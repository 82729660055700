import React, { useMemo } from 'react';

import {
  Box,
  ButtonV2 as Button,
  Checkbox,
  Grid,
  Select,
  Text,
  NewTextField as TextField,
  Stack,
} from '@lp/ds-next';
import { Field, useFormik } from 'formik';
import { isEqual } from 'lodash';

import { useCreatePartnerAddresses } from '../../api/createPartnerAddress';
import { useUpdatePartnerAddresses } from '../../api/updatePartnerAddress';
import { CreateAddressSchema } from '../../models/validationSchema';
import { AddressTypes } from '../../types/address';
import { PhoneNumber } from '@/components/forms/phoneNumber';
import { COUNTRY_CODES_JSON_PATH } from '@/config';
import dates from '@/helpers/dates';
import { IAddToPartnerAddress } from '@/models/address';
import useCountryCodes from '@/shared/hooks/useCountryCodes';

export const Address = ({
  partnerId,
  address,
  handleClose,
}: {
  partnerId: string;
  address: IAddToPartnerAddress;
  handleClose: (val: boolean) => void;
}) => {
  const [countryCodes, getCountryNameFromCode] = useCountryCodes({
    localCountryCodesPath: COUNTRY_CODES_JSON_PATH,
  });

  const countries = useMemo(
    () =>
      countryCodes?.data?.map((option) => ({
        label: getCountryNameFromCode(option.alpha2Code),
        value: option.alpha2Code,
      })),
    [countryCodes, getCountryNameFromCode]
  );

  const { mutate: updateAddressPartner, isPending: isUpdatePending } =
    useUpdatePartnerAddresses({
      partnerId,
    });
  const { mutate: addAdressPartner, isPending: isAddPending } =
    useCreatePartnerAddresses({ partnerId });

  const addressFormik = useFormik({
    enableReinitialize: true,
    initialValues: address,
    validationSchema: CreateAddressSchema,
    validate: (values) => {
      const errors: any = {};
      if (!values.addressTypes || values.addressTypes.length === 0) {
        errors.addressTypes = 'Please select at least one address type';
      }

      return errors;
    },
    onSubmit: async (values) => {
      if (values.hasOwnProperty('id') && values?.id !== '') {
        updateAddressPartner(
          { values },
          {
            onSuccess: () => {
              handleClose(false);
            },
          }
        );
      } else {
        addAdressPartner(
          { partnerId, values },
          {
            onSuccess: () => {
              handleClose(false);
            },
          }
        );
      }
    },
  });

  return (
    <>
      <Box sx={{ paddingBottom: '1rem' }}>
        <Text variant="titleXL" data-testid="addressTitle">
          {address.id ? 'Edit address' : 'Create address'}
        </Text>
      </Box>

      {address.id && (
        <Stack gap="3" alignItems="flex-end" paddingBottom="0.5rem">
          <Text variant="bodyTextS">
            Created At : {dates.parseDate(addressFormik.values.createdAt)}
          </Text>
          <Text variant="bodyTextS">
            Updated At: {dates.parseDate(addressFormik.values.updatedAt)}
          </Text>
        </Stack>
      )}

      <form onSubmit={addressFormik.handleSubmit}>
        <Grid container direction="column" gap="1rem">
          <Grid item mobile={12}>
            <TextField
              required
              onChange={addressFormik.handleChange}
              label="Identification name"
              placeholder="Identification name"
              name="company"
              value={addressFormik.values.company}
              error={
                addressFormik.touched.company && !!addressFormik.errors.company
              }
              helperText={addressFormik.errors.company as string}
              onBlur={addressFormik.handleBlur}
              data-testid="addressIdentificationName"
            />
          </Grid>
          <Grid item mobile={12}>
            <TextField
              required
              onChange={addressFormik.handleChange}
              label="Address"
              placeholder="Address"
              name="street"
              value={addressFormik.values.street}
              error={
                addressFormik.touched.street && !!addressFormik.errors.street
              }
              helperText={addressFormik.errors.street as string}
              onBlur={addressFormik.handleBlur}
              data-testid="addressLabel"
            />
          </Grid>

          <Grid item mobile={12} container direction="row" spacing={1}>
            <Grid item mobile={12} tablet={6}>
              <TextField
                onChange={addressFormik.handleChange}
                label="Additional address"
                placeholder="Additional address"
                value={addressFormik.values.street2}
                name="street2"
                error={!!addressFormik.errors.street2}
                helperText={addressFormik.errors.street2}
              />
            </Grid>
            <Grid item mobile={12} tablet={6}>
              <TextField
                onChange={addressFormik.handleChange}
                label="App, suite, building"
                placeholder="App, suite, building"
                name="building"
                value={addressFormik.values.building}
                error={!!addressFormik.errors.building}
                helperText={addressFormik.errors.building}
              />
            </Grid>
          </Grid>
          <Grid item mobile={12} container direction="row" spacing={1}>
            <Grid item mobile={12} tablet={2}>
              <TextField
                required
                onChange={addressFormik.handleChange}
                label="Zipcode"
                placeholder="Zipcode"
                value={addressFormik.values.postalCode}
                name="postalCode"
                error={
                  addressFormik.touched.postalCode &&
                  !!addressFormik.errors.postalCode
                }
                helperText={addressFormik.errors.postalCode}
                onBlur={addressFormik.handleBlur}
                data-testid="addressZipcode"
              />
            </Grid>
            <Grid item mobile={12} tablet={4}>
              <TextField
                required
                onChange={addressFormik.handleChange}
                label="City / Town"
                placeholder="City / Town"
                name="city"
                value={addressFormik.values.city}
                error={
                  addressFormik.touched.city && !!addressFormik.errors.city
                }
                helperText={addressFormik.errors.city}
                onBlur={addressFormik.handleBlur}
                data-testid="addressCityTown"
              />
            </Grid>
            <Grid item mobile={12} tablet={6}>
              <Select
                label="Country"
                options={countries}
                defaultValue={addressFormik.values.countryCode}
                {...{
                  onChange: addressFormik.handleChange,
                  name: 'countryCode',
                  id: 'country_code',
                  required: true,
                  helperText: addressFormik.errors.countryCode,
                }}
                error={
                  addressFormik.touched.countryCode &&
                  !!addressFormik.errors.countryCode
                }
                onBlur={addressFormik.handleBlur}
                value={addressFormik.values.countryCode}
                data-testid="addressCountryCode"
              />
            </Grid>
          </Grid>
          <Grid item mobile={12} container direction="row" spacing={1}>
            <Grid item mobile={12}>
              <PhoneNumber
                label="Phone number"
                defaultCountryCode="FR"
                name="phoneNumber"
                countrySelectorMaxHeight="11.25rem"
                error={addressFormik.errors.hasOwnProperty('phoneNumber')}
                helperText={addressFormik.errors.phoneNumber}
                value={addressFormik.values.phoneNumber}
                onChange={(value) => {
                  addressFormik.setValues({
                    ...addressFormik.values,
                    phoneNumber: value,
                  });
                }}
                data-testid="addressPhoneNumber"
              />
            </Grid>
            <Grid item mobile={12}>
              <TextField
                onChange={addressFormik.handleChange}
                label="Email"
                placeholder="Email"
                name="email"
                value={addressFormik.values.email}
                error={
                  addressFormik.touched.email && !!addressFormik.errors.email
                }
                helperText={addressFormik.errors.email}
                onBlur={addressFormik.handleBlur}
                data-testid="addressEmail"
              />
            </Grid>
          </Grid>

          <Box display="flex" flexDirection="column">
            <Text variant="titleL">Address type * </Text>
            <Text variant="bodyTextXS" color="#FF3838">
              {addressFormik.touched.addressTypes &&
              addressFormik.errors.addressTypes
                ? addressFormik.errors.addressTypes
                : null}
            </Text>
            <Stack direction="row">
              {Object.keys(AddressTypes).map((key) => (
                <Field
                  component={Checkbox}
                  name="addressTypes"
                  id="addressTypes"
                  value={AddressTypes[key]}
                  checked={
                    addressFormik.values.addressTypes
                      ? addressFormik.values.addressTypes.includes(
                          AddressTypes[key]
                        )
                      : false
                  }
                  key={key}
                  label={key}
                  onChange={addressFormik.handleChange}
                  data-testid={`addressTypes_${key}`}
                />
              ))}
            </Stack>
          </Box>

          <Stack justifyContent="flex-end" gap="1rem" direction="row">
            <Button variant="tertiary" onClick={() => handleClose(false)}>
              Cancel
            </Button>
            <Button
              disabled={
                !addressFormik.isValid ||
                addressFormik.isSubmitting ||
                isEqual(address, addressFormik.values) ||
                isUpdatePending ||
                isAddPending
              }
              onClick={addressFormik.submitForm}
              data-testid="addressSubmitButton"
            >
              Submit
            </Button>
          </Stack>
        </Grid>
      </form>
    </>
  );
};
