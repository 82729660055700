import React, { useCallback, useMemo, useState } from 'react';

import useCreateTos from '../hooks/useCreateTos';
import CancelSubmitFooterModal from '@/components/CancelSubmitFooterModal';
import CustomModal from '@/components/CustomModal';
import DateTimePicker from '@/components/forms/DateTimePicker';

interface IEditActiveAtModal {
  visible: boolean;
  closeModal: () => void;
  id: string;
}

const EditActiveAtModal = ({ id, visible, closeModal }: IEditActiveAtModal) => {
  const [activeAt, setActiveAt] = useState<number | null>(null);
  const { loadingEdit, editTos } = useCreateTos();

  const isInvalid = useMemo(() => !activeAt, [activeAt]);

  const handleSubmit = useCallback(async () => {
    const { success } = await editTos(id, null, activeAt);
    success && closeModal();
  }, [activeAt, id, editTos, closeModal]);

  const modalFooterButtons = CancelSubmitFooterModal(
    isInvalid,
    handleSubmit,
    closeModal,
    loadingEdit
  );

  return (
    <CustomModal
      title="Edit Terms of Service"
      open={visible}
      destroyOnClose={true}
      footer={modalFooterButtons}
      onCancel={closeModal}
    >
      <DateTimePicker
        setUnixTimeStamp={setActiveAt}
        useDefaultTime={false}
        withTime={false}
        disabledPastDate={true}
      />
    </CustomModal>
  );
};

export default EditActiveAtModal;
