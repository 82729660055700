type TState = any;

export type TTelemetryEvent = {
  consumedAt: string;
  id: string;
  serviceLevel: number;
  shipmentId: string;
  state: TState;
  thingName: string;
  timestamp: number;
  type: string;
};

export const EVENT_TYPES = [
  'ADDONS_ALERT_SHOCK',
  'ALERT_BOX_VIOLATION',
  'ALERT_TEMPERATURE',
  'ALERT_HUMIDITY',
  'ALERT_PRESSURE',
  'ALERT_SHOCK',
  'BATTERY_INFO',
  'BATTERY_STATE_LOW',
  'BOX_CLOSED',
  'BOX_LOCKED',
  'BOX_LOCK_STATE',
  'BOX_UNLOCKED',
  'BOX_OPENED',
  'BOX_REGISTERED',
  'BOX_STARTUP',
  'BOX_INFO',
  'CAMERA',
  'CONNECTION_BLE_ESTABLISHED',
  'CONNECTION_BLE_LOST',
  'CONTRACT_ACTIVATED',
  'CONTRACT_DEACTIVATED',
  'EXTERNAL_TEMPERATURE_EXCEEDED',
  'EXTERNAL_HUMIDITY_EXCEEDED',
  'FAILURE',
  'GPS_ACQUIRE',
  'GEOLOCATION_INFO',
  'INTERNAL_TEMPERATURE_EXCEEDED',
  'INTERNAL_HUMIDITY_EXCEEDED',
  'LABEL_WRITTEN',
  'PRESSURE_EXCEEDED',
  'SCALE_VALUE_CHANGED',
  'SCREEN_CHANGED',
  'SENSOR_ACQUIRE',
  'SHOCK_EXCEEDED',
  'TRANSFER_OF_HOLDER',
  'WEIGHT_REQUESTED',
];

export type TModifiedEvent = {
  parsedTimestamp: string;
} & TTelemetryEvent;
