import React from 'react';

import SVG from '@/shared/components/Svg';

const defaults = {
  width: '1.4375rem',
  height: '1.4375rem',
  stroke: '#FFFFFF',
  viewBox: '0 0 23 23',
};

const CheckIcon = ({
  width = defaults.width,
  height = defaults.height,
  stroke = defaults.stroke,
  viewBox = defaults.viewBox,
  ...props
}) => (
  <SVG width={width} height={height} viewBox={viewBox} {...props}>
    <g transform="translate(2.490234 2.48999)">
      <path
        d="M11.333 0L3.777 7.556L0 3.778"
        transform="translate(5.228 4.444)"
        fill="none"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 9C18 13.971 13.971 18 9 18C4.029 18 0 13.971 0 9C0 4.029 4.029 0 9 0C10.453 0 11.821 0.353 13.036 0.964"
        fill="none"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </SVG>
);

export default CheckIcon;
