export interface IApiKey {
  createdAt: number;
  createdBy: string;
  description: string;
  expiresAfter: number;
  id: string;
  partnerId: string;
  scopes: string[];
  updatedAt: number;
}

export interface IFormattedApiKey extends IApiKey {
  apiKeyId: string;
}

export interface ICreateApiKey
  extends Pick<IApiKey, 'description' | 'scopes'> {}

export const INITIAL_CreateApiKey = {
  description: '',
  scopes: [],
};
