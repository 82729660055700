import { Reducer, useReducer } from 'react';

export type ServerTablePagination = {
  pageSize: number;
  offset: number;
  total: number;
  count: number;
  sortBy: string;
  order: 'DESC' | 'ASC';
};

export type ServerTableState<TRowData> = {
  pagination: ServerTablePagination;
  rowData: TRowData;
};

type TActionsTypes = 'updatePagination' | 'updateRowDataFromResponse';

export type ServerTableActions = {
  type: TActionsTypes | string;
  args: any;
};

export const INITIAL_Pagination: ServerTablePagination = {
  pageSize: 10,
  offset: 0,
  total: 0,
  count: 0,
  sortBy: '',
  order: 'DESC',
};

const useServerSideTable = <
  TRowData,
  TStoreState extends ServerTableState<TRowData>,
  TActions extends ServerTableActions,
>(
  red: Reducer<TStoreState, TActions>,
  init: TStoreState
) => useReducer(red, init);

export default useServerSideTable;
