import { create } from 'zustand';

import { IQualityCheckType } from '../models/qualityCheck';
import {
  getInitialState,
  IPaginatedStore,
  IReducer,
  reducer,
} from '@/helpers/paginatedStoreHelpers';

const INITIAL_STATE = getInitialState<IQualityCheckType, {}>({
  sortBy: 'updatedAt',
  order: 'DESC',
});

const useQualityCheckTypeStore = create<IPaginatedStore<IQualityCheckType, {}>>(
  (set) => ({
    ...INITIAL_STATE,
    dispatch: (input: IReducer<IQualityCheckType, {}>) =>
      set((state) => reducer(state, input, INITIAL_STATE)),
  })
);

export default useQualityCheckTypeStore;
