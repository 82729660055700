import { IShipmentMap } from '../components/map/IShipmentMap';
import { IBaseAddress } from '@/models/address';

interface IPos {
  lat: number;
  lng: number;
}

export const getPositions = ({ trackingData, shipment }: IShipmentMap) => {
  const boxLat = trackingData?.lastKnownLocation?.latitude;
  const boxLong = trackingData?.lastKnownLocation?.longitude;
  const senderLat = shipment.sender.address.position?.latitude;
  const senderLong = shipment.sender.address.position?.longitude;
  const recipientLat = shipment.recipient.address.position?.latitude;
  const recipientLong = shipment.recipient.address.position?.longitude;
  let boxPos, senderPos, recipientPos;
  if (boxLat && boxLong) {
    boxPos = { lat: boxLat, lng: boxLong };
  }
  if (senderLat && senderLong) {
    senderPos = { lat: senderLat, lng: senderLong };
  }
  if (recipientLat && recipientLong) {
    recipientPos = { lat: recipientLat, lng: recipientLong };
  }

  return { boxPos, senderPos, recipientPos };
};

export const getBounds = (positions: (IPos | undefined)[]) => {
  const normalisedPos = positions.filter(
    (pos) => pos?.lat && pos?.lng
  ) as IPos[];
  const north = Math.max.apply(
    Math,
    normalisedPos.map((o) => o.lat)
  );
  const east = Math.max.apply(
    Math,
    normalisedPos.map((o) => o.lng)
  );
  const south = Math.min.apply(
    Math,
    normalisedPos.map((o) => o.lat)
  );
  const west = Math.min.apply(
    Math,
    normalisedPos.map((o) => o.lng)
  );

  return {
    north,
    south,
    east,
    west,
  };
};

export const reduceAddressLocation = (
  address: IBaseAddress,
  getCountryNameFromCode?: (code: string) => string
) => {
  const { street, street2, houseNumber, postalCode, city, countryCode } =
    address;

  return `${street} ${
    street2 ? `${street2} ` : ''
  }${houseNumber} ${postalCode} ${city}${
    getCountryNameFromCode ? `, ${getCountryNameFromCode(countryCode)}` : ''
  }`;
};
