import React from 'react';

import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import useLPPartner, { PartnerData } from '../api/useLPPartner';
import LPPartnerDetailsContent from '../components/LPPartnerDetailsContent';
import PageTitle from '@/components/layout/PageTitle';

export const CurrentPartnerContext = React.createContext<PartnerData>(
  {} as PartnerData
);

const LPPartnerDetails = () => {
  const { id } = useParams<{ id: string }>();

  const partnerData = useLPPartner(id!);

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={11}>
        <PageTitle title="LP PARTNER DETAILS" />
      </Grid>
      <Grid item xs={11}>
        <CurrentPartnerContext.Provider value={partnerData}>
          <LPPartnerDetailsContent />
        </CurrentPartnerContext.Provider>
      </Grid>
    </Grid>
  );
};

export default LPPartnerDetails;
