type TUserAssignment = {
  email?: string;
  id: string;
};

export type TBoxNote = {
  boxId: string;
  createdAt: number;
  createdBy: TUserAssignment;
  updatedBy?: TUserAssignment;
  id: string;
  note: string;
  updatedAt: number;
};

export type TModifiedBoxNote = {
  parsedCreatedAt: string;
  parsedUpdatedAt: string;
  isDirty: boolean;
} & TBoxNote;

export const INITIAL_ModifiedBoxNote: TModifiedBoxNote = {
  boxId: '',
  createdAt: 0,
  createdBy: {
    email: undefined,
    id: '',
  },
  id: '',
  note: '',
  parsedCreatedAt: '',
  parsedUpdatedAt: '',
  updatedAt: 0,
  isDirty: false,
};
