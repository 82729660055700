import { create } from 'zustand';

import { ModifiedBoxContractOffer } from '../models/boxContract';
import {
  getInitialState,
  IPaginatedStore,
  IReducer,
  reducer,
} from '@/helpers/paginatedStoreHelpers';

type TFilters = {
  onlyActive?: boolean;
};

type TAdditionalState = {
  editActivationDateId: string | null;
  editDeactivationDateId: string | null;
} | null;

const INITIAL_STATE = getInitialState<
  ModifiedBoxContractOffer,
  TFilters,
  TAdditionalState
>({
  additionalState: {
    editActivationDateId: null,
    editDeactivationDateId: null,
  },
  sortBy: 'updatedAt',
  order: 'DESC',
  filters: {
    onlyActive: false,
  },
});

const useBoxOffersStore = create<
  IPaginatedStore<ModifiedBoxContractOffer, TFilters, TAdditionalState>
>((set) => ({
  ...INITIAL_STATE,
  dispatch: (
    input: IReducer<ModifiedBoxContractOffer, TFilters, TAdditionalState>
  ) => set((state) => reducer(state, input, INITIAL_STATE)),
}));

export default useBoxOffersStore;
