import React, { useEffect, useState } from 'react';

import useGetArticleCodes from '../../features/boxes/hooks/useGetArticleCodes';
import { IArticle } from '../../features/boxes/models/box';
export const ArticlesContext = React.createContext({});

const ArticlesProvider = ({ children }) => {
  const [data, setData] = useState<Array<IArticle>>([]);
  const { articles } = useGetArticleCodes();

  useEffect(() => {
    setData(articles);
  }, [articles]);

  const { Provider } = ArticlesContext;

  return <Provider value={data}>{children}</Provider>;
};

export default ArticlesProvider;
