import React, { useCallback, useEffect, useState } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Button as AntdButton, Col, Row, Tooltip } from 'antd';
import moment from 'moment';
import { Navigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import EditActiveAtModal from './EditActiveAtModal';
import EditPdfModal from './EditPdfModal';
import { SupportedLangs } from './ToSFileUploads';
import useGetTos from '../hooks/useGetTos';
import { IModifiedTos, INITIAL_STATE_TOS } from '../models/tos';
import TextInput from '@/components/forms/TextInput';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';

const getLocationLanguage = (lang: string, location: string) => {
  if (!location) {
    return '';
  }
  const url = new URL(location);

  return `https://${url.hostname}/${lang}${url.pathname.substr(1)}`;
};

const StyledRow = styled(Row)`
  align-items: center;
`;

const StyledLabel = styled.div`
  margin-bottom: 0.65rem;
`;

const TosForm = () => {
  const { id } = useParams<{ id: string }>();
  const { getSingleTos } = useGetTos();
  const [tos, setTos] = useState<IModifiedTos>(INITIAL_STATE_TOS);
  const [visibleActiveAt, setVisibleActiveAt] = useState<boolean>(false);
  const [visiblePdf, setVisiblePdf] = useState<boolean>(false);
  const [tosNotFound, setTosNotFound] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      getSingleTos(id).then(({ success, data }) => {
        if (success && data) {
          setTos(data);
        }

        if (!success) {
          setTosNotFound(true);
        }
      });
    }
  }, [id, getSingleTos]);

  const handleEdit = useCallback((pdf: boolean) => {
    setVisiblePdf(pdf);
    setVisibleActiveAt(!pdf);
  }, []);

  const handleCloseModal = useCallback(async () => {
    setVisibleActiveAt(false);
    setVisiblePdf(false);
    if (id) {
      getSingleTos(id).then(({ success, data }) => {
        if (success && data) {
          setTos(data);
        }
      });
    }
  }, [getSingleTos, id]);

  if (tosNotFound) {
    return <Navigate to="/404" />;
  }

  return (
    <div>
      <StyledRow gutter={24}>
        <Col span={12}>
          <TextInput label="ToS ID" name="id" disabled value={tos.id} />
        </Col>
        <Col span={10}>
          <TextInput
            label="Active At"
            name="activeAt"
            disabled
            value={tos.parsedActiveAt}
          />
        </Col>
        <Col span={2}>
          <Tooltip placement="topRight" title="Edit Active at">
            <ScopedCta
              component={AntdButton}
              type="primary"
              icon={<EditOutlined />}
              onClick={() => handleEdit(false)}
              requiredScopes={[scopes.MANAGE_TOS]}
              disabled={tos.activeAt < moment().unix()}
            />
          </Tooltip>
        </Col>
      </StyledRow>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            label="Created At"
            name="createdAt"
            disabled
            value={tos.parsedCreatedAt}
          />
        </Col>
        <Col span={12}>
          <TextInput
            label="Updated At"
            name="updatedAt"
            disabled
            value={tos.parsedUpdatedAt}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <TextInput
            label="Service Type"
            name="serviceType"
            disabled
            value={tos.serviceType}
          />
        </Col>
      </Row>
      <Row>
        <Col span={15}>
          <StyledLabel>ToS Locations</StyledLabel>
          {SupportedLangs.map((lang) => {
            const location = getLocationLanguage(lang, tos.tosLocation);

            return (
              <Row key={lang}>
                <Col>{lang.toUpperCase()}:&nbsp;&nbsp;</Col>
                <Col>
                  <a href={location} target="_blank" rel="noreferrer">
                    {location.substr(0, 80)}...
                  </a>
                </Col>
              </Row>
            );
          })}
        </Col>
        <Col span={2}>
          <Tooltip placement="topRight" title="Edit PDF">
            <ScopedCta
              component={AntdButton}
              type="primary"
              icon={<EditOutlined />}
              onClick={() => handleEdit(true)}
              requiredScopes={[scopes.MANAGE_TOS]}
            />
          </Tooltip>
        </Col>
      </Row>
      <EditPdfModal
        visible={visiblePdf}
        closeModal={handleCloseModal}
        id={tos.id}
      />
      <EditActiveAtModal
        visible={visibleActiveAt}
        closeModal={handleCloseModal}
        id={tos.id}
      />
    </div>
  );
};

export default TosForm;
