import React from 'react';

function DollarCrossedIcon(props) {
  return (
    <svg
      cursor="pointer"
      width="2em"
      height="2em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="#ff4d4f">
        <path d="m10.86 9.41h-.07v-2.94h.07c.66 0 1.22.44 1.4 1.08.05.18.16.33.32.42.17.1.36.12.53.07.18-.05.33-.16.42-.33.09-.16.12-.35.07-.53-.33-1.23-1.46-2.1-2.75-2.1h-.07v-1.58c0-.38-.31-.69-.69-.69s-.69.31-.69.69v1.58h-.07c-.75 0-1.48.3-2.01.83-.54.54-.83 1.24-.83 2.01 0 1.58 1.28 2.86 2.86 2.86h.07v2.92h-.07c-.65 0-1.22-.43-1.4-1.04-.08-.33-.36-.56-.69-.56-.38 0-.69.31-.69.69 0 .07 0 .12.02.2.35 1.25 1.46 2.09 2.75 2.09h.07v1.58c0 .38.31.69.69.69s.69-.31.69-.69v-1.58h.07c1.58 0 2.86-1.28 2.86-2.86 0-.75-.3-1.48-.83-2.01-.56-.52-1.29-.81-2.05-.81zm1.44 2.84c0 .39-.14.76-.41 1.04s-.64.43-1.03.43h-.07v-2.94h.07c.39 0 .74.15 1.02.43.27.27.41.62.41 1.03zm-4-5.32c.27-.28.65-.43 1.02-.43h.07v2.92h-.05c-.8 0-1.46-.65-1.46-1.46 0-.39.15-.76.41-1.02z" />
        <path d="m10.1 0c-5.57 0-10.1 4.53-10.1 10.1s4.53 10.1 10.1 10.1 10.1-4.53 10.1-10.1-4.53-10.1-10.1-10.1zm5.21 14.3c-.26-.26-.72-.26-.98 0-.27.27-.27.71 0 .98l1.42 1.42c-1.6 1.35-3.6 2.1-5.64 2.1-4.8 0-8.7-3.9-8.7-8.7 0-2.07.74-4.07 2.09-5.64l1.42 1.42c.13.13.3.2.49.2.16 0 .34-.07.49-.2.13-.13.2-.31.2-.49s-.07-.36-.2-.49l-1.42-1.42c1.6-1.35 3.6-2.1 5.64-2.1 4.8 0 8.7 3.9 8.7 8.7 0 2.07-.74 4.07-2.09 5.64z" />
      </g>
    </svg>
  );
}

export default DollarCrossedIcon;
