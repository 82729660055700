import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useSearchPartner = () => {
  const addError = useError();
  const [, makeRequest] = useImperativeRequestWrapper('lpVillage');

  const getSinglePartner = async (searchName) => {
    const queryString = new URLSearchParams({
      name: `${searchName}`,
      pageSize: '2',
    }).toString();
    const { error, data } = await makeRequest({
      path: `/admin/partners?${queryString}`,
      method: 'get',
    });
    if (error || (data?.items || []).length !== 1) {
      error && addError(error);

      return { success: false, size: (data?.items || []).length };
    }

    return { success: true, partner: data.items[0] };
  };

  const getMultiplePartner = async (searchName) => {
    const queryString = new URLSearchParams({
      name: `${searchName}`,
      pageSize: '15',
    }).toString();

    const { error, data } = await makeRequest({
      path: `/admin/partners?${queryString}`,
      method: 'get',
    });
    if (error || (data?.items || []).length === 0) {
      error && addError(error);

      return { success: false, size: (data?.items || []).length };
    }

    return { success: true, partners: data.items };
  };

  return { getSinglePartner, getMultiplePartner };
};

export default useSearchPartner;
