import React from 'react';

import SVG from '@/shared/components/Svg';
const defaults = {
  width: '1.8125rem',
  height: '1.8125rem',
  stroke: '#FFFFFF',
  viewBox: '0 0 29 29',
};

const AlertIcon = ({
  width = defaults.width,
  height = defaults.height,
  stroke = defaults.stroke,
  viewBox = defaults.viewBox,
  ...props
}) => (
  <SVG width={width} height={height} viewBox={viewBox} {...props}>
    <g id="Group" transform="translate(2.490234 2.48999)">
      <g id="Group">
        <path
          d="M0 0L24 0L24 24L0 24L0 0Z"
          id="Path"
          fill="none"
          stroke="none"
        />
        <path
          d="M9 0L9 0C13.971 0 18 4.029 18 9L18 9C18 13.971 13.971 18 9 18L9 18C4.029 18 0 13.971 0 9L0 9C0 4.029 4.029 0 9 0Z"
          transform="translate(3 3)"
          id="Path"
          fill="none"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.5 5L0.5 0"
          transform="translate(11.5 7.5)"
          id="Path"
          fill="none"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.499003 0.25C0.361003 0.25 0.249003 0.362 0.250003 0.5C0.250003 0.638 0.362003 0.75 0.500003 0.75C0.638003 0.75 0.750003 0.638 0.750003 0.5C0.750003 0.362 0.638003 0.25 0.499003 0.25"
          transform="translate(11.5 15.75)"
          id="Path"
          fill="none"
          stroke={stroke}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </SVG>
);

export default AlertIcon;
