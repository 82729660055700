import { useCallback } from 'react';

import { PaginatedResponse } from './useGetBoxConfigHistory';
import useGetBoxConfigHistory, {
  GetBoxConfigHistoryParams,
} from '../hooks/useGetBoxConfigHistory';
import { BoxConfigHistoryItem } from '../models/boxConfig';
import { UseFetchResource, useFetchResource } from '@/hooks/useFetchResource';

const useBoxConfigHistory = (
  boxId: string,
  params?: GetBoxConfigHistoryParams
): UseFetchResource<PaginatedResponse<BoxConfigHistoryItem>> => {
  const getBoxConfigHistory = useGetBoxConfigHistory();

  const fetchFunction = useCallback(
    () => getBoxConfigHistory(boxId, params),
    [getBoxConfigHistory, boxId, params]
  );

  return useFetchResource(fetchFunction, {
    enabled: boxId !== '',
  });
};

export default useBoxConfigHistory;
