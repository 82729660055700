import React from 'react';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import H2 from '@/components/text/H2';
import Button from '@/shared/components/Button';

const Wrapper = styled.div`
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  text-align: center;
  margin-top: 2rem;
  font-family: TTNormsPro-Regular;
`;

const NotAllowed = () => {
  const history = useNavigate();

  return (
    <Wrapper>
      <H2>Sorry, you are not allowed to view this!</H2>
      <StyledButton onClick={() => history(-1)}>Go Back</StyledButton>
    </Wrapper>
  );
};

export default NotAllowed;
