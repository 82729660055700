import React, { useEffect, useState } from 'react';

import useGetTotals from '../hooks/useGetTotals';

export const AccountsTotalContext = React.createContext({});

const AccountsTotalProvider = ({ children }) => {
  const [data, setData] = useState<number>(0);
  const { getAccountsTotal } = useGetTotals();

  useEffect(() => {
    async function asyncWrapper() {
      const total = await getAccountsTotal();
      setData(total);
    }
    asyncWrapper();
  }, [getAccountsTotal]);

  const { Provider } = AccountsTotalContext;

  return <Provider value={data}>{children}</Provider>;
};

export default AccountsTotalProvider;
