import React from 'react';

const TransactionIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.397 16.1694C10.397 16.6694 10.257 17.1294 10.017 17.5294C9.42703 18.4994 8.21702 19.1094 6.81702 19.1094C5.41702 19.1094 4.207 18.4894 3.617 17.5294C3.377 17.1294 3.23706 16.6694 3.23706 16.1694C3.23706 15.3094 3.63704 14.5394 4.27704 13.9794C4.92704 13.4094 5.81701 13.0695 6.80701 13.0695C7.79701 13.0695 8.68704 13.4194 9.33704 13.9794C9.99704 14.5294 10.397 15.3094 10.397 16.1694Z"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3988 16.1694V18.9194C10.3988 20.6394 8.7988 21.8594 6.8288 21.8594C4.8588 21.8594 3.24878 20.6294 3.24878 18.9194V16.1694C3.24878 14.4494 4.8488 13.0594 6.8288 13.0594C7.8188 13.0594 8.70883 13.4094 9.35883 13.9694C9.99883 14.5294 10.3988 15.3094 10.3988 16.1694Z"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 15.0599C22 18.9299 18.87 22.0599 15 22.0599L16.05 20.3099"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 9.05994C2 5.18994 5.13 2.05994 9 2.05994L7.95001 3.80994"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 11.2266H18.25C20.3334 11.2266 21.1667 10.3933 21.1667 8.30998V5.80998C21.1667 3.72664 20.3334 2.89331 18.25 2.89331H15.75C13.6667 2.89331 12.8334 3.72664 12.8334 5.80998V8.30998C12.8334 10.3933 13.6667 11.2266 15.75 11.2266Z"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.5625 5.80994H15.4375"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 8.30994H15.4375"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TransactionIcon;
