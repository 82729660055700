import React from 'react';

import { useLocation, Navigate, Route, Routes } from 'react-router-dom';

import SAContributions from './views/SAContributions';
import SAOffers from './views/SAOffers';
import DashboardTabs from '@/components/layout/DashboardTabs';
import ScopedRoute from '@/components/ScopedRoute';
import { scopes } from '@/config';
import { appRoutes } from '@/configs/appRoutes';
import useAppState from '@/hooks/useAppState';

const CustomTabs = () => (
  <DashboardTabs
    title="SHARING ANGELS"
    tabs={[
      { name: 'Contributions', key: 'contributions' },
      { name: 'Offers', key: 'offers' },
    ]}
  />
);

const SharingAngelsRouter = () => {
  const { pathname } = useLocation();
  const userScopes = useAppState((state) => state.scopes);

  return (
    <>
      {pathname.split('/').length <= 3 && <CustomTabs />}
      {pathname === `${appRoutes.SHARING_ANGELS.ROOT}` && (
        <Navigate to={appRoutes.SHARING_ANGELS.CONTRIBUTIONS} />
      )}
      <Routes>
        <Route
          path={`${appRoutes.SHARING_ANGELS.CONTRIBUTIONS}/*`}
          element={
            <ScopedRoute
              component={<SAContributions />}
              userScopes={userScopes}
              requiredScopes={[scopes.VIEW_SHARING_ANGELS]}
            />
          }
        />

        <Route
          path={`${appRoutes.SHARING_ANGELS.OFFERS}/*`}
          element={<SAOffers />}
        />
      </Routes>
    </>
  );
};

export default SharingAngelsRouter;
