import React from 'react';

import { Route, Routes } from 'react-router-dom';

import LPRolesTable from './views/LPRolesTable';
import { appRoutes } from '@/configs/appRoutes';

const LPRoles = () => (
  <>
    <Routes>
      <Route path={appRoutes.ROOT} element={<LPRolesTable />} />;
    </Routes>
  </>
);

export default LPRoles;
