import React, { useCallback, useContext, useState } from 'react';

import useEditProduct from '../hooks/useEditProduct';
import CustomModal from '@/components/CustomModal';
import Dropdown from '@/components/forms/Dropdown';
import { ArticlesContext } from '@/shared/providers/articlesProvider';

const ProductTypesModal = ({
  box,
  defaultChoice,
  showModal,
  setShowModal,
  setProductDescriptionState,
  setArticleCode,
}) => {
  const { editProductType } = useEditProduct();
  const [currentChoice, setCurrentChoice] = useState<string>(defaultChoice);
  const articles = useContext(ArticlesContext);

  const handleValidation = useCallback(async () => {
    const data = await editProductType(box.lpUI, {
      articleCode: currentChoice,
    });
    if (data.success) {
      setProductDescriptionState(data.data.productDescription);
      setArticleCode(data.data.articleCode);
      setShowModal(false);
    }
  }, [
    currentChoice,
    box.lpUI,
    editProductType,
    setShowModal,
    setProductDescriptionState,
    setArticleCode,
  ]);

  return (
    <CustomModal
      title="Choose a product type"
      open={showModal}
      onCancel={() => setShowModal(false)}
      onOk={handleValidation}
    >
      <Dropdown
        options={articles}
        getOptionValue={(option) => option.code}
        getOptionLabel={(option) => option.description}
        onChange={(option) => setCurrentChoice(option.code)}
      />
    </CustomModal>
  );
};
export default ProductTypesModal;
