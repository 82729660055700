import { useCallback } from 'react';

import { useApiClient } from '@/helpers/ApiClients';
import { useFetchResource } from '@/hooks/useFetchResource';

const useGetBoxLastLocation = () => {
  const device = useApiClient('tracking');

  const getLastLocation = useCallback(
    (id: string, thingName: string) =>
      device
        .get(
          `/boxes/${id}/events?sortBy=timestamp&offset=0&&order=DESC&pageSize=1&thingName=${thingName}&hasCoordinate=true`
        )
        .then((response) => response.data),
    [device]
  );

  return getLastLocation;
};

const useBoxLastLocation = (id: string, thingName: string) => {
  const getBoxLastLocation = useGetBoxLastLocation();

  const fetchFunction = useCallback(
    () => getBoxLastLocation(id, thingName),
    [getBoxLastLocation, id, thingName]
  );

  return useFetchResource(fetchFunction);
};

export default useBoxLastLocation;
