import React, { useCallback, useMemo, useState } from 'react';

import styled from 'styled-components';

import Dropdown from '@/components/forms/Dropdown';
import { scopes } from '@/config';
import { SearchAccount } from '@/features/lp-account-users';
import { SearchPartner, SearchShop } from '@/features/lp-village';
import useAppState from '@/hooks/useAppState';

interface ICreateRelationForm {
  relation: { relationType: string; objectType: string; objectId: string };
  setRelation: (relation: {
    relationType: string;
    objectType: string;
    objectId: string;
  }) => void;
}

const Wrapper = styled.div`
  margin-top: 2rem;
`;

const RelationForm = ({ relation, setRelation }: ICreateRelationForm) => {
  const [partnerId, setPartnerId] = useState<string>('');
  const [shopId, setShopId] = useState<string>('');
  const [accountId, setAccountId] = useState<string>('');
  const userScopes = useAppState((state) => state.scopes);

  let RELATIONS = [
    { value: 'HOLDER', name: 'Holder' },
    { value: 'PIC', name: 'Partner in Charge' },
    { value: 'LOCATION', name: 'Location' },
  ];

  if (userScopes.includes(scopes.EDIT_PRODUCT_OWNER)) {
    RELATIONS = [
      ...RELATIONS,
      {
        value: 'OWNER',
        name: 'Owner',
      },
    ];
  }

  const onChangeRelationType = useCallback(
    (value) => {
      setRelation({
        relationType: value.value,
        objectType: '',
        objectId: '',
      });
      setAccountId('');
      setPartnerId('');
      setShopId('');
    },
    [setRelation]
  );

  const onChangeAccount = useCallback(
    (id: string) => {
      setAccountId(id);
      setRelation({
        ...relation,
        objectId: id,
        objectType: 'LP_ACCOUNT',
      });
    },
    [relation, setRelation]
  );

  const onChangePartner = useCallback(
    (id) => {
      setPartnerId(id);
      setRelation({
        ...relation,
        objectId: id,
        objectType: 'LP_PARTNER',
      });
    },
    [relation, setRelation]
  );

  const onChangeShop = useCallback(
    (id) => {
      setShopId(id);
      setRelation({
        ...relation,
        objectId: id,
        objectType: 'LP_SHOP',
      });
    },
    [relation, setRelation]
  );

  const SearchFields = useMemo(() => {
    switch (relation.relationType) {
      case 'HOLDER':
        return (
          <Wrapper>
            <SearchAccount
              accountId={accountId}
              setAccountId={onChangeAccount}
              disabled={!!relation.objectId}
            />
            Or LP Shop
            <SearchShop
              shopId={shopId}
              setShopId={onChangeShop}
              disabled={!!accountId}
            />
          </Wrapper>
        );
      case 'PIC':
        return (
          <Wrapper>
            <SearchPartner
              searchMultiple={true}
              partnerId={partnerId}
              setPartnerId={onChangePartner}
            />
          </Wrapper>
        );
      case 'LOCATION':
        return (
          <Wrapper>
            <SearchShop shopId={shopId} setShopId={onChangeShop} />
          </Wrapper>
        );
      case 'OWNER':
        return (
          <Wrapper>
            <SearchAccount
              accountId={accountId}
              setAccountId={onChangeAccount}
              disabled={!!relation.objectId}
            />
          </Wrapper>
        );
    }
  }, [
    relation.relationType,
    relation.objectId,
    accountId,
    onChangeAccount,
    shopId,
    onChangeShop,
    partnerId,
    onChangePartner,
  ]);

  return (
    <div>
      <Dropdown
        options={RELATIONS}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.name}
        mw="35rem"
        onChange={onChangeRelationType}
        isDisabled={false}
        value={RELATIONS.find((r) => r.value === relation.relationType)}
      />
      {SearchFields}
    </div>
  );
};

export default RelationForm;
