import React, { useCallback } from 'react';

import { Col, Divider, Row, Input } from 'antd';
import styled from 'styled-components';

import { ICreateQualityChecklist } from '../models/qualityCheck';

interface IAddShipmentId {
  setChecklist: any;
  checklist: ICreateQualityChecklist;
}

const Wrapper = styled.div`
  margin: 1.5rem 0;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

const AddShipmentId = ({ setChecklist, checklist }: IAddShipmentId) => {
  const handleChangeShipmentId = useCallback(
    (e) => {
      setChecklist({ ...checklist, shipmentId: e.target.value });
    },
    [setChecklist, checklist]
  );

  return (
    <Wrapper>
      <FormWrapper>
        <Row gutter={24}>
          <Col span={12}>
            <Input
              onChange={handleChangeShipmentId}
              placeholder="Shipment ID"
              name="shipmentId"
            />
          </Col>
        </Row>
      </FormWrapper>
      <Divider />
    </Wrapper>
  );
};

export default AddShipmentId;
