import React from 'react';
const BuyNRentIcon = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3094 2.84245C15.2224 1.39611 14.0214 0.25 12.5526 0.25H3.01181L2.84356 0.255038C1.39661 0.341949 0.25 1.54244 0.25 3.01062V16.5627L0.25504
    16.7309C0.341989 18.1772 1.543 19.3233 3.01181 19.3233H6.02468L6.12689 19.3165C6.49454 19.2666 6.7779 18.9516 6.7779 18.5705C6.7779 18.1546 6.44068 17.8176
    6.02468 17.8176H3.01181L2.88345 17.8111C2.25043 17.7468 1.75644 17.2124 1.75644 16.5627V3.01062L1.76292 2.88232C1.82721 2.24956 2.36182 1.75579 3.01181
    1.75579H12.5526L12.6809 1.76227C13.314 1.82653 13.808 2.36091 13.808 3.01062V8.53185L13.8148 8.63401C13.8647 9.0015 14.1798 9.28474 14.5612 9.28474C14.9772
    9.28474 15.3144 8.94766 15.3144 8.53185V3.01062L15.3094 2.84245Z"
      fill="#0CA86D"
    />
    <path
      d="M14.059 13.8021C14.475 13.8021 14.8122 14.1392 14.8122 14.555C14.8122 14.9362 14.5289 15.2512 14.1612
    15.301L14.059 15.3079H13.0547C12.6387 15.3079 12.3015 14.9708 12.3015 14.555C12.3015 14.1738 12.5849 13.8588 12.9525
    13.809L13.0547 13.8021H14.059Z"
      fill="#0CA86D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0463 11.3678H16.0678C16.9969 11.3678 17.75 12.1206 17.75
    13.0492V18.0685C17.75 18.9972 16.9969 19.75 16.0678 19.75H11.0463C10.1173 19.75 9.36415 18.9972 9.36415 18.0685V13.0492C9.36415
    12.1206 10.1173 11.3678 11.0463 11.3678ZM16.0678 12.723H11.0463C10.8661 12.723 10.7199 12.869 10.7199 13.0492V18.0685C10.7199 18.2487
    10.8661 18.3948 11.0463 18.3948H16.0678C16.2481 18.3948 16.3942 18.2487 16.3942 18.0685V13.0492C16.3942 12.869 16.2481 12.723 16.0678 12.723Z"
      fill="#0CA86D"
    />
    <path
      d="M8.03327 4.26544C8.44926 4.26544 8.78649 4.60252 8.78649 5.01834C8.78649 5.3995 8.50312 5.7145 8.13548 5.76436L8.03327
    5.77123H5.02039C4.6044 5.77123 4.26717 5.43415 4.26717 5.01834C4.26717 4.63718 4.55054 4.32217 4.91818 4.27231L5.02039 4.26544H8.03327Z"
      fill="#0CA86D"
    />
    <path
      d="M10.7951 8.53185C10.7951 8.11604 10.4578 7.77895 10.0419 7.77895H5.02039L4.91818 7.78583C4.55054 7.83568
    4.26717 8.15069 4.26717 8.53185C4.26717 8.94766 4.6044 9.28474 5.02039 9.28474H10.0419L10.1441
    9.27787C10.5117 9.22802 10.7951 8.91301 10.7951 8.53185Z"
      fill="#0CA86D"
    />
  </svg>
);

export default BuyNRentIcon;
