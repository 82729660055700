import React from 'react';
const ShipmentsIcon = (props) => (
  <svg width="2.25rem" height="2.25rem" {...props}>
    <path
      d="M19.788 24.747l.087.003-.062-.002-.025-.001zM21.905.132l11.25 6 .008.005.076.045.012.008.016.01.007.006-.005-.003.042.03c.023.018.046.037.067.056l.016.015.016.015c.02.02.04.04.059.062l.033.04a1.074 1.074 0 0 1 .168.288 1.155 1.155 0 0 1 .042.126l.016.07a1.122 1.122 0 0 1 .02.172l.002.015v7.533c0 .365-.177.708-.476.919l-12.75 9-.035.024-.06.036-.015.006-.003.002-.006.003h.003l-.06.03.02-.01-.008.005a1.12 1.12 0 0 1-.344.101h-.005l-.136.009-.105-.006-.069-.008a1.124 1.124 0 0 1-.343-.112l-.025-.014a1.143 1.143 0 0 1-.036-.02l-11.25-6.75a1.123 1.123 0 0 1-.547-.965V8.619a1.12 1.12 0 0 1 .555-.964l12.75-7.5a1.129 1.129 0 0 1 1.1-.023zm-2.153 24.611l.018.001.018.003-.036-.004zm.63-.115l-.006.004-.008.003.014-.007zm.047-.024l-.021.01.003-.002.018-.008zM9.75 10.612v5.626l9 5.4v-5.626l-9-5.4zm21.75-1.42l-9.372 6.064-1.128.731v5.467l10.5-7.413V9.192zM5.003 15a1.125 1.125 0 0 1 0 2.25H1.125a1.125 1.125 0 0 1 0-2.25h3.878zm16.4-12.586l-10.575 6.22 9.024 5.415L30.41 7.22l-9.007-4.805zM5.002 10.5a1.125 1.125 0 0 1 0 2.25H1.125a1.125 1.125 0 0 1 0-2.25h3.878zm0-4.5a1.125 1.125 0 0 1 0 2.25H1.125a1.125 1.125 0 0 1 0-2.25h3.878zm28.202.163l.059.037-.013-.01-.046-.027z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default ShipmentsIcon;
