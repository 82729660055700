import React, { useCallback, useState, useMemo, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import PartnerAccountForm from '../partners/components/PartnerAccountForm';
import useUpdateAccountRole from '../partners/hooks/useUpdateAccountRole';
import CustomModal from '@/components/CustomModal';
import PillButton from '@/components/PillButton';
import ViewEditFooterModalFactory from '@/components/ViewEditFooterModalFactory';
import { useLPUsersStore, useRemoveAccount } from '@/features/lp-account-users';
import useError from '@/hooks/useError';

const ViewAccountModal = ({
  id,
  domain,
  onSuccess,
}: {
  id?: string;
  domain: 'partners' | 'shops';
  onSuccess: () => void;
}) => {
  const history = useNavigate();
  const addError = useError();
  const viewModalAccount = useLPUsersStore((state) => state.viewModalAccount);
  const dispatch = useLPUsersStore((state) => state.dispatch);
  const [footerMode, setFooterMode] = useState<
    'viewEditDelete' | 'delete' | 'edit'
  >('viewEditDelete');

  const { loading: removeLoading, removeAccount } = useRemoveAccount(domain);

  const closeModal = useCallback(() => {
    dispatch({
      type: 'updateViewModal',
      args: { viewModalAccount: null },
    });
  }, [dispatch]);

  const {
    loading: addRoleLoading,
    selectedRole,
    account,
    handleSubmitRoles,
    setSelectedRole,
  } = useUpdateAccountRole(
    id,
    domain,
    closeModal,
    setFooterMode,
    viewModalAccount
  );

  useEffect(() => {
    setSelectedRole(account.role);
  }, [account.role, setSelectedRole]);

  const handleRemoveAccount = useCallback(async () => {
    const { success } = await removeAccount(id, account.id);
    if (success) {
      closeModal();
      await onSuccess();
    }
  }, [removeAccount, id, account.id, closeModal, onSuccess]);

  const handleAccountLink = useCallback(
    () =>
      !!account?.id
        ? history(`/lp-account/${account.id}`)
        : addError(new Error('Unable to find Account')),
    [history, addError, account]
  );

  const isInvalid = useMemo(() => !selectedRole.id, [selectedRole]);

  return (
    <CustomModal
      destroyOnClose={true}
      title="View Account"
      open={!!viewModalAccount}
      width="80%"
      onCancel={closeModal}
      afterClose={() => setFooterMode('viewEditDelete')}
      footer={ViewEditFooterModalFactory({
        footerMode,
        editText: 'Edit Roles',
        deleteText: 'Remove Account',
        isInvalid: isInvalid,
        loading: addRoleLoading || removeLoading,
        handleSubmit: handleSubmitRoles,
        setFooterMode,
        closeModal,
        disableEdit: false,
        handleDelete: handleRemoveAccount,
        onEditCancel: () => setSelectedRole(account.role),
      })}
    >
      <PartnerAccountForm
        account={account}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
        disabled={footerMode !== 'edit'}
        domain={domain}
      />
      <PillButton onClick={handleAccountLink}>To LP-Account</PillButton>
    </CustomModal>
  );
};

export default ViewAccountModal;
