import has from 'lodash/has';

import dates from '@/helpers/dates';

export const addressPostProcessor = (item) => ({
  ...item,
  parsedCreatedAt: has(item, 'createdAt')
    ? dates.parseDate(item.createdAt)
    : '',
  parsedUpdatedAt: has(item, 'updatedAt')
    ? dates.parseDate(item.updatedAt)
    : '',
});

const addressesPostProcessor = (data) => {
  const parsedItems = data.items.map(addressPostProcessor);

  return { ...data, items: parsedItems };
};

export default addressesPostProcessor;
