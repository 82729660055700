import React, { useCallback, useState } from 'react';

import styled from 'styled-components';

import DateTimePicker from '../../../../components/forms/DateTimePicker';
import useEditContribution from '../../hooks/saContributions/useEditContribution';
import { IContribution, INITIAL_Contribution } from '../../models/contribution';
import CustomModal from '@/components/CustomModal';
import FloatInput, { nonNumberError } from '@/components/forms/FloatInput';
import P from '@/components/text/P';
import FormTextInput from '@/shared/components/FormTextInput/FormTextInput';

const StyledP = styled(P)`
  margin-bottom: 1rem;
`;

const EditContributionModal = ({
  versionHash,
  contributionId,
  showModal,
  setShowModal,
}) => {
  const [{ loading }, editContribution] = useEditContribution(contributionId);
  const [newContribution, setNewContribution] =
    useState<IContribution>(INITIAL_Contribution);
  const [unixTimeStamp, setUnixTimeStamp] = useState<number | null>(null);
  const [createdAt, setCreatedAt] = useState<number | null>(null);

  const handleChangeInputtedContributionId = useCallback(
    (event) => {
      setNewContribution((prev) => ({
        ...prev,
        id: event.target.value,
        touched: true,
      }));
    },
    [setNewContribution]
  );

  const handleChangeNewPaymentUniqueId = useCallback(
    (event) => {
      setNewContribution((prev) => ({
        ...prev,
        paymentUniqueId: event.target.value,
        touched: true,
      }));
    },
    [setNewContribution]
  );

  const handleChangeNewPaymentAmount = useCallback(
    (event) => {
      setNewContribution((prev) => ({
        ...prev,
        totalAmountInCents: event.target.value,
        touched: true,
      }));
    },
    [setNewContribution]
  );

  const handleChangeNewInviteCode = useCallback(
    (event) => {
      setNewContribution((prev) => ({
        ...prev,
        inviteCode: event.target.value,
        touched: true,
      }));
    },
    [setNewContribution]
  );

  const handleChangeNewAcceptedTosVersion = useCallback(
    (event) => {
      setNewContribution((prev) => ({
        ...prev,
        acceptedTosVersion: event.target.value,
        touched: true,
      }));
    },
    [setNewContribution]
  );

  const handleCancel = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const updatedDateAndTime = Math.round(+new Date() / 1000);
  const handleSubmit = useCallback(async () => {
    const { success } = await editContribution({
      ...newContribution,
      versionHash: versionHash,
      updatedAt: updatedDateAndTime,
      paymentDate: unixTimeStamp,
      createdAt: createdAt,
    });
    if (success) {
      setShowModal(false);
    }
  }, [
    newContribution,
    editContribution,
    versionHash,
    setShowModal,
    updatedDateAndTime,
    unixTimeStamp,
    createdAt,
  ]);

  const afterClose = useCallback(() => {
    setNewContribution(INITIAL_Contribution);
    setUnixTimeStamp(null);
    setCreatedAt(null);
  }, [setNewContribution, setUnixTimeStamp]);

  const hasErrorId = newContribution.id.trim() !== contributionId;
  const hasErrorAmount = nonNumberError(newContribution.totalAmountInCents);

  return (
    <CustomModal
      open={showModal}
      onCancel={handleCancel}
      title="Change the contribution"
      okText="Modify Contribution"
      cancelText="Close"
      okButtonProps={{
        loading: loading,
        disabled: hasErrorAmount || hasErrorId,
      }}
      width="30%"
      onOk={handleSubmit}
      afterClose={afterClose}
    >
      <div data-testid="modify-modal">
        <StyledP>
          To modify the payment amount of the contribution, please enter the
          contribution&apos;s ID:
        </StyledP>
        <label>Contribution-ID</label>
        <FormTextInput
          name="contributionId"
          errorMsg={hasErrorId ? 'The Contribution-ID does not match' : ''}
          hasError={hasErrorId}
          isTouched={newContribution.touched}
          value={newContribution.id}
          onChange={handleChangeInputtedContributionId}
        />

        <label>Payment Unique ID</label>
        <FormTextInput
          name="inputtedPaymentUniqueId"
          isTouched={newContribution.touched}
          value={newContribution.paymentUniqueId}
          onChange={handleChangeNewPaymentUniqueId}
        />

        <label>Invited By Code</label>
        <FormTextInput
          name="inviteCode"
          isTouched={newContribution.touched}
          value={newContribution.inviteCode}
          onChange={handleChangeNewInviteCode}
        />
        <label>Accepted ToS version</label>
        <FormTextInput
          name="acceptedTosVersion"
          isTouched={newContribution.touched}
          value={newContribution.acceptedTosVersion}
          onChange={handleChangeNewAcceptedTosVersion}
        />

        <label>Total Contribution Amount</label>
        <FloatInput
          name="inputtedAmount"
          isTouched={newContribution.touched}
          value={newContribution.totalAmountInCents}
          onChange={handleChangeNewPaymentAmount}
          type="number"
          mb="2rem"
        />
        <label>Payment date</label>
        <DateTimePicker
          setUnixTimeStamp={setUnixTimeStamp}
          useDefaultTime={false}
        />
        <div style={{ marginTop: '1rem' }}>
          <label>Created at</label>
          <DateTimePicker
            setUnixTimeStamp={setCreatedAt}
            useDefaultTime={false}
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default EditContributionModal;
