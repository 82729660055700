import React, { useCallback, useEffect, useState } from 'react';

import { ColumnProps } from 'antd/lib/table';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

import { TLPPartnersSortBy } from '../../partners/hooks/useLPPartnersStore';
import { IService } from '../../services/models/service';
import useGetShops from '../hooks/useGetShops';
import useLPShopsStore from '../hooks/useLPShopsStore';
import StyledTable from '@/components/tables/StyledTable';
import {
  getCurrentPage,
  getOffset,
  parseAntdSorter,
} from '@/helpers/antdTable';
import useAntdColumns from '@/hooks/useAntdColumns';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useWindowSize from '@/hooks/useWindowSize';

const LPShopsTable = ({ partnerId }: { partnerId?: string }) => {
  useGetShops(partnerId);
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpVillage');
  const addError = useError();
  const rowData = useLPShopsStore((state) => state.rowData);
  const pageSize = useLPShopsStore((state) => state.pageSize);
  const offset = useLPShopsStore((state) => state.offset);
  const total = useLPShopsStore((state) => state.total);
  const defaultSort = useLPShopsStore((state) => state.defaultSort);
  const dispatch = useLPShopsStore((state) => state.dispatch);
  const windowSize = useWindowSize();

  const [services, setServices] = useState<IService[]>([]);

  const getServices = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: `/admin/services?offset=0&order=DESC&pageSize=100&sortBy=updatedAt`,
      method: 'get',
    });
    if (error) {
      addError(error);

      return;
    }
    if (data?.items) {
      setServices(data.items.filter((s) => s.type === 'shop'));
    }
  }, [makeRequest, addError]);

  useEffect(() => {
    getServices();
  }, [getServices]);

  const pagination = {
    current: getCurrentPage(pageSize, offset),
    pageSize,
    total,
    showSizeChanger: true,
  };

  useEffect(
    () => () => {
      dispatch({ type: 'updateDefaultSort' });
      dispatch({
        type: 'updateFilters',
        args: {
          serviceIds: [],
        },
      });
    },
    [dispatch]
  );

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: [
      'shopId',
      'name',
      'partnerId',
      'phoneNumber',
      'website',
      'services',
    ],
    addDefaultColumns: true,
    defaultSort: defaultSort,
    columnsSpecialProps: {
      services: {
        filters: services.map((r) => ({ text: r.name, value: r.id })),
        width: '10rem',
        render: (text, record) =>
          isArray(record.services)
            ? record.services.map((s) => s.name).join(', ')
            : '',
      },
    },
    eyeLinkProps: { to: '/lp-village/shops' },
  });

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const [sortField, sortOrder] = parseAntdSorter(sorter);
      // TODO: add validation that sortField is a valid field
      dispatch({
        type: 'updateSorting',
        args: { sortBy: sortField as TLPPartnersSortBy, order: sortOrder },
      });
      dispatch({
        type: 'updatePagination',
        args: {
          offset: getOffset(pagination.pageSize, pagination.current),
          pageSize: pagination.pageSize,
        },
      });
      dispatch({
        type: 'updateFilters',
        args: {
          serviceIds: isArray(get(filters, 'services'))
            ? filters.services
            : null,
        },
      });
    },
    [dispatch]
  );

  return (
    <StyledTable
      rowKey="id"
      dataSource={rowData}
      columns={columns}
      size="small"
      pagination={pagination}
      loading={loading}
      scroll={{ y: get(windowSize, 'height', 0) * 0.65 }}
      onChange={handleTableChange}
    />
  );
};

export default LPShopsTable;
