import has from 'lodash/has';

import dates from '@/helpers/dates';

const serviceLevelPostProcessor = (data) => {
  if (!data) {
    return {};
  }

  return {
    ...data,
    parsedCreatedAt: has(data, 'created_at')
      ? dates.parseDate(data.created_at)
      : '',
    parsedUpdatedAt: has(data, 'updated_at')
      ? dates.parseDate(data.updated_at)
      : '',
  };
};

const serviceLevelsPostProcessor = (data) => {
  if (!data) {
    return {};
  }

  const items = data.serviceLevels || [];
  const parsedItems = items.map((item) => serviceLevelPostProcessor(item));

  return { total: 0, items: parsedItems };
};

export default serviceLevelsPostProcessor;
