import has from 'lodash/has';

import dates from './dates';

const datePostProcessor = (data) => {
  const parsedItems = data.items.map((item) => ({
    ...item,
    parsedCreatedAt: has(item, 'createdAt')
      ? dates.parseDate(item.createdAt)
      : '',
    parsedUpdatedAt: has(item, 'updatedAt')
      ? dates.parseDate(item.updatedAt)
      : '',
  }));

  return { ...data, items: parsedItems };
};
export default datePostProcessor;
