import React, { useState, useCallback, useEffect, useRef } from 'react';

import { Select, Col, Row } from 'antd';
import styled from 'styled-components';

import {
  LIFECYCLE_STATUSES,
  LIFECYCLE_SUB_STATUSES,
} from '../models/boxLifecycle';

const { Option } = Select;

const StyledSelect = styled(Select)`
  width: 16rem;
  padding-bottom: 0.5rem;
`;

const LifeCycleTypeForm = ({
  setLifeCycleTypeCode,
  lastState,
}: {
  setLifeCycleTypeCode: (codeStatus: string, codeSubStatus: string) => void;
  lastState?: any;
}) => {
  const [statutSelected, setStatutSelected] = useState<string | undefined>(
    undefined
  );
  const [subStatutSelected, setSubStatutSelected] = useState('');
  const [subStatusList, setSubStatusList] = useState([]);
  const [isSubstatusVisible, setIsSubstatusVisible] = useState(false);

  const statutSelectRef = useRef<any>(null);

  const clear = useCallback(() => {
    setSubStatusList([]);
    setSubStatutSelected(() => '');
    setLifeCycleTypeCode('', '');
  }, [setLifeCycleTypeCode]);

  const handleSelectStatus = useCallback(
    (val) => {
      clear();
      if (
        val &&
        LIFECYCLE_SUB_STATUSES[val] &&
        LIFECYCLE_SUB_STATUSES[val].length
      ) {
        setSubStatusList(LIFECYCLE_SUB_STATUSES[val]);
      }
      setStatutSelected(val);
      setLifeCycleTypeCode(val, '');
    },
    [setLifeCycleTypeCode, setSubStatusList, clear]
  );

  const handleSelectSubStatus = useCallback(
    (val) => {
      setSubStatutSelected(val);
      if (statutSelected) setLifeCycleTypeCode(statutSelected, val);
    },
    [setLifeCycleTypeCode, statutSelected, setSubStatutSelected]
  );

  useEffect(() => {
    if (lastState && lastState?.status && !statutSelected) {
      handleSelectStatus(lastState.status);
    }
    setIsSubstatusVisible(lastState?.status === statutSelected);
  }, [lastState, statutSelected, handleSelectStatus]);

  return (
    <>
      <Row>
        <Col>
          <StyledSelect
            data-testid="select-status"
            onChange={handleSelectStatus}
            placeholder="Select a status"
            ref={statutSelectRef}
            value={statutSelected}
          >
            {LIFECYCLE_STATUSES.filter(
              (lifeCycle) => !['INIT'].includes(lifeCycle)
            ).map((status) => (
              <Option key={status} value={status}>
                {status}
              </Option>
            ))}
          </StyledSelect>
        </Col>
      </Row>

      {subStatusList.length !== 0 && isSubstatusVisible && (
        <Row>
          <Col>
            <StyledSelect
              data-testid="select-sub-status"
              onChange={handleSelectSubStatus}
              placeholder="Select a sub status"
              value={subStatutSelected || undefined}
            >
              <Option value={undefined}>None</Option>
              {subStatusList.map((substatus) => (
                <Option key={substatus} value={substatus}>
                  {substatus}
                </Option>
              ))}
            </StyledSelect>
          </Col>
        </Row>
      )}
    </>
  );
};

export default LifeCycleTypeForm;
