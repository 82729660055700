import { useCallback } from 'react';

import useLPRolesStore from './useRolesStore';
import datePostProcessor from '@/helpers/datePostProcessor';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useRefreshRoles = () => {
  const dispatch = useLPRolesStore((state) => state.dispatch);
  const order = useLPRolesStore((state) => state.order);
  const sortBy = useLPRolesStore((state) => state.sortBy);
  const offset = useLPRolesStore((state) => state.offset);
  const pageSize = useLPRolesStore((state) => state.pageSize);
  const addError = useError();
  const [, makeRequest] = useImperativeRequestWrapper('lpVillage');

  const getRoles = useCallback(async () => {
    const { data, error } = await getPaginatedTableData({
      params: {
        path: '/admin/roles',
        method: 'get',
      },
      reducer: datePostProcessor,
      dispatch,
      addError,
      makeRequest,
      queryParams: Object.assign({
        pageSize: pageSize,
        offset: offset,
        sortBy: sortBy,
        order: order,
      }),
      queryParamsOptions: { skipEmptyString: true, skipNull: true },
    });

    return { data, error };
  }, [addError, dispatch, makeRequest, order, sortBy, pageSize, offset]);

  return getRoles;
};

export default useRefreshRoles;
