import React, { createContext } from 'react';

import { Box, TabsV2 as Tabs, Text } from '@lp/ds-next';
import { get } from 'lodash';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { LPAccount } from '../components/LPAccount';
import { Partner } from '../components/Partner';

const routes = [
  { path: 'lpaccount', element: <LPAccount /> },
  { path: 'partner', element: <Partner /> },
  { path: '*', element: <Navigate to="lpaccount" replace /> },
];

const tabs = [
  {
    to: 'lpaccount',
    label: 'LPAccount',
  },
  {
    to: 'partner',
    label: 'Partner',
  },
];

type AppContextValue = {
  tab: number;
};

const TABS_ENUM = {
  all: 0,
  partner: 1,
} as const;

type TabsValues = keyof typeof TABS_ENUM;

export const TabsContext = createContext<AppContextValue>({
  tab: TABS_ENUM.all,
});

export const ChangelogRoutes = () => {
  const element = useRoutes(routes);
  const location = useLocation();

  const tab = location.pathname.split('/').pop() as TabsValues;
  const tabValue = get(TABS_ENUM, tab!, TABS_ENUM.all);

  return (
    <Box p={{ mobile: '1rem', tablet: '2rem' }}>
      <Text gutterBottom variant="titleXXL">
        Changelog
      </Text>
      <Tabs tabs={tabs} value={tabValue} component={Link} />
      <Box pt="1rem">{element}</Box>
    </Box>
  );
};
