import React from 'react';

import { withTheme } from 'styled-components';

import SVG from './SVG';

const CopyRoundIcon = (props) => (
  <SVG width="30" height="30" viewBox="0 0 18 18" cursor="pointer" {...props}>
    <g clipPath="url(#clip0_2642_23738)">
      <path
        d="M1.86649 0H11.9748C12.4696 0 12.9446 0.196531 13.2947 0.546612C13.6447 0.896327 13.8416 1.37094 13.842 1.86612V4.13853H16.3929C16.819 4.13853 17.2278 4.30788 17.5294 4.6091C17.8307 4.91069 18 5.31918 18 5.74567V16.3929C18 16.819 17.8307 17.2278 17.5294 17.5291C17.2278 17.8307 16.819 18 16.3929 18H5.74567C5.31955 18 4.91069 17.8307 4.6091 17.5291C4.30788 17.2278 4.13853 16.819 4.13853 16.3929V13.8615H1.86649C1.37131 13.8615 0.896695 13.6646 0.546613 13.3145C0.196531 12.9644 5.51343e-07 12.4898 5.51343e-07 11.9946V1.86576C5.51343e-07 1.37094 0.196899 0.896327 0.546981 0.546612C0.897062 0.196531 1.37167 0 1.86649 0ZM5.74567 16.7947H16.3929C16.6147 16.7947 16.7947 16.6147 16.7947 16.3929V5.74567C16.7947 5.5238 16.6147 5.3438 16.3929 5.3438H5.74567C5.63914 5.3438 5.53702 5.38604 5.46171 5.46135C5.38641 5.53665 5.34416 5.63878 5.34416 5.74531V16.3929C5.34416 16.4994 5.38641 16.6015 5.46171 16.6768C5.53702 16.7521 5.63914 16.7944 5.74567 16.7944V16.7947ZM1.18543 11.995C1.18543 12.1702 1.25523 12.3388 1.37902 12.4626C1.50282 12.5864 1.67106 12.6562 1.84665 12.6562H4.11833V5.74567C4.11833 5.31955 4.28767 4.91069 4.5889 4.6091C4.89049 4.30788 5.29935 4.13853 5.72547 4.13853H12.636V1.86612C12.636 1.6909 12.5662 1.52229 12.4424 1.39849C12.3186 1.27469 12.1504 1.2049 11.9748 1.2049H1.84629C1.67106 1.2049 1.50282 1.27469 1.37865 1.39886C1.25449 1.52302 1.18506 1.6909 1.18506 1.86649V11.995H1.18543Z"
        fill={
          props.isCopied ? props.theme.colors.brand : props.theme.colors.black
        }
      />
      <path
        d="M8.38982 8.65837H13.7211C14.0385 8.65837 14.3111 8.89935 14.3339 9.216C14.3464 9.38314 14.2887 9.54808 14.1748 9.67114C14.0606 9.79384 13.9008 9.864 13.7329 9.864H8.40158C8.08419 9.864 7.81162 9.62302 7.78884 9.30637C7.77635 9.13922 7.83403 8.97429 7.94827 8.85122C8.06252 8.72816 8.22231 8.65837 8.39019 8.65837H8.38982ZM8.38982 12.2745H13.7211C14.0385 12.2745 14.3111 12.5155 14.3339 12.8322C14.3464 12.9993 14.2887 13.1642 14.1748 13.2873C14.0606 13.41 13.9008 13.4802 13.7329 13.4802H8.40158C8.08419 13.4802 7.81162 13.2392 7.78884 12.9225C7.77635 12.7554 7.83403 12.5904 7.94827 12.4674C8.06252 12.3443 8.22231 12.2745 8.39019 12.2745H8.38982Z"
        fill={
          props.isCopied ? props.theme.colors.brand : props.theme.colors.black
        }
      />
    </g>
    <defs>
      <clipPath id="clip0_2642_23738">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="matrix(-1 0 0 1 18 0)"
        />
      </clipPath>
    </defs>
  </SVG>
);

export default withTheme(CopyRoundIcon);
