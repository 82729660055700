import React from 'react';

import FieldError from './FieldError';
import FormFieldWrapper from './FormFieldWrapper';
import Label from '../Label';
import StyledInput, { ITextInput } from '../TextInput';

export interface IFormTextInputMeta {
  isTouched?: boolean;
  hasError?: boolean;
  errorMsg?: string | undefined;
}

export interface IFormTextInputField {
  field?: any;
  meta?: any;
}

const FormTextInput = (
  props: ITextInput & IFormTextInputMeta & IFormTextInputField
) => {
  const {
    mb,
    width,
    label,
    field,
    meta,
    hasError,
    errorMsg,
    type,
    icon,
    isTouched,
    ...rest
  } = props;

  return (
    <FormFieldWrapper mb={mb} width={width}>
      <Label>
        {label}
        <StyledInput
          {...field}
          {...meta}
          {...rest}
          isTouched={isTouched}
          error={hasError}
          type={type || 'text'}
          icon={icon}
        />
        {isTouched && (
          <FieldError
            data-testid={hasError ? 'fieldError' : ''}
            pos="absolute"
            top="3rem"
            message={errorMsg}
          />
        )}
      </Label>
    </FormFieldWrapper>
  );
};

export default FormTextInput;
