import React from 'react';

import TimeLineRow from '../../shipments/components/TimeLineRow';
import { boxLifecycle } from '../models/boxLifecycle';
const BoxLifecycleHistory = ({ lifecycleStatuses }) => (
  <>
    {lifecycleStatuses.length !== 0 ? (
      lifecycleStatuses.map((item: boxLifecycle) => (
        <TimeLineRow timeLineEvent={item} key={item.id} />
      ))
    ) : (
      <p>No lifecycle statuses.</p>
    )}
  </>
);

export default BoxLifecycleHistory;
