import { IModifiedOffer, IOffer } from '../models/offer';
import { ILPAccountAddress } from '@/models/address';

export interface IContribution {
  id: string;
  accountId: string;
  contributionOffer: IOffer | {};
  totalAmountInCents: number;
  currency: 'EUR' | 'USD' | 'UNKNOWN';
  paymentMethod:
    | 'CREDIT_CARD'
    | 'GIROPAY'
    | 'SOFORT'
    | 'WIRE'
    | 'UNKNOWN'
    | 'REWARD'
    | null;
  paymentStatus:
    | 'rejected'
    | 'canceled'
    | 'pending'
    | 'completed'
    | 'unknown'
    // TODO(santi698): Verify if this state is possible
    | 'refund_requested'
    | 'refund_initiated'
    | 'refunded'
    | 'error'
    | null;
  paymentDate: number;
  paymentUniqueId: string;
  paymentProcessor: string;
  inviteCode: string;
  createdAt: number;
  updatedAt: number;
  state: 'requested' | 'active' | 'refunded' | 'cancelled' | 'expired' | null;
  acceptedTosVersion: string;
  startDate: number;
  versionHash: string;
  address: ILPAccountAddress | {};
  rewardForContributionId: string;
  notes: string;
  touched: boolean;
  availableDate: number;
}

export interface IModifiedContribution extends IContribution {
  parsedTotalAmount: string;
  parsedCreatedAt: string;
  parsedUpdatedAt: string;
  parsedPaymentDate: string;
  parsedStartDate: string;
  parsedOffer: Partial<IModifiedOffer>;
}

export interface IAddContribution
  extends Pick<
    IContribution,
    | 'accountId'
    | 'totalAmountInCents'
    | 'currency'
    | 'inviteCode'
    | 'acceptedTosVersion'
    | 'createdAt'
  > {
  offerId: string;
  addressId: string;
}

export const INITIAL_Contribution: IContribution = {
  accountId: '',
  paymentMethod: null,
  paymentStatus: null,
  paymentUniqueId: '',
  paymentProcessor: '',
  inviteCode: '',
  createdAt: 0,
  state: null,
  acceptedTosVersion: '',
  versionHash: '',
  rewardForContributionId: '',
  notes: '',
  totalAmountInCents: 0,
  contributionOffer: {},
  currency: 'UNKNOWN',
  id: '',
  paymentDate: 0,
  updatedAt: 0,
  startDate: 0,
  address: {},
  touched: false,
  availableDate: 0,
};

export const INITIAL_ModifiedContribution: IModifiedContribution = {
  ...INITIAL_Contribution,
  parsedTotalAmount: '',
  parsedCreatedAt: '',
  parsedUpdatedAt: '',
  parsedPaymentDate: '',
  parsedStartDate: '',
  parsedOffer: {},
};
