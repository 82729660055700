import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { Descriptions, Typography } from 'antd';
import { useParams } from 'react-router-dom';

import ArchiveQualityCheckType from '../components/ArchiveQualityCheckType';
import ImageThumbnail from '../components/ImageThumbnail';
import useQualityCheckType from '../hooks/useQualityCheckType';
import {
  INITIAL_QualityCheckType,
  IQualityCheckType,
  ZonesNames,
} from '../models/qualityCheck';
import PageTitle from '@/components/layout/PageTitle';
import datePostProcessor from '@/helpers/datePostProcessor';

const QualityCheckTypeDetailView = () => {
  const { id } = useParams<{ id: string }>();
  const { getQualityCheckType } = useQualityCheckType();
  const [qualityCheckType, setQualityCheckType] = useState<IQualityCheckType>(
    INITIAL_QualityCheckType
  );

  useEffect(() => {
    getQualityCheckType(id!).then(({ success, data }) => {
      if (success) {
        const processedData = datePostProcessor({ items: [data] });
        setQualityCheckType((prev) => ({ ...prev, ...processedData.items[0] }));
      }
    });
  }, [getQualityCheckType, id]);

  const {
    name,
    description,
    expectedLevel,
    image,
    parsedCreatedAt,
    parsedUpdatedAt,
    createdBy,
    updatedBy,
    isArchived,
    zone,
  } = qualityCheckType;

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item xs={10}>
        <PageTitle title="QUALITY CHECK TYPE DETAILS" />
      </Grid>
      <Grid
        container
        justifyContent={isArchived ? 'space-between' : 'flex-end'}
        item
        xs={10}
      >
        <ArchiveQualityCheckType
          qualityCheckType={qualityCheckType}
          setQualityCheckType={setQualityCheckType}
        />
      </Grid>
      <Grid item xs={10}>
        <Descriptions bordered column={2}>
          <Descriptions.Item label="Quality Check Type ID">
            <Typography.Text copyable={true}>{id || ''}</Typography.Text>
          </Descriptions.Item>

          <Descriptions.Item label="Name">{name}</Descriptions.Item>
          <Descriptions.Item label="Description">
            {description}
          </Descriptions.Item>
          <Descriptions.Item label="Expected Level">
            {expectedLevel}
          </Descriptions.Item>

          <Descriptions.Item label="Created At">
            {parsedCreatedAt}
          </Descriptions.Item>
          <Descriptions.Item label="Updated At">
            {parsedUpdatedAt}
          </Descriptions.Item>
          <Descriptions.Item label="Created By">
            {createdBy.email}
          </Descriptions.Item>
          <Descriptions.Item label="Updated By">
            {updatedBy.email}
          </Descriptions.Item>
          <Descriptions.Item label="Zone">{ZonesNames[zone]}</Descriptions.Item>
          <Descriptions.Item label="Image">
            <ImageThumbnail url={image} />
          </Descriptions.Item>
        </Descriptions>
      </Grid>
    </Grid>
  );
};

export default QualityCheckTypeDetailView;
