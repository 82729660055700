import useRefreshRoles from './useRefreshRoles';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useCreateRole: any = () => {
  const addError = useError();
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpVillage');
  const getRoles = useRefreshRoles();
  const successMsg = useToasts((state) => state.addItem);

  const createRole = async (role) => {
    const { error } = await makeRequest({
      path: '/admin/roles',
      method: 'post',
      body: role,
    });
    if (error) {
      addError(error);

      return { success: false };
    }
    await getRoles();
    successMsg(
      { msg: 'The role was successfully added', type: MessageType.Success },
      'root',
      {}
    );

    return { success: true };
  };

  return [{ loading }, createRole];
};
export default useCreateRole;
