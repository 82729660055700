import { object, string, boolean, array, number } from 'yup';

import withPaginationSchema from '@/helpers/withPaginationSchema';

const identitySchema = object().shape({
  id: string().default(''),
  userId: string().default(''),
  connection: string().default(''),
  providerUserId: string().default(''),
  provider: string().default(''),
  isSocial: boolean().default(false),
});

const noteSchema = object().shape({
  id: string().default(''),
  createdAt: number(),
  note: string().default(''),
  user_id: string().default(''),
});

export const profileSchema = object().shape({
  id: string().required(),
  createdAt: number().required(),
  updatedAt: number().required(),
  deletedAt: number().notRequired(),
  firstName: string().default(''),
  givenName: string().default(''),
  lastName: string().default(''),
  email: string().default(''),
  emailVerified: boolean().default(false),
  accountVerified: boolean().default(false),
  phoneNumber: string().default(''),
  phoneNumberVerified: boolean().default(false),
  publicHandle: string().default(''),
  profilePicture: string().default(''),
  identities: array().of(identitySchema).default([]),
  notes: array().of(noteSchema).nullable(),
  preferredLanguage: string().default(''),
  businessType: string().default(''),
});

export const updateProfileSchema = object().shape({
  firstName: string().default(''),
  lastName: string().default(''),
  emailVerified: boolean().default(false),
  accountVerified: boolean().default(false),
  phoneNumber: string().default(''),
  preferredLanguage: string().required(),
});

export default withPaginationSchema(profileSchema);
