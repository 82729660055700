import React from 'react';
const BoxesIcon = (props) => (
  <svg width="2.25rem" height="2.25rem" {...props}>
    <path
      d="M17.515 34.995l.11.006-.091-.004-.019-.002zm-.005-.001h.005-.013.008zm.668-.14l-.029.015-.019.01.048-.024zM9 22.079L9 27.484l7.5 4.422v-5.621L9 22.078zm18.751-1.215l-9 5.4v5.626l9-5.4v-5.626zm-8.834-5.948l-8.79 5.214 7.48 4.197 7.145-4.288 1.83-1.097-7.665-4.026zM2.25 10.26v4.979l5.821 3.493L9.8 17.707a1.124 1.124 0 0 1-.05-.33v-3.08l-7.5-4.037zm18-.695l-1.218.695L12 14.278v2.124l6.308-3.743c.336-.2.752-.209 1.098-.029l.843.443.001-3.508zM12.155 3.19L3.417 8.333l7.44 4.007 8.326-4.757-7.028-4.393zm17.337 14.747l-.063-.04.075.047-.012-.007zM.004 8.292v.01l.002-.04-.002.03zm0 0l.002-.029V8.254v.004l.018-.113a1.134 1.134 0 0 1 .031-.117L.066 8c.086-.243.255-.453.483-.59l.007-.003L11.602.905c.363-.212.811-.206 1.167.015l9.202 5.752.037.026.043.028.014.011-.094-.065a1.124 1.124 0 0 1 .503.711l.005.025a1.099 1.099 0 0 1 .021.218v6.63l6.898 3.624.012.006.02.011.041.028.021.012.024.016-.009-.007.006.004.017.011-.01-.006.062.046a1.123 1.123 0 0 1 .144.139l.032.038a1.077 1.077 0 0 1 .188.354 1.156 1.156 0 0 1 .032.124l.012.073a.892.892 0 0 1 .01.115v8.282c0 .396-.207.762-.547.965l-11.25 6.75-.012.007a1.13 1.13 0 0 1-.008.005l-.005.002-.08.042-.01.004-.002.001-.076.031a1.289 1.289 0 0 1-.213.055l-.037.005c-.044.005-.09.008-.135.008l-.084-.005-.03-.002-.068-.008a1.209 1.209 0 0 1-.326-.106.688.688 0 0 1-.046-.025l-.017-.01-9.75-5.75a1.122 1.122 0 0 1-.554-.969v-7.564L.545 16.84A1.123 1.123 0 0 1 0 15.876v-7.5l.001-.02.002-.03v.007l.001-.041z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default BoxesIcon;
