import React, { useCallback, useEffect, useMemo, useState } from 'react';

import RoleForm from './RoleForm';
import useEditRole from '../hooks/useEditRole';
import CancelSubmitFooterModal from '@/components/CancelSubmitFooterModal';
import CustomModal from '@/components/CustomModal';

const INITIAL_STATE = {
  name: '',
  description: '',
  type: '',
};

const EditRole = ({ originalRole, visible, closeModal }) => {
  const [{ loading }, editRole] = useEditRole();
  const [role, setRole] = useState(INITIAL_STATE);

  useEffect(() => {
    if (originalRole) {
      setRole(originalRole);
    }
  }, [originalRole]);

  const isInvalid = useMemo(
    () => !role.name || !role.description || !role.type,
    [role]
  );

  const handleSubmit = useCallback(async () => {
    const { success } = await editRole(role);
    if (success) {
      setRole(INITIAL_STATE);
      closeModal();
    }
  }, [editRole, closeModal, role]);

  const modalFooterButtons = useMemo(
    () => CancelSubmitFooterModal(isInvalid, handleSubmit, closeModal, loading),
    [closeModal, handleSubmit, isInvalid, loading]
  );

  return (
    <CustomModal
      title="Edit role"
      open={visible}
      destroyOnClose={true}
      footer={modalFooterButtons}
      onCancel={closeModal}
    >
      <RoleForm role={role} setRole={setRole} />
    </CustomModal>
  );
};

export default EditRole;
