import React, { Fragment, useEffect, useState } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Button as AntdButton, Col, Row, Tooltip } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import ActivateOfferModal from './ActivateOfferModal';
import DeactivateOfferModal from './DeactivateOfferModal';
import offersPostProcessor, {
  offerPostProcessor,
} from '../../helpers/offersPostProcessor';
import useOffersStore from '../../hooks/useOffersStore';
import TextInput from '@/components/forms/TextInput';
import ScopedCta from '@/components/ScopedCta';
import SpinLoader from '@/components/SpinLoader';
import H2 from '@/components/text/H2';
import { scopes } from '@/config';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const Wrapper = styled.div`
  margin-bottom: 3rem;
`;

const LoadingWrapper = styled.div`
  margin-top: 15vh;
`;

const FlexRow = styled(Row)`
  display: flex !important;
`;

const StyledCol = styled(Col)`
  align-self: flex-end !important;
  margin-bottom: 1.8rem !important;
`;

const ActiveH2 = styled(H2)`
  color: ${(props) => props.theme.colors.adminPanel.brand};
`;

const InActiveH2 = styled(H2)`
  color: ${(props) => props.theme.colors.adminPanel.error};
`;

const PendingH2 = styled(H2)`
  color: ${(props) => props.theme.colors.adminPanel.orange};
`;

const parseState = (state) => {
  switch (state) {
    case 'active':
      return <ActiveH2>ACTIVE</ActiveH2>;
    case 'deactivated':
      return <InActiveH2>DEACTIVATED</InActiveH2>;
    case 'notYetActive':
      return <PendingH2>NOT YET ACTIVE</PendingH2>;
  }

  return <H2>UNKNOWN STATE</H2>;
};

const SAOfferDetailsContent = () => {
  const { id } = useParams<{ id: string }>();
  const history = useNavigate();

  const [offer, setOffer] = useState<any>(null);
  const [offersRequest, setOffersRequest] = useState({
    sent: false,
    loading: false,
  });

  const [activateModal, setActivateModal] = useState(false);
  const [deactivateModal, setDeactivateModal] = useState(false);
  const offers = useOffersStore((state) => state.rowData);
  const addError = useError();
  const dispatch = useOffersStore((state) => state.dispatch);

  const [, makeRequest] = useImperativeRequestWrapper('serviceLpAccount');

  useEffect(() => {
    if (!id || offersRequest.loading) {
      return;
    }

    const getData = async () => {
      setOffersRequest({ sent: true, loading: true });
      await getPaginatedTableData({
        params: {
          path: `/admin/offers`,
          method: 'get',
        },
        dispatch,
        queryParams: {
          pageSize: 100,
          offset: 0,
          sortBy: 'updatedAt',
          order: 'DESC',
          onlyActive: 'false',
          offerTypes: [],
        },
        reducer: offersPostProcessor,
        addError,
        makeRequest,
      });
      setOffersRequest({ sent: true, loading: false });
    };

    if (offers.length === 0 && !offersRequest.sent) {
      getData();

      return;
    }
    try {
      const offer = offers.find((x) => x.id === id);
      setOffer(offerPostProcessor(offer));
    } catch (err) {
      history('/sharing-angels/offers');
    }
  }, [
    offers,
    id,
    history,
    addError,
    dispatch,
    makeRequest,
    setOffersRequest,
    offersRequest,
  ]);

  if (!offer) {
    return (
      <LoadingWrapper>
        <SpinLoader />
      </LoadingWrapper>
    );
  }

  return (
    <Fragment>
      <Wrapper>
        <Row>{parseState(offer.state)}</Row>
        <Row gutter={24}>
          <Col span={12}>
            <TextInput disabled label="Offer-ID" name="offerId" value={id} />
          </Col>
          <Col span={12}>
            <TextInput
              disabled
              label="Label"
              name="label"
              value={offer.label}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <TextInput
              disabled
              label="Created at"
              name="createdAt"
              value={offer.parsedCreatedAt}
            />
          </Col>
          <Col span={12}>
            <TextInput
              disabled
              label="Created by"
              name="activatedAt"
              value={offer.createdBy}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <TextInput
              disabled
              label="Updated at"
              name="updatedAt"
              value={offer.parsedUpdatedAt}
            />
          </Col>
          <Col span={6}>
            <TextInput
              disabled
              label="Multiplier"
              name="multiplierInCents"
              value={offer.parsedMultiplier}
            />
          </Col>
          <Col span={6}>
            <TextInput
              disabled
              label="Duration (in months)"
              name="durationInMonth"
              value={offer.durationInMonth}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <TextInput
              disabled
              label="Offer type"
              name="offerType"
              value={offer.offerType}
            />
          </Col>
          <Col span={12}>
            <TextInput
              disabled
              label="Deactivation Payment Buffer"
              name="deactivationPaymentBuffer"
              value={offer.parsedDeactivatedPaymentBuffer}
            />
          </Col>
        </Row>
        <FlexRow gutter={24}>
          <Col span={10}>
            <TextInput
              disabled
              label="Active at"
              name="activeAt"
              value={offer.parsedActiveAt}
            />
          </Col>
          <StyledCol span={2}>
            <Tooltip placement="topRight" title="Set Activation Date/Time">
              <ScopedCta
                component={AntdButton}
                type="primary"
                icon={<EditOutlined />}
                onClick={() => setActivateModal(true)}
                requiredScopes={[scopes.EDIT_SHARING_ANGELS_OFFER]}
              />
            </Tooltip>
          </StyledCol>
          <Col span={10}>
            <TextInput
              disabled
              label="Deactivated at"
              name="deactivatedAt"
              value={offer.parsedDeactivatedAt}
            />
          </Col>
          <StyledCol span={2}>
            <Tooltip placement="topRight" title="Set Deactivation Date/Time">
              <ScopedCta
                component={AntdButton}
                danger
                icon={<EditOutlined />}
                onClick={() => setDeactivateModal(true)}
                requiredScopes={[scopes.EDIT_SHARING_ANGELS_OFFER]}
              />
            </Tooltip>
          </StyledCol>
        </FlexRow>
      </Wrapper>
      <ActivateOfferModal
        id={id}
        visible={activateModal}
        onClose={() => setActivateModal(false)}
      />
      <DeactivateOfferModal
        id={id}
        visible={deactivateModal}
        onClose={() => setDeactivateModal(false)}
      />
    </Fragment>
  );
};

export default SAOfferDetailsContent;
