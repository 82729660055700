import React, { useCallback } from 'react';

import { Col, Row, Form, Input } from 'antd';

//import ScopeSelector from './ScopeSelector';
import { ICreateApiKey } from '../models/apiKey';

const CreateApiKeyForm = ({
  apiKey,
  setApiKey,
}: {
  apiKey: ICreateApiKey;
  setApiKey: (res: any) => void;
}) => {
  const handleDescriptionChange = useCallback(
    (e) => {
      if (setApiKey) {
        setApiKey({ ...apiKey, description: e.target.value });
      }
    },
    [setApiKey, apiKey]
  );

  /*const handleScopesChange = useCallback(
    e => {
      if (setApiKey) {
        setApiKey({ ...apiKey, scopes: e });
      }
    },
    [setApiKey, apiKey]
  );*/

  return (
    <Row gutter={24}>
      <Col span={24}>
        <Form layout="vertical">
          <Form.Item label="Description *">
            <Input
              name="description"
              onChange={handleDescriptionChange}
              value={apiKey.description}
            />
          </Form.Item>
          {/*<ScopeSelector
            disabled={true}
            onChange={handleScopesChange}
            initialScope={apiKey.scopes}
          />*/}
        </Form>
      </Col>
    </Row>
  );
};
export default CreateApiKeyForm;
