import React from 'react';

import {
  ButtonV2 as Button,
  TableSortLabel,
  TableCell,
  TableHead,
  TableRow,
} from '@lp/ds-next';

import { HeadCell, Order } from '../../../types/changelog';

interface EnhancedTableProps {
  headCells: HeadCell[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  globalOpen: boolean;
  setGlobalOpen: (prev: boolean) => void;
}

export const TableHeader = ({
  headCells,
  order,
  orderBy,
  onRequestSort,
  setGlobalOpen,
  globalOpen,
}: EnhancedTableProps) => {
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <Button
            sx={{
              '& > span': { paddingLeft: '0 !important', whiteSpace: 'nowrap' },
            }}
            variant="flat"
            onClick={() => setGlobalOpen(!globalOpen)}
          >
            {globalOpen ? 'Collapse All' : 'Expand All'}
          </Button>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="right"
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ whiteSpace: 'nowrap' }}
          >
            {headCell.order ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
