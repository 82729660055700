import { camelCase, isArray, isObject, transform } from 'lodash';

export const getRandomInt = (max: number) =>
  Math.floor(Math.random() * Math.floor(max));

export const fromSnakeCaseDeep = (obj) =>
  transform(obj, (acc: any, value, key: string, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);
    acc[camelKey] = isObject(value) ? fromSnakeCaseDeep(value) : value;
  });
