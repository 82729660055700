import React from 'react';

import get from 'lodash/get';
import Select from 'react-select';
import styled, { withTheme } from 'styled-components';
interface IDropdown {
  width?: number;
  mw?: string;
  iconWidth?: number;
  isDisabled?: boolean;
}

const SelectStyled = styled.div<IDropdown>`
  width: ${({ width }) => width || '100%'};
  max-width: ${({ mw }) => mw || '24rem'};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : '')};
  & svg {
    width: ${({ iconWidth }) => iconWidth || '1rem'};
  }
`;

const Dropdown: React.FC<IDropdown & any> = ({
  theme,
  customStylesOverrides,
  width,
  mw,
  iconWidth,
  isDisabled,
  defaultValue,
  isOptionSelected,
  value,
  ...props
}) => {
  const selectColors = {
    primary25: get(
      theme,
      'colors.lightbrand',
      theme.colors.adminPanel.lightbrand
    ),
    primary50: get(
      theme,
      'colors.lightbrand',
      theme.colors.adminPanel.lightbrand
    ),
    primary75: get(
      theme,
      'colors.lightbrand',
      theme.colors.adminPanel.lightbrand
    ),
    primary: get(
      theme,
      'colors.lightbrand',
      theme.colors.adminPanel.lightbrand
    ),
    neutral80: get(theme, 'colors.darkgrey', theme.colors.adminPanel.darkgrey),
    danger: get(theme, 'colors.error', theme.colors.adminPanel.error),
  };

  const customStyles = {
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
      transition: 'transform 0.25s ease',
      color: state.selectProps.menuIsOpen
        ? get(
            theme,
            'colors.adminPanel.darkgrey',
            theme.colors.adminPanel.darkgrey
          )
        : get(
            theme,
            'colors.adminPanel.darkgrey',
            theme.colors.adminPanel.darkgrey
          ),
    }),
    placeholder: (provided: any, state: any) => ({
      ...provided,
      fontFamily: get(
        theme,
        'fontStyles.adminPanel.monospace',
        theme.fontStyles.adminPanel.monospace
      ),
      color: !state.IsFocused
        ? get(
            theme,
            'colors.adminPanel.darkgrey',
            theme.colors.adminPanel.darkgrey
          )
        : get(theme, 'colors.adminPanel.grey', theme.colors.adminPanel.grey),
    }),
    singleValue: (provided: any, state: any) => ({
      ...provided,
      color: !state.selectProps.menuIsOpen
        ? `${get(
            theme,
            'colors.adminPanel.black',
            theme.colors.adminPanel.black
          )}!important`
        : `${get(
            theme,
            'colors.adminPanel.darkgrey',
            theme.colors.adminPanel.darkgrey
          )}!important`,
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      height: '2.75rem',
      fontFamily: get(
        theme,
        'fontStyles.monospace',
        theme.fontStyles.monospace
      ),
      borderRadius: '0',
      border: 'none',
      background: state.isFocused
        ? get(theme, 'colors.adminPanel.grey', theme.colors.adminPanel.grey)
        : get(theme, 'colors.adminPanel.white', theme.colors.adminPanel.white),
      boxShadow: !state.isFocused
        ? state.error && state.isTouched
          ? `inset 0 0 0 1px ${get(
              theme,
              'colors.adminPanel.error',
              theme.colors.adminPanel.error
            )}`
          : `inset 0 0 0 1px ${get(
              theme,
              'colors.adminPanel.brand',
              theme.colors.adminPanel.brand
            )}`
        : get(theme, 'shadows.adminPanel.big', theme.shadows.adminPanel.big),
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      border: 0,
      fontFamily: get(
        theme,
        'fontStyles.adminPanel.monospace',
        theme.fontStyles.adminPanel.monospace
      ),
      color: state.isSelected
        ? get(theme, 'colors.adminPanel.black', theme.colors.adminPanel.black)
        : get(
            theme,
            'colors.adminPanel.darkgrey',
            theme.colors.adminPanel.darkgrey
          ),
      fontWeight: state.isSelected ? 600 : 400,
      background: state.isFocused
        ? get(
            theme,
            'colors.adminPanel.lightbrand',
            theme.colors.adminPanel.lightbrand
          )
        : get(theme, 'colors.adminPanel.white', theme.colors.adminPanel.white),
      padding: '0.6rem 0.6rem',
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: '0',
    }),
    menuList: (provided: any) => ({
      ...provided,
      padding: '0',
    }),
  };

  return (
    <SelectStyled
      data-testid="dropdown"
      width={width}
      mw={mw}
      iconWidth={iconWidth}
      isDisabled={isDisabled}
    >
      <Select
        value={value}
        defaultValue={defaultValue}
        styles={{ ...customStyles, ...customStylesOverrides }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            ...selectColors,
          },
        })}
        isOptionSelected={isOptionSelected}
        isDisabled={isDisabled}
        {...props}
      />
    </SelectStyled>
  );
};

export default withTheme(Dropdown);
