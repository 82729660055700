import { useCallback } from 'react';

import { useApiClient } from '@/helpers/ApiClients';

const useUploadLabelImage = () => {
  const shipmentService = useApiClient('shipment');

  const uploadLabelImage = useCallback(
    async (id, versionHash, label) => {
      const bodyFormData = new FormData();
      bodyFormData.append('file', label);
      bodyFormData.append('versionHash', versionHash);

      await shipmentService.patch(
        `/admin/shipments/${id}/label/overwrite`,
        bodyFormData
      );
    },
    [shipmentService]
  );

  return uploadLabelImage;
};

export default useUploadLabelImage;
