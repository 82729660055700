import React, { useMemo } from 'react';

import { Tooltip } from 'antd';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import useAppState from '../hooks/useAppState';
import { useAuth0 } from '@/shared/auth0';

const Text = styled.p`
  margin: 0;
  margin-top: 0.5rem;
  font-family: TTNormsPro-Bold;
  text-transform: uppercase;
  font-size: 13px;
`;

const StyledNavLink = styled(NavLink)<{ disabled?: boolean }>`
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  color: ${({ theme, disabled }) =>
    disabled
      ? theme.colors.adminPanel.darkgrey
      : theme.colors.adminPanel.black};

  &.active-nav-link {
    &.active {
      background: white;
      position: relative;
      color: #0c6b64;
      box-shadow: 0px 0px 12px #0c6b6433;
    }
  }
`;

interface NavElementProps {
  icon: any;
  to: string;
  text: string;
  requiredScopes?: string[];
}

const NavElement = ({
  icon: Icon,
  requiredScopes,
  to,
  text,
}: NavElementProps) => {
  const { isAuthenticated } = useAuth0();
  const userScopes = useAppState((state) => state.scopes);

  const disabled = useMemo(() => {
    if (isAuthenticated) {
      return !(
        !requiredScopes ||
        requiredScopes.length === 0 ||
        requiredScopes.some((val) => userScopes.includes(val))
      );
    }

    return true;
  }, [isAuthenticated, requiredScopes, userScopes]);

  return disabled ? (
    <Tooltip placement="right" title="Sorry, you are not allowed to view this!">
      <StyledNavLink to={to} disabled={disabled}>
        <Icon fill="currentColor" />
        <Text>{text}</Text>
      </StyledNavLink>
    </Tooltip>
  ) : (
    <StyledNavLink to={to} className="active-nav-link">
      <Icon />
      <Text>{text}</Text>
    </StyledNavLink>
  );
};

export default NavElement;
