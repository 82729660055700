import React, { useCallback, useEffect } from 'react';

import { ColumnProps } from 'antd/lib/table';
import get from 'lodash/get';

import boxContractsPostProcessor from '../helpers/boxContractsPostProcessor';
import useContractsStore from '../hooks/useContractsStore';
import { BoxContract, SerializedBoxContract } from '../models/boxContract';
import StyledTable from '@/components/tables/StyledTable';
import {
  getCurrentPage,
  getOffset,
  parseAntdSorter,
  getDefaultSortOrder,
} from '@/helpers/antdTable';
import { formatDate } from '@/helpers/dates';
import { storeSelector } from '@/helpers/paginatedStoreHelpers';
import useAntdColumns from '@/hooks/useAntdColumns';
import usePaginatedTableData from '@/hooks/usePaginatedTableData';
import useWindowSize from '@/hooks/useWindowSize';
interface BoxContractTableProps {
  filterByAccountId?: boolean;
  accountId?: string;
  hasFilters?: boolean | Record<string, boolean>;
}

const BoxContractTable = ({
  filterByAccountId = false,
  accountId,
  hasFilters,
}: BoxContractTableProps) => {
  const {
    dispatch,
    order,
    sortBy,
    offset,
    pageSize,
    rowData,
    total,
    defaultSort = { sortBy: 'paymentDate', order: 'ASC' },
    filters,
    searchTerms,
  } = useContractsStore(storeSelector);

  useEffect(() => () => dispatch({ type: 'reset' }), [dispatch]);

  const windowSize = useWindowSize();

  const endpoint =
    filterByAccountId && accountId
      ? `/admin/box-contracts/byaccount/${accountId}`
      : '/admin/box-contracts';

  const { loading } = usePaginatedTableData({
    params: {
      path: endpoint,
      method: 'get',
      reducer: boxContractsPostProcessor,
    },
    dispatch,
    queryParams: {
      pageSize: pageSize,
      offset: offset,
      sortBy: sortBy,
      order: order,
      search: searchTerms.search,
      ...filters,
    },
    queryParamsOptions: { skipEmptyString: true, skipNull: true },
    service: 'serviceLpAccount',
  });

  const hasFilter = useCallback(
    (type: string) => hasFilters === true || get(hasFilters, type, false),
    [hasFilters]
  );

  const columns: ColumnProps<SerializedBoxContract>[] = useAntdColumns<
    BoxContract,
    'contractId'
  >({
    columnsKeys: [
      'paymentDate',
      'contractId',
      'updatedAt',
      'numOfBox',
      'boxContractOffer',
      'firstName',
      'lastName',
      'email',
      'paymentStatus',
      'state',
      'totalAmountInCents',
    ],
    defaultSort,
    eyeLinkProps: { to: '/buy-n-rent/contracts' },
    columnsSpecialProps: {
      boxContractOffer: {
        render: (_, record) =>
          `${get(record, 'boxContractOffer.label', '')} | ${get(
            record,
            'boxContractOffer.offerType',
            ''
          )}`,
        filters: [
          { text: 'buy_and_rent', value: 'buy_and_rent' },
          { text: 'buy', value: 'buy' },
        ],
        filterMultiple: false,
        filteredValue: filters.boxContractOfferType,
        width: '8rem',
      },
      totalAmountInCents: {
        render: (_, record) => `${record.totalAmountInCents / 100} €`,
      },
      paymentDate: {
        sorter: hasFilter('paymentDate'),
        defaultSortOrder: getDefaultSortOrder(defaultSort, 'paymentDate'),
        width: '6rem',
        render: (text, record) =>
          record.paymentDate.getTime() === 0
            ? 'N/A'
            : formatDate(record.paymentDate),
      },
      paymentStatus: {
        filters: [
          { text: 'Rejected', value: 'rejected' },
          { text: 'Canceled', value: 'canceled' },
          { text: 'Pending', value: 'pending' },
          { text: 'Completed', value: 'completed' },
          { text: 'Refund Requested', value: 'refund_requested' },
          { text: 'Refund Initiated', value: 'refund_initiated' },
          { text: 'Refunded', value: 'refunded' },
          { text: 'Unknown', value: 'unknown' },
        ],
        filterMultiple: false,
        filteredValue: filters.paymentStatus,
        width: '6rem',
      },
      email: { width: '12rem' },
      numOfBox: {
        width: '4.5rem',
        render: (_, record) => {
          if (record && record.productIDs) {
            return `
           ${record.productIDs.length} / ${record.numOfBox}
           `;
          }
        },
      },
    },
  });

  const pagination = {
    current: getCurrentPage(pageSize, offset),
    pageSize,
    total,
    showSizeChanger: true,
  };

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const [sortField, sortOrder] = parseAntdSorter(sorter);
      dispatch({
        type: 'updateSorting',
        args: { sortBy: sortField, order: sortOrder },
      });
      dispatch({
        type: 'updatePagination',
        args: {
          offset: getOffset(pagination.pageSize, pagination.current),
          pageSize: pagination.pageSize,
        },
      });
      dispatch({
        type: 'updateFilters',
        args: {
          filters: {
            boxContractOfferType: get(filters, 'boxContractOffer', null),
            paymentStatus: get(filters, 'paymentStatus', null),
          },
        },
      });
    },
    [dispatch]
  );

  return (
    <StyledTable
      rowKey="id"
      dataSource={rowData}
      columns={columns}
      size="small"
      pagination={pagination}
      loading={loading}
      scroll={{ y: get(windowSize, 'height', 0) * 0.65 }}
      onChange={handleTableChange}
    />
  );
};

export default BoxContractTable;
