import { useMutation } from '@tanstack/react-query';

import { ISimStatus, SIM_STATUS } from '../../models/sim';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { MutationConfig, queryClient } from '@/lib/react-query';

export const updateSimStatus =
  (makeRequest: any) =>
  async ({
    status,
    lpUI,
  }: {
    status: SIM_STATUS;
    lpUI: string;
  }): Promise<ISimStatus> => {
    const { data, error } = await makeRequest({
      path: `/products/${lpUI}/sim`,
      method: 'patch',
      body: {
        status,
      },
    });

    if (!data || error) {
      throw new Error(error);
    }

    return data;
  };

type UseUpdateSimStatus = {
  config?: MutationConfig<ReturnType<typeof updateSimStatus>>;
};

export const useUpdateSimStatus = ({ config }: UseUpdateSimStatus = {}) => {
  const addError = useError();
  const [, makeRequest] = useImperativeRequestWrapper('deviceV3');

  return useMutation({
    onError: (error) => {
      addError(error);
    },

    onSuccess: (data) => {
      queryClient.setQueryData(['sim', data.sim], data);
    },
    ...config,
    mutationFn: updateSimStatus(makeRequest),
  });
};
