import React from 'react';

import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { Auth0ProviderWithNavigate } from './Auth0ProviderWithNavigate';
import App from './components/App';

import './index.css';
import './index.less';
import 'react-datepicker/dist/react-datepicker.css';

import '@lp/ds-next/style.css';

declare global {
  interface Window {
    Cypress?: any;
  }
}

const root = createRoot(document.getElementById('root')!);

const router = createBrowserRouter([
  {
    path: '/*',
    element: (
      <Auth0ProviderWithNavigate>
        <App />
      </Auth0ProviderWithNavigate>
    ),
  },
]);

root.render(<RouterProvider router={router} />);
