export type IReducer<
  Record extends { [key: string]: any },
  TFilters,
  TAdditionalState = null,
> =
  | {
      type: 'updateRowData';
      args: { rowData: Record[] };
    }
  | {
      type: 'updateError';
      args: { error: string | boolean };
    }
  | {
      type: 'updateLoading';
      args: { loading: boolean };
    }
  | {
      type: 'updatePagination';
      args: { offset: number; pageSize: number };
    }
  | {
      type: 'updateSorting';
      args: {
        sortBy: keyof Record;
        order: 'ASC' | 'DESC';
      };
    }
  | {
      type: 'updateCount';
      args: { count: number };
    }
  | {
      type: 'updateFilters';
      args: {
        filters: TFilters;
      };
    }
  | {
      type: 'updateTotal';
      args: { total: number };
    }
  | {
      type: 'updateAdditionalState';
      args: { additionalState: TAdditionalState };
    }
  | {
      type: 'updateDefaultSort';
    }
  | {
      type: 'updateSearchTerms';
      args: {
        search: string;
      };
    }
  | {
      type: 'reset';
    };

export const reducer = <
  Record extends { [key: string]: any },
  TFilters,
  TAdditionalState = null,
>(
  state: IPaginatedStore<Record, TFilters, TAdditionalState>,
  input: IReducer<Record, TFilters, TAdditionalState>,
  INITIAL_STATE: any
) => {
  switch (input.type) {
    case 'updateRowData':
      return { ...state, rowData: input.args.rowData };
    case 'updateError':
      return { ...state, error: input.args.error };
    case 'updateLoading':
      return { ...state, loading: input.args.loading };
    case 'updatePagination':
      return {
        ...state,
        offset: input.args.offset,
        pageSize: input.args.pageSize,
      };
    case 'updateFilters':
      return { ...state, filters: input.args.filters };
    case 'updateSorting':
      return { ...state, sortBy: input.args.sortBy, order: input.args.order };
    case 'updateCount':
      return { ...state, count: input.args.count };
    case 'updateTotal':
      return { ...state, total: input.args.total };
    case 'updateAdditionalState':
      return { ...state, additionalState: input.args.additionalState };
    case 'updateDefaultSort':
      return {
        ...state,
        defaultSort: { sortBy: state.sortBy, order: state.order },
      };
    case 'updateSearchTerms':
      return {
        ...state,
        searchTerms: input.args,
        offset: 0,
      };
    case 'reset':
      return INITIAL_STATE;
  }
};

export interface IPaginatedStore<
  Record extends { [key: string]: any },
  TFilters,
  TAdditionalState = null,
> {
  rowData: Record[];
  pageSize: number;
  offset: number;
  sortBy: keyof Record;
  filters: TFilters;
  order: 'ASC' | 'DESC';
  error: string | boolean;
  loading: boolean;
  count: number;
  dispatch: (args: IReducer<Record, TFilters, TAdditionalState>) => void;
  total: number;
  additionalState: TAdditionalState;
  searchTerms: { search: string };
  defaultSort?: {
    sortBy: keyof Record;
    order: 'ASC' | 'DESC';
  };
}

export const getInitialState = <
  Record extends { [key: string]: any },
  TFilters,
  TAdditionalState = null,
>(
  params: { sortBy: keyof Record; order: 'ASC' | 'DESC' } & Partial<
    Omit<IPaginatedStore<Record, TFilters, TAdditionalState>, 'dispatch'>
  >
) => ({
  rowData: [],
  error: '',
  loading: false,
  pageSize: 10,
  offset: 0,
  count: 0,
  total: 0,
  filters: {},
  defaultSort: {
    sortBy: params.sortBy,
    order: params.order,
  },
  additionalState: null,
  searchTerms: { search: '' },
  ...params,
});

export const storeSelector = <
  Record extends { [key: string]: any },
  TFilters,
  TAdditionalState = null,
>(
  state: IPaginatedStore<Record, TFilters, TAdditionalState>
) => ({
  rowData: state.rowData,
  pageSize: state.pageSize,
  offset: state.offset,
  sortBy: state.sortBy,
  filters: state.filters,
  defaultSort: state.defaultSort,
  order: state.order,
  error: state.error,
  loading: state.loading,
  count: state.count,
  dispatch: state.dispatch,
  total: state.total,
  searchTerms: state.searchTerms,
  additionalState: state.additionalState,
});
