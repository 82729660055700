import React from 'react';

import { Route, Routes } from 'react-router-dom';

import LPPartnerDetails from './views/LPPartnerDetails';
import LPPartnersTable from './views/LPPartnersTable';
import { appRoutes } from '@/configs/appRoutes';
import VillageTotalProvider from '@/shared/providers/villageTotalProvider';

const LPPartners = () => (
  <VillageTotalProvider>
    <Routes>
      <Route path={appRoutes.ROOT} element={<LPPartnersTable />} />
      <Route
        path={appRoutes.LPVILLAGE.PARTNERS.CREATE}
        element={<LPPartnerDetails />}
      />
      <Route
        path={appRoutes.LPVILLAGE.PARTNERS.PARTNER_ID}
        element={<LPPartnerDetails />}
      />
    </Routes>
  </VillageTotalProvider>
);

export default LPPartners;
