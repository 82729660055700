import React, { useCallback } from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { IRelation } from '../models/box';
import PillButton from '@/components/PillButton';
import { scopes } from '@/config';
import { ILPAccountUser } from '@/features/lp-account-users';
import { IPartner } from '@/features/lp-village';
import useAppState from '@/hooks/useAppState';

const ActiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const ActiveItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const SubWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;
const Text = styled.p`
  margin: 0.5rem 0 0;
  font-family: TTNormsPro-Bold;
  text-transform: uppercase;
  font-size: 13px;
  width: 5rem;
`;
const getLink = (relation) => {
  switch (relation.objectType) {
    case 'LP_SHOP':
      return `/lp-village/shops/${relation.object.id}`;
    case 'LP_PARTNER':
      return `/lp-village/partners/${relation.object.id}`;
    case 'LP_ACCOUNT':
      return `/lp-account/${relation.object.id}`;
    default:
      throw new Error(`Invalid relation objectType ${relation.objectType}`);
  }
};

const ActiveRelations = ({
  activeRelations,
}: {
  activeRelations: IRelation[];
}) => {
  const userScopes = useAppState((state) => state.scopes);
  const activeRelation = useCallback(
    (type) => {
      const rel = activeRelations.find((r) => r.relationType === type);
      if (rel) {
        let data;
        switch ((rel as IRelation).objectType) {
          case 'LP_SHOP':
          case 'LP_PARTNER':
            data = (rel.object as IPartner).name;
            break;
          case 'LP_ACCOUNT':
            const object = rel.object as ILPAccountUser;
            data = object.email
              ? object.email
              : `${object.firstName} ${object.lastName}`;
        }

        return (
          <ActiveItemWrapper>
            <SubWrapper>
              <Text>{type}:&nbsp;</Text>
              {data}
            </SubWrapper>
            <PillButton>
              <Link to={getLink(rel)}>
                To {type.charAt(0) + type.slice(1).toLowerCase()}
              </Link>
            </PillButton>
          </ActiveItemWrapper>
        );
      }
    },
    [activeRelations]
  );

  return (
    <ActiveWrapper>
      {activeRelation('HOLDER')}
      {activeRelation('PIC')}
      {activeRelation('LOCATION')}

      {userScopes.includes(scopes.VIEW_PRODUCT_OWNER) &&
        activeRelation('OWNER')}
    </ActiveWrapper>
  );
};

export default ActiveRelations;
