import React, { useCallback, useEffect } from 'react';

import { ColumnProps } from 'antd/lib/table';

import usePartnershipStore from '../hooks/usePartnershipStore';
import StyledTable from '@/components/tables/StyledTable';
import { getCurrentPage, getOffset } from '@/helpers/antdTable';
import useAntdColumns from '@/hooks/useAntdColumns';
import usePaginatedTableData from '@/hooks/usePaginatedTableData';

const LPAccountPartnershipsTable = ({ id }: { id: string }) => {
  const rowData = usePartnershipStore((state) => state.rowData);
  const loading = usePartnershipStore((state) => state.loading);
  const pageSize = usePartnershipStore((state) => state.pageSize);
  const offset = usePartnershipStore((state) => state.offset);
  const sortBy = usePartnershipStore((state) => state.sortBy);
  const order = usePartnershipStore((state) => state.order);
  const total = usePartnershipStore((state) => state.total);
  const dispatch = usePartnershipStore((state) => state.dispatch);

  useEffect(() => () => dispatch({ type: 'reset' }), [dispatch]);

  usePaginatedTableData({
    params: {
      path: `/admin/accounts/${id}/partners`,
      method: 'get',
    },
    dispatch,
    queryParams: {
      pageSize,
      offset,
      sortBy,
      order,
    },
    service: 'lpVillage',
  });

  const pagination = {
    current: getCurrentPage(pageSize, offset),
    pageSize,
    total,
    showSizeChanger: false,
  };

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: ['partnerName', 'type'],
    addDefaultColumns: false,
    defaultSort: {
      sortBy: 'updatedAt',
      order: 'DESC',
    },
  });

  const handleTableChange = useCallback(
    (pagination) => {
      dispatch({
        type: 'updatePagination',
        args: {
          offset: getOffset(pagination.pageSize, pagination.current),
          pageSize: pagination.pageSize,
        },
      });
    },
    [dispatch]
  );

  return (
    <StyledTable
      rowKey="id"
      rowClassName={(record) => record.id}
      dataSource={rowData}
      columns={columns}
      size="small"
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
      scroll={{ x: 'max-content' }}
    />
  );
};

export default LPAccountPartnershipsTable;
