import { useCallback } from 'react';

import { deserializeBoxContract } from '../helpers/boxContractsPostProcessor';
import { BoxContract, SerializedBoxContract } from '../models/boxContract';
import { useApiClient } from '@/helpers/ApiClients';

export function useGetBoxContract(): (id: string) => Promise<BoxContract> {
  const lpAccount = useApiClient('serviceLpAccount');
  const getBoxContract = useCallback(
    (id: string) =>
      lpAccount
        .get<SerializedBoxContract>(`/admin/box-contracts/${id}`)
        .then((response) => deserializeBoxContract(response.data)),
    [lpAccount]
  );

  return getBoxContract;
}
