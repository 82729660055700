import React, { useCallback } from 'react';

import { ColumnProps } from 'antd/lib/table';
import get from 'lodash/get';

import InvitationCodeModal from './InvitationCodeModal';
import StyledTable from '@/components/tables/StyledTable';
import {
  getCurrentPage,
  getOffset,
  parseAntdSorter,
} from '@/helpers/antdTable';
import useAntdColumns from '@/hooks/useAntdColumns';

const InvitationCodesTable = ({
  rowData,
  pageSize,
  offset,
  total,
  loading,
  dispatch,
  showSizeChanger,
}) => {
  const pagination = {
    current: getCurrentPage(pageSize, offset),
    pageSize,
    total,
    showSizeChanger,
  };

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const [sortField, sortOrder] = parseAntdSorter(sorter);
      dispatch({
        type: 'updateSorting',
        args: { sortBy: sortField as string, order: sortOrder },
      });
      dispatch({
        type: 'updatePagination',
        args: {
          offset: getOffset(pagination.pageSize, pagination.current),
          pageSize: pagination.pageSize,
        },
      });
      dispatch({
        type: 'updateFilters',
        args: {
          filters: {
            onlyActive:
              get(filters, 'status', []).length > 0 ? filters.status[0] : false,
            invitationTypes: get(filters, 'invitationType', []).join(),
          },
        },
      });
    },
    [dispatch]
  );

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: [
      'codeId',
      'issuerUserId',
      'numberOfContributions',
      'parsedEmailsField',
      'code',
      'invitationType',
      'incentiveType',
      'offerType',
      'status',
    ],
    columnsSpecialProps: {
      invitationType: {
        filters: [
          { text: 'Default', value: 'default' },
          { text: 'Personal', value: 'personal' },
        ],
      },
      status: {
        fixed: 'right',
        width: '7rem',
        filters: [{ text: 'Only Active', value: 'true' }],
      },
      numberOfContributions: {
        width: '8rem',
      },
    },
    addDefaultColumns: true,
    defaultSort: {
      sortBy: 'updatedAt',
      order: 'DESC',
    },
    eyeLinkProps: {
      onClick: (record) => {
        dispatch({
          type: 'updateViewModal',
          args: {
            viewModalCode: record,
          },
        });
      },
    },
  });

  return (
    <>
      <StyledTable
        rowKey="id"
        dataSource={rowData}
        columns={columns}
        size="small"
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
      <InvitationCodeModal />
    </>
  );
};

export default InvitationCodesTable;
