import React from 'react';
const ServiceLevelsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="2.25rem"
    height="2.25rem"
    fill="currentColor"
    viewBox="0 0 48 48 "
    {...props}
  >
    <path d="M44 48H4c-2.206 0-4-1.794-4-4V4c0-2.206 1.794-4 4-4h40c2.206 0 4 1.794 4 4v40c0 2.206-1.794 4-4 4zM4 2c-1.103 0-2 .897-2 2v40c0 1.103.897 2 2 2h40c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2H4z" />
    <path d="M24 30a1 1 0 01-1-1V15a1 1 0 112 0v14a1 1 0 01-1 1zM34 30a1 1 0 01-1-1v-4a1 1 0 112 0v4a1 1 0 01-1 1zM14 18a1 1 0 01-1-1V7a1 1 0 112 0v10a1 1 0 01-1 1zM14 30a1 1 0 01-1-1v-8a1 1 0 112 0v8a1 1 0 01-1 1zM34 22a1 1 0 01-1-1V7a1 1 0 112 0v14a1 1 0 01-1 1zM24 12a1 1 0 01-1-1V7a1 1 0 112 0v4a1 1 0 01-1 1z" />
    <path d="M37 26h-6a1 1 0 01-1-1v-4a1 1 0 011-1h6a1 1 0 011 1v4a1 1 0 01-1 1zm-5-2h4v-2h-4v2zM27 16h-6a1 1 0 01-1-1v-4a1 1 0 011-1h6a1 1 0 011 1v4a1 1 0 01-1 1zm-5-2h4v-2h-4v2zM17 22h-6a1 1 0 01-1-1v-4a1 1 0 011-1h6a1 1 0 011 1v4a1 1 0 01-1 1zm-5-2h4v-2h-4v2zM34 42c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2zM24 42c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2zM14 42c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
  </svg>
);

export default ServiceLevelsIcon;
