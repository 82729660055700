import React, { useEffect } from 'react';

import {
  Box,
  ButtonV2 as Button,
  Grid,
  NewTooltip as Tooltip,
  ModalV2,
  Text,
  Toolbar,
  FilterIcon,
  useTheme,
  useMediaQuery,
} from '@lp/ds-next';

import { FilterModalContext } from '.';

export const HeaderToolbar = ({
  title,
  total,
  filters,
}: {
  title: string;
  total: number;
  filters?: JSX.Element;
}) => {
  const { open, setOpen } = React.useContext(FilterModalContext);
  const theme = useTheme();

  const isLaptop = useMediaQuery(theme.breakpoints.up('laptop'));

  // Hide filters modal on laptop and higher
  useEffect(() => {
    if (isLaptop) setOpen(false);
  }, [isLaptop, setOpen]);

  return (
    <Toolbar sx={{ pl: { tablet: 2 }, pr: { mobile: 1, tablet: 1 } }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        columnGap="1rem"
      >
        <Grid flexGrow={1}>
          <Text variant="titleXL">{title}</Text>
        </Grid>
        <Text variant="titleM" color="custom.primary.100">
          TOTAL {total || ''}
        </Text>
        {filters && (
          <Grid item sx={{ display: { mobile: 'block', laptop: 'none' } }}>
            <Tooltip title="Filter list">
              <Button
                variant="tertiary"
                onClick={() => setOpen(true)}
                icon={FilterIcon}
              />
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <ModalV2 open={open} setOpen={setOpen} hideCloseIcon>
        <Box sx={{ minWidth: '12.5rem' }}>{filters}</Box>
      </ModalV2>
    </Toolbar>
  );
};
