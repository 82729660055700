import React from 'react';

import styled from 'styled-components';
const CountWrapper = styled.div<{ textColor: boolean }>`
  color: ${(props) =>
    props.textColor
      ? props.theme.colors.black
      : props.theme.colors.adminPanel.brand};
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SeletedWrapper = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

const TablesTotals = ({
  total,
  gTotal,
  selectedItems,
}: {
  total;
  gTotal;
  selectedItems?;
}) => (
  <TotalWrapper>
    <SeletedWrapper>
      {' '}
      {selectedItems > 0
        ? `${selectedItems} item${selectedItems > 1 ? 's' : ''} selected`
        : ''}
    </SeletedWrapper>
    <TotalWrapper>
      <CountWrapper textColor={total === gTotal}>{total}</CountWrapper> /{' '}
      {gTotal}
    </TotalWrapper>
  </TotalWrapper>
);

export default TablesTotals;
