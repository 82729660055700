export enum MessageType {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
  Info = 'info',
  Loading = 'loading',
  Edit = 'edit',
}

export type ToastMessage = {
  msg?: string;
  cancel?: boolean;
  close?: boolean;
  type: MessageType;
  render?: React.FC<{ closeModal?: (e: React.MouseEvent) => void }>;
};
