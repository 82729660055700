import React, { useCallback, useMemo, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';

import useDeleteLPAccount from '../hooks/useDeleteLPAccount';
import { IModifiedLPAccountUser } from '../models/lpaccount';
import CustomModal from '@/components/CustomModal';
import InputIdForm from '@/components/InputIdForm';
import InlineSpacer from '@/components/layout/InlineSpacer';
import Spacer from '@/components/layout/Spacer';
import YesConfirmForm from '@/components/YesConfirmForm';
import { useRefreshAccountContribs } from '@/features/sharing-angels';

const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  width: 100%;
`;

const DeleteLpAccountModal = ({
  showModal,
  setShowModal,
  user,
  getUser,
}: {
  showModal: boolean;
  setShowModal: (i: boolean) => void;
  user: IModifiedLPAccountUser;
  getUser: any;
}) => {
  const [inputtedId, setInputtedId] = useState({ data: '', touched: false });
  const [confirmationInput, setConfirmationInput] = useState({
    data: '',
    touched: false,
  });
  const [step, setStep] = useState<1 | 2>(1);
  const stepOneInvalid = inputtedId.data !== user.id;
  const stepTwoInvalid = confirmationInput.data !== 'YES';

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const increaseStep = useCallback(() => {
    setStep(2);
  }, []);

  const handleStep2Change = useCallback((event) => {
    setConfirmationInput({
      touched: true,
      data: event.target.value,
    });
  }, []);

  const afterClose = useCallback(() => {
    setInputtedId({ data: '', touched: false });
    setConfirmationInput({ data: '', touched: false });
    setStep(1);
  }, []);

  const [{ loading }, deleteAccount] = useDeleteLPAccount();
  const getContributions = useRefreshAccountContribs();

  const handleSubmit = useCallback(async () => {
    const { success } = await deleteAccount(user.id);
    await getUser();
    await getContributions(user.id);
    if (success) {
      closeModal();
    }
  }, [closeModal, deleteAccount, getUser, user, getContributions]);

  const Footer = useMemo(() => {
    switch (step) {
      case 1:
        return [
          <Button key="cancel" onClick={closeModal}>
            Cancel
          </Button>,
        ];
      case 2:
        return [
          <Button key="cancel" onClick={closeModal}>
            Cancel
          </Button>,
          <Button
            key="delete"
            data-testid="submitDeleteBtn"
            danger={true}
            onClick={handleSubmit}
            disabled={stepTwoInvalid || loading}
            loading={loading}
            icon={<DeleteOutlined />}
          >
            Delete Account
          </Button>,
        ];
    }
  }, [step, stepTwoInvalid, closeModal, handleSubmit, loading]);

  return (
    <CustomModal
      open={showModal}
      onCancel={closeModal}
      afterClose={afterClose}
      destroyOnClose={true}
      title="Delete LP Account"
      footer={Footer}
    >
      {step === 1 ? (
        <div>
          <InputIdForm
            placeholder="Account-ID"
            text={
              <Wrapper>
                To delete the LP Account of email:
                <Spacer v="0.5rem" />
                <InlineSpacer width="0.5rem" />
                {user.email}
                <Spacer v="0.5rem" />
                please enter the Account ID:
              </Wrapper>
            }
            hasError={stepOneInvalid}
            inputtedId={inputtedId}
            setId={setInputtedId}
            inputName="delete-modal"
          />
          <ButtonWrapper>
            <Button
              data-testid="nextBtn"
              danger
              onClick={increaseStep}
              disabled={stepOneInvalid}
            >
              Next
            </Button>
          </ButtonWrapper>
        </div>
      ) : (
        <YesConfirmForm
          text={
            <>
              To confirm the deletion of the LP Account of email:
              <Spacer v="0.5rem" />
              <InlineSpacer width="0.5rem" />
              {user.email}
              <Spacer v="0.5rem" /> please type &ldquo;YES&rdquo; and press the
              button
            </>
          }
          isInvalid={stepTwoInvalid}
          confirmationInput={confirmationInput}
          handleChange={handleStep2Change}
        />
      )}
    </CustomModal>
  );
};

export default DeleteLpAccountModal;
