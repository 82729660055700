import React from 'react';

import { Grid } from '@material-ui/core';

import H1 from '../text/H1';
import TitleCopy from '@/components/TitleCopy';

const PageTitle = (props: { title: string; copy?: string }) => (
  <>
    <Grid item xs={12}>
      <H1>{props.title}</H1>
    </Grid>
    {props.copy && (
      <Grid item xs={4}>
        <TitleCopy>{props.copy}</TitleCopy>
      </Grid>
    )}
  </>
);

export default PageTitle;
