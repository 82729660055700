import React, { useCallback, useMemo, useState } from 'react';

import {
  Grid,
  Text,
  NewTextField as TextField,
  useMediaQuery,
  useTheme,
} from '@lp/ds-next';

import OfferConfigurationForm from './OfferConfigurationFrom';
import { IServiceLevel } from '../models/serviceLevel';
import CancelSubmitFooterModal from '@/components/CancelSubmitFooterModal';
import CustomModal from '@/components/CustomModal';
import useError from '@/hooks/useError';

interface ServiceLevelFormProps {
  initialServiceLevel: any;
  onSubmit: (body: IServiceLevel, id?: string) => Promise<void>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  fetchServiceLevels: () => void;
  isSameConfigInvalid?: boolean;
  title?: string;
  subtitle?: string;
  cloneMode?: boolean;
}

interface StateServiceLevel {
  name: string;
  description: string;
  productConfiguration: string;
  offerConfiguration: any;
}

const ServiceLevelForm = ({
  initialServiceLevel,
  onSubmit,
  loading,
  setLoading,
  visible,
  setVisible,
  fetchServiceLevels,
  isSameConfigInvalid = true,
  title = 'Add New Service Level',
  subtitle,
  cloneMode = false,
}: ServiceLevelFormProps) => {
  const addError = useError();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));

  const removeOfferFields = useCallback((offerConfiguration) => {
    const {
      productConfiguration,
      name,
      description,
      humiditySensor,
      humidityThreshold,
      pressureSensor,
      pressureThreshold,
      temperatureSensor,
      temperatureThreshold,
      shockSensor,
      shockThreshold,
      ...cleanOffer
    } = offerConfiguration;

    return cleanOffer;
  }, []);

  const formatInitialServiceLevel = useMemo(
    () => ({
      name: initialServiceLevel.name,
      description: initialServiceLevel.description,
      productConfiguration: JSON.stringify(
        initialServiceLevel.productConfiguration,
        null,
        2
      ),
      offerConfiguration: {
        otherValues: JSON.stringify(
          removeOfferFields(initialServiceLevel),
          null,
          2
        ),
        humiditySensor: {
          enabled: initialServiceLevel.humiditySensor,
          values: JSON.stringify(
            initialServiceLevel.humidityThreshold,
            null,
            2
          ),
        },
        pressureSensor: {
          enabled: initialServiceLevel.pressureSensor,
        },
        temperatureSensor: {
          enabled: initialServiceLevel.temperatureSensor,
          values: JSON.stringify(
            initialServiceLevel.temperatureThreshold,
            null,
            2
          ),
        },
        shockSensor: {
          enabled: initialServiceLevel.shockSensor,
          values: JSON.stringify(initialServiceLevel.shockThreshold, null, 2),
        },
      },
    }),
    [initialServiceLevel, removeOfferFields]
  );

  const [serviceLevel, setServiceLevel] = useState<StateServiceLevel>(
    formatInitialServiceLevel
  );

  const isInvalid = () => {
    try {
      JSON.parse(serviceLevel.productConfiguration);
    } catch (e) {
      return true;
    }
    if (cloneMode) {
      return (
        !serviceLevel.name ||
        !serviceLevel.description ||
        serviceLevel.name === formatInitialServiceLevel.name ||
        serviceLevel.description === formatInitialServiceLevel.description
      );
    }
    if (isSameConfigInvalid) {
      return (
        !serviceLevel.name ||
        !serviceLevel.description ||
        (serviceLevel.productConfiguration ===
          formatInitialServiceLevel.productConfiguration &&
          serviceLevel.offerConfiguration ===
            formatInitialServiceLevel.productConfiguration)
      );
    }

    return (
      serviceLevel.name === formatInitialServiceLevel.name &&
      serviceLevel.description === formatInitialServiceLevel.description &&
      serviceLevel.productConfiguration ===
        formatInitialServiceLevel.productConfiguration &&
      serviceLevel.offerConfiguration ===
        formatInitialServiceLevel.productConfiguration
    );
  };

  const formatSensorsData = useCallback(
    () => ({
      humiditySensor: serviceLevel.offerConfiguration.humiditySensor.enabled,
      humidityThreshold: {
        percentMin: JSON.parse(
          serviceLevel.offerConfiguration.humiditySensor.values
        ).percentMin,
        percentMax: JSON.parse(
          serviceLevel.offerConfiguration.humiditySensor.values
        ).percentMax,
      },

      pressureSensor: serviceLevel.offerConfiguration.pressureSensor.enabled,
      temperatureSensor:
        serviceLevel.offerConfiguration.temperatureSensor.enabled,
      temperatureThreshold: {
        celsiusMin: JSON.parse(
          serviceLevel.offerConfiguration.temperatureSensor.values
        ).celsiusMin,
        celsiusMax: JSON.parse(
          serviceLevel.offerConfiguration.temperatureSensor.values
        ).celsiusMax,
      },
      shockSensor: serviceLevel.offerConfiguration.shockSensor.enabled,
      shockThreshold: JSON.parse(
        serviceLevel.offerConfiguration.shockSensor.values
      ),
    }),
    [serviceLevel.offerConfiguration]
  );

  const handleSubmit = useCallback(async () => {
    try {
      const otherValues = JSON.parse(
        serviceLevel.offerConfiguration.otherValues
      );
      const sensorsData = formatSensorsData();
      const { offerConfiguration, ...cleanService } = serviceLevel;

      await onSubmit({
        ...cleanService,
        ...otherValues,
        ...sensorsData,
        productConfiguration: JSON.parse(serviceLevel.productConfiguration),
      });
      await fetchServiceLevels();
      setVisible(false);
    } catch (e) {
      let errorValue = '';

      if (e instanceof Object) {
        errorValue = ` : "${
          e['response'].data.message.split(/"(.*?)"/g)[1]
        }" value is wrong because [format], [type] or [value} is not correct`;
      }

      addError(`There was an error ${errorValue}`);
      setLoading(false);

      return;
    }
  }, [
    addError,
    onSubmit,
    setVisible,
    fetchServiceLevels,
    serviceLevel,
    formatSensorsData,
    setLoading,
  ]);

  const handleChange = useCallback((event) => {
    setServiceLevel((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  }, []);

  const modalFooterButtons = CancelSubmitFooterModal(
    isInvalid(),
    handleSubmit,
    () => setVisible(false),
    loading
  );

  return (
    <CustomModal
      title={
        <>
          {title}
          <Text variant="bodyTextS" color="custom.neutral.black.50">
            {subtitle}
          </Text>
        </>
      }
      open={visible}
      destroyOnClose={true}
      footer={modalFooterButtons}
      onCancel={() => setVisible(false)}
      {...(!isMobile ? { width: '80%' } : {})}
      style={{ maxWidth: '1280px' }}
      centered={!isMobile}
    >
      <Grid container spacing={1}>
        <Grid item mobile={12} tablet={6} laptop={4}>
          <Grid container direction="column" spacing={1}>
            <Grid item mobile={12}>
              <TextField
                onChange={handleChange}
                placeholder="Name"
                label="Name"
                name="name"
                value={serviceLevel.name}
                fullWidth
              />
            </Grid>
            <Grid item mobile={12}>
              <TextField
                onChange={handleChange}
                placeholder="Description"
                label="Description"
                name="description"
                value={serviceLevel.description}
                fullWidth
              />
            </Grid>
            <Grid item mobile={12}>
              <TextField
                multiline
                fullWidth
                onChange={handleChange}
                placeholder="Box Configuration"
                label="Box Configuration"
                name="productConfiguration"
                value={serviceLevel.productConfiguration}
                maxRows={21.5}
              />
            </Grid>
          </Grid>
        </Grid>
        <OfferConfigurationForm
          initialOffer={serviceLevel.offerConfiguration}
          setServiceLevel={setServiceLevel}
        />
      </Grid>
    </CustomModal>
  );
};

export default ServiceLevelForm;
