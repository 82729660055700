export interface Address {
  addressTypes: string[];
  building: string;
  city: string;
  company: string;
  countryCode: string;
  createdAt: 0;
  createdBy: string;
  displayName: string;
  email: string;
  firstName: string;
  houseNumber: string;
  id: string;
  lastName: string;
  phoneNumber: string;
  position: {
    latitude: number;
    longitude: number;
  };
  postalCode: string;
  referenceId: string;
  stakeholder: string;
  state: string;
  street: string;
  street2: string;
  type: string;
  updatedAt: number;
}

/**
 * Editable address
 */
export interface EditableAddress {
  addressTypes: AddressTypesKeys[];
  building: string;
  city: string;
  email: string;
  countryCode: string;
  phoneNumber: string;
  postalCode: string;
  street: string;
  street2: string;
  // Not present if addressTypes contains MAIN
  company?: string;
}

export const EditableAddressKeys = [
  'addressTypes',
  'building',
  'city',
  'email',
  'countryCode',
  'phoneNumber',
  'postalCode',
  'street',
  'street2',
  'company',
];

export const AddressTypes = {
  MAIN: 'MAIN',
  BILLING: 'BILLING',
  DELIVERY: 'DELIVERY',
  /*  SENDER: 'SENDER', */
} as const;

export type AddressTypesKeys = (typeof AddressTypes)[keyof typeof AddressTypes];
