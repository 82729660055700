import React from 'react';

import { Route, Navigate, useLocation, Routes } from 'react-router-dom';

import LPPartners from './partners/LPPartners';
import LPRoles from './roles/LPRoles';
import LPScopes from './scopes/LPScopes';
import LPShops from './shops/LPShops';
import DashboardTabs from '@/components/layout/DashboardTabs';
import { appRoutes } from '@/configs/appRoutes';

const CustomTabs = () => (
  <DashboardTabs
    title="LP VILLAGE"
    tabs={[
      { name: 'Partners', key: 'partners' },
      { name: 'Shops', key: 'shops' },
      { name: 'Roles', key: 'roles' },
      { name: 'Scopes', key: 'scopes' },
    ]}
  />
);

const VillageRouter = () => {
  const { pathname } = useLocation();

  return (
    <>
      {pathname.split('/').length <= 3 && <CustomTabs />}
      {pathname === `${appRoutes.LPVILLAGE.ROOT}` && (
        <Navigate to={appRoutes.LPVILLAGE.PARTNERS.ROOT} />
      )}
      <Routes>
        <Route
          path={`${appRoutes.LPVILLAGE.PARTNERS.ROOT}/*`}
          element={<LPPartners />}
        />
        <Route
          path={`${appRoutes.LPVILLAGE.SHOPS.ROOT}/*`}
          element={<LPShops />}
        />
        <Route path={`${appRoutes.LPVILLAGE.ROLES}/*`} element={<LPRoles />} />
        <Route
          path={`${appRoutes.LPVILLAGE.SCOPES}/*`}
          element={<LPScopes />}
        />
      </Routes>
    </>
  );
};

export default VillageRouter;
