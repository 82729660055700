import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';

import { Row } from 'antd';
import styled from 'styled-components';

import { ICreateShipment } from '../models/shipment';
import DragAndDrop from '@/components/DragAndDrop';
import Dropdown from '@/components/forms/Dropdown';
import TextInput from '@/components/forms/TextInput';

const TitleWrapper = styled.h2`
  padding-bottom: 1rem;
`;
const CreateShipmentCarrierForm = ({
  carrier,
  setCarrier,
  file,
  setFile,
  setShipment,
  shipment,
  dropdownDisabled = false,
}: {
  carrier: string;
  setCarrier: (id: string) => void;
  file: File | null;
  setFile: (file: File | null) => void;
  setShipment: Dispatch<SetStateAction<ICreateShipment>>;
  shipment: ICreateShipment;
  dropdownDisabled?: boolean;
}) => {
  const CARRIERS_LIST = useMemo(
    () => [
      {
        carrierCode: 'LPCarrier',
        label: 'LPCarrier',
      },
      {
        carrierCode: 'CHRONOPOST',
        label: 'Chronopost',
      },
      {
        carrierCode: 'FEDEX',
        label: 'FedEx',
      },
      {
        carrierCode: 'TNTExpress',
        label: 'Tnt',
      },
      {
        carrierCode: 'TCAT',
        label: 'T Cat',
      },
      {
        carrierCode: 'DHL',
        label: 'DHL',
      },
      {
        carrierCode: 'UPS',
        label: 'UPS',
      },
    ],
    []
  );

  const carrierOptionsDropDown = useCallback(
    () =>
      CARRIERS_LIST.map((carrier) => ({
        value: carrier.label,
        id: carrier.carrierCode,
      })),
    [CARRIERS_LIST]
  );

  const onCarrierDropDownChange = useCallback(
    (option) => {
      setCarrier(option.id);
    },
    [setCarrier]
  );

  const handleDropImage = useCallback(
    async (droppedFiles) => {
      droppedFiles[0] && setFile(droppedFiles[0]);
    },
    [setFile]
  );
  const handleChangeWeight = useCallback(
    (e) => {
      setShipment((prev) => ({
        ...prev,
        weightInGrams: e.target.value,
      }));
    },
    [setShipment]
  );

  return (
    <>
      <TitleWrapper>You can add a label (OPTIONAL):</TitleWrapper>
      <Row justify="center" style={{ marginBottom: 20 }}>
        <Dropdown
          isDisabled={dropdownDisabled}
          options={carrierOptionsDropDown()}
          getOptionValue={(option) => option['id']}
          getOptionLabel={(option) => option['value']}
          value={carrierOptionsDropDown().find(
            (option) => option.id === carrier
          )}
          onChange={onCarrierDropDownChange}
          is
        />
      </Row>
      <Row justify="center">
        <DragAndDrop
          onDrop={handleDropImage}
          text="Drop a PNG file to upload as the E-Label of this shipment"
          multiple={false}
          accept={{ 'image/png': [] }}
          height="7rem"
          fileDropped={!!file}
          removeFiles={() => setFile(null)}
        />
      </Row>
      <Row justify="center" style={{ marginRight: 210, marginTop: 15 }}>
        <div>
          <TextInput
            label="Package weight (kg) :"
            name="weightInGrams"
            onChange={handleChangeWeight}
            value={shipment.weightInGrams}
          />
        </div>
      </Row>
    </>
  );
};

export default CreateShipmentCarrierForm;
