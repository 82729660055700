import { useCallback } from 'react';

import useContribution from './useContribution';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useEditContribution: any = (contributionId) => {
  const addError = useError();
  const [{ loading }, makeRequest] =
    useImperativeRequestWrapper('serviceLpAccount');
  const successMsg = useToasts((state) => state.addItem);
  const { getContribution } = useContribution(contributionId);

  const editContribution = useCallback(
    async (data) => {
      const totalAmountInCents = (
        parseFloat(data.totalAmountInCents) * 100
      ).toFixed();
      const { error } = await makeRequest({
        path: `/admin/contributions/${contributionId}`,
        method: 'patch',
        body: {
          totalAmountInCents: parseFloat(totalAmountInCents),
          versionHash: data.versionHash,
          paymentUniqueId: data.paymentUniqueId,
          inviteCode: data.inviteCode,
          paymentDate: data.paymentDate,
          acceptedTosVersion: data.acceptedTosVersion,
          createdAt: data.createdAt,
          updatedAt: data.updatedAt,
        },
      });
      if (error) {
        addError(error);

        return { success: false };
      }
      await getContribution();
      successMsg(
        {
          msg: 'Contribution amount was successfully updated',
          type: MessageType.Success,
        },
        'root',
        {}
      );

      return { success: true };
    },
    [makeRequest, addError, successMsg, getContribution, contributionId]
  );

  return [{ loading }, editContribution];
};

export default useEditContribution;
