import { useCallback } from 'react';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useDeleteLPAccount: () => [
  { loading: boolean },
  (id: string) => Promise<{ success: boolean }>,
] = () => {
  const addError = useError();
  const addSuccessMsg = useToasts((state) => state.addItem);
  const [{ loading }, makeRequest] =
    useImperativeRequestWrapper('serviceLpAccount');

  const deleteAccount = useCallback(
    async (id: string) => {
      const { data, error } = await makeRequest({
        path: `/admin/accounts/${id}`,
        method: 'delete',
      });
      if (error || data !== null) {
        addError(
          'An error occurred. The account may NOT have been deleted. Please refresh the page, or try again later.'
        );

        return { success: false };
      }
      addSuccessMsg(
        {
          msg: 'LPAccount was successfully deleted',
          type: MessageType.Success,
        },
        'root',
        {}
      );

      return { success: true };
    },
    [addError, addSuccessMsg, makeRequest]
  );

  return [{ loading }, deleteAccount];
};

export default useDeleteLPAccount;
