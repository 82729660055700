import React from 'react';

import { Col, Descriptions, Row, Typography } from 'antd';

interface wire {
  bank_account_number: string;
  bank_title: string;
  beneficiary_name: string;
  bic: string;
  iban: string;
  swift_code: string;
}

const TransactionModalBankInfo = ({ bankInfo }: { bankInfo: wire }) => (
  <Row gutter={24}>
    <Descriptions>
      <Descriptions.Item label="Bank Title">
        <Typography.Text>{bankInfo?.bank_title}</Typography.Text>
      </Descriptions.Item>
    </Descriptions>
    <Descriptions>
      <Descriptions.Item label="Beneficiary Name">
        <Typography.Text>{bankInfo?.beneficiary_name}</Typography.Text>
      </Descriptions.Item>
    </Descriptions>
    {bankInfo?.iban && bankInfo?.bic ? (
      <Row gutter={24}>
        <Col span={13}>
          <Descriptions>
            <Descriptions.Item label="IBAN">
              <Typography.Text>{bankInfo?.iban}</Typography.Text>
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={11}>
          <Descriptions>
            <Descriptions.Item label="BIC">
              <Typography.Text>{bankInfo?.bic}</Typography.Text>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    ) : null}

    {bankInfo?.bank_account_number && bankInfo?.swift_code ? (
      <Row gutter={24}>
        <Col span={13}>
          <Descriptions>
            <Descriptions.Item label="Bank Account Number">
              <Typography.Text>{bankInfo?.bank_account_number}</Typography.Text>
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={11}>
          <Descriptions>
            <Descriptions.Item label="SWIFT Code">
              <Typography.Text>{bankInfo?.swift_code}</Typography.Text>
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    ) : null}
  </Row>
);

export default TransactionModalBankInfo;
