import React, { useEffect } from 'react';

import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Message from './Message';
import useToasts, {
  clearItemsSelector,
  itemsSelector,
  markItemsSelector,
} from '../../hooks/useToasts';

const Container = styled.div`
  position: fixed;
  top: 4rem;
  left: 0;
  z-index: 1000;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  align-items: center;
`;

const MessageHub: React.FC<{ t?: (msg: string) => string }> = ({ t }) => {
  const items = useToasts(itemsSelector);
  const clearItems = useToasts(clearItemsSelector);
  const markItems = useToasts(markItemsSelector);

  const { pathname, search } = useLocation();

  useEffect(
    () => () => {
      clearItems();
    },
    [clearItems, pathname, search]
  );

  return createPortal(
    <Container>
      {items
        .filter((t: any) => t.view === 'root')
        .map(({ key, toast }: any) => (
          <Message
            key={key}
            cancel={toast.cancel}
            render={toast.render}
            msg={t ? t(toast.msg) : toast.msg}
            type={toast.type}
            showClose={toast.close}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              markItems(key);
            }}
            data-testid="toastMessage"
          ></Message>
        ))}
    </Container>,
    document.body
  );
};

export default MessageHub;
