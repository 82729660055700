import React, { useEffect } from 'react';

import { ColumnProps } from 'antd/lib/table';
import styled from 'styled-components';

import AddAccountModal from '../../components/AddAccountModal';
import useAddAccountToPartner from '../hooks/useAddAccountToPartner';
import {
  ViewAccountModal,
  useLPUsersStore,
  useRefreshAccounts,
  useGetAccounts,
  LPAccountUsersTable,
} from '@/features/lp-account-users';
import { getDefaultSortOrder } from '@/helpers/antdTable';
import { getRandomInt } from '@/helpers/utilities';
import useAntdColumns from '@/hooks/useAntdColumns';
import AccountsTotalProvider from '@/shared/providers/accountsTotalProvider';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const WrapperButton = styled.div`
  margin-bottom: 1rem;
`;

const WrapperAccountsTablePartner = ({ partnerId }) => {
  const dispatch = useLPUsersStore((state) => state.dispatch);
  const defaultSort = useLPUsersStore((state) => state.defaultSort);
  const getPartnerAccounts = useRefreshAccounts({
    id: partnerId,
    domain: 'partners',
  });
  useGetAccounts({ id: partnerId, domain: 'partners' });
  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: ['accountId', 'email', 'firstName', 'lastName', 'roleName'],
    columnsSpecialProps: {
      firstName: {
        sorter: true,
        defaultSortOrder: getDefaultSortOrder(defaultSort, 'firstName'),
      },
      lastName: {
        sorter: true,
        defaultSortOrder: getDefaultSortOrder(defaultSort, 'lastName'),
      },
      email: { width: '20rem' },
    },
    addDefaultColumns: true,
    defaultSort: defaultSort,
    eyeLinkProps: {
      onClick: (record) => {
        dispatch({
          type: 'updateViewModal',
          args: {
            viewModalAccount: record,
          },
        });
      },
    },
  });
  useEffect(() => () => dispatch({ type: 'reset' }), [dispatch]);

  return (
    <Wrapper>
      <WrapperButton>
        <AddAccountModal
          id={partnerId}
          useAddAccount={useAddAccountToPartner}
          onSuccess={getPartnerAccounts}
          domain="partners"
        />
      </WrapperButton>
      <AccountsTotalProvider>
        <LPAccountUsersTable
          columns={columns}
          rowKey={(record) => record.id + getRandomInt(1000)}
        />

        <ViewAccountModal
          id={partnerId}
          domain="partners"
          onSuccess={getPartnerAccounts}
        />
      </AccountsTotalProvider>
    </Wrapper>
  );
};
export default WrapperAccountsTablePartner;
