import React from 'react';

import { QuestionCircleTwoTone } from '@ant-design/icons';
import { Radio, RadioChangeEvent, Tooltip } from 'antd';
import styled from 'styled-components';

import Label from '@/components/forms/Label';

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  padding-bottom: 16px;
  justify-content: space-between;
  width: 100%;
  > div {
    margin-right: 20px;
    width: 50%;
  }
`;

const LabelWrapper = styled.div`
  margin-bottom: 0.5rem;
`;

const PARTNER_TYPE = [
  { label: 'TEST', value: 'TEST' },
  { label: 'PRO', value: 'PRO' },
];
const PARTNER_STATUTS = {
  PRO: [
    { label: 'PENDING', value: 'PENDING' },
    { label: 'BLOCKED', value: 'BLOCKED' },
    { label: 'VALIDATED', value: 'VALIDATED' },
  ],
  TEST: [
    { label: 'BLOCKED', value: 'BLOCKED' },
    { label: 'VALIDATED', value: 'VALIDATED' },
  ],
};

/**
 *
 * TODO: To remove when v3 partner create is available
 * Then use the PartnerBusinessInformationSelectorV3 in replacement
 *
 */

const PartnerBusinessInformationSelector = ({
  onChangeStatus,
  onChangeType,
  value,
  style,
}: {
  onChangeStatus: Function;
  onChangeType: Function;
  value?: { type: string; status: string };
  style?: any;
}) => (
  <ContainerWrapper style={style}>
    <div>
      <LabelWrapper>
        <Label>Account Type *</Label>
      </LabelWrapper>
      <Radio.Group
        name="partner-type"
        options={PARTNER_TYPE}
        onChange={({ target: { value } }: RadioChangeEvent) =>
          onChangeType(value)
        }
        optionType="button"
        buttonStyle="solid"
        value={value?.type}
      />
    </div>
    <div>
      <LabelWrapper>
        <Label>
          Account status *
          {value?.type === 'TEST' && (
            <Tooltip title="Actif is display for validated value">
              <QuestionCircleTwoTone twoToneColor="#a1debd" />
            </Tooltip>
          )}
        </Label>
      </LabelWrapper>
      <Radio.Group
        name="partner-status"
        options={PARTNER_STATUTS[value?.type!]}
        onChange={({ target: { value } }: RadioChangeEvent) =>
          onChangeStatus(value)
        }
        optionType="button"
        buttonStyle="solid"
        value={value?.status}
      />
    </div>
  </ContainerWrapper>
);

export default PartnerBusinessInformationSelector;
