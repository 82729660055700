import queryString from 'query-string';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useSearchShop = () => {
  const addError = useError();
  const [, makeRequest] = useImperativeRequestWrapper('lpVillage');

  const getSingleShop = async (searchName) => {
    const { error, data } = await makeRequest({
      path: `/admin/shops?${queryString.stringify({
        name: searchName,
        pageSize: 2,
      })}`,
      method: 'get',
    });
    if (error || (data?.items || []).length !== 1) {
      error && addError(error);

      return { success: false, size: (data?.items || []).length };
    }

    return { success: true, shop: data.items[0] };
  };

  return getSingleShop;
};

export default useSearchShop;
