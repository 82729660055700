import React, { useCallback, useEffect } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import styled from 'styled-components';

import AddAddressModal from './AddAddressModal';
import ViewAddressModal from './ViewAddressModal';
import useLPAddressStore from '../hooks/useLPAddressStore';
import ActiveIcon from '@/components/icons/ActiveIcon';
import InActiveIcon from '@/components/icons/InActiveIcon';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import StyledTable from '@/components/tables/StyledTable';
import { scopes } from '@/config';
import addressesPostProcessor from '@/helpers/addressesPostProcessor';
import {
  getCurrentPage,
  getOffset,
  parseAntdSorter,
} from '@/helpers/antdTable';
import useAntdColumns from '@/hooks/useAntdColumns';
import usePaginatedTableData from '@/hooks/usePaginatedTableData';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 1rem;
`;

const LPAccountUserAddressTable = ({ id }) => {
  const rowData = useLPAddressStore((state) => state.rowData);
  const loading = useLPAddressStore((state) => state.loading);
  const pageSize = useLPAddressStore((state) => state.pageSize);
  const offset = useLPAddressStore((state) => state.offset);
  const sortBy = useLPAddressStore((state) => state.sortBy);
  const order = useLPAddressStore((state) => state.order);
  const total = useLPAddressStore((state) => state.total);
  const newAddressId = useLPAddressStore((state) => state.newAddressId);
  const dispatch = useLPAddressStore((state) => state.dispatch);

  useEffect(() => () => dispatch({ type: 'reset' }), [dispatch]);

  usePaginatedTableData({
    params: {
      path: `/admin/addresses/byaccount/${id}`,
      method: 'get',
      reducer: addressesPostProcessor,
    },
    dispatch,
    queryParams: {
      pageSize,
      offset,
      sortBy,
      order,
    },
    service: 'serviceLpAccount',
  });

  const pagination = {
    current: getCurrentPage(pageSize, offset),
    pageSize,
    total,
    showSizeChanger: false,
  };

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: [
      'addressId',
      'creatorId',
      'isPrimary',
      'types',
      'firstName',
      'lastName',
      'displayName',
      'company',
      'street',
      'street2',
      'houseNumber',
      'postalCode',
      'city',
      'state',
      'countryCode',
      'position,',
    ],
    columnsSpecialProps: {
      isPrimary: {
        render: (_, record) =>
          record.isPrimary ? <ActiveIcon /> : <InActiveIcon />,
        align: 'center',
      },
      types: {
        render: (_, record) => record.types.join(),
      },
    },
    addDefaultColumns: true,
    defaultSort: {
      sortBy: 'updatedAt',
      order: 'DESC',
    },
    eyeLinkProps: {
      onClick: (record) => {
        dispatch({
          type: 'updateViewModal',
          args: {
            viewModalAddress: record,
          },
        });
      },
    },
  });

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const [sortField, sortOrder] = parseAntdSorter(sorter);
      dispatch({
        type: 'updateSorting',
        args: { sortBy: sortField as string, order: sortOrder },
      });
      dispatch({
        type: 'updatePagination',
        args: {
          offset: getOffset(pagination.pageSize, pagination.current),
          pageSize: pagination.pageSize,
        },
      });
    },
    [dispatch]
  );

  return (
    <>
      <Wrapper>
        <ButtonWrapper>
          <ScopedCta
            component={PillButton}
            icon={<PlusOutlined />}
            onClick={() =>
              dispatch({ type: 'updateAddModal', args: { showAddModal: true } })
            }
            requiredScopes={[scopes.EDIT_LPACCOUNT]}
          >
            Add Address
          </ScopedCta>
        </ButtonWrapper>
        <StyledTable
          rowKey="id"
          rowClassName={(record) =>
            record.id && record.id === newAddressId ? 'newRecord' : ''
          }
          dataSource={rowData}
          columns={columns}
          size="small"
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
          scroll={{ x: 'max-content' }}
        />
      </Wrapper>
      <AddAddressModal accountId={id} />
      <ViewAddressModal />
    </>
  );
};

export default LPAccountUserAddressTable;
