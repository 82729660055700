import React from 'react';

import FromIcon from './icons/FromIcon';
import ToIcon from './icons/ToIcon';
const RecipientSender = (props: { recipient: string; sender: string }) => (
  <div>
    <FromIcon />
    &nbsp;{props.sender} <br />
    <ToIcon />
    &nbsp;{props.recipient}
  </div>
);

export default RecipientSender;
