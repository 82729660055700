import has from 'lodash/has';

import dates from '@/helpers/dates';

export const offerPostProcessor = (record) => ({
  ...record,
  parsedCreatedAt: has(record, 'createdAt')
    ? dates.parseDate(record.createdAt)
    : '',
  parsedUpdatedAt: has(record, 'updatedAt')
    ? dates.parseDate(record.updatedAt)
    : '',
  parsedActiveAt: !!record.activeAt ? dates.parseDate(record.activeAt) : '',
  parsedDeactivatedAt: !!record.deactivatedAt
    ? dates.parseDate(record.deactivatedAt)
    : '',
  parsedMultiplier: has(record, 'multiplierInCents')
    ? `${record.multiplierInCents}%`
    : '',
  parsedDeactivatedPaymentBuffer: has(record, 'deactivationPaymentBufferInSec')
    ? dates.parseSecondsDuration(record.deactivationPaymentBufferInSec)
    : '',
});

const offersPostProcessor = (data) => {
  const parsedItems = data.items.map((item) => offerPostProcessor(item));

  return { ...data, items: parsedItems };
};

export default offersPostProcessor;
