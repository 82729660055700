import { useCallback, useEffect, useState } from 'react';

import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useGetStateEventHistoryShipment = (id) => {
  const addError = useError();
  const [, makeRequest] = useImperativeRequestWrapper('shipment');
  const [events, setEvents] = useState<any>([]);

  const getStateEventHistory = useCallback(async () => {
    const { error, data } = await makeRequest({
      path: `/admin/shipments/${id}/state_events`,
      method: 'get',
    });
    if (error) {
      addError(error);

      return { events: [] };
    }

    return { events: data.events || [] };
  }, [addError, id, makeRequest]);

  useEffect(() => {
    getStateEventHistory().then(({ events }) => {
      setEvents(events);
    });
  }, [getStateEventHistory]);

  return events;
};
export default useGetStateEventHistoryShipment;
