import React, { useCallback } from 'react';

import { EMPTY_FIlES } from './CreateTos';
import { TosUploads, useTosLogic } from './ToSFileUploads';
import useCreateTos from '../hooks/useCreateTos';
import CancelSubmitFooterModal from '@/components/CancelSubmitFooterModal';
import CustomModal from '@/components/CustomModal';

interface IEditPdfModal {
  visible: boolean;
  closeModal: () => void;
  id: string;
}

const EditPdfModal = ({ id, visible, closeModal }: IEditPdfModal) => {
  const { loadingEdit, editTos } = useCreateTos();

  const { isInvalid, setLangFile, files, resetFiles } =
    useTosLogic(EMPTY_FIlES);

  const onClose = useCallback(() => {
    resetFiles();
    closeModal();
  }, [resetFiles, closeModal]);

  const handleSubmit = useCallback(async () => {
    const { success } = await editTos(id, files, null);
    if (success) {
      onClose();
    }
  }, [id, editTos, files, onClose]);

  const modalFooterButtons = CancelSubmitFooterModal(
    isInvalid,
    handleSubmit,
    onClose,
    loadingEdit
  );

  return (
    <CustomModal
      title="Edit Terms of Service"
      open={visible}
      destroyOnClose={true}
      footer={modalFooterButtons}
      onCancel={onClose}
    >
      <TosUploads setLangFile={setLangFile} files={files} />
    </CustomModal>
  );
};

export default EditPdfModal;
