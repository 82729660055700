import React from 'react';

import { Col, Row, TimePicker } from 'antd';
import map from 'lodash/map';
import moment, { Moment } from 'moment';

import { IWeeklyOpeningTime } from '../models/shop';
import { momentToUtc } from '@/helpers/dates';

type TTimeRange = [Moment, Moment] | null;

const extractTime = (key) => (day) =>
  day && day[0] && new Date(day[0][key] * 1000);

const openingTime = extractTime('openingTime');
const closingTime = extractTime('closingTime');

const capitalizeFirstLetter = (str) =>
  str
    .split('')
    .map((letter, i) => (i === 0 ? letter.toUpperCase() : letter))
    .join('');

const OpeningHours = ({ openTimes, onChange }) => {
  if (!openTimes) {
    return null;
  }

  const handleEntryChange = (
    event: TTimeRange,
    key: keyof IWeeklyOpeningTime
  ) => {
    onChange({
      ...openTimes,
      [key]: event
        ? [
            {
              openingTime: momentToUtc(event[0]),
              closingTime: momentToUtc(event[1]),
            },
          ]
        : null,
    });
  };

  return (
    <div>
      {openTimes &&
        map(openTimes, (val, key) => (
          <Row key={key} gutter={[6, 6]}>
            <Col span={2}>
              <p style={{ marginTop: '0.5rem' }}>
                {capitalizeFirstLetter(key)}
              </p>
            </Col>
            <Col span={4}>
              <TimePicker.RangePicker
                onChange={(e) =>
                  handleEntryChange(
                    e as TTimeRange,
                    key as keyof IWeeklyOpeningTime
                  )
                }
                format="HH:mm"
                value={
                  openingTime(val) &&
                  closingTime(val) && [
                    moment.utc(openingTime(val)),
                    moment.utc(closingTime(val)),
                  ]
                }
              />
            </Col>
          </Row>
        ))}
    </div>
  );
};

export default OpeningHours;
