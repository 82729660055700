import React, { useEffect, useState } from 'react';

import useGetTotals from '../hooks/useGetTotals';

export const ProductTotalContext = React.createContext({});

const ProductTotalProvider = ({ children }) => {
  const [data, setData] = useState<number>(0);
  const { getProductTotal } = useGetTotals();

  useEffect(() => {
    async function asyncWrapper() {
      const total = await getProductTotal();
      setData(total);
    }
    asyncWrapper();
  }, [getProductTotal]);

  const { Provider } = ProductTotalContext;

  return <Provider value={data}>{children}</Provider>;
};

export default ProductTotalProvider;
