import { useQuery } from '@tanstack/react-query';

import { ChangelogResponse } from '../types/changelog';
import { TAPIService } from '@/configs/app';
import { objectToURLSearchParams } from '@/helpers/urlParams';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { ExtractFnReturnType } from '@/lib/react-query';
import { QueryParams } from '@/types/queryParams';

export const Targets = {
  Partner: { uri: 'partners', responseKey: 'partner_changelogs' },
  LPAccount: { uri: 'lpaccounts', responseKey: 'lp_account_changelogs' },
} as const;

export type TargetsKeys = (typeof Targets)[keyof typeof Targets];

export const getChangelog = async <T>({
  target,
  queryParams,
  makeRequest,
}: {
  target: string;
  queryParams?: QueryParams;
  makeRequest: any;
}): Promise<ChangelogResponse<T>> => {
  const { data, error } = await makeRequest({
    path: `/${target}/changelog?${objectToURLSearchParams(
      queryParams as Record<string, string>
    )}`,
  });

  if (error) {
    throw new Error(error);
  }

  return data;
};

type QueryFnType = typeof getChangelog;

type UseChangelogOptions = {
  service: TAPIService;
  target: TargetsKeys;
  queryParams?: QueryParams;
};

export const useChangelog = <T>({
  service,
  target,
  queryParams,
}: UseChangelogOptions) => {
  const [, makeRequest] = useImperativeRequestWrapper(service);

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ['changelog', target, queryParams],
    queryFn: () =>
      getChangelog<T>({ target: target.uri, queryParams, makeRequest }),
  });
};
