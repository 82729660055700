import * as React from 'react';

const VillageIcon = (props) => (
  <svg
    width="2.25rem"
    height="2.25rem"
    fill="currentColor"
    viewBox="0 0 512 512"
    {...props}
  >
    <path d="M172.106 323.616H67.894L3.5 388.009v120.606h233V388.009l-64.394-64.393zm-70.606 170h-83v-95h83v95zm-72.394-110l45-45h76.787l-45 45H29.106zm152.394 110h-25v-52.5c0-6.893 5.607-12.5 12.5-12.5s12.5 5.607 12.5 12.5v52.5zm40 0h-25v-52.5c0-15.164-12.336-27.5-27.5-27.5s-27.5 12.336-27.5 27.5v52.5h-25v-99.394l52.5-52.5 52.5 52.5v99.394z" />
    <path d="M33.5 413.616v55h53v-55h-53zm38 40h-23v-25h23v25zM403.5 353.616v55h75v-55h-75zm60 40h-45v-25h45v25z" />
    <path d="M478.5 251.616V229.01l-64.394-64.394H408.5v-29h-45v29h-36.848L408.396 30.85l89.12 128.539 12.327-8.547L407.604 3.381l-65.445 107.094-56.689-64.302-105.932 144.15L75.546 87.097l-72.71 85.147 11.407 9.741 62.21-72.853 94.103 93.409-36.062 49.073H0v15h223.5v93h30v99h255V328.009l-30-30v-31.394H512v-15h-33.5zm-100-101h15v25h-15v-25zm-15 29v11h45v-10.394l44.394 44.394h-98.787l-45-45H363.5zm100 60v43.394l-19.394-19.394H358.5v-24h105zm-240 12h-70.391L286.531 70.059l47.441 53.813-24.898 40.744h-21.18L223.5 229.009v22.607zm40.001 66.395l-10 10v16.606H238.5V235.223l52.5-52.5 52.5 52.5v28.394h-25.606l-8.26 8.26c-4.98-4.602-11.562-7.26-18.633-7.26-15.164 0-27.5 12.336-27.5 27.5v25.894zm35.505-35.507L278.5 303.009v-10.894c0-6.893 5.607-12.5 12.5-12.5a12.447 12.447 0 018.006 2.889zM333.5 443.616h-25v-52.5c0-6.893 5.607-12.5 12.5-12.5s12.5 5.607 12.5 12.5v52.5zm40 0h-25v-52.5c0-15.164-12.336-27.5-27.5-27.5s-27.5 12.336-27.5 27.5v52.5h-25V334.223l52.5-52.5 52.5 52.5v109.393zm120 0h-105v-105h105v105zm-10.607-120h-98.787l-45-45h98.787l45 45z" />
    <path d="M175 3.616c-20.678 0-37.5 16.822-37.5 37.5s16.822 37.5 37.5 37.5 37.5-16.822 37.5-37.5c0-20.679-16.822-37.5-37.5-37.5zm0 60c-12.407 0-22.5-10.093-22.5-22.5s10.093-22.5 22.5-22.5 22.5 10.093 22.5 22.5-10.093 22.5-22.5 22.5zM183.086 398.238l10.606-10.605 10.607 10.607-10.606 10.606zM172.482 387.637l-11.786-11.787 10.605-10.606 11.787 11.787zM384 293.616h15v15h-15zM414 293.616h25v15h-25zM327.483 330.637l-16.787-16.786 10.606-10.606 16.787 16.787zM338.085 341.24l10.606-10.605 10.606 10.605-10.606 10.606zM266.304 249.849l-10.606-10.606 10.606-10.606 10.605 10.606zM276.908 218.027l12.787-12.787 10.606 10.606-12.787 12.787zM420.803 101.387l12.327-8.547 34.973 50.44-12.327 8.547zM464.322 164.154l12.327-8.547 8.547 12.327-12.327 8.547zM242.57 180.55l-12.086-8.883 8.883-12.087 12.087 8.883zM248.242 147.507l33.845-46.052 12.087 8.883-33.845 46.052zM37.057 201.46l-11.408-9.742 9.741-11.407 11.408 9.74zM45.139 168.914l26.98-31.597 11.408 9.741-26.98 31.597zM114.645 189.307l-10.92-10.84 10.568-10.645 10.919 10.84zM125.279 199.882l10.567-10.646 10.646 10.568-10.568 10.645zM16 279.618h40v15H16zM88 296.618h19v15H88zM135 277.618h45v15h-45zM272 471.618h48v15h-48zM349 492.618h15v15h-15zM389 477.618h40v15h-40zM456 493.618h39v15h-39z" />
  </svg>
);

export default VillageIcon;
