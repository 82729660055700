import React, { useContext } from 'react';

import { Grid } from '@material-ui/core';

import Dropdown from '@/components/forms/Dropdown';
import Label from '@/components/forms/Label';
import { ArticlesContext } from '@/shared/providers/articlesProvider';

const ProductTypeForm = ({
  setProductTypeCode,
}: {
  setProductTypeCode: (code) => void;
}) => {
  const articles = useContext(ArticlesContext);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Label>Article description</Label>
      </Grid>
      <Grid item xs={12}>
        <Dropdown
          options={articles}
          getOptionValue={(option) => option.code}
          getOptionLabel={(option) => option.description}
          onChange={(option) => setProductTypeCode(option?.code)}
        />
      </Grid>
    </Grid>
  );
};

export default ProductTypeForm;
