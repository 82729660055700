import React from 'react';

import {
  Box,
  Collapse,
  Paper,
  Text,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@lp/ds-next';

export const ExpandableRow = React.memo(
  ({ open, row, nbCells }: { open: boolean; row: any; nbCells: number }) => (
    <TableRow>
      <TableCell
        sx={{
          pb: 0,
          pt: 0,
          bgcolor: 'custom.neutral.black.4',
        }}
        colSpan={nbCells}
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box sx={{ m: '1rem', mb: '2rem' }}>
            <Text
              data-testid="changelog-details"
              variant="titleL"
              gutterBottom
              component="div"
            >
              Details
            </Text>
            <TableContainer component={Paper} sx={{ p: '1rem' }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Field Name</TableCell>
                    <TableCell align="right">Before</TableCell>
                    <TableCell align="right">After</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.changelog.payload.fields.map((item) => (
                    <TableRow
                      key={`${row.changelog.created_at}-${item.field_name}`}
                    >
                      <TableCell>{item.field_name}</TableCell>
                      <TableCell align="right">{item.before || '-'}</TableCell>
                      <TableCell align="right">{item.after || '-'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
);

ExpandableRow.displayName = 'ExpandableRow';
