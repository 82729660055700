import { useEffect } from 'react';

import { IShipment } from '../models/shipment';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useTrackingHistory = (shipment: IShipment) => {
  const [result, makeRequest] = useImperativeRequestWrapper('tracking');
  const addError = useError();

  useEffect(() => {
    //Only fetch if shipment has box
    const getData = async () => {
      const { error } = await makeRequest({
        path: `/shipments/${shipment.id}/tracking-history`,
      });
      if (error) {
        addError(error);
      }
    };

    if (shipment.box?.id) {
      getData();
    }
  }, [makeRequest, shipment, addError]);

  return result;
};

export default useTrackingHistory;
