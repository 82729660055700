import React, { useMemo } from 'react';

import { renderToStaticMarkup } from 'react-dom/server';
import { QRCode as SVGQRCode } from 'react-qr-svg';
import styled from 'styled-components';

import BoxSticker from './BoxSticker';
import PillButton from '@/components/PillButton';

const Wrapper = styled.div`
  display: flex;
`;

const StyledButton = styled(PillButton)`
  margin-top: 1.4rem;
`;

const BoxesSticker = ({ box }) => {
  const qrInfo = useMemo(
    () =>
      JSON.stringify({
        lpUi: box.thingName,
        bleMacAddress: box.bluetoothMacAddress,
        mechConfig: box.mechanicalConfiguration,
      }),
    [box]
  );

  const svgString = useMemo(
    () =>
      encodeURIComponent(
        renderToStaticMarkup(
          <BoxSticker
            qrCode={
              <SVGQRCode
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="L"
                style={{ width: 256 }}
                value={qrInfo}
              />
            }
          />
        )
      ),
    [qrInfo]
  );

  const stickerUrl = useMemo(
    () => `data:image/svg+xml,${svgString}`,
    [svgString]
  );

  return (
    <Wrapper>
      <img style={{ width: '40rem' }} src={stickerUrl} alt="Box sticker" />
      <StyledButton>
        <a href={stickerUrl} download>
          Download Sticker
        </a>
      </StyledButton>
    </Wrapper>
  );
};

export default BoxesSticker;
