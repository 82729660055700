import React, { useCallback, useEffect } from 'react';

import { HStack } from '@chakra-ui/react';
import { ColumnProps } from 'antd/es/table';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

import CreateBoxChecklist from './CreateBoxChecklist';
import DownloadQualityChecks from './DownloadQualityChecks';
import StyledTable from '@/components/tables/StyledTable';
import {
  useQualityCheck,
  useQualityCheckStore,
} from '@/features/quality-checks';
import {
  getCurrentPage,
  getOffset,
  parseAntdSorter,
} from '@/helpers/antdTable';
import useAntdColumns from '@/hooks/useAntdColumns';
import useWindowSize from '@/hooks/useWindowSize';

const BoxQualityCheck = ({ boxId }: { boxId: string }) => {
  const { loading, getQualityChecklists } = useQualityCheck(boxId);
  const { rowData, defaultSort, dispatch, pageSize, total, offset } =
    useQualityCheckStore();
  const windowSize = useWindowSize();
  const { pathname } = useLocation();

  useEffect(() => {
    getQualityChecklists();
  }, [getQualityChecklists]);

  useEffect(
    () => () => {
      dispatch({ type: 'reset' });
    },
    [dispatch]
  );

  const pagination = {
    current: getCurrentPage(pageSize, offset),
    pageSize,
    total,
    showSizeChanger: true,
  };

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      const [sortField, sortOrder] = parseAntdSorter(sorter);
      dispatch({
        type: 'updateSorting',
        args: { sortBy: sortField, order: sortOrder },
      });
      dispatch({
        type: 'updatePagination',
        args: {
          offset: getOffset(pagination.pageSize, pagination.current),
          pageSize: pagination.pageSize,
        },
      });
    },
    [dispatch]
  );

  const columns: ColumnProps<any>[] = useAntdColumns({
    columnsKeys: ['qualityChecklistId', 'createdBy', 'updatedBy', 'shipmentId'],
    addDefaultColumns: true,
    columnsSpecialProps: {
      createdBy: { width: '7rem' },
      updatedBy: { width: '7rem' },
    },
    defaultSort: defaultSort,
    eyeLinkProps: { to: `${pathname}/quality-check` },
  });

  return (
    <div>
      <HStack spacing={6} width="100%" justify="flex-end" mb="1rem">
        <DownloadQualityChecks boxId={boxId} />
        <CreateBoxChecklist boxId={boxId} />
      </HStack>
      <StyledTable
        rowKey="id"
        dataSource={rowData}
        columns={columns}
        size="small"
        loading={loading}
        scroll={{ y: get(windowSize, 'height', 0) * 0.65 }}
        onChange={handleTableChange}
        pagination={pagination}
      />
    </div>
  );
};

export default BoxQualityCheck;
