import { create } from 'zustand';

import { ToastMessage, MessageType } from './useToasts.types';

let id: number = 0;
const defaultCloseDuration = 400;

export const toastSettings = {
  defaultDuration: 3500,
};

interface IToastItem {
  key: number;
  toast: ToastMessage;
  view: string;
  settings: { duration?: number; forever?: boolean };
}

export interface IUseToasts {
  items: IToastItem[];
  cancelItems: Map<number, any>;
  getViewItems: (view: string) => void;
  markItems: (key: number) => void;
  removeItem: (key: number) => void;
  addItem: (
    toast: ToastMessage,
    view: string,
    settings?: {
      duration?: number;
      forever?: boolean;
    }
  ) => number;
  toggleToastLoading: (key: number, type: MessageType) => void;
  clearItem: (key: number) => void;
  clearItems: () => void;
}

export const itemsSelector = (state: IUseToasts) => state.items;
export const markItemsSelector = (state: IUseToasts) => state.markItems;
export const removeItemSelector = (state: IUseToasts) => state.removeItem;
export const addItemSelector = (state: IUseToasts) => state.addItem;
export const clearItemsSelector = (state: IUseToasts) => state.clearItems;

const useToasts = create<IUseToasts>((set, get) => ({
  items: [],
  cancelItems: new Map(),
  getViewItems: (view: string) =>
    get().items.filter((i: IToastItem) => i.view === view),
  markItems: (key: number) => {
    const item = get().items.find((c: IToastItem) => c.key === key);
    if (!item) {
      return;
    }
    set((state) => ({
      items: state.items.map((i: IToastItem) => {
        if (i.key === key) {
          i.toast.cancel = true;
        }

        return i;
      }),
      cancelItems: item.settings?.forever
        ? new Map(
            state.cancelItems.set(
              key,
              setTimeout(() => {
                get().removeItem(key);
              }, defaultCloseDuration)
            )
          )
        : state.cancelItems,
    }));
  },
  removeItem: (key: number) => {
    get().clearItem(key);
    set((state) => ({
      items: state.items.filter((i: IToastItem) => i.key !== key),
    }));
    set((state) => {
      state.cancelItems.delete(key);

      return {
        cancelItems: new Map(state.cancelItems),
      };
    });
  },
  addItem: (toast, view, settings) => {
    const key = id++;
    const item: IToastItem = {
      key: key,
      toast: toast,
      view: view,
      settings: { forever: settings?.forever },
    };
    set((state) =>
      settings?.forever
        ? { items: [...state.items, item], cancelItems: new Map() }
        : {
            items: [...state.items, item],
            cancelItems: new Map(
              state.cancelItems.set(
                item.key,
                setTimeout(() => {
                  get().markItems(item.key);
                  setTimeout(
                    () => get().removeItem(item.key),
                    defaultCloseDuration
                  );
                }, settings?.duration || toastSettings.defaultDuration)
              )
            ),
          }
    );

    return key;
  },
  toggleToastLoading: (key: number, type: MessageType) => {
    set((state) => ({
      items: state.items.map((i: IToastItem) => {
        if (i.key === key) {
          i.toast.type = type;
        }

        return i;
      }),
    }));
  },
  clearItem: (key: number) => {
    clearTimeout(get().cancelItems.get(key));
  },
  clearItems: () => {
    for (var key of get().cancelItems.keys()) {
      clearTimeout(key);
    }
    set((_) => ({ items: [], cancelItems: new Map() }));
  },
}));

export default useToasts;
