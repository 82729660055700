import React from 'react';

import { Box, Input, VStack, Text, Button, HStack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { EditBoxContractForm } from './components/EditBoxContractForm';
import { useBoxContract } from './hooks/useBoxContract';
import { useUpdateBoxContract } from './hooks/useUpdateBoxContract';
import FormRow from '@/components/forms/FormRow';
import Loader from '@/components/Loader';
import IdCell from '@/components/tables/IdCell';
import H1 from '@/components/text/H1';
import useError from '@/hooks/useError';

export function BoxContractDetailView() {
  const addError = useError();
  const { id } = useParams<{ id: string }>();
  const { result, invalidate } = useBoxContract(id!);
  const updateBoxContract = useUpdateBoxContract();

  if (result.state === 'idle' || result.state === 'loading') {
    return <Loader size={64} />;
  }

  if (result.state === 'error') return <>Error fetching contract {id}.</>;

  const { resource: contract } = result;

  return (
    <Box padding="0 64px" mb="1rem">
      <H1>Box contract details</H1>
      <Box maxW="900px">
        <VStack alignItems="flex-start" spacing={6} width="100%" mb="2rem">
          <FormRow>
            <VStack width="50%" spacing={2} alignItems="flex-start">
              <Text fontSize="md" fontWeight="500">
                Contract-ID
              </Text>
              <IdCell id={contract.id} />
            </VStack>
            <VStack width="50%" spacing={2} alignItems="flex-start">
              <Text fontSize="md" fontWeight="500">
                Offer-ID
              </Text>
              <IdCell id={contract.boxContractOffer.id} />
            </VStack>
            <VStack width="50%" spacing={2} alignItems="flex-start">
              <Text fontSize="md" fontWeight="500">
                PRODUCT-IDs
              </Text>
              <HStack>
                {contract.productIDs.map((productID, index) => (
                  <IdCell key={`contract-product${index}`} id={productID} />
                ))}
              </HStack>
            </VStack>
          </FormRow>
          {(contract.partnerId || contract.shopId) && (
            <>
              <HStack width="100%" spacing={10} justify="flex-start">
                {contract.partnerId && (
                  <>
                    <VStack width="30%" spacing={2} alignItems="flex-start">
                      <Text fontSize="md" fontWeight="500">
                        Partner-ID
                      </Text>
                      <IdCell id={contract.partnerId} />
                    </VStack>
                    <Link to={`/lp-village/partners/${contract.partnerId}`}>
                      <Button> To Partner</Button>
                    </Link>
                  </>
                )}
                {contract.shopId && (
                  <>
                    <VStack width="30%" spacing={2} alignItems="flex-start">
                      <Text fontSize="md" fontWeight="500">
                        Shop-ID
                      </Text>
                      <IdCell id={contract.shopId} />
                    </VStack>
                    <Link to={`/lp-village/shops/${contract.shopId}`}>
                      <Button> To Shop</Button>
                    </Link>
                  </>
                )}
              </HStack>
            </>
          )}

          <FormRow>
            <VStack width="50%" spacing={2} alignItems="flex-start">
              <Text fontSize="md" fontWeight="500">
                Created At
              </Text>
              <Input
                disabled
                variant="flushed"
                value={contract.createdAt.toLocaleString()}
              />
            </VStack>
            <VStack width="50%" spacing={2} alignItems="flex-start">
              <Text fontSize="md" fontWeight="500">
                Updated At
              </Text>
              <Input
                disabled
                variant="flushed"
                value={contract.updatedAt.toLocaleString()}
              />
            </VStack>
          </FormRow>
        </VStack>
      </Box>

      <Box maxW="900px">
        <EditBoxContractForm
          contract={contract}
          onSubmit={async (values) => {
            try {
              await updateBoxContract(id!, values);
              invalidate();
            } catch (error) {
              addError(error);
            }
          }}
        />
      </Box>
    </Box>
  );
}
