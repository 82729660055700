import { useQuery } from '@tanstack/react-query';

import { ISimStatus } from '../../models/sim';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

// Only available in prod and dev env and with only two lpUI in dev env
const isTwilioAvailable = (lpUI: string) =>
  (process.env.REACT_APP_BUILD_ENV === 'dev' &&
    (lpUI === 'vbC7D01543CE86493496' || lpUI === 'vb8D5588A007AD4F9A86')) ||
  process.env.REACT_APP_BUILD_ENV === 'prod';

export const getSim = async ({
  lpUI,
  makeRequest,
}: {
  lpUI: string;
  makeRequest: any;
}): Promise<ISimStatus | null> => {
  if (!isTwilioAvailable(lpUI)) {
    return null;
  }

  const { data, error } = await makeRequest({
    path: `/products/${lpUI}/sim`,
  });

  if (error) {
    throw new Error(error);
  }

  return data;
};

type QueryFnType = typeof getSim;

type UseSimOptions = {
  lpUI: string;
  config?: QueryConfig<QueryFnType>;
};

export const useSim = ({ lpUI, config }: UseSimOptions) => {
  const [, makeRequest] = useImperativeRequestWrapper('deviceV3');

  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['sim', lpUI],
    queryFn: () => getSim({ lpUI, makeRequest }),
  });
};
