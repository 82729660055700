import { useQuery } from '@tanstack/react-query';

import { ITrackingCode } from '../../types/trackingCode';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getTrackingCode = async ({
  shipmentId,
  makeRequest,
}: {
  shipmentId: string;
  makeRequest: any;
}): Promise<any | ITrackingCode[]> => {
  if (!shipmentId) return;

  const { data, error } = await makeRequest({
    path: `/shipments/${shipmentId}/tracking-codes`,
    method: 'get',
  });

  if (error) {
    throw new Error(error);
  }

  return data.trackingCodes;
};

type QueryFnType = typeof getTrackingCode;

type UseTrackingCodeOptions = {
  shipmentId: string;
  config?: QueryConfig<QueryFnType>;
};

export const useTrackingCodes = ({
  shipmentId,
  config,
}: UseTrackingCodeOptions) => {
  const [, makeRequest] = useImperativeRequestWrapper('shipment');

  return useQuery<ExtractFnReturnType<QueryFnType>, Error>({
    queryKey: ['shipment', shipmentId, 'trackingCode'],
    queryFn: () => getTrackingCode({ shipmentId, makeRequest }),
    ...config,
  });
};
