import React, { useCallback } from 'react';

import GitInfo from 'react-git-info/macro';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import H2 from '../text/H2';
import logo from '@/assets/images/LP-logo-env.png';
import Logout from '@/components/Logout';
import { appRoutes } from '@/configs/appRoutes';
import { useAuth0 } from '@/shared/auth0';

const HeaderElement = styled.header`
  width: 100%;
  height: 72px;
  padding: 1rem 0;
  position: relative;
  border-top: 4px solid ${(props) => props.theme.colors.adminPanel.brand};
  display: flex;
  justify-content: space-between;
`;

const LogoWrapper = styled.div`
  width: 8rem;
  display: flex;
  justify-content: center;
  padding-right: 10%; // Optically center icon
`;

const IMG = styled.img`
  height: 36px;
  width: auto;
  position: relative;
`;

const TextWrapper = styled.div`
  width: 100%;
`;

const H2extended = styled(H2)`
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  margin: 0;
`;

const RegularP = styled.span`
  font-family: 'TTNormsPro-Regular';
  margin-left: 5px;
`;

const LightP = styled.span`
  font-family: 'TTNormsPro-Regular';
  font-weight: lighter;
  margin-left: 5px;
  font-size: 14px;
`;

const Header = () => {
  const { isAuthenticated, user, logout } = useAuth0();
  const gitInfo = GitInfo();

  const handleClick = useCallback(() => {
    logout({
      returnTo: window.location.origin,
    });
  }, [logout]);

  return (
    <HeaderElement>
      <Link to={appRoutes.ROOT}>
        <LogoWrapper>
          <IMG src={logo} />
        </LogoWrapper>
      </Link>
      <TextWrapper>
        <H2extended>
          THEBOX <RegularP>Backoffice</RegularP>{' '}
          <LightP>{gitInfo.tags.length > 0 ? gitInfo.tags : `V1.6.3`}</LightP>
        </H2extended>
        {isAuthenticated && user && (
          <Logout data={user} onClick={handleClick}>
            Logout
          </Logout>
        )}
      </TextWrapper>
    </HeaderElement>
  );
};

export default Header;
