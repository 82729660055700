import { useCallback } from 'react';

import useShipmentsStore from './useShipmentsStore';
import shipmentsPostProcessor from '../helpers/shipmentsPostProcessor';
import getPaginatedTableData from '@/helpers/getPaginatedTableData';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';

const useRefreshShipments = () => {
  const dispatch = useShipmentsStore((state) => state.dispatch);
  const order = useShipmentsStore((state) => state.order);
  const sortBy = useShipmentsStore((state) => state.sortBy);
  const pageSize = useShipmentsStore((state) => state.pageSize);
  const offset = useShipmentsStore((state) => state.offset);
  const addError = useError();
  const [, makeRequest] = useImperativeRequestWrapper('shipment');

  const getShipments = useCallback(async () => {
    const { data, error } = await getPaginatedTableData({
      params: {
        path: '/admin/shipments',
        method: 'get',
      },
      reducer: shipmentsPostProcessor,
      dispatch,
      addError,
      makeRequest,
      queryParams: {
        pageSize,
        offset,
        sortBy,
        order,
      },
      queryParamsOptions: { skipEmptyString: true, skipNull: true },
    });

    return { data, error };
  }, [addError, dispatch, makeRequest, order, sortBy, offset, pageSize]);

  return getShipments;
};

export default useRefreshShipments;
