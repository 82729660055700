import { useCallback } from 'react';

import { useApiClient } from '@/helpers/ApiClients';

const useDeleteServiceLevel = () => {
  const device = useApiClient('device');

  const deleteServiceLevel = useCallback(
    (id: number) => device.delete(`/service-levels/${id}`),
    [device]
  );

  return deleteServiceLevel;
};

export default useDeleteServiceLevel;
