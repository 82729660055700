import React from 'react';

import styled from 'styled-components';

import Dropdown from '../../../components/forms/Dropdown';
import Label from '../../../components/forms/Label';

const OFFER_TYPES = [
  { value: 'default' },
  { value: 'pioneer' },
  { value: 'gift' },
  { value: 'thebag' },
  { value: 'reward' },
  { value: 'singular' },
];
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const WrapperLabel = styled(Label)`
  margin-right: 1rem;
`;

const OfferTypeSelector = ({
  label,
  onChange,
  offerType,
}: {
  label: string;
  onChange: any;
  offerType: string;
}) => (
  <Wrapper>
    {label && <WrapperLabel>{label}</WrapperLabel>}
    <Dropdown
      options={OFFER_TYPES}
      getOptionValue={(option) => option['value']}
      getOptionLabel={(option) => option['value']}
      value={{ value: offerType }}
      onChange={onChange}
    />
  </Wrapper>
);

export default OfferTypeSelector;
