import React, { useState } from 'react';

import { Box, TabsV2, TabPanelV2 } from '@lp/ds-next';
import { useParams } from 'react-router-dom';

import LPPartnerAddressTable from './LPPartnerAddressesTable';
import PartnerSettings from './Tabs/PartnerSettings';
import WrapperAccountsTablePartner from './WrapperAccountsTablePartner';
import PartnerApiKeyTable from '../../apiKeys/components/PartnerApiKeyTable';
import ProductsTable from '../../products/components/ProductsTable';
import LPShopsTable from '../../shops/components/LPShopsTable';
import { ILPPartnerState } from '../api/useLPPartner';
import { IPartnerV3Update } from '../models/partnerV3';
import { PartnerServiceLevel } from '@/features/serviceLevels';
import ArticlesProvider from '@/shared/providers/articlesProvider';
import ProductTotalProvider from '@/shared/providers/productTotalProvider';

const Tabs = ({
  partner,
  updatePartner,
}: {
  partner: ILPPartnerState;
  updatePartner: (data: IPartnerV3Update) => Promise<{ success: boolean }>;
}) => {
  const { id } = useParams<{ id: string }>();
  const [tab, setTab] = useState<number>(0);

  const tabsItems = [
    {
      label: 'Addresses',
      key: 'addresses',
      children: <LPPartnerAddressTable id={id} />,
    },
    {
      label: 'Accounts',
      key: 'accounts',
      children: <WrapperAccountsTablePartner partnerId={id} />,
    },
    {
      label: 'Shops',
      key: 'shops',
      children: <LPShopsTable partnerId={id} />,
    },
    {
      label: 'Products',
      key: 'products',
      children: (
        <ProductTotalProvider>
          <ArticlesProvider>
            <ProductsTable partnerId={id} />
          </ArticlesProvider>
        </ProductTotalProvider>
      ),
    },
    {
      label: 'API Keys',
      key: 'api-keys',
      children: <PartnerApiKeyTable partnerID={id} />,
    },
    {
      label: 'Service Level',
      key: 'service-level',
      children: (
        <PartnerServiceLevel
          partner={partner.data}
          updatePartner={updatePartner}
        />
      ),
    },
    {
      label: 'Settings',
      key: 'settings',
      children: (
        <PartnerSettings partner={partner.data} updatePartner={updatePartner} />
      ),
    },
  ];

  return (
    <>
      <TabsV2 value={tab} setValue={setTab} tabs={tabsItems} />
      <Box mt={1}>
        {tabsItems.map((tabItem, index) => (
          <TabPanelV2 key={index} value={tab} index={index}>
            {tabItem.children}
          </TabPanelV2>
        ))}
      </Box>
    </>
  );
};

export default Tabs;
