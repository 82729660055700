import React, { useCallback, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button as AntdButton } from 'antd';

import AddContributionModal from './AddContributionModal/AddContributionModal';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';

const AddContribution = ({ accountId }) => {
  const [visible, setVisible] = useState(false);
  const handleClose = useCallback(() => setVisible(false), [setVisible]);

  return (
    <>
      <ScopedCta
        type="primary"
        icon={<PlusOutlined />}
        shape="round"
        component={AntdButton}
        requiredScopes={[scopes.EDIT_LPACCOUNT]}
        onClick={() => setVisible(true)}
        data-testid="addContributionBtn"
      >
        Add contribution
      </ScopedCta>
      <AddContributionModal
        visible={visible}
        onClose={handleClose}
        accountId={accountId}
      />
    </>
  );
};

export default AddContribution;
