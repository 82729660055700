export type TBuildEnv = 'dev' | 'prod' | 'test';

export const getApiServices = (env: TBuildEnv) => {
  switch (env) {
    case 'dev':
      return {
        lpVillage: 'https://api.dev.livingpackets.com/lpvillage/api/v1',
        lpVillageV3: 'https://api.dev.livingpackets.com/v3/lpvillage',
        serviceLpAccount: 'https://api.dev.livingpackets.com/lpaccount/api/v1',
        lpAccountV3: 'https://api.dev.livingpackets.com/v3/lpaccount',
        shipment: 'https://api.dev.livingpackets.com/shipment/api/v2',
        tracking: 'https://api.dev.livingpackets.com/tracking/api/v2',
        device: 'https://api.dev.livingpackets.com/device/api/v1',
        deviceV3: 'https://api.dev.livingpackets.com/v3/device',
        auth: 'https://auth.dev.livingpackets.com',
        oldBffApiUrl: 'https://admin.api.dev.livingpackets.com/api/v1',
        tos: 'https://api.dev.livingpackets.com/tos/api/v1',
        productV3: 'https://api.dev.livingpackets.com/v3/device/products',
      } as const;
    case 'test':
      return {
        lpVillage: 'https://api.test.livingpackets.com/lpvillage/api/v1',
        lpVillageV3: 'https://api.test.livingpackets.com/v3/lpvillage',
        serviceLpAccount: 'https://api.test.livingpackets.com/lpaccount/api/v1',
        lpAccountV3: 'https://api.test.livingpackets.com/v3/lpaccount',
        shipment: 'https://api.test.livingpackets.com/shipment/api/v2',
        tracking: 'https://api.test.livingpackets.com/tracking/api/v2',
        device: 'https://api.test.livingpackets.com/device/api/v1',
        deviceV3: 'https://api.test.livingpackets.com/v3/device',
        auth: 'https://auth.test.livingpackets.com',
        oldBffApiUrl: 'https://admin.api.test.livingpackets.com/api/v1',
        tos: 'https://api.test.livingpackets.com/tos/api/v1',
        productV3: 'https://api.test.livingpackets.com/v3/device/products',
      } as const;
    default:
      return {
        lpVillage: 'https://api.livingpackets.com/lpvillage/api/v1',
        lpVillageV3: 'https://api.livingpackets.com/v3/lpvillage',
        serviceLpAccount: 'https://api.livingpackets.com/lpaccount/api/v1',
        lpAccountV3: 'https://api.livingpackets.com/v3/lpaccount',
        shipment: 'https://api.livingpackets.com/shipment/api/v2',
        tracking: 'https://api.livingpackets.com/tracking/api/v2',
        device: 'https://api.livingpackets.com/device/api/v1',
        deviceV3: 'https://api.livingpackets.com/v3/device',
        auth: 'https://auth.livingpackets.com',
        oldBffApiUrl: 'https://admin.api.livingpackets.com/api/v1',
        tos: 'https://api.livingpackets.com/tos/api/v1',
        productV3: 'https://api.livingpackets.com/v3/device/products',
      } as const;
  }
};
