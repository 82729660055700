import React from 'react';

import { Grid, GridItem } from '@chakra-ui/react';
import styled from 'styled-components';

import TimeLineAltCard from './TimelineAltCard';
import TimeLineMainCard from './TimeLineMainCard';

const VerticalLine = styled.div`
  border-left: 1.5px solid #00bf6f;
  height: 100%;
  margin-left: 2.5px;
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: #00bf6f;
  border-radius: 50%;
  display: inline-block;
`;

const TimeLineRow = ({ timeLineEvent }) => (
  <>
    <Grid templateColumns="20px 5fr">
      <GridItem w="100%" h="100%">
        <Grid templateRows="3fr 1fr 3fr">
          <GridItem>
            <VerticalLine />
          </GridItem>
          <GridItem>
            <Dot />
          </GridItem>
          <GridItem>
            <VerticalLine />
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem w="100%">
        {timeLineEvent && timeLineEvent.sub_status ? (
          <TimeLineAltCard timeLineEvent={timeLineEvent} />
        ) : (
          <TimeLineMainCard timeLineEvent={timeLineEvent} />
        )}
      </GridItem>
    </Grid>
  </>
);

export default TimeLineRow;
