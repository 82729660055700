import React from 'react';

import {
  NumberInput as ChakraNumberInput,
  NumberInputProps as ChakraNumberInputProps,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from '@chakra-ui/react';

export interface NumberInputProps extends ChakraNumberInputProps {}

export function NumberInput({ value, onChange, ...rest }: NumberInputProps) {
  return (
    <ChakraNumberInput onChange={onChange} value={value} {...rest}>
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </ChakraNumberInput>
  );
}
