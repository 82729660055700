import { useCallback } from 'react';

import { useGetBoxConfiguration } from '../hooks/useGetBoxConfiguration';
import { BoxConfig } from '../models/boxConfig';
import { useFetchResource, UseFetchResource } from '@/hooks/useFetchResource';

export function useBoxConfiguration(id: string): UseFetchResource<BoxConfig> {
  const getBoxConfiguration = useGetBoxConfiguration();

  const fetchFunction = useCallback(
    () => getBoxConfiguration(id),
    [getBoxConfiguration, id]
  );

  return useFetchResource(fetchFunction);
}
