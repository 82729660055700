import React, { useCallback, useMemo, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';

import RoleSelector from './RoleSelector';
import useDeleteRole from '../hooks/useDeleteRole';
import CancelSubmitFooterModal from '@/components/CancelSubmitFooterModal';
import CustomModal from '@/components/CustomModal';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';

const DeleteRole = () => {
  const [{ loading }, deleteRole] = useDeleteRole();
  const [visible, setVisible] = useState(false);
  const openModal = useCallback(() => setVisible(true), []);
  const closeModal = useCallback(() => setVisible(false), []);
  const [roleId, setRoleId] = useState('');

  const handleSubmit = useCallback(async () => {
    const { success } = await deleteRole(roleId);
    if (success) {
      setRoleId('');
      closeModal();
    }
  }, [deleteRole, closeModal, roleId]);

  const handleChangeDropdown = useCallback((role) => {
    setRoleId(role.id);
  }, []);

  const modalFooterButtons = useMemo(
    () => CancelSubmitFooterModal(!roleId, handleSubmit, closeModal, loading),
    [closeModal, handleSubmit, roleId, loading]
  );

  return (
    <div>
      <ScopedCta
        component={PillButton}
        icon={<DeleteOutlined />}
        type="danger"
        onClick={openModal}
        requiredScopes={[scopes.MANAGE_LPVILLAGE_ROLES]}
        data-testid="deleteRoleButton"
      >
        Delete Role
      </ScopedCta>

      <CustomModal
        title="Delete a role"
        open={visible}
        destroyOnClose={true}
        footer={modalFooterButtons}
        onCancel={closeModal}
      >
        <RoleSelector onChange={handleChangeDropdown} />
      </CustomModal>
    </div>
  );
};

export default DeleteRole;
