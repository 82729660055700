import React from 'react';

import { HStack } from '@chakra-ui/react';
import { Grid } from '@material-ui/core';

import BoxContractTable from './components/BoxContractTable';
import CreateBoxContract from './components/CreateBoxContract';
import useContractsStore from './hooks/useContractsStore';
import ServerSearch from '@/components/ServerSearch';
import { storeSelector } from '@/helpers/paginatedStoreHelpers';

const BoxContractsListView = () => {
  const { dispatch, searchTerms } = useContractsStore(storeSelector);

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid container item xs={11}>
        <HStack spacing="2rem" align="center" width="100%">
          <ServerSearch
            fields={[
              { key: 'search', placeholder: 'Last name, first name or email' },
            ]}
            dispatch={dispatch}
            storeSearchTerms={searchTerms}
          />
          <CreateBoxContract />
        </HStack>
      </Grid>
      <Grid item xs={11}>
        <BoxContractTable hasFilters={{ paymentDate: true }} />
      </Grid>
    </Grid>
  );
};

export default BoxContractsListView;
