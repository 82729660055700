import React, { useCallback, useMemo, useState } from 'react';

import ScopesSelector from './ScopesSelector';
import useAddScopeToRole from '../../roles/hooks/useAddScopeToRole';
import CancelSubmitFooterModal from '@/components/CancelSubmitFooterModal';
import CustomModal from '@/components/CustomModal';

const AddScopeToRole = ({ role, visible, closeModal }) => {
  const [{ loading }, addScope] = useAddScopeToRole();
  const [scope, setScope] = useState({ id: '', name: '' });

  const handleSubmit = useCallback(async () => {
    const { success } = await addScope(role, [scope]);
    setScope({ id: '', name: '' });
    if (success) {
      closeModal();
    }
  }, [addScope, role, closeModal, scope]);

  const handleChange = useCallback((e) => {
    setScope({ id: e.id, name: e.name });
  }, []);

  const isInvalid = useMemo(() => !scope, [scope]);

  const modalFooterButtons = useMemo(
    () => CancelSubmitFooterModal(isInvalid, handleSubmit, closeModal, loading),
    [closeModal, handleSubmit, isInvalid, loading]
  );

  return (
    <CustomModal
      title={`Add a new scope to ${role.name}`}
      open={visible}
      destroyOnClose={true}
      footer={modalFooterButtons}
      onCancel={closeModal}
    >
      <ScopesSelector onChange={handleChange} role={role} />
    </CustomModal>
  );
};
export default AddScopeToRole;
