import React, { useMemo } from 'react';

import { Typography } from 'antd';

interface IdCellProps {
  id: string;
  full?: boolean;
}

const IdCell = (props: IdCellProps) => {
  const id = useMemo(() => {
    if (!props.id) {
      return null;
    }
    if (props.id.length > 8 && !props.full) {
      return `${props.id.substring(0, 4)}…${props.id.substring(
        props.id.length - 4
      )}`;
    }
    if (props.id.length <= 8 || props.full) {
      return props.id;
    }
  }, [props]);

  return id ? (
    <Typography.Text copyable={{ text: props.id }}>{id}</Typography.Text>
  ) : (
    <span>-</span>
  );
};

export default IdCell;
