import React from 'react';

import styled from 'styled-components';

import AngelIcon from '../icons/AngelIcon';
import BnRIcon from '../icons/BnRIcon';
import BoxesIcon from '../icons/BoxesIcon';
import ChecklistIcon from '../icons/ChecklistIcon';
import ServiceLevelsIcon from '../icons/ServiceLevelsIcon';
import ShipmentsIcon from '../icons/ShipmentsIcon';
import TransactionIcon from '../icons/TransactionIcon';
import UsersIcon from '../icons/UsersIcon';
import VillageIcon from '../icons/VillageIcon';
import ChangelogIcon from '@/components/icons/ChangelogIcon';
import DashboardIcon from '@/components/icons/DashboardIcon';
import NavElement from '@/components/NavElement';
import { scopes } from '@/config';
import { appRoutes } from '@/configs/appRoutes';

const Container = styled.nav`
  width: 8rem;
  text-align: center;
  position: sticky;
  top: 0;
  height: calc(100vh - 72px);
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
`;

const MainNavigation = () => (
  // TODO: add Admins tab back in once in place
  <Container>
    {/*<NavElement to="/users" icon={UsersIcon} text="Admins" />*/}
    <NavElement
      to={appRoutes.PRODUCTS.ROOT}
      icon={BoxesIcon}
      text="Products"
      requiredScopes={[scopes.VIEW_PRODUCT]}
    />
    <NavElement
      to={appRoutes.SHIPMENTS}
      icon={ShipmentsIcon}
      text="Shipments"
      requiredScopes={[scopes.VIEW_SHIPMENTS]}
    />
    <NavElement
      to={appRoutes.LPACCOUNT}
      icon={UsersIcon}
      text="LP ACCOUNT"
      requiredScopes={[scopes.VIEW_LPACCOUNT]}
    />
    <NavElement
      to={appRoutes.SHARING_ANGELS.ROOT}
      icon={AngelIcon}
      text="Sharing Angels"
      requiredScopes={[scopes.VIEW_SHARING_ANGELS]}
    />
    <NavElement
      to={appRoutes.LPVILLAGE.ROOT}
      icon={VillageIcon}
      text="LP Village"
      requiredScopes={[scopes.VIEW_LPVILLAGE]}
    />
    <NavElement
      to={appRoutes.BUY_N_RENT.ROOT}
      icon={BnRIcon}
      text="Buy'n'Rent"
      requiredScopes={[scopes.VIEW_BUY_N_RENT]}
    />
    <NavElement
      to={appRoutes.TRANSACTION.ROOT}
      icon={() => <TransactionIcon />}
      text="TRANSACTION"
      requiredScopes={[scopes.VIEW_TRANSACTION]}
    />
    {/*  Blocked because waiting new specification     <NavElement
        to={appRoutes.TOS.ROOT}
        icon={TosIcon}
        text="ToS"
        requiredScopes={[scopes.MANAGE_TOS]}
      /> */}
    <NavElement
      to={appRoutes.QUALITY_CHECKS.ROOT}
      icon={ChecklistIcon}
      text="Quality Check"
      requiredScopes={[scopes.VIEW_QUALITY_CHECKS]}
    />
    <NavElement
      to={appRoutes.SERVICE_LEVELS}
      icon={ServiceLevelsIcon}
      text="Service Levels"
      requiredScopes={[scopes.VIEW_SERVICE_LEVELS]}
    />
    {/*       <NavElement
        to={appRoutes.OFFER_TEMPLATE.ROOT}
        icon={ServiceLevelsIcon}
        text="Offer Template"
        requiredScopes={[scopes.VIEW_SERVICE_LEVELS]}
      /> */}
    <NavElement
      to={appRoutes.CHANGELOG.ROOT}
      icon={ChangelogIcon}
      text="Changelog"
      requiredScopes={[scopes.VIEW_CHANGELOG]}
    />
    <NavElement
      to={appRoutes.DASHBOARD}
      icon={DashboardIcon}
      text="Dashboard"
      requiredScopes={[scopes.VIEW_CHANGELOG]}
    />
  </Container>
);
export default MainNavigation;
