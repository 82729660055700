import useRefreshRoles from './useRefreshRoles';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useAddScopeToRole: any = () => {
  const addError = useError();
  const [{ loading }, makeRequest] = useImperativeRequestWrapper('lpVillage');
  const successMsg = useToasts((state) => state.addItem);
  const getRoles = useRefreshRoles();

  const addScope = async (role, scopes) => {
    const { error } = await makeRequest({
      path: `/admin/roles/${role.id}/scopes`,
      method: 'post',
      body: scopes.map((s) => s.id),
    });
    if (error) {
      addError(error);

      return { success: false };
    }
    successMsg(
      {
        msg: `The scope "${scopes[0].name}" was successfully added to role "${role.name}"`,
        type: MessageType.Success,
      },
      'root',
      {}
    );

    await getRoles();

    return { success: true };
  };

  return [{ loading }, addScope];
};
export default useAddScopeToRole;
