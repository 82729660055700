import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { find, get } from 'lodash';
import moment from 'moment';
import { CSVLink } from 'react-csv';

import { IArticle } from '../../boxes/models/box';
import CustomModal from '@/components/CustomModal';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';
import { ArticlesContext } from '@/shared/providers/articlesProvider';

const headers = [
  { label: 'Partnership’s ID', key: 'partnerId' },
  //   { label: 'Partnership’s name', key: 'Billy' },
  //   { label: 'Partnership’s Created At', key: 'pCreatedAt' },
  { label: 'Time window', key: 'timeWindow' },
  //   par shipment
  { label: 'Shipment ID', key: 'id' },
  { label: 'Shipment’s name', key: 'reference' },
  { label: 'Service Level', key: 'serviceLevel' },
  { label: 'Carrier’s name', key: 'carrierCode' },
  { label: 'Carrier’s reference number', key: 'carrierTrackingCode' },
  { label: 'Status of the shipment', key: 'contractState' },
  { label: 'Deployed At', key: 'deployedAt' },
  { label: 'Arrived At', key: 'receivedAt' },
  { label: 'LPUI', key: 'box.thingName' },
  { label: 'Article code', key: 'box.articleCode' },
  { label: 'Article description', key: 'articleDescription' },
  { label: 'To bill', key: 'isBillable' },
];

const GenerateBillingReport = ({
  data,
  billingMode,
  setBillingMode,
  billingWindow,
  selectedRows,
}) => {
  const [csvLoading, setCsvLoading] = useState(false);
  const [csvData, setCsvData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const csvLink = useRef<any>();
  const articles = useContext(ArticlesContext) as IArticle[];

  const generateName = useCallback(() => {
    let title: string;
    if (
      billingWindow.length === 0 ||
      (billingWindow[0] === '' && billingWindow[1] === '')
    ) {
      title = 'billing.csv';
    } else if (billingWindow[0] === '' && billingWindow[1] !== '') {
      title = `billing_to_${billingWindow[1]}.csv`;
    } else if (billingWindow[0] !== '' && billingWindow[1] === '') {
      title = `billing_from_${billingWindow[0]}.csv`;
    } else {
      title = `billing_from_${billingWindow[0]}_to_${billingWindow[1]}.csv`;
    }

    return title;
  }, [billingWindow]);

  const aggregateBillingDatas = useCallback(
    (data) => {
      data = data.filter((row) => selectedRows.includes(row.id));
      data.forEach((shipment) => {
        shipment.timeWindow = `${billingWindow[0]} - ${billingWindow[1]}`;
        if (
          typeof shipment.deployedAt == 'number' &&
          shipment.deployedAt !== 0
        ) {
          shipment.deployedAt = moment
            .unix(shipment.deployedAt)
            .format('YYYY-MM-DD');
        }
        if (
          typeof shipment.receivedAt == 'number' &&
          shipment.receivedAt !== 0
        ) {
          shipment.deployedAt = moment
            .unix(shipment.receivedAt)
            .format('YYYY-MM-DD');
        }
        if (shipment.box.articleCode) {
          shipment.box.articleDescription =
            find(articles, ['code', shipment.box.articleCode])?.description ||
            'Unknown';
        }
        //cast to string in order for the cs to display the value false
        shipment.isBillable = shipment.isBillable.toString();
      });

      return data;
    },
    [billingWindow, articles, selectedRows]
  );

  const handleGenerateReport = useCallback(() => {
    setCsvLoading(true);
    const billingData = aggregateBillingDatas(data);
    setCsvData(billingData);
    try {
      get(csvLink, 'current.link').click();
    } catch (err) {
      console.error(err);
    }
    setCsvLoading(false);
  }, [aggregateBillingDatas, data]);

  useEffect(() => {
    const billingData = aggregateBillingDatas(data);
    setCsvData(billingData);
  }, [data, setCsvData, aggregateBillingDatas]);

  return (
    <>
      <CSVLink
        data={csvData || []}
        headers={headers}
        filename={generateName()}
        separator=";"
        ref={csvLink}
      />
      {!billingMode && (
        <ScopedCta
          component={PillButton}
          onClick={() => setBillingMode(true)}
          requiredScopes={[scopes.MANAGE_BILLING]}
        >
          Switch to billing mode
        </ScopedCta>
      )}
      {billingMode && (
        <ScopedCta
          component={PillButton}
          onClick={handleGenerateReport}
          disabled={csvLoading}
          requiredScopes={[scopes.MANAGE_BILLING]}
        >
          Generate billing CSV
        </ScopedCta>
      )}
      <CustomModal
        open={showModal}
        onCancel={() => setShowModal(false)}
        destroyOnClose={true}
      >
        <div>Generating billing datas</div>
      </CustomModal>
    </>
  );
};

export default GenerateBillingReport;
