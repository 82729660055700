import React, { useCallback, useMemo, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';

import RoleForm from './RoleForm';
import useCreateRole from '../hooks/useCreateRole';
import { INITIAL_CreateRole } from '../models/role';
import CancelSubmitFooterModal from '@/components/CancelSubmitFooterModal';
import CustomModal from '@/components/CustomModal';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';

const CreateRole = () => {
  const [{ loading }, createRole] = useCreateRole();
  const [visible, setVisible] = useState(false);
  const [role, setRole] = useState(INITIAL_CreateRole);
  const openModal = useCallback(() => setVisible(true), []);
  const closeModal = useCallback(() => setVisible(false), []);

  const handleSubmit = useCallback(async () => {
    const { success } = await createRole(role);
    if (success) {
      setRole(INITIAL_CreateRole);
      closeModal();
    }
  }, [createRole, closeModal, role]);

  const isInvalid = useMemo(
    () => !role.name || !role.description || !role.type,
    [role]
  );

  const modalFooterButtons = useMemo(
    () => CancelSubmitFooterModal(isInvalid, handleSubmit, closeModal, loading),
    [closeModal, handleSubmit, isInvalid, loading]
  );

  return (
    <div>
      <ScopedCta
        component={PillButton}
        icon={<PlusOutlined />}
        onClick={openModal}
        requiredScopes={[scopes.EDIT_LPVILLAGE_ROLES]}
        data-testid="createRoleButton"
      >
        Create Role
      </ScopedCta>

      <CustomModal
        title="Add a new role"
        open={visible}
        destroyOnClose={true}
        footer={modalFooterButtons}
        onCancel={closeModal}
      >
        <RoleForm role={role} setRole={setRole} />
      </CustomModal>
    </div>
  );
};

export default CreateRole;
