import React, { useState } from 'react';

import {
  Divider,
  Grid,
  Stack,
  Text,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ChevronDownIcon,
  RefreshIcon,
  NewTooltip as Tooltip,
  ButtonV2,
  useTheme,
} from '@lp/ds-next';
import { Card } from '@mui/material';
import { Typography } from 'antd';

import { NotFoundError, useERPContract } from '../api/getERPContract';
import { useUpdateContractSync } from '../api/updateContractSync';
import Loader from '@/components/Loader';
import { DATE_DISPLAY_FORMAT, formatStringToDate } from '@/helpers/dates';
import useError from '@/hooks/useError';

const PieceOfInformation = ({
  label,
  value,
}: {
  label: string;
  value: React.ReactNode;
}) => (
  <Stack
    direction={{ mobile: 'column', tablet: 'row' }}
    justifyContent="space-between"
    alignItems={{ mobile: 'flex-start', tablet: 'center' }}
    spacing={{ mobile: 0, tablet: 2 }}
  >
    <Text variant="titleS">{label} :</Text>
    <span>{value}</span>
  </Stack>
);

export const ContractInfosCard = () => {
  const theme = useTheme();

  const addError = useError();

  const { data: contract, error, isLoading } = useERPContract();

  const isError404 = error instanceof NotFoundError;

  const { mutateAsync: refreshContractSync, isPending: syncInProgress } =
    useUpdateContractSync();

  const [expanded, setExpanded] = useState(false);

  const toggleAccordion = () => {
    if (!isError404 && !isLoading) {
      setExpanded((prev) => !prev);
    }
  };

  const handleRefresh = async (e) => {
    e.stopPropagation();

    try {
      await refreshContractSync();

      setExpanded(true);
    } catch (error) {
      addError(error);
    }
  };

  return (
    <Card
      component={Accordion}
      expanded={expanded}
      onChange={toggleAccordion}
      elevation={0}
      disableGutters
      sx={{ borderRadius: '10px !important' }}
    >
      <AccordionSummary
        expandIcon={!isError404 && !isLoading && <ChevronDownIcon />}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flex={1}
          minHeight="2.5rem"
        >
          <Text data-testid="contract-card" variant="titleL">
            Contract Information
          </Text>
          <Stack direction="row" alignItems="center" gap="1rem">
            <Text sx={{ color: 'custom.neutral.black.20' }} variant="titleS">
              {isLoading
                ? ''
                : isError404
                  ? 'No contract found'
                  : `Last sync from ERP : ${formatStringToDate(contract?.erp_sync_at || '')}`}
            </Text>
            {isLoading ? (
              <Loader size={7} color={theme.palette.custom.primary[100]} />
            ) : (
              <Tooltip
                title={
                  <Text variant="bodyTextL">
                    Click to synchronize the contract info from the ERP
                  </Text>
                }
              >
                <ButtonV2
                  data-testid="sync-contract-button"
                  size="small"
                  variant="flat"
                  icon={RefreshIcon}
                  disabled={syncInProgress}
                  onClick={handleRefresh}
                />
              </Tooltip>
            )}
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Divider sx={{ mb: '20px' }} />
        <Grid item mobile={12} container direction="column" spacing={0.5}>
          <Grid item>
            <PieceOfInformation
              label="Contract ID"
              value={
                <Typography.Text
                  copyable={{ text: contract?.contract_id || '' }}
                >
                  {contract?.contract_id}
                </Typography.Text>
              }
            />
          </Grid>
          <Grid item>
            <PieceOfInformation
              label="Business Contractor"
              value={
                <Typography.Text
                  copyable={{
                    text: contract?.business_contractor || '',
                  }}
                >
                  {contract?.business_contractor}
                </Typography.Text>
              }
            />
          </Grid>
          <Grid item>
            <PieceOfInformation
              label="End Date"
              value={formatStringToDate(
                contract?.end_at || '',
                DATE_DISPLAY_FORMAT
              )}
            />
          </Grid>
          <Grid item>
            <PieceOfInformation
              label="ERP Created at"
              value={formatStringToDate(
                contract?.created_at || '',
                DATE_DISPLAY_FORMAT
              )}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Card>
  );
};
