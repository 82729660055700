import React from 'react';

import {
  ButtonV2,
  Grid,
  HexagoneExclamationMarkError,
  Text,
} from '@lp/ds-next';

interface IConfirmDeletingAddressModal {
  handleClose: (open: boolean) => void;
  deleteAddress: (params: { addressId: string }) => void;
  id: string;
}

const ConfirmDeletingAddressModal = ({
  handleClose,
  deleteAddress,
  id,
}: IConfirmDeletingAddressModal) => {
  const handleDelete = () => {
    deleteAddress({ addressId: id });
    handleClose(false);
  };

  return (
    <Grid
      container
      direction="column"
      sx={{
        height: '100%',
        maxWidth: { mobile: '100%', tablet: '23.375rem' },
        justifyContent: 'space-around',
      }}
    >
      <Grid container direction="column" alignItems="center" gap="1rem">
        <HexagoneExclamationMarkError />
        <Text variant="titleL" align="center" data-testid="deleteAddressTitle">
          Address deletion
        </Text>
        <Text
          variant="bodyTextM"
          color="custom.neutral.black.90"
          align="center"
        >
          Are you sure you want to delete this address?
        </Text>
        <Grid container justifyContent="space-between" mt="1rem">
          <ButtonV2
            variant="flat"
            color="critical"
            onClick={() => handleClose(false)}
          >
            Cancel
          </ButtonV2>
          <ButtonV2
            color="critical"
            onClick={handleDelete}
            data-testid="confirmDeleteAddress"
          >
            Delete
          </ButtonV2>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConfirmDeletingAddressModal;
