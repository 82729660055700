import React, { useCallback, useState } from 'react';

import { HStack, VStack } from '@chakra-ui/react';
import { Col, Input } from 'antd';

import Spacer from '@/components/layout/Spacer';
import PillButton from '@/components/PillButton';
import ScopedCta from '@/components/ScopedCta';
import { scopes } from '@/config';
const { TextArea } = Input;

interface BoxConfigEditProps {
  onSave: (newBoxConfig: string) => void;
  initialValue: string;
}

const BoxConfigEdit = ({ initialValue, onSave }: BoxConfigEditProps) => {
  const [boxConfig, setBoxConfig] = useState<string>(initialValue);

  const handleChange = useCallback((e) => {
    const value = e?.target?.value;
    setBoxConfig(value);
  }, []);

  const handleSubmit = useCallback(async () => {
    onSave(boxConfig);
  }, [boxConfig, onSave]);

  const handleCancel = useCallback(() => {
    setBoxConfig(initialValue);
  }, [initialValue]);

  return (
    <VStack align="flex-end" minW="35%">
      <TextArea
        placeholder="Edit Box Config"
        autoSize={{ minRows: 3, maxRows: 50 }}
        value={boxConfig}
        style={{ fontFamily: 'monospace' }}
        onChange={handleChange}
      />
      <Spacer v="1rem" />
      <HStack>
        <Col>
          <PillButton onClick={handleCancel} type="ghost">
            Cancel
          </PillButton>
        </Col>
        <Col>
          <ScopedCta
            component={PillButton}
            onClick={handleSubmit}
            disabled={boxConfig === initialValue}
            requiredScopes={[scopes.EDIT_BOX_CONFIG]}
          >
            Upload
          </ScopedCta>
        </Col>
      </HStack>
    </VStack>
  );
};

export default BoxConfigEdit;
