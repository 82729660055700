import { create } from 'zustand';

import {
  IModifiedContribution,
  INITIAL_ModifiedContribution,
} from '../../models/contribution';

interface ContributionState {
  contribution: IModifiedContribution;
  loading: boolean;
  setContribution: (contribution: IModifiedContribution) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: Error) => void;
  setReset: () => void;
}

const useContributionStore = create<ContributionState>((set) => ({
  contribution: INITIAL_ModifiedContribution,
  loading: true,
  setContribution: (contribution: IModifiedContribution) => {
    set((state) => ({ ...state, contribution: contribution }));
  },
  setLoading: (loading: boolean) => {
    set((state) => ({ ...state, loading: loading }));
  },
  setError: (error: Error) => {
    set((state) => ({ ...state, error: error }));
  },
  setReset: () => {
    set((_state) => ({
      loading: true,
      contribution: INITIAL_ModifiedContribution,
    }));
  },
}));

export default useContributionStore;
