import { IService } from '../../services/models/service';
import { ILPVillageAddress, INITIAL_LPVillageAddress } from '@/models/address';

export interface IBusinessHours {
  closingTime: number;
  openingTime: number;
}

export interface IWeeklyOpeningTime {
  fri: IBusinessHours[];
  mon: IBusinessHours[];
  sat: IBusinessHours[];
  sun: IBusinessHours[];
  thu: IBusinessHours[];
  tue: IBusinessHours[];
  wed: IBusinessHours[];
}

export interface IShop {
  address: ILPVillageAddress;
  archived: boolean;
  carrierCodes: string[];
  createdAt: number;
  createdBy: string;
  description: string;
  id: string;
  name: string;
  openTimes: IWeeklyOpeningTime;
  partner: { id: string; name: string };
  phoneNumber: string;
  services: IService[] | null;
  thumbnail: string;
  updatedAt: number;
  website: string;
}

export interface IModifiedShop extends IShop {
  partnerId: string;
  parsedCreatedAt: string;
  parsedUpdatedAt: string;
  serviceIds?: string[];
}

export interface ICreateShop {
  partnerId: string;
  name: string;
  phoneNumber: string;
  website: string;
  description: string;
  thumbnail: string;
}

export const INITIAL_OpeningTimes = {
  fri: [],
  mon: [],
  sat: [],
  sun: [],
  thu: [],
  tue: [],
  wed: [],
};

export const INITIAL_Shop: IShop = {
  id: '',
  partner: { id: '', name: '' },
  name: '',
  phoneNumber: '',
  website: '',
  description: '',
  thumbnail: '',
  archived: false,
  carrierCodes: [],
  createdAt: 0,
  createdBy: '',
  openTimes: INITIAL_OpeningTimes,
  services: [],
  updatedAt: 0,
  address: INITIAL_LPVillageAddress,
};

export const INITIAL_ModifiedShop: IModifiedShop = {
  ...INITIAL_Shop,
  partnerId: '',
  parsedCreatedAt: '',
  parsedUpdatedAt: '',
};

export const INITIAL_CreateShop: ICreateShop = {
  partnerId: '',
  name: '',
  phoneNumber: '',
  website: '',
  description: '',
  thumbnail: '',
};
