import React, { useEffect } from 'react';

import {
  ArrowDownIcon,
  ArrowUpIcon,
  ButtonV2 as Button,
  TableCell,
  TableRow,
} from '@lp/ds-next';
import { get } from 'lodash/fp';

import { ExpandableRow } from './ExpandableRow';
import { HeadCell } from '@/features/changelog/types/changelog';

export function Row<T>({
  row,
  headCells,
  globalOpen = false,
}: {
  row: T;
  headCells: HeadCell[];
  globalOpen?: boolean;
}) {
  const [open, setOpen] = React.useState(globalOpen);

  const nbCells = headCells.length + 1;

  useEffect(() => setOpen(globalOpen), [globalOpen]);

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset !important' },
          cursor: 'pointer',
        }}
        onClick={() => setOpen(!open)}
        hover
      >
        <TableCell>
          <Button
            size="small"
            variant="flat"
            aria-label="expand row for more details"
            onClick={() => setOpen(!open)}
            icon={open ? ArrowUpIcon : ArrowDownIcon}
          />
        </TableCell>
        {headCells.map(({ path, formatter }, index) => {
          const value = get(path, row).toString();

          let formattedValue = value;

          if (formatter) {
            formattedValue = formatter(value);
          }

          return (
            <TableCell
              key={index}
              align="right"
              sx={{ whiteSpace: 'nowrap', fontWeight: open ? 500 : 400 }}
            >
              {formattedValue || '-'}
            </TableCell>
          );
        })}
      </TableRow>
      <ExpandableRow row={row} open={open} nbCells={nbCells} />
    </React.Fragment>
  );
}
