import has from 'lodash/has';

import dates from '@/helpers/dates';

const lpPartnersPostProcessor = (data) => {
  if (!data) {
    return {};
  }
  const parsedItems = data.items.map((item) => ({
    ...item,
    parsedCreatedAt: has(item, 'createdAt')
      ? dates.parseDate(item.createdAt)
      : '',
    parsedUpdatedAt: has(item, 'updatedAt')
      ? dates.parseDate(item.updatedAt)
      : '',
  }));

  return { ...data, items: parsedItems };
};

export const lpPartnerPostProcessor = (data) => ({
  ...data,
  parsedCreatedAt: has(data, 'createdAt')
    ? dates.parseDate(data.createdAt)
    : '',
  parsedUpdatedAt: has(data, 'updatedAt')
    ? dates.parseDate(data.updatedAt)
    : '',
});

export default lpPartnersPostProcessor;
