import React from 'react';

import Lottie from 'react-lottie';

import greenDotsLoader from '../../assets/lotties/green-dots-loader.json';

export const Loader = () => (
  <Lottie
    style={{
      width: '7rem',
      height: '7rem',
    }}
    isClickToPauseDisabled
    options={{
      loop: true,
      autoplay: true,
      animationData: greenDotsLoader,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }}
  />
);
