import { find } from 'lodash';
import has from 'lodash/has';

import dates, { formatISODate } from '@/helpers/dates';

const boxesPostProcessor = (data, articles) => {
  if (!data) {
    return {};
  }
  const items = data.boxes || data.items || [];
  const parsedItems = items.map((item) => ({
    ...item,
    parsedCreatedAt: has(item, 'createdAt')
      ? dates.parseDate(item.createdAt)
      : '',
    parsedUpdatedAt: has(item, 'updatedAt')
      ? dates.parseDate(item.updatedAt)
      : '',
    parsedManufacturingDate: has(item, 'manufacturingDate')
      ? dates.parseDate(item.manufacturingDate)
      : '',
    productDescription:
      find(articles, ['code', item.articleCode])?.description || 'Unknown',
  }));

  return { ...data, items: parsedItems };
};

export const boxPostProcessor = (data, articles) => {
  if (!data) {
    return {};
  }

  return {
    ...data,
    parsedCreatedAt: has(data, 'createdAt')
      ? formatISODate(data.createdAt, 'yy-MM-dd HH:mm:ss')
      : '',
    parsedUpdatedAt: has(data, 'updatedAt')
      ? formatISODate(data.updatedAt, 'yy-MM-dd HH:mm:ss')
      : '',
    parsedManufacturingDate: has(data, 'manufacturingDate')
      ? formatISODate(data.manufacturingDate, 'yy-MM-dd HH:mm:ss')
      : '',
    productDescription:
      find(articles, ['code', data.articleCode])?.description || 'Unknown',
  };
};

export const boxRelationsPostProcessor = (data) => {
  if (!data) {
    return {};
  }
  const items = data.items || [];

  const parsedItems = items.map((item) => ({
    ...item,
    parsedCreatedAt: has(item, 'createdAt')
      ? dates.parseDate(item.createdAt)
      : '',
    parsedUpdatedAt: has(item, 'updatedAt')
      ? dates.parseDate(item.updatedAt)
      : '',
    parsedActiveSince: has(item, 'activeSince')
      ? dates.parseDate(item.activeSince)
      : '',
  }));

  return { ...data, items: parsedItems };
};

export default boxesPostProcessor;
