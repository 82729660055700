import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Row } from 'antd';
import { isEmpty, some } from 'lodash';
import styled from 'styled-components';

import useAddPartnerAddress from '../../lp-village/partners/hooks/useAddPartnerAddress';
import { ICreateShipment } from '../models/shipment';
import AddressForm from '@/components/AddressForm';
import PillButton from '@/components/PillButton';
import { PartnerAddressSelector } from '@/features/lp-village';
import {
  ILPVillageAddress,
  INITIAL_AddToPartnerAddress,
} from '@/models/address';

interface ICreateShipmentSenderAddressSelection {
  addressType: 'sender' | 'recipient';
  partnerId: string;
  partnerAddressId: string;
  handlePartnerAddressIdChange: (input: string) => void;
  setShipment: Dispatch<SetStateAction<ICreateShipment>>;
}

const StyledButton = styled(PillButton)`
  margin-top: 1rem;
`;

const CreateShipmentSenderAddressSelection = ({
  addressType,
  partnerId,
  partnerAddressId,
  handlePartnerAddressIdChange,
  setShipment,
}: ICreateShipmentSenderAddressSelection) => {
  const [, addPartnerAddress] = useAddPartnerAddress();

  const [newAddress, setNewAddress] = useState(INITIAL_AddToPartnerAddress);
  const [showCreateAddressForm, setShowCreateAddressForm] =
    useState<boolean>(false);
  const handleAddAddress = useCallback(() => {
    setShowCreateAddressForm(true);
  }, [setShowCreateAddressForm]);

  const setAddressSender = useCallback(
    (newSelectedAddress) => {
      setShipment((prev) => ({
        ...prev,
        [addressType]: {
          ...prev[addressType],
          accountId: partnerId,
          address: {
            firstName: newSelectedAddress.firstName,
            lastName: newSelectedAddress.lastName,
            displayName: newSelectedAddress.displayName,
            company: newSelectedAddress.company,
            street: newSelectedAddress.street,
            street2: newSelectedAddress.street2,
            houseNumber: newSelectedAddress.houseNumber,
            postalCode: newSelectedAddress.postalCode,
            state: newSelectedAddress.state,
            city: newSelectedAddress.city,
            countryCode: newSelectedAddress.countryCode,
          },
        },
      }));
    },
    [setShipment, partnerId, addressType]
  );

  const handleAddressSelection = useCallback(
    (newSelectedAddress: ILPVillageAddress) => {
      setAddressSender(newSelectedAddress);
      handlePartnerAddressIdChange(newSelectedAddress.id);
    },
    [handlePartnerAddressIdChange, setAddressSender]
  );

  const handleCancelAddress = useCallback(() => {
    setShowCreateAddressForm(false);
  }, [setShowCreateAddressForm]);

  const isSenderAddressInvalid = useCallback(() => {
    const {
      street2,
      state,
      company,
      displayName,
      type,
      houseNumber,
      ...requiredAddressFields
    } = newAddress;

    return some(requiredAddressFields, isEmpty);
  }, [newAddress]);

  const handleNewAddress = useCallback(async () => {
    const reqBody = { ...newAddress, type: 'sender' };
    const res = await addPartnerAddress(partnerId, reqBody);
    if (res.success) {
      setShowCreateAddressForm(false);
      handlePartnerAddressIdChange(res.data.id);
    }
  }, [
    newAddress,
    setShowCreateAddressForm,
    handlePartnerAddressIdChange,
    addPartnerAddress,
    partnerId,
  ]);

  return (
    <>
      {!showCreateAddressForm && (
        <PartnerAddressSelector
          onChange={handleAddressSelection}
          partnerId={partnerId}
          addressId={partnerAddressId}
        />
      )}
      {showCreateAddressForm && (
        <>
          <AddressForm
            address={newAddress}
            setAddress={setNewAddress}
            hideAddressTypeFields={true}
            hidePrimary={true}
            initialFocus={false}
          />
          <Row justify="end" gutter={[15, 14]}>
            <PillButton danger type="default" onClick={handleCancelAddress}>
              Cancel
            </PillButton>
            <PillButton
              onClick={handleNewAddress}
              disabled={isSenderAddressInvalid()}
            >
              Add
            </PillButton>
          </Row>
        </>
      )}
      {!showCreateAddressForm && (
        <Row justify="end">
          <StyledButton onClick={handleAddAddress}>
            Add a new address
          </StyledButton>
        </Row>
      )}
    </>
  );
};

export default CreateShipmentSenderAddressSelection;
