import { useCallback } from 'react';

import useContributionStore from './useContributionStore';
import useError from '@/hooks/useError';
import useImperativeRequestWrapper from '@/hooks/useImperativeRequestWrapper';
import useToasts from '@/shared/hooks/useToasts';
import { MessageType } from '@/shared/hooks/useToasts.types';

const useSaveContributionNote = () => {
  const addError = useError();
  const contribution = useContributionStore((state) => state.contribution);
  const [, makeRequest] = useImperativeRequestWrapper('serviceLpAccount');
  const successMsg = useToasts((state) => state.addItem);

  const addNote = useCallback(
    async (note) => {
      const { error } = await makeRequest({
        path: '/admin/contributions/notes',
        method: 'post',
        body: {
          note: note,
          id: contribution.id,
          versionHash: contribution.versionHash,
        },
      });
      if (error) {
        addError(error);

        return { success: false };
      }
      successMsg(
        { msg: 'The note was successfully added', type: MessageType.Success },
        'root',
        {}
      );

      return { success: true };
    },
    [
      addError,
      contribution.id,
      contribution.versionHash,
      makeRequest,
      successMsg,
    ]
  );

  return addNote;
};
export default useSaveContributionNote;
